import React, {useState, useEffect} from 'react'
import {withTranslation} from 'react-i18next'
import {Col, Row} from 'reactstrap'
import {useRecoilState, useRecoilValue} from 'recoil'
import {filterNavbarUsageState, loadingDroneInformationState} from 'store/atoms/drone-information'

import {getUsageDetail} from 'services/skc/drone-information'

import SkeletonDetailDrone from 'components/DroneiNalyze/Skeleton-DroneInformation/Usage/SkeletonDetailDrone'
import CardDetail from '../CardDetail'

const UsageDetailDrone = props => {
    const {t, i18n} = props

    const [detailCard, setDetailCard] = useState([])
    const [data, setData] = useState([])
    const [loadingSkeleton, setLoadingSkeleton] = useState(false)
    const [language, setLanguage] = useState(i18n.language)

    const filterNavbarUsage = useRecoilValue(filterNavbarUsageState)
    const [loadingDroneInformation, setLoadingDroneInformation] = useRecoilState(loadingDroneInformationState)

    useEffect(() => {
        fetchUsageDetail(filterNavbarUsage)
    }, [])

    useEffect(() => {
        if (loadingDroneInformation.detail) {
            fetchUsageDetail(filterNavbarUsage)
            setLoadingDroneInformation(cur => ({...cur, detail: false}))
        }
    }, [loadingDroneInformation.detail])

    useEffect(() => {
        if (i18n.language !== language) {
            setDataInCard(data)
            setLanguage(i18n.language)
        }
    }, [i18n.language])

    const fetchUsageDetail = async params => {
        try {
            setLoadingSkeleton(true)
            const response = await getUsageDetail(params)

            if (response?.status_code === 200 && response.results) {
                setData(response?.results)
                setDataInCard(response?.results)
            }
        } catch (error) {
            setDetailCard([])
        } finally {
            setLoadingSkeleton(false)
        }
    }

    const setDataInCard = data => {
        const twoDecimal = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        const noDecimal = new Intl.NumberFormat('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})

        const newCardDetail = [
            {
                title: t('droneInformation.totalNumberOfFlights'),
                total: noDecimal.format(data?.total_flight || 0),
                unit: t('common.flightTimeUnit'),
            },
            {
                title: t('droneInformation.totalFlightTime'),
                total: twoDecimal.format(data?.total_fly_time || 0),
                unit: t('common.hourUnit'),
            },
            {
                title: t('droneInformation.totalWorkingArea'),
                total: twoDecimal.format(data?.total_area || 0),
                unit: t('common.raiUnit'),
            },
            {
                title: t('droneInformation.numberOfSpreader'),
                total: noDecimal.format(data?.total_spread_trip || 0),
                unit: t('common.flightTimeUnit'),
            },
            {
                title: t('droneInformation.numberOfSpraying'),
                total: noDecimal.format(data?.total_spray_trip || 0),
                unit: t('common.flightTimeUnit'),
            },
            {
                title: t('droneInformation.spreaderQuantity'),
                total: noDecimal.format(data?.total_spread_usage || 0),
                unit: t('common.kgUnitRaiUnit'),
            },
            {
                title: t('droneInformation.sprayingQuantity'),
                total: noDecimal.format(data?.total_spray_usage || 0),
                unit: t('common.literRaiUnit'),
            },
        ]

        setDetailCard(newCardDetail)
    }

    return (
        <>
            {loadingSkeleton ? (
                <SkeletonDetailDrone />
            ) : (
                <Row style={{marginTop: '24px', marginBottom: '24px'}}>
                    {detailCard?.map((item, index) => (
                        <Col key={index}>
                            <CardDetail data={item} />
                        </Col>
                    ))}
                </Row>
            )}
        </>
    )
}

export default withTranslation()(UsageDetailDrone)
