import React, {useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {Row, Col, Card, CardBody, CardTitle} from 'reactstrap'
import {useRecoilState, useRecoilValue} from 'recoil'

import ChartResultAreaData from './ChartResultAreaData'

import {Spin} from 'antd'

import ResultTimeSkeleton from 'components/DroneiNalyze/Skeleton-iNalyze/Comparison/FormTime/ResultTimeSkeleton'
import {formCompareTimeState, loadingInalyzeState} from 'store/atoms/inalyze'
import {getCompareTime} from 'services/skc/inalyze'

const ComparisonResultTime = props => {
    const {t, i18n} = props

    const [loading, setLoading] = useRecoilState(loadingInalyzeState)
    const formCompareTime = useRecoilValue(formCompareTimeState)

    const [dataCompare, setDataCompare] = useState({})
    const [labels, setLabels] = useState([])
    const [skeleton, setSkeleton] = useState(false)

    const [language, setLanguage] = useState(i18n.language)

    const state = {}

    useEffect(() => {
        if (i18n.language !== language) {
            fetchDataCompareTime()
            setLanguage(i18n.language)
        }
    }, [i18n.language])

    useEffect(() => {
        if (loading.compare_time) {
            fetchDataCompareTime()
        }
    }, [loading.compare_time])

    const fetchDataCompareTime = async () => {
        try {
            setSkeleton(true)
            const response = await getCompareTime(formCompareTime)

            if (response.status_code === 200) {
                const data = [...response?.results?.data]
                const titleName = response?.results?.label

                const unitType = onSetUnit(titleName)

                onSetDataChart(data, titleName, unitType)
            }
        } catch (error) {
            setDataCompare([])
            setLabels([])
        } finally {
            setSkeleton(false)
            setLoading(cur => ({...cur, compare_time: false}))
        }
    }

    const onSetUnit = titleName => {
        if (titleName === t('inalyze.flightDuration')) {
            return {
                unit: t('common.hour'),
                unitAverage: t('common.hourUnitDroneUnit'),
            }
        }

        if (titleName === t('inalyze.numberOfWorkingDays')) {
            return {
                unit: t('common.dayUnit'),
                unitAverage: t('common.HourUnitDayUnit'),
            }
        }

        if (titleName === t('inalyze.compareWorkingAreas')) {
            return {
                unit: t('common.raiUnit'),
                unitAverage: t('common.raiUnitDroneUnit'),
            }
        }

        if (titleName === t('inalyze.compareNumberOfFlights')) {
            return {
                unit: t('common.flightUnit'),
                unitAverage: t('common.flightUnitDroneUnit'),
            }
        }

        if (titleName === t('inalyze.compareSprayingQuantity')) {
            return {
                unit: t('common.literUnit'),
                unitAverage: t('common.literRaiUnit'),
            }
        }

        if (titleName === t('inalyze.compareSpeed')) {
            return {
                unit: t('common.msUnit'),
                unitAverage: t('common.msUnit'),
            }
        }

        if (titleName === t('inalyze.compareHeight')) {
            return {
                unit: t('common.mUnit'),
                unitAverage: t('common.mUnit'),
            }
        }
    }

    const separatedData = data => {
        const result = data.reduce((acc, obj) => {
            const {label, data} = obj

            if (!acc[label]) {
                acc[label] = []
            }
            acc[label].push(data)
            return acc
        }, {})

        return result
    }

    const setColorData = data => {
        const result = Object.entries(data).map(([label, data], index) => {
            if (index === 0) {
                return {
                    name: label,
                    data: data,
                    color: '#F1B44C',
                }
            }

            if (index === 1) {
                return {
                    name: label,
                    data: data,
                    color: '#556EE6',
                }
            }

            if (index === 2) {
                return {
                    name: label,
                    data: data,
                    color: '#50A5F1',
                }
            }

            if (index === 3) {
                return {
                    name: label,
                    data: data,
                    color: '#34C38F',
                }
            }

            if (index === 4) {
                return {
                    name: label,
                    data: data,
                    color: '#e83e8c',
                }
            }
        })

        return result
    }

    const onSetDataChart = (data, titleName, unitType) => {
        const label = []
        const newDataTotal = []
        const newDataAverage = []

        data.forEach(item => {
            label.push(item.x)
            item.y?.forEach(yItem => {
                newDataTotal.push({type: item.x.label, label: yItem.label, data: yItem.data.total, sort: item.x.sort})
                newDataAverage.push({
                    type: item.x.label,
                    label: yItem.label,
                    data: yItem.data.average,
                    sort: item.x.sort,
                })
            })
        })

        label.sort((a, b) => a.sort - b.sort)

        const newLabel = label.map(item => item.label)

        newDataTotal.sort((a, b) => a.sort - b.sort)
        newDataAverage.sort((a, b) => a.sort - b.sort)

        const separatedTotalData = separatedData(newDataTotal)

        const separatedAverageData = separatedData(newDataAverage)
        const resultTotalData = setColorData(separatedTotalData)
        const resultAverageData = setColorData(separatedAverageData)

        const resultData = {
            data: {
                name: `${titleName} (${unitType?.unit})`,
                data: [...resultTotalData],
                unit: unitType?.unit,
            },
            dataAverage: {
                name: `${titleName} ${t('common.averageUnit')} (${unitType?.unitAverage})`,
                data: [...resultAverageData],
                unit: unitType?.unitAverage,
            },
        }

        setDataCompare(resultData)
        setLabels(newLabel)
    }

    return (
        <Spin tip="Loading..." size="large" spinning={skeleton}>
            {skeleton ? (
                <ResultTimeSkeleton dataCompare={dataCompare} />
            ) : (
                <Card className="card-result-compare-time">
                    <CardTitle className="card-title">
                        <p className="text-header">{t('inalyze.comparisonSelectionResult')}</p>
                    </CardTitle>
                    <CardBody>
                        <Row style={{margin: 0, padding: 0}}>
                            <Col xl={12} className="col-box-chart">
                                {dataCompare?.data?.data?.map(item => (
                                    <div className="container-box" key={item?.name}>
                                        <div className="box-color" style={{background: item?.color || ''}}></div>
                                        <div className="box-text">{item?.name || '-'}</div>
                                    </div>
                                ))}
                            </Col>

                            {dataCompare?.data?.name !== 'ความเร็ว (m/s)' &&
                                dataCompare?.data?.name !== 'Speed (m/s)' &&
                                dataCompare?.data?.name !== 'Height (m)' &&
                                dataCompare?.data?.name !== 'ความสูง (m)' && (
                                    <Col xl={6}>
                                        <ChartResultAreaData
                                            title={dataCompare?.data?.name}
                                            labels={labels}
                                            series={dataCompare?.data?.data}
                                            yaxisTitle={dataCompare?.data?.name}
                                            unit={dataCompare?.data?.unit}
                                        />
                                    </Col>
                                )}
                            {dataCompare?.dataAverage?.name === 'ความเร็ว เฉลี่ย (m/s)' ||
                            dataCompare?.dataAverage?.name === 'Speed average (m/s)' ||
                            dataCompare?.dataAverage?.name === 'Height average (m)' ||
                            dataCompare?.dataAverage?.name === 'ความสูง เฉลี่ย (m)' ? (
                                <Col xl={12}>
                                    <ChartResultAreaData
                                        title={dataCompare?.dataAverage?.name}
                                        labels={labels}
                                        series={dataCompare?.dataAverage?.data}
                                        yaxisTitle={dataCompare?.dataAverage?.name}
                                        unit={dataCompare?.dataAverage?.unit}
                                    />
                                </Col>
                            ) : (
                                <Col xl={6}>
                                    <ChartResultAreaData
                                        title={dataCompare?.dataAverage?.name}
                                        labels={labels}
                                        series={dataCompare?.dataAverage?.data}
                                        yaxisTitle={dataCompare?.dataAverage?.name}
                                        unit={dataCompare?.dataAverage?.unit}
                                    />
                                </Col>
                            )}
                        </Row>
                    </CardBody>
                </Card>
            )}
        </Spin>
    )
}

export default withTranslation()(ComparisonResultTime)
