import React, {useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {Row, Col, Button, Card, CardBody, CardTitle} from 'reactstrap'

import CardAverage from './CardAverage'
import CardResultCompare from './CardResultCompare'

import ResultAreaSkeleton from 'components/DroneiNalyze/Skeleton-iNalyze/Comparison/FormArea/ResultAreaSkeleton'
import CardAverageSkeleton from 'components/DroneiNalyze/Skeleton-iNalyze/Comparison/FormArea/CardAverageSkeleton'
import {useRecoilState, useRecoilValue} from 'recoil'
import {formCompareAreaState, loadingInalyzeState} from 'store/atoms/inalyze'
import {getCompareAreaData, getCompareAreaGraph} from 'services/skc/inalyze'

const ComparisonResultArea = props => {
    const {t, i18n} = props
    const formCompareArea = useRecoilValue(formCompareAreaState)
    const [loading, setLoading] = useRecoilState(loadingInalyzeState)

    const [droneListsButton, setDroneListsButton] = useState([])
    const [activeTab, setActiveTab] = useState(null)
    const [dataCompare, setDataCompare] = useState([])
    const [flyHour, setFlyHour] = useState([])
    const [flyAmount, setFlyAmount] = useState([])
    const [amountWorking, setAmountWorking] = useState([])
    const [workingArea, setWorkingArea] = useState([])
    const [amountWorkingAverage, setAmountWorkingAverage] = useState([])
    const [workingAreaAverage, setWorkingAreaAverage] = useState([])
    const [flyHourAverage, setFlyHourAverage] = useState([])
    const [flyAmountAverage, setFlyAmountAverage] = useState([])

    const [sprayingAmountAverage, setSprayingAmountAverage] = useState([])

    const [totalSpeedAverage, setTotalSpeedAverage] = useState([])

    const [totalHeightAverage, setTotalHeightAverage] = useState([])

    const [language, setLanguage] = useState(i18n.language)

    const [loadingSkeletonData, setLoadingSkeletonData] = useState(false)
    const [loadingCompareFlyHour, setLoadingCompareFlyHour] = useState(false)
    const [loadingCompareFlyHourAverage, setLoadingCompareFlyHourAverage] = useState(false)
    const [loadingCompareAmountWorking, setLoadingCompareAmountWorking] = useState(false)
    const [loadingCompareAmountWorkingAverage, setLoadingCompareAmountWorkingAverage] = useState(false)
    const [loadingCompareWorkingArea, setLoadingCompareWorkingArea] = useState(false)
    const [loadingCompareWorkingAreaAverage, setLoadingCompareWorkingAreaAverage] = useState(false)
    const [loadingCompareFlyAmount, setLoadingCompareFlyAmount] = useState(false)
    const [loadingCompareFlyAmountAverage, setLoadingCompareFlyAmountAverage] = useState(false)
    const [loadingCompareSprayingAmountAverage, setLoadingCompareSprayingAmountAverage] = useState(false)
    const [loadingCompareTotalSpeedAverage, setLoadingCompareTotalSpeedAverage] = useState(false)
    const [loadingCompareTotalHeightAverage, setLoadingCompareTotalHeightAverage] = useState(false)

    const state = {}

    useEffect(() => {
        if (loading.compare_area) {
            setDroneListBtn()
            fetchData()
        }
    }, [loading.compare_area])

    useEffect(() => {
        if (i18n.language !== language) {
            setDroneListBtn()
            fetchData()
            setLanguage(i18n.language)
        }
    }, [i18n.language])

    const fetchData = async () => {
        fetchCompareAreaData(formCompareArea)
        fetchCompareAreaFlyHour(formCompareArea, true)
        fetchCompareAreaFlyHour(formCompareArea, false)
        fetchCompareAreaAmountWorking(formCompareArea, true)
        fetchCompareAreaAmountWorking(formCompareArea, false)
        fetchCompareAreaWorkingArea(formCompareArea, true)
        fetchCompareAreaWorkingArea(formCompareArea, false)
        fetchCompareAreaFlyAmount(formCompareArea, true)
        fetchCompareAreaFlyAmount(formCompareArea, false)
        fetchCompareSprayingAmountAverage(formCompareArea)
        fetchCompareTotalSpeedAverage(formCompareArea)
        await fetchCompareTotalHeightAverage(formCompareArea)
        setLoading(cur => ({...cur, compare_area: false}))
    }
    const handleToggleTab = data => {
        setActiveTab(data)
        // this.setState({activeTab: data})

        if (data === 'all') {
            fetchCompareAreaData(formCompareArea)
            fetchCompareAreaFlyHour(formCompareArea, true)
            fetchCompareAreaFlyHour(formCompareArea, false)
            fetchCompareAreaAmountWorking(formCompareArea, true)
            fetchCompareAreaAmountWorking(formCompareArea, false)
            fetchCompareAreaWorkingArea(formCompareArea, true)
            fetchCompareAreaWorkingArea(formCompareArea, false)
            fetchCompareAreaFlyAmount(formCompareArea, true)
            fetchCompareAreaFlyAmount(formCompareArea, false)
            fetchCompareSprayingAmountAverage(formCompareArea)
            fetchCompareTotalSpeedAverage(formCompareArea)
            fetchCompareTotalHeightAverage(formCompareArea)
        } else {
            fetchCompareAreaData({...formCompareArea, drone: [data]})
            fetchCompareAreaFlyHour({...formCompareArea, drone: [data]}, true)
            fetchCompareAreaFlyHour({...formCompareArea, drone: [data]}, false)
            fetchCompareAreaAmountWorking({...formCompareArea, drone: [data]}, true)
            fetchCompareAreaAmountWorking({...formCompareArea, drone: [data]}, false)
            fetchCompareAreaWorkingArea({...formCompareArea, drone: [data]}, true)
            fetchCompareAreaWorkingArea({...formCompareArea, drone: [data]}, false)
            fetchCompareAreaFlyAmount({...formCompareArea, drone: [data]}, true)
            fetchCompareAreaFlyAmount({...formCompareArea, drone: [data]}, false)
            fetchCompareSprayingAmountAverage({...formCompareArea, drone: [data]})
            fetchCompareTotalSpeedAverage({...formCompareArea, drone: [data]})
            fetchCompareTotalHeightAverage({...formCompareArea, drone: [data]})
        }
    }

    const setDroneListBtn = () => {
        const dataDrone = [...formCompareArea.droneBtn]

        const droneLists = [{label: t('common.all'), value: 'all'}]

        if (dataDrone.length > 1) {
            dataDrone?.forEach(itm => {
                const btnItem = {label: itm.label, value: itm.value}
                droneLists.push(btnItem)
            })

            setDroneListsButton(droneLists)
            setActiveTab(droneLists[0].value)
        } else {
            const droneListsButton = []
            dataDrone?.forEach(itm => {
                const btnItem = {label: itm.label, value: itm.value}

                droneListsButton.push(btnItem)
            })

            setDroneListsButton(droneListsButton)
            setActiveTab(droneListsButton[0].value)
        }
    }

    const fetchCompareAreaData = async params => {
        try {
            setLoadingSkeletonData(true)

            const results = await getCompareAreaData(params)

            if (results.status_code === 200 && results.results.data.length > 0) {
                const data = [...results.results.data]

                const labels = [...results?.results?.area_labels]
                const type = formCompareArea.type
                setDataCardResultCompare(data, type, labels)
            }
        } catch (error) {
            setDataCompare([])
        } finally {
            setLoadingSkeletonData(false)
        }
    }

    const fetchCompareAreaFlyHour = async (params, average) => {
        try {
            if (average) {
                setLoadingCompareFlyHourAverage(true)

                const response = await getCompareAreaGraph(params, 'fly_hour', true)

                if (response.status_code === 200 && response?.results?.data?.length > 0) {
                    const data = response?.results
                    const type = formCompareArea.timeType
                    const results = setDataAverageResult(data, type)
                    setFlyHourAverage(results)
                }
            } else {
                setLoadingCompareFlyHour(true)
                const response = await getCompareAreaGraph(params, 'fly_hour', '')

                if (response.status_code === 200 && response?.results?.data?.length > 0) {
                    const data = response?.results
                    const type = formCompareArea.timeType
                    const results = setDataAverageResult(data, type)
                    setFlyHour(results)
                }
            }
        } catch (error) {
            setFlyHour([])
            setFlyHourAverage([])
        } finally {
            if (average) {
                setLoadingCompareFlyHourAverage(false)
            } else {
                setLoadingCompareFlyHour(false)
            }
        }
    }

    const fetchCompareAreaAmountWorking = async (params, average) => {
        try {
            if (average) {
                setLoadingCompareAmountWorkingAverage(true)

                const response = await getCompareAreaGraph(params, 'amount_working', true)

                if (response.status_code === 200 && response?.results?.data?.length > 0) {
                    const data = response?.results
                    const type = formCompareArea.timeType
                    const results = setDataAverageResult(data, type)
                    setAmountWorkingAverage(results)
                }
            } else {
                setLoadingCompareAmountWorking(true)
                const response = await getCompareAreaGraph(params, 'amount_working', '')

                if (response.status_code === 200 && response?.results?.data?.length > 0) {
                    const data = response?.results
                    const type = formCompareArea.timeType
                    const results = setDataAverageResult(data, type)
                    setAmountWorking(results)
                }
            }
        } catch (error) {
            setAmountWorking([])
            setAmountWorkingAverage([])
        } finally {
            if (average) {
                setLoadingCompareAmountWorkingAverage(false)
            } else {
                setLoadingCompareAmountWorking(false)
            }
        }
    }

    const fetchCompareAreaWorkingArea = async (params, average) => {
        try {
            if (average) {
                setLoadingCompareWorkingAreaAverage(true)

                const response = await getCompareAreaGraph(params, 'working_area', true)

                if (response.status_code === 200 && response?.results?.data?.length > 0) {
                    const data = response?.results
                    const type = formCompareArea.timeType
                    const results = setDataAverageResult(data, type)
                    setWorkingAreaAverage(results)
                }
            } else {
                setLoadingCompareWorkingArea(true)
                const response = await getCompareAreaGraph(params, 'working_area', '')

                if (response.status_code === 200 && response?.results?.data?.length > 0) {
                    const data = response?.results
                    const type = formCompareArea.timeType
                    const results = setDataAverageResult(data, type)
                    setWorkingArea(results)
                }
            }
        } catch (error) {
            setWorkingArea([])
            setWorkingAreaAverage([])
        } finally {
            if (average) {
                setLoadingCompareWorkingAreaAverage(false)
            } else {
                setLoadingCompareWorkingArea(false)
            }
        }
    }

    const fetchCompareAreaFlyAmount = async (params, average) => {
        try {
            if (average) {
                setLoadingCompareFlyAmountAverage(true)

                const response = await getCompareAreaGraph(params, 'fly_amount', true)

                if (response.status_code === 200 && response?.results?.data?.length > 0) {
                    const data = response?.results
                    const type = formCompareArea.timeType
                    const results = setDataAverageResult(data, type)
                    setFlyAmountAverage(results)
                }
            } else {
                setLoadingCompareFlyAmount(true)
                const response = await getCompareAreaGraph(params, 'fly_amount', '')

                if (response.status_code === 200 && response?.results?.data?.length > 0) {
                    const data = response?.results
                    const type = formCompareArea.timeType
                    const results = setDataAverageResult(data, type)
                    setFlyAmount(results)
                }
            }
        } catch (error) {
            setFlyAmount([])
            setFlyAmountAverage([])
        } finally {
            if (average) {
                setLoadingCompareFlyAmountAverage(false)
            } else {
                setLoadingCompareFlyAmount(false)
            }
        }
    }

    const fetchCompareSprayingAmountAverage = async params => {
        try {
            setLoadingCompareSprayingAmountAverage(true)

            const response = await getCompareAreaGraph(params, 'spraying', true)

            if (response.status_code === 200 && response?.results?.data?.length > 0) {
                const data = response?.results
                const type = formCompareArea.timeType
                const results = setDataAverageResult(data, type)
                setSprayingAmountAverage(results)
            }
        } catch (error) {
            setSprayingAmountAverage([])
        } finally {
            setLoadingCompareSprayingAmountAverage(false)
        }
    }

    const fetchCompareTotalSpeedAverage = async params => {
        try {
            setLoadingCompareTotalSpeedAverage(true)

            const response = await getCompareAreaGraph(params, 'velocity', true)

            if (response.status_code === 200 && response?.results?.data?.length > 0) {
                const data = response?.results
                const type = formCompareArea.timeType
                const results = setDataAverageResult(data, type)
                setTotalSpeedAverage(results)
            }
        } catch (error) {
            setTotalSpeedAverage([])
        } finally {
            setLoadingCompareTotalSpeedAverage(false)
        }
    }

    const fetchCompareTotalHeightAverage = async params => {
        try {
            setLoadingCompareTotalHeightAverage(true)

            const response = await getCompareAreaGraph(params, 'height', true)

            if (response.status_code === 200 && response?.results?.data?.length > 0) {
                const data = response?.results
                const type = formCompareArea.timeType
                const results = setDataAverageResult(data, type)
                setTotalHeightAverage(results)
            }
        } catch (error) {
            setTotalHeightAverage([])
        } finally {
            setLoadingCompareTotalHeightAverage(false)
        }
    }

    const setDataCardResultCompare = (data, type, labels) => {
        const newData = data?.map(itm => {
            return {
                id: itm.id,
                type: type,
                data: itm?.data_area.map(itemData => {
                    return {
                        title: itemData?.name,
                        data: itemData?.data.map(itemResult => {
                            return {
                                ...itemResult,
                                label: labels,
                            }
                        }),
                    }
                }),
            }
        })

        setDataCompare(newData)
    }

    const setDataAverageResult = (data, type) => {
        const newData = data?.data?.map(itm => {
            return {
                id: itm.id,
                type: type,
                message: data?.message,
                labels: data?.area_labels,
                data: itm?.data_area.map(itemData => {
                    return {
                        title: itemData?.name,
                        data: itemData?.data,
                        total: itemData?.total,
                        id: itemData?.id,
                    }
                }),
            }
        })

        return newData
    }

    return (
        <Row>
            {loadingSkeletonData ? (
                <>
                    <ResultAreaSkeleton btnSkeleton={droneListsButton} compareAreaForm={formCompareArea} />
                </>
            ) : (
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} className="container-header">
                        <p className="text-header">{t('inalyze.comparisonSelectionResult')}</p>
                    </Col>
                    <Col xl={12} lg={12}>
                        <Card>
                            <CardTitle className="container-btn">
                                {droneListsButton?.map(item => {
                                    return (
                                        <Button
                                            key={item?.value}
                                            className="btn-select-drone"
                                            outline
                                            style={{
                                                fontFamily: item?.value === 'all' ? 'Kanit' : 'Inter',
                                                backgroundColor: activeTab === item.value ? '#f05a28' : '#fff5f2',
                                                borderColor: activeTab === item.value ? '#f05a28' : '#fff5f2',
                                                color: activeTab === item.value ? '#fff5f2' : '#f05a28',
                                                letterSpacing: item.value === 'all' ? '1px' : '',
                                            }}
                                            onClick={() => handleToggleTab(item.value)}>
                                            {item.label}
                                        </Button>
                                    )
                                })}
                            </CardTitle>

                            <CardBody>
                                <Row>
                                    {dataCompare?.map((itm, index) => {
                                        return (
                                            <div key={itm.id}>
                                                {activeTab === itm?.id && (
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Row>
                                                            {itm?.data?.map((itemData, indexData) => (
                                                                <Col xs={6} sm={6} md={6} lg={6} xl={6} key={indexData}>
                                                                    <CardResultCompare
                                                                        data={itemData}
                                                                        index={indexData + 1}
                                                                        type={itm.type}
                                                                    />
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </Col>
                                                )}
                                            </div>
                                        )
                                    })}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}

            <Col lg={6} xl={6}>
                {loadingCompareFlyHour ? (
                    <CardAverageSkeleton />
                ) : (
                    <>
                        {flyHour?.map(flyHour => (
                            <div key={flyHour.id}>
                                {activeTab === flyHour.id && (
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CardAverage
                                            title={t('inalyze.totalFlightTime')}
                                            yaxisText={t('inalyze.totalFlightTimeHrUnit')}
                                            labels={[...flyHour?.labels]}
                                            type={flyHour?.type}
                                            data={flyHour?.data}
                                            series={flyHour?.data}
                                            unit={t('common.hourUnit')}
                                        />
                                    </Col>
                                )}
                            </div>
                        ))}
                    </>
                )}
            </Col>
            <Col lg={6} xl={6}>
                {loadingCompareFlyHourAverage ? (
                    <CardAverageSkeleton />
                ) : (
                    <>
                        {flyHourAverage?.map(flyHourAverage => (
                            <div key={flyHourAverage.id}>
                                {activeTab === flyHourAverage.id && (
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CardAverage
                                            title={t('inalyze.averageFlightTime')}
                                            yaxisText={t('inalyze.averageFlightTimeHrDroneUnit')}
                                            labels={[...flyHourAverage?.labels]}
                                            type={flyHourAverage?.type}
                                            data={flyHourAverage?.data}
                                            series={flyHourAverage?.data}
                                            unit={t('common.hourUnitDroneUnit')}
                                        />
                                    </Col>
                                )}
                            </div>
                        ))}
                    </>
                )}
            </Col>
            <Col lg={6} xl={6}>
                {loadingCompareAmountWorking ? (
                    <CardAverageSkeleton />
                ) : (
                    <>
                        {amountWorking?.map(amountWorking => (
                            <div key={amountWorking.id}>
                                {activeTab === amountWorking.id && (
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CardAverage
                                            title={t('inalyze.workingDay')}
                                            yaxisText={t('inalyze.workingDayDayUnit')}
                                            labels={[...amountWorking?.labels]}
                                            type={amountWorking?.type}
                                            data={amountWorking?.data}
                                            series={amountWorking?.data}
                                            unit={t('common.dayUnit')}
                                        />
                                    </Col>
                                )}
                            </div>
                        ))}
                    </>
                )}
            </Col>
            <Col lg={6} xl={6}>
                {loadingCompareAmountWorkingAverage ? (
                    <CardAverageSkeleton />
                ) : (
                    <>
                        {amountWorkingAverage?.map(amountWorkingAverage => (
                            <div key={amountWorkingAverage.id}>
                                {activeTab === amountWorkingAverage.id && (
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CardAverage
                                            title={t('inalyze.averageWorkingDays')}
                                            yaxisText={t('inalyze.averageWorkingDaysHourDayUnit')}
                                            labels={[...amountWorkingAverage?.labels]}
                                            type={amountWorkingAverage?.type}
                                            data={amountWorkingAverage?.data}
                                            series={amountWorkingAverage?.data}
                                            unit={t('common.HourUnitDayUnit')}
                                        />
                                    </Col>
                                )}
                            </div>
                        ))}
                    </>
                )}
            </Col>
            <Col lg={6} xl={6}>
                {loadingCompareWorkingArea ? (
                    <CardAverageSkeleton />
                ) : (
                    <>
                        {workingArea?.map(workingArea => (
                            <div key={workingArea.id}>
                                {activeTab === workingArea.id && (
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CardAverage
                                            title={t('inalyze.totalWorkingAreas')}
                                            yaxisText={t('inalyze.totalWorkingAreasHourUnit')}
                                            labels={[...workingArea?.labels]}
                                            type={workingArea?.type}
                                            data={workingArea?.data}
                                            series={workingArea?.data}
                                            unit={t('common.raiUnit')}
                                        />
                                    </Col>
                                )}
                            </div>
                        ))}
                    </>
                )}
            </Col>
            <Col lg={6} xl={6}>
                {loadingCompareWorkingAreaAverage ? (
                    <CardAverageSkeleton />
                ) : (
                    <>
                        {workingAreaAverage?.map(workingAreaAverage => (
                            <div key={workingAreaAverage.id}>
                                {activeTab === workingAreaAverage.id && (
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CardAverage
                                            title={t('inalyze.averageWorkingAreas')}
                                            yaxisText={t('inalyze.averageWorkingAreasRaiDroneUnit')}
                                            labels={[...workingAreaAverage?.labels]}
                                            type={workingAreaAverage?.type}
                                            data={workingAreaAverage?.data}
                                            series={workingAreaAverage?.data}
                                            unit={t('common.raiUnitDroneUnit')}
                                        />
                                    </Col>
                                )}
                            </div>
                        ))}
                    </>
                )}
            </Col>
            <Col lg={6} xl={6}>
                {loadingCompareFlyAmount ? (
                    <CardAverageSkeleton />
                ) : (
                    <>
                        {flyAmount?.map(flyAmount => (
                            <div key={flyAmount.id}>
                                {activeTab === flyAmount.id && (
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CardAverage
                                            title={t('inalyze.totalNumberOfFlights')}
                                            yaxisText={t('inalyze.totalNumberOfFlightsFlightUnit')}
                                            labels={[...flyAmount?.labels]}
                                            type={flyAmount?.type}
                                            data={flyAmount?.data}
                                            series={flyAmount?.data}
                                            unit={t('common.flightUnit')}
                                        />
                                    </Col>
                                )}
                            </div>
                        ))}
                    </>
                )}
            </Col>
            <Col lg={6} xl={6}>
                {loadingCompareFlyAmountAverage ? (
                    <CardAverageSkeleton />
                ) : (
                    <>
                        {flyAmountAverage?.map(flyAmountAverage => (
                            <div key={flyAmountAverage.id}>
                                {activeTab === flyAmountAverage.id && (
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CardAverage
                                            title={t('inalyze.averageNumberOfFlights')}
                                            yaxisText={t('inalyze.averageNumberOfFlightsTimeDroneUnit')}
                                            labels={[...flyAmountAverage?.labels]}
                                            type={flyAmountAverage?.type}
                                            data={flyAmountAverage?.data}
                                            series={flyAmountAverage?.data}
                                            unit={t('common.timeDroneUnit')}
                                        />
                                    </Col>
                                )}
                            </div>
                        ))}
                    </>
                )}
            </Col>
            <Col lg={12} xl={12}>
                {loadingCompareSprayingAmountAverage ? (
                    <CardAverageSkeleton />
                ) : (
                    <>
                        {sprayingAmountAverage?.map(sprayingAmountAverage => (
                            <div key={sprayingAmountAverage.id}>
                                {activeTab === sprayingAmountAverage.id && (
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CardAverage
                                            title={t('inalyze.averageSprayingQuantity')}
                                            yaxisText={t('inalyze.averageSprayingQuantityLiterRaiUnit')}
                                            labels={[...sprayingAmountAverage?.labels]}
                                            type={sprayingAmountAverage?.type}
                                            data={sprayingAmountAverage?.data}
                                            series={sprayingAmountAverage?.data}
                                            unit={t('common.literRaiUnit')}
                                        />
                                    </Col>
                                )}
                            </div>
                        ))}
                    </>
                )}
            </Col>

            <Col lg={12} xl={12}>
                {loadingCompareTotalSpeedAverage ? (
                    <CardAverageSkeleton />
                ) : (
                    <>
                        {totalSpeedAverage?.map(totalSpeedAverage => (
                            <div key={totalSpeedAverage.id}>
                                {activeTab === totalSpeedAverage.id && (
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CardAverage
                                            title={t('inalyze.averageSpeed')}
                                            yaxisText={t('inalyze.averageSpeedMsUnit')}
                                            labels={[...totalSpeedAverage?.labels]}
                                            type={totalSpeedAverage?.type}
                                            data={totalSpeedAverage?.data}
                                            series={totalSpeedAverage?.data}
                                            unit={t('common.msUnit')}
                                        />
                                    </Col>
                                )}
                            </div>
                        ))}
                    </>
                )}
            </Col>

            <Col lg={12} xl={12}>
                {loadingCompareTotalHeightAverage ? (
                    <CardAverageSkeleton />
                ) : (
                    <>
                        {totalHeightAverage?.map(totalHeightAverage => (
                            <div key={totalHeightAverage.id}>
                                {activeTab === totalHeightAverage.id && (
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CardAverage
                                            title={t('inalyze.averageHeight')}
                                            yaxisText={t('inalyze.averageHeightMUnit')}
                                            labels={[...totalHeightAverage?.labels]}
                                            type={totalHeightAverage?.type}
                                            data={totalHeightAverage?.data}
                                            series={totalHeightAverage?.data}
                                            unit={t('common.mUnit')}
                                        />
                                    </Col>
                                )}
                            </div>
                        ))}
                    </>
                )}
            </Col>
        </Row>
    )
}

export default withTranslation()(ComparisonResultArea)
