import styled from 'styled-components'
export const Section = styled.label`
    padding: 5px 10px 0px 0px;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    color: var(--495057, #495057);
`

export const ButtonSubmit = styled.button`
    border-style: unset;
    color: #fff;
    font-family: Kanit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border-radius: 8px;
    background-color: #00a69c;
    margin-left: 15px;
    padding: 7px 20px 7px 20px;
`

export const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    // padding-top: 10px;
`
export const SelectWrapperII = styled.div`
    display: flex;
    align-items: center;
    padding-top: 17px;
`
export const SelectWrapperIII = styled.div`
    display: flex;
    align-items: start;
`
export const SelectButton = styled.button`
    font-family: Kanit;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    border-color: #e2e2e2;
    border-radius: 8px;
    margin-right: 15px;
    border: 1px solid #e2e2e2;
    padding: 7px 20px 7px 20px;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    &:hover {
        background-color: #79829c;
        color: #fff;
    }
`
export const DivTitle = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`
export const H3 = styled.h3`
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    color: #000000;
    font-family: Inter, sans-serif;
`
