import { takeEvery, put, call } from "redux-saga/effects";

// Chat Redux States
import { GET_SPEED_DRONE_ACTIVE } from "./actionTypes";
import { getSpeedDroneActiveSuccess, getSpeedDroneActiveFail } from "../actions";

//Include Both Helper File with needed methods
import { getSpeedHeightDroneActive } from "../../services/skc/kafka";

function* onGetSpeedDroneActive(action) {
  try {
    const response = yield call(getSpeedHeightDroneActive, action.payload);

    yield put(getSpeedDroneActiveSuccess(response));
  } catch (error) {
    yield put(getSpeedDroneActiveFail(error));
  }
}

function* speedDroneActiveSaga() {
  yield takeEvery(GET_SPEED_DRONE_ACTIVE, onGetSpeedDroneActive);
}

export default speedDroneActiveSaga;
