import React, {useState} from 'react'
import {Row, Col, Card, CardBody, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap'
import {withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import FormArea from './FormArea'
import FormTime from './FormTime'
import ComparisonResultArea from './ComparisonResultArea'
import ComparisonResultTime from './ComparisonResultTime'

import {compareButton} from 'constants/lookup'
import './index.scss'

const FormComparison = props => {
    const {t, i18n} = props

    const [selectType, setSelectType] = useState('area')
    const [searchArea, setSearchArea] = useState(false)
    const [searchTime, setSearchTime] = useState(false)

    const onChangeType = type => {
        setSelectType(type)
        setSearchArea(false)
        setSearchTime(false)
    }

    const onClickSearchArea = () => {
        setSearchArea(true)
    }

    const onClickSearchTime = () => {
        setSearchTime(true)
    }

    const onClickClearSearchArea = () => {
        setSearchArea(false)
    }

    const onClickClearSearchTime = () => {
        setSearchTime(false)
    }

    return (
        <>
            <Col xl={12}>
                <div className="box-head">
                    <p className="text-head">{t('inalyze.comparisonOfDataByAreaAndTimePeriod')}</p>
                </div>
            </Col>
            <Col xl={12}>
                <Card>
                    <CardTitle className="container-title">
                        <Nav tabs className="tab-compare">
                            <>
                                {/* <NavItem className="container-tap">
                                    <Skeleton width={194} height={47} style={{marginRight: 16}} />
                                </NavItem>
                                <NavItem className="container-tap">
                                    <Skeleton width={221} height={47} />
                                </NavItem> */}

                                {compareButton?.map(itm => (
                                    <NavItem
                                        className="container-tab"
                                        key={itm.value}
                                        onClick={() => onChangeType(itm.value)}>
                                        <NavLink className={`${selectType === itm.value && 'active-tab'}`}>
                                            <span>{i18n.language === 'en' ? itm.title_en : itm.title_th}</span>
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </>
                        </Nav>
                    </CardTitle>
                    <CardBody>
                        <Row className="container-form">
                            <TabContent activeTab={selectType} className="text-muted">
                                <TabPane tabId="area">
                                    <FormArea
                                        onClickSearchArea={onClickSearchArea}
                                        onClickClearSearchArea={onClickClearSearchArea}
                                    />
                                </TabPane>
                                <TabPane tabId="time">
                                    <FormTime
                                        onClickSearchTime={onClickSearchTime}
                                        onClickClearSearchTime={onClickClearSearchTime}
                                    />
                                </TabPane>
                            </TabContent>
                        </Row>
                    </CardBody>
                </Card>
            </Col>

            <Col xl={12}>
                <Row className="container-result">
                    <TabContent activeTab={selectType} className="text-muted">
                        <TabPane tabId="area">{searchArea && <ComparisonResultArea />}</TabPane>
                        <TabPane tabId="time">{searchTime && <ComparisonResultTime />}</TabPane>
                    </TabContent>
                </Row>
            </Col>
        </>
    )
}

export default withTranslation()(FormComparison)
