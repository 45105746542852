import { LOADING_DRONE_ACTIVE, LOADING_DRONE_ACTIVE_FAIL, LOADING_DRONE_ACTIVE_SUCCESS } from "./actionTypes";

export const loadingDroneActive = data => ({
  type: LOADING_DRONE_ACTIVE,
  payload: data,
});

export const loadingDroneActiveSuccess = data => ({
  type: LOADING_DRONE_ACTIVE_SUCCESS,
  payload: data,
});

export const loadingDroneActiveFail = error => ({
  type: LOADING_DRONE_ACTIVE_FAIL,
  payload: error,
});
