import React, {useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {Col, Card, CardBody, CardTitle, TabContent, TabPane, Row} from 'reactstrap'
import {Section, ButtonSubmit, SelectWrapper, SelectWrapperII, SelectButton, DivTitle, H3} from '../component/MyStyle'

import {Select} from 'antd'
const {Option} = Select

import './component/style.css'
import 'apexcharts/dist/apexcharts.css'
import './component/style.scss'
import './style.scss'

import dayjs from 'dayjs'
import 'dayjs/locale/th'

import WeekPicker from '../component/weekPicker'
import MonthPicker from '../component/monthPicker'
import YearPicker from '../component/yearPicker'
import QuarterPicker from '../component/quarterPicker'
import HalfYearPicker from '../component/HalfYearPicker'
import CustomPicker from '../component/customPicker'
import OptionScatter from './component/OptionScatter'
import ScatterSkeleton from '../CardSkeleton/ScatterSkeleton'

import {
    buttonFilterTimeRange,
    optionsCountry,
    selectOptionCustomerSegmentation,
    selectOptionDataType,
} from 'constants/lookup'

import {getFilterDrone, getFilterRegion, getProvince} from 'services/skc/lookup'
import {getUserInalyzeScatterCustomer} from 'services/skc/user-inalyze'

let quarterOfYear = require('dayjs/plugin/quarterOfYear')

dayjs.extend(quarterOfYear)

const ScatterCustomerUserInalyze = props => {
    const {t, i18n} = props

    const [activeTab, setActiveTab] = useState('year')
    const [loadingSkeleton, setLoadingSkeleton] = useState(false)
    const [language, setLanguage] = useState(i18n.language)

    const [date, setDate] = useState(dayjs())
    const [dateDynamic, setDateDynamic] = useState([])

    const [selectHalfYear, setSelectHalfYear] = useState(1)
    const [dataValue, setDataValue] = useState('drone')
    const [multipleValue, setMultipleValue] = useState([])
    const [selectY, setSelectY] = useState('working_area')
    const [selectX, setSelectX] = useState('fly_amount')

    const [lutDrones, setLutDrones] = useState([])
    const [lutProvince, setLutProvince] = useState([])
    const [lutRegion, setLutRegion] = useState([])
    const [lutOptionX, setLutOptionX] = useState(selectOptionCustomerSegmentation)
    const [lutOptionY, setLutOptionY] = useState(selectOptionCustomerSegmentation)

    const [dataScatter, setDataScatter] = useState({})
    const [maxXValue, setMaxXValue] = useState(null)
    const [maxYValue, setMaxYValue] = useState(null)

    useEffect(() => {
        fetchLutDrone()
        fetchLutRegion()
        fetchProvince()
    }, [])

    useEffect(() => {
        if (language !== i18n.language) {
            let params = {
                type: activeTab,
                start_date: '',
                end_date: '',
                year: dayjs(date).year(),
                quarter: '',
                halfYear: 0,
                month: 0,
                week: 0,
                y: selectY,
                x: selectX,
            }

            if (dataValue === 'drone') {
                params = {
                    ...params,

                    drone: multipleValue?.length > 0 ? multipleValue : 'all',
                }
            }

            if (dataValue === 'region') {
                params = {
                    ...params,

                    region: multipleValue?.length > 0 ? multipleValue : 'all',
                }
            }

            if (dataValue === 'province') {
                params = {
                    ...params,

                    province: multipleValue?.length > 0 ? multipleValue : 'all',
                }
            }

            if (dataValue === 'country') {
                params = {
                    ...params,

                    country: multipleValue?.length > 0 ? multipleValue : 'all',
                }
            }

            fetchCustomerSegmentation(params)
            setLanguage(i18n.language)
        }
    }, [i18n.language])

    useEffect(() => {
        const params = {
            type: 'year',
            start_date: '',
            end_date: '',
            year: dayjs(date).year(),
            quarter: '',
            halfYear: 0,
            month: 0,
            week: 0,
            y: 'working_area',
            x: 'fly_amount',
            drone: 'all',
        }
        fetchCustomerSegmentation(params)
    }, [])

    useEffect(() => {
        if (dataScatter.series?.length > 0) {
            setMaxYMaxXValue()
        }
    }, [dataScatter])

    useEffect(() => {
        if (i18n.language !== language) {
            handleChangeSubmit()
            setLanguage(i18n.language)
        }
    }, [i18n.language])

    const fetchCustomerSegmentation = async params => {
        try {
            setLoadingSkeleton(true)

            const response = await getUserInalyzeScatterCustomer(params)

            if (response.status_code === 200 && response?.results) {
                setDataScatter(response.results)
            }
        } catch (error) {
            setDataScatter({})
        } finally {
            setLoadingSkeleton(false)
        }
    }

    const fetchLutDrone = async () => {
        try {
            const response = await getFilterDrone()

            if (response.status_code === 200 && response.results.length > 0) {
                setLutDrones(response.results)
            }
        } catch (error) {
            setLutDrones([])
        }
    }

    const fetchLutRegion = async () => {
        try {
            const response = await getFilterRegion()

            if (response.status_code === 200 && response.results.length > 0) {
                setLutRegion(response.results)
            }
        } catch (error) {
            setLutRegion([])
        }
    }

    const fetchProvince = async () => {
        try {
            const response = await getProvince()

            if (response.status_code === 200 && response.results.length > 0) {
                setLutProvince(response.results)
            }
        } catch (error) {
            setLutProvince([])
        }
    }

    const toggleTab = tab => {
        setActiveTab(tab)
    }

    const handleChangeTime = date => {
        if (activeTab === 'period') {
            setDateDynamic(date)
        } else {
            setDate(date)
        }
    }

    const handleHalfYearChange = option => {
        setSelectHalfYear(option.value)
    }

    const onChangeData = value => {
        setDataValue(value)
        setMultipleValue([])
    }

    const handleChangeMultiple = value => {
        setMultipleValue(value)
    }

    const handleChangeSelectY = value => {
        const optionX = []
        const selectX = selectOptionCustomerSegmentation.find(item => item.value !== value).value

        selectOptionCustomerSegmentation.forEach(item => {
            if (item.value === value) {
                optionX.push({...item, disabled: true})
            } else {
                optionX.push(item)
            }
        })

        setSelectY(value)

        setLutOptionX(optionX)

        setSelectX(selectX)
    }

    const handleChangeSelectX = value => {
        const optionY = []
        const selectY = selectOptionCustomerSegmentation.find(item => item.value !== value).value

        selectOptionCustomerSegmentation.forEach(item => {
            if (item.value === value) {
                optionY.push({...item, disabled: true})
            } else {
                optionY.push(item)
            }
        })
        setSelectX(value)

        setLutOptionY(optionY)
        setSelectX(selectY)
    }

    const handleChangeSubmit = () => {
        let params = {
            type: activeTab,
            x: selectX,
            y: selectY,
            week: dayjs(date).week(),
            month: dayjs(date).month() + 1,
            year: dayjs(date).year(),
            quarter: 'Q' + dayjs(date).quarter(),
            halfYear: selectHalfYear,
        }

        if (activeTab === 'year') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('year').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('year').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'month') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('month').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('month').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'week') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('week').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('week').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'quarter') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('quarter').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('quarter').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'period') {
            params = {
                ...params,

                start_date: dayjs(dateDynamic[0]).format('YYYY-MM-DD'),
                end_date: dayjs(dateDynamic[1]).format('YYYY-MM-DD'),
            }
        }

        if (dataValue === 'drone') {
            params = {
                ...params,

                drone: multipleValue?.length > 0 ? multipleValue : 'all',
            }
        }

        if (dataValue === 'region') {
            params = {
                ...params,

                region: multipleValue?.length > 0 ? multipleValue : 'all',
            }
        }

        if (dataValue === 'province') {
            params = {
                ...params,

                province: multipleValue?.length > 0 ? multipleValue : 'all',
            }
        }

        if (dataValue === 'country') {
            params = {
                ...params,

                country: multipleValue?.length > 0 ? multipleValue : 'all',
            }
        }

        fetchCustomerSegmentation(params)
    }

    const setMaxYMaxXValue = () => {
        for (let i = 0; i < dataScatter?.series.length; i++) {
            //  console.log("maxXValue", scatter.results.series[i].data);
            if (dataScatter?.series[i].data?.length != 0) {
                for (const dataPoint of dataScatter?.series[i]?.data) {
                    const xValue = Number(dataPoint.x)
                    const yValue = Number(dataPoint.y)
                    if (xValue > maxXValue) {
                        setMaxXValue(xValue)
                    }
                    if (yValue > maxYValue) {
                        setMaxYValue(yValue)
                    }
                }
            }
        }
    }

    return (
        <Row>
            {loadingSkeleton ? (
                <ScatterSkeleton />
            ) : (
                <Col xl={12}>
                    <Card
                        style={{
                            borderRadius: 8,
                        }}>
                        <CardTitle
                            style={{
                                borderBottom: '1px solid #EDECEC',
                                paddingTop: 25,
                                paddingLeft: 60,
                                paddingRight: 35,
                                paddingBottom: 20,
                            }}>
                            <DivTitle>
                                <H3>Customer segmentation</H3>
                            </DivTitle>
                        </CardTitle>
                        <CardBody
                            style={{
                                paddingLeft: 60,
                                paddingRight: 130,
                            }}>
                            <SelectWrapper>
                                {buttonFilterTimeRange.map((item, index) => {
                                    return (
                                        <SelectButton
                                            key={index}
                                            color={activeTab == item.value ? '#fff' : '#495057'}
                                            backgroundColor={activeTab == item.value ? '#79829C' : '#fff'}
                                            onClick={() => toggleTab(item.value)}>
                                            {i18n.language === 'en' ? item.title_en : item.title_th}
                                        </SelectButton>
                                    )
                                })}
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId={'week'}>
                                        <WeekPicker value={date} onChange={handleChangeTime} />
                                    </TabPane>

                                    <TabPane tabId={'month'}>
                                        <MonthPicker value={date} onChange={handleChangeTime} />
                                    </TabPane>
                                    <TabPane tabId={'year'}>
                                        <YearPicker value={date} onChange={handleChangeTime} />
                                    </TabPane>
                                    <TabPane tabId={'quarter'}>
                                        <QuarterPicker value={date} onChange={handleChangeTime} />
                                    </TabPane>
                                    <TabPane tabId={'halfYear'}>
                                        <div className="d-flex">
                                            <HalfYearPicker value={selectHalfYear} onChange={handleHalfYearChange} />
                                            <YearPicker value={date} onChange={handleChangeTime} />
                                        </div>
                                    </TabPane>
                                    <TabPane tabId={'period'}>
                                        <CustomPicker onChange={handleChangeTime} />
                                    </TabPane>
                                </TabContent>
                            </SelectWrapper>

                            <SelectWrapperII>
                                <Section> {t('common.data')} : </Section>
                                <Select style={{width: 200}} value={dataValue} onChange={onChangeData}>
                                    {selectOptionDataType?.map(item => (
                                        <Option
                                            key={item.value}
                                            value={item.value}
                                            label={i18n.language === 'en' ? item.label_en : item.label_th}>
                                            {i18n.language === 'en' ? item.label_en : item.label_th}
                                        </Option>
                                    ))}
                                </Select>

                                <Select
                                    className=" container-select-multiple"
                                    mode="multiple"
                                    style={{width: '50%', paddingRight: 15, marginLeft: 15}}
                                    placeholder={t('common.all')}
                                    value={multipleValue}
                                    onChange={handleChangeMultiple}
                                    showSearch
                                    optionFilterProp="label">
                                    {dataValue === 'drone' && (
                                        <>
                                            {lutDrones?.map((item, index) => (
                                                <Option key={index} value={item.id} label={item.name}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                        </>
                                    )}
                                    {dataValue === 'country' && (
                                        <>
                                            {optionsCountry?.map(
                                                (option, index) =>
                                                    option?.value !== 'all' && (
                                                        <Option key={index} value={option.value} label={option.label}>
                                                            {i18n.language === 'th' ? option.label_th : option.label_en}
                                                        </Option>
                                                    ),
                                            )}
                                        </>
                                    )}
                                    {dataValue === 'region' && (
                                        <>
                                            {lutRegion?.map(
                                                (option, index) =>
                                                    option.reg_code != 1 && (
                                                        <Option
                                                            key={index}
                                                            value={option.reg_code}
                                                            label={
                                                                i18n.language === 'en' ? option.name_en : option.name_th
                                                            }>
                                                            {i18n.language === 'en' ? option.name_en : option.name_th}
                                                        </Option>
                                                    ),
                                            )}
                                        </>
                                    )}
                                    {dataValue === 'province' && (
                                        <>
                                            {lutProvince?.map((item, index) => (
                                                <Option
                                                    key={index}
                                                    value={item.id}
                                                    label={i18n.language === 'en' ? item.label_en : item?.label}>
                                                    {i18n.language === 'en' ? item.label_en : item?.label}
                                                </Option>
                                            ))}
                                        </>
                                    )}
                                </Select>
                            </SelectWrapperII>
                            <SelectWrapperII>
                                <Section> {t('userInalyze.yAxis')} </Section>
                                <Select
                                    style={{width: '27%', marginRight: 20}}
                                    showSearch
                                    value={selectY}
                                    onChange={handleChangeSelectY}>
                                    {lutOptionY?.map((item, index) => (
                                        <Option
                                            disabled={item.disabled}
                                            key={index}
                                            value={item.value}
                                            label={i18n.language === 'en' ? item.label_en : item?.label_th}>
                                            {i18n.language === 'en' ? item.label_en : item?.label_th}
                                        </Option>
                                    ))}
                                </Select>

                                <Section> {t('userInalyze.xAxis')} </Section>
                                <Select
                                    style={{width: '27%'}}
                                    showSearch
                                    value={selectX}
                                    onChange={handleChangeSelectX}>
                                    {lutOptionX?.map((item, index) => (
                                        <Option
                                            disabled={item.disabled}
                                            key={index}
                                            value={item.value}
                                            label={i18n.language === 'en' ? item.label_en : item?.label_th}>
                                            {i18n.language === 'en' ? item.label_en : item?.label_th}
                                        </Option>
                                    ))}
                                </Select>
                                <ButtonSubmit type="Submit" onClick={handleChangeSubmit}>
                                    {t('common.searchInformation')}
                                </ButtonSubmit>
                            </SelectWrapperII>

                            <div
                                style={{
                                    marginTop: 50,
                                    paddingLeft: 80,
                                    marginBottom: 50,
                                }}>
                                {maxXValue ? (
                                    <OptionScatter
                                        data={t('common.droneModel')}
                                        series={dataScatter?.series}
                                        color={dataScatter?.colours}
                                        name_x={dataScatter?.name_x}
                                        name_y={dataScatter?.name_y}
                                        max_x={maxXValue}
                                        max_y={maxYValue || 0}
                                    />
                                ) : (
                                    <>
                                        <OptionScatter
                                            data={t('common.droneModel')}
                                            series={dataScatter?.series}
                                            color={dataScatter?.colours}
                                            name_x={dataScatter?.name_x}
                                            name_y={dataScatter?.name_y}
                                            max_x={0}
                                            max_y={maxYValue || 0}
                                        />
                                    </>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            )}
        </Row>
    )
}

export default withTranslation()(ScatterCustomerUserInalyze)
