import React, {useEffect, useState} from 'react'
import {Row, Col, CardBody, Table} from 'reactstrap'
import {withTranslation} from 'react-i18next'

import styled from 'styled-components'

import './styles/select-data.scss'
import {getUserInformationFlightTime, getUserInformationWorkOverView} from 'services/skc/user-information'
import {useRecoilState, useRecoilValue} from 'recoil'
import {filterUserInformationState, loadingUserInformationState} from 'store/atoms/user-information'

import SelectDataSkeleton from '../../CardSkeleton/SelectDataSkeleton'
const StyledContainer = styled.div`
    padding: 10px 1px 10px 10px;
    background: #f9f9f9;
    border-radius: 10px;
    width: 31%;
    margin: 0px 14px 15px 0;
`

const Actions = styled.div`
    color: #333;
    display: flex;
    align-items: center;

    svg {
        transform: translateY(2px);
        margin-right: 5px;
    }
    @media (max-width: 500px) {
        flex-direction: column;
        & button {
            width: 100%;
            margin-bottom: 4px;
            font-size: 0.65rem;
        }
    }
`
const Value = styled.label`
    color: #4e4e4e;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
`
const Value2 = styled.label`
    color: #898989;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin: 0px 0px 4px 6px;
`

const SelectData = props => {
    const {t, i18n} = props

    const [dataWorkOverView, setDataWorkOverView] = useState({})
    const [skeletonWorkOverView, setSkeletonWorkOverView] = useState(false)

    const [dataFlightTime, setDataFlightTime] = useState([])
    const [skeletonFlightTime, setSkeletonFlightTime] = useState(false)

    const filterUserInformation = useRecoilValue(filterUserInformationState)
    const [loading, setLoading] = useRecoilState(loadingUserInformationState)
    const [language, setLanguage] = useState(i18n.language)

    useEffect(() => {
        fetchWorkOverView(filterUserInformation)
        fetchFlightTimeRanking(filterUserInformation)
    }, [])

    useEffect(() => {
        if (loading.work_overview) {
            fetchWorkOverView(filterUserInformation)
            setLoading(cur => ({...cur, work_overview: false}))
        }
    }, [loading.work_overview])

    useEffect(() => {
        if (loading.work_overview) {
            fetchWorkOverView(filterUserInformation)
            setLoading(cur => ({...cur, work_overview: false}))
        }
    }, [loading.work_overview])

    useEffect(() => {
        if (i18n.language !== language) {
            fetchFlightTimeRanking(filterUserInformation)
            setLanguage(i18n.language)
        }
    }, [i18n.language])

    const fetchWorkOverView = async params => {
        try {
            setSkeletonWorkOverView(true)

            const response = await getUserInformationWorkOverView(params)

            if (response?.status_code === 200 && response?.results?.data) {
                setDataWorkOverView(response?.results?.data)
            }
        } catch (error) {
            setDataWorkOverView([])
        } finally {
            setSkeletonWorkOverView(false)
        }
    }

    const fetchFlightTimeRanking = async params => {
        try {
            setSkeletonFlightTime(true)

            const response = await getUserInformationFlightTime(params)

            if (response?.status_code === 200 && response?.results?.data) {
                setDataFlightTime(response?.results?.data)
            }
        } catch (error) {
            setDataFlightTime([])
        } finally {
            setSkeletonFlightTime(false)
        }
    }

    const twoDecimal = new Intl.NumberFormat('en-NZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })

    const noDecimal = new Intl.NumberFormat('en-NZ', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })

    return (
        <CardBody className="card-body-work-overview-flight-time">
            {skeletonWorkOverView || skeletonFlightTime ? (
                <SelectDataSkeleton />
            ) : (
                <Row>
                    <Col xl={7} className="col-work-overview">
                        <h4 className="text">{t('userInformation.workOverview')}</h4>
                        <div className="box">
                            <StyledContainer>
                                <h4 className="box-head-text">{t('userInformation.totalFlights')}</h4>
                                <Actions>
                                    <Value> {noDecimal.format(dataWorkOverView?.flight) || '-'}</Value>
                                    <Value2> {t('common.flightTimeUnit')}</Value2>
                                </Actions>
                            </StyledContainer>
                            <StyledContainer>
                                <h4 className="box-head-text">{t('userInformation.totalFlightTime')}</h4>
                                <Actions>
                                    <Value> {twoDecimal.format(dataWorkOverView?.hour) || '-'}</Value>
                                    <Value2> {t('common.hourUnit')}</Value2>
                                </Actions>
                            </StyledContainer>
                            <StyledContainer>
                                <h4 className="box-head-text">{t('userInformation.averageFlightTime')}</h4>
                                <Actions>
                                    <Value> {twoDecimal.format(dataWorkOverView?.hour_avg) || '-'}</Value>
                                    <Value2> {t('common.hourUnit')}</Value2>
                                </Actions>
                            </StyledContainer>
                            <StyledContainer>
                                <h4 className="box-head-text">{t('userInformation.totalWorkingArea')}</h4>
                                <Actions>
                                    <Value> {twoDecimal.format(dataWorkOverView?.area) || '-'}</Value>
                                    <Value2> {t('common.raiUnit')}</Value2>
                                </Actions>
                            </StyledContainer>
                            <StyledContainer>
                                <h4 className="box-head-text">{t('userInformation.averageWorkingArea')}</h4>
                                <Actions>
                                    <Value> {twoDecimal.format(dataWorkOverView?.area_avg) || '-'}</Value>
                                    <Value2> {t('common.raiUnit')}</Value2>
                                </Actions>
                            </StyledContainer>
                            <StyledContainer>
                                <h4 className="box-head-text">{t('userInformation.totalSprayingArea')}</h4>
                                <Actions>
                                    <Value> {noDecimal.format(dataWorkOverView?.total_spray_area) || '-'}</Value>
                                    <Value2> {t('common.raiUnit')}</Value2>
                                </Actions>
                            </StyledContainer>
                            <StyledContainer>
                                <h4 className="box-head-text">{t('userInformation.totalSpreadingArea')}</h4>
                                <Actions>
                                    <Value> {noDecimal.format(dataWorkOverView?.total_spread_area) || '-'}</Value>
                                    <Value2> {t('common.raiUnit')}</Value2>
                                </Actions>
                            </StyledContainer>
                        </div>
                    </Col>
                    <Col xl={5} className="col-flight-time">
                        <h4 className="text">{t('userInformation.flightTimeRanking')}</h4>
                        {dataFlightTime?.length != 0 ? (
                            <div className="table-responsive">
                                <Table className="table mb-0 white-bg">
                                    <tbody style={{borderTop: '1px solid #EFF2F7'}}>
                                        {dataFlightTime?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th
                                                        scope="row"
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            fontFamily: 'Inter',
                                                            color: '#000',
                                                        }}>
                                                        {index + 1}.
                                                    </th>
                                                    <td
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            fontFamily: 'Kanit',
                                                            color: '#000',
                                                        }}>
                                                        {item.days.toLocaleString('en-US', {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 2,
                                                        })}
                                                    </td>
                                                    <td
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            fontFamily: 'Inter',
                                                            color: '#000',
                                                        }}>
                                                        {item.amounthour.toLocaleString('en-US', {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 2,
                                                        })}
                                                        <span
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 300,
                                                                fontFamily: 'Kanit',
                                                                color: '#495057',
                                                                paddingLeft: 4,
                                                            }}>
                                                            {t('common.hourUnit')}
                                                        </span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 600,
                                                            fontFamily: 'Inter',
                                                            color: '#000',
                                                        }}>
                                                        {item.time}{' '}
                                                        <span
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 300,
                                                                fontFamily: 'Kanit',
                                                                color: '#495057',
                                                                paddingLeft: 4,
                                                            }}>
                                                            {t('common.minuteUnit')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        ) : (
                            <div
                                style={{
                                    width: '100%',
                                    height: 330,
                                    paddingLeft: 10,
                                    paddingRight: 30,
                                    marginTop: 20,
                                    textAlign: 'center',
                                    paddingTop: 100,
                                }}>
                                <p
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 400,
                                        fontFamily: 'Kanit',
                                        color: 'gray',
                                        textAlign: 'center',
                                        marginTop: 50,
                                    }}>
                                    {t('common.noData')}
                                </p>
                            </div>
                        )}
                    </Col>
                </Row>
            )}
        </CardBody>
    )
}

export default withTranslation()(SelectData)
