import {
  GET_WAY_POINT,
  GET_WAY_POINT_FAIL,
  GET_WAY_POINT_SUCCESS,
 
} from "./actionTypes";

export const getWayPoint = (data) => ({
  type: GET_WAY_POINT,
  data
});

export const getWayPointSuccess = (waypoint) => ({
  type: GET_WAY_POINT_SUCCESS,
  payload: waypoint,
});

export const getWayPointFail = (error) => ({
  type: GET_WAY_POINT_FAIL,
  payload: error,
});

