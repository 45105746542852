import React, {useState, useEffect} from 'react'
import {useRecoilState, useSetRecoilState} from 'recoil'
import {withTranslation} from 'react-i18next'
import {Card, Button, TabContent, TabPane, CardTitle} from 'reactstrap'

import dayjs from 'dayjs'
import 'dayjs/locale/th'

import WeekPicker from '../../component/weekPicker'
import MonthPicker from '../../component/monthPicker'
import YearPicker from '../../component/yearPicker'

import NavbarInfoSkeleton from '../../CardSkeleton/NavbarInfoSkeleton'

import {buttonFilterTimeRange} from 'constants/lookup'
import {filterUserInformationState, loadingUserInformationState} from 'store/atoms/user-information'

const NavBarUser = props => {
    const {t, i18n} = props

    const [activeTab, setActiveTab] = useState('year')
    const [date, setDate] = useState(dayjs())
    const [skeleton, setSkeleton] = useState(false)

    const [filterUserInformation, setFilterUserInformation] = useRecoilState(filterUserInformationState)
    const setLoading = useSetRecoilState(loadingUserInformationState)

    useEffect(() => {
        setSkeleton(true)
        setTimeout(() => {
            setSkeleton(false)
        }, 3000)
    }, [])

    useEffect(() => {
        return () => {
            setFilterUserInformation({
                type: 'year',
                week: dayjs().week(),
                month: dayjs().month() + 1,
                year: dayjs().year(),
                start_date: dayjs().startOf('year').format('YYYY-MM-DD'),
                end_date: dayjs().endOf('year').format('YYYY-MM-DD'),
            })
        }
    }, [])

    const toggleTab = tab => {
        setActiveTab(tab)
    }

    const handleChangeTime = date => {
        setDate(date)
    }

    const handleChangeSubmit = () => {
        let params = {
            ...filterUserInformation,
            type: activeTab,
        }

        if (activeTab === 'year') {
            params = {
                ...params,
                week: dayjs(date).week(),
                month: dayjs(date).month() + 1,
                year: dayjs(date).year(),
                start_date: dayjs(date).startOf('year').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('year').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'month') {
            params = {
                ...params,
                week: dayjs(date).week(),
                month: dayjs(date).month() + 1,
                year: dayjs(date).year(),
                start_date: dayjs(date).startOf('month').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('month').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'week') {
            params = {
                ...params,
                week: dayjs(date).week(),
                month: dayjs(date).month() + 1,
                year: dayjs(date).year(),
                start_date: dayjs(date).startOf('week').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('week').format('YYYY-MM-DD'),
            }
        }

        setFilterUserInformation(params)
        setLoading(cur => ({
            ...cur,
            drone_detail: true,
            drone_flight_duration: true,
            drone_working_area: true,
            drone_speed: true,
            drone_height: true,
            drone_flight_history: true,
            drone_table: true,
        }))
    }

    return (
        <>
            {skeleton ? (
                <NavbarInfoSkeleton />
            ) : (
                <Card
                    style={{
                        borderRadius: 8,
                    }}>
                    <CardTitle
                        style={{
                            marginBottom: 0,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginTop: 20,
                            paddingBottom: 20,
                        }}>
                        <div
                            className={'col-sm-auto '}
                            style={{
                                marginLeft: 20,
                            }}>
                            <div className="row-sm-auto button-items">
                                {buttonFilterTimeRange?.map((item, index) => {
                                    return (
                                        item.value !== 'quarter' &&
                                        item.value !== 'halfYear' &&
                                        item.value !== 'period' && (
                                            <Button
                                                key={index}
                                                color="secondary"
                                                outline
                                                style={{
                                                    fontFamily: 'Kanit',
                                                    fontWeight: 400,
                                                    fontSize: 14,
                                                    borderColor: '#E2E2E2',
                                                    borderRadius: 8,
                                                    color: activeTab == item.value ? '#fff' : '#495057',
                                                    backgroundColor: activeTab == item.value && '#79829C',
                                                }}
                                                onClick={() => toggleTab(item.value)}>
                                                {i18n.language === 'en' ? item.title_en : item.title_th}
                                            </Button>
                                        )
                                    )
                                })}
                            </div>
                        </div>
                        <div className="col-sm-auto " style={{marginLeft: 10}}>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="week">
                                    <WeekPicker value={date} onChange={handleChangeTime} />
                                </TabPane>
                                <TabPane
                                    tabId="month"
                                    id="v-pills-payment"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-payment-tab">
                                    <MonthPicker value={date} onChange={handleChangeTime} />
                                </TabPane>
                                <TabPane tabId="year" id="v-pills-confir" role="tabpanel">
                                    <YearPicker value={date} onChange={handleChangeTime} />
                                </TabPane>
                            </TabContent>
                        </div>

                        <div
                            className="col-sm-auto d-flex"
                            style={{
                                marginLeft: 10,
                            }}>
                            <button
                                onClick={() => {
                                    handleChangeSubmit()
                                }}
                                type="button"
                                className="btn btn-primary w-xs"
                                style={{
                                    borderColor: '#00A69C',
                                    borderRadius: 8,
                                    backgroundColor: '#00A69C',
                                }}>
                                {t('common.searchInformation')}
                            </button>
                        </div>
                    </CardTitle>
                </Card>
            )}
        </>
    )
}

export default withTranslation()(NavBarUser)
