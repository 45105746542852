import {atom} from 'recoil'
import dayjs from 'dayjs'

export const filterUserInalyzeState = atom({
    key: 'filterUserInalyze',
    default: {
        type: 'year',
        month: '',
        week: '',
        year: dayjs().year(),
        start_date: dayjs().startOf('year').format('YYYY-MM-DD'),
        end_date: dayjs().endOf('year').format('YYYY-MM-DD'),
        country: 'all',
        province: [],
        district: [],
        date: dayjs(),
    },
})

export const loadingUserInalyzeState = atom({
    key: 'loadingUserInalyze',
    default: {
        widget: false,
        map: false,
    },
})
