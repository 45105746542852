import React, {useState, useEffect} from 'react'
import {Card, CardBody, Col, Row, CarouselItem, CardTitle, Table} from 'reactstrap'
import {useRecoilState, useRecoilValue} from 'recoil'
import {withTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import Drone from '../../../../assets/images/icon/Rectangle46.png'
import DataDroneSkeleton from '../../CardSkeleton/DataDroneSkeleton'
import MapLocation from './Component/MapLocation'
import {getUserHardwareID, getUserInformationDrone} from 'services/skc/user-information'
import {filterUserInformationState, loadingUserInformationState} from 'store/atoms/user-information'
import {getUserInformationDroneStatus} from 'services/skc/new-kafka'

const Button = styled.button`
    background-color: #3b89ff;
    color: white;
    font-size: 14px;
    padding: 5px 10px 5px 15px;
    border-radius: 8px;
    // margin: 10px 0px;
    cursor: pointer;
    border-width: 0px;
    text-align: center;
`

const DataDrone = props => {
    const {t} = props

    const filterUserInformation = useRecoilValue(filterUserInformationState)
    const [loading, setLoading] = useRecoilState(loadingUserInformationState)

    const [skeletonData, setSkeletonData] = useState(false)
    const [skeletonDataDroneStatus, setSkeletonDataDroneStatus] = useState(false)

    const [data, setData] = useState([])
    const [userHardwareID, setUserHardwareID] = useState([])
    const [dataDroneStatus, setDataDroneStatus] = useState([])

    const history = useHistory()

    useEffect(() => {
        fetchUserInformationDrone(filterUserInformation.id)
        fetchUserHardwareID(filterUserInformation.id)
    }, [])

    useEffect(() => {
        if (userHardwareID) {
            fetchDroneStatus({hardware: userHardwareID})
        }
    }, [userHardwareID])

    useEffect(() => {
        if (loading?.data_drone) {
            fetchUserInformationDrone(filterUserInformation.id)
            fetchUserHardwareID(filterUserInformation.id)
            setLoading(cur => ({...cur, data_drone: false}))
        }
    }, [loading.data_drone])

    const fetchUserInformationDrone = async params => {
        try {
            setSkeletonData(true)
            const response = await getUserInformationDrone(params)

            if (response?.status_code === 200 && response?.results?.data?.length > 0) {
                setData(response?.results?.data)
            } else {
                setData([])
            }
        } catch (error) {
            setData([])
        } finally {
            setSkeletonData(false)
        }
    }

    const fetchUserHardwareID = async params => {
        try {
            const response = await getUserHardwareID(params)

            if (response?.status_code === 200 && response?.results?.data?.length > 0) {
                setUserHardwareID(response?.results?.data)
            }
        } catch (error) {
            setUserHardwareID([])
        } finally {
        }
    }

    const fetchDroneStatus = async params => {
        try {
            setSkeletonDataDroneStatus(true)
            const response = await getUserInformationDroneStatus(params)

            if (response?.result.length > 0) {
                setDataToDroneStatus(response?.result)
            }
        } catch (error) {
            setDataDroneStatus([])
        } finally {
            setSkeletonDataDroneStatus(false)
        }
    }

    const handleClickToDroneInformation = id => {
        history.push({
            pathname: '/drone-information/overall',
            search: `${id}`,
            id,
        })
    }

    const setDataToDroneStatus = dataDrone => {
        const result = []
        dataDrone.forEach(item => {
            data.forEach(name => {
                if (item.position_latest?.hardwareid === name?.hardware_id) {
                    result.push({
                        ...item,
                        drone_name: name?.drone_model?.name || '-',
                    })
                }
            })
        })
        setDataDroneStatus(result)
    }

    return (
        <div>
            {!skeletonData && !skeletonDataDroneStatus ? (
                <Card
                    style={{
                        borderRadius: 8,
                        height: 496,
                    }}>
                    <CardBody>
                        <Row>
                            <Col
                                md={6}
                                style={{
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                }}>
                                <CardTitle
                                    className="h3 mt-0"
                                    style={{
                                        fontSize: 20,
                                        fontWeight: 600,
                                        paddingTop: 10,
                                        color: '#495057',
                                        marginBottom: 15,
                                    }}>
                                    {t('userInformation.ownedDrones')}
                                </CardTitle>
                                {data?.length > 0 ? (
                                    <div className="table-responsive ">
                                        <Table className="table mb-0 white-bg">
                                            <thead className="table-light ">
                                                <tr>
                                                    <th
                                                        style={{
                                                            fontFamily: 'Kanit',
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            paddingTop: 10,
                                                            color: '#495057',
                                                            paddingLeft: 30,
                                                        }}>
                                                        {t('userInformation.picture')}
                                                    </th>
                                                    <th
                                                        style={{
                                                            fontFamily: 'Kanit',
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            paddingTop: 10,
                                                            color: '#495057',
                                                        }}>
                                                        {t('userInformation.droneModel')}
                                                    </th>
                                                    <th
                                                        style={{
                                                            fontFamily: 'Kanit',
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            paddingTop: 10,
                                                            color: '#495057',
                                                        }}>
                                                        {t('userInformation.usageInHours')}
                                                    </th>
                                                    <th
                                                        style={{
                                                            fontFamily: 'Kanit',
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            paddingTop: 10,
                                                            color: '#495057',
                                                        }}>
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.map((item, index) => {
                                                    return (
                                                        <tr
                                                            key={index}
                                                            style={{
                                                                textAlign: 'left',
                                                            }}>
                                                            <th
                                                                scope="row"
                                                                style={{
                                                                    paddingLeft: 20,
                                                                }}>
                                                                <img
                                                                    src={
                                                                        item.drone_model.image == null
                                                                            ? Drone
                                                                            : 'https://s3dev-gramick.sgp1.cdn.digitaloceanspaces.com/kubota/' +
                                                                              item.drone_model.image +
                                                                              '?v=1'
                                                                    }
                                                                    style={{height: 28, width: 56, boxShadow: 'none'}}
                                                                />
                                                            </th>
                                                            <td
                                                                style={{
                                                                    fontSize: 14,
                                                                    fontWeight: 500,
                                                                    fontFamily: 'Inter',
                                                                    color: '#495057',
                                                                }}>
                                                                {item?.drone_model.name || '-'}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    fontSize: 14,
                                                                    fontWeight: 500,
                                                                    fontFamily: 'Inter',
                                                                    color: '#495057',
                                                                }}>
                                                                {item?.hour || '-'}
                                                            </td>
                                                            <td>
                                                                <Button
                                                                    onClick={() => {
                                                                        handleClickToDroneInformation(item.id)
                                                                    }}
                                                                    color="#3B89FF"
                                                                    size="sm">
                                                                    <span>{t('userInformation.droneInformation')}</span>
                                                                    <i className="mdi mdi-chevron-right"></i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            width: '100%',
                                            height: 330,
                                            paddingLeft: 10,
                                            paddingRight: 30,
                                            marginTop: 20,
                                            textAlign: 'center',
                                            paddingTop: 100,
                                        }}>
                                        <p
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 400,
                                                fontFamily: 'Kanit',
                                                color: 'gray',
                                                textAlign: 'center',
                                                marginTop: 50,
                                            }}>
                                            {t('common.noData')}
                                        </p>
                                    </div>
                                )}
                            </Col>
                            <Col
                                md={6}
                                style={{
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    paddingTop: 10,
                                }}>
                                <CardTitle
                                    className="h3 mt-0"
                                    style={{
                                        fontSize: 20,
                                        fontWeight: 600,

                                        marginBottom: 15,
                                    }}>
                                    {t('userInformation.latestDronePosition')}
                                    {dataDroneStatus?.length === 0 && (
                                        <span
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 400,
                                                fontFamily: 'Kanit',
                                                color: 'gray',
                                                textAlign: 'center',
                                                marginLeft: 20,
                                            }}>
                                            ( {t('userInformation.latestDronePositionNotFound')})
                                        </span>
                                    )}
                                </CardTitle>
                                {dataDroneStatus?.length > 0 && <MapLocation location={dataDroneStatus} />}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            ) : (
                <DataDroneSkeleton />
            )}
        </div>
    )
}

export default withTranslation()(DataDrone)
