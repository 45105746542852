import React, {useState, useEffect, useRef} from 'react'
import {useRecoilState, useRecoilValue} from 'recoil'
import {withTranslation} from 'react-i18next'
import {Row, Col, Card, CardBody} from 'reactstrap'

import {filterUserInalyzeState, loadingUserInalyzeState} from 'store/atoms/user-inalyze'

import ChartsMap from 'components/Map/ChartsMap'
import TableMap from 'components/Map/TableMap'
import MapSkeleton from '../CardSkeleton/MapSkeleton'

import {getUserMapInalyze} from 'services/skc/user-inalyze'

const MapUserInalyze = props => {
    const {t, i18n} = props

    const filterUserInalyze = useRecoilValue(filterUserInalyzeState)

    const [loading, setLoading] = useRecoilState(loadingUserInalyzeState)
    const [language, setLanguage] = useState(i18n.language)

    const [mapUserInalyze, setMapUserInalyze] = useState({})
    const [sortDataButton, setSortDataButton] = useState('UNIT')
    const [loadingSkeleton, setLoadingSkeleton] = useState(false)

    const mapRef = useRef()
    const refDivRows = useRef()
    let rowRefs = useRef()

    const handleClickMap = selectMap => {
        const dataRow = Object.keys(rowRefs).map(key => rowRefs[key])
        const dataDivRow = Object.keys(refDivRows).map(key => refDivRows[key])

        const findDataRow = dataRow?.find(item => item?.current?.id === selectMap)
        const findDataDiv = dataDivRow?.find(item => item?.current?.id === selectMap)

        dataDivRow?.forEach(ref => {
            ref?.current?.classList?.remove('table-active')
        })

        dataRow?.forEach(ref => {
            ref?.current?.classList?.remove('text-click')
        })

        findDataRow?.current?.scrollIntoView({behavior: 'auto', block: 'nearest'})
        findDataRow?.current?.classList?.add('text-click')
        findDataDiv?.current?.classList?.add('table-active')
    }

    const sortFilterBtn = data => {
        setSortDataButton(data)
        fetchMapUserInalyze({...filterUserInalyze, to_date: data}, 'user')
    }

    useEffect(() => {
        fetchMapUserInalyze(filterUserInalyze, 'user')
    }, [])

    useEffect(() => {
        if (loading?.map) {
            fetchMapUserInalyze(filterUserInalyze, 'user')
            setLoading(current => ({...current, map: false}))
        }
    }, [loading])

    useEffect(() => {
        if (i18n.language !== language) {
            fetchMapUserInalyze(filterUserInalyze, 'user')
            setLanguage(i18n.language)
        }
    }, [i18n.language])

    const fetchMapUserInalyze = async (params, value) => {
        try {
            setLoadingSkeleton(true)
            const response = await getUserMapInalyze(params, value)
            if (response.status_code === 200 && response?.results) {
                setMapUserInalyze(response?.results)
            }
        } catch (error) {
            setMapUserInalyze({})
        } finally {
            setLoadingSkeleton(false)
        }
    }

    return (
        <React.Fragment>
            {loadingSkeleton ? (
                <MapSkeleton />
            ) : (
                <Card
                    style={{
                        borderRadius: 8,
                        paddingTop: 10,
                        height: 770,
                    }}>
                    <CardBody>
                        <Row>
                            <Col lg="4">
                                <p className="title-head-map">
                                    {t('userInalyze.informationOnUsageAndUsageInEachArea')}
                                </p>
                                <ChartsMap
                                    country={filterUserInalyze.country}
                                    data={mapUserInalyze?.mapData}
                                    mapRef={mapRef}
                                    selectMap={handleClickMap}
                                />
                            </Col>
                            <Col lg="8" style={{marginTop: 20}}>
                                <TableMap
                                    title={t('userInalyze.totalUsers')}
                                    data={mapUserInalyze?.provinceData}
                                    percentChart={mapUserInalyze?.percentChart}
                                    max={mapUserInalyze?.max}
                                    tabData={'drone'}
                                    unit={t('common.personUnit')}
                                    refDivRows={refDivRows}
                                    rowRefs={rowRefs}
                                    filter={filterUserInalyze}
                                    sortFilterBtn={sortFilterBtn}
                                    sortDataButton={sortDataButton}
                                    percentHeatMap={{
                                        maxPercent: mapUserInalyze?.maxPercent,
                                        minPercent: mapUserInalyze?.minPercent,
                                    }}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )}
        </React.Fragment>
    )
}

export default withTranslation()(MapUserInalyze)
