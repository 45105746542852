import { AUTHORIZED_SUCCESS, AUTHORIZED_FAIL } from "./actionTypes";
import * as moment from "moment";

const INIT_STATE = {
  data: {
    status: true
  },
};

const authorized = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTHORIZED_SUCCESS:
      return {
        ...state,
        data : action.payload,
      };
   
    case AUTHORIZED_FAIL:
      return {
        ...state,
        data : action.payload,
      };
    default:
      return state;
  }
};

export default authorized;
