import React, {useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {useRecoilState} from 'recoil'
import {Select, DatePicker, Radio} from 'antd'
import {Row, Col, Button, Label, TabContent, TabPane} from 'reactstrap'
import './index.scss'

const {Option} = Select
import dayjs from 'dayjs'

import FormAreaSkeleton from 'components/DroneiNalyze/Skeleton-iNalyze/Comparison/FormArea/FormAreaSkeleton'
import {formAreaType, lookupFormAreaSelectTime, optionsCountry} from 'constants/lookup'
import {getFilterDrone, getFilterRegion, getProvince} from 'services/skc/lookup'
import {formCompareAreaState, loadingInalyzeState} from 'store/atoms/inalyze'

const monthFormat = 'MMMM YYYY'

const FormArea = props => {
    const {t, i18n, onClickSearchArea, onClickClearSearchArea} = props

    const [formCompareArea, setFormCompareArea] = useRecoilState(formCompareAreaState)
    const [loading, onLoading] = useRecoilState(loadingInalyzeState)

    const [lutRegion, setLutRegion] = useState([])
    const [lutDrones, setLutDrones] = useState([])
    const [lutProvince, setLutProvince] = useState([])

    const [areaType, setAreaType] = useState('country')
    const [amountCompare, setAmountCompare] = useState(2)
    const [amountCompareList, setAmountCompareList] = useState([
        {label: 'กรุณาเลือก', value: '', showValue: true},
        {label: 'กรุณาเลือก', value: '', showValue: true},
        {label: 'กรุณาเลือก', value: '', showValue: false},
    ])
    const [optAmount, setOptAmount] = useState([
        {label: t('common.pleaseSelect'), value: ''},
        {label: '2', value: '2'},
        {label: '3', value: '3'},
    ])
    const [compareLists, setCompareLists] = useState([])
    const [compareCountryLists, setCompareCountryLists] = useState([])
    const [compareRegionLists, setCompareRegionLists] = useState([])
    const [compareProvinceLists, setCompareProvinceLists] = useState([])

    // ช่วงเวลา
    const [timeType, setTimeType] = useState('year')
    const [month, setMonth] = useState(dayjs().month() + 1)
    const [year, setYear] = useState(dayjs().year())
    const [quarter, setQuarter] = useState('')
    const [defaultQuarter, setDefaultQuarter] = useState(dayjs().startOf('quarter'))
    const [halfYear, setHalfYear] = useState(1)
    const [selectDrone, setSelectDrone] = useState([])
    const [droneBtn, setDroneBtn] = useState([])
    const [skeleton, setSkeleton] = useState(false)

    useEffect(() => {
        fetchLutRegion()
        fetchLutDrone()
        fetchProvince()
    }, [])

    const fetchLutRegion = async () => {
        try {
            const response = await getFilterRegion()

            if (response.status_code === 200 && response.results.length > 0) {
                const newRegion = []
                response.results?.forEach(item => {
                    newRegion.push({...item, disabled: false})
                })
                setLutRegion(newRegion)
            }
        } catch (error) {
            setLutRegion([])
        }
    }

    const fetchLutDrone = async () => {
        try {
            const response = await getFilterDrone()

            if (response.status_code === 200 && response.results.length > 0) {
                setLutDrones(response.results)
            }
        } catch (error) {
            setLutDrones([])
        }
    }

    const fetchProvince = async () => {
        try {
            const response = await getProvince()

            if (response.status_code === 200 && response.results?.length > 0) {
                const newProvince = []
                response.results?.forEach(item => {
                    newProvince.push({...item, disabled: false})
                })
                setLutProvince(newProvince)
            }
        } catch (error) {
            setLutProvince([])
        }
    }

    const onChangeAreaType = data => {
        let resultData

        if (data === 'country') {
            resultData = onCalSelectLength('3')
        } else if (data === 'region') {
            resultData = onCalSelectLength('4')
        } else {
            resultData = onCalSelectLength('4')
        }

        setAreaType(data)
        setAmountCompare('')
        setAmountCompareList(resultData.resultAmountCompareLists)
        setOptAmount(resultData.resultOptAmount)
        setCompareLists([])
    }

    const onCalSelectLength = length => {
        let resultAmountCompareLists = []
        let resultOptAmount = [{label: t('common.pleaseSelect'), value: ''}]

        for (let i = 1; i <= length; i++) {
            resultOptAmount.push({label: String(i), value: String(i)})
            resultAmountCompareLists.push({label: t('common.pleaseSelect'), value: '', showValue: false})
        }

        const filterOptAmount = resultOptAmount.filter(itm => itm.value !== '1')

        return {
            resultAmountCompareLists,
            resultOptAmount: filterOptAmount,
        }
    }

    const onSelectAmountCompare = count => {
        const newAmountCompareList = []
        const newCompareLists = []
        if (count) {
            amountCompareList.forEach((item, index) => {
                if (index + 1 <= count) {
                    newAmountCompareList.push({...item, showValue: true})
                } else {
                    newAmountCompareList.push({label: t('common.pleaseSelect'), value: '', showValue: false})
                }
            })

            newAmountCompareList.forEach(itm => {
                if (itm.value !== '') {
                    newCompareLists.push(itm)
                }
            })

            setCompareLists(newCompareLists)
        } else {
            amountCompareList.forEach(item => {
                newAmountCompareList.push({label: t('common.pleaseSelect'), value: '', showValue: false})
            })

            setCompareLists([])
        }

        setAmountCompareList(newAmountCompareList)
        setAmountCompare(count)
    }

    const onSelectCompare = (data, index, option) => {
        const newAmountCompare = []
        const newCompareLists = []

        amountCompareList.forEach((itm, itmIndex) => {
            if (index === itmIndex) {
                newAmountCompare.push({...itm, label: option.label, value: option.value})
            } else {
                newAmountCompare.push(itm)
            }
        })

        newAmountCompare.forEach(itm => {
            if (itm.value !== '') {
                newCompareLists.push(itm)
            }
        })

        setAmountCompareList(newAmountCompare)
        setCompareLists(newCompareLists)
    }

    const changeFirstString = str => {
        const changeString = str
            .toLowerCase()
            .split(' ')
            .map(item => {
                return item[0].toUpperCase() + item.slice(1).toLowerCase()
            })

        return changeString.join(' ')
    }

    const onSelectTimeRange = timeType => {
        setTimeType(timeType)
    }

    const disabledDate = current => {
        return current && current > dayjs().endOf('day')
    }

    const handleChangeTime = (date, dateString) => {
        const year = dayjs(date).year()
        const month = dayjs(date).month() + 1
        const quarter = `Q${Math.floor(dayjs(date).month() + 3) / 3}`

        if (timeType === 'year') {
            setYear(year)
        }
        if (timeType === 'month') {
            this.setState({month, year})
            setMonth(month)
            setYear(year)
        }
        if (timeType === 'quarter') {
            setYear(year)
            setQuarter(quarter)
            setDefaultQuarter(date)
        }
        if (timeType === 'halfYear') {
            setYear(year)
        }
    }

    const onSelectHalfYear = value => {
        setHalfYear(value)
    }

    const onSelectDrone = (data, option) => {
        setSelectDrone(data)
        setDroneBtn(option)
    }

    const onClear = () => {
        setAreaType('country')
        setAmountCompare(2)
        setAmountCompareList([
            {label: t('common.pleaseSelect'), value: '', showValue: true},
            {label: t('common.pleaseSelect'), value: '', showValue: true},
            {label: t('common.pleaseSelect'), value: '', showValue: false},
        ])
        setCompareLists([])
        // setCompareCountryLists([])
        // setCompareRegionLists([])
        // setCompareProvinceLists([])
        setTimeType('year')
        setMonth(dayjs().month() + 1)
        setYear(dayjs().year())
        setQuarter(`Q${Math.floor(dayjs(dayjs().startOf('quarter')).month() + 3) / 3}`)
        setHalfYear(1)
        setSelectDrone([])
        setDefaultQuarter(dayjs().startOf('quarter'))

        onClickClearSearchArea()
    }

    const onSubmit = () => {
        setSkeleton(true)
        onLoading(cur => ({...cur, compare_area: true}))

        let paramData = {}
        if (timeType === 'year') {
            paramData = {
                areaType: areaType,
                amountCompare: amountCompare,
                timeType: timeType,
                year: year,
                quarter: '',
                month: '',
                halfYear: null,
                drone: selectDrone,
                amountCompareList: amountCompareList,
                compareLists: compareLists,
                droneBtn: droneBtn,
            }
        }
        if (timeType === 'quarter') {
            paramData = {
                areaType: areaType,
                amountCompare: amountCompare,
                timeType: timeType,
                year: year,
                quarter: quarter,
                month: '',
                halfYear: null,
                drone: selectDrone,
                amountCompareList: amountCompareList,
                compareLists: compareLists,
                defaultQuarter: defaultQuarter,
                droneBtn: droneBtn,
            }
        }
        if (timeType === 'halfYear') {
            paramData = {
                areaType: areaType,
                amountCompare: amountCompare,
                timeType: timeType,
                year: year,
                quarter: '',
                month: '',
                halfYear: halfYear,
                drone: selectDrone,
                amountCompareList: amountCompareList,
                compareLists: compareLists,
                droneBtn: droneBtn,
            }
        }
        if (timeType === 'month') {
            paramData = {
                areaType: areaType,
                amountCompare: amountCompare,
                timeType: timeType,
                year: year,
                quarter: '',
                month: month,
                halfYear: '',
                drone: selectDrone,
                amountCompareList: amountCompareList,
                compareLists: compareLists,
                droneBtn: droneBtn,
            }
        }

        if (areaType === 'country') {
            const compareCountryLists = []
            compareLists.forEach(item => {
                compareCountryLists.push(item.value)
            })

            const resultParams = {...paramData, compareCountryLists}

            setFormCompareArea(resultParams)
        }

        if (areaType === 'region') {
            const compareRegionLists = []
            compareLists.forEach(item => {
                compareRegionLists.push(item.value)
            })

            const resultParams = {...paramData, compareRegionLists}

            setFormCompareArea(resultParams)
        }

        if (areaType === 'province') {
            const compareProvinceLists = []
            compareLists.forEach(item => {
                compareProvinceLists.push(item.value)
            })
            const resultParams = {...paramData, compareProvinceLists}

            setFormCompareArea(resultParams)
        }

        onClickSearchArea()
        setSkeleton(false)
    }

    const checkDisabled = amountCompareList?.find(itm => itm.value === '' && itm.showValue === true) ? true : false

    return (
        <Row>
            {loading.compare_area ? (
                <Col xl={12}>
                    <FormAreaSkeleton />
                </Col>
            ) : (
                <>
                    <Col xl={12}>
                        <Row>
                            <Col xs={12} sm={2} md={2} lg={2} className="mb-2">
                                <Label for="type" className="label-form">
                                    {t('inalyze.selectComparison')} :
                                </Label>
                            </Col>
                            <Col xs={12} sm={10} md={10} lg={10} className="mb-2">
                                <Row>
                                    <Radio.Group
                                        style={{display: 'flex'}}
                                        optionLabelProp="value"
                                        name="radiogroup"
                                        value={areaType}>
                                        {formAreaType?.map(itm => (
                                            <Col xs={12} sm={3} md={3} lg={3} key={itm.value} className="mb-2">
                                                <div className="radio-box">
                                                    <Radio
                                                        name="radio-area-type"
                                                        value={itm.value}
                                                        onChange={() => onChangeAreaType(itm.value)}>
                                                        {i18n.language === 'en' ? itm.name_en : itm.name_th}
                                                    </Radio>
                                                </div>
                                            </Col>
                                        ))}
                                    </Radio.Group>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Row className="d-flex align-items-center">
                                    <Col xs={12} sm={2} md={2} lg={2} className="mb-2">
                                        <Label for="type" className="label-form">
                                            {t('inalyze.comparisonQuantity')} :
                                        </Label>
                                    </Col>
                                    <Col xs={12} sm={2} md={2} lg={2} className="mb-2">
                                        <div>
                                            <Select
                                                placeholder={t('common.pleaseSelect')}
                                                value={amountCompare}
                                                style={{width: '100%'}}
                                                options={optAmount}
                                                onChange={e => onSelectAmountCompare(e)}
                                                optionFilterProp="children"
                                            />
                                        </div>
                                    </Col>
                                    {amountCompareList?.map((item, index) => (
                                        <Col xs={12} sm={2} md={2} lg={2} key={index} className="mb-2">
                                            <Select
                                                disabled={!item.showValue}
                                                optionFilterProp="children"
                                                placeholder={t('common.pleaseSelect')}
                                                value={amountCompareList?.[index].value}
                                                style={{width: '100%'}}
                                                onChange={(e, option) => {
                                                    onSelectCompare(e, index, option)
                                                }}>
                                                <Option value={''}>{t('common.pleaseSelect')}</Option>
                                                {areaType === 'region' &&
                                                    lutRegion?.map((item, index) => {
                                                        const checkDisable = compareLists.find(compareList => {
                                                            if (compareList.value == item.reg_code) {
                                                                return compareList
                                                            }
                                                        })
                                                            ? true
                                                            : false

                                                        return (
                                                            item.reg_code != 1 && (
                                                                <Option
                                                                    key={index}
                                                                    value={item?.reg_code}
                                                                    disabled={item?.disabled || checkDisable}
                                                                    label={
                                                                        i18n.language === 'en'
                                                                            ? item.name_en
                                                                            : item?.name_th
                                                                    }>
                                                                    {i18n.language === 'en'
                                                                        ? item.name_en
                                                                        : item?.name_th}
                                                                </Option>
                                                            )
                                                        )
                                                    })}
                                                {areaType === 'country' &&
                                                    optionsCountry?.map((item, index) => {
                                                        const checkDisable = compareLists.find(compareList => {
                                                            if (compareList.value == item.value) {
                                                                return compareList
                                                            }
                                                        })
                                                            ? true
                                                            : false

                                                        return (
                                                            <React.Fragment key={index}>
                                                                {item.value !== 'all' && (
                                                                    <Option
                                                                        value={item.value}
                                                                        label={
                                                                            i18n.language === 'en'
                                                                                ? item.label_en
                                                                                : item?.label_th
                                                                        }
                                                                        disabled={item?.disabled || checkDisable}>
                                                                        {i18n.language === 'en'
                                                                            ? item.label_en
                                                                            : item?.label_th}
                                                                    </Option>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                {areaType === 'province' &&
                                                    lutProvince?.map((item, index) => {
                                                        const checkDisable = compareLists.find(compareList => {
                                                            if (compareList.value == item.value) {
                                                                return compareList
                                                            }
                                                        })
                                                            ? true
                                                            : false

                                                        return (
                                                            <Option
                                                                key={index}
                                                                value={item.value}
                                                                label={
                                                                    i18n.language === 'en'
                                                                        ? changeFirstString(item?.label_en)
                                                                        : item.label
                                                                }
                                                                disabled={item?.disabled || checkDisable}>
                                                                {i18n.language === 'en'
                                                                    ? changeFirstString(item?.label_en)
                                                                    : item.label}
                                                            </Option>
                                                        )
                                                    })}
                                            </Select>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                            <Col xs={12} sm={2} md={2} lg={2} className="mb-2">
                                <Label for="type" className="label-form">
                                    {t('inalyze.selectTimeRange')} :
                                </Label>
                            </Col>
                            <Col xs={12} sm={10} md={10} lg={10} className="mb-2">
                                <Row>
                                    <Radio.Group style={{display: 'flex'}} name="radiogroup" value={timeType}>
                                        {lookupFormAreaSelectTime?.map(itm => (
                                            <Col xs={12} sm={3} md={2} lg={2} key={itm.value} className="mb-2">
                                                <div className="radio-box">
                                                    <Radio
                                                        name="radio-time-range"
                                                        value={itm.value}
                                                        onChange={() => onSelectTimeRange(itm.value)}>
                                                        {i18n.language === 'en' ? itm.label_en : itm.label_th}
                                                    </Radio>
                                                </div>
                                            </Col>
                                        ))}
                                    </Radio.Group>
                                </Row>
                            </Col>
                            <Col xs={12} sm={2} md={2} log={2} className="mb-2"></Col>
                            <Col xs={12} sm={6} md={6} lg={3} className="mb-2">
                                <TabContent activeTab={timeType}>
                                    <TabPane
                                        tabId="month"
                                        id="v-pills-payment"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-payment-tab">
                                        <DatePicker
                                            placeholder={t('inalyze.pleaseSelectTimeRange')}
                                            className="date-time"
                                            picker="month"
                                            format={monthFormat}
                                            value={dayjs(year + '-' + month + '-' + '01')}
                                            defaultValue={dayjs(year + '-' + month + '-' + '01')}
                                            bordered={true}
                                            onChange={(date, dateString) => {
                                                handleChangeTime(date, dateString)
                                            }}
                                            disabledDate={disabledDate}
                                        />
                                    </TabPane>
                                    <TabPane tabId="year" id="v-pills-confir" role="tabpanel">
                                        <DatePicker
                                            placeholder={t('inalyze.pleaseSelectTimeRange')}
                                            className="date-time"
                                            picker="year"
                                            value={dayjs(year + '-' + month + '-' + '01')}
                                            defaultValue={dayjs(year + '-' + month + '-' + '01')}
                                            bordered={true}
                                            onChange={(date, dateString) => {
                                                handleChangeTime(date, dateString)
                                            }}
                                            disabledDate={disabledDate}
                                        />
                                    </TabPane>
                                    <TabPane tabId="quarter" id="v-pills-confir" role="tabpanel">
                                        <DatePicker
                                            placeholder={t('inalyze.pleaseSelectTimeRange')}
                                            className="date-time"
                                            picker="quarter"
                                            value={dayjs(defaultQuarter)}
                                            defaultValue={dayjs(defaultQuarter)}
                                            bordered={true}
                                            onChange={(date, dateString) => {
                                                handleChangeTime(date, dateString)
                                            }}
                                            disabledDate={disabledDate}
                                        />
                                    </TabPane>
                                    <TabPane tabId="halfYear" id="v-pills-confir" role="tabpanel">
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <DatePicker
                                                placeholder={t('inalyze.pleaseSelectYear')}
                                                picker={'year'}
                                                className="date-time"
                                                bordered={true}
                                                value={dayjs(year + '-' + month + '-' + '01')}
                                                defaultValue={dayjs(year + '-' + month + '-' + '01')}
                                                onChange={(date, dateString) => {
                                                    handleChangeTime(date, dateString)
                                                }}
                                                disabledDate={disabledDate}
                                            />
                                            <Select
                                                optionFilterProp="children"
                                                placeholder={t('inalyze.pleaseSelectTimeRange')}
                                                value={halfYear}
                                                style={{width: '100%', marginLeft: '10px'}}
                                                options={[
                                                    {label: t('inalyze.firstHalfYear'), value: 1},
                                                    {label: t('inalyze.secondHalfYear'), value: 2},
                                                ]}
                                                onChange={value => {
                                                    onSelectHalfYear(value)
                                                }}
                                            />
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} sm={2} md={2} log={2} className="mb-2">
                        <Label for="type" className="label-form">
                            {t('common.selectDrone')}:
                        </Label>
                    </Col>
                    <Col xs={12} sm={10} md={10} lg={9} className="mb-2" style={{paddingLeft: 4}}>
                        <Select
                            className="w-100 container-select-drone"
                            mode="multiple"
                            placeholder={t('common.pleaseSelectDrone')}
                            value={selectDrone}
                            showSearch
                            optionFilterProp="children"
                            onChange={(e, option) => {
                                onSelectDrone(e, option)
                            }}>
                            {lutDrones?.map((item, index) => (
                                <Option key={index} value={item.id} label={item.name}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={12} sm={2} md={2} log={2} className="mb-2  mt-3"></Col>
                    <Col xs={6} sm={4} md={3} lg={3} xl={2} className="mb-2 mt-3">
                        <Button
                            disabled={selectDrone?.length === 0 || checkDisabled}
                            className="btn-submit"
                            color="primary"
                            onClick={() => onSubmit()}>
                            {t('inalyze.compare')}
                        </Button>
                    </Col>
                    <Col xs={6} sm={4} md={3} lg={3} xl={2} className="mb-2 mt-3">
                        <Button className="btn-clear" onClick={onClear}>
                            {t('common.clearData')}
                        </Button>
                    </Col>
                </>
            )}
        </Row>
    )
}

export default withTranslation()(FormArea)
