import React from 'react'
import Skeleton from 'react-loading-skeleton'

import '../skeleton-droneInformation.scss'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonCardDroneMap = () => {
    return (
        <div>
            <div className="d-flex flex-wrap" style={{display: 'flex', alignItems: 'center'}}>
                <Skeleton width={180} height={34} />
            </div>
            <hr className="mb-4" />
            <div>
                <Skeleton width={1030} height={400} />
            </div>
        </div>
    )
}

export default SkeletonCardDroneMap
