import React from 'react'

const ProgressBar = props => {
    const {color, data, unit, percent, tab, typeNotSpecified, progress, difference} = props

    const setDataFromTab = () => {
        let newData
        if (tab === 'drone' || tab === 'amount_working' || tab === 'user') {
            newData = parseInt(data)

            return newData.toLocaleString()
        } else {
            newData = parseFloat(data)
            return newData.toLocaleString()
        }
    }

    const setDifferenceTab = () => {
        let newData
        if (tab === 'drone' || tab === 'amount_working' || tab === 'user') {
            newData = parseInt(difference)

            return newData.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            })
        } else {
            newData = parseFloat(data)
            return newData.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            })
        }
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', height: 13}}>
            <div style={{width: '60%', outline: `1px ${color} solid`, borderRadius: 2}}>
                <div
                    className="progress-bar"
                    style={{background: color, width: `${progress > 100 ? 100 : progress}%`}}></div>
            </div>

            <div style={{display: 'flex', alignItems: 'center', width: '40%'}}>
                <p className="data">{setDataFromTab()}</p>
                <p className="unit">{unit}</p>
                <div
                    className={`${
                        typeNotSpecified
                            ? 'percent-not-specified-box'
                            : Number(percent) === 0
                            ? 'percent-not-specified-box'
                            : Number(percent) > 0
                            ? 'percent-box'
                            : 'percent-minus'
                    }`}>
                    {percent} %
                </div>
                {difference && (
                    <div
                        className={`${
                            typeNotSpecified
                                ? 'percent-not-specified-box'
                                : Number(difference) === 0
                                ? 'percent-not-specified-box'
                                : Number(difference) > 0
                                ? 'percent-box'
                                : 'percent-minus'
                        }`}>
                        {Number(difference) > 0 && '+'}
                        {setDifferenceTab()} {unit}
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProgressBar
