import { GET_FILTERDRONE_SUCCESS, GET_FILTERDRONE_FAIL, GET_FILTERDRONE } from "./actionTypes";

const INIT_STATE = {
  drone: [],
};

const drone = (state = INIT_STATE, action) => {
  // console.log("action",action.payload)
  switch (action.type) {
    case GET_FILTERDRONE_SUCCESS:
      return {
        ...state,
        drone: action.payload,
      };

    case GET_FILTERDRONE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default drone;
