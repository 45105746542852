import React from 'react'
import {withTranslation} from 'react-i18next'
import {Card, CardBody} from 'reactstrap'

const CardDetail = props => {
    const {colorCard, data, i18n} = props
    return (
        <Card
            style={{
                background: colorCard || '#FFFFFF',
                borderRadius: '8px',
                fontFamily: i18n.language === 'th' ? 'Kanit' : 'Inter',
            }}>
            <CardBody>
                <p style={{color: '#898989', fontSize: '14px', fontWeight: 400}}>{data?.title || '-'}</p>
                <div>
                    <span
                        style={{
                            marginRight: '8px',
                            fontFamily: 'Inter',
                            fontWeight: 700,
                            fontSize: '20px',
                        }}>
                        {data?.total || '-'}
                    </span>
                    <span style={{color: '#898989', fontFamily: i18n.language === 'th' ? 'Kanit' : 'Inter'}}>
                        {data?.unit || '-'}
                    </span>
                </div>
            </CardBody>
        </Card>
    )
}

export default withTranslation()(CardDetail)
