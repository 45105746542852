import axios from 'axios'
import accessToken from './jwt-token-access/accessToken'

//pass new generated access token here
const API_URL = 'https://kidrone-dev-skc-api.gramick.dev'

const axiosApi = axios.create({
    baseURL: API_URL,
})

axiosApi.defaults.headers.common['x-api-key'] = 'Q6vZguRy0P7c9!zyJN=0Y=9NCjC1F1Hqj!?gCv?p9AkvPzKDKIr0e2YbKqH!?BTg'

axiosApi.interceptors.response.use(
    response => response,
    error => Promise.reject(error),
)

export async function get(url, config = {}) {
    return await axiosApi
        .get(url, {...config})
        .then(response => response.data)
        .catch()
}

export async function post(url, data, config = {}) {
    return axiosApi.post(url, {...data}, {...config}).then(response => response.data)
}

export async function put(url, data, config = {}) {
    return axiosApi.put(url, {...data}, {...config}).then(response => response.data)
}

export async function del(url, config = {}) {
    return await axiosApi.delete(url, {...config}).then(response => response.data)
}
