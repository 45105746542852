import { LOADING_DRONE_ACTIVE_SUCCESS, LOADING_DRONE_ACTIVE_FAIL } from "./actionTypes";
import * as moment from "moment";

const INIT_STATE = {
  data: {
    amountDroneUsed: false,
    sprayAndWeighing: false,
    speedDroneActive: false,
    heightDroneActive: false,
  },
};

const loadingDroneActive = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING_DRONE_ACTIVE_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    case LOADING_DRONE_ACTIVE_FAIL:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default loadingDroneActive;
