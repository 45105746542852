const realtime = {
    workingDrone: 'โดรนที่กำลังทำงาน',
    total: 'ทั้งหมด',
    pilot: 'นักบิน',
    workInformation: 'ข้อมูลการทำงาน',
    flightMode: 'โหมดการบิน',
    speed: 'ความเร็วในการทำงาน',
    heightFromCrop: 'ความสูงจากพืช',
    sprayingRate: 'อัตราการฉีดพ่น',
}

export default realtime
