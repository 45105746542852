// import React, {Component} from 'react'
import {Container, Row, Col, Button, InputGroup, Input, Card, CardTitle, CardBody} from 'reactstrap'
import './index.scss'
import iconSearch from '../../assets/images/icon/icon-search.png'
import iconRightData from '../../assets/images/icon/icon-arrow-right-data.png'
import CardDroneWorking from 'components/DroneiNalyze/Real-time-Monitoring/CardDroneWorking'
import MapDroneMonitoring from 'components/DroneiNalyze/Real-time-Monitoring/MapDroneMonitoring'
import 'animate.css'
import TitleHeadRealTimeSkeleton from 'components/DroneiNalyze/Skeleton-iNalyze/Real-time-Monitoring/TitleHeadRealTimeSkeleton'

import {withTranslation} from 'react-i18next'
import iconLeftData from '../../assets/images/icon/icon-arrow-left-data.png'
import iconClose from '../../assets/images/icon/icon-close-b.png'
import React, {useEffect, useState} from 'react'
import {useRecoilState, useRecoilValue} from 'recoil'
import {monitoringState} from 'store/atoms/inalyze'
import io from 'socket.io-client'
import config from 'config'

const RealTimeMonitoring = props => {
    const {t, i18n} = props

    const [showTabDrone, setShowTabDrone] = useState(true)
    const [isFirst, setIsFirst] = useState(true)
    const [search, setSearch] = useState('')
    const [isSearch, setIsSearch] = useState(false)
    const [loadingSkeleton, setLoadingSkeleton] = useState(true)
    const [total, setTotal] = useState(0)
    const [filterDroneModels, setFilterDroneModels] = useState([])
    const [droneModel, setDroneModel] = useState([
        {
            id: 'KD1',
            name: 'K-D1',
            active: false,
        },
        {
            id: 'KD10',
            name: 'K-D10',
            active: false,
        },
        {
            id: 'KD20',
            name: 'K-D20',
            active: false,
        },
        {
            id: 'KD20P',
            name: 'K-D20P',
            active: false,
        },
        {
            id: 'KD25',
            name: 'K-D25',
            active: false,
        },
        {
            id: 'KD30',
            name: 'K-D30',
            active: false,
        },
        {
            id: 'KD40',
            name: 'K-D40',
            active: false,
        },
        {
            id: 'KD50',
            name: 'K-D50',
            active: false,
        },
    ])
    const [monitoring, setMonitoring] = useRecoilState(monitoringState)

    useEffect(() => {
        setLoadingSkeleton(false)
        const socket = io(`${config.socket.URL}`)
        socket.on('AllRealtime', data => {
            setTotal(data.filter(item => item.position != undefined).length)
            setMonitoring(cur => ({
                ...cur,
                droneModel: monitoring.droneModel.map(idm => {
                    return {...idm, drone: data.filter(item => item.hardware_model == idm.name).length}
                }),
            }))
        })
    }, [])

    const onClickFilterDroneModels = (name, i) => {
        var filter = monitoring.filters

        if (filter.includes(name)) {
            filter = filter.filter(item => item !== name)
        } else {
            filter = [...filter, name]
        }
        setDroneModel(droneModel.map((item, index) => (index === i ? {...item, active: !item.active} : item)))

        setMonitoring(cur => ({
            ...cur,
            filters: filter,
        }))
        setFilterDroneModels(filter)
    }

    const onClickToggleTabDrone = show => {
        setShowTabDrone(show)
        setIsFirst(false)
    }
    const onClearFilter = () => {
        setDroneModel(droneModel.map((item) => ({...item, active: false})))
        setMonitoring(cur => ({
            ...cur,
            filters: [],
        }))
        setFilterDroneModels([])
    }
    

    return (
        <>
            <div className="page-content">
                <Container fluid className="content-real-time-monitoring">
                    {loadingSkeleton ? (
                        <TitleHeadRealTimeSkeleton />
                    ) : (
                        <Row>
                            <Col xl={6}>
                                <p className="section-header">Real-time Monitoring</p>
                            </Col>
                            <Col xl={6} className="container-box-search">
                                <InputGroup className="box-search">
                                    <Input
                                        className="input-search"
                                        type="text"
                                        defaultValue={search}
                                        // onChange={this.onSearch}
                                        placeholder="Kubota S/N , DJI S/N"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    )}
                    <div id="page-content">
                        <Row className="container-data">
                            <div
                                style={{display: isFirst ? 'none' : ''}}
                                className={`box-btn-show-tap animate__animated ${
                                    !showTabDrone ? 'animate__fadeIn' : !isFirst ? 'animate__fadeOut' : ''
                                }`}>
                                <Button
                                    onClick={() => onClickToggleTabDrone(true)}
                                    color="link"
                                    className="btn btn-link waves-effect btn-hide btn-show-tab">
                                    <img src={iconRightData} />
                                </Button>
                            </div>
                            <Col
                                xl={5}
                                style={{
                                    position: !showTabDrone ? 'absolute' : !isFirst ? 'relative' : '',
                                }}
                                className={`animate__animated ${
                                    !showTabDrone ? 'animate__fadeOutLeft' : !isFirst ? 'animate__slideInLeft' : ''
                                }`}>
                                <div className="card-drone-working">
                                    <Card>
                                        <CardTitle>
                                            <Row>
                                                <Col lg={3} xl={11}>
                                                    <div>
                                                        <span className="section-text p-2">
                                                            {t('realtime.workingDrone')}
                                                        </span>
                                                        <span className="p-1" style={{fontWeight: 300}}>
                                                            {t('realtime.total')}
                                                        </span>
                                                        <span className="count p-1" style={{fontWeight: 300}}>
                                                            {total || '-'}
                                                        </span>
                                                        <span style={{fontWeight: 300}}>{t('common.droneUnit')}</span>
                                                        {filterDroneModels.length > 0 && <span
                                                            onClick={() => onClearFilter()}
                                                            style={{
                                                                marginLeft:8,
                                                                fontWeight: 300,
                                                                fontSize: 13,
                                                                background: '#F4F4F4',
                                                                paddingLeft: 8,
                                                                paddingRight: 8,
                                                                paddingTop: 2,
                                                                paddingBottom: 2,
                                                                borderRadius: 8,
                                                                cursor: 'pointer'
                                                            }}>
                                                            <img src={iconClose} />&nbsp;&nbsp;{'ล้างค่า'}
                                                        </span>}
                                                    </div>
                                                </Col>
                                                <Col lg={1} xl={1}>
                                                    <Button
                                                        onClick={() => onClickToggleTabDrone(false)}
                                                        color="link"
                                                        className="btn btn-link waves-effect btn-hide">
                                                        <img src={iconLeftData} />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardTitle>
                                        <CardBody>
                                            <Row>
                                                <Col xl={12} className="box-count-data">
                                                    {droneModel.map((itm, index) => (
                                                        <div
                                                            style={{
                                                                display: 'inline-flex',
                                                                border: itm.active
                                                                    ? '1px solid #F05A28'
                                                                    : '1px solid #EEF0F4',
                                                                padding: 8,
                                                                margin: 2,
                                                                borderRadius: 4,
                                                                color: itm.active ? '#FFF' : '#000',
                                                                background: itm.active ? '#F05A28' : '#FFF',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => onClickFilterDroneModels(itm.name, index)}>
                                                            {itm.name} :{' '}
                                                            {monitoring.droneModel.map(
                                                                item => item.name == itm.name && item.drone,
                                                            )}
                                                        </div>
                                                    ))}
                                                </Col>
                                                <Col xl={12}>
                                                    <CardDroneWorking
                                                        droneModels={droneModel}
                                                        filter={monitoring.filters}
                                                        search={search}
                                                        isSearch={isSearch}
                                                        showTabDrone={showTabDrone}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                            <Col
                                xl={!showTabDrone ? 12 : 7}
                                className={`animate__animated ${
                                    !showTabDrone ? 'animate__slideInRight' : !isFirst ? 'animate__slideInLeft' : ''
                                }`}>
                                <MapDroneMonitoring
                                    search={search}
                                    isSearch={isSearch}
                                    showTabDrone={showTabDrone}
                                    droneModels={droneModel}
                                    filter={monitoring.filters}
                                />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}
// class RealTimeMonitoring extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             showTabDrone: true,
//             isFirst: true,
//             search: '',
//             isSearch: false,
//             loadingSkeleton: true,
//             total: 0,
//             droneModels: [
//                 {
//                     id: 'KD1',
//                     name: 'K-D1',
//                     drone: 0,
//                     active: false,
//                 },
//                 {
//                     id: 'KD10',
//                     name: 'K-D10',
//                     drone: 0,
//                     active: false,
//                 },
//                 {
//                     id: 'KD20',
//                     name: 'K-D20',
//                     drone: 0,
//                     active: false,
//                 },
//                 {
//                     id: 'KD20P',
//                     name: 'K-D20P',
//                     drone: 0,
//                     active: false,
//                 },
//                 {
//                     id: 'KD25',
//                     name: 'K-D25',
//                     drone: 0,
//                     active: false,
//                 },
//                 {
//                     id: 'KD30',
//                     name: 'K-D30',
//                     drone: 0,
//                     active: false,
//                 },
//                 {
//                     id: 'KD40',
//                     name: 'K-D40',
//                     drone: 0,
//                     active: false,
//                 },
//                 {
//                     id: 'KD50',
//                     name: 'K-D50',
//                     drone: 0,
//                     active: false,
//                 },
//             ],
//             filterDroneModels: [],
//         }

//         this.onClickToggleTabDrone = this.onClickToggleTabDrone.bind(this)
//         // this.setTotal = this.setTotal.bind(this)
//     }

//     onClickToggleTabDrone = show => {
//         this.setState({
//             showTabDrone: show,
//             isFirst: false,
//         })
//     }

//     onSearch = e => {
//         this.setState({
//             isSearch: true,
//             search: e.target.value,
//         })

//         setTimeout(() => {
//             this.setState({
//                 isSearch: false,
//             })
//         }, 100)
//     }

//     componentDidMount() {
//         this.setState({loadingSkeleton: true})
//     }

//     componentDidUpdate() {
//         if (this.state.loadingSkeleton) {
//             setTimeout(() => {
//                 this.setState({loadingSkeleton: false})
//             }, 2000)
//         }
//     }

//     onClickFilterDroneModels = (name, i) => {
//         let filter = this.state.filterDroneModels
//         if (filter.includes(name)) {
//             filter = filter.filter(item => item !== name)
//         } else {
//             filter.push(name)
//         }
//         this.setState({
//             droneModels: this.state.droneModels.map((item, index) =>
//                 index === i ? {...item, active: !item.active} : item,
//             ),
//             filterDroneModels: filter,
//         })
//     }

//     setTotal = (total) => {
//         this.setState({
//             total
//         })
//     }

//     render() {
//         const {t} = this.props
//         const state = this.state

// }
// }
export default withTranslation()(RealTimeMonitoring)
