import React, {useState} from 'react'
import {DatePicker, Select, Button} from 'antd'
import moment from 'moment'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import i18n from '../../../i18n'

const {RangePicker} = DatePicker

const {YearPicker} = DatePicker
const CustomDateInput = ({value, onClick}) => {
    const handleButtonClick = () => {
        // Handle button click action
    }

    return (
        <div className="d-flex align-items-center justify-content-center" style={{marginTop: 10, marginBottom: 10}}>
            <Button type="primary" onClick={handleButtonClick}>
                {'\xa0\xa0\xa0' + 'แรก' + '\xa0\xa0\xa0'}
            </Button>
            <Button type="primary" onClick={handleButtonClick} style={{marginLeft: 15}}>
                {'\xa0\xa0\xa0' + 'หลัง' + '\xa0\xa0\xa0'}
            </Button>
            {/* <input
        className="ant-calendar-picker-input ant-input"
        value={value || 'Select Date'}
        placeholder="Select Date"
        readOnly
        onClick={onClick}
      /> */}
        </div>
    )
}
import styled, {css} from 'styled-components'
const StyledSelect = styled(Select)`
    /* Add your custom styles here */
    width: 100px;
    // background-color: yellow;
    // border-radius: 4px;
    // font-size: 14px;
    height: 37px;
    // border-color: #4e4e4e;
    // border-width: 1px;
    .ant-select-selector {
        border-color: red;
        height: 37px;
        background-color: lightgray;
        border-radius: 4px;
    }
    .ant-select-customize-input {
        height: 37px;
    }
    .ant-select-single {
        /* Add your custom styles here */
        background-color: lightgray;
        border-radius: 4px;
        height: 100px;
        /* Add more CSS properties as needed */
    }
`
const BoxSelect = styled.div`
    background-color: #fff;
    color: white;
    font-size: 14px;
    border-radius: 8px;
    border-width: 1px;
    border-color: #e2e2e2;
    text-align: center;
    font-family: 'Kanit';
    height: 38px;
    border-style: solid;
    margin-right: 15px;
`
const selectStyle = {
    width: 200,

    backgroundColor: 'lightgray',
    borderRadius: 4,

    // Add more CSS properties as needed
}

const HalfYearPicker = ({value, onChange, style, onSelect}) => {
    const [selectedDate, setSelectedDate] = useState(null)

    const handleDateChange = dates => {
        // Handle date change

        setSelectedDate(dates)
    }

    const formatDate = date => {
        return moment(date).format('YYYY')
    }

    return (
        <div>
            <BoxSelect>
                <Select
                    placeholder={i18n.t('common.pleaseSelectTimeRang')}
                    value={value}
                    options={[
                        {
                            label: i18n.t('common.firstHalfYear'),
                            value: 1,
                        },
                        {
                            label: i18n.t('common.secondHalfYear'),
                            value: 2,
                        },
                    ]}
                    labelInValue
                    onChange={onChange}
                    onSelect={onSelect}
                    optionFilterProp={'label'}
                    bordered={false}
                />
            </BoxSelect>
        </div>
    )
}
export default HalfYearPicker
