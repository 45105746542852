import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React, { Component, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

const CardSkeleton = ({}) => {
  return (
    <Col>
      <Card
        style={{
          // height: 600,
          display: "flex",
          borderRadius: 8,
          boxShadow: "0px 12px 24px rgb(240, 240, 240)",
        }}
      >
        <CardTitle
          style={{
            justifyContent: "flex-start",
            alignItems: "center",
            display: "flex",
            marginTop: 20,
            paddingLeft: 25,
            paddingRight: 25,
          }}
        >
          <div>
            <Skeleton height={16} width={250} />
            <Skeleton width={170} />
          </div>
        </CardTitle>
        <CardBody>
          <Row>
            <Col lg="3">
              <div className="card-skeleton ">
                <div className="d-flex align-items-center justify-content-start">
                  <Skeleton width={120} height={16} style={{}} />
           
                </div>
                <div>
                 
                  <Skeleton height={1} style={{ marginBottom: 5 }} />
                  <Skeleton style={{ marginTop: 8 }} />
                  <Skeleton height={1} style={{ marginBottom: 5 }} />
                  <Skeleton style={{ marginTop: 8 }} />
                  <Skeleton height={1} style={{ marginBottom: 5 }} />
                  <Skeleton style={{ marginTop: 8 }} />
                  <Skeleton height={1} style={{ marginBottom: 5 }} />
                  <Skeleton style={{ marginTop: 8 }} />
                  <Skeleton height={1} style={{ marginBottom: 5 }} />
                  <Skeleton style={{ marginTop: 8 }} />
                  <Skeleton height={1} style={{ marginBottom: 5 }} />
            
                </div>
              </div>
            </Col>
            <Col lg="9">
            <div className="card-skeleton " style={{ marginTop: 20 }}>
                <Skeleton height={420} />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CardSkeleton;
