import React from 'react'
import {Row, Col} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './realtime-skeleton.scss'
const TitleHeadRealTimeSkeleton = () => {
    return (
        <Row className="realtime-header-skeleton">
            <Col xl={6}>
                <Skeleton width={240} height={36} />
            </Col>
            <Col xl={6} className="container-box-search">
                <div className="box-search">
                    <Skeleton width={580} />
                </div>
            </Col>
        </Row>
    )
}

export default TitleHeadRealTimeSkeleton
