import React, {Component} from 'react'
import {Container, Row, Col, Button, InputGroup, Input} from 'reactstrap'
import './index.scss'
import MapFlightHistoryPlay from 'components/DroneiNalyze/DroneInformation/Droneinfo-2/Flight-history-play/MapFlightHistoryPlay'
import {getWayPoint} from 'store/actions'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Skeleton from 'react-loading-skeleton'

class FlightHistoryMapPlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isPlay: false,
            indexWaypoint: 0,
            maxWaypoint: 0,
            activeWaypoint: {},
            plus: 0,
            minus: 0,
            wayPoint: [],
            interval: null,
            speed: 1,
            end: false,
            isMove: false,
        }
    }

    componentDidMount() {
        const {onGetWayPoint, way_point} = this.props
        onGetWayPoint({
            hardware: `${this.props.match.params.hardwareID}`,
            id: `${this.props.match.params.id}`,
        })

        // if(way_point.length){
        // this.setState({
        //   maxWaypoint : way_point?.result?.waypoint?.length
        // })
        // }
    }

    play = () => {
        if (this.state.isPlay) {
            this.stop()
        } else {
            this.start()
        }
    }

    speedChange = async () => {
        await clearInterval(this.state.interval)
        await this.setState({
            speed: this.state.speed == 4 ? 1 : this.state.speed + 1,
        })
        await this.start()
    }

    plus = () => {
        const {way_point} = this.props
        this.setState({
            indexWaypoint:
                this.state.indexWaypoint + 10 > way_point.result?.waypoint?.length
                    ? way_point.result?.waypoint?.length - 1
                    : this.state.indexWaypoint + 10,
        })
    }

    minus = () => {
        // if((this.state.indexWaypoint - 10) < 0){
        this.setState({
            indexWaypoint: this.state.indexWaypoint - 10 < 0 ? 0 : this.state.indexWaypoint - 10,
        })
        // }else{
        //   this.setState({
        //     indexWaypoint: (this.state.indexWaypoint - 10) > 0 ? 0 : this.state.indexWaypoint - 10
        //   })
        // }
    }

    start = () => {
        if (this.state.end) {
            let {way_point} = this.props
            console.log(way_point)
            this.setState({
                indexWaypoint: 0,
                wayPoint: [],
            })
            way_point = []
        }
        this.setState({
            isPlay: true,
        })
        let vm = this
        this.setState({
            interval: setInterval(() => {
                const {way_point} = this.props
                this.setState({
                    isMove: true,
                })
                if (this.state.indexWaypoint < way_point.result.waypoint.length - 1) {
                    this.setState({
                        indexWaypoint: this.state.indexWaypoint + 1,
                    })
                } else {
                    this.setState({
                        end: true,
                    })
                    vm.stop()
                }
            }, 1000 / this.state.speed),
        })
    }

    stop = () => {
        clearInterval(this.state.interval)
        this.setState({
            isPlay: false,
        })
    }

    render() {
        const state = this.state
        const {way_point} = this.props
        // console.log(way_point)
        console.log('this.state.showTabDrone :>> ', this.state.showTabDrone)

        return (
            <>
                {way_point != undefined && way_point?.result?.flight != undefined ? (
                    <div className="page-content">
                        <Container fluid className="content-flight-map-play">
                            <div id="page-content">
                                <Row>
                                    <Col xl={12} lg={12}>
                                        <MapFlightHistoryPlay
                                            data={way_point?.result?.flight}
                                            summary={way_point?.result?.summary}
                                            waypoint={way_point?.result?.waypoint[this.state.indexWaypoint]}
                                            ways={way_point?.result?.waypoint}
                                            play={this.play}
                                            isPlay={this.state.isPlay}
                                            plus={this.plus}
                                            minus={this.minus}
                                            indexWaypoint={this.state.indexWaypoint}
                                            speedChange={this.speedChange}
                                            speed={this.state.speed}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                ) : (
                    <div className="page-content">
                        <Skeleton width={1641} height={750} />
                    </div>
                )}
            </>
        )
    }
}

FlightHistoryMapPlay.propTypes = {
    onGetWayPoint: PropTypes.func,
    way_point: PropTypes.any,
}

const mapStateToProps = ({way_point}) => ({
    way_point: way_point.way_point,
})

const mapDispatchToProps = dispatch => ({
    onGetWayPoint: data => dispatch(getWayPoint(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FlightHistoryMapPlay)
// export default ;
