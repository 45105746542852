import {getWithQuery, get} from '../../helpers/api_helper'

import {ENDPOINT} from 'constants/endpoint'

export const getSearchDroneInformation = async params => {
    try {
        const response = await getWithQuery(`${ENDPOINT.DRONE_INFORMATION_SEARCH}?search=${params}`)

        return response
    } catch (error) {
        return null
    }
}

export const getDroneInformationTable = async params => {
    try {
        const response = await getWithQuery(
            `${ENDPOINT.DRONE_INFORMATION_TABLE}?search=${params.search}&page=${params.page}&page_size=${params.pageSize}&sort=${params.sort}&sort_direction=${params.sort_direction}&work_type=${params.work_type}&drone=${params.drone}&lowest_hour=${params.lowest_hour}&highest_hour=${params.highest_hour}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getOverAllData = async params => {
    try {
        const response = await get(`${ENDPOINT.DRONE_INFORMATION_OVERALL}/${params.id}`)

        return response
    } catch (error) {
        return null
    }
}

export const getDroneHardwareID = async params => {
    try {
        const response = await get(`${ENDPOINT.HARDWARE_ID}/${params}`)

        return response
    } catch (error) {
        return null
    }
}

export const getUsageDetail = async params => {
    try {
        const response = await getWithQuery(
            `${ENDPOINT.DRONE_INFORMATION_USAGE_DETAIL}/${params.id}?type=${params.type}&year=${params.year}&month=${params.month}&week=${params.week}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getUsageFlightDuration = async params => {
    try {
        const response = await getWithQuery(
            `${ENDPOINT.DRONE_INFORMATION_USAGE_FLIGHT_DURATION}/${params.id}?type=${params.type}&year=${params.year}&month=${params.month}&week=${params.week}&start_date=${params.start_date}&end_date=${params.end_date}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getUsageWorkingArea = async params => {
    try {
        const response = await getWithQuery(
            `${ENDPOINT.DRONE_INFORMATION_USAGE_WORKING_AREA}/${params.id}?type=${params.type}&year=${params.year}&month=${params.month}&week=${params.week}&start_date=${params.start_date}&end_date=${params.end_date}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getUsageFlightHistory = async params => {
    try {
        const response = await getWithQuery(
            `${ENDPOINT.DRONE_INFORMATION_USAGE_FLIGHT_HISTORY}?drone_id=${params.id}&type=${params.type}&week=${params.week}&month=${params.month}&year=${params.year}&start_date=${params.start_date}&end_date=${params.end_date}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getUsageDroneFlightListsTable = async params => {
    try {
        const response = await getWithQuery(
            `${ENDPOINT.DRONE_INFORMATION_USAGE_FLIGHT_LIST_TABLE}/${params.id}?type=${params.type}&month=${
                params.month
            }&week=${params.week}&year=${params.year}&page=${params.page}&pageSize=${params.pageSize}&search=${
                params.search || ''
            }&sortBy=${params.sortBy || ''}&upOrDown=${params.upOrDown}&start_date=${params.start_date}&end_date=${
                params.end_date
            }&idType=${params.idType}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getFlightHistoryDetail = async params => {
    try {
        const response = await get(`${ENDPOINT.DRONE_INFORMATION_FLIGHT_HISTORY_DETAIL}/${params}`)

        return response
    } catch (error) {
        return null
    }
}
