import {useEffect, useState} from 'react'
import {Col, Card, CardBody} from 'reactstrap'
import {withTranslation} from 'react-i18next'
import {useRecoilState, useRecoilValue} from 'recoil'

import iconUser from '../../assets/images/icon/iconuser.png'
import iconDrone from '../../assets/images/icon/icondrone.png'
import iconPlot from '../../assets/images/icon/iconplot.png'

import {filterDashboardState, loadingDashboardState} from 'store/atoms/dashboard'

import {getCumulativeCard} from 'services/skc/new-dashboard'

import CumulativeSkeleton from './CardSkeleton/CumulativeSkeleton'

const Cumulative = props => {
    const {t} = props

    const [loading, setLoading] = useRecoilState(loadingDashboardState)
    const filterDashboard = useRecoilValue(filterDashboardState)
    const [data, setData] = useState([])
    const [loadingSkeleton, setLoadingSkeleton] = useState(true)

    useEffect(() => {
        if (loading.cumulative) {
            fetchCumulative()
            setLoading(cur => ({...cur, cumulative: false}))
        }
    }, [loading.cumulative])

    useEffect(() => {
        fetchCumulative()
    }, [])

    const fetchCumulative = async () => {
        try {
            setLoadingSkeleton(true)
            const response = await getCumulativeCard(filterDashboard)

            if (response.status_code === 200 && response.results) {
                setDataToCard(response.results)
            }
            setLoading(cur => ({...cur, cumulative: false}))
        } catch (error) {
            setData([])
        } finally {
            setLoadingSkeleton(false)
        }
    }

    const setDataToCard = data => {
        let nf = new Intl.NumberFormat()
        const newData = []

        if (data.users) {
            newData.push({
                type: 'user',
                icon: iconUser,
                title: t(`dashboard.cumulativeUsers`),
                value: nf.format(data.users.total_users),
                badgeValue: data.users.users_percentage,
                color:
                    parseFloat(data.users.users_percentage) > 0
                        ? 'success'
                        : parseFloat(data.users.users_percentage) < 0
                        ? 'danger'
                        : 'warning',
                mark:
                    parseFloat(data.users.users_percentage) > 0
                        ? '+'
                        : parseFloat(data.users.users_percentage) < 0
                        ? '-'
                        : '',
            })
        }

        if (data.drones) {
            newData.push({
                type: 'drone',
                icon: iconDrone,
                title: t(`dashboard.cumulativeDrones`),
                value: nf.format(data.drones.total_drones),
                badgeValue: data.drones.drones_percentage,
                color:
                    parseFloat(data.drones.drones_percentage) > 0
                        ? 'success'
                        : parseFloat(data.drones.drones_percentage) < 0
                        ? 'danger'
                        : 'warning',
                mark:
                    parseFloat(data.drones.drones_percentage) > 0
                        ? '+'
                        : parseFloat(data.drones.drones_percentage) < 0
                        ? '-'
                        : '',
            })
        }
        if (data.area) {
            newData.push({
                type: 'area',
                icon: iconPlot,
                title: t(`dashboard.totalAmountOfUserPlotSpaceRai`),
                value: nf.format(data.area.total_area),
                badgeValue: data.area.area_percentage,
                color:
                    parseFloat(data.area.area_percentage) > 0
                        ? 'success'
                        : parseFloat(data.area.area_percentage) < 0
                        ? 'danger'
                        : 'warning',
                mark:
                    parseFloat(data.area.area_percentage) > 0
                        ? '+'
                        : parseFloat(data.area.area_percentage) < 0
                        ? '-'
                        : '',
            })
        }

        setData(newData)
    }

    return (
        <>
            {loadingSkeleton ? (
                <CumulativeSkeleton amount={3} />
            ) : (
                <>
                    {data.length > 0 &&
                        data?.map((items, key) => (
                            <Col key={key}>
                                <Card
                                    style={{
                                        borderRadius: 8,
                                        boxShadow: '0px 12px 24px rgb(240, 240, 240)',
                                    }}>
                                    <CardBody>
                                        <div className="d-flex align-items-center mb-3">
                                            <div className="avatar-xs me-3">
                                                {items.type === 'user' && (
                                                    <img
                                                        src={iconUser}
                                                        alt=""
                                                        height="32"
                                                        style={{boxShadow: 'none'}}
                                                    />
                                                )}
                                                {items.type === 'drone' && (
                                                    <img
                                                        src={iconDrone}
                                                        alt=""
                                                        height="32"
                                                        style={{boxShadow: 'none'}}
                                                    />
                                                )}
                                                {items.type === 'area' && (
                                                    <img
                                                        src={iconPlot}
                                                        alt=""
                                                        height="32"
                                                        style={{boxShadow: 'none'}}
                                                    />
                                                )}
                                            </div>
                                            <h5
                                                className="mb-0"
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    color: '#495057',
                                                }}>
                                                {items.type === 'user' && t(`dashboard.cumulativeUsers`)}
                                                {items.type === 'drone' && t(`dashboard.cumulativeDrones`)}
                                                {items.type === 'area' && t(`dashboard.totalAmountOfUserPlotSpaceRai`)}
                                            </h5>
                                        </div>
                                        <div className="text-muted mt-4">
                                            <h4
                                                style={{
                                                    fontSize: 24,
                                                    fontWeight: 700,
                                                    fontFamily: 'Inter',
                                                    color: '#495057',
                                                }}>
                                                {items.value}
                                                {items.color === 'success' && (
                                                    <i className="mdi mdi-chevron-up ms-1 text-success" />
                                                )}
                                                {items.color === 'danger' && (
                                                    <i className="mdi mdi-chevron-down ms-1 text-danger" />
                                                )}
                                            </h4>
                                            {filterDashboard.type !== 'period' && (
                                                <div className="d-flex">
                                                    <span
                                                        className={'badge badge-soft-' + items.color + ' font-size-12'}
                                                        style={{
                                                            fontSize: 11,
                                                            fontWeight: 600,
                                                            fontFamily: 'Inter',
                                                        }}>
                                                        {' '}
                                                        {items.mark} {items.badgeValue}%{' '}
                                                    </span>{' '}
                                                    <span
                                                        className="ms-2 text-truncate"
                                                        style={{
                                                            fontSize: 13,
                                                            fontWeight: 400,
                                                            color: '#79829C',
                                                        }}>
                                                        {filterDashboard.type === 'year' && t(`common.lastYearAgo`)}
                                                        {filterDashboard.type === 'month' && t(`common.lastMonthAgo`)}
                                                        {filterDashboard.type === 'week' && t(`common.lastWeekAgo`)}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                </>
            )}
        </>
    )
}

export default withTranslation()(Cumulative)
