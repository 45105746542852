import { del, get, post, put, upload } from "../../helpers/api_kafka_helper";
import * as apiSkc from "../../helpers/api_helper";
import * as url from "../../constants/url_helper/kafka";

const getWayPoint = ({ hardware, id }) => get(`${url.GET_WAY_POINT}/${hardware}/${id}`);

const getSpeedDroneInfo = ({ type, month, year, week, id, data_type, start_date, end_date }) =>
  get(
    url.GET_SPEED_HEIGHT_DRONE +
      `/${id}?data_type=${data_type}&type=${type}&month=${month}&week=${week}&year=${year}&start_date=&${start_date}&end_date=${end_date}`
  );

const getFlightHistoryDroneListsInfo = ({
  type,
  month,
  year,
  week,
  id,
  start_date,
  end_date,
  page,
  size,
  search,
  sort,
  direction,
}) =>
  get(
    url.GET_FLIGHT_HISTORY_DRONE_LISTS_INFO +
      `/${id}?type=${type}&month=${month}&week=${week}&year=${year}&start_date=${start_date}&end_date=${end_date}&page=${page}&size=${size}&search=${
        search ?? ""
      }&sort=${sort}&direction=${direction}`
  );

const getDroneFlightSummaryInfo = ({ id, flightId }) =>
  apiSkc.get(url.GET_DRONE_FLIGHT_SUMMARY_INFO + `/${flightId}`);

const getUserSpeedDroneInfo = ({ type, month, year, week, data_type, start_date, end_date, drones }) =>
  post(
    url.GET_USER_SPEED_HEIGHT_DRONE +
      `?data_type=${data_type}&type=${type}&month=${month}&week=${week}&year=${year}&start_date=&${start_date}&end_date=${end_date}`,
    { drones: drones }
  );

const getFlightHistoryUserLists = ({
  type,
  month,
  year,
  week,
  id,
  start_date,
  end_date,
  page,
  size,
  search,
  sort,
  direction,
}) =>
  get(
    url.GET_FLIGHT_HISTORY_DRONE_LISTS_INFO +
      `/${id}?type=${type}&month=${month}&week=${week}&year=${year}&start_date=${start_date}&end_date=${end_date}&page=${page}&size=${size}&search=${
        search ?? ""
      }&sort=${sort}&direction=${direction}`
  );

const getSpeedHeightDroneActive = ({ type, month, year, week, id, data_type, start_date, end_date }) =>
  get(
    url.GET_SPEED_HEIGHT_DRONE_ACTIVE +
      `/${id}?data_type=${data_type}&type=${type}&month=${month}&week=${week}&year=${year}&start_date=&${start_date}&end_date=${end_date}`
  );
const getDroneStatus = data => post(url.GET_DRONE_STATUS, { hardware: data });

export {
  getWayPoint,
  getSpeedDroneInfo,
  getFlightHistoryDroneListsInfo,
  getDroneFlightSummaryInfo,
  getUserSpeedDroneInfo,
  getFlightHistoryUserLists,
  getSpeedHeightDroneActive,
  getDroneStatus,
};
