import React, {Component} from 'react'
import {Row, Col, Button} from 'reactstrap'

import {GoogleMap, Marker, Polyline, useLoadScript} from '@react-google-maps/api'
import './index.scss'
import iconMarkerMap from '../../../../../assets/images/icon/icon-marker-map.png'
import iconClose from '../../../../../assets/images/icon/icon-close.png'
import TabFlightHistoryPlaySlide from './TabFlightHistoryPlaySlide'
import DetailMap from './DetailMap'
import DetailDroneFlightHistoryPlay from './DetailDroneFlightHistoryPlay'
import {withRouter} from 'react-router-dom'

import 'leaflet/dist/leaflet.css' // sass
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet-rotatedmarker'
import config from 'config'
import {withTranslation} from 'react-i18next'

let map = null
let markers = L.markerClusterGroup({
    showCoverageOnHover: false,
})
class MapFlightHistoryPlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            polylines: [],
            index: -1,
            degree: 0,
        }

        this.onClickBack = this.onClickBack.bind(this)
    }

    onClickBack = () => {
        const {history, location} = this.props

        // console.log(location.state.flightId)
        // if(location.state.backTo && location.state.backTo != undefined){
        // history.push(`${location.state.backTo}`);
        history.goBack()
        // }else{
        //   if (location.state.path === "drone") {
        //     history.push(`/drone-information`);
        //   }

        //   if (location.state.path === "user") {
        //     history.push(`/user-information`);
        //   }
        // }
    }

    componentDidMount() {
        map = L.map('map').setView(
            [
                this.props.ways[this.props.ways.length - 1]?.latitude,
                this.props.ways[this.props.ways.length - 1]?.longitude,
            ],
            10,
        )
        // const vm = this
        // let markers = L.markerClusterGroup({
        //   showCoverageOnHover: false,
        // })
        // const icon = L.icon({
        //   iconUrl: `${config.s3.URL}arrow-drone2.svg`,
        //   iconSize: [32, 32],
        // })

        markers = L.markerClusterGroup({
            showCoverageOnHover: false,
        })

        L.tileLayer(`${config.google.MAP_VT}`, {
            maxZoom: 20,
            zoom: 16,
            // attribution: '© OpenStreetMap'
        }).addTo(map)

        // var polylines = [];
        // this.props.ways.forEach((e,i)=>{
        //   polylines.push([
        //     e.latitude,e.longitude
        //   ])

        //   if((this.props.ways.length - 1) == i){
        //     const marker = L.marker([e.latitude,e.longitude],{ icon,rotationOrigin:'center center' })
        //     markers.addLayer(marker)

        //     // this.setState({
        //     //   positionLatest : newPosition
        //     // })
        //   }
        // })

        // var polyline = L.polyline(polylines, {color: '#FFB800'}).addTo(map);
        // map.fitBounds(polyline.getBounds());
        // map.addLayer(markers)
    }

    async componentWillUpdate() {
        // map = L.map('map').setView([this.props.ways[this.props.indexWaypoint].latitude,this.props.ways[this.props.indexWaypoint].longitude], 10)
        // const
        // const vm = this

        if (this.state.index != this.props.indexWaypoint) {
            if (this.props.ways[this.props.indexWaypoint] == 0) {
                this.setState({
                    polylines: [],
                })
            }
            markers.clearLayers()
            markers.remove()
            map.removeLayer(markers)

            // let degree = await this.calculateDegree(
            //   this.props.ways[this.props.indexWaypoint-1]?.velocity?.velocityX ?? 0,this.props.ways[this.props.indexWaypoint-1]?.velocity?.velocityY ?? 0,
            //   this.props.ways[this.props.indexWaypoint]?.velocity?.velocityX ?? 0,this.props.ways[this.props.indexWaypoint]?.velocity?.velocityY ?? 0
            // )

            // if(parseInt(degree) > 0){
            //   this.setState({
            //     degree
            //   })
            // }

            // console.log(degree)

            // this.props.ways.forEach((e,i)=>{
            try {
                const marker = L.marker(
                    [
                        this.props.ways[this.props.indexWaypoint - 1]?.latitude,
                        this.props.ways[this.props.indexWaypoint - 1]?.longitude,
                    ],
                    {
                        icon: L.icon({
                            iconUrl: `${config.s3.URL}icon-drone-new.svg`,
                            iconSize: [32, 32],
                        }),
                        rotationOrigin: 'center center',
                        rotationAngle: this.props.ways[this.props.indexWaypoint].degree,
                    },
                )

                markers.addLayer(marker)
                map.addLayer(markers)

                this.setState(prevState => ({
                    polylines: [
                        ...prevState.polylines,
                        [
                            this.props.ways[this.props.indexWaypoint]?.latitude,
                            this.props.ways[this.props.indexWaypoint]?.longitude,
                        ],
                    ],
                }))

                if (this.state.polylines.length > 0) {
                    var polyline = L.polyline(this.state.polylines, {color: '#FFB800'}).addTo(map)
                    map.fitBounds(polyline.getBounds())
                }
            } catch (error) {}

            this.setState({
                index: this.props.indexWaypoint,
            })
        }
    }

    calculateDegree = (x1, y1, x2, y2) => {
        // console.log(x1, y1, x2, y2)
        const dy = y2 - y1
        const dx = x2 - x1
        let angle = 0

        if (dy > dx) {
            angle = (Math.atan2(dy, dx) * 180) / Math.PI
        } else {
            angle = (Math.atan2(dx, dy) * 180) / Math.PI
        }

        if (angle < 0) {
            angle += 360
        }

        // console.log(angle)
        return angle
    }
    render() {
        const state = this.props

        return state ? (
            <Row className="row-map-flight-play">
                <Col lg={12} xl={12} style={{margin: 0, padding: 0}}>
                    <div className="box-btn-close-flight-play">
                        <Button className="btn-close-flight-play" onClick={() => this.onClickBack()}>
                            <img src={iconClose} />
                            <span className="btn-text">{state.t('common.close')}</span>
                        </Button>
                    </div>
                </Col>
                <Col lg={12} xl={12} style={{margin: 0, padding: 0}}>
                    {/* <MapComponent /> */}
                    <div id="map"></div>
                </Col>
                <Col lg={12} xl={12} style={{display: 'flex', justifyContent: 'center', margin: 0, padding: 0}}>
                    <div className="row-tab-play">
                        <TabFlightHistoryPlaySlide
                            data={state.data}
                            play={state.play}
                            plus={state.plus}
                            minus={state.minus}
                            isPlay={state.isPlay}
                            indexWaypoint={state.indexWaypoint}
                            speedChange={state.speedChange}
                            speed={state.speed}
                        />
                    </div>
                </Col>
                <Col lg={12} xl={12} style={{display: 'flex', justifyContent: 'center', margin: 0, padding: 0}}>
                    <div className="row-detail-drone">
                        <DetailDroneFlightHistoryPlay
                            flight={state.data}
                            ways={state.ways}
                            waypoint={state.waypoint}
                            indexWaypoint={state.indexWaypoint}
                            isPlay={state.isPlay}
                        />
                    </div>
                </Col>
                <Col lg={12} xl={12} style={{display: 'flex', margin: 0, padding: 0}}>
                    <div className="row-detail-map">
                        <DetailMap flight={state.data} summary={state.summary} waypoint={state.waypoint} />
                    </div>
                </Col>
            </Row>
        ) : (
            <></>
        )
    }
}

export default withRouter(withTranslation()(MapFlightHistoryPlay))
