export const ColumnsDroneInformation = [
    {
        key: 'kbt_sn',
        title_en: 'KBT SN',
        title_th: 'KBT SN',
        textAlign: 'center',
        sort: true,
        width: 200,
    },
    {
        key: 'first_name',
        title_en: 'First name',
        title_th: 'ชื่อ',
        textAlign: 'center',
        sort: true,
        width: 200,
    },
    {
        key: 'last_name',
        title_en: 'Last name',
        title_th: 'นามสกุล',
        textAlign: 'center',
        sort: true,
        width: 200,
    },

    {
        key: 'mobile_phone_no',
        title_en: 'Mobile',
        title_th: 'เบอร์โทร',
        textAlign: 'center',
        sort: true,
        width: 200,
    },
    {
        key: 'model_name',
        title_en: 'Drone model',
        title_th: 'รุ่นโดรน',
        textAlign: 'center',
        sort: true,
        width: 300,
    },
    {
        key: 'sale_date',
        title_en: 'Date of purchase',
        title_th: 'วันที่ซื้อโดรน',
        textAlign: 'center',
        sort: true,
        width: 300,
    },
    {
        key: 'sale_date_number',
        title_en: 'Number of purchase',
        title_th: 'จำนวนวันที่ซื้อ',
        textAlign: 'left',
        sort: true,
        width: 350,
    },
    {
        key: 'company_code',
        title_en: 'Dealer ID',
        title_th: 'รหัสตัวแทนจำหน่าย',
        textAlign: 'center',
        sort: true,
        width: 400,
    },
    {
        key: 'sum_time',
        title_en: 'Total working hours',
        title_th: 'ชม.ทำงานทั้งหมด',
        textAlign: 'left',
        sort: true,
        width: 300,
    },
    {
        key: 'spray_time',
        title_en: 'Hours of spraying',
        title_th: 'ชั่วโมงที่ฉีดพ่น',
        textAlign: 'left',
        sort: true,
        width: 300,
    },
    {
        key: 'spray_area',
        title_en: 'Number of  sprayed',
        title_th: 'จำนวนไร่ที่ฉีดพ่น',
        textAlign: 'left',
        sort: true,
        width: 350,
    },
    {
        key: 'spread_time',
        title_en: 'Hours of spread',
        title_th: 'ชั่วโมงที่หว่าน',
        textAlign: 'left',
        sort: true,
        width: 300,
    },
    {
        key: 'spread_area',
        title_en: 'Number of spread',
        title_th: 'จำนวนไร่ที่หว่าน',
        textAlign: 'left',
        sort: true,
        width: 300,
    },
]
