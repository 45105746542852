import React from 'react'
import {DatePicker, Space} from 'antd'

import dayjs from 'dayjs'
import 'dayjs/locale/th'

const {RangePicker} = DatePicker

const customPicker = ({value, onChange}) => {
    const disabledDate = current => {
        return current && current > dayjs().endOf('day')
    }
    return (
        <Space direction="vertical" size={13}>
            <RangePicker
                PanelMode="date"
                style={{
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    fontSize: 14,
                    borderColor: '#CED4DA',
                    borderRadius: 8,
                    color: '#495057',
                    height: 38,
                }}
                format="DD MMM YYYY"
                defaultValue={value}
                disabledDate={disabledDate}
                bordered={true}
                onChange={onChange}
            />
        </Space>
    )
}
export default customPicker
