import dashboard from './dashboard'
import menu from './menu'
import common from './common'
import inalyze from './inalyze'
import realtime from './realtime'
import droneInformation from './droneInformation'
import userInalyze from './userInalyze'
import userInformation from './userInformation'

export const TH = {
    dashboard,
    menu,
    common,
    inalyze,
    realtime,
    droneInformation,
    userInalyze,
    userInformation,
}

export default TH
