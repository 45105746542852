import React from 'react'
import './skeleton-droneInformation.scss'
import {Row, Col} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonTableDroneInformation = () => {
    return (
        <Row className="justify-content-center d-flex">
            <Col lg={'12'}>
                <Skeleton width={'100%'} height={500} />
            </Col>
        </Row>
    )
}

export default SkeletonTableDroneInformation
