import React, {Component} from 'react'
import {Table} from 'reactstrap'
import {Pagination} from 'antd'
import './index.scss'
import {withTranslation} from 'react-i18next'
class DataTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sortType: 'ASC',

            page: 1,
            pageSize: 10,
            defaultPage: 1,
            showIndex: this.props.isShowIndex ?? true,
            borderLine: this.props.isShowBorderLine ?? true,
        }

        this.handleSort = this.handleSort.bind(this)
    }
    handleSort(columnName) {
        let sortType

        const sortedData = [...this.props.row].sort((a, b) => {
            if (this.state.sortType == 'ASC') {
                sortType = 'DESC'
            } else {
                sortType = 'ASC'
            }
        })

        if (columnName === 'hour') {
            this.props.onSort('hoursort', sortType)
        } else {
            this.props.onSort(columnName, sortType)
        }

        this.setState({row: sortedData, sortType})
        this.state.row = sortedData
    }

    handleChangePagnation(page, pageSize) {
        this.props.onChangePagination(page, pageSize)
        this.setState({page, pageSize})
    }

    render() {
        const state = this.state

        const {t} = this.props

        return (
            <>
                <div className="table-responsive ">
                    <Table
                        className="table table-hover  table-bordered  mb-0 data-table white-bg"
                        style={{
                            borderLeft: state.isShowBorderLine ? '' : 'none',
                            borderRight: state.isShowBorderLine ? '' : 'none',
                            margin: 0,
                            marginTop: 12,
                            padding: 0,
                        }}>
                        <thead className="table-header">
                            <tr
                                style={{
                                    boxShadow: 'none',
                                }}>
                                {state.showIndex && (
                                    <th style={{backgroundColor: '#F3F6F9'}} className="column-index">
                                        #
                                    </th>
                                )}
                                {this.props.column.map((itm, index) => (
                                    <th
                                        key={`${itm.key}-${index}`}
                                        style={{
                                            backgroundColor: '#F3F6F9',
                                            textAlign: itm.textAlign,
                                            borderRight: '1px rgba(73, 80, 87, 0.1) solid',
                                            borderLeft: !state.isShowBorderLine ?? 'none',
                                            borderRight: !state.isShowBorderLine ?? 'none',
                                        }}>
                                        <div className="container-text-column">
                                            {itm.title}
                                            {itm.sort && (
                                                <i
                                                    className="bx bx-sort icon-sort"
                                                    onClick={() => this.handleSort(itm.key)}></i>
                                            )}
                                        </div>
                                    </th>
                                ))}
                                {this.props.action?.length > 0 && (
                                    <th className="column-action" style={{backgroundColor: '#F3F6F9'}}>
                                        Action
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.row.length > 0 ? (
                                this.props.row.map((itemRow, indexRow) => (
                                    <tr key={`${itemRow}-${indexRow}`}>
                                        {state.showIndex && (
                                            <th
                                                scope="row"
                                                width={'3%'}
                                                style={{textAlign: 'center', fontFamily: 'Inter'}}>
                                                {(this.props.rowPerPage - 1) * this.props.pageSize + (indexRow + 1)}
                                            </th>
                                        )}
                                        {this.props.column.map((itemColumn, columnIndex) => (
                                            <td
                                                key={`${itemRow[itemColumn.key]}-${columnIndex}`}
                                                style={{
                                                    width: itemColumn.width,
                                                    color: itemColumn.color,
                                                    borderLeft: !state.isShowBorderLine ?? 'none',
                                                    borderRight: !state.isShowBorderLine ?? 'none',
                                                }}
                                                className="row-data">
                                                {itemColumn.setColor ? (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}>
                                                        <div
                                                            style={{
                                                                background:
                                                                    itemRow[itemColumn.key] ===
                                                                    itemColumn.checkChangeColor
                                                                        ? itemColumn.background1
                                                                        : itemRow[itemColumn.key]
                                                                        ? itemColumn.background2
                                                                        : '',
                                                                color:
                                                                    itemRow[itemColumn.key] ===
                                                                    itemColumn.checkChangeColor
                                                                        ? itemColumn.color1
                                                                        : itemRow[itemColumn.key]
                                                                        ? itemColumn.color2
                                                                        : '',
                                                                borderRadius: '16px',
                                                                width: 66,
                                                                height: 27,
                                                                padding: 8,
                                                                textAlign: 'center',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}>
                                                            <p style={{margin: 0, padding: 0}}>
                                                                {itemRow[itemColumn.key] ?? '-'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {itemColumn.key === 'area_name' ? (
                                                            <div
                                                                style={{
                                                                    alignItems: 'center',
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    height: '100%',
                                                                    marginLeft: '20px',
                                                                }}>
                                                                <p
                                                                    style={{
                                                                        margin: 0,
                                                                        padding: 0,
                                                                        marginTop: 4,
                                                                        wordWrap: 'break-word',
                                                                    }}>
                                                                    {itemRow[itemColumn.key] ?? '-'}
                                                                </p>
                                                                {itemRow?.latitude || itemRow.longitude ? (
                                                                    <p
                                                                        style={{
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            fontFamily: 'Inter',
                                                                            color: '#9E9E9E',
                                                                            fontSize: 12,
                                                                        }}>
                                                                        {itemRow?.latitude || '-'} /{' '}
                                                                        {itemRow?.longitude || '-'}
                                                                    </p>
                                                                ) : (
                                                                    <p
                                                                        style={{
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            fontFamily: 'Inter',
                                                                            color: '#9E9E9E',
                                                                            fontSize: 12,
                                                                        }}>
                                                                        -
                                                                    </p>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    width: '100%',

                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    height: '100%',
                                                                    marginLeft: '20px',
                                                                }}>
                                                                <p style={{margin: 0, padding: 0}}>
                                                                    {itemRow[itemColumn.key] ?? '-'}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </td>
                                        ))}
                                        {this.props.action?.length > 0 && (
                                            <td
                                                style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    borderLeft: !state.isShowBorderLine ?? 'none',
                                                    borderRight: !state.isShowBorderLine ?? 'none',
                                                }}
                                                className="row-action"
                                                width="5%">
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        margin: '4px',
                                                        padding: 0,
                                                    }}>
                                                    {this.props.action.map((itm, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                margin: 2,
                                                            }}
                                                            onClick={() =>
                                                                itm.onClick(
                                                                    itemRow.dji_record_id,
                                                                    itemRow?.hardware_id,
                                                                    itemRow?.drone_model,
                                                                    itemRow?.id,
                                                                )
                                                            }>
                                                            {itm.button}
                                                        </div>
                                                    ))}
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                ))
                            ) : (
                                <tr key={0} className="text-center py-5 px-4">
                                    <th colSpan={this.props.column.length + 2}>{t('common.noData')}</th>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                {this.props.isPagination && (
                    <div
                        className="container-pagination"
                        style={{
                            marginTop: 20,
                            marginBottom: 16,
                        }}>
                        <Pagination
                            defaultCurrent={this.props.rowPerPage}
                            total={this.props.total == 0 ? 1 : this.props.total}
                            defaultPageSize={this.props.pageSize}
                            pageSize={this.props.pageSize}
                            onChange={(page, pageSize) => this.handleChangePagnation(page, pageSize)}
                        />
                    </div>
                )}
            </>
        )
    }
}

export default withTranslation()(DataTable)
