import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React, { Component, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import "./style.scss";
const CardSkeleton = ({ amount }) => {
  const loadCards = Array(amount).fill(1);
  return loadCards.map((card, i) => (
    <Col key={i}>
      <Card
        style={{
          borderRadius: 8,
          boxShadow: "0px 12px 24px rgb(240, 240, 240)",
        }}
      >
        <CardBody>
          <div className="card-skeleton " >
            <div className="d-flex align-items-center justify-content-between" style={{ marginBottom: 30 }}>
              <Skeleton width={150} height={16} />
              <Skeleton width={40} height={16} />
            </div>
            <div className="d-flex align-items-center justify-content-between " >
              <div>
                <Skeleton width={100} height={16} />
              <Skeleton width={150} height={16} /> 
                </div>
              <Skeleton width={32} height={32} />
            </div>

          </div>
        </CardBody>
      </Card>
    </Col>
  ));
};

export default CardSkeleton;
