import React from 'react'
import {Row, Col, Card, CardBody} from 'reactstrap'

import './index.scss'

import iconDroneBlue from '../../../../../assets/images/icon/icon-drone-blue.png'
import iconCalendarCheck from '../../../../../assets/images/icon/icon-calendar-check.png'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
let localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const CardDroneDateDetail = props => {
    const {data} = props

    return (
        <Row className="row row-card-drone-date-detail">
            <Col lg={6} xl={6} className="col-card-drone">
                <Card className="card-drone">
                    <div className="line"></div>
                    <CardBody>
                        <Row>
                            <Col lg={2} xl={2} className="col-box-icon-drone">
                                <div className="box-icon-drone">
                                    <img src={iconDroneBlue} />
                                </div>
                            </Col>
                            <Col lg={10} xl={10}>
                                <p className="title-drone-name">{data?.model || '-'}</p>
                                <p className="sub-title-drone">Flight record No. {data?.dji_record_id || '-'} </p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={6} xl={6}>
                <Card className="card-date">
                    <div className="line"></div>
                    <CardBody>
                        <Row>
                            <Col lg={2} xl={2} className="col-box-icon-date">
                                <div className="box-icon-calendar">
                                    <img src={iconCalendarCheck} />
                                </div>
                            </Col>
                            <Col lg={10} xl={10}>
                                <p className="title-date">
                                    {data?.time_start ? dayjs(data?.time_start).format('DD/MM/YYYY') : '-'}
                                </p>
                                <p className="sub-title">
                                    Flying time{' '}
                                    {data?.time_start
                                        ? new Date(dayjs(data?.time_start).format('L LT'))
                                              .toLocaleTimeString('th-TH')
                                              .slice(0, 5)
                                        : '-'}
                                </p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default CardDroneDateDetail
