import React, {useState, useEffect} from 'react'
import {withTranslation} from 'react-i18next'
import {Row, Col} from 'reactstrap'
import {Select, DatePicker, Tag, ConfigProvider} from 'antd'

import dayjs from 'dayjs'
import 'dayjs/locale/th'

import './index.scss'

import localeTH from 'antd/locale/th_TH'
import localeEN from 'antd/locale/en_US'

const SelectDatePickerMultiple = props => {
    const {t, i18n, picker, onSet, data} = props

    const [selectHalfYear, setSelectHalfYear] = useState(null)
    const [selectDateHalfYear, setSelectDateHalfYear] = useState(null)
    const [disableHalfYear, setDisableHalfYear] = useState(true)
    const [waringText, setWaringText] = useState(false)
    const [language, setLanguage] = useState(i18n.language)

    const [selectDate, setSelectDate] = useState(null)
    const [showDatePicker, setShowDatePicker] = useState(false)

    const [tag, setTag] = useState([])
    const [resultTimeYear, setResultTimeYear] = useState([])
    const [resultTimeMonth, setResultTimeMonth] = useState([])
    const [resultTimeQuarter, setResultTimeQuarter] = useState([])
    const [resultTimeHalfYear, setResultTimeHalfYear] = useState([])

    useEffect(() => {
        setDefaultValueTagAndData()
    }, [])

    useEffect(() => {
        if (i18n.language !== language) {
            setLanguage(i18n.language)

            if (resultTimeHalfYear?.length > 0) {
                const newTag = []
                resultTimeHalfYear.forEach(item => {
                    if (item?.halfYear === 1) {
                        newTag.push(`${t('inalyze.firstYear')}-${item.year}`)
                    }

                    if (item?.halfYear === 2) {
                        newTag.push(`${t('inalyze.secondYear')}-${item.year}`)
                    }
                })

                setTag(newTag)
            }

            if (resultTimeMonth?.length > 0) {
                const newTag = []
                resultTimeMonth?.forEach(item => {
                    if (item?.month === 1) {
                        newTag.push(`${t('common.janMonth')} ${item.year}`)
                    }

                    if (item?.month === 2) {
                        newTag.push(`${t('common.febMonth')} ${item.year}`)
                    }

                    if (item?.month === 3) {
                        newTag.push(`${t('common.marMonth')} ${item.year}`)
                    }

                    if (item?.month === 4) {
                        newTag.push(`${t('common.aprMonth')} ${item.year}`)
                    }

                    if (item?.month === 5) {
                        newTag.push(`${t('common.mayMonth')} ${item.year}`)
                    }

                    if (item?.month === 6) {
                        newTag.push(`${t('common.junMonth')} ${item.year}`)
                    }

                    if (item?.month === 7) {
                        newTag.push(`${t('common.julMonth')} ${item.year}`)
                    }

                    if (item?.month === 8) {
                        newTag.push(`${t('common.augMonth')} ${item.year}`)
                    }

                    if (item?.month === 9) {
                        newTag.push(`${t('common.sepMonth')} ${item.year}`)
                    }

                    if (item?.month === 10) {
                        newTag.push(`${t('common.octMonth')} ${item.year}`)
                    }

                    if (item?.month === 11) {
                        newTag.push(`${t('common.novMonth')} ${item.year}`)
                    }

                    if (item?.month === 12) {
                        newTag.push(`${t('common.decMonth')} ${item.year}`)
                    }
                })

                setTag(newTag)
            }
        }
    }, [i18n.language])

    useEffect(() => {
        if (data?.length === 0) {
            setTag([])
            setSelectDate(null)
            setSelectHalfYear(null)
            setSelectDateHalfYear(null)
            setResultTimeYear([])
            setResultTimeMonth([])
            setResultTimeQuarter([])
            setResultTimeHalfYear([])
        }
    }, [data])

    const disabledDateYear = current => {
        return current < dayjs('2020') || (current && current > dayjs().endOf('day'))
    }

    const handleToggleDatePicker = e => {
        setShowDatePicker(current => !current)
    }

    const handleDatePickerClose = () => {
        setShowDatePicker(false)
    }

    const formatDateToThai = date => {
        const thaiLocale = dayjs.locale('th')
        return dayjs(date).locale(thaiLocale).format('MMM YYYY')
    }

    const formatDateToEng = date => {
        const enLocale = dayjs.locale('en')
        return dayjs(date).locale(enLocale).format('MMM YYYY')
    }

    const onChangeDate = (date, dateString) => {
        const year = dayjs(date).year()
        const month = dayjs(date).month() + 1
        const quarter = `Q${Math.floor(dayjs(date).month() + 3) / 3}`

        if (picker === 'year') {
            const tagSame = tag.find(item => item === year) ? true : false

            if (!tagSame) {
                setTag(current => [...current, year])

                setResultTimeYear(current => [...current, year])

                onSet([...resultTimeYear, year])
            }
        }

        if (picker === 'quarter') {
            const quarterTag = dateString
            const quarterResult = {year, quarter}
            const tagSame = tag.find(item => item === quarterTag) ? true : false

            if (!tagSame) {
                setTag(current => [...current, quarterTag])
                setResultTimeQuarter(current => [...current, quarterResult])

                onSet([...resultTimeQuarter, quarterResult])
            }
        }

        if (picker === 'month') {
            const tagResult = language === 'th' ? formatDateToThai(date) : formatDateToEng(date)
            const tagSame = tag.find(item => item === tagResult) ? true : false
            const monthResult = {
                year,
                month,
            }

            if (!tagSame) {
                setTag(current => [...current, tagResult])
                setResultTimeMonth(current => [...current, monthResult])

                onSet([...resultTimeMonth, monthResult])
            }
        }

        if (picker === 'halfYear') {
            const halfYear = selectHalfYear?.value
            const halfYearName = selectHalfYear?.value === 1 ? t('inalyze.firstYear') : t('inalyze.secondYear')
            const halfYearTag = `${halfYearName}-${year}`
            const halfYearResult = {year, halfYear}
            const tagSame = tag.find(item => item === halfYearTag) ? true : false

            if (!tagSame) {
                setTag(current => [...current, halfYearTag])
                setResultTimeHalfYear(current => [...current, halfYearResult])

                onSet([...resultTimeHalfYear, halfYearResult])
            }
        }

        setSelectDate(null)
        setSelectHalfYear(null)
        setShowDatePicker(true)
    }

    const handleClose = (removedTag, removeResult) => {
        const newTags = tag.filter((tagItem, indexElement) => tagItem !== removedTag)

        if (picker === 'year') {
            const result = [...resultTimeYear].filter((itm, indexYear) => itm !== removedTag)

            setResultTimeYear(result)

            onSet([...result])
        }

        if (picker === 'quarter') {
            const result = [...resultTimeQuarter].filter((itm, index) => index !== removeResult)

            setResultTimeQuarter(result)
            onSet([...result])
        }

        if (picker === 'month') {
            const result = [...resultTimeMonth].filter((itm, index) => index !== removeResult)

            setResultTimeMonth(result)
            onSet([...result])
        }

        if (picker === 'halfYear') {
            const result = [...resultTimeHalfYear].filter((itm, index) => index !== removeResult)

            setResultTimeHalfYear(result)
            onSet([...result])
        }

        setTag(newTags)
    }

    const setDefaultValueTagAndData = () => {
        if (picker === 'year') {
            setTag(data)
            setResultTimeYear(data)
        }

        if (picker === 'quarter') {
            const resultTag = []
            data?.forEach(itm => {
                resultTag.push(`${itm.quarter}-${itm.year}`)
            })

            setTag(resultTag)
            setResultTimeQuarter(data)
        }

        if (picker === 'halfYear') {
            const resultTag = []
            data?.forEach(itm => {
                resultTag.push(`${itm.halfYear === 1 ? t('inalyze.firstYear') : t('inalyze.secondYear')}-${itm.year}`)
            })

            setTag(resultTag)
            setResultTimeHalfYear(data)
        }

        if (picker === 'month') {
            const resultTag = []

            data?.forEach(itm => {
                const date = itm.year + '-' + itm.month + '-' + '01'
                if (language === 'en') {
                    resultTag.push(`${formatDateToThai(date)}`)
                } else {
                    resultTag.push(`${formatDateToEng(date)}`)
                }
            })

            setTag(resultTag)
            setResultTimeMonth(data)
        }
    }

    const onChangDateHalfYear = (date, dateString) => {
        if (date) {
            setSelectDateHalfYear(date)
            setDisableHalfYear(false)
            setSelectHalfYear(null)
        } else {
            setSelectDateHalfYear(date)
            setDisableHalfYear(true)
            setSelectHalfYear(null)
        }
    }

    const onChangeHalfYear = (value, option) => {
        const year = dayjs(selectDateHalfYear).year()
        const halfYear = option?.value
        const halfYearName = option?.value === 1 ? t('inalyze.firstYear') : t('inalyze.secondYear')
        const halfYearTag = `${halfYearName}-${year}`
        const halfYearResult = {year, halfYear}
        const tagSame = tag.find(item => item === halfYearTag) ? true : false

        if (!tagSame) {
            setTag(current => [...current, halfYearTag])

            setResultTimeHalfYear(current => [...current, halfYearResult])

            onSet([...resultTimeHalfYear, halfYearResult])
        }

        setSelectHalfYear(option)
    }

    const onSelectHalfYear = (value, option) => {
        const year = dayjs(selectDateHalfYear).year()

        const halfYearName = option?.value === 1 ? t('inalyze.firstYear') : t('inalyze.secondYear')
        const halfYearTag = `${halfYearName}-${year}`

        const tagSame = tag.find(item => item === halfYearTag) ? true : false
        if (tagSame) {
            setWaringText(true)
        } else {
            setWaringText(false)
        }
    }

    return (
        <div className="container-time-group-1">
            <Row>
                <Col xl={12} lg={12} style={{padding: 0}}>
                    <Row style={{margin: 0, padding: 0, marginLeft: '40px'}}>
                        {picker === 'halfYear' && (
                            <Row>
                                <Col xl={6} lg={6}>
                                    <div className="date-select">
                                        <ConfigProvider locale={language === 'en' ? localeEN : localeTH}>
                                            <DatePicker
                                                disabled={tag?.length >= 5 ? true : false}
                                                placeholder={
                                                    picker === 'halfYear'
                                                        ? t('inalyze.pleaseSelectYear')
                                                        : t('inalyze.pleaseSelectTimeRange')
                                                }
                                                picker={picker === 'halfYear' ? 'year' : picker}
                                                className="date-picker"
                                                value={selectDateHalfYear}
                                                bordered={true}
                                                onChange={(date, dateString) => onChangDateHalfYear(date)}
                                                placement="topLeft"
                                                disabledDate={disabledDateYear}
                                            />
                                        </ConfigProvider>
                                    </div>
                                </Col>
                                <Col xl={6} lg={6}>
                                    <Select
                                        disabled={tag?.length >= 5 ? true : disableHalfYear}
                                        style={{width: '100%'}}
                                        placeholder={t('inalyze.pleaseSelectTimeRange')}
                                        value={selectHalfYear?.value || ''}
                                        // className="select-first"
                                        options={[
                                            {label: t('inalyze.firstHalfYear'), value: 1},
                                            {label: t('inalyze.secondHalfYear'), value: 2},
                                        ]}
                                        onChange={(value, option) => onChangeHalfYear(value, option)}
                                        onSelect={(value, option) => onSelectHalfYear(value, option)}
                                    />
                                    {waringText && (
                                        <p style={{color: 'red'}}>
                                            {t('inalyze.duringThisTimeTheSelectionHasBeenMade')}
                                        </p>
                                    )}
                                </Col>
                            </Row>
                        )}
                        {picker !== 'halfYear' && (
                            <Col xl={12} lg={12}>
                                <div className="date-select">
                                    <ConfigProvider locale={language === 'en' ? localeEN : localeTH}>
                                        {picker === 'quarter' ? (
                                            <DatePicker
                                                disabled={
                                                    picker === 'halfYear'
                                                        ? disableHalfYear
                                                        : tag?.length >= 5
                                                        ? true
                                                        : false
                                                }
                                                placeholder={t('inalyze.pleaseSelectTimeRange')}
                                                picker={'quarter'}
                                                className="date-picker"
                                                value={selectDate}
                                                format="[Q]Q-YYYY"
                                                onChange={(date, dateString) => onChangeDate(date, dateString)}
                                                onClick={handleToggleDatePicker}
                                                placement="topLeft"
                                                // open={showDatePicker}
                                                // onBlur={handleDatePickerClose}
                                                disabledDate={disabledDateYear}
                                            />
                                        ) : (
                                            <DatePicker
                                                disabled={
                                                    picker === 'halfYear'
                                                        ? disableHalfYear
                                                        : tag?.length >= 5
                                                        ? true
                                                        : false
                                                }
                                                placeholder={
                                                    picker === 'halfYear'
                                                        ? t('inalyze.pleaseSelectYear')
                                                        : t('inalyze.pleaseSelectTimeRange')
                                                }
                                                picker={picker === 'halfYear' ? 'year' : picker}
                                                className="date-picker"
                                                value={selectDate}
                                                bordered={true}
                                                onChange={(date, dateString) => onChangeDate(date)}
                                                onClick={handleToggleDatePicker}
                                                placement="topLeft"
                                                // open={showDatePicker}
                                                // onBlur={handleDatePickerClose}
                                                disabledDate={disabledDateYear}
                                            />
                                        )}
                                    </ConfigProvider>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Col>

                <Col xl={12} lg={12}>
                    <div
                        className="box-select"
                        style={{background: tag?.length <= 0 ? 'rgba(0, 0, 0, 0.04)' : '', marginTop: '16px'}}>
                        {tag?.map((itm, index) => (
                            <Tag
                                key={`${itm}-${index}`}
                                closable
                                onClose={e => {
                                    e.preventDefault()
                                    handleClose(itm, index)
                                }}>
                                <span className="tag-name">{itm}</span>
                            </Tag>
                        ))}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default withTranslation()(SelectDatePickerMultiple)
