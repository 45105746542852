import React, {useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {Row, Col, Card, CardBody, Table} from 'reactstrap'
import {useRecoilState, useRecoilValue} from 'recoil'
import ReactApexChart from 'react-apexcharts'

import SkeletonFlightHistoryDroneInformation from 'components/DroneiNalyze/Skeleton-DroneInformation/Usage/SkeletonFlightHistoryDroneInformation'
import {filterNavbarUsageState, loadingDroneInformationState} from 'store/atoms/drone-information'
import {getUsageFlightHistory} from 'services/skc/drone-information'

const UsageAllFlightHistory = props => {
    const {t} = props

    const [data, setData] = useState({})

    const [loadingSkeleton, setLoadingSkeleton] = useState(false)

    const filterNavbarUsage = useRecoilValue(filterNavbarUsageState)
    const [loadingDroneInformation, setLoadingDroneInformation] = useRecoilState(loadingDroneInformationState)

    useEffect(() => {
        fetchUsageFlightHistory(filterNavbarUsage)
    }, [])

    useEffect(() => {
        if (loadingDroneInformation?.flight_time_history) {
            fetchUsageFlightHistory(filterNavbarUsage)

            setLoadingDroneInformation(cur => ({...cur, flight_time_history: false}))
        }
    }, [loadingDroneInformation?.flight_time_history])

    const fetchUsageFlightHistory = async params => {
        try {
            setLoadingSkeleton(true)

            const response = await getUsageFlightHistory(params)

            if (response.status_code === 200 && response?.results) {
                setData(response?.results)
            }
        } catch (error) {
            setData({})
        } finally {
            setLoadingSkeleton(false)
        }
    }

    return (
        <React.Fragment>
            {loadingSkeleton ? (
                <SkeletonFlightHistoryDroneInformation />
            ) : (
                <Col xl="12">
                    <Card
                        style={{
                            borderRadius: 8,
                        }}>
                        <CardBody>
                            <div>
                                <div className=" me-3">
                                    <h4
                                        style={{
                                            fontSize: 20,
                                            fontWeight: 600,
                                            paddingLeft: 20,
                                            paddingTop: 10,
                                        }}>
                                        {t('droneInformation.totalFlightHistory')}
                                    </h4>
                                </div>

                                <div>
                                    <p
                                        className="mb-4 text-muted"
                                        style={{
                                            fontSize: 15,
                                            fontWeight: 400,
                                            color: '#495057',
                                            paddingLeft: 20,
                                        }}>
                                        {t('droneInformation.dronesFlightHistory')}
                                    </p>
                                </div>
                            </div>
                            <Row>
                                <Col lg="3">
                                    <div className="d-flex">
                                        <div className="me-2">
                                            <h5
                                                style={{
                                                    fontSize: 16,
                                                    fontWeight: 600,
                                                    color: '#000000',
                                                    paddingLeft: 20,
                                                    fontFamily: 'Kanit',
                                                }}>
                                                {t('droneInformation.flightTimeRanking')}
                                            </h5>
                                        </div>
                                    </div>
                                    <div
                                        className="type-drone"
                                        style={{
                                            paddingTop: 20,
                                            paddingLeft: 20,
                                        }}>
                                        <Table className="table-nowrap">
                                            <tbody>
                                                {data?.rating?.length ? (
                                                    data?.rating?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td
                                                                style={{
                                                                    fontSize: 14,
                                                                    fontWeight: 500,
                                                                    fontFamily: 'Inter',
                                                                    color: '#000',
                                                                }}>
                                                                {index + 1}.
                                                            </td>
                                                            <td
                                                                style={{
                                                                    fontSize: 14,
                                                                    fontWeight: 500,
                                                                    fontFamily: 'Inter',
                                                                    color: '#000',
                                                                }}>
                                                                {item.days}
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="ms-2 text-truncate"
                                                                    style={{
                                                                        fontSize: 14,
                                                                        fontWeight: 500,
                                                                        fontFamily: 'Inter',
                                                                        color: '#000',
                                                                    }}>
                                                                    {Intl.NumberFormat('en-US').format(
                                                                        item.amounthour.toLocaleString('en-US', {
                                                                            minimumFractionDigits: 0,
                                                                            maximumFractionDigits: 2,
                                                                        }),
                                                                    )}
                                                                </span>
                                                                <span
                                                                    className="ms-2 text-truncate"
                                                                    style={{
                                                                        fontSize: 14,
                                                                        fontWeight: 300,
                                                                        fontFamily: 'Kanit',
                                                                        color: '#495057',
                                                                    }}>
                                                                    {t('common.hourUnit')}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="ms-2 text-truncate"
                                                                    style={{
                                                                        fontSize: 14,
                                                                        fontWeight: 500,
                                                                        fontFamily: 'Inter',
                                                                        color: '#000',
                                                                    }}>
                                                                    {item.time}
                                                                </span>
                                                                <span
                                                                    className="ms-2 text-truncate"
                                                                    style={{
                                                                        fontSize: 14,
                                                                        fontWeight: 300,
                                                                        fontFamily: 'Kanit',
                                                                        color: '#495057',
                                                                    }}>
                                                                    {t('common.minuteUnit')}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 500,
                                                                fontFamily: 'Kanit',
                                                                color: 'gray',
                                                                textAlign: 'center',
                                                            }}
                                                            colSpan={4}>
                                                            {t('common.noData')}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>

                                <Col lg="9">
                                    {data?.series?.length > 0 ? (
                                        <div
                                            id="chart"
                                            style={{
                                                paddingLeft: 20,
                                                paddingRight: 30,

                                                marginTop: -40,
                                            }}>
                                            <ReactApexChart
                                                options={{
                                                    chart: {
                                                        height: 350,
                                                        type: 'heatmap',
                                                        toolbar: {
                                                            show: false,
                                                        },
                                                    },
                                                    legend: {
                                                        show: false,
                                                        position: 'top',
                                                        horizontalAlign: 'center',
                                                        fontSize: 17,
                                                        fontWeight: 500,
                                                    },
                                                    plotOptions: {
                                                        heatmap: {
                                                            shadeIntensity: 0.5,
                                                            radius: 0,

                                                            colorScale: {
                                                                ranges: data?.ranges,
                                                            },
                                                        },
                                                    },
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    yaxis: {
                                                        title: {
                                                            text: t('common.day'),

                                                            offsetX: 13,
                                                            offsetY: 0,
                                                            style: {
                                                                fontSize: '12px',
                                                            },
                                                        },
                                                    },

                                                    tooltip: {
                                                        y: {
                                                            formatter: function (val, index) {
                                                                return val.toFixed(2) + t('common.hourUnit')
                                                            },
                                                        },
                                                    },
                                                    xaxis: {
                                                        title: {
                                                            text: t('common.time'),

                                                            offsetX: 0,
                                                            offsetY: 0,
                                                            style: {
                                                                fontSize: '12px',
                                                                color: '#495057',
                                                            },
                                                        },
                                                    },
                                                }}
                                                series={data?.series}
                                                type="heatmap"
                                                height={450}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                width: '100%',
                                                height: 330,
                                                paddingLeft: 10,
                                                paddingRight: 30,
                                                marginTop: 30,
                                                textAlign: 'center',
                                                paddingTop: 100,
                                            }}>
                                            <p
                                                style={{
                                                    fontSize: 16,
                                                    fontWeight: 500,
                                                    fontFamily: 'Kanit',
                                                    color: 'gray',
                                                    textAlign: 'center',
                                                    marginTop: 50,
                                                }}>
                                                {t('common.noData')}
                                            </p>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            )}
        </React.Fragment>
    )
}

export default withTranslation()(UsageAllFlightHistory)
