const dashboard = {
    cumulativeUsers: 'Cumulative users',
    cumulativeDrones: 'Cumulative drones',
    totalAmountOfUserPlotSpaceRai: `Cumulative area of user's plots (Rai)`,

    numberOfDrones: 'Number of drones',
    numberOfUsers: 'Number of users',
    droneOverview: 'Drone Overview',
    totalNumberOfFlights: 'Total number of flights',
    totalFlightHours: 'Total flight hours',
    totalWorkAreas: 'Total work areas',
    numberOfWorkingDrones: 'Number of working drones',
    averageDroneUsageData: 'Average drone usage data',
    averageWorkingTime: 'Average working time',
    averageWorkArea: 'Average working area',
    averageAmountFlights: 'Average amount flights',
    cumulativeWorkingArea: 'Cumulative working area',
    cumulativeFlightHours: 'Cumulative flight hours',
    cumulativeAmountTaskDays: 'Cumulative amount task days',
    cumulativeLifespan: 'Cumulative lifespan',
    averageWorkingAreaPerDrone: 'Average working area per drone',
    averageFlightHoursPerDrone: 'Average flight hours per drone',
    averageWorkingDaysPerDrone: 'Average working days per drone',
    averageOperationalAgePerDrone: 'Average operational age per drone',
    days: 'days',
    droneTypes: 'Drone types',
    numberOfDronesAddedForEachType: 'Number of drones added for each type.',
    cumulativeData: 'Cumulative data',
    averagePerDrone: 'Average per drone',
    cumulativeNumberOfWorkingDrones: 'Cumulative number of working drones',
    accumulate: 'Accumulate',
    average: 'Average',
    numberOfDronesWorkingEachDay: 'Number of drones working each day.',
    dronesRegisteredInTheKiDroneSystem: 'Drones registered in the K-iAgri system',
    droneAmount: 'Drone amount',
    summaryOfTotalAreas: 'Summary of total areas',
    workingAreaFromDrones: 'Working area from drones',
    areaRai: 'Area (Rai)',
    workArea: 'Work area',
    totalArea: 'Total area',
    averageFlightDuration: 'Average flight duration',
    userOverview: 'User Overview',
    droneAmountUnit: 'Drones Amount (Unit)',
    averageFlightTimeHrUnit: 'Average flight time (Hr./Unit)',
    averageFlightTime: 'Average flight time',
    averageDroneFlightTime: 'Average drone flight time',
    flightDurationHrUnit: 'Flight duration (Hour)',
    totalFlightHistory: 'Total flight history',
    droneFlightHistory: 'Drone flight history',
    flightTimeRanking: 'Flight time ranking',
    informationAboutDronesAndUsersInEachArea: 'Information about drones and users in each area',
}

export default dashboard
