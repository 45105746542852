import React, {useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {Row} from 'reactstrap'

import iconWidget1 from '../../../assets/images/icon/widget-1.png'
import iconWidget2 from '../../../assets/images/icon/widget-2.png'
import iconWidget3 from '../../../assets/images/icon/widget-3.png'
import iconWidget4 from '../../../assets/images/icon/widget-4.png'
import iconWidget5 from '../../../assets/images/icon/widget-5.png'

import CardWidget from './component/CardWidget'
import WidgetSkeleton from '../CardSkeleton/WidgetSkeleton'

import {filterUserInalyzeState, loadingUserInalyzeState} from 'store/atoms/user-inalyze'
import {useRecoilState, useRecoilValue} from 'recoil'
import {getUserInalyzeWidget} from 'services/skc/user-inalyze'

const Widget = props => {
    const {t, i18n} = props

    const filterUserInalyze = useRecoilValue(filterUserInalyzeState)
    const [loading, setLoading] = useRecoilState(loadingUserInalyzeState)

    const [loadingSkeleton, setLoadingSkeleton] = useState(false)
    const [language, setLanguage] = useState(i18n.language)

    const [dataUser, setDataUser] = useState({})
    const [dataNewUser, setDataNewUser] = useState({})
    const [dataJobAll, setDataJobAll] = useState({})
    const [dataJobDone, setDataJobDone] = useState({})
    const [dataJobWait, setDataJobWait] = useState({})

    const [cardData, setCardData] = useState([])

    useEffect(() => {
        fetchWidget()
    }, [])

    useEffect(() => {
        if (loading?.widget) {
            fetchWidget()
            setLoading(current => ({...current, widget: false}))
        }
    }, [loading?.widget])

    useEffect(() => {
        setDataCard()
    }, [dataUser, dataNewUser, dataJobAll, dataJobDone, dataJobWait])

    useEffect(() => {
        if (i18n.language !== language) {
            setDataCard()
            setLanguage(i18n.language)
        }
    }, [i18n.language])

    const fetchWidget = async () => {
        try {
            setLoadingSkeleton(true)

            const responseUser = await getUserInalyzeWidget(filterUserInalyze, 'totalUser')

            if (responseUser?.status_code === 200 && responseUser?.results) {
                setDataUser(responseUser?.results)
            }

            const responseNewUser = await getUserInalyzeWidget(filterUserInalyze, 'newUser')

            if (responseNewUser?.status_code === 200 && responseNewUser?.results) {
                setDataNewUser(responseNewUser?.results)
            }

            const responseJobAll = await getUserInalyzeWidget(filterUserInalyze, 'totalJobAll')

            if (responseJobAll?.status_code === 200 && responseJobAll?.results) {
                setDataJobAll(responseJobAll?.results)
            }

            const responseJobDone = await getUserInalyzeWidget(filterUserInalyze, 'totalJobDone')

            if (responseJobDone?.status_code === 200 && responseJobDone?.results) {
                setDataJobDone(responseJobDone?.results)
            }

            const responseJobWait = await getUserInalyzeWidget(filterUserInalyze, 'totalJobWait')

            if (responseJobWait?.status_code === 200 && responseJobWait?.results) {
                setDataJobWait(responseJobWait?.results)
            }
        } catch (error) {
            setDataUser({})
            setDataNewUser({})
            setDataJobAll({})
            setDataJobDone({})
            setDataJobWait({})
        } finally {
            setLoadingSkeleton(false)
        }
    }

    const setDataCard = () => {
        let desc = ''

        if (filterUserInalyze.type === 'year') {
            desc = t('common.jobYearUnit')
        }

        if (filterUserInalyze.type === 'month') {
            desc = t('common.jobMonthUnit')
        }

        if (filterUserInalyze.type === 'week') {
            desc = t('common.jobWeekUnit')
        }

        let data = [
            {
                icon: iconWidget1,
                title: t('userInalyze.totalUsers'),
                value: dataUser?.total?.toLocaleString(),
                badgeValue: dataUser?.percentage,
                color:
                    parseFloat(dataUser?.percentage) > 0
                        ? 'success'
                        : parseFloat(dataUser?.percentage) < 0
                        ? 'danger'
                        : 'warning',
                mark: parseFloat(dataUser?.percentage) > 0 ? '+' : parseFloat(dataUser?.percentage) < 0 ? '' : '',
                desc: desc,
                unit: t('userInalyze.personUnit'),
            },
            {
                icon: iconWidget2,
                title: t('userInalyze.newUsers'),
                value: dataNewUser?.total?.toLocaleString(),
                badgeValue: dataNewUser?.percentage,
                color:
                    parseFloat(dataNewUser?.percentage) > 0
                        ? 'success'
                        : parseFloat(dataNewUser?.percentage) < 0
                        ? 'danger'
                        : 'warning',
                mark: parseFloat(dataNewUser?.percentage) > 0 ? '+' : parseFloat(dataNewUser?.percentage) < 0 ? '' : '',
                desc: desc,
                unit: t('userInalyze.personUnit'),
            },
            {
                icon: iconWidget3,
                title: t('userInalyze.totalJobs'),
                value: dataJobAll?.total?.toLocaleString(),
                badgeValue: dataJobAll?.percentage,
                color:
                    parseFloat(dataJobAll?.percentage) > 0
                        ? 'success'
                        : parseFloat(dataJobAll?.percentage) < 0
                        ? 'danger'
                        : 'warning',
                mark: parseFloat(dataJobAll?.percentage) > 0 ? '+' : parseFloat(dataJobAll?.percentage) < 0 ? '' : '',
                desc: desc,
                avg: dataJobAll?.average?.toLocaleString(),
                unit_avg: desc,
                unit: t('userInalyze.jobUnit'),
            },
            {
                icon: iconWidget4,
                title: t('userInalyze.completedTasks'),
                value: dataJobDone?.total?.toLocaleString(),
                badgeValue: dataJobDone?.percentage,
                color:
                    parseFloat(dataJobDone?.percentage) > 0
                        ? 'success'
                        : parseFloat(dataJobDone?.percentage) < 0
                        ? 'danger'
                        : 'warning',
                mark: parseFloat(dataJobDone?.percentage) > 0 ? '+' : parseFloat(dataJobDone?.percentage) < 0 ? '' : '',
                desc: '235' + '' + desc,
                unit: t('userInalyze.jobUnit'),
            },
            {
                icon: iconWidget5,
                title: t('userInalyze.ongoingTasks'),
                value: dataJobWait?.total?.toLocaleString(),
                badgeValue: dataJobWait?.percentage,
                color:
                    parseFloat(dataJobWait?.percentage) > 0
                        ? 'success'
                        : parseFloat(dataJobWait?.percentage) < 0
                        ? 'danger'
                        : 'warning',
                mark: parseFloat(dataJobWait?.percentage) > 0 ? '+' : parseFloat(dataJobWait?.percentage) < 0 ? '' : '',
                desc: desc,
                unit: t('userInalyze.jobUnit'),
            },
        ]

        setCardData(data)
    }

    return (
        <Row>
            {loadingSkeleton ? (
                <WidgetSkeleton amount={5} />
            ) : (
                <>
                    {cardData?.length > 0 &&
                        cardData?.map((report, key) => (
                            <CardWidget
                                key={key}
                                img={report.icon}
                                title={report.title}
                                value={report.value}
                                badgeValue={report.badgeValue}
                                color={report.color}
                                unit={report.unit}
                                avg={report.avg}
                                unit_avg={report.unit_avg}
                            />
                        ))}
                </>
            )}
        </Row>
    )
}

export default withTranslation()(Widget)
