import React, {Component} from 'react'
import {Row, Col} from 'reactstrap'
import iconModeFlightHistoryPlay from '../../../../../assets/images/icon/icon-mode-flight-history-play.png'
import iconDroneFast from '../../../../../assets/images/icon/icon-drone-fast.png'
import iconDroneHeight from '../../../../../assets/images/icon/icon-drone-height.png'
import iconArrowExpand from '../../../../../assets/images/icon/icon-arrow-expand.png'
import iconDroneOutletSize from '../../../../../assets/images/icon/icon-drone-outlet-size.png'
import iconBattery from '../../../../../assets/images/icon/icon-battery.png'
import './index.scss'
import {withTranslation} from 'react-i18next'

class DetailDroneFlightHistoryPlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            waypoint: {},
            rowWidth: 0,
        }
    }

    async componentDidMount() {
        if (this.props.ways) {
            let max = await Math.max(...this.props.ways.map(o => o.rowWidth))
            // console.log(max)
            this.setState({
                rowWidth: max,
            })
        }
    }

    // async componentDidUpdate()  {

    // }

    calculateDegree = (x1, y1, x2, y2) => {
        const dy = y2 - y1
        const dx = x2 - x1
        let angle = 0

        if (dy > dx) {
            angle = (Math.atan2(dy, dx) * 180) / Math.PI
        } else {
            angle = (Math.atan2(dx, dy) * 180) / Math.PI
        }

        if (angle < 0) {
            angle += 360
        }
        return angle
    }

    render() {
        const state = this.state
        const {t} = this.props

        return (
            <>
                <Row className="row-detail-drone-flight-history-play">
                    <Col xl={2} lg={2}>
                        <div className="icon-mode">
                            <img src={iconModeFlightHistoryPlay} />
                            <span>Mode</span>
                        </div>
                        <div className="mode-value">
                            <span>
                                {this.props.waypoint?.mode == 0
                                    ? 'None'
                                    : this.props.waypoint?.mode == 1
                                    ? 'Manual'
                                    : this.props.waypoint?.mode == 2
                                    ? 'AB'
                                    : this.props.waypoint?.mode == 3
                                    ? 'M+'
                                    : this.props.waypoint?.mode == 4
                                    ? 'Auto'
                                    : t('common.notSpecified')}
                            </span>
                        </div>
                    </Col>
                    <Col xl={10} lg={10}>
                        <Row className="row-sub-detail">
                            <Col xl={2} lg={2} className="col-sub-detail">
                                <Row className="box-sub-detail">
                                    <Col xl={8} lg={8} className="box-title">
                                        <p className="title">{t('droneInformation.flightSpeed')}</p>
                                        <p className="title-value">
                                            {this.props.waypoint?.velocity?.max != null
                                                ? parseFloat(this.props.waypoint?.velocity?.max).toFixed(2)
                                                : '0'}{' '}
                                            m/s{' '}
                                        </p>
                                    </Col>
                                    <Col xl={4} lg={4} className="box-icon">
                                        <img className="icon-drone-fast" src={iconDroneFast} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={2} lg={2} className="col-sub-detail">
                                <Row className="box-sub-detail">
                                    <Col xl={8} lg={8} className="box-title">
                                        <p className="title">{t('droneInformation.height')}</p>
                                        <p className="title-value">
                                            {this.props.waypoint?.height != null
                                                ? parseFloat(this.props.waypoint?.height).toFixed(2)
                                                : '0'}{' '}
                                            m
                                        </p>
                                    </Col>
                                    <Col xl={4} lg={4} className="box-icon">
                                        <img className="icon-drone-height" src={iconDroneHeight} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={2} lg={2} className="col-sub-detail">
                                <Row className="box-sub-detail">
                                    <Col xl={8} lg={8} className="box-title">
                                        <p className="title">{t('droneInformation.routeDistance')}</p>
                                        <p className="title-value">
                                            {this.state.rowWidth != null
                                                ? parseFloat(this.state.rowWidth).toFixed(2)
                                                : '0'}{' '}
                                            m
                                        </p>
                                    </Col>
                                    <Col xl={4} lg={4} className="box-icon">
                                        <img className="icon-drone-expand" src={iconArrowExpand} />
                                    </Col>
                                </Row>
                            </Col>
                            {this.props.waypoint?.isSpreading ? (
                                <Col xl={2} lg={2} className="col-sub-detail">
                                    <Row className="box-sub-detail">
                                        <Col xl={8} lg={8} className="box-title">
                                            <p className="title">{t('droneInformation.rotationalSpeed')}</p>
                                            <p className="title-value">
                                                {this.props.waypoint?.spreading?.spinnerDiskRPM != null
                                                    ? parseFloat(
                                                          this.props.waypoint?.spreading?.spinnerDiskRPM,
                                                      ).toFixed(0)
                                                    : 0}{' '}
                                                RPM
                                            </p>
                                        </Col>
                                        <Col xl={4} lg={4} className="box-icon">
                                            <img className="icon-drone-fast" src={iconDroneFast} />
                                        </Col>
                                    </Row>
                                </Col>
                            ) : this.props.waypoint?.isSpraying ? (
                                <Col xl={2} lg={2} className="col-sub-detail">
                                    <Row className="box-sub-detail">
                                        <Col xl={8} lg={8} className="box-title">
                                            <p className="title">{t('droneInformation.flowRate')}</p>
                                            <p className="title-value">
                                                {this.props.waypoint?.spraying?.flowRate != null
                                                    ? parseFloat(
                                                          this.props.waypoint?.spraying?.flowRate / 1000,
                                                      ).toFixed(2)
                                                    : 0}{' '}
                                                L/min
                                            </p>
                                        </Col>
                                        <Col xl={4} lg={4} className="box-icon">
                                            <img className="icon-drone-fast" src={iconDroneFast} />
                                        </Col>
                                    </Row>
                                </Col>
                            ) : (
                                <></>
                            )}
                            {this.props.waypoint?.isSpreading ? (
                                <Col xl={2} lg={2} className="col-sub-detail">
                                    <Row className="box-sub-detail">
                                        <Col xl={8} lg={8} className="box-title">
                                            <p className="title">{t('droneInformation.nozzleSize')}</p>
                                            <p className="title-value">
                                                {this.props.waypoint?.spreading?.hopperOutletSize != null
                                                    ? parseFloat(
                                                          this.props.waypoint?.spreading?.hopperOutletSize,
                                                      ).toFixed(2)
                                                    : 0}
                                                %
                                            </p>
                                        </Col>
                                        <Col xl={4} lg={4} className="box-icon">
                                            <img className="icon-drone-outlet-size" src={iconDroneOutletSize} />
                                        </Col>
                                    </Row>
                                </Col>
                            ) : this.props.waypoint?.isSpraying ? (
                                <Col xl={2} lg={2} className="col-sub-detail">
                                    <Row className="box-sub-detail">
                                        <Col xl={8} lg={8} className="box-title">
                                            <p className="title">{t('droneInformation.substanceUsed')}</p>
                                            <p className="title-value">
                                                {this.props.waypoint?.isSpraying &&
                                                this.props.waypoint?.workInfo?.sprayed
                                                    ? parseFloat(
                                                          this.props.waypoint?.workInfo?.sprayed[0] > 0
                                                              ? this.props.waypoint?.workInfo?.sprayed[0] / 1000
                                                              : 0,
                                                      ).toFixed(2)
                                                    : this.props.waypoint?.isSpreading &&
                                                      this.props.waypoint?.workInfo?.sowed
                                                    ? parseFloat(
                                                          this.props.waypoint?.workInfo?.sowed[0] > 0
                                                              ? this.props.waypoint?.workInfo?.sowed[0] / 1000
                                                              : 0,
                                                      ).toFixed(2)
                                                    : ''}
                                                {/* {this.props.waypoint?.spraying?.remainingPesticide != null ? parseFloat(this.props.waypoint?.spraying?.remainingPesticide / 1000).toFixed(2) : 0} L */}
                                            </p>
                                        </Col>
                                        <Col xl={4} lg={4} className="box-icon">
                                            <img className="icon-drone-outlet-size" src={iconDroneOutletSize} />
                                        </Col>
                                    </Row>
                                </Col>
                            ) : (
                                <></>
                            )}
                            <Col xl={2} lg={2} className="col-sub-detail">
                                <Row className="box-sub-detail">
                                    <Col xl={8} lg={8} className="box-title">
                                        <p className="title">{t('droneInformation.battery')}</p>
                                        <p className="title-value">{this.props.waypoint?.battery?.remaining ?? '-'}%</p>
                                    </Col>
                                    <Col xl={4} lg={4} className="box-icon">
                                        <img className="icon-battery" src={iconBattery} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}

export default withTranslation()(DetailDroneFlightHistoryPlay)
