import {atom} from 'recoil'
import dayjs from 'dayjs'

export const filterNavbarUsageState = atom({
    key: 'filterNavbarUsage',
    default: {
        type: 'year',
        month: '',
        week: '',
        year: dayjs().year(),
        start_date: dayjs().startOf('year').format('YYYY-MM-DD'),
        end_date: dayjs().endOf('year').format('YYYY-MM-DD'),
        date: dayjs(),
        id: '',
    },
})

export const loadingDroneInformationState = atom({
    key: 'loadingDroneInformation',
    default: {
        detail: false,
        fly_duration: false,
        working_area: false,
        speed: false,
        height: false,
        flight_time_history: false,
        flight_lists_history: false,
    },
})
