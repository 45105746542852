import axios from 'axios'
import accessToken from './jwt-token-access/accessToken'
import i18n from '../i18n'

//pass new generated access token here
//  const token = accessToken;
// const token =
//   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzeXNtX2lkIjoiZGQwNTdhNzgtZTQ3Yy00YTUxLTlmOTUtNTcyMDE5MDJjMzAyIiwidXNlcl9uYW1lIjoic3VwZXJhZG1pbiIsImVfbWFpbCI6InN1cGVyYWRtaW5AZ21haWwuY29tIiwib3Blbl9pZCI6bnVsbCwicmVmcmVzaF90b2tlbl9kamkiOm51bGwsImlzTG9naW5ESkkiOmZhbHNlLCJpYXQiOjE2ODE5NjU2ODAsImV4cCI6MTY4OTc0MTY4MH0.wlJnXvZk6EpTuB6zwpfsIH4L8tAphC2sN_Y_GQFcZ58";
// console.log("token", token);
// apply base url for axios
const API_URL = 'https://uat-api-portal.k-iagri.com'
// const API_URL = 'https://k-idrone.gramick.dev/gmservice'
// const API_URL = 'http://localhost:4000'

//all-data
// const API_URL = "https://k-idrone.com/gmservice";

const axiosApi = axios.create({
    baseURL: API_URL,
})

// // axiosApi.defaults.headers.common["Authorization"] = accessToken;
// const authToken = localStorage.getItem("authUser")
//   ? "Bearer " + JSON.parse(localStorage.getItem("authUser")).results?.access_token
//   : token;
axiosApi.defaults.headers.common['Authorization'] = accessToken

// console.log('i18n', i18n.language)

axiosApi.interceptors.response.use(
    response => response,
    error => Promise.reject(error),
)

export async function get(url, config) {
    if (!config?.notLang) {
        return await axiosApi
            .get(`${url}${i18n.language && `?lang=${i18n.language}`}`, {...config})
            .then(response => response.data)
    } else {
        return await axiosApi.get(`${url}`, {...config}).then(response => response.data)
    }
}

export async function getNoLang(url, config) {
    return await axiosApi.get(`${url}`, {...config}).then(response => response.data)
}

export async function getWithQuery(url, config) {
    return await axiosApi
        .get(`${url}${i18n.language && `&lang=${i18n.language}`}`, {...config})
        .then(response => response.data)
}

export async function post(url, data, config) {
    return await axiosApi
        .post(`${url}${i18n.language && `?lang=${i18n.language}`}`, {...data}, {...config})
        .then(response => response.data)
}

export async function postWithQuery(url, data, config) {
    return await axiosApi
        .post(`${url}${i18n.language && `&lang=${i18n.language}`}`, {...data}, {...config})
        .then(response => response.data)
}
export async function postNoLang(url, data, config) {
    return await axiosApi.post(`${url}`, {...data}, {...config}).then(response => response.data)
}

export async function put(url, data, config = {}) {
    return await axiosApi.put(`${url}&lang=${i18n.language}`, {...data}, {...config}).then(response => response.data)
}

export async function del(url, config = {}) {
    return await axiosApi.delete(`${url}&lang=${i18n.language}`, {...config}).then(response => response.data)
}
