import React from 'react'
import './flight-history-detail-skeleton.scss'
import {Row, Col, Card, CardTitle, CardBody} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const TableDetailSkeleton = () => {
    return (
        <Card className="card-detail-skeleton">
            <CardTitle>
                <Row className="row">
                    <Col xl={9} lg={9}>
                        <Skeleton height={33} width={100} />
                    </Col>
                    <Col xl={3} lg={3} className="col-btn-play-drone">
                        <Skeleton height={48} width={143} style={{borderRadius: 8}} />
                    </Col>
                </Row>
            </CardTitle>
            <CardBody>
                <Row className={'row-table  row-table-color'}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <Skeleton height={21} width={60} />
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <Skeleton height={21} width={65} />
                    </Col>
                </Row>
                <Row className={'row-table'}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <Skeleton height={21} width={150} />
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <Skeleton height={21} width={50} />
                    </Col>
                </Row>
                <Row className={'row-table row-table-color'}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <Skeleton height={21} width={60} />
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <Skeleton height={21} width={40} />
                    </Col>
                </Row>
                <Row className={'row-table '}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <Skeleton height={21} width={65} />
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <Skeleton height={21} width={40} />
                    </Col>
                </Row>
                <Row className={'row-table row-table-color '}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <Skeleton height={21} width={68} />
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <Skeleton height={21} width={40} />
                    </Col>
                </Row>
                <Row className={'row-table  '}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <Skeleton height={21} width={68} />
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <Skeleton height={21} width={40} />
                    </Col>
                </Row>
                <Row className={'row-table row-table-color '}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <Skeleton height={21} width={80} />
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <Skeleton height={21} width={40} />
                    </Col>
                </Row>
                <Row className={'row-table  '}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <Skeleton height={21} width={90} />
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <Skeleton height={21} width={40} />
                    </Col>
                </Row>
                <Row className={'row-table  row-table-color  '}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <Skeleton height={21} width={90} />
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <Skeleton height={21} width={40} />
                    </Col>
                </Row>
                <Row className={'row-table   '}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <Skeleton height={21} width={100} />
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <Skeleton height={21} width={40} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default TableDetailSkeleton
