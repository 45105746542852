export const buttonFilterTimeRange = [
    {title_th: 'สัปดาห์', title_en: 'Week', value: 'week'},
    {title_th: 'เดือน', title_en: 'Month', value: 'month'},
    {title_th: 'ปี', title_en: 'Year', value: 'year'},
    {title_th: 'ไตรมาส', title_en: 'Quarter', value: 'quarter'},
    {title_th: 'ครึ่งปี', title_en: 'Half year', value: 'halfYear'},
    {title_th: 'กำหนดเอง', title_en: 'Customized', value: 'period'},
]

export const optionsCountry = [
    {
        label_th: 'ทั้งหมด',
        label_en: 'All',
        value: 'all',
    },
    {
        label_th: 'ไทย',
        label_en: 'Thailand',
        value: 'th',
    },
    {
        label_th: 'ลาว',
        label_en: 'Laos',
        value: 'la',
    },
    {
        label_th: 'กัมพูชา',
        label_en: 'Cambodia',
        value: 'kh',
    },
]

export const lookupDroneTypeTotal = [
    {
        value: 'drone',
        label_th: 'จำนวนโดรนที่ทำงานสะสม',
        label_en: 'Cumulative number of working drones',
    },
    {
        value: 'working_area',
        label_th: 'พื้นที่การทำงานสะสม',
        label_en: 'Cumulative working area',
    },
    {
        value: 'fly_hour',
        label_th: 'ชั่วโมงบินสะสม',
        label_en: 'Cumulative flight hours',
    },
    {
        value: 'amount_working',
        label_th: 'จำนวนวันทำงานสะสม',
        label_en: 'Cumulative amount task days',
    },
    {
        value: 'life_time',
        label_th: 'อายุการใช้งานสะสม',
        label_en: 'Cumulative lifespan',
    },
]

export const lookupDroneTypeAverage = [
    {
        value: 'working_area',
        label_th: 'พื้นที่การทำงานเฉลี่ยต่อลำ',
        label_en: 'Average working area per drone',
    },
    {
        value: 'fly_hour',
        label_th: 'ชั่วโมงบินเฉลี่ยต่อลำ',
        label_en: 'Average flight hours per drone',
    },

    {
        value: 'amount_working',
        label_th: 'จำนวนวันทำงานเฉลี่ยต่อลำ',
        label_en: 'Average working days per drone',
    },
    {
        value: 'life_time',
        label_th: 'อายุการใช้งานเฉลี่ยต่อลำ',
        label_en: 'Average operational age per drone',
    },
]

export const tabDataInalyzeMap = [
    {
        title_en: 'Number of drones',
        title_th: 'จำนวนโดรน',
        value: 'drone',
    },
    {title_en: 'Flight hours', title_th: 'ชั่วโมงการทำงาน', value: 'fly_hour'},
    {title_en: 'Working days', title_th: 'จำนวนวันทำงาน', value: 'amount_working'},
    {title_en: 'Working area', title_th: 'พื้นที่ทำงาน', value: 'working_area'},
    {title_en: 'Average spraying area', title_th: 'พื้นที่ฉีดพ่นเฉลี่ย', value: 'spraying'},
]

export const compareButton = [
    {title_en: 'Compare by area', title_th: 'เปรียบเทียบตามพื้นที่', value: 'area'},
    {title_en: 'Compare by time', title_th: 'เปรียบเทียบตามช่วงเวลา', value: 'time'},
]

export const formAreaType = [
    {name_th: 'ประเทศ', name_en: 'Country', value: 'country'},
    {name_th: 'ภูมิภาค', name_en: 'Region', value: 'region'},
    {name_th: 'จังหวัด', name_en: 'Province', value: 'province'},
]

export const lookupFormAreaSelectTime = [
    {label_en: 'Year', label_th: 'ปี', value: 'year'},
    {label_en: 'Quarter', label_th: 'ไตรมาส', value: 'quarter'},
    {label_en: 'Half year', label_th: 'ครึ่งปี', value: 'halfYear'},
    {label_en: 'Month', label_th: 'เดือน', value: 'month'},
]

export const optCompareDataTime = [
    {
        label_th: 'ระยะเวลาการบิน',
        label_en: 'Flight Duration',
        value: 'flyTime',
    },
    {
        label_th: 'จำนวนวันทำงาน',
        label_en: 'Number of working days',
        value: 'amountWorking',
    },
    {
        label_th: 'พื้นที่การทำงาน',
        label_en: 'Working Areas',

        value: 'workingArea',
    },
    {
        label_th: 'จำนวนเที่ยวบิน',
        label_en: 'Number of Flights',

        value: 'flyAmount',
    },
    {
        label_th: 'ปริมาณการฉีดพ่น',
        label_en: 'Spraying Quantity',
        value: 'spraying',
    },
    {
        label_th: 'ความเร็ว',
        label_en: 'Speed',
        value: 'velocity',
    },
    {
        label_th: 'ความสูง',
        label_en: 'Height',
        value: 'height',
    },
]

export const optCompareTimeType = [
    {label_th: 'ปี', label_en: 'Year', value: 'year', disabled: false},
    {label_th: 'ไตรมาส', label_en: 'Quarter', value: 'quarter', disabled: false},
    {label_th: 'ครึ่งปี', label_en: 'Half year', value: 'halfYear', disabled: false},
    {label_th: 'เดือน', label_en: 'Month', value: 'month', disabled: false},
]

export const droneInformationWorkType = [
    {label_th: 'การหว่าน', label_en: 'Spreading', value: 'spreading'},
    {label_th: 'การฉีดพ่น', label_en: 'Spraying', value: 'spraying'},
]

export const overAllUsageTab = [
    {title: 'Overall', value: 'overall'},
    {title: 'Usage', value: 'usage'},
]

export const buttonSortTable = [
    {title: 'Unit', value: 'UNIT'},
    {title: 'MTD', value: 'MTD'},
    {title: 'YTD', value: 'YTD'},
]

export const selectOptionDataType = [
    {
        label_th: 'รุ่นโดรน',
        label_en: 'Drone Model',
        value: 'drone',
    },
    {
        label_th: 'ประเทศ',
        label_en: 'Country',
        value: 'country',
    },
    {
        label_th: 'ภูมิภาค',
        label_en: 'Region',
        value: 'region',
    },
    {
        label_th: 'จังหวัด',
        label_en: 'Province',
        value: 'province',
    },
]

export const selectOptionCustomerSegmentation = [
    {
        value: 'working_area',
        label_th: 'พื้นที่การทำงาน (ไร่)',
        label_en: 'Working Area (Rai)',
        disabled: false,
    },

    {
        value: 'fly_amount',
        label_th: 'จำนวนเที่ยวบิน (เที่ยว)',
        label_en: 'Number of Flights (Times)',
        disabled: false,
    },
    {
        value: 'spraying_trip',
        label_th: 'จำนวนการฉีดพ่น (เที่ยว)',
        label_en: 'Number of Spraying (Times)',
        disabled: false,
    },
    {
        value: 'spreading_trip',
        label_th: 'จำนวนการหว่าน (เที่ยว)',
        label_en: 'Number of Spreading (Times)',
        disabled: false,
    },
    {
        value: 'spreading_area',
        label_th: 'อัตราการหว่าน (กก./ไร่)',
        label_en: 'Spreading Rate (Kg./Rai)',
        disabled: false,
    },
    {
        value: 'spraying_area',
        label_th: 'อัตราการฉีดพ่น (ล./ไร่)',
        label_en: 'Spraying Rate (L.Rai)',
        disabled: false,
    },
]
