import React, {useState, useEffect} from 'react'
import {useRecoilState, useRecoilValue} from 'recoil'
import {withTranslation} from 'react-i18next'
import {Row, Col, Card, CardBody, CardTitle} from 'reactstrap'
import {ComposedChart, YAxis, XAxis, CartesianGrid, Tooltip, Bar, Line, ResponsiveContainer, Text, Cell} from 'recharts'

import UsedSkeleton from '../../CardSkeleton/UsedSkeleton'

import {filterUserInformationState, loadingUserInformationState} from 'store/atoms/user-information'
import {getUserHardwareID} from 'services/skc/user-information'
import {getUserInformationSpeedHeightDrone} from 'services/skc/new-kafka'

const HeightUserInfo = props => {
    const {t} = props

    const [activeIndex, setActiveIndex] = useState(-1)
    const [data, setData] = useState({})
    const [loadingSkeleton, setLoadingSkeleton] = useState(false)
    const [hardwareUserID, setHardwareUserID] = useState([])

    const filterUserInformation = useRecoilValue(filterUserInformationState)
    const [loading, setLoading] = useRecoilState(loadingUserInformationState)

    useEffect(() => {
        fetchUserHardwareID(filterUserInformation.id)
    }, [])

    useEffect(() => {
        if (hardwareUserID) {
            fetchHeightUserInfo({...filterUserInformation, drones: hardwareUserID, data_type: 'height'})
        }
    }, [hardwareUserID])

    useEffect(() => {
        if (loading.drone_height) {
            fetchHeightUserInfo({...filterUserInformation, drones: hardwareUserID, data_type: 'height'})

            setLoading(cur => ({...cur, drone_height: false}))
        }
    }, [loading.drone_height])

    const fetchUserHardwareID = async params => {
        try {
            const response = await getUserHardwareID(params)

            if (response?.status_code === 200 && response?.results?.data?.length > 0) {
                setHardwareUserID(response?.results?.data)
            } else {
                setHardwareUserID([])
            }
        } catch (error) {
            setHardwareUserID([])
        } finally {
        }
    }

    const fetchHeightUserInfo = async params => {
        try {
            setLoadingSkeleton(true)

            const response = await getUserInformationSpeedHeightDrone(params)

            if (response?.result) {
                setData(response?.result?.data)
            }
        } catch (error) {
            setData({})
        } finally {
            setLoadingSkeleton(false)
        }
    }

    const handleClick = index => {
        setActiveIndex(index)
    }

    const handleOut = () => {
        setActiveIndex(-1)
    }

    const CustomTooltip = ({active, payload, label}) => {
        const tooltip = {
            backgroundColor: '#000',
            opacity: '0.8',
            borderRadius: '4px',
            paddingTop: '8px',
        }

        let nf = new Intl.NumberFormat()
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip " style={tooltip}>
                    <p
                        style={{
                            textAlign: 'left',
                            color: '#fff',
                            fontSize: 13,
                            fontWeight: 400,
                            paddingLeft: 20,
                            fontFamily: 'Inter',
                        }}>
                        {label} m/s
                    </p>
                    <div
                        style={{
                            marginTop: -10,
                            marginBottom: 8,
                            borderTopWidth: '1px',
                            borderTopColor: '#404040',
                            borderTopStyle: 'solid',
                        }}
                    />
                    <div className="d-flex align-items-start " style={{paddingLeft: 10, paddingRight: 10}}>
                        <div className="me-2">
                            <i className="mdi mdi-circle" style={{color: '#71E5BB'}} />
                        </div>
                        <div className="flex-grow-1">
                            <div className="text-muted">
                                <p
                                    className="mb-0"
                                    style={{
                                        color: '#fff',
                                        fontSize: 12,
                                        fontWeight: 500,
                                    }}>
                                    {t('userInformation.numberOfFlights')}
                                </p>
                            </div>
                        </div>

                        <div className="ml-2">
                            <p
                                className=""
                                style={{
                                    color: '#fff',
                                    marginLeft: 40,
                                    fontSize: 13,
                                    fontWeight: 600,
                                    fontFamily: 'Inter',
                                }}>
                                {Number.isInteger(payload[0].value) == true
                                    ? nf.format(payload[0].value) + '.00'
                                    : nf.format(parseFloat(payload[0].value).toFixed(2))}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex align-items-start " style={{paddingLeft: 10, paddingRight: 10}}>
                        <div className="me-2">
                            <div style={{background: '#F46A6A', marginTop: '8px', width: '8px', height: '3px'}} />
                        </div>
                        <div className="flex-grow-1">
                            <div className="text-muted">
                                <p
                                    className="mb-0"
                                    style={{
                                        color: '#fff',
                                        fontSize: 12,
                                        fontWeight: 500,
                                    }}>
                                    {t('userInformation.average')}
                                </p>
                            </div>
                        </div>

                        <div className="ml-2">
                            <p
                                className=""
                                style={{
                                    color: '#fff',
                                    marginLeft: 40,
                                    fontSize: 13,
                                    fontWeight: 600,
                                    fontFamily: 'Inter',
                                }}>
                                {Number.isInteger(payload[0].payload.line_average) == true
                                    ? nf.format(payload[0].payload.line_average) + '.00'
                                    : nf.format(parseFloat(payload[0].payload.line_average).toFixed(2))}
                            </p>
                        </div>
                    </div>
                </div>
            )
        }

        return null
    }

    const heightUserInfo = {}
    return (
        <>
            {loadingSkeleton ? (
                <UsedSkeleton />
            ) : (
                <Card className="card-height-drone-used">
                    <CardTitle className="card-title">
                        <p className="section"> {t('userInformation.heightUsed')}</p>

                        <div className="container-detail">
                            {data?.total?.map((item, idx) => (
                                <div className="box-detail-data" key={idx}>
                                    <p className="title">
                                        {idx === 0
                                            ? t('userInformation.average')
                                            : idx === 1
                                            ? t('userInformation.median')
                                            : t('userInformation.mode')}
                                    </p>
                                    <div className="box-value">
                                        <label className="value"> {item.value || '-'}</label>
                                        <label className="unit"> {item.unit || '-'}</label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </CardTitle>

                    <CardBody className="result-data">
                        <Row
                            style={{
                                margin: 0,
                                padding: 0,
                                width: '100%',
                                height: 360,
                                paddingLeft: 10,
                                paddingRight: 15,
                            }}>
                            <Col xl={12}>
                                {data?.graph?.length > 0 ? (
                                    <ResponsiveContainer width="100%" height="92%">
                                        <ComposedChart
                                            layout="horizontal"
                                            data={data?.graph}
                                            margin={{
                                                top: 10,
                                                right: 20,
                                                bottom: 20,
                                                left: 20,
                                            }}>
                                            <CartesianGrid stroke="#E0E0E0" vertical={false} />
                                            <XAxis
                                                dataKey="x"
                                                scale="point"
                                                padding={{left: 10, right: 10}}
                                                domain={[0, 'dataMax']}
                                                label={
                                                    <Text
                                                        x={0}
                                                        y={0}
                                                        dx={300}
                                                        dy={325}
                                                        offset={0}
                                                        angle={0}
                                                        fill={'#495057'}
                                                        fontSize={'12'}
                                                        style={{
                                                            color: '#495057',
                                                            textAlign: 'center',
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            fontFamily: 'Kanit',
                                                        }}>
                                                        {t('userInformation.heightMUnit')}
                                                    </Text>
                                                }
                                            />
                                            <YAxis
                                                stroke="#373D3F"
                                                tickLine={false}
                                                domain={[0, 'dataMax']}
                                                label={
                                                    <Text
                                                        x={-20}
                                                        y={50}
                                                        dx={50}
                                                        dy={150}
                                                        offset={0}
                                                        angle={-90}
                                                        fill={'#495057'}
                                                        fontSize={'12'}
                                                        style={{
                                                            textAlign: 'center',
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            fontFamily: 'Kanit',
                                                        }}>
                                                        {t('userInformation.numberOfFlights')}
                                                    </Text>
                                                }
                                            />
                                            <Tooltip
                                                content={<CustomTooltip />}
                                                cursor={{stroke: '#E0E0E0', strokeWidth: 22}}
                                            />

                                            <Bar
                                                dataKey="bar"
                                                onMouseEnter={handleClick}
                                                onMouseOut={handleOut}
                                                onClick={handleClick}
                                                barSize={15}>
                                                {data?.graph?.map((entry, index) => (
                                                    <Cell
                                                        cursor="pointer"
                                                        fill={index === activeIndex ? '#25B17E' : '#71E6BB'}
                                                        key={`cell-${index}`}
                                                    />
                                                ))}
                                            </Bar>
                                            <Line
                                                connectNulls={true}
                                                type="monotone"
                                                dataKey="line"
                                                stroke="#1FBA82"
                                                strokeWidth={2.5}
                                                dot={false}
                                                tooltipType="none"
                                            />
                                            <Line
                                                connectNulls={true}
                                                type="monotone"
                                                dataKey="line_average"
                                                stroke="#F46A6A"
                                                strokeWidth={2}
                                                dot={false}></Line>
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                ) : (
                                    <p
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            fontFamily: 'Kanit',
                                            color: 'gray',
                                            textAlign: 'center',
                                            marginTop: 80,
                                        }}>
                                        {t('common.noData')}
                                    </p>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )}
        </>
    )
}

export default withTranslation()(HeightUserInfo)
