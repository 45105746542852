import {del, get, getWithQuery, post, put, upload} from '../../helpers/api_helper'
import * as url from '../../constants/url_helper/dashboard'
import {config} from 'process'

const getdashboardheader = ({type, month, week, year, country, regionTH, dronemodel, start_date, end_date}) =>
    get(
        `${url.GET_DASHBOARD_HEADER}?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}&start_date=${start_date}&end_date=${end_date}`,
    )
const getdashboarddrone = ({
    type,
    month,
    week,
    year,
    country,
    regionTH,
    dronemodel,
    province,
    amphure,
    start_date,
    end_date,
}) =>
    get(
        url.GET_DASHBOARD_DRONE +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}&province=${province}&district=${amphure}&start_date=${start_date}&end_date=${end_date}`,
    )
const getdashboarddroneaverage = ({
    type,
    month,
    week,
    year,
    country,
    regionTH,
    dronemodel,
    province,
    amphure,
    start_date,
    end_date,
}) =>
    get(
        url.GET_DASHBOARD_DRONEAVERAGE +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}&province=${province}&district=${amphure}&start_date=${start_date}&end_date=${end_date}`,
    )
const getdashboarddronemodel = ({
    type,
    month,
    week,
    year,
    country,
    regionTH,
    dronemodel,
    province,
    amphure,
    value,
    start_date,
    end_date,
    display,
    pageDrone,
    sizeDrone,
}) =>
    get(
        url.GET_DASHBOARD_DRONEMODEL +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}&province=${province}&district=${amphure}&value=${value}&start_date=${start_date}&end_date=${end_date}&display=${display}&pageDrone=${pageDrone}&sizeDrone=${sizeDrone}`,
    )
const getdashboarddroneamount = ({
    type,
    month,
    week,
    year,
    drone,
    country,
    regionTH,
    dronemodel,
    start_date,
    end_date,
}) =>
    get(
        url.GET_DASHBOARD_DRONEAMOUNT +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}&start_date=${start_date}&end_date=${end_date}`,
    )
const getdashboarddroneflyaverage = ({
    type,
    month,
    week,
    year,
    country,
    regionTH,
    dronemodel,
    province,
    amphure,
    start_date,
    end_date,
}) =>
    get(
        url.GET_DASHBOARD_DRONEFLYAVERAGE +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}&province=${province}&district=${amphure}&start_date=${start_date}&end_date=${end_date}`,
    )
const getdashboarddroneusergraph = ({type, month, week, year, country, regionTH, dronemodel}) =>
    get(
        url.GET_DASHBOARD_DRONEUSERGRAPH +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}`,
    )
const getdashboardtotalarea = ({
    type,
    month,
    week,
    year,
    drone,
    region,
    country,
    regionTH,
    dronemodel,
    start_date,
    end_date,
}) =>
    get(
        url.GET_DASHBOARD_TOTALAREA +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${region}&drone=${dronemodel}&start_date=${start_date}&end_date=${end_date}`,
    )
const getincomeexpense = ({type, month, week, year, country, regionTH, dronemodel}) =>
    get(
        url.GET_INCOME_EXPENSE +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}`,
    )
const getdashjobgraph = ({type, month, week, year, country, regionTH, dronemodel}) =>
    get(
        url.GET_JOB_GRAPH +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}`,
    )
const getFlightHistory = ({type, month, week, year, country, regionTH, dronemodel, start_date, end_date}) =>
    get(
        url.GET_FLIGHT_HISTORY +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}&start_date=${start_date}&end_date=${end_date}`,
    )
const getWorkingHours = ({type, month, week, year, country, regionTH, dronemodel}) =>
    get(
        url.GET_WORKING_HOURS +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}`,
    )
const getdashjob = ({type, month, week, year, country, regionTH, dronemodel}) =>
    get(
        url.GET_JOB +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}`,
    )
const getdronemap = ({type, month, week, year, country, regionTH, dronemodel, start_date, end_date}) =>
    get(
        url.GET_DRONE_MAP +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}&start_date=${start_date}&end_date=${end_date}`,
    )
const getusermap = ({type, month, week, year, country, regionTH, dronemodel, start_date, end_date}) =>
    get(
        url.GET_USER_MAP +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}&start_date=${start_date}&end_date=${end_date}`,
    )
const getdroneprovince = ({type, month, week, year, country, regionTH, dronemodel, start_date, end_date}) =>
    get(
        url.GET_DRONE_PROVINCE +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}&start_date=${start_date}&end_date=${end_date}`,
    )
const getfilterdrone = () => get(url.GET_FILTER_DRONE, {option: true})
const getfilterregion = () => get(url.GET_FILTER_REGION, {option: true})
const getuserprovince = ({type, month, week, year, country, regionTH, dronemodel, start_date, end_date}) =>
    get(
        url.GET_USER_PROVINCE +
            `?type=${type}&month=${month}&week=${week}&year=${year}&country=${country}&region=${regionTH}&drone=${dronemodel}&start_date=${start_date}&end_date=${end_date}`,
    )
const getprovince = () => get(url.GET_PROVINCE, {option: true})
const getdistrict = id => get(`${url.GET_DISTRICT}/${id}`, {option: true})
const getNewDroneFlyAvarage = ({filter, data}) =>
    post(
        url.GET_DRONE_FLY_AVERAGE +
            `?type=${filter.type}&month=${filter.month}&week=${filter.week}&year=${filter.year}&drone=${filter.dronemodel}&start_date=${filter.start_date}&end_date=${filter.end_date} `,
        data,
    )
const getNewTotalArea = ({filter, data}) =>
    post(
        url.GET_NEW_TOTAL_AREA +
            `?type=${filter.type}&month=${filter.month}&week=${filter.week}&year=${filter.year}&drone=${filter.dronemodel}&start_date=${filter.start_date}&end_date=${filter.end_date}`,
        data,
    )
const getFilterProvince = data => post(url.GET_FILTER_PROVINCE, data, {option: true})
const getFilterDistrict = data => post(url.GET_FILTER_DISTRICT, data, {option: true})
const getFilterRegion = data => get(url.GET_FILTER_REGION, {option: true})
const getFilterDrone = () => get(url.GET_FILTER_DRONE, {option: true})
const getDashboardCumulative = params =>
    getWithQuery(
        `${url.GET_DASHBOARD_HEADER}?type=${params.type}&week=${params.week}&month=${params.month}&year=${params.year}&region=${params.region}`,
    )

export {
    getdashboardheader,
    getdashboarddrone,
    getdashboarddroneaverage,
    getdashboarddronemodel,
    getdashboarddroneamount,
    getdashboarddroneflyaverage,
    getdashboarddroneusergraph,
    getdashboardtotalarea,
    getincomeexpense,
    getdashjobgraph,
    getFlightHistory,
    getWorkingHours,
    getdashjob,
    getdronemap,
    getusermap,
    getdroneprovince,
    getfilterdrone,
    getfilterregion,
    getuserprovince,
    getprovince,
    getdistrict,
    getNewDroneFlyAvarage,
    getNewTotalArea,
    getFilterProvince,
    getFilterDistrict,
    getFilterRegion,
    getFilterDrone,
}
