import React, {useState, useEffect} from 'react'
import {Container, Row, Col, Button} from 'reactstrap'
import {withTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import './index.scss'

import iconArrowBack from '../../assets/images/icon/icon-arrow-back.png'

import CardDroneDateDetail from 'components/DroneiNalyze/DroneInformation/Droneinfo-2/Flight-history-detail/CardDroneDateDetail'
import MapFlightHistory from 'components/DroneiNalyze/DroneInformation/Droneinfo-2/Flight-history-detail/MapFilghtHistory'
import TableDetail from 'components/DroneiNalyze/DroneInformation/Droneinfo-2/Flight-history-detail/TableDetail'

import FlightHistoryDetailTitleSkeleton from 'components/DroneiNalyze/Skeleton-DroneInformation/Flight-history-detail/FlightHistoryDetailTitleSkeleton'
import CardDroneDataDetailSkeleton from 'components/DroneiNalyze/Skeleton-DroneInformation/Flight-history-detail/CardDroneDataDetailSkeleton'
import MapFlightHistorySkeleton from 'components/DroneiNalyze/Skeleton-DroneInformation/Flight-history-detail/MapFlightHistorySkeleton'
import TableDetailSkeleton from 'components/DroneiNalyze/Skeleton-DroneInformation/Flight-history-detail/TableDetailSkeleton'
import {getFlightHistoryDetail} from 'services/skc/drone-information'

const FlightHistoryDetail = props => {
    const {t, i18n} = props

    const history = useHistory()

    const hardwareID = history.location?.state?.hardwareID
    const flightID = history.location?.state?.flight_id
    const [detailData, setDetailData] = useState({})

    const [loadingSkeleton, setLoadingSkeleton] = useState(false)

    const droneFlightSummaryInfo = {}

    useEffect(() => {
        fetchFlightHistoryDetail(flightID)
    }, [])

    const fetchFlightHistoryDetail = async params => {
        try {
            setLoadingSkeleton(true)
            const response = await getFlightHistoryDetail(params)

            if (response?.status_code === 200 && response?.results) {
                setDetailData(response?.results)
            }
        } catch (error) {
            setDetailData({})
        } finally {
            setLoadingSkeleton(false)
        }
    }

    const onClickBack = () => {
        if (history?.location?.state?.path === 'drone') {
            history.push({
                pathname: '/drone-information/overall',
                search: `${detailData?.data?.dat_drone_id}`,
            })
        }
        if (history?.location?.state?.path === 'user') {
            history.push({
                pathname: '/user-information/overall',
                search: `${detailData?.data?.dat_drone_id}`,
            })
        }
    }

    return (
        <div className="page-content">
            <Container fluid className="content-flight-history-detail">
                {loadingSkeleton ? (
                    <FlightHistoryDetailTitleSkeleton />
                ) : (
                    <Row className="row">
                        <Col xs={10} md={10} lg={10} xl={10}>
                            <p className="section-header">{t('droneInformation.flightHistory')}</p>
                        </Col>
                        <Col className="col-button-back" xs={2} md={2} lg={2} xl={2}>
                            <Button color="light" className="btn btn-light waves-effect btn-back" onClick={onClickBack}>
                                <img src={iconArrowBack} />
                                <span>{t('common.back')}</span>
                            </Button>
                        </Col>
                    </Row>
                )}
                <div id="page-content">
                    <Row className="row margin-t-16">
                        <Col lg={6} xl={6}>
                            <Row className="row">
                                <Col lg={12} xl={12} className="col-card">
                                    {loadingSkeleton ? (
                                        <CardDroneDataDetailSkeleton />
                                    ) : (
                                        <CardDroneDateDetail data={detailData?.data} />
                                    )}
                                </Col>
                                <Col lg={12} xl={12} className="col-map">
                                    {loadingSkeleton ? (
                                        <MapFlightHistorySkeleton />
                                    ) : (
                                        <MapFlightHistory data={detailData} />
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} xl={6}>
                            {loadingSkeleton ? <TableDetailSkeleton /> : <TableDetail data={detailData} />}
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default withTranslation()(FlightHistoryDetail)
