import {getWithQuery, postWithQuery, post} from '../../helpers/api_helper'

import {ENDPOINT} from 'constants/endpoint'

export const getUserInalyzeWidget = async (params, value) => {
    try {
        const response = await getWithQuery(
            `${ENDPOINT.USER_INALYZE_WIDGET}?type=${params?.type}&month=${params?.month}&week=${params?.week}&year=${params?.year}&country=${params?.country}&start_date=${params?.start_date}&end_date=${params?.end_date}&value=${value}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getUserMapInalyze = async (params, value) => {
    try {
        const response = await postWithQuery(
            `${ENDPOINT.INALYZE_MAP}?type=${params.type}&week=${params.week}&month=${params.month}&year=${params.year}&start_date=${params.start_date}&end_date=${params.end_date}&value=${value}&country=${params.country}&to_date=${params.to_date}`,
            params,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getUserInalyzeScatterCustomer = async params => {
    try {
        const response = await post(`${ENDPOINT.USER_INALYZE_SCATTER_CUSTOMER}`, params)

        return response
    } catch (error) {
        return null
    }
}

export const getUserInalyzeBoxPlot = async params => {
    try {
        const response = await post(`${ENDPOINT.USER_INALYZE_BOX_PLOT}`, params)

        return response
    } catch (error) {
        return null
    }
}
