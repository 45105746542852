import React from 'react'
import {Row, Col, Card, CardTitle, CardBody} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './realtime-detail-skeleton.scss'

const RealTimeMapDetailSkeleton = () => {
    return <Skeleton width={1355} height={820} />
}

export default RealTimeMapDetailSkeleton
