import {atom} from 'recoil'
import dayjs from 'dayjs'

export const filterDroneInalyzeState = atom({
    key: 'filterDroneInalyze',
    default: {
        type: 'year',
        month: '',
        week: '',
        year: dayjs().year(),
        start_date: dayjs().startOf('year').format('YYYY-MM-DD'),
        end_date: dayjs().endOf('year').format('YYYY-MM-DD'),
        country: 'all',
        drone: [],
        region: [],
        province: [],
        district: [],
        date: dayjs(),
        value: 'drone',
    },
})

export const loadingInalyzeState = atom({
    key: 'loadingInalyze',
    default: {
        map: false,
        compare_area: false,
        compare_time: false,
    },
})

export const formCompareAreaState = atom({
    key: 'formCompareArea',
    default: {
        areaType: 'country',
        amountCompare: 2,
        amountCompareList: [
            {label: 'กรุณาเลือก', value: '', showValue: true},
            {label: 'กรุณาเลือก', value: '', showValue: true},
            {label: 'กรุณาเลือก', value: '', showValue: false},
        ],
        compareLists: [],
        compareCountryLists: [],
        compareRegionLists: [],
        compareProvinceLists: [],
        timeType: 'year',
        month: dayjs().month() + 1,
        year: dayjs().year(),
        quarter: '',
        halfYear: 1,
        drone: [],
        defaultQuarter: dayjs().startOf('quarter'),
        droneBtn: [],
    },
})

export const formCompareTimeState = atom({
    key: 'formCompareTime',
    default: {
        compareData: '',
        drone: [],
        periodTimeGroup: [
            {
                timeType: 'year',
                year: [],
                month: [],
                quarter: [],
                halfYear: [],
            },
            {
                timeType: 'month',
                year: [],
                quarter: [],
                month: [],
                halfYear: null,
            },
        ],
    },
})

export const monitoringState = atom({
    key: 'monitoring',
    default: {
        totalFlight: 0,
        filters: [],
        droneModel: [
            {
                id: 'KD1',
                name: 'K-D1',
                drone: 0,
                active: false,
            },
            {
                id: 'KD10',
                name: 'K-D10',
                drone: 0,
                active: false,
            },
            {
                id: 'KD20',
                name: 'K-D20',
                drone: 0,
                active: false,
            },
            {
                id: 'KD20P',
                name: 'K-D20P',
                drone: 0,
                active: false,
            },
            {
                id: 'KD25',
                name: 'K-D25',
                drone: 0,
                active: false,
            },
            {
                id: 'KD30',
                name: 'K-D30',
                drone: 0,
                active: false,
            },
            {
                id: 'KD40',
                name: 'K-D40',
                drone: 0,
                active: false,
            },
            {
                id: 'KD50',
                name: 'K-D50',
                drone: 0,
                active: false,
            },
        ],
    },
})
