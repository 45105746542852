import React, { Component, useRef, createRef } from "react";
import { DatePicker, Space, DatePickerProps, Select } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/th";
const { RangePicker } = DatePicker;
const yearPicker = ({ value, onChange }) => {
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };
  return (
    <Space direction="vertical" size={13}>
      <DatePicker
        picker="year"
        style={{
          fontFamily: "Inter",
          fontWeight: 400,
          fontSize: 14,
          borderColor: "#CED4DA",
          borderRadius: 8,
          color: "#495057",
          height: 38,
          // width:"80%"
        }}
        defaultValue={value}
        disabledDate={disabledDate}
        bordered={true}
        onChange={onChange}
      />
    </Space>
  );
};
export default yearPicker;
