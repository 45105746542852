import React from 'react'

import {Row, Col, Card, CardTitle, CardBody} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import '../comparison-skeleton.scss'

const ResultAreaSkeleton = props => {
    const {btnSkeleton, compareAreaForm} = props

    return (
        <Row className="skeleton-result-area">
            <Col xs={12} sm={12} md={12} lg={12} className="container-header">
                <Skeleton className="skeleton-text-header" />
            </Col>
            <>
                <Col xl={12} lg={12}>
                    <Card>
                        <CardTitle className="container-btn">
                            {btnSkeleton?.map(item => (
                                <Skeleton key={item?.value} className="btn-select-drone-skeleton" />
                            ))}
                        </CardTitle>
                        <CardBody>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Row>
                                        {compareAreaForm?.compareLists?.map(itm => (
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} key={itm.value}>
                                                <Card className="skeleton-card-compare">
                                                    <CardTitle className="title-card">
                                                        <Skeleton width={100} height={33} />
                                                    </CardTitle>
                                                    <CardBody style={{paddingBottom: 0, height: '100px'}}>
                                                        <Row>
                                                            <Col
                                                                xs={6}
                                                                sm={6}
                                                                md={12}
                                                                lg={6}
                                                                style={{marginBottom: 40}}>
                                                                <Skeleton className="card-skeleton-graph" />
                                                            </Col>

                                                            <Col
                                                                xs={6}
                                                                sm={6}
                                                                md={12}
                                                                lg={6}
                                                                style={{marginBottom: 40}}>
                                                                <Skeleton className="card-skeleton-graph" />
                                                            </Col>

                                                            <Col
                                                                xs={6}
                                                                sm={6}
                                                                md={12}
                                                                lg={6}
                                                                style={{marginBottom: 40}}>
                                                                <Skeleton className="card-skeleton-graph" />
                                                            </Col>

                                                            <Col
                                                                xs={6}
                                                                sm={6}
                                                                md={12}
                                                                lg={6}
                                                                style={{marginBottom: 40}}>
                                                                <Skeleton className="card-skeleton-graph" />
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </>
        </Row>
    )
}

export default ResultAreaSkeleton
