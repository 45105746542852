import PropTypes from 'prop-types'
import React, {Component} from 'react'

//Simple bar
import SimpleBar from 'simplebar-react'

// MetisMenu
import MetisMenu from 'metismenujs'
import {withRouter, Link} from 'react-router-dom'

import {withTranslation} from 'react-i18next'

class SidebarContent extends Component {
    constructor(props) {
        super(props)
        this.refDiv = React.createRef()
        const {match} = this.props

        this.state = {
            user_id: null,
            showMenuDrone:
                match.path.includes('/inalyze') ||
                match.path.includes('/real-time-monitoring') ||
                match.path.includes('/drone-information')
                    ? true
                    : false,
            showMenuUser:
                match.path.includes('/user-inalyze') || match.path.includes('/user-information/overall') ? true : false,
        }
    }

    componentDidMount() {
        this.setState({user_id: JSON.parse(localStorage.getItem('authUser')).results?.id})
        setTimeout(() => {
            this.initMenu()
        }, 100)
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, ss) {
        if (this.props.type !== prevProps.type) {
            this.initMenu()
        }
    }

    initMenu() {
        new MetisMenu('#side-menu')

        let matchingMenuItem = null
        const ul = document.getElementById('side-menu')
        const items = ul.getElementsByTagName('a')
        for (let i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i]
                break
            }
        }

        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem)
        }
    }

    scrollElement = item => {
        setTimeout(() => {
            if (this.refDiv.current !== null) {
                if (item) {
                    const currentPosition = item.offsetTop
                    if (currentPosition > window.innerHeight) {
                        if (this.refDiv.current) {
                            this.refDiv.current.getScrollElement().scrollTop = currentPosition - 300
                        }
                    }
                }
            }
        }, 300)
    }

    activateParentDropdown = item => {
        item.classList.add('active')
        const parent = item.parentElement

        const parent2El = parent.childNodes[1]
        if (parent2El && parent2El.id !== 'side-menu') {
            parent2El.classList.add('mm-show')
        }

        if (parent) {
            parent.classList.add('mm-active')
            const parent2 = parent.parentElement

            if (parent2) {
                parent2.classList.add('mm-show') // ul tag

                const parent3 = parent2.parentElement // li tag

                if (parent3) {
                    parent3.classList.add('mm-active') // li
                    parent3.childNodes[0].classList.add('mm-active') //a
                    const parent4 = parent3.parentElement // ul
                    if (parent4) {
                        parent4.classList.add('mm-show') // ul
                        const parent5 = parent4.parentElement
                        if (parent5) {
                            parent5.classList.add('mm-show') // li
                            parent5.childNodes[0].classList.add('mm-active') // a tag
                        }
                    }
                }
            }
            this.scrollElement(item)

            return false
        }
        this.scrollElement(item)
        return false
    }

    onClickMenuDrone = current => {
        this.setState({showMenuUser: false, showMenuDrone: !current})
    }

    onClickMenuUser = current => {
        this.setState({showMenuUser: !current, showMenuDrone: false})
    }

    render() {
        const {showMenuDrone, showMenuUser} = this.state

        return (
            <React.Fragment>
                <SimpleBar className="h-100" ref={this.refDiv}>
                    <div id="sidebar-menu">
                        <ul
                            className="metismenu list-unstyled"
                            id="side-menu"
                            style={{
                                fontSize: 14,
                                fontWeight: 500,
                                fontFamily: 'Inter',
                                color: '#A8A8BB',
                            }}>
                            <li className="menu-title">{this.props.t('Menu')}</li>
                            <li>
                                <Link to="/dashboard">
                                    <i className="dripicons-graph-pie" />
                                    <span>{this.props.t('menu.kInalyzeDashboard')}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/#" onClick={() => this.onClickMenuDrone(showMenuDrone)}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}>
                                        <div>
                                            <i className="bx bx-home-circle" />

                                            <span>{this.props.t('menu.droneInalyze')}</span>
                                        </div>
                                        {showMenuDrone ? (
                                            <>
                                                <i id="arrow-menu-drone" className="bx bx-chevron-up"></i>
                                            </>
                                        ) : (
                                            <i id="arrow-menu-drone" className="bx bx-chevron-down"></i>
                                        )}
                                    </div>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li>
                                        <Link to="/inalyze">{this.props.t('menu.iNalyze')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/real-time-monitoring">
                                            {this.props.t('menu.realTimeMonitoring')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/drone-information">{this.props.t('menu.droneInformation')}</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/#" onClick={() => this.onClickMenuUser(showMenuUser)}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}>
                                        <div>
                                            <i className="bx bx-user" />
                                            <span>{this.props.t('menu.userInalyze')}</span>
                                        </div>
                                        {showMenuUser ? (
                                            <i
                                                id="arrow-menu-user"
                                                className="bx bx-chevron-up"
                                                style={{zIndex: 999}}></i>
                                        ) : (
                                            <i id="arrow-menu-user" className="bx bx-chevron-down"></i>
                                        )}
                                    </div>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li>
                                        <Link to="/user-inalyze">{this.props.t('menu.iNalyze')}</Link>
                                    </li>

                                    <li>
                                        <Link to="/user-information/overall">
                                            {this.props.t('menu.userInformation')}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </SimpleBar>
            </React.Fragment>
        )
    }
}

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    type: PropTypes.string,
}

export default withRouter(withTranslation()(SidebarContent))
