import {useState} from 'react'

import {Container, Row, Col, DropdownMenu, DropdownToggle, Dropdown, DropdownItem} from 'reactstrap'
import html2canvas from 'html2canvas'
import dayjs from 'dayjs'
import './index.scss'

import IconExport from '../../assets/images/icon/icon-export.png'

import InalyzeData from 'components/DroneiNalyze/iNalyze/iNalyze/InalyzeData'
import FormComparison from 'components/DroneiNalyze/iNalyze/Comparison/FormComparison'

const Inalyze = () => {
    const [menuExport, setMenuExport] = useState(false)

    const toggleExport = () => {
        setMenuExport(current => !current)
    }

    const downloadScreenshot = async ext => {
        html2canvas(document.getElementById('page-content'), {
            scale: 1.2,
        }).then(async function (canvas) {
            const data = await canvas.toDataURL(`image/${ext}`),
                link = document.createElement('a')

            link.href = data
            link.download = `${dayjs().format('DD-MM-YYYY-hmmss')}.${ext}`

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        })
    }
    return (
        <div className="page-content">
            <Container fluid>
                <Row className="d-flex justify-content-end  container-row">
                    <Col xs={12} sm={12} md={6} lg={6} className=" container-btn-export d-flex justify-content-end ">
                        <Dropdown isOpen={menuExport} toggle={toggleExport} style={{marginRight: 16}}>
                            <DropdownToggle className="btn-export">
                                <img src={IconExport} alt="" height="20" className="img-export" />
                                <span>Export</span>
                            </DropdownToggle>
                            <DropdownMenu className="box-menu-export">
                                <DropdownItem className="item-menu-export-header" header>
                                    Export to
                                </DropdownItem>

                                <DropdownItem
                                    className="item-menu-export"
                                    onClick={() => {
                                        downloadScreenshot('png')
                                    }}>
                                    png
                                </DropdownItem>
                                <DropdownItem className="item-menu-export" onClick={() => downloadScreenshot('jpeg')}>
                                    jpeg
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row id="page-content" className="row-data">
                    <Col xl={12} className="col-data">
                        <InalyzeData />
                    </Col>
                    <Col xl={12} className="col-data">
                        <FormComparison />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Inalyze
