import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React, { Component, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

const CardSkeleton = ({ }) => {
  return (
    <Col>
      <Card
        style={{
          display: "flex",
          borderRadius: 8,
          boxShadow: "0px 12px 24px rgb(240, 240, 240)",
        }}
      >
        <CardTitle
          style={{
            justifyContent: "flex-start",
            alignItems: "center",
            display: "flex",
            marginTop: 30,
            paddingLeft: 25,
            paddingRight: 25,
            paddingBottom: 30,
            borderBottom: "1px solid #EDECEC",
          }}
        >
          <Skeleton width={180} height={24} />
        </CardTitle>
        <CardBody>
          <Row>
            <Col lg="12" className="d-flex">

              <Skeleton width={60} height={33} style={{ borderRadius: 8, marginLeft: 15 }} />
              <Skeleton width={60} height={33} style={{ borderRadius: 8, marginLeft: 10 }} />
              <Skeleton width={60} height={33} style={{ borderRadius: 8, marginLeft: 10 }} />
              <Skeleton width={60} height={33} style={{ borderRadius: 8, marginLeft: 10 }} />
              <Skeleton width={60} height={33} style={{ borderRadius: 8, marginLeft: 10 }} />

              <div className="filter-navbar" style={{ marginLeft: 15 }}>
                <Skeleton width={100} />
              </div>
            </Col>
            <Col lg="12" className="d-flex align-items-center" style={{ marginTop: 15 }}>
              <Skeleton width={40} style={{ marginLeft: 15 }} />

              <div className="filter-navbar" style={{ marginLeft: 15 }}>
                <Skeleton width={100} />
              </div>
              <div className="filter-input-navbar  align-items-center" style={{ marginLeft: 15 }}>
                <Skeleton width={80} height={18} />
                <Skeleton width={80} height={18} style={{ marginLeft: 8 }}/>
                <Skeleton width={80} height={18} style={{ marginLeft: 8 }}/>
              </div>
            </Col>
            <Col lg="12" className="d-flex align-items-center" style={{ marginTop: 15 }}>
              <Skeleton width={40} style={{ marginLeft: 15 }} />

              <div className="filter-navbar" style={{ marginLeft: 15 }}>
                <Skeleton width={100} />
              </div>
              <Skeleton width={40} style={{ marginLeft: 15 }} />
              <div className="filter-input-navbar  align-items-center" style={{ marginLeft: 15 }}>
              <Skeleton width={100} />
              </div>
              <Skeleton width={80} height={33} style={{ borderRadius: 8, marginLeft: 15 }} />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="card-skeleton " style={{ marginTop: 20 }}>
                <Skeleton height={500} />
              </div>
            </Col>

          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CardSkeleton;
