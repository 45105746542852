import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React, { Component, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import "./style.scss";
const CardSkeleton = ({ amount }) => {
  const loadCards = Array(amount).fill(1);
  return loadCards.map((card, i) => (
    <Col key={i}>
      <Card
        style={{
          borderRadius: 8,
          boxShadow: "0px 12px 24px rgb(240, 240, 240)",
        }}
      >
        <CardBody>
          <div className="card-skeleton " >
            <div className="d-flex align-items-center " style={{  }}>       
              <Skeleton width={60} height={33} style={{ borderRadius: 8, marginLeft: 10 }} />
              <Skeleton width={60} height={33} style={{ borderRadius: 8, marginLeft: 10 }} />
              <Skeleton width={60} height={33} style={{ borderRadius: 8, marginLeft: 10 }} />
              <Skeleton width={60} height={33} style={{ borderRadius: 8, marginLeft: 10 }} />
              <div className="filter-navbar" style={{ marginLeft: 15 }}>
                <Skeleton width={100} />
              </div>
              <Skeleton width={60} height={33} style={{ borderRadius: 8, marginLeft: 10 }} />
            </div>     
          </div>
        </CardBody>
      </Card>
    </Col>
  ));
};

export default CardSkeleton;
