import React, {useState, useEffect} from 'react'
import {Card, CardBody, Col, Row, Input, Button} from 'reactstrap'
import {withTranslation} from 'react-i18next'
import {DatePicker} from 'antd'
const {RangePicker} = DatePicker
import {useHistory} from 'react-router-dom'
import {useRecoilState, useRecoilValue} from 'recoil'

import dayjs from 'dayjs'
import 'dayjs/locale/th'

import iconEyeOpen from '../../../../assets/images/icon/icon-eye-open.png'
import iconPlay from '../../../../assets/images/icon/icon-play.png'

import DataTable from '../DataTable'
import SkeletonDroneFlightListsTable from 'components/DroneiNalyze/Skeleton-DroneInformation/Usage/SkeletonDroneFlightListsTable'

import {getUsageDroneFlightListsTable} from 'services/skc/drone-information'
import {filterNavbarUsageState, loadingDroneInformationState} from 'store/atoms/drone-information'

const dateFormat = 'YYYY-MM-DD'

const UsageDroneFlightListsTable = props => {
    const {t, i18n} = props
    const history = useHistory()
    const filterNavbarUsage = useRecoilValue(filterNavbarUsageState)
    const [loadingDroneInformation, setLoadingDroneInformation] = useRecoilState(loadingDroneInformationState)
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState('')
    const [upOrDown, setUpOrDown] = useState('')
    const [search, setSearch] = useState('')
    const [defaultData, setDefaultData] = useState({})
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [rows, setRows] = useState([])
    const [total, setTotal] = useState(0)

    const [loadingSkeleton, setLoadingSkeleton] = useState(false)

    const [language, setLanguage] = useState(i18n.language)

    useEffect(() => {
        fetchFlightListsTable({
            ...filterNavbarUsage,
            idType: 'drone',
            page,
            pageSize,
            upOrDown,
            search,
            sortBy,
            start_date: '',
            end_date: '',
        })
    }, [])

    useEffect(() => {
        if (loadingDroneInformation.flight_lists_history) {
            fetchFlightListsTable({
                ...filterNavbarUsage,
                idType: 'drone',
                page,
                pageSize,
                upOrDown,
                search,
                sortBy,
                start_date: '',
                end_date: '',
            })

            setLoadingDroneInformation(cur => ({...cur, flight_lists_history: false}))
        }
    }, [loadingDroneInformation.flight_lists_history])

    useEffect(() => {
        if (i18n.language !== language) {
            fetchFlightListsTable({
                ...filterNavbarUsage,
                idType: 'drone',
                page,
                pageSize,
                upOrDown,
                search,
                sortBy,
                start_date: startDate || '',
                end_date: endDate || '',
            })

            setLanguage(i18n.language)
        }
    }, [i18n.language])

    const fetchFlightListsTable = async params => {
        try {
            setLoadingSkeleton(true)
            const response = await getUsageDroneFlightListsTable(params)

            if (response.status_code === 200 && response.results) {
                setDataInRow(response.results)
            }
        } catch (error) {
        } finally {
            setLoadingSkeleton(false)
        }
    }

    const setDataInRow = data => {
        const newRows =
            data?.data?.length > 0
                ? data?.data?.map(itm => {
                      const date = new Date(itm.time_start)

                      const formattedTime = date.toLocaleTimeString('th-TH').slice(0, 5)

                      return {
                          ...itm,
                          time_start: `${dayjs(itm.time_start).format('DD/MM/YYYY')} ${formattedTime}`,
                          average_speed: itm.average_speed?.toLocaleString('en-US', {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                          }),
                          usedPerRai: itm.usedPerRai?.toLocaleString('en-US', {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                          }),
                          area: itm.area?.toLocaleString('en-US', {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                          }),
                          hour: itm.hour?.toLocaleString('en-US', {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                          }),
                      }
                  })
                : []

        setRows(newRows)
        setDefaultData(data?.data)
        setTotal(data?.totalItems)
    }

    const onClickFlightHistoryDetail = (id, hardwareId, droneModel, flight_id) => {
        history.push(`/drone-information/flight-history/${hardwareId}/${id}/${droneModel}/detail/${flight_id}`, {
            path: 'drone',
            hardwareID: hardwareId,
            flight_id: flight_id,
        })
    }

    const onClickPlayFlightHistory = (id, hardwareId) => {
        history.push(`/drone-information/flight-history/${hardwareId}/${id}/play`, {
            path: 'drone',
            backTo: `/drone-information/usage`,
        })
    }

    const disabledDate = current => {
        // Disable the current date and future dates
        return current && current >= dayjs().endOf('day')
    }

    const onChangePagination = (page, pageSize) => {
        fetchFlightListsTable({
            ...filterNavbarUsage,
            idType: 'drone',
            page: page,
            pageSize: pageSize,
            upOrDown,
            search,
            sortBy,
            start_date: startDate || '',
            end_date: endDate || '',
        })

        setPage(page)
        setPageSize(pageSize)
    }

    const handleChangeTime = date => {
        const startDate = date?.[0] ? dayjs(date?.[0]).format(dateFormat) : ''
        const endDate = date?.[1] ? dayjs(date?.[1] || '').format(dateFormat) : ''

        fetchFlightListsTable({
            ...filterNavbarUsage,
            idType: 'drone',
            page,
            pageSize,
            upOrDown,
            search,
            sortBy,
            start_date: startDate,
            end_date: endDate,
        })

        setStartDate(startDate)
        setEndDate(endDate)
    }

    const onChangeSearchTable = data => {
        fetchFlightListsTable({
            ...filterNavbarUsage,
            idType: 'drone',
            page,
            pageSize,
            upOrDown,
            search: data,
            sortBy,
            start_date: startDate || '',
            end_date: endDate || '',
        })

        setSearch(data)
    }

    const onSort = (columnName, sortType) => {
        fetchFlightListsTable({
            ...filterNavbarUsage,
            idType: 'drone',
            page,
            pageSize,
            upOrDown: sortType,
            search: data,
            sortBy: columnName,
            start_date: startDate || '',
            end_date: endDate || '',
        })

        setSortBy(columnName)
        setUpOrDown(sortType)
    }

    return (
        <Col xl={12}>
            {loadingSkeleton ? (
                <SkeletonDroneFlightListsTable />
            ) : (
                <Card style={{borderRadius: '8px'}}>
                    <CardBody>
                        <Row>
                            <Col xl={5}>
                                <p
                                    style={{
                                        fontFamily: 'Kanit',
                                        fontWeight: 600,
                                        fontSize: '20px',
                                        color: '#000000',
                                    }}>
                                    {t('droneInformation.flightHistory')}
                                </p>
                            </Col>
                            <Col
                                xl={1}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    padding: 0,
                                    margin: 0,
                                }}>
                                <p
                                    style={{
                                        padding: 0,
                                        margin: 0,
                                        fontFamily: 'Kanit',
                                        fontSize: '15px',
                                        fontWeight: 500,
                                    }}>
                                    {t('common.search')} :
                                </p>
                            </Col>
                            <Col xl={3}>
                                <RangePicker
                                    placeholder={[t('common.pleaseSelectStartDate'), t('common.pleaseSelectEndDate')]}
                                    style={{
                                        fontFamily: 'Inter',
                                        fontWeight: 400,
                                        fontSize: 14,
                                        borderColor: '#CED4DA',
                                        borderRadius: 8,
                                        color: '#495057',
                                        height: 38,
                                        width: '100%',
                                    }}
                                    format="DD MMM YYYY"
                                    defaultValue={[
                                        startDate ? dayjs(startDate) : null,
                                        endDate ? dayjs(endDate) : null,
                                    ]}
                                    onChange={(date, dateString) => {
                                        handleChangeTime(date)
                                    }}
                                    disabledDate={disabledDate}
                                />
                            </Col>
                            <Col xl={3}>
                                <Input
                                    value={search}
                                    type="text"
                                    placeholder={t('common.searchFlightLocation')}
                                    style={{borderRadius: '8px'}}
                                    onChange={e => onChangeSearchTable(e.target.value)}
                                />
                            </Col>
                            {rows?.length > 0 ? (
                                <Col xl={12} style={{marginTop: '24px'}}>
                                    <DataTable
                                        onChangePagination={onChangePagination}
                                        action={[
                                            {
                                                button: (
                                                    <Button
                                                        style={{
                                                            width: 36,
                                                            height: 36,
                                                            margin: 0,
                                                            padding: 0,
                                                            border: '1px solid',
                                                            borderColor: '#34c38f',
                                                            background: 'white',
                                                        }}>
                                                        <img
                                                            style={{width: 20, height: 20, boxShadow: 'none'}}
                                                            src={iconEyeOpen}
                                                        />
                                                    </Button>
                                                ),
                                                onClick: onClickFlightHistoryDetail,
                                            },
                                            {
                                                button: (
                                                    <Button
                                                        style={{width: 36, height: 36, margin: 0, padding: 0}}
                                                        type="button"
                                                        className="btn btn-success">
                                                        <img style={{width: 20, height: 20}} src={iconPlay} />
                                                    </Button>
                                                ),
                                                onClick: onClickPlayFlightHistory,
                                            },
                                        ]}
                                        column={[
                                            {
                                                key: 'time_start',
                                                title: t('droneInformation.flightDate'),
                                                textAlign: 'left',
                                                sort: true,
                                                width: 150,
                                            },
                                            {
                                                key: 'area_name',
                                                title: t('droneInformation.location'),
                                                textAlign: 'left',
                                                sort: true,
                                                width: '38%',
                                            },
                                            {
                                                key: 'area',
                                                title: t('droneInformation.workingAreaRai'),
                                                textAlign: 'left',
                                                sort: true,
                                                width: 150,
                                            },
                                            {
                                                key: 'hour',
                                                title: t('droneInformation.flightTimeMUnit'),
                                                textAlign: 'left',
                                                sort: true,
                                                width: 150,
                                            },
                                            {
                                                key: 'average_speed',
                                                title: t('droneInformation.averageSpeedMSUnit'),
                                                textAlign: 'left',
                                                sort: false,
                                                width: 150,
                                            },
                                            {
                                                key: 'usageType',
                                                title: t('droneInformation.workingType'),
                                                textAlign: 'left',
                                                sort: false,
                                                width: 130,
                                                setColor: true,
                                                checkChangeColor: t('droneInformation.spraying'),
                                                color1: '#D323FF',
                                                background1: '#FAE8FF',
                                                color2: '#FF8A00',
                                                background2: '#FFF1D9',
                                            },
                                            {
                                                key: 'usedPerRai',
                                                title: t('droneInformation.averageSprayingSpreadingQuantity'),
                                                textAlign: 'left',
                                                sort: false,
                                                width: 200,
                                            },
                                        ]}
                                        row={rows}
                                        isPagination={true}
                                        pageSize={pageSize}
                                        total={total}
                                        rowPerPage={page}
                                        onSort={onSort}
                                        isShowBorderLine={true}
                                    />
                                </Col>
                            ) : (
                                <Col xl={12} style={{marginTop: '24px'}}>
                                    <DataTable
                                        onChangePagination={onChangePagination}
                                        action={[
                                            {
                                                button: (
                                                    <Button
                                                        style={{
                                                            width: 36,
                                                            height: 36,
                                                            margin: 0,
                                                            padding: 0,
                                                            border: '1px solid',
                                                            borderColor: '#34c38f',
                                                            background: 'white',
                                                        }}>
                                                        <img
                                                            style={{width: 20, height: 20, boxShadow: 'none'}}
                                                            src={iconEyeOpen}
                                                        />
                                                    </Button>
                                                ),
                                                onClick: onClickFlightHistoryDetail,
                                            },
                                            {
                                                button: (
                                                    <Button
                                                        style={{width: 36, height: 36, margin: 0, padding: 0}}
                                                        type="button"
                                                        className="btn btn-success">
                                                        <img style={{width: 20, height: 20}} src={iconPlay} />
                                                    </Button>
                                                ),
                                                onClick: onClickPlayFlightHistory,
                                            },
                                        ]}
                                        column={[
                                            {
                                                key: 'date',
                                                title: t('droneInformation.flightDate'),
                                                textAlign: 'left',
                                                sort: false,
                                                width: '10%',
                                            },
                                            {
                                                key: 'location',
                                                title: t('droneInformation.location'),
                                                textAlign: 'left',
                                                sort: false,
                                                width: '30%',
                                            },
                                            {
                                                key: 'workArea',
                                                title: t('droneInformation.workingAreaRai'),
                                                textAlign: 'left',
                                                sort: false,
                                                width: '10%',
                                            },
                                            {
                                                key: 'workTime',
                                                title: t('droneInformation.flightTimeMUnit'),
                                                textAlign: 'left',
                                                sort: false,
                                                width: '10%',
                                            },
                                            {
                                                key: 'average_speed',
                                                title: t('droneInformation.averageSpeedMSUnit'),
                                                textAlign: 'left',
                                                sort: false,
                                                width: '10%',
                                            },
                                            {
                                                key: 'usageType',
                                                title: t('droneInformation.workingType'),
                                                textAlign: 'left',
                                                sort: false,
                                                width: '10%',
                                                setColor: true,
                                                checkChangeColor: t('droneInformation.spraying'),
                                                color1: '#D323FF',
                                                background1: '#FAE8FF',
                                                color2: '#FF8A00',
                                                background2: '#FFF1D9',
                                            },
                                            {
                                                key: 'usedPerRai',
                                                title: t('droneInformation.averageSprayingSpreadingQuantity'),
                                                textAlign: 'left',
                                                sort: false,
                                                width: '10%',
                                            },
                                        ]}
                                        row={[]}
                                        isPagination={false}
                                        pageSize={0}
                                        total={0}
                                        rowPerPage={page}
                                    />
                                </Col>
                            )}
                        </Row>
                    </CardBody>
                </Card>
            )}
        </Col>
    )
}

export default withTranslation()(UsageDroneFlightListsTable)
