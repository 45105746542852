import React, {useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {useRecoilState, useRecoilValue} from 'recoil'
import {Row, Col, Card, CardBody, Table} from 'reactstrap'
import ReactApexChart from 'react-apexcharts'

import {filterDashboardState, loadingDashboardState} from 'store/atoms/dashboard'
import {getFlightHistory} from 'services/skc/new-dashboard'

import FlightHistorySkeleton from './CardSkeleton/FlightHistorySkeleton'

import './style/flight-history.scss'

const DashboardFlightHistory = props => {
    const {t, i18n} = props

    const [loading, setLoading] = useRecoilState(loadingDashboardState)
    const filterDashboard = useRecoilValue(filterDashboardState)

    const [skeleton, setSkeleton] = useState(true)
    const [rating, setRating] = useState([])
    const [series, setSeries] = useState([])
    const [ranges, setRanges] = useState([])
    const [language, setLanguage] = useState(i18n.language)

    useEffect(() => {
        fetchFlightHistory(filterDashboard)
    }, [])

    useEffect(() => {
        if (loading.flight_history) {
            fetchFlightHistory(filterDashboard)
            setLoading(cur => ({...cur, flight_history: false}))
        }
    }, [loading.flight_history])

    useEffect(() => {
        if (i18n.language !== language) {
            fetchFlightHistory(filterDashboard)
            setLoading(cur => ({...cur, flight_history: false}))
            setLanguage(i18n.language)
        }
    }, [i18n.language])

    const fetchFlightHistory = async params => {
        try {
            setSkeleton(true)

            const response = await getFlightHistory(params)

            if (response.status_code === 200 && response?.results) {
                setRanges(response?.results?.ranges)
                setRating(response?.results?.rating)
                setSeries(response?.results?.series)
            }
        } catch (error) {
            setRanges([])
            setRating([])
            setSeries([])
        } finally {
            setSkeleton(false)
        }
    }
    return (
        <>
            <Col xl="12">
                {skeleton ? (
                    <FlightHistorySkeleton />
                ) : (
                    <Card className="card-flight-history">
                        <CardBody>
                            <Row className="row-head">
                                <Col xl={12}>
                                    <h4 className="text-head">{t('dashboard.totalFlightHistory')}</h4>
                                </Col>

                                <Col xl={12}>
                                    <p className="mb-4 text-muted text-sub">{t('dashboard.droneFlightHistory')}</p>
                                </Col>
                            </Row>
                            <Row className="row-table">
                                <Col lg="3">
                                    <h5 className="text-flight-time-ranking">{t('dashboard.flightTimeRanking')}</h5>

                                    <div className="type-drone table-flight">
                                        <Table className="table-nowrap">
                                            <tbody>
                                                {rating?.length > 0 ? (
                                                    rating?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="col-text">{index + 1}.</td>
                                                            <td className="col-text">{item.days}</td>
                                                            <td>
                                                                <span className="ms-2 text-truncate col-text">
                                                                    {Intl.NumberFormat('en-US').format(
                                                                        item.amounthour.toLocaleString('en-US', {
                                                                            minimumFractionDigits: 0,
                                                                            maximumFractionDigits: 2,
                                                                        }),
                                                                    )}
                                                                </span>
                                                                <span className="ms-2 text-truncate col-unit">
                                                                    {t('common.hourUnit')}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="ms-2 text-truncate col-text">
                                                                    {item.time}
                                                                </span>
                                                                <span className="ms-2 text-truncate col-unit">
                                                                    {t('common.minuteUnit')}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td className="col-no-data" colSpan={4}>
                                                            {t('common.noData')}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>

                                <Col lg="9">
                                    {series?.length > 0 ? (
                                        <div id="chart" className="chart-flight-history">
                                            <ReactApexChart
                                                options={{
                                                    chart: {
                                                        height: 350,
                                                        type: 'heatmap',
                                                        toolbar: {
                                                            show: false,
                                                        },
                                                    },
                                                    legend: {
                                                        show: false,
                                                        position: 'top',
                                                        horizontalAlign: 'center',
                                                        fontSize: 17,
                                                        fontWeight: 500,
                                                    },

                                                    plotOptions: {
                                                        heatmap: {
                                                            shadeIntensity: 0.5,
                                                            radius: 0,

                                                            colorScale: {
                                                                ranges: ranges,
                                                            },
                                                        },
                                                    },

                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    yaxis: {
                                                        title: {
                                                            text: t('common.day'),

                                                            offsetX: 13,
                                                            offsetY: 0,
                                                            style: {
                                                                fontSize: '12px',
                                                            },
                                                        },
                                                    },
                                                    tooltip: {
                                                        y: {
                                                            formatter: function (val) {
                                                                return (
                                                                    val.toLocaleString('en-US', {
                                                                        minimumFractionDigits: 0,
                                                                        maximumFractionDigits: 2,
                                                                    }) + ` ${t('common.hourUnit')}`
                                                                )
                                                            },
                                                        },
                                                    },
                                                    xaxis: {
                                                        title: {
                                                            text: `${t('common.time')}`,

                                                            offsetX: 0,
                                                            offsetY: 0,
                                                            style: {
                                                                fontSize: '12px',
                                                                color: '#495057',
                                                            },
                                                        },
                                                    },
                                                }}
                                                series={series}
                                                type="heatmap"
                                                height={450}
                                            />
                                        </div>
                                    ) : (
                                        <div className="div-no-data">
                                            <p className="text-no-data">{t('common.noData')}</p>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                )}
            </Col>
        </>
    )
}

export default withTranslation()(DashboardFlightHistory)
