import React from 'react'
import {Col, Card, CardBody} from 'reactstrap'

import {withTranslation} from 'react-i18next'

const CardWidget = ({img, title, value, color, badgeValue, unit, avg, unit_avg, i18n}) => {
    return (
        <React.Fragment>
            <Col>
                <Card
                    style={{
                        borderRadius: 8,
                        boxShadow: '0px 12px 24px rgb(240, 240, 240)',
                    }}>
                    <CardBody>
                        <div
                            className="d-flex align-items-center mb-3 "
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                            }}>
                            <h5
                                className="mb-0"
                                style={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: '#495057',
                                    textAlign: 'end',
                                }}>
                                {title}
                            </h5>
                            {color === 'success' ? (
                                <h4
                                    style={{
                                        fontSize: 12,
                                        fontWeight: 700,
                                        fontFamily: 'Inter',
                                        color: '#34C38F',
                                        marginBottom: 0,
                                    }}>
                                    <i className={'mdi mdi-arrow-top-right ms-1 text-' + color} />
                                    {badgeValue}%
                                </h4>
                            ) : color === 'danger' ? (
                                <h4
                                    style={{
                                        fontSize: 12,
                                        fontWeight: 700,
                                        fontFamily: 'Inter',
                                        color: '#F46A6A',
                                        marginBottom: 0,
                                    }}>
                                    <i className={'mdi mdi-arrow-bottom-right ms-1 text-' + color} />
                                    {badgeValue}%
                                </h4>
                            ) : (
                                <h4
                                    style={{
                                        fontSize: 12,
                                        fontWeight: 700,
                                        fontFamily: 'Inter',
                                        color: '#FAAF41',
                                        marginBottom: 0,
                                    }}>
                                    {badgeValue}%
                                </h4>
                            )}
                        </div>
                        <div
                            className="d-flex align-items-center "
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: title === i18n.t('userInalyze.totalJobs') ? 27 : 40,
                            }}>
                            <div
                                className="d-flex align-items-center "
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                }}>
                                <h4
                                    style={{
                                        fontSize: 24,
                                        fontWeight: 700,
                                        fontFamily: 'Inter',
                                        color: '#495057',
                                        marginBottom: 0,
                                        alignSelf: 'flex-start',
                                    }}>
                                    {value}
                                    <span
                                        className="ms-2 text-truncate"
                                        style={{
                                            fontSize: 13,
                                            fontWeight: 400,
                                            color: '#79829C',
                                            fontFamily: 'Kanit',
                                        }}>
                                        {unit}
                                    </span>
                                </h4>
                                {title === i18n.t('userInalyze.totalJobs') && (
                                    <h4
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 700,
                                            fontFamily: 'Inter',
                                            color: '#556EE6',
                                            marginBottom: 0,
                                            alignSelf: 'flex-start',
                                        }}>
                                        <span
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 400,
                                                color: '#556EE6',
                                                fontFamily: 'Kanit',
                                                marginRight: 5,
                                            }}>
                                            {i18n.t('userInalyze.averageTasks')}
                                        </span>
                                        {avg}
                                        <span
                                            className="ms-2 text-truncate"
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 400,
                                                color: '#556EE6',
                                                fontFamily: 'Kanit',
                                            }}>
                                            {unit_avg}
                                        </span>
                                    </h4>
                                )}
                            </div>

                            <img src={img} alt="" height="32" style={{alignSelf: 'self-end', boxShadow: 'none'}} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
}

export default withTranslation()(CardWidget)
