import React, {useState, useEffect} from 'react'
import {Card, CardBody} from 'reactstrap'
import {useRecoilState, useRecoilValue} from 'recoil'
import {
    Area,
    YAxis,
    XAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Text,
    ComposedChart,
    Bar,
} from 'recharts'
import {withTranslation} from 'react-i18next'

import SkeletonFlightDuration from 'components/DroneiNalyze/Skeleton-DroneInformation/Usage/SkeletonFlightDuration'
import timerPause from '../../../../assets/images/icon/timer-pause.png'
import {filterNavbarUsageState, loadingDroneInformationState} from 'store/atoms/drone-information'
import {getUsageFlightDuration} from 'services/skc/drone-information'

const labels = [
    {name: 'totalFlyTime', color: '#556EE6'},
    {name: 'averageFlyTime', color: '#F46A6A'},
    {name: 'active_average', color: '#34C38F'},
]

const UsageFlightDuration = props => {
    const {t, i18n} = props

    const [active, setActive] = useState(1)
    const [count, setCount] = useState(0)

    const [data, setData] = useState({})
    const [dataSeriesMonth, setDataSeriesMonth] = useState([])
    const [dataOpen, setDataOpen] = useState(false)

    const [loadingSkeleton, setLoadingSkeleton] = useState(false)
    const [language, setLanguage] = useState(i18n.language)

    const filterNavbarUsage = useRecoilValue(filterNavbarUsageState)
    const [loadingDroneInformation, setLoadingDroneInformation] = useRecoilState(loadingDroneInformationState)

    const twoDecimal = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})

    useEffect(() => {
        fetchUsageFlightDuration(filterNavbarUsage)
    }, [])

    useEffect(() => {
        if (loadingDroneInformation?.fly_duration) {
            fetchUsageFlightDuration(filterNavbarUsage)
            setLoadingDroneInformation(cur => ({...cur, fly_duration: false}))
        }
    }, [loadingDroneInformation?.fly_duration])

    useEffect(() => {
        if (i18n.language !== language) {
            fetchUsageFlightDuration(filterNavbarUsage)
            setLanguage(i18n.language)
        }
    }, [i18n.language])

    const fetchUsageFlightDuration = async params => {
        try {
            setLoadingSkeleton(true)
            const response = await getUsageFlightDuration(params)

            if (response?.status_code === 200 && response?.results?.data?.length > 0) {
                setDataSeriesCharts(response?.results)
                setData(response?.results)
            }
        } catch (error) {
            setDataSeriesMonth([])
            setData({})
        } finally {
            setLoadingSkeleton(false)
        }
    }

    const CustomTooltip = ({active, payload, label}) => {
        const tooltip = {
            backgroundColor: '#000',
            opacity: '0.8',

            borderRadius: '4px',

            paddingTop: '8px',
        }

        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip " style={tooltip}>
                    <p
                        style={{
                            textAlign: 'center',
                            color: '#fff',
                            fontSize: 13,
                            fontWeight: 400,
                        }}>
                        {label}
                    </p>
                    <div
                        style={{
                            marginTop: -10,
                            marginBottom: 8,
                            borderTopWidth: '1px',
                            borderTopColor: '#404040',
                            borderTopStyle: 'solid',
                        }}
                    />
                    {payload.map((pld, key) => (
                        <React.Fragment key={key}>
                            {pld?.chartType !== 'bar' && (
                                <div className="d-flex align-items-start " style={{paddingLeft: 10, paddingRight: 10}}>
                                    <div className="me-2">
                                        <i className="mdi mdi-circle" style={{color: pld.stroke}} />
                                    </div>
                                    <div className="flex-grow-1">
                                        <div className="text-muted">
                                            <p
                                                className="mb-0"
                                                style={{
                                                    color: '#fff',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                }}>
                                                {pld.dataKey == 'totalFlyTime'
                                                    ? t('droneInformation.totalFlightTimesMinutes')
                                                    : pld.dataKey == 'averageFlyTime'
                                                    ? filterNavbarUsage.type == 'year'
                                                        ? t('droneInformation.averageFlightTimesMinutesMonth')
                                                        : t('droneInformation.averageFlightTimesMinutesDays')
                                                    : pld.dataKey == 'active_average'
                                                    ? filterNavbarUsage.type == 'year'
                                                        ? t('droneInformation.averageFlightTimesWorkingPeriodMinMonth')
                                                        : t('droneInformation.averageFlightTimesWorkingPeriodMinDays')
                                                    : ''}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="ml-2">
                                        <p
                                            className=""
                                            style={{
                                                color: '#fff',
                                                marginLeft: 40,
                                                fontSize: 13,
                                                fontWeight: 600,
                                                fontFamily: 'Inter',
                                            }}>
                                            {twoDecimal.format(pld.value)}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            )
        }

        return null
    }

    const toggle = tab => {
        setActive(Number(tab))
    }

    const handleSlideRight = () => {
        const newBarSeries = []

        const newData = []
        for (let i = 0; i < data?.data?.length; i++) {
            if (count === 0 && newData.length < 10 && i > 9) {
                newData.push(data?.data[i])
            }
            if (count === 1 && i > 19) {
                newData.push(data?.data[i])
            }
        }
        newBarSeries.push({newData})

        setDataSeriesMonth(newData)
        setDataOpen(true)
        setCount(current => (current === 1 ? 1 : current + 1))
    }

    const handleSlideLeft = () => {
        const newData = []

        for (let i = 0; i < data?.data.length; i++) {
            if (count === 1 && newData?.length < 10 && i > 9) {
                newData.push(data?.data[i])
            }

            if (count === 0 && newData?.length < 10) {
                newData.push(data?.data[i])
            }
        }

        setDataSeriesMonth(newData)
        setDataOpen(true)
        setCount(current => (current === 0 ? 0 : current - 1))
    }

    const setDataSeriesCharts = data => {
        if (!dataOpen || active === 1) {
            const newData = []

            for (let i = 0; i < 10; i++) {
                newData.push(data?.data[i])
            }

            setDataSeriesMonth(newData)
        }

        if (dataOpen && active !== 1) {
            const newData = []

            if (active === 2) {
                for (let i = 0; i < data?.data?.length; i++) {
                    if (newData.length < 10 && i > 9) {
                        newData.push(data?.data[i])
                    }
                }
            } else {
                for (let i = 0; i < data?.data?.length; i++) {
                    if (newData?.length <= 10 && i > 19) {
                        newData?.push(data?.data[i])
                    }
                }
            }
            setDataSeriesMonth(newData)
        }
    }

    return (
        <>
            {loadingSkeleton ? (
                <SkeletonFlightDuration />
            ) : (
                <Card
                    style={{
                        borderRadius: 8,
                        height: 600,
                        paddingTop: 10,
                    }}>
                    <CardBody>
                        <div className="d-flex flex-wrap" style={{marginBottom: 20}}>
                            <h1
                                className="card-title me-2"
                                style={{
                                    fontSize: 20,
                                    fontWeight: 600,
                                    paddingLeft: 20,
                                }}>
                                {t('droneInformation.flightTimes')}
                            </h1>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginLeft: 80,
                            }}>
                            <div>
                                <h6
                                    className="font-size-14 align-items-center"
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: '#495057',
                                    }}>
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 400,
                                            color: '#495057',
                                            paddingRight: 15,
                                        }}>
                                        <img src={timerPause} alt="" height="18" />
                                    </span>
                                    {t('droneInformation.averageFlightTimesWorkingPeriod')}
                                </h6>
                                <h2
                                    style={{
                                        fontFamily: 'Inter',
                                        fontSize: 22,
                                        fontWeight: 700,
                                        color: '#000000',
                                    }}>
                                    {twoDecimal.format(data?.active_average || 0)}
                                    <span
                                        className="font-size-12"
                                        style={{
                                            fontFamily: 'Kanit',
                                            fontSize: 12,
                                            fontWeight: 300,
                                            color: '#495057',
                                        }}>
                                        {'   ' + '\xa0\xa0' + `${t('common.minutes')}/`}
                                        {filterNavbarUsage?.type == 'year' ? t('common.month') : t('common.day')}
                                    </span>
                                </h2>
                            </div>
                            <div
                                style={{
                                    width: '50%',
                                    borderLeft: '2px rgba(242, 242, 242, 1) solid',
                                    paddingLeft: 20,
                                    marginRight: 20,
                                }}>
                                <h6
                                    className="font-size-14 align-items-center"
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: '#495057',
                                    }}>
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 400,
                                            color: '#495057',
                                            paddingRight: 15,
                                        }}>
                                        <img src={timerPause} alt="" height="18" />
                                    </span>
                                    {t('droneInformation.averageFlightTimesTotal')}
                                </h6>
                                <h2
                                    style={{
                                        fontFamily: 'Inter',
                                        fontSize: 22,
                                        fontWeight: 700,
                                        color: '#000000',
                                    }}>
                                    {twoDecimal.format(data?.average || 0)}
                                    <span
                                        className="font-size-12"
                                        style={{
                                            fontFamily: 'Kanit',
                                            fontSize: 12,
                                            fontWeight: 300,
                                            color: '#495057',
                                        }}>
                                        {'   ' + '\xa0\xa0' + `${t('common.minutes')}/`}
                                        {filterNavbarUsage?.type == 'year' ? t('common.month') : t('common.day')}
                                    </span>
                                </h2>
                            </div>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                height: 380,
                                // paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 30,
                            }}>
                            <ResponsiveContainer>
                                <ComposedChart
                                    data={filterNavbarUsage?.type === 'month' ? dataSeriesMonth : data?.data}
                                    margin={{top: 10, right: 30, left: 20, bottom: 0}}>
                                    <CartesianGrid stroke="#E0E0E0" vertical={false} />
                                    <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#556EE6" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#556EE6" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#F46A6A" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#F46A6A" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#34C38F" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#34C38F" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="name" />
                                    <YAxis
                                        stroke="#373D3F"
                                        tickLine={false}
                                        tickFormatter={value => twoDecimal.format(value)}
                                        label={
                                            <Text
                                                x={-40}
                                                y={80}
                                                dx={50}
                                                dy={150}
                                                offset={0}
                                                angle={-90}
                                                fill={'#495057'}
                                                fontSize={'12'}
                                                style={{
                                                    textAlign: 'center',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                    fontFamily: 'Kanit',
                                                }}>
                                                {t('droneInformation.flightTimesMinutes')}
                                            </Text>
                                        }
                                    />

                                    <Tooltip content={<CustomTooltip />} cursor={{fill: '#EAEAEA'}} />
                                    <Legend
                                        textDecoration={{
                                            color: '#fff',
                                            fontSize: 12,
                                            fontWeight: 500,
                                            fontFamily: 'Inter',
                                        }}
                                        textLength={{
                                            color: '#fff',
                                            fontSize: 12,
                                            fontWeight: 500,
                                            fontFamily: 'Inter',
                                        }}
                                        margin={{bottom: 20}}
                                        verticalAlign="bottom"
                                        height={36}
                                        align="center"
                                        inactiveColor="red"
                                        wrapperStyle={{
                                            color: '#79829Cff',
                                            fontSize: 12,
                                            fontWeight: 500,
                                            fontFamily: 'Inter',
                                            bottom: -18,
                                            left: 60,
                                        }}
                                        payload={labels?.map((item, index) => ({
                                            ke: index,
                                            id: item.name,
                                            type: 'circle',
                                            value:
                                                item.name === 'totalFlyTime'
                                                    ? t('droneInformation.totalFlightTimesMinutes')
                                                    : item.name === 'averageFlyTime'
                                                    ? filterNavbarUsage.type === 'year'
                                                        ? t('droneInformation.averageFlightTimesMinutesMonth')
                                                        : t('droneInformation.averageFlightTimesMinutesDays')
                                                    : item.name === 'active_average'
                                                    ? filterNavbarUsage.type === 'year'
                                                        ? t('droneInformation.averageFlightTimesWorkingPeriodMinMonth')
                                                        : t('droneInformation.averageFlightTimesWorkingPeriodMinDays')
                                                    : '',
                                            color: item.color,
                                        }))}
                                    />
                                    <Bar
                                        chartType="bar"
                                        dataKey="totalFlyTime"
                                        stroke="#556EE6"
                                        barSize={20}
                                        strokeWidth={2.5}
                                        fill="#556EE6"
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="totalFlyTime"
                                        stroke="#556EE6"
                                        fillOpacity={1}
                                        strokeWidth={2.5}
                                        fill="rgba(85, 110, 230, 0.1)"
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="averageFlyTime"
                                        stroke="#F46A6A"
                                        fillOpacity={1}
                                        strokeWidth={2.5}
                                        fill="rgba(244, 106, 106, 0.1)"
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="active_average"
                                        stroke="#34C38F"
                                        fillOpacity={1}
                                        strokeWidth={2.5}
                                        fill="rgba(52, 195, 143, 0.1)"
                                    />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </div>
                        {filterNavbarUsage?.type == 'month' && data?.data?.length !== 0 && (
                            <div
                                className="col align-items-center d-flex justify-content-between "
                                style={{marginTop: '-5%'}}>
                                <span
                                    className="btn "
                                    onClick={() => {
                                        handleSlideLeft(), active > 1 ? toggle(Number(active) - 1) : toggle(1)
                                    }}
                                    style={{color: '#9AA2A9', marginLeft: '2%'}}>
                                    <i className="mdi mdi-triangle mdi-2px mdi-rotate-270"></i>
                                </span>
                                <span
                                    className="btn"
                                    onClick={() => {
                                        handleSlideRight(), active < 3 ? toggle(Number(active) + 1) : toggle(3)
                                    }}
                                    style={{color: '#9AA2A9', marginLeft: '-4%'}}>
                                    <i className="mdi mdi-triangle mdi-2px mdi-rotate-90"></i>
                                </span>
                            </div>
                        )}
                    </CardBody>
                </Card>
            )}
        </>
    )
}

export default withTranslation()(UsageFlightDuration)
