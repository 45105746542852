import React, { Component, useRef, createRef } from "react";
import { DatePicker, Space, DatePickerProps, Select } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/th";
const { RangePicker } = DatePicker;
const weekPicker = ({value ,onChange}) => {
    
    const weekFormat = "DD MMMM YYYY";
    const customWeekStartEndFormat = (value) =>
    `${dayjs(value).startOf("week").format(weekFormat)} - ${dayjs(value)
      .endOf("week")
      .format(weekFormat)}`;
      const disabledDate = (current) => {
        return current && current > dayjs().endOf("day");
      };
  return (
    <Space direction="vertical" size={13}>
      <DatePicker
        picker="week"
        style={{
          fontFamily: "Inter",
          fontWeight: 400,
          fontSize: 14,
          borderColor: "#CED4DA",
          borderRadius: 8,
          color: "#495057",
          height: 38,
        }}
        defaultValue={value}
        format={customWeekStartEndFormat}
        disabledDate={disabledDate}
        bordered={true}
        onChange={onChange}
      />
    </Space>
  );
};
export default weekPicker;
