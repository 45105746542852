const inalyze = {
    inalyze: 'iNalyze',
    comparison: 'Comparison',
    droneActive: 'Drone (Active)',
    numberOfDrones: 'Number of drones',
    flightHours: 'Flight hours',
    workingDays: 'Working days',
    workingArea: 'Working area',
    averageSprayingArea: 'Average spraying area',
    totalFlightHours: 'Total flight hours',
    totalWorkingDays: 'Total working days',
    allWorkingArea: 'All working areas',
    totalWorkingArea: 'Total working area',
    compareByArea: 'Compare by area',
    compareByTime: 'Compare by time',
    selectComparison: 'Select Comparison',
    comparisonQuantity: 'Comparison Quantity',
    selectTimeRange: 'Select time range',
    pleaseSelectTimeRange: 'Please select a time range',
    pleaseSelectYear: 'Please select year',
    firstHalfYear: 'First half year (Jan - Jun)',
    secondHalfYear: 'Second half year (Jul - Dec)',
    compare: 'Compare',
    comparisonSelectionResult: 'Comparison selection result',
    closeData: 'Close Data',
    openData: 'Open Data',
    cumulativeDrones: 'Cumulative drones',
    totalFlightTime: 'Total flight time',
    totalFlightTimeHrUnit: 'Total flight time (Hours)',
    averageFlightTime: 'Average flight time',
    averageFlightTimeHrDroneUnit: 'Average flight time (Hours/Unit)',
    averageLine: 'Average line',
    workingDay: 'Working days',
    workingDayDayUnit: 'Working days (Days)',
    averageWorkingDays: 'Average working days',
    averageWorkingDaysHourDayUnit: 'Average working days (Hours/Day)',
    totalWorkingAreas: 'Total working areas',
    totalWorkingAreasHourUnit: 'Total working areas (Hours)',
    averageWorkingAreas: 'Average working areas',
    averageWorkingAreasRaiDroneUnit: 'Average working areas (Rai/Unit)',
    totalNumberOfFlights: 'Total number of flights',
    totalFlight: 'Total flights',
    totalNumberOfFlightsFlightUnit: 'Total number of flights (Flights)',
    averageNumberOfFlights: 'Average number of flights',
    averageNumberOfFlightsTimeDroneUnit: 'Average number of flights (Time/Unit)',
    averageSprayingQuantity: 'Average spraying quantity',
    averageSprayingQuantityLiterRaiUnit: 'Average spraying quantity (Liter/Rai)',
    averageSpeed: 'Average Speed',
    averageSpeedMsUnit: 'Average Speed (m/s)',
    averageHeight: 'Average Height',
    averageHeightMUnit: 'Average Height (m)',
    dataForComparison: 'Data for comparison',
    pleaseSelectDataForComparison: 'Please select data for comparison',
    selectTimeRangeGroup: 'Select time range group',
    flightDuration: 'Flight Duration',
    numberOfWorkingDays: 'Number of working days',
    compareWorkingAreas: 'Working Areas',
    compareNumberOfFlights: 'Number of Flights',
    compareSprayingQuantity: 'Spraying Quantity',
    compareSpeed: 'Speed',
    compareHeight: 'Height',
    pleaseSelectYear: 'Please select year',
    duringThisTimeTheSelectionHasBeenMade: 'During this time, the selection has been made.',
    firstYear: 'First year',
    secondYear: 'Second year',
    droneInformationAndUseInEachArea: 'Drone information and use in each area',
    comparisonOfDataByAreaAndTimePeriod: 'Comparison of data by area and time period',
    totalWorkingDrones: 'Total working drones',
    totalDrones: 'Total drones',
}
export default inalyze
