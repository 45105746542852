import {del, get, post, put, upload} from '../../helpers/api_kafka_helper'
import {ENDPOINT} from 'constants/endpoint'

export const getDroneLatest = async params => {
    try {
        const response = await post(`${ENDPOINT.DRONE_STATUS}`, {hardware: params})

        return response
    } catch (error) {
        return null
    }
}

export const getUsageSpeedHeightDrone = async params => {
    try {
        const response = await get(
            `${ENDPOINT.DRONE_INFORMATION_USAGE_SPEED_HEIGHT_DRONE}/${params.id}?data_type=${params.data_type}&type=${params.type}&year=${params.year}&month=${params.month}&week=${params.week}&start_date=${params.start_date}&end_date=${params.end_date}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getFlightHistoryPlay = async params => {
    try {
        const response = await get(`${ENDPOINT.FLIGHT_HISTORY_PLAY}/${params.hardwareID}/${params.id}`)

        return response
    } catch (error) {
        return null
    }
}

export const getUserInformationDroneStatus = async params => {
    try {
        const response = await post(`${ENDPOINT.USER_INFORMATION_DRONE_STATUS}`, params)

        return response
    } catch (error) {
        return null
    }
}

export const getUserInformationSpeedHeightDrone = async params => {
    try {
        const response = await post(
            `${ENDPOINT.USER_INFORMATION_SPEED_HEIGHT_DRONE}?data_type=${params?.data_type}&type=${params?.type}&month=${params?.month}&week=${params?.week}&year=${params?.year}&start_date=&${params?.start_date}&end_date=${params?.end_date}`,
            {drones: params?.drones},
        )

        return response
    } catch (error) {
        return null
    }
}
