import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {} from '../../store/actions'

import {Link} from 'react-router-dom'

//i18n
import {withTranslation} from 'react-i18next'
import SidebarContent from './SidebarContent'

import logoKidrone from '../../assets/images/Logo.png'
import logoK from '../../assets/images/logo-K.png'

class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <div className="vertical-menu " style={{backgroundColor: '#151529', position: 'fixed'}}>
                    <div className="navbar-brand-box " style={{backgroundColor: '#151529'}}>
                        <div className="logo logo-light">
                            <span className="logo-sm">
                                <img src={logoK} alt="" height="22" />
                            </span>
                            <span className="logo-lg">
                                <img
                                    src={logoKidrone}
                                    alt=""
                                    height="70"
                                    style={{
                                        paddingTop: 15,
                                        boxShadow: 'none',
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                    <div data-simplebar className="h-100">
                        {this.props.type !== 'condensed' && <SidebarContent type={this.props.type} />}
                    </div>
                    <div className="sidebar-background"></div>
                </div>
            </React.Fragment>
        )
    }
}

Sidebar.propTypes = {
    type: PropTypes.string,
}

const mapStateToProps = state => {
    return {
        layout: state.Layout,
    }
}
export default connect(mapStateToProps, {})(withRouter(withTranslation()(Sidebar)))
