import { takeEvery, put, call } from "redux-saga/effects";

// Chat Redux States
import { GET_HEIGHT_DRONE_ACTIVE } from "./actionTypes";
import { getHeightDroneActiveSuccess, getHeightDroneActiveFail } from "../actions";

//Include Both Helper File with needed methods
import { getSpeedHeightDroneActive } from "../../services/skc/kafka";

function* onGetHeightDroneActive(action) {
  try {
    const response = yield call(getSpeedHeightDroneActive, action.payload);

    yield put(getHeightDroneActiveSuccess(response));
  } catch (error) {
    yield put(getHeightDroneActiveFail(error));
  }
}

function* heightDroneActiveSaga() {
  yield takeEvery(GET_HEIGHT_DRONE_ACTIVE, onGetHeightDroneActive);
}

export default heightDroneActiveSaga;
