import React from 'react'

import {Row, Col, Card, CardTitle, CardBody} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import '../comparison-skeleton.scss'

const ResultTimeSkeleton = props => {
    return (
        <Card className="skeleton-result-time">
            <CardTitle className="card-title">
                <Skeleton width={326} height={42} style={{marginBottom: 16}} />
            </CardTitle>
            <CardBody>
                <Row style={{margin: 0, padding: 0}}>
                    <Col xl={12} className="col-box-chart">
                        {props.dataCompare?.data?.data?.map(item => (
                            <div className="container-box" key={item?.name}>
                                <Skeleton className="box-color" />
                                <Skeleton className="box-text" />
                            </div>
                        ))}
                    </Col>
                    {props?.dataCompare?.data?.name !== 'ความเร็ว (m/s)' &&
                        props?.dataCompare?.data?.name !== 'Speed (m/s)' &&
                        props?.dataCompare?.data?.name !== 'Height (m)' &&
                        props?.dataCompare?.data?.name !== 'ความสูง (m)' && (
                            <Col xl={6}>
                                <div className="title-chart">
                                    <Skeleton width={200} height={32} />
                                </div>
                                <Skeleton width={'100%'} height={350} />
                            </Col>
                        )}
                    {props?.dataCompare?.dataAverage?.name === 'ความเร็ว เฉลี่ย (m/s)' ||
                    props?.dataCompare?.dataAverage?.name === 'Speed average (m/s)' ||
                    props?.dataCompare?.dataAverage?.name === 'Height average (m)' ||
                    props?.dataCompare?.dataAverage?.name === 'ความสูง เฉลี่ย (m)' ? (
                        <Col xl={12}>
                            {' '}
                            <div className="title-chart">
                                <Skeleton width={200} height={32} />
                            </div>
                            <Skeleton width={'100%'} height={350} />
                        </Col>
                    ) : (
                        <Col xl={6}>
                            {' '}
                            <div className="title-chart">
                                <Skeleton width={200} height={32} />
                            </div>
                            <Skeleton width={'100%'} height={350} />
                        </Col>
                    )}
                </Row>
            </CardBody>
        </Card>
    )
}

export default ResultTimeSkeleton
