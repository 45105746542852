import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER,LOGIN_FAIL } from "./actionTypes"
import { loginSuccess, logoutUserSuccess, loginUserFail } from "./actions"

import {
  postLogin,
} from "../../../services/skc/auth";


function* loginUser({ payload: { username,password } }) {
  try {
    const response = yield call(postLogin, {
      email: username,
      password: password,
    })
    localStorage.setItem("authUser", JSON.stringify(response))
    yield put(loginSuccess(response))
    // history.push("/dashboard")
  } catch (error) {
    yield put(loginUserFail({status : false}))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    // yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
