import React, {Component} from 'react'
import {Row, Col, Card, CardBody, CardFooter, CardTitle} from 'reactstrap'
import ReactApexChart from 'react-apexcharts'
// import icon
import IconArrowUp from '../../../../assets/images/icon/icon-arrow-up.png'
import IconArrowDownRed from '../../../../assets/images/icon/icon-arrow-down-red.png'
import 'animate.css'
import {Switch} from 'antd'
import AnimateHeight from 'react-animate-height'
import {withTranslation} from 'react-i18next'
import i18n from '../../../../i18n'
class CardResultCompare extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: this.props?.index,
            data: this.props.data?.data,
            showData: true,
            height: 'auto',
        }

        this.setDataUnitSeries = this.setDataUnitSeries.bind(this)
    }

    componentDidMount() {
        const {data} = this.props
        this.setDataUnitSeries(data)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data.data.length > 0) {
            this.setDataUnitSeries(nextProps.data)
        }
    }

    componentDidUpdate() {}

    setDataUnitSeries = data => {
        const {t} = this.props
        const newData = []
        data?.data?.forEach(itemDetail => {
            let newItem
            if (itemDetail?.name === t('inalyze.totalWorkingDrones')) {
                newItem = {
                    ...itemDetail,
                    colorChart: '#F1B44C',
                    unit: t('common.droneUnit'),
                    series: [
                        {
                            data: itemDetail?.data?.length > 0 ? itemDetail?.data : [],
                        },
                    ],
                }

                newData.push(newItem)
            }

            if (itemDetail?.name === t('inalyze.totalFlight')) {
                newItem = {
                    ...itemDetail,
                    colorChart: '#34C38F',
                    unit: t('common.flightTimeUnit'),
                    series: [
                        {
                            data: itemDetail?.data?.length > 0 ? itemDetail?.data : [],
                        },
                    ],
                }
                newData.push(newItem)
            }

            if (itemDetail?.name === t('inalyze.totalFlightTime')) {
                newItem = {
                    ...itemDetail,
                    unit: t('common.hourUnit'),
                    colorChart: '#556EE6',
                    series: [
                        {
                            data: itemDetail?.data?.length > 0 ? itemDetail?.data : [],
                        },
                    ],
                }
                newData.push(newItem)
            }

            if (itemDetail?.name === t('inalyze.totalWorkingArea')) {
                newItem = {
                    ...itemDetail,
                    unit: t('common.raiUnit'),
                    colorChart: '#20A9F6',
                    series: [
                        {
                            data: itemDetail?.data?.length > 0 ? itemDetail?.data : [],
                        },
                    ],
                }
                newData.push(newItem)
            }
        })
        this.setState({data: newData})
    }

    onChangeShowData(checked) {
        this.setState({showData: checked})

        if (checked) {
            this.setState({height: 'auto'})
        } else {
            this.setState({height: 0})
        }
    }

    render() {
        const state = this.state
        const {t} = this.props

        let nf = new Intl.NumberFormat()

        return (
            // card ใหญ่ เปลี่ยนสี ตาม เลข
            <Card className={`card-compare `} style={{background: state.index % 2 === 0 ? '#F5EFEB' : '#F2F4FC'}}>
                <CardTitle className="title-card">
                    <p style={{margin: 0}}>{this?.props?.data?.title}</p>

                    <div className="box-show-data">
                        <p className="text-close">{state.showData ? t('inalyze.closeData') : t('inalyze.openData')}</p>
                        <Switch defaultChecked={state.showData} onChange={check => this.onChangeShowData(check)} />
                    </div>
                </CardTitle>
                <AnimateHeight id="example-panel" duration={500} height={state.height}>
                    <CardBody
                        className={`animate__animated ${state.showData ? 'animate__fadeIn' : 'animate__fadeOut'}`}>
                        <Row className={`card-item `}>
                            {this.state?.data?.map((item, index) => (
                                <Col xs={6} sm={6} md={12} lg={6} key={`${item?.name}-${index}`}>
                                    <Card className={`card-item`}>
                                        <CardBody
                                            style={{
                                                paddingBottom: 0,
                                                height: i18n.language === 'en' ? 120 : 100,
                                            }}>
                                            <Row>
                                                <Col md={6} lg={6}>
                                                    <Row>
                                                        {/* ค่า title-detail เปลี่ยนคำตามค่าที่ส่งมา */}
                                                        <Col lg={12} className="title-detail">
                                                            {item?.name}
                                                        </Col>
                                                        {/* ค่า data-detail เปลี่ยนคำตามค่าที่ส่งมา */}
                                                        <Col md={12} lg={12} className="container-detail-chart">
                                                            <span className="data-detail">
                                                                {item.total?.toLocaleString() || '-'}
                                                            </span>
                                                            <span className="unit">{item?.unit || '-'}</span>
                                                            {item?.name === t('inalyze.totalWorkingDrones') && (
                                                                <div
                                                                    style={{
                                                                        fontFamily: 'Kanit',
                                                                        fontSize: '14px',
                                                                        color: '#79829C',
                                                                    }}>
                                                                    {t('inalyze.totalDrones')} :{' '}
                                                                    <span>{item?.totalAll?.toLocaleString() || 0}</span>{' '}
                                                                    <span> {t('common.droneUnit')}</span>
                                                                </div>
                                                            )}
                                                        </Col>
                                                        {/* ค่า unit เปลี่ยนคำตามค่าที่ส่งมา */}
                                                    </Row>
                                                </Col>
                                                {/* chart */}
                                                <Col md={6} lg={6}>
                                                    <ReactApexChart
                                                        options={{
                                                            chart: {
                                                                type: 'area',
                                                                height: 250,
                                                                width: '100%',
                                                                toolbar: {
                                                                    show: false,
                                                                },

                                                                zoom: {
                                                                    enabled: false,
                                                                },
                                                                animations: {
                                                                    enabled: false,
                                                                },
                                                            },

                                                            grid: {
                                                                show: false,
                                                                padding: {
                                                                    top: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    left: 0,
                                                                },
                                                            },
                                                            dataLabels: {
                                                                enabled: false,
                                                            },
                                                            colors: [item?.colorChart],
                                                            stroke: {
                                                                // เส้น line chart
                                                                curve: 'smooth',
                                                                width: 2,
                                                                // colors: ["red"],
                                                            },
                                                            markers: {
                                                                // จุด point
                                                                // colors: ["red"],

                                                                hover: {
                                                                    size: 6,
                                                                    sizeOffset: 7,
                                                                },
                                                            },
                                                            yaxis: {
                                                                show: false,
                                                            },
                                                            xaxis: {
                                                                // ค่า categories ที่ส่งมา data เป็นอะไร  exsample เป็น เดือน
                                                                categories: [...item?.label],
                                                                labels: {show: false},
                                                                axisBorder: {
                                                                    show: false,
                                                                },
                                                                axisTicks: {
                                                                    show: false,
                                                                },
                                                                tooltip: {
                                                                    enabled: false,
                                                                },
                                                            },

                                                            fill: {
                                                                type: 'gradient',
                                                                gradient: {
                                                                    enabled: true,
                                                                    opacityFrom: 0.7,
                                                                    opacityTo: 0.5,
                                                                    shadeIntensity: 1,
                                                                    stops: [0, 90, 100],
                                                                },
                                                            },
                                                            tooltip: {
                                                                enabled: true,

                                                                style: {
                                                                    fontSize: '13px',
                                                                    fontFamily: 'Kanit',
                                                                },

                                                                y: {
                                                                    formatter: function (val, index) {
                                                                        return val.toLocaleString() + ` ${item?.unit}`
                                                                    },
                                                                    title: {
                                                                        formatter: function (val, index) {
                                                                            return undefined
                                                                        },
                                                                    },
                                                                },

                                                                marker: {
                                                                    show: true,
                                                                },
                                                            },
                                                        }}
                                                        series={item?.series || []}
                                                        type="area"></ReactApexChart>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter className="card-footer">
                                            <Row>
                                                <Col md={3} lg={3} xl={3} style={{padding: 0}}>
                                                    <div
                                                        className="percent-box"
                                                        style={{
                                                            background:
                                                                item?.percentage > 0
                                                                    ? '#daf4eb'
                                                                    : item?.percentage === 0
                                                                    ? '#FFEFD9'
                                                                    : '#FFEAEA',
                                                        }}>
                                                        {item?.percentage !== 0 && (
                                                            <img
                                                                className="icon-arrow"
                                                                style={{
                                                                    marginLeft: 8,
                                                                    width: item?.percentage > 0 ? '8px' : '16px',
                                                                    height: item?.percentage > 0 ? '8px' : '16px',
                                                                }}
                                                                src={
                                                                    item?.percentage > 0
                                                                        ? IconArrowUp
                                                                        : IconArrowDownRed
                                                                }></img>
                                                        )}
                                                        <span
                                                            className="percent"
                                                            style={{
                                                                color:
                                                                    item?.percentage > 0
                                                                        ? '#34c38f'
                                                                        : item?.percentage === 0
                                                                        ? '#FAAF41'
                                                                        : '#f46a6a',
                                                            }}>
                                                            {item?.percentage.toLocaleString()}%
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col md={3} lg={3} xl={3}>
                                                    <div className="word-percent">
                                                        {this.props.type === 'year' && (
                                                            <span>{t('common.lastYearAgo')}</span>
                                                        )}
                                                        {this.props.type === 'quarter' && (
                                                            <span>{t('common.lastQuarterAgo')}</span>
                                                        )}
                                                        {this.props.type === 'halfYear' && (
                                                            <span>{t('common.lastHalfYearAgo')}</span>
                                                        )}
                                                        {this.props.type === 'month' && (
                                                            <span>{t('common.lastMonthAgo')}</span>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </CardBody>
                </AnimateHeight>
            </Card>
        )
    }
}

export default withTranslation()(CardResultCompare)
