import React from 'react'
import {Row, Col, Card, CardBody} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './style.scss'

const MapSkeleton = ({}) => {
    const loadCards = Array(11).fill(1)
    return (
        <Col>
            <Card
                style={{
                    borderRadius: 8,
                    boxShadow: '0px 12px 24px rgb(240, 240, 240)',
                }}>
                <CardBody>
                    <Row>
                        <Col lg="6">
                            <div className="card-skeleton ">
                                <Skeleton height={600} />
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="card-skeleton ">
                                <div className="d-flex align-items-center">
                                    <Skeleton width={120} height={16} />
                                    <Skeleton width={120} height={16} style={{marginLeft: 15}} />
                                </div>
                                <div>
                                    <Skeleton height={16} width={160} style={{marginTop: 20}} />
                                    <Skeleton height={1} style={{marginBottom: 10, marginTop: 18}} />
                                    {loadCards.map((card, i) => (
                                        <div key={i}>
                                            <div className="row">
                                                <div className="col-4">
                                                    <Skeleton />
                                                </div>
                                                <div className="col-8">
                                                    <Skeleton />
                                                </div>
                                            </div>
                                            <Skeleton height={1} style={{marginBottom: 10}} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

export default MapSkeleton
