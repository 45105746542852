import React, {useState, useEffect} from 'react'
import {Row, Col, Card, CardBody, Table} from 'reactstrap'
import ReactApexChart from 'react-apexcharts'
import {withTranslation} from 'react-i18next'

import FlightHistorySkeleton from '../../CardSkeleton/FlightHistorySkeleton'
import {useRecoilState, useRecoilValue} from 'recoil'
import {filterUserInformationState, loadingUserInformationState} from 'store/atoms/user-information'
import {getDroneFlightHistory} from 'services/skc/user-information'

const HistoryFlightTotal = props => {
    const {t, i18n} = props

    const filterUserInformation = useRecoilValue(filterUserInformationState)
    const [loading, setLoading] = useRecoilState(loadingUserInformationState)

    const [data, setData] = useState({})
    const [loadingSkeleton, setLoadingSkeleton] = useState(false)

    const [language, setLanguage] = useState(i18n.language)

    useEffect(() => {
        fetchFlightHistory(filterUserInformation)
    }, [])

    useEffect(() => {
        if (loading?.drone_flight_history) {
            fetchFlightHistory(filterUserInformation)
            setLoading(cur => ({...cur, drone_flight_history: false}))
        }
    }, [loading?.drone_flight_history])

    useEffect(() => {
        if (i18n.language !== language) {
            fetchFlightHistory(filterUserInformation)

            setLanguage(i18n.language)
        }
    }, [i18n.language])

    const fetchFlightHistory = async params => {
        try {
            setLoadingSkeleton(true)
            const response = await getDroneFlightHistory(params)

            if (response?.status_code === 200 && response?.results) {
                setData(response?.results)
            }
        } catch (error) {
            setData({})
        } finally {
            setLoadingSkeleton(false)
        }
    }

    const twoDecimal = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})

    return (
        <>
            {loadingSkeleton ? (
                <FlightHistorySkeleton />
            ) : (
                <Card
                    style={{
                        borderRadius: 8,
                    }}>
                    <CardBody>
                        <div>
                            <div className=" me-3">
                                <h4
                                    style={{
                                        fontSize: 20,
                                        fontWeight: 600,
                                        paddingLeft: 20,
                                        paddingTop: 10,
                                    }}>
                                    {t('userInformation.totalFlightHistory')}
                                </h4>
                            </div>

                            <div>
                                <p
                                    className="mb-4 text-muted"
                                    style={{
                                        fontSize: 15,
                                        fontWeight: 400,
                                        color: '#495057',
                                        paddingLeft: 20,
                                    }}>
                                    {t('userInformation.dronesFlightHistory')}
                                </p>
                            </div>
                        </div>
                        <Row>
                            <Col lg="3">
                                <div className="d-flex">
                                    <div className="me-2">
                                        <h5
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                                color: '#000000',
                                                paddingLeft: 20,
                                                fontFamily: 'Kanit',
                                            }}>
                                            {t('userInformation.flightTimeRanking')}
                                        </h5>
                                    </div>
                                </div>
                                <div
                                    className="type-drone"
                                    style={{
                                        paddingTop: 20,
                                        paddingLeft: 20,
                                    }}>
                                    <Table className="table-nowrap">
                                        <tbody>
                                            {data?.rating?.length > 0 ? (
                                                data?.rating?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 500,
                                                                fontFamily: 'Inter',
                                                                color: '#000',
                                                            }}>
                                                            {index + 1}.
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 500,
                                                                fontFamily: 'Inter',
                                                                color: '#000',
                                                            }}>
                                                            {item.days.toLocaleString('en-US', {
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 2,
                                                            })}
                                                        </td>
                                                        <td>
                                                            <span
                                                                className="ms-2 text-truncate"
                                                                style={{
                                                                    fontSize: 14,
                                                                    fontWeight: 500,
                                                                    fontFamily: 'Inter',
                                                                    color: '#000',
                                                                }}>
                                                                {Intl.NumberFormat('en-US').format(
                                                                    item.amounthour.toLocaleString('en-US', {
                                                                        minimumFractionDigits: 0,
                                                                        maximumFractionDigits: 2,
                                                                    }),
                                                                )}
                                                            </span>
                                                            <span
                                                                className="ms-2 text-truncate"
                                                                style={{
                                                                    fontSize: 14,
                                                                    fontWeight: 300,
                                                                    fontFamily: 'Kanit',
                                                                    color: '#495057',
                                                                }}>
                                                                {t('common.hourUnit')}
                                                            </span>
                                                        </td>
                                                        <td
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 500,
                                                                fontFamily: 'Inter',
                                                                color: '#000',
                                                            }}>
                                                            {item.time}
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            fontFamily: 'Kanit',
                                                            color: 'gray',
                                                            textAlign: 'center',
                                                        }}
                                                        colSpan={4}>
                                                        {t('common.noData')}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>

                            <Col lg="9">
                                {data?.series?.length > 0 ? (
                                    <div
                                        id="chart"
                                        style={{
                                            paddingLeft: 20,
                                            paddingRight: 30,
                                            marginTop: -40,
                                        }}>
                                        <ReactApexChart
                                            options={{
                                                chart: {
                                                    height: 350,
                                                    type: 'heatmap',
                                                    toolbar: {
                                                        show: false,
                                                    },
                                                },
                                                legend: {
                                                    show: false,
                                                    position: 'top',
                                                    horizontalAlign: 'center',
                                                    fontSize: 17,
                                                    fontWeight: 500,
                                                },
                                                plotOptions: {
                                                    heatmap: {
                                                        shadeIntensity: 0.5,
                                                        radius: 0,

                                                        colorScale: {
                                                            ranges: data?.ranges,
                                                        },
                                                    },
                                                },
                                                dataLabels: {
                                                    enabled: false,
                                                },
                                                yaxis: {
                                                    title: {
                                                        text: t('common.day'),

                                                        offsetX: 12,
                                                        offsetY: 0,
                                                        style: {
                                                            fontSize: '12px',
                                                        },
                                                    },
                                                },
                                                tooltip: {
                                                    y: {
                                                        formatter: function (val, index) {
                                                            return twoDecimal.format(val) + ` ${t('common.hourUnit')}`
                                                        },
                                                    },
                                                },
                                                xaxis: {
                                                    title: {
                                                        text: t('common.time'),
                                                        offsetX: 0,
                                                        offsetY: 0,
                                                        style: {
                                                            fontSize: '12px',
                                                            color: '#495057',
                                                        },
                                                    },
                                                },
                                            }}
                                            series={data?.series}
                                            type="heatmap"
                                            height={450}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            width: '100%',
                                            height: 330,
                                            paddingLeft: 10,
                                            paddingRight: 30,
                                            marginTop: 30,
                                            textAlign: 'center',
                                            paddingTop: 100,
                                        }}>
                                        <p
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 500,
                                                fontFamily: 'Kanit',
                                                color: 'gray',
                                                textAlign: 'center',
                                                marginTop: 50,
                                            }}>
                                            {t('common.noData')}
                                        </p>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )}
        </>
    )
}

export default withTranslation()(HistoryFlightTotal)
