//api
export const ENDPOINT = {
    GET_FILTER_REGION: 'skc/filter/region',
    GET_FILTER_DRONE: 'skc/filter/drone',
    DASHBOARD_MAP: 'skc/drone/map/inalyze',
    PROVINCE: 'master/province',
    DISTRICT: 'master/district',
    FILTER_PROVINCE: 'skc/filter/province',
    FILTER_DISTRICT: 'skc/filter/district',
    DASHBOARD_CUMULATIVE: 'skc/header',
    DASHBOARD_OVERVIEW_DETAIL: 'skc/drone',
    DASHBOARD_OVERVIEW_AVERAGE: 'skc/drone/avg',
    DASHBOARD_DRONE_TYPE: 'skc/drone/model',
    DASHBOARD_WORKING_DRONE: 'skc/drone/amount',
    DASHBOARD_TOTAL_AREA: 'skc/v1.5/area',
    DASHBOARD_AVERAGE_FLIGHT: 'skc/v1.5/drone/fly',
    DASHBOARD_FLIGHT_HISTORY: 'skc/drone/flyhistory',
    INALYZE_MAP: 'skc/drone/map/inalyze',
    COMPARE_AREA_DATA: 'skc/drone/inalyze/v2/compare/area',
    COMPARE_AREA_GRAPH: 'skc/drone/inalyze/v2/compare/area/graph',
    COMPARE_TIME: 'skc/drone/inalyze/compare/time',
    DRONE_INFORMATION_SEARCH: 'skc/info/search/table',
    DRONE_INFORMATION_TABLE: 'skc/info/table',
    DRONE_INFORMATION_OVERALL: 'skc/info/overall',
    HARDWARE_ID: 'skc/info/hardware',
    DRONE_STATUS: 'flight/drone-status',
    DRONE_INFORMATION_USAGE_DETAIL: 'skc/info/usage',
    DRONE_INFORMATION_USAGE_FLIGHT_DURATION: 'skc/info/flytime',
    DRONE_INFORMATION_USAGE_WORKING_AREA: 'skc/info/flyarea',
    DRONE_INFORMATION_USAGE_SPEED_HEIGHT_DRONE: 'skc/drone/info/graph',
    DRONE_INFORMATION_USAGE_FLIGHT_HISTORY: 'skc/drone/flyhistory',
    DRONE_INFORMATION_USAGE_FLIGHT_LIST_TABLE: 'skc/info/flightlist',
    DRONE_INFORMATION_FLIGHT_HISTORY_DETAIL: 'skc/info/flightdetail',
    FLIGHT_HISTORY_PLAY: 'skc/flight/history',
    USER_INALYZE_WIDGET: 'skc/user/inalyze/header',
    USER_INALYZE_SCATTER_CUSTOMER: 'skc/user/inalyze/scatterplot',
    USER_INALYZE_BOX_PLOT: 'skc/user/inalyze/boxplot',
    USER_INFORMATION_SEARCH: 'skc/user/info/search',
    USER_HARDWARE_ID: 'skc/user/info/hardware',
    USER_INFORMATION_DETAIL: 'skc/user/info/user',
    USER_INFORMATION_DRONE: 'skc/user/info/drone',
    USER_INFORMATION_DRONE_STATUS: 'flight/drone-status',
    USER_INFORMATION_WORK_OVERVIEW: 'skc/user/info/summary',
    USER_INFORMATION_FLIGHT_TIME: 'skc/user/info/time',
    USER_INFORMATION_AREA: 'skc/user/info/area',
    USER_INFORMATION_LOCATION_AREA: 'skc/user/info/location/area',
    USER_INFORMATION_FLIGHT_HISTORY: 'skc/drone/flyhistory',
    USER_INFORMATION_DRONE_DETAIL: 'skc/user/info/summary',
    USER_INFORMATION_DRONE_FLIGHT_DURATION: 'skc/user/info/timegraph',
    USER_INFORMATION_DRONE_WORKING_AREA: 'skc/user/info/areagraph',
    USER_INFORMATION_SPEED_HEIGHT_DRONE: 'skc/user/info/graph',
    USER_INFORMATION_DRONE_TABLE_FLIGHT_HISTORY: 'skc/info/flightlist',
}
