const common = {
    selectDate: 'เลือกข้อมูล',
    week: 'สัปดาห์',
    month: 'เดือน',
    year: 'ปี',
    period: 'กำหนดเอง',
    country: 'ประเทศ',
    region: 'ภูมิภาค',
    all: 'ทั้งหมด',
    thai: 'ไทย',
    laos: 'ลาว',
    cambodia: 'กัมพูชา',
    ago: 'ที่แล้ว',
    provinceNotSpecified: 'ไม่ระบุจังหวัด',
    districtNotSpecified: 'ไม่ระบุอำเภอ',
    droneModel: 'รุ่นโดรน',
    searchInformation: 'ดูข้อมูล',
    lastYearAgo: 'จากปีที่แล้ว',
    lastMonthAgo: 'จากเดือนที่แล้ว',
    lastWeekAgo: 'จากสัปดาห์ที่แล้ว',
    lastQuarterAgo: 'จากไตรมาสที่แล้ว',
    lastHalfYearAgo: 'จากครึ่งปีที่แล้ว',
    displayOption: 'เลือกการแสดงผล',
    barChart: 'กราฟแท่ง',
    lineChart: 'กราฟเส้น',
    area: 'พื้นที่',
    areaNotSpecified: 'ไม่ระบุพื้นที่',
    province: 'จังหวัด',
    district: 'อำเภอ/เขต',
    dataAt: 'ข้อมูล ณ',
    day: 'วัน',
    time: 'เวลา',
    minuteUnit: 'น.',
    noData: 'ไม่พบข้อมูล',
    hourUnit: 'ชม.',
    pleaseSelect: 'กรุณาเลือก',
    droneUnit: 'ลำ',
    raiUnit: 'ไร่',
    flightTimeUnit: 'เที่ยว',
    hourUnitDroneUnit: 'ชม./ลำ',
    raiUnitDroneUnit: 'ไร่/ลำ',
    hourUnitFlightUnit: 'ชม./เที่ยว',
    averageUnit: 'เฉลี่ย/ลำ',
    noData: 'ไม่พบข้อมูล',
    character: 'ตัวอักษร',
    maximum: 'จำนวนมากที่สุด',
    minimum: 'จำนวนน้อยที่สุด',
    quarter: 'ไตรมาส',
    halfYear: 'ครึ่งปี',
    selectDrone: 'เลือกโดรน',
    pleaseSelectDrone: 'กรุณาเลือกโดรน',
    clearData: 'เคลียข้อมูล',
    dayUnit: 'วัน',
    HourUnitDayUnit: 'ชั่วโมง/วัน',
    timeDroneUnit: 'เที่ยว/ลำ',
    literRaiUnit: 'ลิตร/ไร่',
    flightUnit: 'เที่ยวบิน',
    flightUnitDroneUnit: 'เที่ยวบิน/ลำ',
    msUnit: 'm/s',
    mUnit: 'm',
    janMonth: 'ม.ค.',
    febMonth: 'ก.พ.',
    marMonth: 'มี.ค.',
    aprMonth: 'เม.ย.',
    mayMonth: 'พ.ค.',
    junMonth: 'มิ.ย.',
    julMonth: 'ก.ค',
    augMonth: 'ส.ค.',
    sepMonth: 'ก.ย.',
    octMonth: 'ต.ค.',
    novMonth: 'พ.ย.',
    decMonth: 'ธ.ค.',
    hour: 'ชั่วโมง',
    literUnit: 'ลิตร',
    close: 'ปิด',
    position: 'ตำแหน่ง',
    raiUnitFlightUnit: 'ไร่/เที่ยว',
    literSemiUnit: 'ล.',
    literSemiUnitRaiUnit: 'ล./ไร่',
    kg: 'กก.',
    kgUnitRaiUnit: 'กก./ไร่',
    searchForDrones: 'ค้นหาโดรน',
    searchByPilotNameSnEmail: 'ค้นหาด้วยชื่อนักบิน, S/N, อีเมล',
    searchHistory: 'ประวัติการค้นหา',
    searchResults: 'ผลการค้นหา',
    noResultsFound: 'ไม่พบผลลัพธ์',
    minutes: 'นาที',
    search: 'การค้นหา',
    pleaseSelectStartDate: 'กรุณาเลือกวันที่เริ่มต้น',
    pleaseSelectEndDate: 'กรุณาเลือกวันที่สิ้นสุด',
    searchFlightLocation: 'ค้นหาสถานที่บิน',
    back: 'ย้อนกลับ',
    details: 'รายละเอียด',
    average: 'เฉลี่ย',
    notSpecified: 'ไม่ระบุ',
    kilogram: 'กิโลกรัม',
    jobYearUnit: 'งาน/ปี',
    jobMonthUnit: 'งาน/เดือน',
    jobWeekUnit: 'งาน/สัปดาห์',
    firstHalfYear: 'ครึ่งปีแรก (ม.ค - มิ.ย.)',
    secondHalfYear: 'ครึ่งปีหลัง (ก.ค - ธ.ค.)',
    data: 'ข้อมูล',
    pleaseSelectTimeRang: 'กรุณาเลือกช่วงเวลา',
    selectRegion: 'เลือกภูมิภาค',
    chooseProvince: 'เลือกจังหวัด',
    phoneNumber: 'เบอร์โทรศัพท์',
    email: 'อีเมล',
    address: 'ที่อยู่',
    averageUnit: 'เฉลี่ย',
    personUnit: 'คน',
    searchByPilotNameLastNameSnEmail: 'ค้นหาด้วยชื่อนักบิน, นามสกุล, S/N',
    total: 'จำนวนทั้งหมด',
    list: 'รายการ',
}

export default common
