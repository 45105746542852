const menu = {
    kInalyzeDashboard: 'K-iNalyze Dashboard',
    droneInalyze: 'Drone iNalyze',
    iNalyze: 'iNalyze',
    realTimeMonitoring: 'Real-time Monitoring',
    droneInformation: 'Drone Information',
    userInalyze: 'User iNalyze',
    userInformation: 'User Information',
}

export default menu
