import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {Row, Col} from 'reactstrap'
import './style.scss'

const OverviewAverageSkeleton = () => {
    return (
        <Row className="skeleton-overview-average">
            <Col lg={6}>
                <div className="div-skeleton-average-tab">
                    <Skeleton className="skeleton-tab" width={120} height={42} />
                    <Skeleton className="skeleton-tab" width={120} height={42} />
                    <Skeleton className="skeleton-tab" width={120} height={42} />
                </div>
            </Col>
            <Col lg={12}>
                <div className="div-table">
                    <Skeleton className="skeleton-title" width={170} height={19} />
                    <Skeleton className="skeleton-tab" width={'100%'} height={138} />
                </div>
            </Col>
        </Row>
    )
}

export default OverviewAverageSkeleton
