import React, {useState, useEffect, useRef} from 'react'
import {withTranslation} from 'react-i18next'
import {Card, CardBody, Col, Row, Button} from 'reactstrap'
import {GoogleMap, useLoadScript, Circle, useGoogleMap, OverlayView} from '@react-google-maps/api'
import './index.scss'

import CardDetail from '../CardDetail'

import iconZoomIn from '../../../../assets/images/icon/icon-zoom-in-map.png'
import iconZoomOut from '../../../../assets/images/icon/icon-zoom-out-map.png'
import iconCenter from '../../../../assets/images/icon/icon-center-map.png'

import SkeletonCardDroneMap from 'components/DroneiNalyze/Skeleton-DroneInformation/Overall/SkeletonCardDroneMap'
import SkeletonCardDroneDetail from 'components/DroneiNalyze/Skeleton-DroneInformation/Overall/SkeletonCardDroneDetail'

const CardDrone = props => {
    const {t, i18n, droneLatest, overAllData, loadingDroneLatest, loadingOverAll} = props

    const [dataDetail, setDataDetail] = useState([])
    const [dataSpraying, setDataSpraying] = useState([])
    const [dataSowing, setDataSowing] = useState([])
    const [language, setLanguage] = useState(i18n.language)

    useEffect(() => {
        if (overAllData) {
            setDataInCardDetail(overAllData)
        }
    }, [overAllData])

    useEffect(() => {
        if (i18n.language !== language) {
            setDataInCardDetail(overAllData)
            setLanguage(i18n.language)
        }
    }, [i18n.language])

    const setDataInCardDetail = data => {
        let nf = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        let nn = new Intl.NumberFormat('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})

        const newCardDetail = [
            {
                title: t('droneInformation.totalNumberOfFlights'),
                total: nn.format(data?.total_flight || 0),
                unit: t('common.flightTimeUnit'),
            },
            {
                title: t('droneInformation.totalFlightDuration'),
                total: nf.format(data?.total_fly_time || 0),
                unit: t('common.hourUnit'),
            },
            {
                title: t('droneInformation.averageFlightDuration'),
                total: nf.format(data?.average_fly_time || 0),
                unit: t('common.hourUnitFlightUnit'),
            },
            {
                title: t('droneInformation.totalWorkingArea'),
                total: nf.format(data?.total_area || 0),
                unit: t('common.raiUnit'),
            },
            {
                title: t('droneInformation.averageWorkingArea'),
                total: nf.format(data?.average_area || 0),
                unit: t('common.raiUnitFlightUnit'),
            },
        ]

        const newDataSpraying = [
            {
                title: t('droneInformation.numberOfSpraying'),
                total: nn.format(data?.total_spray_trip || 0),
                unit: t('common.flightTimeUnit'),
            },
            {
                title: t('droneInformation.totalSprayingArea'),
                total: nn.format(data?.total_spray_area || 0),
                unit: t('common.raiUnit'),
            },
            {
                title: t('droneInformation.totalSprayingQuantity'),
                total: nn.format(data?.total_spray_usage || 0),
                unit: t('common.literSemiUnit'),
            },
            {
                title: t('droneInformation.averageSprayingQuantity'),
                total: nn.format(data?.total_use_spray_per_rai || 0),
                unit: t('common.literSemiUnitRaiUnit'),
            },
        ]

        const newDataSowing = [
            {
                title: t('droneInformation.numberOfSpreading'),
                total: nn.format(data?.total_spread_trip || 0),
                unit: t('common.flightTimeUnit'),
            },
            {
                title: t('droneInformation.totalSpreadingArea'),
                total: nn.format(data?.total_spread_area || 0),
                unit: t('common.raiUnit'),
            },
            {
                title: t('droneInformation.totalSpreadingQuantity'),
                total: nn.format(data?.total_spread_usage || 0),
                unit: t('common.kg'),
            },
            {
                title: t('droneInformation.averageSpreadingQuantity'),
                total: nn.format(data?.total_use_spread_per_rai || 0),
                unit: t('common.kgUnitRaiUnit'),
            },
        ]

        setDataDetail(newCardDetail)
        setDataSpraying(newDataSpraying)
        setDataSowing(newDataSowing)
    }

    return (
        <>
            <Col xl={8}>
                {' '}
                <Card>
                    <CardBody>
                        {loadingDroneLatest ? (
                            <SkeletonCardDroneMap />
                        ) : (
                            <>
                                {' '}
                                <div className="d-flex flex-wrap" style={{display: 'flex', alignItems: 'center'}}>
                                    <h2
                                        className="card-title me-2"
                                        style={{
                                            fontSize: 20,
                                            fontWeight: 600,
                                            paddingTop: 10,
                                        }}>
                                        {t('droneInformation.latestDroneLocation')}

                                        <span></span>
                                    </h2>

                                    {droneLatest?.position_latest ? (
                                        <span style={{padding: 0, margin: 0, marginTop: '5px'}}>
                                            {`(  ${t('droneInformation.latestDroneLocation')}  )`}
                                        </span>
                                    ) : (
                                        <span style={{padding: 0, margin: 0, marginTop: '5px'}}>
                                            {`(  ${t('droneInformation.latestDroneLocationNotFound')}  )`}
                                        </span>
                                    )}
                                </div>
                                <hr className="mb-4" />
                                {droneLatest?.position_latest ? (
                                    <MapComponent
                                        center={{
                                            lat: droneLatest?.position_latest?.position?.latitude,
                                            lng: droneLatest?.position_latest?.position?.longitude,
                                        }}
                                        droneLatest={droneLatest}
                                        droneName={overAllData?.drone_name}
                                    />
                                ) : (
                                    <div>
                                        <MapComponent center={{lat: 14.13644128900171, lng: 100.60026904044614}} />
                                    </div>
                                )}
                            </>
                        )}
                        {loadingOverAll ? (
                            <SkeletonCardDroneDetail />
                        ) : (
                            <div style={{marginTop: '40px', borderTop: '1px solid #EFF2F7'}}>
                                <Row style={{margin: 0}}>
                                    <Col lg={12}>
                                        <p
                                            style={{
                                                fontFamily: 'Kanit',
                                                fontWeight: 600,
                                                fontSize: '18px',
                                                marginTop: '16px',
                                            }}>
                                            {t('droneInformation.details')}
                                        </p>
                                    </Col>

                                    {dataDetail?.length > 0 &&
                                        dataDetail?.map((item, index) => (
                                            <Col key={`${item}-${index}`} lg={3} style={{marginBottom: '16px'}}>
                                                <CardDetail data={item} colorCard="#f5f5f5" />
                                            </Col>
                                        ))}

                                    <Col lg={12}>
                                        <p
                                            style={{
                                                fontFamily: 'Kanit',
                                                fontWeight: 600,
                                                fontSize: '18px',
                                                marginTop: '16px',
                                            }}>
                                            {t('droneInformation.spraying')}
                                        </p>
                                    </Col>

                                    {dataSpraying?.length > 0 &&
                                        dataSpraying?.map((item, index) => (
                                            <Col key={`${item}-${index}`} lg={3} style={{marginBottom: '16px'}}>
                                                <CardDetail data={item} colorCard="#f5f5f5" />
                                            </Col>
                                        ))}

                                    <Col lg={12}>
                                        <p
                                            style={{
                                                fontFamily: 'Kanit',
                                                fontWeight: 600,
                                                fontSize: '18px',
                                                marginTop: '16px',
                                            }}>
                                            {t('droneInformation.spreading')}
                                        </p>
                                    </Col>

                                    {dataSowing?.length > 0 &&
                                        dataSowing?.map((item, index) => (
                                            <Col key={`${item}-${index}`} lg={3} style={{marginBottom: '16px'}}>
                                                <CardDetail data={item} colorCard="#f5f5f5" />
                                            </Col>
                                        ))}
                                </Row>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default withTranslation()(CardDrone)

function MapComponent({center, droneLatest, droneName}) {
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: 'AIzaSyB5RVDr2EpQhSt1YsMFCu7Xi2qlVz3qP2o',
    })
    const [centerMap, setCenterMap] = useState(center)
    const [dataDrone, setDataDrone] = useState(droneLatest)
    const [zoom, setZoom] = useState(18)
    const [isFullscreen, setIsFullscreen] = useState(false)

    let mapOptions = {
        mapTypeId: 'satellite',
        disableDefaultUI: true,
        fullscreenControl: true,
        zoom: zoom,
        center: centerMap,
    }

    const circleOptions = {
        strokeOpacity: 0.8,
        strokeWeight: 0,
        fillColor: '#00FFF0',
        fillOpacity: 0.35,
    }

    const containerMapStyle = {
        width: '100%',
        height: '400px',
    }

    if (loadError) {
        console.error('Failed to load Google Maps library:', loadError)
    }

    const circles = [
        {
            center: {
                lat: dataDrone?.position_latest?.position?.latitude,
                lng: dataDrone?.position_latest?.position?.longitude,
            },
            radius: 20,
        }, // Circle 1
        {
            center: {
                lat: dataDrone?.position_latest?.position?.latitude,
                lng: dataDrone?.position_latest?.position?.longitude,
            },
            radius: 10,
        }, // Circle 2
    ]

    const clickCenterMap = () => {
        setCenterMap(center)
        setZoom(18)
    }

    const clearCenterMap = () => {
        // console.log("fff :>> ");
        setCenterMap(null)
        mapOptions.center = null
    }

    const handleZoomIn = () => {
        // console.log("zoom-in :>> ", zoom);
        if (zoom < 20) {
            setZoom(cur => cur + 1)
            // clearCenterMap();
        }
    }

    const handleZoomOut = () => {
        // console.log("map :>> ", mapRef.current.state);
        // console.log("zoom-out :>> ", zoom);
        if (zoom > 0) {
            setZoom(cur => cur - 1)
            // clearCenterMap();
        }
    }

    useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullScreen)

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreen)
        }
    }, [])

    useEffect(() => {
        setCenterMap(center)
    }, [center])

    useEffect(() => {
        setDataDrone(droneLatest)
    }, [droneLatest])

    const handleFullScreen = () => {
        setIsFullscreen(document.fullscreenElement !== null)
    }
    const handleDragEnd = map => {
        const lat = map.center.lat()
        const lng = map.center.lng()

        setCenterMap({lat, lng})
    }

    const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
        return {
            x: offsetWidth + labelAnchor.x,
            y: offsetHeight + labelAnchor.y,
        }
    }

    return (
        <div>
            {isLoaded ? (
                <GoogleMap
                    id="map"
                    options={mapOptions}
                    mapContainerStyle={containerMapStyle}
                    center={centerMap}
                    zoom={zoom}
                    mapContainerClassName="map-container"
                    onLoad={map => {
                        map.addListener('dragend', () => handleDragEnd(map))
                    }}>
                    {isFullscreen ? (
                        <div className="custom-fullscreen-icon" onClick={handleFullScreen}>
                            <ButtonCenterFullscreen clickCenterMap={clickCenterMap} position="RIGHT_BOTTOM" />
                            <ButtonZoomFullscreen
                                position="RIGHT_BOTTOM"
                                handleZoomOut={handleZoomOut}
                                handleZoomIn={handleZoomIn}
                            />
                        </div>
                    ) : (
                        <>
                            <div className="custom-zoom-controls">
                                <Button
                                    disabled={zoom === 20 ? true : false}
                                    className="btn-zoom-in"
                                    onClick={() => handleZoomIn()}>
                                    <img src={iconZoomIn} />
                                </Button>
                                <Button
                                    disabled={zoom === 0 ? true : false}
                                    className="btn-zoom-out"
                                    onClick={() => handleZoomOut()}>
                                    <img src={iconZoomOut} />
                                </Button>
                            </div>
                            <Button className="btn-map-center" onClick={() => clickCenterMap()}>
                                <img src={iconCenter} />
                            </Button>
                        </>
                    )}
                    {dataDrone ? (
                        <>
                            {circles.map((item, index) => (
                                <React.Fragment key={index}>
                                    <Circle center={item.center} radius={item.radius} options={circleOptions} />
                                </React.Fragment>
                            ))}
                            <OverlayView
                                position={center}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                getPixelPositionOffset={(x, y) =>
                                    getPixelPositionOffset(x, y, {x: -(x / 0.65), y: -(y / 0.36)})
                                }>
                                <div
                                    style={{
                                        fontFamily: 'Inter',
                                        fontSize: 13,
                                        fontWeight: 500,
                                        background: `rgba(0, 0, 0, 0.85)`,
                                        color: `white`,
                                        padding: 10,
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                        borderRadius: 4,
                                        width: 'max-content',
                                        // marginTop: -70,
                                    }}>
                                    {droneName || '-'}
                                </div>
                            </OverlayView>
                        </>
                    ) : (
                        <></>
                    )}
                </GoogleMap>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    )
}

const ButtonCenterFullscreen = props => {
    const map = useGoogleMap()
    const ref = useRef()
    useEffect(() => {
        if (map && ref) {
            map.controls[window.google.maps.ControlPosition[props.position]].push(ref.current)
        }
        // google.maps.ControlPosition.RIGHT_BOTTOM;
    }, [map, ref])
    return (
        <div ref={ref} style={{height: '100%', top: '600px'}}>
            <Button className="btn-map-center" onClick={() => props.clickCenterMap()}>
                <img src={iconCenter} />
            </Button>
        </div>
    )
}

const ButtonZoomFullscreen = props => {
    const map = useGoogleMap()
    const ref = useRef()
    useEffect(() => {
        if (map && ref) {
            map.controls[window.google.maps.ControlPosition[props.position]].push(ref.current)
        }
        // google.maps.ControlPosition.RIGHT_BOTTOM;
    }, [map, ref])
    return (
        <div className="custom-zoom-controls" ref={ref} style={{height: '400px', top: '900px'}}>
            <Button className="btn-zoom-in" onClick={() => props.handleZoomIn()}>
                <img src={iconZoomIn} />
            </Button>
            <Button className="btn-zoom-out" onClick={() => props.handleZoomOut()}>
                <img src={iconZoomOut} />
            </Button>
        </div>
    )
}
