export const GET_DASHBOARD_HEADER = "skc/header";
export const GET_DASHBOARD_DRONE = "skc/drone";
export const GET_DASHBOARD_DRONEAVERAGE = "skc/drone/avg";
export const GET_DASHBOARD_DRONEMODEL = "skc/drone/model";
export const GET_DASHBOARD_DRONEAMOUNT = "skc/drone/amount";
export const GET_DASHBOARD_DRONEFLYAVERAGE = "skc/drone/fly";
export const GET_DASHBOARD_DRONEUSERGRAPH = "skc/user";
export const GET_DASHBOARD_TOTALAREA = "skc/area";
export const GET_INCOME_EXPENSE = "skc/profit";
export const GET_JOB_GRAPH = "skc/jobgraph";
export const GET_FLIGHT_HISTORY = "skc/drone/flyhistory";
export const GET_WORKING_HOURS = "skc/periodworkingtime";
export const GET_JOB = "skc/job";
export const GET_DRONE_MAP = "skc/drone/map";
export const GET_DRONE_PROVINCE = "skc/drone/province";
export const GET_FILTER_DRONE = "skc/filter/drone";
export const GET_FILTER_REGION = "skc/filter/region";
export const GET_USER_PROVINCE = "skc/user/province";
export const GET_PROVINCE = "master/province";
export const GET_DISTRICT = "master/district";
export const GET_USER_MAP = "skc/user/map";
export const GET_DRONE_FLY_AVERAGE = "skc/v1.5/drone/fly";
export const GET_NEW_TOTAL_AREA = "skc/v1.5/area";
export const GET_FILTER_PROVINCE = "skc/filter/province";
export const GET_FILTER_DISTRICT = "skc/filter/district";
