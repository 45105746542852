import {Component, useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import {withTranslation} from 'react-i18next'
import {Row, Col} from 'reactstrap'

const TotalArea = props => {
    const {series, labels, searchFilter, t} = props

    const [seriesMonth, setSeriesMonth] = useState([])
    const [labelsMonth, setLabelsMonth] = useState([])
    const [activeTab, setActiveTab] = useState(1)
    const [countTab, setCountTab] = useState(0)
    const [isClickSlideTap, setIsClickSlideTap] = useState(false)

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(Number(tab))
        }
    }

    useEffect(() => {
        if (searchFilter.type === 'month') {
            setChartTypeMonth()
            setIsClickSlideTap(false)
        }
    }, [searchFilter, labels])

    const setChartTypeMonth = () => {
        //พื้นที่ทำงาน
        const dataWorkingArea = []
        //พื้นที่ทั้งหมด
        const dataTotalArea = []
        // ค่าแสดง label
        const newLabel = []

        if ((isClickSlideTap === false || activeTab === 1) && searchFilter.type === 'month') {
            for (let i = 0; i < 10; i++) {
                dataWorkingArea.push(series[0].data[i])
                dataTotalArea.push(series[1].data[i])
                newLabel.push(labels[i])
            }

            const seriesMonth = [
                {
                    name: series[0]?.name,
                    type: series[0]?.type,
                    data: dataWorkingArea,
                },
                {
                    name: series[1]?.name,
                    type: series[1]?.type,
                    data: dataTotalArea,
                },
            ]
            setSeriesMonth(seriesMonth)
            setLabelsMonth(newLabel)
            setCountTab(0)
        }
        if (isClickSlideTap === true && activeTab !== 1) {
            if (activeTab === 2) {
                for (let i = 0; i < series[0].data.length; i++) {
                    if (dataWorkingArea.length < 10 && i > 9) {
                        dataWorkingArea.push(series[0].data[i])
                        dataTotalArea.push(series[1].data[i])
                        newLabel.push(labels[i])
                    }
                }
            } else {
                for (let i = 0; i < series[0].data.length; i++) {
                    if (dataWorkingArea.length <= 10 && i > 19) {
                        dataWorkingArea.push(series[0].data[i])
                        dataTotalArea.push(series[1].data[i])
                        newLabel.push(labels[i])
                    }
                }
            }
            const seriesMonth = [
                {
                    name: series[0]?.name,
                    type: series[0]?.type,
                    data: dataWorkingArea,
                },
                {
                    name: series[1]?.name,
                    type: series[1]?.type,
                    data: dataTotalArea,
                },
            ]

            setSeriesMonth(seriesMonth)
            setLabelsMonth(newLabel)
        }
    }

    const handleSlideLeft = () => {
        //พื้นที่ทำงาน
        const dataWorkingArea = []
        //พื้นที่ทั้งหมด
        const dataTotalArea = []
        // ค่าแสดง label
        const newLabel = []

        //ค่า set Series ใหม่
        const newSeries = []

        for (let i = 0; i < series[0].data.length; i++) {
            if (countTab === 1 && dataWorkingArea.length < 10 && i > 9) {
                dataWorkingArea.push(series[0].data[i])
                dataTotalArea.push(series[1].data[i])
                newLabel.push(labels[i])
            }
            if (countTab === 0 && dataWorkingArea.length < 10) {
                dataWorkingArea.push(series[0].data[i])
                dataTotalArea.push(series[1].data[i])
                newLabel.push(labels[i])
            }
        }

        newSeries.push(
            {
                name: series[0]?.name,
                type: series[0]?.type,
                data: dataWorkingArea,
            },
            {
                name: series[1]?.name,
                type: series[1]?.type,
                data: dataTotalArea,
            },
        )

        setSeriesMonth(newSeries)
        setIsClickSlideTap(true)
        setCountTab(countTab === 0 ? 0 : countTab - 1)
        setLabelsMonth(newLabel)
    }

    const handleSlideRight = () => {
        //พื้นที่ทำงาน
        const dataWorkingArea = []
        //พื้นที่ทั้งหมด
        const dataTotalArea = []
        // ค่าแสดง label
        const newLabel = []

        //ค่า set Series ใหม่
        const newSeries = []

        for (let i = 0; i < series[0].data.length; i++) {
            if (countTab === 0 && dataWorkingArea.length < 10 && i > 9) {
                dataWorkingArea.push(series[0].data[i])
                dataTotalArea.push(series[1].data[i])
                newLabel.push(labels[i])
            }

            if (countTab === 1 && i > 19) {
                dataWorkingArea.push(series[0].data[i])
                dataTotalArea.push(series[1].data[i])
                newLabel.push(labels[i])
            }
        }

        newSeries.push(
            {
                name: series[0]?.name,
                type: series[0]?.type,
                data: dataWorkingArea,
            },
            {
                name: series[1]?.name,
                type: series[1]?.type,
                data: dataTotalArea,
            },
        )

        setSeriesMonth(newSeries)
        setIsClickSlideTap(true)
        setCountTab(countTab === 1 ? 1 : countTab + 1)
        setLabelsMonth(newLabel)
    }

    return (
        <Row style={{margin: 0, padding: 0}}>
            <Col xl={12}>
                <p
                    style={{
                        fontFamily: 'Kanit',
                        fontWeight: 600,
                        fontSize: '20px',
                        color: '#000000',
                        margin: 0,
                    }}>
                    {t('dashboard.summaryOfTotalAreas')}
                </p>
            </Col>

            <Col xl={12} style={{marginTop: '8px'}}>
                <p
                    style={{
                        fontSize: 15,
                        fontWeight: 400,
                        color: '#495057',
                    }}>
                    {t('dashboard.workingAreaFromDrones')}
                </p>
            </Col>
            <Col xl={12}>
                <div
                    id="chart"
                    style={{
                        width: '100%',
                        height: 350,
                        marginTop: 30,
                    }}>
                    <ReactApexChart
                        options={{
                            chart: {
                                height: 350,
                                type: 'line',
                                toolbar: {
                                    show: false,
                                },
                                zoom: {
                                    enabled: false,
                                },
                                animations: {
                                    enabled: false,
                                },
                            },
                            stroke: {
                                curve: 'straight',
                                width: [0, 2],
                            },
                            markers: {
                                size: 3,
                                colors: '#FFF',
                                strokeColors: '#F46A6A',
                                strokeWidth: 2,
                                hover: {
                                    size: undefined,
                                    sizeOffset: 3,
                                },
                            },
                            colors: ['#556EE6', '#F46A6A'],
                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                    columnWidth: '35%',
                                    endingShape: 'rounded',
                                },
                            },

                            grid: {
                                show: true,
                                borderColor: '#E0E0E0',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: false,
                                    },
                                },
                                yaxis: {
                                    lines: {
                                        show: true,
                                    },
                                },
                            },
                            legend: {
                                show: true,
                                position: 'top',
                                horizontalAlign: 'right',
                                fontSize: 14,
                                fontWeight: 400,
                            },
                            dataLabels: {
                                enabled: false,
                            },
                            labels: [],
                            xaxis: {
                                categories: searchFilter.type == 'month' ? labelsMonth : labels,
                                labels: {
                                    style: {
                                        fontSize: '12px',
                                        fontFamily: 'Kanit, sans-serif',
                                        color: '#495057',
                                    },
                                },
                                crosshairs: {
                                    show: true,
                                    width: 'tickWidth',
                                    fill: {
                                        type: 'gradient',
                                        gradient: {
                                            colorFrom: '#495057',
                                            colorTo: '#495057',
                                            stops: [0, 100],
                                            opacityFrom: 0.4,
                                            opacityTo: 0.5,
                                        },
                                    },
                                },
                            },

                            yaxis: {
                                title: {
                                    text: `${t('dashboard.areaRai')}`,
                                    offsetX: 8,
                                    offsetY: 0,
                                    style: {
                                        fontSize: '12px',
                                    },
                                },
                                tickAmount: 5,
                                axisTicks: {
                                    show: true,
                                    borderType: 'solid',
                                    color: '#E0E0E0',
                                    height: 6,
                                },
                                axisBorder: {
                                    show: true,
                                    color: '#E0E0E0',
                                },
                                labels: {
                                    style: {
                                        fontSize: 12,
                                    },
                                    formatter: value => {
                                        return value.toLocaleString('en-US', {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 2,
                                        })
                                    },
                                },
                                crosshairs: {
                                    show: true,
                                    position: 'back',
                                    stroke: {
                                        color: '#b6b6b6',
                                        width: 1,
                                        dashArray: 0,
                                    },
                                },
                            },

                            tooltip: {
                                shared: true,
                                theme: 'dark',
                                intersect: false,

                                y: {
                                    formatter: function (y) {
                                        return y.toLocaleString('en-US', {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 2,
                                        })
                                    },
                                },
                            },
                        }}
                        series={searchFilter.type == 'month' ? seriesMonth : series}
                        type="line"
                        height={350}
                    />
                </div>
                {searchFilter.type === 'month' && labelsMonth && (
                    <div className="col align-items-center d-flex justify-content-between " style={{marginTop: '-5%'}}>
                        <span
                            className="btn"
                            style={{color: '#9AA2A9', marginLeft: '2%'}}
                            onClick={() => {
                                handleSlideLeft()
                                activeTab > 1 ? toggle(Number(activeTab) - 1) : toggle(1)
                            }}>
                            <i className="mdi mdi-triangle mdi-2px mdi-rotate-270"></i>
                        </span>
                        <span
                            className="btn"
                            style={{color: '#9AA2A9'}}
                            onClick={() => {
                                handleSlideRight()
                                activeTab < 3 ? toggle(Number(activeTab) + 1) : toggle(3)
                            }}>
                            <i className="mdi mdi-triangle mdi-2px mdi-rotate-90"></i>
                        </span>
                    </div>
                )}
            </Col>
        </Row>
    )
}

export default withTranslation()(TotalArea)
