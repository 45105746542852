const common = {
    selectDate: 'Select data',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    period: 'Customized',
    country: 'Country',
    region: 'Region',
    all: 'All',
    thai: 'Thai',
    laos: 'Laos',
    cambodia: 'Cambodia',
    ago: 'ago',
    provinceNotSpecified: 'Province not specified',
    districtNotSpecified: 'District not specified',
    droneModel: 'Drone Model',
    searchInformation: 'View Information',
    lastYearAgo: 'From last year',
    lastMonthAgo: 'From last month',
    lastWeekAgo: 'From last week',
    lastQuarterAgo: 'From quarter',
    lastHalfYearAgo: 'From half year',
    displayOption: 'Display options',
    barChart: 'Bar chart',
    lineChart: 'Line chart',
    area: 'Area',
    areaNotSpecified: 'Area Not Specified',
    province: 'Province',
    district: 'District',
    dataAt: 'Information at',
    day: 'Days',
    time: 'Time',
    minuteUnit: 'Min',
    noData: 'No data',
    hourUnit: 'Hours',
    pleaseSelect: 'Please select',
    droneUnit: 'Unit',
    raiUnit: 'Rai',
    flightTimeUnit: 'Times',
    hourUnitDroneUnit: 'Hr./Unit',
    raiUnitDroneUnit: 'Rai/Unit',
    hourUnitFlightUnit: 'Hr./Flight',
    averageUnit: 'Average/Unit',
    noData: 'No data',
    character: 'Character',
    maximum: 'Maximum Quantity',
    minimum: 'Minimum Quantity',
    quarter: 'Quarter',
    halfYear: 'Half year',
    selectDrone: 'Select drone',
    pleaseSelectDrone: 'Please select drone',
    clearData: 'Clear data',
    dayUnit: 'Days',
    HourUnitDayUnit: 'Hours/Days',
    timeDroneUnit: 'Time/Unit',
    literRaiUnit: 'Liter/Rai',
    flightUnit: 'Flight',
    flightUnitDroneUnit: 'Flight/Unit',
    msUnit: 'm/s',
    mUnit: 'm',
    janMonth: 'Jan',
    febMonth: 'Feb',
    marMonth: 'Mar',
    aprMonth: 'Apr',
    mayMonth: 'May',
    junMonth: 'Jun',
    julMonth: 'Jul',
    augMonth: 'Aug',
    sepMonth: 'Sep',
    octMonth: 'Oct',
    novMonth: 'Nov',
    decMonth: 'Dec',
    hour: 'Hours',
    literUnit: 'Liter',
    close: 'Close',
    position: 'Position',
    raiUnitFlightUnit: 'Rai/Flight',
    literSemiUnit: 'Liter',
    literSemiUnitRaiUnit: 'L/Rai',
    kg: 'Kg.',
    kgUnitRaiUnit: 'K.g./Rai',
    searchForDrones: 'Search for drones',
    searchByPilotNameSnEmail: 'Search by Pilot name, S/N, Email',
    searchHistory: 'Search history',
    searchResults: 'Search results',
    noResultsFound: 'No results found.',
    minutes: 'Minutes',
    search: 'Search',
    pleaseSelectStartDate: 'Please select start date',
    pleaseSelectEndDate: 'Please select end date',
    searchFlightLocation: 'Search  location',
    back: 'Back',
    details: 'Details',
    average: 'Average',
    notSpecified: 'Not Specified',
    kilogram: 'Kg.',
    jobYearUnit: 'Jobs/Year',
    jobMonthUnit: 'Jobs/Month',
    jobWeekUnit: 'Jobs/Week',
    firstHalfYear: 'First year (Jan - Jun)',
    secondHalfYear: 'Second year (Jul - Dec)',
    data: 'Data',
    pleaseSelectTimeRang: 'Please select a time range',
    selectRegion: 'Select Region',
    chooseProvince: 'Choose Province',
    phoneNumber: 'Phone number',
    email: 'Email',
    address: 'Address',
    averageUnit: 'average',
    personUnit: 'Person',
    searchByPilotNameLastNameSnEmail: 'Search by Pilot name, last name, S/N,',
    total: 'Total',
    list: 'lists',
}

export default common
