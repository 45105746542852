import React, {Component} from 'react'

import Leaflet from 'leaflet'
// import { Map, TileLayer, Marker, LayersControl,MapContainer, Circle, Popup } from "react-leaflet";
// import { MapContainer } from 'react-leaflet/MapContainer'
// import { TileLayer } from 'react-leaflet/TileLayer'

// import { MapContainer, TileLayer, Marker,LayersControl } from "react-leaflet";
// const { BaseLayer, Overlay } = LayersControl;
import MarkerClusterGroup from "react-leaflet-markercluster";
import {Redirect} from 'react-router-dom'

import io from 'socket.io-client'

import 'leaflet/dist/leaflet.css' // sass
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import config from 'config'
import MapDroneMonitoringSkeleton from '../Skeleton-iNalyze/Real-time-Monitoring/MapDroneMonitoringSkeleton'
import i18n from '../../../i18n'
import {withTranslation} from 'react-i18next'
// Leaflet.Icon.Default.imagePath = "../node_modules/leaflet";

// delete Leaflet.Icon.Default.prototype._getIconUrl;

// Leaflet.Icon.Default.mergeOptions({
//   iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
//   shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
//   iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
// });
var map = null
class MapDroneMonitoring extends Component {
    constructor(props) {
        super(props)
        this.state = {
            gotoDetail: null,
            lat: 15.87,
            long: 100.99,
            zoom: 9,
            showTabDrone: true,
            dataMap: [
                {name: 'คลองหลวง, ปทุมธานี ', latitude: 14.06, longitude: 100.65},
                {name: 'เมืองปทุม, ปทุมธานี ', latitude: 14.0, longitude: 100.66},
                {name: ' เมือง, นครนายก ', latitude: 14.12, longitude: 101.07},
                {name: 'ประทาย นครราชสีมา ', latitude: 14.88, longitude: 102.25},
                {name: 'ราชบุรี ', latitude: 13.45, longitude: 99.63},
                {name: 'นครสวรรค์ ', latitude: 15.69, longitude: 100.12},
            ],
            loadingSkeleton: true,
            isLoadingMapFirst: true,
            language: i18n.language,
        }
    }


    clickCheckDetailDrone = id => {
        this.setState({gotoDetail: id})
    }

    componentDidUpdate() {
        if (this.state.loadingSkeleton) {
            setTimeout(() => {
                this.setState({loadingSkeleton: false})
            }, 2000)
        }
        if (this.props.showTabDrone != this.state.showTabDrone) {
            this.mapInit()
            this.setState({showTabDrone: this.props.showTabDrone})
        }
        if (!this.state.loadingSkeleton && this.state.isLoadingMapFirst) {
            this.mapInit()
            this.setState({isLoadingMapFirst: false})
        }
        if (this.state.language !== i18n.language) {
            this.setState({language: i18n.language, loadingSkeleton: true})
        }
        if (!this.state.loadingSkeleton) {
            // this.mapInit()
        }
    }

    componentDidMount() {
        this.setState({loadingSkeleton: true})
        this.mapInit()
    }

    mapInit = () => {
        if (!this.state.loadingSkeleton) {
            if (map != null) map.remove()
            map =  L.map('map').setView([15.5374974, 103.6196411], 6.6)
            const vm = this
            L.tileLayer(`${config.google.MAP_VT}${i18n.language === 'en' ? '&hl=en' : ''}`, {
                maxZoom: 20,
                // zoom : 12
                attribution: '© OpenStreetMap',
            }).addTo(map)

            const icon = L.icon({
                iconUrl: `${config.s3.URL}drone-realtime.svg`,
                iconSize: [30, 30],
            })

            let markers = L.markerClusterGroup({
                showCoverageOnHover: false,
            })

            const socket = io(`${config.socket.URL}`)
            socket.on('AllRealtime', data => {
                markers.clearLayers()
                markers.remove()
                map.removeLayer(markers)

                data.forEach(e => {
                    if (e?.position != undefined) {
                        if (this.props.search != '' && this.props.filter.length > 0) {
                            if (e.hardwareId.toLowerCase().includes(this.props.search.toLowerCase()) && this.props.filter.includes(e.hardware_model)) {
                                const marker = L.marker(new L.LatLng(e.position.lat, e.position.lng), {
                                    icon,
                                    id: e.hardwareId,
                                })
                                markers.addLayer(marker)
                                marker.on('click', function (e) {
                                    // console.log(e.target.options.id)
                                    vm.setState({gotoDetail: e.target.options.id})
                                    // this.clickCheckDetailDrone(e.target.options.id)
                                })
                            }
                        } else if (this.props.search != '') {
                            if (e.hardwareId.toLowerCase().includes(this.props.search.toLowerCase())) {
                                const marker = L.marker(new L.LatLng(e.position.lat, e.position.lng), {
                                    icon,
                                    id: e.hardwareId,
                                })
                                markers.addLayer(marker)
                                marker.on('click', function (e) {
                                    // console.log(e.target.options.id)
                                    vm.setState({gotoDetail: e.target.options.id})
                                    // this.clickCheckDetailDrone(e.target.options.id)
                                })
                            }
                        } else if (this.props.filter.length > 0) {
                            if (this.props.filter.includes(e.hardware_model)) {
                                const marker = L.marker(new L.LatLng(e.position.lat, e.position.lng), {
                                    icon,
                                    id: e.hardwareId,
                                })
                                markers.addLayer(marker)
                                marker.on('click', function (e) {
                                    // console.log(e.target.options.id)
                                    vm.setState({gotoDetail: e.target.options.id})
                                    // this.clickCheckDetailDrone(e.target.options.id)
                                })
                            }
                        } else {
                            const marker = L.marker(new L.LatLng(e.position.lat, e.position.lng), {
                                icon,
                                id: e.hardwareId,
                            })
                            markers.addLayer(marker)
                            marker.on('click', function (e) {
                                // console.log(e.target.options.id)
                                vm.setState({gotoDetail: e.target.options.id})
                                // this.clickCheckDetailDrone(e.target.options.id)
                            })
                        }
                    }
                })
                map.addLayer(markers)
            })
        }
    }

    render() {
        return (
            <>
                {this.state.gotoDetail && (
                    <Redirect to={`/real-time-monitoring/${this.state.gotoDetail}/detail`} replace={true} />
                )}
                {this.state.loadingSkeleton ? <MapDroneMonitoringSkeleton /> : <div id="map"></div>}
            </>
        )
    }
}

export default withTranslation()(MapDroneMonitoring)
