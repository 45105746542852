import React, {useEffect, useState} from 'react'
import {Container, Row, Col, Button, Card, CardBody, CardTitle} from 'reactstrap'
import {useHistory} from 'react-router-dom'
import {withTranslation} from 'react-i18next'
import {Select, InputNumber} from 'antd'
const {Option, OptGroup} = Select

import iconEyeOpen from 'assets/images/icon/icon-eye-open.png'

import {droneInformationWorkType} from 'constants/lookup'
import {ColumnsDroneInformation} from 'constants/column-table'

import {getDroneInformationTable, getSearchDroneInformation} from 'services/skc/drone-information'
import {getFilterDrone} from 'services/skc/lookup'

import TableDroneInformation from 'components/DroneiNalyze/DroneInformation/TableDroneInformation'
import SkeletonTableDroneInformation from 'components/DroneiNalyze/Skeleton-DroneInformation/SkeletonTableDroneInformation'
import SkeletonSearchMachine from 'components/DroneiNalyze/Skeleton-DroneInformation/SkeletonSearchMachine'
import SkeletonSearchDroneAndHour from 'components/DroneiNalyze/Skeleton-DroneInformation/SkeletonSearchDroneAndHour'

import './index.scss'

const DroneInformation = props => {
    const {t, i18n} = props

    const history = useHistory()

    const [searchMachine, setSearchMachine] = useState()
    const [selectDrone, setSelectDrone] = useState('all')
    const [selectWorkType, setSelectWorkType] = useState('all')

    const [skeleton, setSkeleton] = useState(false)

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const [sortType, setSortType] = useState('ASC')
    const [sortColumn, setSortColumn] = useState('')

    const [lowestHour, setLowestHour] = useState('')
    const [highestHour, setHighestHour] = useState('')
    const [lutDrone, setLutDrone] = useState([])
    const [searchResults, setSearchResults] = useState({})
    const [rows, setRows] = useState([])

    const handleChangeSearch = (value, option) => {
        fetchSearch(value)
    }

    const handleChangeSelectDrone = value => {
        setSelectDrone(value)
    }

    useEffect(() => {
        fetchLutDrone()
        fetchSearch('  ')
        fetchDroneInformationTable({
            search: '',
            drone: '',
            work_type: '',
            lowest_hour: '',
            highest_hour: '',
            page: 1,
            pageSize: 10,
            sort_direction: '',
            sort: '',
        })
    }, [])

    const fetchLutDrone = async () => {
        try {
            const response = await getFilterDrone()

            if (response.status_code === 200 && response.results.length > 0) {
                setLutDrone(response.results)
            }
        } catch (error) {
            setLutDrone([])
        }
    }

    const fetchSearch = async params => {
        try {
            const response = await getSearchDroneInformation(params)
            if (response.results && response.status_code === 200) {
                setSearchResults(response.results)
            }
        } catch (error) {
            setSearchResults({})
        }
    }

    const handleChangeWorkType = value => {
        setSelectWorkType(value)
    }

    const handleChangeLowestHour = value => {
        if (value) {
            setLowestHour(value)
        } else {
            setLowestHour('')
        }
    }

    const handleChangeHighestHour = value => {
        if (value) {
            setHighestHour(value)
        } else {
            setHighestHour('')
        }
    }

    const submit = () => {
        let params = {
            search: '',
            drone: selectDrone === 'all' ? '' : selectDrone,
            work_type: selectWorkType === 'all' ? '' : selectWorkType,
            lowest_hour: lowestHour,
            highest_hour: highestHour,
            page: 1,
            pageSize: 10,
            sort_direction: '',
            sort: '',
        }

        setPage(1)
        setPageSize(10)
        fetchDroneInformationTable(params)
    }

    const onChangePagination = (page, pageSize) => {
        setPage(page)
        setPageSize(pageSize)

        const params = {
            search: '',
            drone: selectDrone === 'all' ? '' : selectDrone,
            work_type: selectWorkType === 'all' ? '' : selectWorkType,
            lowest_hour: lowestHour,
            highest_hour: highestHour,
            page: page,
            pageSize: pageSize,
            sort_direction: sortType,
            sort: sortColumn,
        }

        fetchDroneInformationTable(params)
    }

    const onClickTable = data => {
        history.push({
            pathname: '/drone-information/overall',
            search: `${data}`,
            id: data,
        })
    }

    const handleSelect = value => {
        setSearchMachine(value.value)

        const params = {
            search: value.value,
            page: 1,
            pageSize: 10,
            drone: '',
            work_type: '',
            lowest_hour: '',
            highest_hour: '',
            sort_direction: '',
            sort: '',
        }

        setSelectDrone('all')
        setSelectWorkType('all')
        setLowestHour('')
        setHighestHour('')

        fetchDroneInformationTable(params)
    }

    const fetchDroneInformationTable = async params => {
        try {
            setSkeleton(true)
            const response = await getDroneInformationTable(params)

            if (response.status_code === 200) {
                setRows(response?.results?.data)
                setTotal(response?.results?.total)
            }
        } catch (error) {
            setRows([])
            setTotal(0)
        } finally {
            setSkeleton(false)
        }
    }

    const onSort = (column, sortType) => {
        const params = {
            search: '',
            drone: selectDrone === 'all' ? '' : selectDrone,
            work_type: selectWorkType === 'all' ? '' : selectWorkType,
            lowest_hour: lowestHour,
            highest_hour: highestHour,
            page: page,
            pageSize: pageSize,
            sort: column,
            sort_direction: sortType,
        }

        setSortType(sortType)
        setSortColumn(column)
        fetchDroneInformationTable(params)
    }

    const onClearSearch = () => {
        setSearchMachine('')
        fetchDroneInformationTable({
            search: '',
            drone: '',
            work_type: '',
            lowest_hour: '',
            highest_hour: '',
            page: 1,
            pageSize: 10,
            sort_direction: '',
            sort: '',
        })

        setSortType('')
        setSortColumn('')
        setSelectDrone('all')
        setSelectWorkType('all')
        setLowestHour('')
        setHighestHour('')
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Row className="container-row-drone-information">
                    <Col xl={12} className="col-search">
                        {skeleton ? (
                            <SkeletonSearchMachine />
                        ) : (
                            <div className="box-search">
                                <p className="text-search">{t('droneInformation.searchForMachines')} :</p>
                                <Select
                                    className="search-drone-information"
                                    showSearch
                                    placeholder={t('common.searchByPilotNameLastNameSnEmail')}
                                    filterOption={false}
                                    optionLabelProp="label"
                                    onSelect={handleSelect}
                                    onSearch={handleChangeSearch}
                                    onClear={onClearSearch}
                                    labelInValue={true}
                                    allowClear
                                    value={searchMachine}>
                                    <OptGroup label={t('common.searchHistory')}>
                                        {searchResults?.recently_searched?.map(item => (
                                            <Option
                                                key={item.id}
                                                value={
                                                    item.uav_sn ||
                                                    item.first_name ||
                                                    item.last_name ||
                                                    item.email_address
                                                }
                                                label={`${item.uav_sn || '-'} ${item.first_name || '-'} ${
                                                    item.last_name || '-'
                                                }  ${item?.email_address || '-'}`}>
                                                <div className="row">
                                                    <div className="col-3">{item.uav_sn || '-'}</div>
                                                    <div className="col-4">
                                                        {item.first_name || '-'} {item.last_name || '-'}
                                                    </div>
                                                    <div className="col-5">{item.email_address || '-'}</div>
                                                </div>
                                            </Option>
                                        ))}
                                    </OptGroup>

                                    <OptGroup label={t('common.searchResults')} key={t('common.searchResults')}>
                                        {searchResults?.data?.length === 0 && (
                                            <Option key={0} value={''} label={``} disabled={true}>
                                                <div className="row justify-content-center text-center py-2">
                                                    <div className="col-12">{t('common.noResultsFound')}</div>
                                                </div>
                                            </Option>
                                        )}
                                        {searchResults?.data?.map(item => (
                                            <Option
                                                key={item.id}
                                                value={
                                                    item.uav_sn ||
                                                    item.first_name ||
                                                    item.last_name ||
                                                    item.email_address
                                                }
                                                label={`${item.uav_sn || '-'} ${item.first_name || '-'} ${
                                                    item.last_name || '-'
                                                }       ${item.email_address || '-'}`}>
                                                <div className="row">
                                                    <div className="col-3">{item.uav_sn || '-'}</div>
                                                    <div className="col-4">
                                                        {item.first_name || '-'} {item.last_name || '-'}
                                                    </div>
                                                    <div className="col-5">{item?.email_address || '-'}</div>
                                                </div>
                                            </Option>
                                        ))}
                                    </OptGroup>
                                </Select>
                            </div>
                        )}
                    </Col>
                    <Col xl={12} className="col-table">
                        <Card className="card-table">
                            <CardTitle>
                                {skeleton ? (
                                    <SkeletonSearchDroneAndHour />
                                ) : (
                                    <>
                                        <Row className="box-filter">
                                            <Col lg={4} className="col-drone">
                                                <p className="text">{t('common.droneModel')} : </p>

                                                <Select
                                                    disabled={searchMachine ? true : false}
                                                    className="select-drone"
                                                    value={selectDrone}
                                                    onChange={handleChangeSelectDrone}>
                                                    <Option value={'all'} label={t('common.all')}>
                                                        {t('common.all')}
                                                    </Option>
                                                    {lutDrone?.map((item, index) => (
                                                        <Option key={index} value={item.id} label={item.name}>
                                                            {item.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col className="col-time" lg={7}>
                                                <p className="text">{t('droneInformation.workingHours')} :</p>
                                                <div className="box-time">
                                                    <p className="section">{t('droneInformation.workType')} :</p>
                                                    <Select
                                                        disabled={searchMachine ? true : false}
                                                        className="select-type"
                                                        value={selectWorkType}
                                                        onChange={handleChangeWorkType}>
                                                        <Option value={'all'} label={t('common.all')}>
                                                            {t('common.all')}
                                                        </Option>
                                                        {droneInformationWorkType?.map(item => (
                                                            <Option
                                                                key={item.value}
                                                                value={item.value}
                                                                label={
                                                                    i18n.language === 'th'
                                                                        ? item.label_th
                                                                        : item.label_en
                                                                }>
                                                                {i18n.language === 'th' ? item.label_th : item.label_en}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                    <p className="section">{t('droneInformation.numberOfHours')} :</p>
                                                    <InputNumber
                                                        disabled={searchMachine ? true : false}
                                                        style={{width: i18n.language === 'en' ? 150 : 120}}
                                                        value={lowestHour}
                                                        onChange={handleChangeLowestHour}
                                                        className="input-hour"
                                                        placeholder={t('droneInformation.minimumHours')}
                                                    />
                                                    <p className="hyphen">-</p>
                                                    <InputNumber
                                                        disabled={searchMachine ? true : false}
                                                        style={{width: i18n.language === 'en' ? 150 : 120}}
                                                        value={highestHour}
                                                        onChange={handleChangeHighestHour}
                                                        className="input-hour"
                                                        placeholder={t('droneInformation.maximumHours')}
                                                    />
                                                </div>
                                                <button
                                                    disabled={searchMachine ? true : false}
                                                    onClick={submit}
                                                    type="button"
                                                    className="btn btn-primary w-xs btn-submit">
                                                    {t('common.searchInformation')}
                                                </button>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </CardTitle>
                            <CardBody style={{margin: 0, padding: 0}}>
                                {skeleton ? (
                                    <SkeletonTableDroneInformation />
                                ) : (
                                    <TableDroneInformation
                                        isPagination={true}
                                        row={rows}
                                        page={page}
                                        pageSize={pageSize}
                                        total={total}
                                        isShowIndex={true}
                                        column={ColumnsDroneInformation}
                                        onSort={onSort}
                                        onChangePagination={onChangePagination}
                                        onClickRowTable={onClickTable}
                                        action={[
                                            {
                                                button: (
                                                    <Button
                                                        style={{
                                                            width: 36,
                                                            height: 36,
                                                            margin: 0,
                                                            padding: 0,
                                                            border: '1px solid',
                                                            borderColor: '#34c38f',
                                                            background: '#E9FFF3',
                                                        }}>
                                                        <img
                                                            style={{width: 20, height: 20, boxShadow: 'none'}}
                                                            src={iconEyeOpen}
                                                        />
                                                    </Button>
                                                ),
                                                onClick: onClickTable,
                                            },
                                        ]}
                                    />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withTranslation()(DroneInformation)
