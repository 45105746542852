import React from 'react'
import './flight-history-detail-skeleton.scss'
import {Row, Col, Card, CardBody} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const CardDroneDataDetailSkeleton = () => {
    return (
        <Row className="container-skeleton-flight-history-detail row-card-drone-date-detail-skeleton">
            <Col lg={6} xl={6} className="col-card-drone">
                <Card className="card-drone">
                    <div className="line"></div>
                    <CardBody>
                        <Row>
                            <Col lg={2} xl={2} className="col-box-icon-drone">
                                <Skeleton className="box-icon-drone" />
                            </Col>
                            <Col lg={10} xl={10}>
                                <Skeleton className="title-drone-name" />
                                <Skeleton className="sub-title-drone" />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={6} xl={6}>
                <Card className="card-date">
                    <div className="line"></div>
                    <CardBody>
                        <Row>
                            <Col lg={2} xl={2} className="col-box-icon-date">
                                <Skeleton className="box-icon-calendar" />
                            </Col>
                            <Col lg={10} xl={10}>
                                <Skeleton className="title-date" />
                                <Skeleton className="sub-title" />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default CardDroneDataDetailSkeleton
