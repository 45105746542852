import React, {useState} from 'react'
import {Container, Row, Col, Button, Card, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'
import {useHistory} from 'react-router-dom'
import html2canvas from 'html2canvas'
import styled from 'styled-components'

import dayjs from 'dayjs'
import 'dayjs/locale/th'
import './index.scss'

import IconExport from '../../assets/images/icon/icon-export.png'

import SearchBar from '../../components/UseriNalyze/UserInformation/SearchBar'
import DataPilot from 'components/UseriNalyze/UserInformation/User_Overall/DataPilot'
import DataDrone from 'components/UseriNalyze/UserInformation/User_Overall/DataDrone'
import SelectNavBar from 'components/UseriNalyze/UserInformation/User_Overall/SelectNavBar'
import SelectData from 'components/UseriNalyze/UserInformation/User_Overall/SelectData'
import DataRegular from 'components/UseriNalyze/UserInformation/User_Overall/DataRegular'
import Overview from 'components/UseriNalyze/UserInformation/User_Drone/Overview'
import NavBarUser from 'components/UseriNalyze/UserInformation/User_Drone/NavBarUser'
import Widget from 'components/UseriNalyze/UserInformation/User_Drone/Widget'
import FlightDuration from 'components/UseriNalyze/UserInformation/User_Drone/FlightDuration'
import WorkingArea from 'components/UseriNalyze/UserInformation/User_Drone/WorkingArea'
import SpeedUserInfo from 'components/UseriNalyze/UserInformation/User_Drone/SpeedUserInfo'
import HeightUserInfo from 'components/UseriNalyze/UserInformation/User_Drone/HeightUserInfo'
import HistoryFlightTotal from 'components/UseriNalyze/UserInformation/User_Drone/HistoryFlightTotal'
import TableHistoryFlight from 'components/UseriNalyze/UserInformation/User_Drone/TableHistoryFlight'

const buttonName = [
    {title: 'Overall', value: 'overall'},
    {title: 'Drone', value: 'drone'},
]

const ButtonExport = styled.button`
    background-color: #3b89ff;
    color: white;
    font-size: 14px;
    padding: 9px 15px 9px 15px;
    border-radius: 8px;
    // margin: 10px 0px;
    cursor: pointer;
    border-width: 0px;
    text-align: center;
`

const UserInformation = () => {
    const [activeTab, setActiveTab] = useState('overall')
    const [otherMenu, setOtherMenu] = useState(false)
    const history = useHistory()

    const toggleTab = tab => {
        setActiveTab(tab)

        if (tab === 'overall') {
            history.push(`/user-information/overall`)
        }

        if (tab === 'drone') {
            history.push(`/user-information/drone`)
        }
    }

    const toggleOther = () => {
        setOtherMenu(cur => !cur)
    }

    const downloadScreenshot = async ext => {
        html2canvas(document.getElementById('page-content'), {
            scale: 1.2,
        }).then(async function (canvas) {
            const data = await canvas.toDataURL(`image/${ext}`),
                link = document.createElement('a')

            link.href = data
            link.download = `${dayjs().format('DD-MM-YYYY-hmmss')}.${ext}`

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        })
    }

    return (
        <div className="page-content">
            <Container fluid>
                <SearchBar />
                <Row className="flex-container space-between " style={{marginTop: 10, marginBottom: 10}}>
                    <Col>
                        <div className="col-sm-auto">
                            <div className="row-sm-auto button-items">
                                {buttonName.map((item, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            color="#F5F1EF"
                                            outline
                                            className="w-lg "
                                            style={{
                                                fontFamily: 'Inter',
                                                fontWeight: 600,
                                                fontSize: 16,
                                                borderRadius: 8,

                                                color: activeTab === item.value ? '#fff' : '#F05A28',
                                                backgroundColor: activeTab == item.value ? '#F05A28' : '#F5F1EF',
                                            }}
                                            onClick={() => toggleTab(item.value)}>
                                            {item.title}
                                        </Button>
                                    )
                                })}
                            </div>
                        </div>
                    </Col>

                    <div className="col-sm-auto">
                        <Dropdown
                            isOpen={otherMenu}
                            toggle={toggleOther}
                            direction="down"
                            className=" ms-3 dropend"
                            style={{
                                paddingTop: 5,
                            }}>
                            <DropdownToggle tag="i" className="text-muted">
                                <ButtonExport color="#3B89FF" size="sm">
                                    <img src={IconExport} alt="" height="18" />
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 400,
                                            fontFamily: 'Inter',
                                            color: '#FFFFFF',
                                            marginLeft: 10,
                                        }}>
                                        Export
                                    </span>
                                </ButtonExport>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    header
                                    style={{
                                        backgroundColor: '#F3F3F3',
                                        fontSize: 12,
                                        fontWeight: 600,
                                        fontFamily: 'Inter',
                                        color: '#212529',
                                    }}>
                                    Export to
                                </DropdownItem>

                                <DropdownItem
                                    style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        color: '#212529',
                                    }}
                                    onClick={() => downloadScreenshot('png')}>
                                    png
                                </DropdownItem>
                                <DropdownItem
                                    style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        color: '#212529',
                                    }}
                                    onClick={() => downloadScreenshot('jpeg')}>
                                    jpeg
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </Row>
                <div id="page-content">
                    {activeTab === 'overall' && (
                        <Row>
                            <Col lg={6} xl="3">
                                <DataPilot />
                            </Col>
                            <Col lg={6} xl="9">
                                <DataDrone />
                            </Col>

                            <Col xl={12}>
                                <Card
                                    style={{
                                        borderRadius: 8,
                                        height: 480,
                                    }}>
                                    <SelectNavBar />
                                    <SelectData />
                                </Card>
                            </Col>
                            <DataRegular />
                        </Row>
                    )}
                    {activeTab === 'drone' && (
                        <Row>
                            <Col lg={12}>
                                <Overview />
                            </Col>
                            <Col lg={12}>
                                <NavBarUser />
                            </Col>

                            <Widget />

                            <Col lg={6}>
                                <FlightDuration />
                            </Col>
                            <Col lg={6}>
                                <WorkingArea />
                            </Col>
                            <Col lg={6}>
                                <SpeedUserInfo />
                            </Col>
                            <Col lg={6}>
                                <HeightUserInfo />
                            </Col>
                            <Col lg={12}>
                                <HistoryFlightTotal />
                            </Col>
                            <Col lg={12}>
                                <TableHistoryFlight />
                            </Col>
                        </Row>
                    )}
                </div>
            </Container>
        </div>
    )
}

export default UserInformation
