import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React, { Component, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import "./style.scss";
const CardSkeleton = ({}) => {
  const loadCards = Array(11).fill(1);
  return (
    <Col>
      <Card
        style={{
          borderRadius: 8,
          boxShadow: "0px 12px 24px rgb(240, 240, 240)",
        }}
      >
        <CardBody>
          <Row>
            <Col lg="6">
              <div className="card-skeleton ">
                <Skeleton height={600} />
              </div>
            </Col>
            <Col lg="6">
              <div className="card-skeleton ">
                <div>                
                  <div className="row align-items-center" style={{ marginTop: 16,marginBottom:16 }}>
                      <div className="col-4">
                        <Skeleton  width={180} height={16}/>
                      </div>
                      <div className="col-8 d-flex justify-content-end align-items-center">
                        <Skeleton  width={400} height={16}/>
                        <Skeleton width={32} height={32} style={{ marginLeft: 20 }}/>
                      </div>
                    </div>
                  <Skeleton
                    height={1}
                    style={{ marginBottom: 10, marginTop: 18 }}
                  />
                  {loadCards.map((card, i) => (
                  <div key={i}>
                    <div className="row">
                      <div className="col-4">
                        <Skeleton />
                      </div>
                      <div className="col-8">
                        <Skeleton />
                      </div>
                    </div>
                    <Skeleton height={1} style={{ marginBottom: 10 }} />
                  </div>))}
                  {/* <div className="d-flex align-items-center">
                    <Skeleton width={120} style={{}} />
                    <Skeleton
                      // width={120}

                      style={{ marginLeft: 30 }}
                    />
                  </div> */}
                  {/* <Skeleton height={14} count={20} style={{ marginTop: 8 }} /> */}
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CardSkeleton;
