import React, {useState, useEffect} from 'react'
import {withTranslation} from 'react-i18next'
import {Row, Col, Card, CardBody} from 'reactstrap'
import styled from 'styled-components'

import WidgetDroneSkeleton from '../../CardSkeleton/WidgetDroneSkeleton'
import {useRecoilState, useSetRecoilState, useRecoilValue} from 'recoil'
import {filterUserInformationState, loadingUserInformationState} from 'store/atoms/user-information'
import {getDroneDetail} from 'services/skc/user-information'

const Actions = styled.div`
    color: #333;
    display: flex;
    align-items: center;

    @media (max-width: 500px) {
        flex-direction: column;
        & button {
            width: 100%;
            // margin-bottom: 4px;
            font-size: 0.65rem;
        }
    }
`
const Value = styled.label`
    color: #4e4e4e;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
`
const Value2 = styled.label`
    color: #898989;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin: 0px 0px 4px 6px;
`

const Widget = props => {
    const {t, i18n} = props

    const [data, setData] = useState({})
    const [skeleton, setSkeleton] = useState(false)

    const filterUserInformation = useRecoilValue(filterUserInformationState)
    const [loading, setLoading] = useRecoilState(loadingUserInformationState)

    useEffect(() => {
        fetchDroneDetail(filterUserInformation)
    }, [])

    useEffect(() => {
        if (loading?.drone_detail) {
            fetchDroneDetail(filterUserInformation)
            setLoading(cur => ({...cur, drone_detail: false}))
        }
    }, [loading?.drone_detail])

    const fetchDroneDetail = async params => {
        try {
            setSkeleton(true)

            const response = await getDroneDetail(params)
            if (response?.status_code === 200 && response?.results?.data) {
                setData(response?.results?.data)
            }
        } catch (error) {
            setData({})
        } finally {
            setSkeleton(false)
        }
    }

    const twoDecimal = new Intl.NumberFormat('en-NZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })

    const noDecimal = new Intl.NumberFormat('en-NZ', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })

    return (
        <>
            {skeleton ? (
                <WidgetDroneSkeleton amount={7} />
            ) : (
                <Row>
                    <Col>
                        <Card
                            style={{
                                borderRadius: 8,
                                boxShadow: '0px 12px 24px rgb(240, 240, 240)',
                            }}>
                            <CardBody>
                                <h4
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: '#898989',
                                    }}>
                                    {t('userInformation.totalFlights')}
                                </h4>
                                <Actions>
                                    <Value> {noDecimal.format(data?.flight || 0)}</Value>
                                    <Value2> {t('common.flightTimeUnit')}</Value2>
                                </Actions>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card
                            style={{
                                borderRadius: 8,
                                boxShadow: '0px 12px 24px rgb(240, 240, 240)',
                            }}>
                            <CardBody>
                                <h4
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: '#898989',
                                    }}>
                                    {t('userInformation.totalFlightTime')}
                                </h4>
                                <Actions>
                                    <Value> {twoDecimal.format(data?.hour || 0)}</Value>
                                    <Value2> {t('common.hourUnit')}</Value2>
                                </Actions>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card
                            style={{
                                borderRadius: 8,
                                boxShadow: '0px 12px 24px rgb(240, 240, 240)',
                            }}>
                            <CardBody>
                                <h4
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: '#898989',
                                    }}>
                                    {t('userInformation.totalWorkingArea')}
                                </h4>
                                <Actions>
                                    <Value> {twoDecimal.format(data?.area || 0)}</Value>
                                    <Value2> {t('common.raiUnit')}</Value2>
                                </Actions>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card
                            style={{
                                borderRadius: 8,
                                boxShadow: '0px 12px 24px rgb(240, 240, 240)',
                            }}>
                            <CardBody>
                                <h4
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: '#898989',
                                    }}>
                                    {t('userInformation.totalSprayingArea')}
                                </h4>
                                <Actions>
                                    <Value> {noDecimal.format(data?.total_spray_area || 0)}</Value>
                                    <Value2> {t('common.raiUnit')}</Value2>
                                </Actions>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card
                            style={{
                                borderRadius: 8,
                                boxShadow: '0px 12px 24px rgb(240, 240, 240)',
                            }}>
                            <CardBody>
                                <h4
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: '#898989',
                                    }}>
                                    {t('userInformation.averageSprayingQuantity')}
                                </h4>
                                <Actions>
                                    <Value> {noDecimal.format(data?.total_use_spray_per_rai || 0)}</Value>
                                    <Value2> {t('common.literRaiUnit')}</Value2>
                                </Actions>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card
                            style={{
                                borderRadius: 8,
                                boxShadow: '0px 12px 24px rgb(240, 240, 240)',
                            }}>
                            <CardBody>
                                <h4
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: '#898989',
                                    }}>
                                    {t('userInformation.totalSpreadingArea')}
                                </h4>
                                <Actions>
                                    <Value> {noDecimal.format(data?.total_spread_area || 0)}</Value>
                                    <Value2> {t('common.raiUnit')}</Value2>
                                </Actions>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card
                            style={{
                                borderRadius: 8,
                                boxShadow: '0px 12px 24px rgb(240, 240, 240)',
                            }}>
                            <CardBody>
                                <h4
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: '#898989',
                                    }}>
                                    {t('userInformation.averageSpreadingQuantity')}
                                </h4>
                                <Actions>
                                    <Value> {noDecimal.format(data?.total_use_spread_per_rai || 0)}</Value>
                                    <Value2> {t('common.kgUnitRaiUnit')}</Value2>
                                </Actions>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    )
}

export default withTranslation()(Widget)
