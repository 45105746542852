import {Card, CardTitle, CardBody, Col, Row, Button} from 'reactstrap'
import './index.scss'
import iconLeftData from '../../../assets/images/icon/icon-arrow-left-data.png'
import iconTagDrone from '../../../assets/images/icon/icon-tag-drone.png'
import {Redirect} from 'react-router-dom'
import DataTableMonitoring from '../DroneInformation/DataTableMonitoring'
import config from 'config'
import io from 'socket.io-client'
import CardDroneWorkingSkeleton from '../Skeleton-iNalyze/Real-time-Monitoring/CardDroneWorkingSkeleton'
import {withTranslation} from 'react-i18next'
import i18n from '../../../i18n'
import React, {Component} from 'react'

class CardDroneWorking extends Component {
    constructor(props) {
        super(props)
        // console.log(props)
        this.state = {
            gotoDetail: null,
            isSearch: false,
            column: [
                {key: 'kubota_sn', title: 'Kubota S/N', textAlign: 'left', sort: false, width: 100},
                {key: 'dji_sn', title: 'DJI S/N', textAlign: 'left', sort: false, width: 100},
                {key: 'hardware_model', title: 'Drone Model', textAlign: 'left', sort: false, width: 100},
                {
                    key: 'pilot_firstname',
                    title: this.props.t('realtime.pilot'),
                    textAlign: 'left',
                    sort: false,
                    width: 100,
                },
            ],
            rows: [],
            total: 0,
            loadingSkeleton: true,
            action: [
                {
                    button: (
                        <Button color="link" className="btn btn-link waves-effect btn-hide">
                            <img style={{width: 20, height: 20, boxShadow: 'none'}} src={iconTagDrone} />
                        </Button>
                    ),
                    onClick: this.clickCheckDetailDrone,
                },
            ],
            language: i18n.language,
        }
    }

    componentDidMount() {
        const socket = io(`${config.socket.URL}`)
        socket.on('AllRealtime', data => {
            // this.setState({rows: data})

            this.setState({
                total: data.filter(e => e?.position != undefined).length,
            })

            
            if (this.props.search != '' && this.props.filter.length > 0) {
                this.setState({
                    rows: data.filter(
                        item =>
                            ((item.dji_sn != null &&
                                (item.dji_sn.replace('-', '').toLowerCase().includes(this.props.search.toLowerCase()) ||
                                    item.dji_sn.toLowerCase().includes(this.props.search.toLowerCase()))) ||
                                (item.kubota_sn != null &&
                                    item.kubota_sn.toLowerCase().includes(this.props.search.toLowerCase())) ||
                                (item.pilot_firstname != null &&
                                    item.pilot_firstname.toLowerCase().includes(this.props.search.toLowerCase()))) &&
                            data.filter(item => this.props.filter.includes(item.hardware_model)) && data.filter(item => item.position != undefined),
                    ),
                })
            } else if (this.props.search != '') {
                this.setState({
                    rows: data.filter(
                        item =>
                            (item.dji_sn != null &&
                                (item.dji_sn.replace('-', '').toLowerCase().includes(this.props.search.toLowerCase()) ||
                                    item.dji_sn.toLowerCase().includes(this.props.search.toLowerCase()))) ||
                            (item.kubota_sn != null &&
                                item.kubota_sn.toLowerCase().includes(this.props.search.toLowerCase())) ||
                            (item.pilot_firstname != null &&
                                item.pilot_firstname.toLowerCase().includes(this.props.search.toLowerCase())) && data.filter(item => item.position != undefined),
                    ),
                })
            } else if (this.props.filter.length > 0) {
                this.setState({
                    rows: data.filter(item =>
                        this.props.filter.includes(item.hardware_model) && data.filter(item => item.position != undefined)
                    ),
                })
            } else{
                this.setState({
                    rows: data.filter(item => item.position != undefined)
                })
            }
        })

        this.setState({loadingSkeleton: true})
    }

    componentDidUpdate() {
        if (this.state.loadingSkeleton) {
            setTimeout(() => {
                this.setState({loadingSkeleton: false})
            }, 500)
        }

        // setTimeout(() => {
        //     this.props.setTotal(this.state.total)
        // }, 5000);
        if (this.state.language !== i18n.language) {
            this.setState({
                language: i18n.language,
                column: [
                    {key: 'kubota_sn', title: 'Kubota S/N', textAlign: 'left', sort: false, width: 100},
                    {key: 'dji_sn', title: 'DJI S/N', textAlign: 'left', sort: false, width: 100},
                    {key: 'hardware_model', title: 'Drone Model', textAlign: 'left', sort: false, width: 100},
                    {
                        key: 'pilot_firstname',
                        title: this.props.t('realtime.pilot'),
                        textAlign: 'left',
                        sort: false,
                        width: 100,
                    },
                ],
            })
        }

    }

    clickCheckDetailDrone = id => {
        this.setState({gotoDetail: id})
    }

    onClickFilterDroneModels = (name, i) => {
        let filter = this.state.filterDroneModels
        if (filter.includes(name)) {
            filter = filter.filter(item => item !== name)
        } else {
            filter.push(name)
        }
        this.setState({
            droneModels: this.state.droneModels.map((item, index) =>
                index === i ? {...item, active: !item.active} : item,
            ),
            filterDroneModels: filter,
        })
    }

    setTotal = (total) => {
        console.log(total)
    }

    render() {

        const {rows,column, action} = this.state

        return (
            <>
                {this.state.loadingSkeleton ? (
                    <CardDroneWorkingSkeleton />
                ) : (
                    <Col xl={12}>
                        <DataTableMonitoring
                            row={rows}
                            column={column}
                            isShowIndex={false}
                            isShowBorderLine={false}
                            action={action}
                        />
                    </Col>
                )}
            </>
        )
    }
}

export default withTranslation()(CardDroneWorking)
