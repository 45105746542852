import { GET_SPEED_DRONE_ACTIVE_FAIL, GET_SPEED_DRONE_ACTIVE_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  data: [],
};

const speedDroneActive = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SPEED_DRONE_ACTIVE_SUCCESS:
      // console.log("action :>> ", action.payload);
      return {
        ...state,
        data: action.payload,
      };
    case GET_SPEED_DRONE_ACTIVE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default speedDroneActive;
