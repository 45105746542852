import React from 'react'
import {Card, CardBody, Row, Col} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './style.scss'

const DroneTypeSkeleton = () => {
    return (
        <Card className="skeleton-drone-type">
            <CardBody>
                <Row>
                    <Col xs={6} lg={6}>
                        <div className=" me-3 ">
                            <Skeleton className="skeleton-tab" width={100} height={20} />
                            <Skeleton className="skeleton-tab" width={210} height={20} />
                        </div>
                    </Col>
                    <Col xs={6} lg={6} className="col-btn">
                        <Skeleton className="skeleton-btn" width={100} height={37} />
                        <Skeleton className="skeleton-btn" width={120} height={37} />
                    </Col>
                </Row>
                <Row>
                    <div className="col-province">
                        <Skeleton className="skeleton-province" width={200} height={32} />
                        <Skeleton className="skeleton-province" width={200} height={32} />
                    </div>
                    <div className="skeleton-data-total-average">
                        <Skeleton className="skeleton-type" width={70} height={20} />
                        <Skeleton className="skeleton-type" width={300} height={32} />
                        <Skeleton className="skeleton-type" width={80} height={32} />
                    </div>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col xs={6} lg={6}>
                        <Skeleton width={'100%'} height={450} />
                    </Col>
                    <Col xs={6} lg={6}>
                        <Skeleton width={'100%'} height={450} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default DroneTypeSkeleton
