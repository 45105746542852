import usFlag from '../assets/images/flags/us.png'
import thailand from '../assets/images/flags/thailand.png'
const languages = {
    th: {
        label: 'ไทย',
        flag: thailand,
    },
    en: {
        label: 'English',
        flag: usFlag,
    },
}

export default languages
