import {all, fork} from 'redux-saga/effects'

//public

import AuthSaga from './auth/login/saga'
import authorizedSaga from './authorized/saga'

import LayoutSaga from './layout/saga'

// -------------------------------------------------------------------

import filterdroneSaga from './filterdrone/saga'

import provinceSaga from './getprovince/saga'
import districtSaga from './getdistrict/saga'

// Drone infomation Inalyze

import navbarDroneActiveSaga from './navbar-drone-active/saga'

// User info

import wayPointSaga from './waypoint/saga'

// Drone Active

import loadingDroneActiveSaga from './loading-drone-active/saga'

import speedDroneActiveSaga from './speed-drone-active/saga'
import heightDroneActiveSaga from './height-drone-active/saga'

export default function* rootSaga() {
    yield all([
        fork(AuthSaga),
        fork(authorizedSaga),
        fork(LayoutSaga),

        fork(filterdroneSaga),

        fork(provinceSaga),
        fork(districtSaga),

        fork(navbarDroneActiveSaga),

        fork(wayPointSaga),

        fork(loadingDroneActiveSaga),

        fork(speedDroneActiveSaga),
        fork(heightDroneActiveSaga),
    ])
}
