import React, {Component} from 'react'
import {Container, Row, Col, Button, InputGroup, Input} from 'reactstrap'
import './index.scss'
import iconSearch from '../../assets/images/icon/icon-search.png'

import 'animate.css'
import MapDroneMonitoringDetail from 'components/DroneiNalyze/Real-time-Monitoring-Detail/MapDroneDetailMonotoring'
import CardDetailDrone from 'components/DroneiNalyze/Real-time-Monitoring-Detail/CardDetailDrone'
import Skeleton from 'react-loading-skeleton'

class RealTimeMonitoringDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {loadingSkeleton: true}
    }

    componentDidMount() {
        this.setState({loadingSkeleton: true})
    }

    componentDidUpdate() {
        if (this.state.loadingSkeleton) {
            setTimeout(() => {
                this.setState({loadingSkeleton: false})
            }, 3000)
        }
    }

    render() {
        const state = this.state
        // console.log("this.state.showTabDrone :>> ", this.state.showTabDrone);
        return (
            <>
                <div className="page-content">
                    <Container fluid className="content-real-time-monitoring">
                        <Row>
                            <Col xl={6}>
                                {state.loadingSkeleton ? (
                                    <Skeleton height={36} width={236} />
                                ) : (
                                    <p className="section-header">Real-time Monitoring</p>
                                )}
                            </Col>
                            <Col xl={6} className="container-box-search">
                                {/* <InputGroup className="box-search">
                  <Input className="input-search" type="text" placeholder="Kubota S/N , DJI S/N" />

                  <Button className="btn-search" type="button" color="primary">
                    <img src={iconSearch} />
                  </Button>
                </InputGroup> */}
                            </Col>
                        </Row>
                        <div id="page-content">
                            {/* <Row className="container-data-detail"> */}
                            {/* <Col lg={10} xl={10} className="col-data-map"> */}
                            <MapDroneMonitoringDetail />
                            {/* </Col>
                <Col lg={2} xl={2} className="col-data-detail ">
                  <CardDetailDrone />
                </Col> */}
                            {/* </Row> */}
                        </div>
                    </Container>
                </div>
            </>
        )
    }
}
export default RealTimeMonitoringDetail
