import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import React from 'react'
import {Col, Card, CardBody} from 'reactstrap'
import './style.scss'

const CumulativeSkeleton = ({amount}) => {
    const loadCards = Array(amount).fill(1)
    return loadCards.map((card, i) => (
        <Col key={i}>
            <Card
                style={{
                    borderRadius: 8,
                    boxShadow: '0px 12px 24px rgb(240, 240, 240)',
                }}>
                <CardBody>
                    <div className="card-skeleton ">
                        <div className="d-flex align-items-center">
                            <Skeleton circle width={32} height={32} />
                            <Skeleton width={150} height={16} style={{marginLeft: 15}} />
                        </div>
                        <div>
                            <Skeleton height={16} style={{marginTop: 30}} />
                            <Skeleton height={16} style={{marginTop: 8}} />
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    ))
}

export default CumulativeSkeleton
