import { takeEvery, put, call } from "redux-saga/effects";

// Chat Redux States
import { GET_FILTERDRONE } from "./actionTypes";
import { getfilterdroneSuccess, getfilterdroneFail } from "../actions";

//Include Both Helper File with needed methods
import { getfilterdrone } from "../../services/skc/dashboard";

function* onGetFilterdrone() {
 
  try {
    const response = yield call( getfilterdrone);
    
    yield put(getfilterdroneSuccess(response));
  } catch (error) {
    yield put(getfilterdroneFail(error));
  }
}

function* filterdroneSaga() {
  yield takeEvery(GET_FILTERDRONE, onGetFilterdrone);
}

export default filterdroneSaga;
