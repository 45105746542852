import React, {useEffect, useState} from 'react'
import {Table} from 'reactstrap'
import {Pagination} from 'antd'
import {withTranslation} from 'react-i18next'

const DataTable = props => {
    const {
        t,
        i18n,
        column = [],
        action,
        row = [],
        isShowIndex = true,
        isShowBorderLine = true,
        isPagination,
        rowPerPage,
        total,
        pageSize,
        onChangePagination,
        onSort,
    } = props

    const [sortType, setSortType] = useState('ASC')

    const handleChangePagination = (page, pageSize) => {
        onChangePagination(page, pageSize)
    }

    const handleSort = columnName => {
        let resultSortType

        if (sortType === 'ASC') {
            resultSortType = 'DESC'
        } else {
            resultSortType = 'ASC'
        }

        if (columnName === 'hour') {
            onSort('hoursort', resultSortType)
        } else {
            onSort(columnName, resultSortType)
        }

        setSortType(resultSortType)
    }

    return (
        <>
            <div className="table-responsive ">
                <Table
                    className="table table-hover  table-bordered  mb-0 data-table white-bg"
                    style={{
                        borderLeft: isShowBorderLine || 'none',
                        borderRight: isShowBorderLine || 'none',
                        margin: 0,
                        marginTop: 12,
                        padding: 0,
                    }}>
                    <thead className="table-header">
                        <tr>
                            {isShowIndex && (
                                <th className="column-index" style={{backgroundColor: '#F3F6F9'}}>
                                    #
                                </th>
                            )}
                            {column?.map((itm, index) => (
                                <th
                                    key={`${itm.key}-${index}`}
                                    style={{
                                        backgroundColor: '#F3F6F9',
                                        textAlign: itm.textAlign,
                                        borderRight: '1px rgba(73, 80, 87, 0.1) solid',
                                        borderLeft: !isShowBorderLine || 'none',
                                        borderRight: !isShowBorderLine || 'none',
                                    }}>
                                    <div className="container-text-column">
                                        {itm.title}
                                        {itm.sort && (
                                            <i className="bx bx-sort icon-sort" onClick={() => handleSort(itm.key)}></i>
                                        )}
                                    </div>
                                </th>
                            ))}
                            {action?.length > 0 && (
                                <th className="column-action" style={{backgroundColor: '#F3F6F9'}}>
                                    Action
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {row?.length > 0 ? (
                            row?.map((itemRow, indexRow) => (
                                <tr key={`${itemRow}-${indexRow}`}>
                                    {isShowIndex && (
                                        <th scope="row" width={'3%'} style={{textAlign: 'center'}}>
                                            {(rowPerPage - 1) * pageSize + (indexRow + 1)}
                                        </th>
                                    )}
                                    {column?.map((itemColumn, columnIndex) => (
                                        <td
                                            key={`${itemRow[itemColumn.key]}-${columnIndex}`}
                                            style={{
                                                width: itemColumn.width,
                                                color: itemColumn.color,
                                                borderLeft: !isShowBorderLine || 'none',
                                                borderRight: !isShowBorderLine || 'none',
                                            }}
                                            className="row-data">
                                            {itemColumn?.setColor ? (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                    <div
                                                        style={{
                                                            background:
                                                                itemRow[itemColumn.key] === itemColumn.checkChangeColor
                                                                    ? itemColumn.background1
                                                                    : itemRow[itemColumn.key]
                                                                    ? itemColumn.background2
                                                                    : '',
                                                            color:
                                                                itemRow[itemColumn.key] === itemColumn.checkChangeColor
                                                                    ? itemColumn.color1
                                                                    : itemRow[itemColumn.key]
                                                                    ? itemColumn.color2
                                                                    : '',
                                                            borderRadius: '16px',
                                                            height: 27,
                                                            padding: 16,
                                                            textAlign: 'center',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}>
                                                        <p style={{margin: 0, padding: 0}}>
                                                            {itemRow[itemColumn.key] || '-'}
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    {itemColumn.key === 'area_name' ? (
                                                        <div
                                                            style={{
                                                                alignItems: 'center',
                                                                margin: 0,
                                                                padding: 0,
                                                                height: '100%',
                                                                marginLeft: '20px',
                                                            }}>
                                                            <p
                                                                style={{
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    marginTop: 4,
                                                                    wordWrap: 'break-word',
                                                                }}>
                                                                {itemRow[itemColumn.key] || '-'}
                                                            </p>
                                                            {itemRow?.latitude || itemRow.longitude ? (
                                                                <p
                                                                    style={{
                                                                        margin: 0,
                                                                        padding: 0,
                                                                        fontFamily: 'Inter',
                                                                        color: '#9E9E9E',
                                                                        fontSize: 12,
                                                                    }}>
                                                                    {itemRow?.latitude || '-'} /{' '}
                                                                    {itemRow?.longitude || '-'}
                                                                </p>
                                                            ) : (
                                                                <p
                                                                    style={{
                                                                        margin: 0,
                                                                        padding: 0,
                                                                        fontFamily: 'Inter',
                                                                        color: '#9E9E9E',
                                                                        fontSize: 12,
                                                                    }}>
                                                                    -
                                                                </p>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                margin: 0,
                                                                padding: 0,
                                                                height: '100%',
                                                                marginLeft: '20px',
                                                            }}>
                                                            <p style={{margin: 0, padding: 0}}>
                                                                {itemRow[itemColumn.key] || '-'}
                                                            </p>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </td>
                                    ))}
                                    {action?.length > 0 && (
                                        <td
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                borderLeft: !isShowBorderLine || 'none',
                                                borderRight: !isShowBorderLine || 'none',
                                            }}
                                            className="row-action"
                                            width="5%">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    margin: '4px',
                                                    padding: 0,
                                                }}>
                                                {action?.map((itm, index) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            margin: 2,
                                                        }}
                                                        onClick={() =>
                                                            itm.onClick(
                                                                itemRow.dji_record_id,
                                                                itemRow?.hardware_id,
                                                                itemRow?.drone_model,
                                                                itemRow?.id,
                                                            )
                                                        }>
                                                        {itm.button}
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr key={0} className="text-center py-5 px-4">
                                <th colSpan={column?.length + 2}>{t('common.noData')}</th>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            {isPagination && (
                <div
                    className="container-pagination"
                    style={{
                        marginTop: 20,
                        marginBottom: 16,
                    }}>
                    <Pagination
                        defaultCurrent={rowPerPage}
                        total={total}
                        defaultPageSize={pageSize}
                        pageSize={pageSize}
                        onChange={(page, pageSize) => handleChangePagination(page, pageSize)}
                    />
                </div>
            )}
        </>
    )
}

export default withTranslation()(DataTable)
