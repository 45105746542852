import React, {useEffect, useState} from 'react'
import {Row, Col, Button, Card, CardBody, CardTitle} from 'reactstrap'
import {withTranslation} from 'react-i18next'
import {useRecoilState, useRecoilValue} from 'recoil'
import {Select} from 'antd'
const {Option} = Select

import {filterDashboardState, loadingDashboardState} from 'store/atoms/dashboard'

import {getFilterDistrict, getFilterProvince} from 'services/skc/lookup'
import {getAverageFlight, getTotalArea} from 'services/skc/new-dashboard'

import TotalArea from 'components/Dashboard/DroneTotalAreaAndAverageFlight/TotalArea'
import AverageFlight from 'components/Dashboard/DroneTotalAreaAndAverageFlight/AverageFlight'
import TotalAreaAndAverageFlightSkeleton from '../CardSkeleton/TotalAreaAndAverageFlightSkeleton'

import '../style/drone-total-area-and-average-flight.scss'

const DroneTotalAreaAndAverageFlight = props => {
    const {t, i18n} = props

    const [skeletonTotalArea, setSkeletonTotalArea] = useState(true)
    const [skeletonAverage, setSkeletonAverage] = useState(true)

    const [dataTotalArea, setDataTotalArea] = useState({})
    const [labelsTotalArea, setLabelsTotalArea] = useState({})
    const [dataAverageFlight, setDataAverageFlight] = useState({})
    const [labelsAverageFlight, setLabelsAverageFlight] = useState({})
    const [lutProvince, setLutProvince] = useState([])
    const [lutDistrict, setLutDistrict] = useState([])

    const [selectProvince, setSelectProvince] = useState([])
    const [selectDistrict, setSelectDistrict] = useState([])

    const [disableProvince, setDisableProvince] = useState(true)
    const [disabledDistrict, setDisabledDistrict] = useState(true)
    const [language, setLanguage] = useState(i18n.language)

    const [max, setMax] = useState(0)

    const searchFilter = useRecoilValue(filterDashboardState)
    const [loading, setLoading] = useRecoilState(loadingDashboardState)

    useEffect(() => {
        fetchProvince()
        fetchTotalArea({...searchFilter, province: [], district: []})
        fetchAverageFlight({...searchFilter, province: [], district: []})
    }, [])

    useEffect(() => {
        if (loading.total_area_average_flight) {
            fetchProvince()
            checkRegionOnlyOther()

            if (searchFilter?.region?.includes('other') && searchFilter?.region?.length === 1) {
                fetchTotalArea({...searchFilter, province: ['other'], district: ['other']})
                fetchAverageFlight({...searchFilter, province: ['other'], district: ['other']})
            } else {
                fetchTotalArea({...searchFilter, province: [], district: []})
                fetchAverageFlight({...searchFilter, province: [], district: []})
            }

            setLoading(cur => ({...cur, total_area_average_flight: false}))
        }
    }, [loading.total_area_average_flight])

    useEffect(() => {
        onSetDistrictValueState(lutDistrict)
    }, [lutDistrict])

    useEffect(() => {
        if (language !== i18n.language) {
            const data = {
                ...searchFilter,
                province: selectProvince === 'all' ? '' : selectProvince,
                district: selectDistrict === 'all' ? '' : selectDistrict,
            }

            fetchTotalArea(data)
            fetchAverageFlight(data)
            setLanguage(i18n.language)
        }
    }, [i18n.language])

    const fetchProvince = async () => {
        try {
            const response = await getFilterProvince({
                region: searchFilter.region.length === 0 ? ['2', '3', '4', '5', '6', '7'] : searchFilter.region,
            })

            if (response.status_code === 200 && response.results.data.length > 0) {
                setLutProvince(response.results.data)
            }
        } catch (error) {
            setLutProvince([])
        }
    }

    const fetchDistrict = async params => {
        try {
            const response = await getFilterDistrict(params)

            if (response.status_code === 200 && response.results.data.length > 0) {
                setLutDistrict(response.results.data)
            }
        } catch (error) {
            setLutDistrict([])
        }
    }

    const fetchTotalArea = async params => {
        try {
            setSkeletonTotalArea(true)
            const response = await getTotalArea(params)

            if (response.status_code === 200) {
                setDataTotalArea(response?.results?.series)
                setLabelsTotalArea(response?.results?.labels)
            }
        } catch (error) {
            setDataTotalArea([])
            setLabelsTotalArea([])
        } finally {
            setSkeletonTotalArea(false)
        }
    }

    const fetchAverageFlight = async params => {
        try {
            setSkeletonAverage(true)
            const response = await getAverageFlight(params)

            if (response.status_code === 200) {
                setDataAverageFlight(response?.results?.series)
                setLabelsAverageFlight(response?.results?.x_axis)
                setMax(response?.results?.max)
            }
        } catch (error) {
            setDataAverageFlight([])
            setLabelsAverageFlight([])
            setMax(0)
        } finally {
            setSkeletonAverage(false)
        }
    }

    const onChangeProvince = (value, options) => {
        const paramsData = {province: [...value]}

        setSelectProvince(value)
        options.forEach(item => {
            if (item.value === 'other' && value.length === 1) {
                setDisabledDistrict(true)
                setSelectDistrict([])
            } else {
                setDisabledDistrict(false)

                fetchDistrict(paramsData)
            }
        })
    }

    const onDeSelectProvince = value => {
        if (value === 'other') {
            setDisabledDistrict(false)
        }
        if (selectProvince.length <= 1) {
            setDisabledDistrict(true)
            setSelectProvince([])
            setSelectDistrict([])
            fetchDistrict({province: []})
        }
    }

    const onChangeDistrict = value => {
        setSelectDistrict(value)
    }

    const onDisableSelect = () => {
        return searchFilter.country === 'th' ? false : true
    }

    const checkRegionOnlyOther = () => {
        console.log('searchFilter :>> ', searchFilter)
        console.log('searchFilter :>> ', searchFilter.country === 'la')
        if (searchFilter?.region?.includes('other') && searchFilter?.region?.length === 1) {
            setSelectProvince(['other'])
            setSelectDistrict(['other'])
            setDisabledDistrict(true)
            setDisableProvince(true)
        } else if (searchFilter.country === 'la' || searchFilter.country === 'kh') {
            console.log('correct')
            setDisableProvince(true)
            setDisabledDistrict(true)
        } else {
            setDisableProvince(false)
            setSelectProvince([])
            setSelectDistrict([])
        }
    }

    const onSetDistrictValueState = newFilterDistrict => {
        const resultDistrict = []
        const district = []

        newFilterDistrict?.forEach(itm => {
            district.push({value: itm.id, name_th: itm.name_th, name_en: itm.name_en})
        })

        selectDistrict.forEach(itemDistrict => {
            district.forEach(itm => {
                if (itemDistrict === itm.value) {
                    resultDistrict.push(itemDistrict)
                }
            })
        })

        setSelectDistrict(resultDistrict)
    }

    const handleSubmit = () => {
        const data = {
            ...searchFilter,
            province: selectProvince,
            district: selectDistrict,
        }

        fetchTotalArea(data)
        fetchAverageFlight(data)
    }

    return (
        <>
            {skeletonTotalArea && skeletonAverage ? (
                <TotalAreaAndAverageFlightSkeleton />
            ) : (
                <Col xl={12}>
                    <Card className="card-total-area-average-flight">
                        <CardTitle className="head-title">
                            <Row className="row-filter">
                                <Col className="col-text" xl={1}>
                                    <p className="text">{t('common.selectDate')}</p>
                                </Col>
                                <Col xl={11}>
                                    <Row className="row-select">
                                        <Col xl={5} className="col-province-district">
                                            <Select
                                                mode="multiple"
                                                className="w-100 container-select"
                                                placeholder={t('common.province')}
                                                disabled={disableProvince}
                                                value={selectProvince}
                                                onChange={onChangeProvince}
                                                showSearch
                                                optionFilterProp="children"
                                                onDeselect={onDeSelectProvince}>
                                                <Option value={'other'} label={t('common.provinceNotSpecified')}>
                                                    {t('common.provinceNotSpecified')}
                                                </Option>

                                                {lutProvince?.map((item, index) => (
                                                    <Option
                                                        key={index}
                                                        value={item.id}
                                                        label={
                                                            i18n.language === 'en'
                                                                ? item.prov_name_en
                                                                : item?.prov_name_th
                                                        }>
                                                        {i18n.language === 'en'
                                                            ? item.prov_name_en
                                                            : item?.prov_name_th}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col xl={5} className="col-province-district">
                                            <Select
                                                mode="multiple"
                                                className="w-100 container-select"
                                                placeholder={t('common.district')}
                                                value={selectDistrict}
                                                onChange={onChangeDistrict}
                                                disabled={disabledDistrict ? disabledDistrict : onDisableSelect()}
                                                showSearch
                                                optionFilterProp="children">
                                                <Option value={'other'} label={t('common.districtNotSpecified')}>
                                                    {t('common.districtNotSpecified')}
                                                </Option>

                                                {lutDistrict?.map((item, index) => (
                                                    <Option
                                                        key={index}
                                                        value={item.id}
                                                        label={i18n.language === 'en' ? item.name_en : item.name_th}>
                                                        {i18n.language === 'en' ? item.name_en : item.name_th}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col xl={2} className="col-province-district">
                                            <Button className="btn btn-submit-search " onClick={() => handleSubmit()}>
                                                {t('common.searchInformation')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardTitle>
                        <CardBody>
                            <Row>
                                <Col xl={6}>
                                    {labelsTotalArea?.length > 0 && (
                                        <TotalArea
                                            series={dataTotalArea}
                                            labels={labelsTotalArea}
                                            searchFilter={searchFilter}
                                        />
                                    )}
                                </Col>
                                <Col xl={6}>
                                    {labelsAverageFlight?.length > 0 && (
                                        <AverageFlight
                                            series={dataAverageFlight}
                                            labels={labelsAverageFlight}
                                            searchFilter={searchFilter}
                                            max={max}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            )}
        </>
    )
}

export default withTranslation()(DroneTotalAreaAndAverageFlight)
