import { takeEvery, put, call } from "redux-saga/effects";

import { UPDATE_FILTER_DRONE_ACTIVE } from "./actionTypes";
import { setFilterDroneActiveSuccess, setFilterDroneActiveFail } from "../actions";

function* onSetFilterDroneActive({ payload: data }) {
  try {
    yield put(setFilterDroneActiveSuccess(data));
  } catch (error) {
    yield put(setFilterDroneActiveFail(error));
  }
}

function* navbarDroneActiveSaga() {
  yield takeEvery(UPDATE_FILTER_DRONE_ACTIVE, onSetFilterDroneActive);
}

export default navbarDroneActiveSaga;
