import React from 'react'
import Skeleton from 'react-loading-skeleton'

import '../skeleton-droneInformation.scss'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonNavbarUsage = props => {
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{display: 'flex', alignItems: 'center', marginRight: 16}}>
                <Skeleton width={60} height={38} style={{borderRadius: 8, marginRight: 8}} />
                <Skeleton width={66} height={38} style={{borderRadius: 8, marginRight: 8}} />
                <Skeleton width={54} height={38} style={{borderRadius: 8}} />
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div
                    style={{
                        height: '38px',
                        width: props?.activeTab === 'week' ? '357px' : '173px',
                        borderRadius: '8px',
                        padding: '4px 11px',
                        border: '1px solid #ced4da',
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '16px',
                    }}>
                    <Skeleton width={props?.activeTab === 'week' ? 330 : 150} />
                </div>

                <Skeleton width={80} height={37} style={{borderRadius: 8}} />
            </div>
        </div>
    )
}

export default SkeletonNavbarUsage
