import React from 'react'
import {withTranslation} from 'react-i18next'
import {Pagination} from 'antd'

import './flex.scss'

const TableDrone = props => {
    const {t, data, type, onChangePagination, page, total, filter, label, drone_models_last} = props

    const numberDigit = (value, digis) => {
        var nf = new Intl.NumberFormat('en-NZ', {
            minimumFractionDigits: digis,
            maximumFractionDigits: digis,
        })
        return nf.format(value)
    }

    return (
        <>
            <table id="customers">
                <thead>
                    <tr>
                        <th className={'text-feature-en'}>Model</th>
                        <th className="normal">
                            {type === 'total' && t('dashboard.accumulate')}
                            {type === 'average' && t('common.average')}
                        </th>
                        <th className="active" style={{textAlign: 'center'}}>
                            {type === 'average' && t('common.average')}{' '}
                            {filter?.type === 'year' && t('common.lastYearAgo')}
                            {filter?.type === 'month' && t('common.lastMonthAgo')}
                            {filter?.type === 'week' && t('common.lastWeekAgo')}
                            {filter?.type === 'period' && t('common.lastWeekAgo')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item, index) => (
                        <tr key={index}>
                            <td className="text-td-en" style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                                <span className="px-1">
                                    <img
                                        src={
                                            'https://s3dev-gramick.sgp1.cdn.digitaloceanspaces.com/kubota/' +
                                            item.image +
                                            '?v=1'
                                        }
                                        style={{height: 28, width: 56, marginRight: '10%', boxShadow: 'none'}}
                                    />
                                </span>
                                {item.name}
                            </td>
                            <td className="normal">
                                {label === 'พื้นที่ทำงาน (ไร่)' || label === 'ชั่วโมงบิน (ชม.)'
                                    ? numberDigit(item.amount, 2)
                                    : numberDigit(Math.round(item.amount), 0)}
                            </td>
                            <td className="active">
                                {label === 'พื้นที่ทำงาน (ไร่)' || label === 'ชั่วโมงบิน (ชม.)'
                                    ? numberDigit(drone_models_last[index]?.amount, 2)
                                    : numberDigit(Math.round(drone_models_last[index]?.amount), 0)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="container-pagination flex justify-center items-center mt-5">
                <Pagination
                    pageSize={5}
                    current={page}
                    total={total}
                    showSizeChanger={false}
                    onChange={page => onChangePagination(page)}
                />
            </div>
        </>
    )
}

export default withTranslation()(TableDrone)
