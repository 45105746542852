import React, {Component} from 'react'

import './index.scss'
import {withTranslation} from 'react-i18next'

class DetailMap extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {}

    render() {
        const state = this.state
        const {t} = this.props

        return (
            <div className="box-detail">
                <div className="box-row">
                    <span className="title"> {t('droneInformation.latitude')} :</span>
                    <span className="value">{this.props.waypoint?.latitude}</span>
                </div>
                <div className="box-row">
                    <span className="title"> {t('droneInformation.longitude')} :</span>
                    <span className="value">{this.props.waypoint?.longitude}</span>
                </div>
                <div className="box-row">
                    <span className="title"> {t('droneInformation.totalWorkingAreaMap')} :</span>
                    <span className="value">{parseFloat(this.props.flight?.workarea * 0.41667).toFixed(2)}</span>
                    <span className="unit">ไร่</span>
                </div>
                <div className="box-row">
                    <span className="title"> {t('droneInformation.totalSubstanceUsed')} :</span>
                    <span className="value">
                        {this.props.flight?.usagetype == 0
                            ? parseFloat(this.props.flight?.sprayusage / 1000).toFixed(2)
                            : this.props.flight?.usagetype == 1
                            ? parseFloat(this.props.summary?.payloadVolume.spreading / 1000).toFixed(2)
                            : ''}
                        {/* {
              (this.props.waypoint?.isSpraying) ? parseFloat(this.props.waypoint?.workInfo?.sprayed[0] > 0 ? (this.props.waypoint?.workInfo?.sprayed[0] / 1000) : 0).toFixed(2) :
              (this.props.waypoint?.isSpreading) ? parseFloat(this.props.waypoint?.workInfo?.sowed[0] > 0 ? (this.props.waypoint?.workInfo?.sowed[0] / 1000) : 0).toFixed(2) :
              ''
            } */}
                    </span>
                    <span className="unit">
                        {this.props.flight?.usagetype == 0
                            ? t('common.literUnit')
                            : this.props.flight?.usagetype == 1
                            ? t('common.kilogram')
                            : ''}
                    </span>
                </div>
            </div>
        )
    }
}

export default withTranslation()(DetailMap)
