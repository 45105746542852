import {
  GET_FILTERDRONE,
  GET_FILTERDRONE_FAIL,
  GET_FILTERDRONE_SUCCESS,
 
} from "./actionTypes";

export const getfilterdrone = () => ({
  type: GET_FILTERDRONE,

});

export const getfilterdroneSuccess = (drone) => ({
  type: GET_FILTERDRONE_SUCCESS,
  payload: drone,
});

export const getfilterdroneFail = (error) => ({
  type: GET_FILTERDRONE_FAIL,
  payload: error,
});

