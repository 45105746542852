module.exports = {
    google: {
        API_KEY: '',
        CLIENT_ID: '',
        SECRET: '',
        MAP_VT: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
        // MAP_VT: 'http://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
    },
    facebook: {
        APP_ID: '',
    },
    s3: {
        URL: 'https://s3dev-gramick.sgp1.cdn.digitaloceanspaces.com/kubota/',
    },
    socket: {
        URL: 'https://socket.jumpt.me',
    },
}
