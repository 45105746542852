import { takeEvery, put, call } from "redux-saga/effects";

// Chat Redux States
import { GET_WAY_POINT } from "./actionTypes";
import { getWayPointSuccess, getWayPointFail } from "../actions";

//Include Both Helper File with needed methods
import { getWayPoint } from "../../services/skc/kafka";

function* onGetWayPoint(action) {
  try {
    const response = yield call(getWayPoint, action.data);

    yield put(getWayPointSuccess(response));
  } catch (error) {
    yield put(getWayPointFail(error));
  }
}

function* wayPointSaga() {
  yield takeEvery(GET_WAY_POINT, onGetWayPoint);
}

export default wayPointSaga;
