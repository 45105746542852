import React from 'react'

import {Row, Col, Card, CardTitle, CardBody} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import '../comparison-skeleton.scss'

const CardAverageSkeleton = props => {
    return (
        <Card className="skeleton-card-average">
            <CardTitle className="title-card">
                <Skeleton className="title" />
                <div className="check-box-average form-check-success">
                    <Skeleton className="check-box-input" />

                    <Skeleton className="text-average" />
                </div>
            </CardTitle>
            <CardBody>
                <Row style={{margin: 0, padding: 0}}>
                    <Col xl={12}>
                        <Row style={{margin: 0, padding: 0}}>
                            {props.series?.map((item, index, array) => (
                                <Col
                                    xl={array.length === 2 ? 6 : ''}
                                    className="box-average"
                                    style={{
                                        borderRight:
                                            index + 1 != array.length && index + 1 != array.length - 1 ? 'none' : '',
                                        display: item.id === 'average' ? props?.showAverage : '',
                                    }}
                                    key={index}>
                                    <div className="country-average">
                                        <Skeleton className="dot-color" />
                                        <Skeleton className="text-country" />
                                    </div>
                                    <div className="box-data">
                                        <Skeleton className="data" />
                                        <Skeleton className="unit" />
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col size={12}>
                        <Skeleton width={'100%'} height={440} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default CardAverageSkeleton
