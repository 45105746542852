import React, {Component, useState, useEffect, useRef} from 'react'
import {Container, Row, Col, Button, Card, CardBody} from 'reactstrap'
import {connect} from 'react-redux'
import iconMarkerMap from '../../../../../assets/images/icon/icon-marker-map.png'
import {
    GoogleMap,
    LoadScript,
    Marker,
    Polyline,
    useLoadScript,
    Circle,
    InfoWindow,
    useGoogleMap,
} from '@react-google-maps/api'
import './index.scss'
import iconZoomIn from '../../../../../assets/images/icon/icon-zoom-in-map.png'
import iconZoomOut from '../../../../../assets/images/icon/icon-zoom-out-map.png'

import 'leaflet/dist/leaflet.css' // sass
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet-rotatedmarker'
import config from 'config'

const ButtonZoomFullscreen = props => {
    const map = useGoogleMap()
    const ref = useRef()
    useEffect(() => {
        if (map && ref) {
            map.controls[window.google.maps.ControlPosition[props.position]].push(ref.current)
        }
        // google.maps.ControlPosition.RIGHT_BOTTOM;
    }, [map, ref])
    return (
        <div className="custom-zoom-controls" ref={ref} style={{height: '400px', top: '900px'}}>
            <Button className="btn-zoom-in" onClick={() => props.handleZoomIn()}>
                <img src={iconZoomIn} />
            </Button>
            <Button className="btn-zoom-out" onClick={() => props.handleZoomOut()}>
                <img src={iconZoomOut} />
            </Button>
        </div>
    )
}

// function MapComponent() {
//     const {isLoaded, loadError} = useLoadScript({
//         googleMapsApiKey: 'AIzaSyB5RVDr2EpQhSt1YsMFCu7Xi2qlVz3qP2o',
//         // libraries: libraries,
//     })
//     const [zoom, setZoom] = useState(18)
//     const [isFullscreen, setIsFullscreen] = useState(false)
//     const [centerMap, setCenterMap] = useState({lat: 14.181815, lng: 101.201649})
//     const mapOptions = {
//         mapTypeId: 'hybrid',
//         disableDefaultUI: true,
//         // zoomControl: true,
//         fullscreenControl: true,
//         center: centerMap,
//         zoom: zoom,
//     }

//     const pathCoordinates = [
//         {lat: 14.181815, lng: 101.201649}, // Start point
//         {lat: 14.181915, lng: 101.202649}, // Start point
//         {lat: 14.181915, lng: 101.203649}, // Start point
//         {lat: 14.181915, lng: 101.204649},
//         {lat: 14.181915, lng: 101.205649},
//     ]

//     const containerStyle = {
//         width: '100%',
//         height: '800px',
//     }

//     if (loadError) {
//         console.error('Failed to load Google Maps library:', loadError)
//     }

//     useEffect(() => {
//         document.addEventListener('fullscreenchange', handleFullScreen)

//         return () => {
//             document.removeEventListener('fullscreenchange', handleFullScreen)
//         }
//     }, [])

//     const handleFullScreen = () => {
//         setIsFullscreen(document.fullscreenElement !== null)
//     }

//     const handleZoomIn = () => {
//         // console.log("zoom-in :>> ", zoom);
//         if (zoom < 20) {
//             setZoom(cur => cur + 1)
//             // clearCenterMap();
//         }
//     }

//     const handleZoomOut = () => {
//         // console.log("map :>> ", mapRef.current.state);
//         // console.log("zoom-out :>> ", zoom);
//         if (zoom > 0) {
//             setZoom(cur => cur - 1)
//             // clearCenterMap();
//         }
//     }

//     const handleDragEnd = map => {
//         const lat = map.center.lat()
//         const lng = map.center.lng()

//         setCenterMap({lat, lng})
//     }

//     return (
//         <div>
//             {isLoaded ? (
//                 <GoogleMap
//                     id="map"
//                     options={mapOptions}
//                     mapContainerStyle={containerStyle}
//                     center={centerMap}
//                     zooom={zoom}
//                     mapContainerClassName="map-container-flight-history"
//                     onLoad={map => {
//                         map.addListener('dragend', () => handleDragEnd(map))
//                     }}>
//                     {/* <Marker
//             position={mapOptions.center}
//             icon={{
//               url: iconMarkerMap,
//               scaledSize: new window.google.maps.Size(40, 40),
//               origin: new window.google.maps.Point(0, 0),
//               anchor: new waindow.google.maps.Point(16, 32),
//             }}
//           /> */}
//                     <Polyline
//                         path={pathCoordinates}
//                         options={{
//                             strokeColor: '#FF0000', // Specify polyline color
//                             strokeOpacity: 1.0, // Specify polyline opacity (0.0 to 1.0)
//                             strokeWeight: 4, // Specify polyline thickness
//                         }}
//                     />

//                     {isFullscreen ? (
//                         <div className="custom-fullscreen-icon" onClick={handleFullScreen}>
//                             <ButtonZoomFullscreen
//                                 position="RIGHT_BOTTOM"
//                                 handleZoomOut={handleZoomOut}
//                                 handleZoomIn={handleZoomIn}
//                             />
//                         </div>
//                     ) : (
//                         <>
//                             <div className="custom-zoom-controls">
//                                 <Button
//                                     disabled={zoom === 20 ? true : false}
//                                     className="btn-zoom-in"
//                                     onClick={() => handleZoomIn()}>
//                                     <img src={iconZoomIn} />
//                                 </Button>
//                                 <Button
//                                     disabled={zoom === 0 ? true : false}
//                                     className="btn-zoom-out"
//                                     onClick={() => handleZoomOut()}>
//                                     <img src={iconZoomOut} />
//                                 </Button>
//                             </div>
//                         </>
//                     )}
//                 </GoogleMap>
//             ) : (
//                 <div>Loading...</div>
//             )}
//         </div>
//     )
// }
var map = null
class MapFlightHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    // componentDidUpdate() {
    //     const {data} = this.props
    //     if (map != undefined) {
    //         map.remove()
    //     }
    //     if (
    //         data?.kafkaData[0]?.airline?.overview[0]?.position?.latitude != undefined &&
    //         data?.kafkaData[0]?.airline?.overview[0]?.position?.longitude != undefined
    //     ) {
    //         map = L.map('map').setView(
    //             [
    //                 data?.kafkaData[0]?.airline?.overview[0]?.position?.latitude,
    //                 data?.kafkaData[0]?.airline?.overview[0]?.position?.longitude,
    //             ],
    //             10,
    //         )

    //         var airline = []
    //         data?.kafkaData[0]?.airline?.overview.forEach((e, i) => {
    //             airline.push([e.position.latitude, e.position.longitude])
    //         })

    //         if (airline.length > 0) {
    //             var polyline = L.polyline(airline, {color: '#FFB800'}).addTo(map)
    //             map.fitBounds(polyline.getBounds())
    //         }

    //         var osm = L.tileLayer(`${config.google.MAP_VT}`, {
    //             maxZoom: 20,
    //             zoom: 16,
    //             attribution: '© OpenStreetMap',
    //         })

    //         map.addLayer(osm)
    //     } else {
    //         map = L.map('map').setView([14.1309086, 100.597907], 10)

    //         var osm = L.tileLayer(`${config.google.MAP_VT}`, {
    //             maxZoom: 20,
    //             zoom: 16,
    //             attribution: '© OpenStreetMap',
    //         })

    //         map.addLayer(osm)
    //     }
    // }

    componentDidMount() {
        const {data} = this.props
        if (map != undefined) {
            map.remove()
        }
        if (
            data?.kafkaData?.[0]?.airline?.overview[0]?.position?.latitude != undefined &&
            data?.kafkaData?.[0]?.airline?.overview[0]?.position?.longitude != undefined
        ) {
            map = L.map('map').setView(
                [
                    data?.kafkaData?.[0]?.airline?.overview[0]?.position?.latitude,
                    data?.kafkaData?.[0]?.airline?.overview[0]?.position?.longitude,
                ],
                10,
            )

            var airline = []
            data?.kafkaData?.[0]?.airline?.overview?.forEach((e, i) => {
                airline.push([e.position?.latitude, e.position?.longitude])
            })

            if (airline.length > 0) {
                var polyline = L.polyline(airline, {color: '#FFB800'}).addTo(map)
                map.fitBounds(polyline.getBounds())
            }

            var osm = L.tileLayer(`${config.google.MAP_VT}`, {
                maxZoom: 20,
                zoom: 16,
                attribution: '© OpenStreetMap',
            })

            map.addLayer(osm)
        } else {
            map = L.map('map').setView([14.1309086, 100.597907], 10)

            var osm = L.tileLayer(`${config.google.MAP_VT}`, {
                maxZoom: 20,
                zoom: 16,
                attribution: '© OpenStreetMap',
            })

            map.addLayer(osm)
        }
    }

    render() {
        return (
            <Row className="row row-map">
                <Col xl={12} lg={12}>
                    <div id="map"></div>
                </Col>
            </Row>
        )
    }
}

export default MapFlightHistory
