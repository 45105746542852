const userInalyze = {
    userInalyze: ' User iNalyze',
    totalUsers: 'จำนวนผู้ใช้งานทั้งหมด',
    newUsers: 'จำนวนผู้ใช้งานใหม่',
    totalJobs: 'จำนวนงานทั้งหมด',
    personUnit: 'คน',
    jobUnit: 'งาน',
    completedTasks: 'จำนวนงานที่สำเร็จ',
    ongoingTasks: 'จำนวนงานที่กำลังดำเนินการ',
    averageTasks: 'จำนวนงานเฉลี่ย',
    totalUsersMap: 'จำนวนผู้ใช้ทั้งหมด',
    numberOfFlightsTimes: 'จำนวนเที่ยวบิน (เที่ยว)',
    workingAreaRai: 'พื้นที่การทำงาน (ไร่)',
    yAxis: 'แกน Y',
    numberOfSprayingTimes: 'จำนวนการฉีดพ่น (เที่ยว)',
    numberOfSpreadingTimes: 'จำนวนการหว่าน (เที่ยว)',
    spreadingRateKgTai: 'อัตราการหว่าน (กก./ไร่)',
    sprayingRateLRai: 'อัตราการฉีดพ่น (ล./ไร่)',
    xAxis: 'แกน X',
    informationOnUsageAndUsageInEachArea: 'ข้อมูลผู้ใช้งานและการใช้งานแต่ละพื้นที่',
}

export default userInalyze
