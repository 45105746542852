import React, { Component } from "react";
import { Container, Row, Col, Button, Card, CardBody } from "reactstrap";
import "./index.scss";
import iconBeforeTenSec from "../../../../../assets/images/icon/icon-before-10-sec.png";
import iconAfterTenSec from "../../../../../assets/images/icon/icon-after-10-sec.png";
import iconPlay from "../../../../../assets/images/icon/icon-play-orange.png";
import iconPause from "../../../../../assets/images/icon/icon-pause.png";
import { Slider, Switch } from "antd";
import * as moment from "moment";
import { ceil } from "lodash";

class TabFlightHistoryPlaySlide extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      clickPlay: this.props.isPlay, 
      speed: 1, 
      mins: 0, 
      secs: 0, 
      maxMins: 0, 
      maxSecs: 0,
      percent: 0,
      isSetTime: false
    };

    this.onClickTogglePlay = this.onClickTogglePlay.bind(this);
    this.onClickSpeed = this.onClickSpeed.bind(this);
  }

  onClickTogglePlay = () => {
    this.setState(prevent => {
      return {
        ...prevent,
        clickPlay: !prevent.clickPlay,
      };
    });
  };

  onClickSpeed = () => {
    if (this.state.speed === 1) {
      this.setState({ speed: 2 });
    } else if (this.state.speed === 2) {
      this.setState({ speed: 4 });
    } else {
      this.setState({ speed: 1 });
    }
  };

  onClickBackWord = () => {
    console.log("click-back :>> ");
  };

  onClickForWord = () => {
    console.log("click-ForWord :>> ");
  };

  componentDidMount() {
    // console.log(this.props.data)
    this.setState({
      isSetTime: false,
      maxMins: parseInt(((this.props.data?.worktime / 1000) / 60)),
      maxSecs: parseInt(ceil((this.props.data?.worktime / 1000)) % 60)
    })
  }
  componentDidUpdate() {

    try {
      if (this.props.isPlay) {
        this.setState({
          mins: parseInt((this.props.indexWaypoint / 60)),
          secs: (this.props.indexWaypoint % 60),
          percent: (100  * (this.props.indexWaypoint * 1000) / this.props.data?.worktime)
        })
      }else{
        this.setState({
          maxMins: parseInt(((this.props.data?.worktime / 1000) / 60)),
          maxSecs: parseInt(ceil((this.props.data?.worktime / 1000)) % 60),
          isSetTime: true
        })
      }
    } catch (error) {

    }
  }

  render() {
    const state = this.state;

    return (
      <Row className="box-play">
        <Col lg={2} xl={2}>
          <div className="box-tools">
            <img src={iconBeforeTenSec} onClick={() => this.props.minus()}/>
            <img
              style={{ width: 20, height: 20 }}
              src={this.props.isPlay ? iconPause : iconPlay}
              onClick={() => this.props.play()}
            />
            <img style={{ marginRight: 20 }} src={iconAfterTenSec} onClick={() => this.props.plus()}/>
          </div>
        </Col>
        <Col lg={1} xl={1}>
          <div className="box-speed" onClick={() => this.props.speedChange()}>
            <p className="text-speed ">{this.props.speed}X</p>
          </div>
        </Col>
        <Col lg={9} xl={9}>
          <div className="box-slide">
            <p>
              {this.state.mins.toString().length == 1 ? '0' + this.state.mins : this.state.mins}
              :
              {this.state.secs.toString().length == 1 ? '0' + this.state.secs : this.state.secs}
            </p>
            <div className="" style={{ width: "600px" }}>
              <Slider
                defaultValue={0}
                value={this.state.percent}
                disabled={false}
                tooltip={{ open: false }}
                handleStyle={{ background: "#25C870" }}
                trackStyle={{ background: "#25C870" }}
                railStyle={{ background: "#E0E0E0" }}
              />
            </div>
            <p>
              {this.state.maxMins.toString().length == 1 ? '0' + this.state.maxMins : this.state.maxMins}
              :
              {this.state.maxSecs.toString().length == 1 ? '0' + this.state.maxSecs : this.state.maxSecs}
            </p>
          </div>
        </Col>
      </Row>
    );
  }
}
export default TabFlightHistoryPlaySlide;
