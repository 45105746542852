import React from 'react'
import '../skeleton-droneInformation.scss'
import {Row, Col, Card, CardText, CardImgOverlay, CardTitle, CardBody} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonDataDroneInformation = () => {
    return (
        <Col xl={4}>
            <Card className="skeleton-card-data-drone" style={{boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 4px 0px'}}>
                <Skeleton className="skeleton-image" />
                <CardImgOverlay>
                    <Row className="d-flex align-items-start mb-3">
                        <Col xs="5" className="align-self-start"></Col>

                        <Col xs="7">
                            <CardTitle className="mt-0" style={{paddingTop: 20}}>
                                <Skeleton width={180} height={30} />
                            </CardTitle>
                            <Skeleton width={230} height={20} />
                            <Skeleton width={150} height={20} />
                        </Col>
                    </Row>
                </CardImgOverlay>
            </Card>
            <Card style={{height: 280}}>
                <CardBody>
                    <CardTitle
                        tag="h5"
                        className="card-title mb-3 me-2"
                        style={{
                            fontSize: 20,
                            fontWeight: 600,
                            fontFamily: 'Kanit',
                        }}>
                        <Skeleton width={230} height={20} />
                    </CardTitle>
                    <div className="row type-drone">
                        {/* table-nowrap mb-0 */}
                        <table className="table align-middle white-bg">
                            <tbody>
                                <tr scope="row">
                                    <td style={{width: '30%'}}>
                                        <Skeleton width={50} height={20} />
                                    </td>
                                    <td style={{width: '70%'}} className="">
                                        <Skeleton width={200} height={20} />
                                    </td>
                                </tr>
                                <tr scope="row">
                                    <td style={{width: '30%'}}>
                                        <Skeleton width={80} height={20} />
                                    </td>
                                    <td style={{width: '70%'}} className="">
                                        <Skeleton width={120} height={20} />
                                    </td>
                                </tr>
                                <tr scope="row">
                                    <td style={{width: '30%'}}>
                                        <Skeleton width={50} height={20} />
                                    </td>
                                    <td style={{width: '70%'}} className="">
                                        <Skeleton width={200} height={20} />
                                    </td>
                                </tr>
                                <tr scope="row">
                                    <td style={{width: '30%'}}>
                                        <Skeleton width={50} height={20} />
                                    </td>
                                    <td style={{width: '70%'}} className="">
                                        <Skeleton width={250} height={20} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}

export default SkeletonDataDroneInformation
