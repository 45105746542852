import React, {useEffect, useState} from 'react'
import {Row, Col, Card, CardBody, Form, TabContent, TabPane} from 'reactstrap'
import {useRecoilState, useSetRecoilState} from 'recoil'
import {withTranslation} from 'react-i18next'
import {Space, Select} from 'antd'
const {Option} = Select

import dayjs from 'dayjs'
import 'dayjs/locale/th'

import {buttonFilterTimeRange, optionsCountry} from 'constants/lookup'
import {filterUserInalyzeState, loadingUserInalyzeState} from 'store/atoms/user-inalyze'
import {getProvince, getFilterDistrict} from 'services/skc/lookup'

import {SelectWrapper, SelectButton} from '../component/MyStyle'
import WeekPicker from '../component/weekPicker'
import MonthPicker from '../component/monthPicker'
import YearPicker from '../component/yearPicker'
import NavbarSkeleton from '../CardSkeleton/NavbarSkeleton'

const SelectNavBar = props => {
    const {t, i18n} = props

    const [activeTab, setActiveTab] = useState('year')

    const [filterUserInalyze, setFilterUserInalyze] = useRecoilState(filterUserInalyzeState)
    const setLoading = useSetRecoilState(loadingUserInalyzeState)

    const [date, setDate] = useState(dayjs())

    const [lutProvince, setLutProvince] = useState([])
    const [lutDistrict, setLutDistrict] = useState([])

    const [selectCountry, setSelectCountry] = useState('all')
    const [selectProvince, setSelectProvince] = useState([])
    const [selectDistrict, setSelectDistrict] = useState([])

    const [disableProvince, setDisableProvince] = useState(false)
    const [disableDistrict, setDisableDistrict] = useState(false)
    const [Skeleton, setSkeleton] = useState(false)

    useEffect(() => {
        setSkeleton(true)

        setTimeout(() => {
            setSkeleton(false)
        }, 3000)
    }, [])

    useEffect(() => {
        return () => {
            setFilterUserInalyze({
                type: 'year',
                province: [],
                district: [],
                country: 'all',
                week: dayjs().week(),
                month: dayjs().month() + 1,
                year: dayjs().year(),
                start_date: dayjs().startOf('year').format('YYYY-MM-DD'),
                end_date: dayjs().endOf('year').format('YYYY-MM-DD'),
            })
        }
    }, [])

    const toggleTab = tab => {
        setActiveTab(tab)
    }

    const handleChangeTime = date => {
        setDate(date)
    }

    const onChangeCountry = value => {
        setSelectCountry(value)

        if (value === 'th') {
            setDisableProvince(false)

            setDisableDistrict(false)
        } else {
            setDisableProvince(true)

            setDisableDistrict(true)
        }
    }

    const onChangeProvince = value => {
        setSelectProvince(value)

        if (value?.length < 1) {
            setDisableDistrict(true)
            setSelectDistrict([])
        } else {
            const paramsData = {province: [...value]}
            setDisableDistrict(false)
            fetchFilterDistrict(paramsData)
        }
    }

    const onDeSelectProvince = value => {
        if (value.length < 1) {
            setDisableDistrict(true)
            setSelectProvince([])
            setSelectDistrict([])
            fetchFilterDistrict({province: []})
        }
    }

    useEffect(() => {
        fetchProvince()
    }, [])

    const fetchProvince = async () => {
        try {
            const response = await getProvince()

            if (response.status_code === 200 && response?.results?.length > 0) {
                setLutProvince(response?.results)
            }
        } catch (error) {
            setLutProvince([])
        }
    }

    const fetchFilterDistrict = async id => {
        try {
            const response = await getFilterDistrict(id)

            if (response.status_code === 200 && response?.results?.data?.length > 0) {
                setLutDistrict(response?.results?.data)
            }
        } catch (error) {
            setLutDistrict([])
        }
    }

    const onChangeDistrict = value => {
        setSelectDistrict(value)
    }

    const handleChangeSubmit = () => {
        let params = {
            ...filterUserInalyze,
            type: activeTab,
            province: selectProvince,
            district: selectDistrict,
            country: selectCountry,
        }

        if (activeTab === 'year') {
            params = {
                ...params,
                week: dayjs(date).week(),
                month: dayjs(date).month() + 1,
                year: dayjs(date).year(),
                start_date: dayjs(date).startOf('year').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('year').format('YYYY-MM-DD'),
            }
        }
        if (activeTab === 'month') {
            params = {
                ...params,
                week: dayjs(date).week(),
                month: dayjs(date).month() + 1,
                year: dayjs(date).year(),
                start_date: dayjs(date).startOf('month').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('month').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'week') {
            params = {
                ...params,
                week: dayjs(date).week(),
                month: dayjs(date).month() + 1,
                year: dayjs(date).year(),
                start_date: dayjs(date).startOf('week').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('week').format('YYYY-MM-DD'),
            }
        }

        setFilterUserInalyze(params)
        setLoading({
            map: true,
            widget: true,
        })
    }

    return (
        <React.Fragment>
            <Row>
                {Skeleton ? (
                    <NavbarSkeleton />
                ) : (
                    <Col lg="12">
                        <Card
                            style={{
                                borderRadius: 8,
                            }}>
                            <CardBody>
                                <SelectWrapper>
                                    {buttonFilterTimeRange?.map((item, index) => {
                                        return (
                                            item.value != 'quarter' &&
                                            item.value != 'halfYear' &&
                                            item.value != 'period' && (
                                                <SelectButton
                                                    key={index}
                                                    color={activeTab == item.value ? '#fff' : '#495057'}
                                                    backgroundColor={activeTab == item.value ? '#79829C' : '#fff'}
                                                    onClick={() => toggleTab(item.value)}>
                                                    {i18n.language === 'en' ? item.title_en : item.title_th}
                                                </SelectButton>
                                            )
                                        )
                                    })}
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="week">
                                            <WeekPicker value={date} onChange={handleChangeTime} />
                                        </TabPane>
                                        <TabPane tabId="month">
                                            <MonthPicker value={date} onChange={handleChangeTime} />
                                        </TabPane>
                                        <TabPane tabId="year">
                                            <YearPicker value={date} onChange={handleChangeTime} />
                                        </TabPane>
                                    </TabContent>
                                </SelectWrapper>

                                <Form
                                    className="row gy-2 gx-3 align-items-center"
                                    style={{
                                        paddingTop: 13,
                                    }}>
                                    <Row>
                                        <Col xl={2} style={{display: 'flex', alignItems: 'center', height: '50px'}}>
                                            <span
                                                className="mt-1"
                                                style={{
                                                    fontFamily: 'Kanit',
                                                    fontWeight: 400,
                                                    fontSize: 14,
                                                    paddingRight: 16,
                                                }}>
                                                {t('common.country')}:
                                            </span>
                                            <Select
                                                style={{
                                                    width: '80%',
                                                    borderColor: '#CED4DA',
                                                    borderRadius: 8,
                                                    color: '#495057',
                                                }}
                                                placeholder={t(`common.all`)}
                                                value={selectCountry}
                                                onChange={onChangeCountry}
                                                className="ms-3">
                                                {optionsCountry?.map((option, index) => (
                                                    <Option key={index} value={option.value} label={option.label}>
                                                        {i18n.language === 'th' ? option.label_th : option.label_en}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col xl={5} style={{display: 'flex', alignItems: 'center', height: '50px'}}>
                                            <span
                                                className="mt-1"
                                                style={{
                                                    fontFamily: 'Kanit',
                                                    fontWeight: 400,
                                                    fontSize: 14,
                                                    width: '80px',
                                                }}>
                                                {t('common.province')} :
                                            </span>
                                            <Select
                                                className=" container-select-multiple"
                                                mode="multiple"
                                                style={{width: '100%', paddingRight: 15}}
                                                placeholder={t('common.all')}
                                                value={selectProvince}
                                                onChange={onChangeProvince}
                                                onDeselect={onDeSelectProvince}
                                                disabled={disableProvince}
                                                showSearch
                                                optionFilterProp="label">
                                                {lutProvince?.map((item, index) => (
                                                    <Option
                                                        key={index}
                                                        value={item.value}
                                                        label={i18n.language === 'en' ? item.label_en : item.label}>
                                                        <Space>
                                                            {i18n.language === 'en' ? item.label_en : item.label}
                                                        </Space>
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col xl={5} style={{display: 'flex', alignItems: 'center', height: '50px'}}>
                                            <span
                                                className="mt-1"
                                                style={{
                                                    fontFamily: 'Kanit',
                                                    fontWeight: 400,
                                                    fontSize: 14,
                                                    width: '80px',
                                                }}>
                                                {t('common.district')} :
                                            </span>
                                            <Select
                                                className=" container-select-multiple"
                                                mode="multiple"
                                                style={{paddingRight: 15, width: '60%'}}
                                                placeholder={t('common.all')}
                                                value={selectDistrict}
                                                onChange={onChangeDistrict}
                                                disabled={disableDistrict}
                                                showSearch
                                                optionFilterProp="label">
                                                {lutDistrict?.map((item, index) => (
                                                    <Option
                                                        key={index}
                                                        value={item.id}
                                                        label={i18n.language === 'en' ? item.name_en : item.name_th}>
                                                        <Space>
                                                            {i18n.language === 'en' ? item.name_en : item.name_th}
                                                        </Space>
                                                    </Option>
                                                ))}
                                            </Select>
                                            <button
                                                onClick={handleChangeSubmit}
                                                type="button"
                                                className="btn btn-primary w-xs"
                                                style={{
                                                    borderColor: '#00A69C',
                                                    borderRadius: 8,
                                                    backgroundColor: '#00A69C',
                                                    marginTop: -3,
                                                }}>
                                                {t('common.searchInformation')}
                                            </button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                )}
            </Row>
        </React.Fragment>
    )
}

export default withTranslation()(SelectNavBar)
