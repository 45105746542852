import React from 'react'
import '../skeleton-droneInformation.scss'
import {Row, Card, CardBody, CardTitle} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonSpeedDrone = () => {
    return (
        <Card className="skeleton-card-speed-drone-used">
            <CardTitle className="card-title">
                <Skeleton width={100} height={30} />
                <div className="container-detail">
                    <div className="box-detail-data">
                        <Skeleton width={70} height={23} />

                        <div className="box-value">
                            <Skeleton width={12} height={18} style={{marginRight: 6}} />
                            <Skeleton width={21} height={18} />
                        </div>
                    </div>
                    <div className="box-detail-data">
                        <Skeleton width={70} height={23} />

                        <div className="box-value">
                            <Skeleton width={12} height={18} style={{marginRight: 6}} />
                            <Skeleton width={21} height={18} />
                        </div>
                    </div>
                    <div className="box-detail-data">
                        <Skeleton width={70} height={23} />

                        <div className="box-value">
                            <Skeleton width={12} height={18} style={{marginRight: 6}} />
                            <Skeleton width={21} height={18} />
                        </div>
                    </div>
                </div>
            </CardTitle>
            <CardBody className="result-data">
                <Row
                    style={{
                        margin: 0,
                        padding: 0,
                        width: '100%',
                        height: 360,
                        paddingLeft: 10,
                        paddingRight: 15,
                    }}>
                    <Skeleton width={'100%'} height={350} style={{borderRadius: 6}} />
                </Row>
            </CardBody>
        </Card>
    )
}

export default SkeletonSpeedDrone
