import React, {useEffect, useState} from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import iconSort from '../../assets/images/icon/sort.png'
import {Dropdown} from 'antd'
import 'react-perfect-scrollbar/dist/css/styles.css'
import {Col, Row} from 'reactstrap'
import './style-table.scss'

import {withTranslation} from 'react-i18next'
import ProgressBar from './ProgressBar'

const TableMap = props => {
    const {
        data,
        percentChart,
        title,
        unit,
        t,
        i18n,
        tabData,
        max,
        rowRefs,
        refDivRows,
        filter,
        sortFilterBtn,
        sortDataButton,
        percentHeatMap,
    } = props

    const [openMenuSort, setOpenMenuSort] = useState(false)

    const [sortData, setSortData] = useState([])

    const [language, setLanguage] = useState(i18n.language)
    const [sortType, setSortType] = useState('character')

    const menuSort = [
        {
            label: t(`common.character`),
            key: 'character',
        },
        {
            label: t(`common.maximum`),
            key: 'max',
        },
        {
            label: t(`common.minimum`),
            key: 'min',
        },
    ]

    useEffect(() => {
        if (data?.length > 0 || i18n.language !== language) {
            sortDataTable(sortType, sortDataButton)
            setLanguage(i18n.language)
        }
    }, [data, i18n.language, sortType])

    const handleOpenMenuSort = open => {
        setOpenMenuSort(open)
    }

    const sortDataTable = (sort, sortUnit) => {
        const newData = []

        const dataNotSpecified = data?.filter(item => item?.id === 'other') || []
        const dataCountry = data?.filter(item => item?.code_jvector === 'LA' || item?.code_jvector === 'KH') || []
        const dataSort =
            data?.filter(
                item =>
                    !item?.code_jvector.includes('LA') &&
                    !item?.code_jvector.includes('KH') &&
                    !item?.id.includes('other'),
            ) || []

        if (sort === 'character') {
            const sortData =
                dataSort.sort((a, b) =>
                    i18n.language === 'en'
                        ? a?.name?.localeCompare(b?.name, 'en')
                        : a?.name?.localeCompare(b?.name, 'th'),
                ) || []
            newData.push(...dataCountry, ...dataNotSpecified, ...sortData)
        }

        if (sort === 'max' && sortUnit === 'UNIT') {
            const sortDataMax = dataSort.sort((a, b) => b.total - a.total)

            newData.push(...dataCountry, ...dataNotSpecified, ...sortDataMax)
        }

        if (sort === 'max' && sortUnit !== 'UNIT') {
            const sortDataMax = dataSort.sort((a, b) => b.percent - a.percent)

            newData.push(...dataCountry, ...dataNotSpecified, ...sortDataMax)
        }

        if (sort === 'min' && sortUnit === 'UNIT') {
            const sortDataMin = dataSort.sort((a, b) => a.total - b.total)
            newData.push(...dataCountry, ...dataNotSpecified, ...sortDataMin)
        }

        if (sort === 'min' && sortUnit !== 'UNIT') {
            const sortDataMin = dataSort.sort((a, b) => a.percent - b.percent)
            newData.push(...dataCountry, ...dataNotSpecified, ...sortDataMin)
        }

        setSortData(newData)
        setOpenMenuSort(false)
    }
    const handleSort = sort => {
        setSortType(sort.key)
    }

    useEffect(() => {
        data?.forEach((_, index) => {
            rowRefs[index] = React.createRef()

            refDivRows[index] = React.createRef()
        })
    }, [data, rowRefs, refDivRows])

    const handleSortDataButton = data => {
        sortFilterBtn(data)
    }

    return (
        <Row className="data-table">
            <Row className="container-header">
                <Col xl={3} style={{padding: 0, margin: 0}}>
                    <p className="title">{title || '-'}</p>
                </Col>
                <Col xl={5} style={{padding: 0, margin: 0}}>
                    <div className="container-percent-data">
                        {sortDataButton !== 'UNIT' ? (
                            <div className="box-gradient">
                                <div className="gradient"></div>
                                <div className="box-percent-text">
                                    <p className="percent-minus">{percentHeatMap?.minPercent}%</p>
                                    <p>0%</p>
                                    <p className="percent-plus ">+{percentHeatMap?.maxPercent}%</p>
                                </div>
                            </div>
                        ) : (
                            <>
                                {percentChart?.map(item => (
                                    <React.Fragment key={item.index}>
                                        {item.index === -4 && (
                                            <div className="group-percent">
                                                <div className="box-data-minus-4"></div>
                                                <div className="title-percent">
                                                    {item?.min.toLocaleString()}% - {item?.max.toLocaleString()}%
                                                </div>
                                            </div>
                                        )}
                                        {item.index === -3 && (
                                            <div className="group-percent">
                                                <div className="box-data-minus-3"></div>
                                                <div className="title-percent">
                                                    {item?.min.toLocaleString()}% - {item?.max.toLocaleString()}%
                                                </div>
                                            </div>
                                        )}
                                        {item.index === -2 && (
                                            <div className="group-percent">
                                                <div className="box-data-minus-2"></div>
                                                <div className="title-percent">
                                                    {item?.min.toLocaleString()}% - {item?.max.toLocaleString()}%
                                                </div>
                                            </div>
                                        )}
                                        {item.index === -1 && (
                                            <div className="group-percent">
                                                <div className="box-data-minus-1"></div>
                                                <div className="title-percent">
                                                    {item?.min.toLocaleString()}% - {item?.max.toLocaleString()}%
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            {item.index === 0 && (
                                                <div className="group-percent">
                                                    <div className="box-data-0"></div>
                                                    <div className="title-percent">{item?.min.toLocaleString()}%</div>
                                                </div>
                                            )}
                                            {item.index === 1 && (
                                                <div className="group-percent">
                                                    <div className="box-data-25"></div>
                                                    <div className="title-percent">
                                                        {item?.min.toLocaleString()}% - {item?.max.toLocaleString()}%
                                                    </div>
                                                </div>
                                            )}
                                            {item.index === 2 && (
                                                <div className="group-percent">
                                                    <div className="box-data-50"></div>
                                                    <div className="title-percent">
                                                        {item?.min.toLocaleString()}% - {item?.max.toLocaleString()}%
                                                    </div>
                                                </div>
                                            )}
                                            {item.index === 3 && (
                                                <div className="group-percent">
                                                    <div className="box-data-75"></div>
                                                    <div className="title-percent">
                                                        {item?.min.toLocaleString()}% - {item?.max.toLocaleString()}%
                                                    </div>
                                                </div>
                                            )}
                                            {item.index === 4 && (
                                                <div className="group-percent">
                                                    <div className="box-data-100"></div>
                                                    <div className="title-percent">
                                                        {item?.min.toLocaleString()}% - {item?.max.toLocaleString()}%
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </React.Fragment>
                                ))}
                            </>
                        )}
                    </div>
                </Col>
                <Col xl={4} style={{padding: 0, margin: 0}}>
                    <div className="container-sort-data">
                        <div className="box-btn">
                            <button
                                onClick={() => handleSortDataButton('UNIT')}
                                type="button"
                                className={'btn  rainbow-1 btn-select-type'}
                                style={{
                                    fontWeight: 600,
                                    color: sortDataButton === 'UNIT' ? '#4965EA' : '#495057',
                                    borderColor: sortDataButton === 'UNIT' ? '#4965EA' : '#fff',
                                    backgroundColor: sortDataButton === 'UNIT' ? '#E8ECFF' : '#EFF0F2',
                                }}>
                                Unit
                            </button>
                            <button
                                disabled={filter.type === 'week' || filter.type === 'year' || filter.type === 'period'}
                                onClick={() => handleSortDataButton('MTD')}
                                type="button"
                                className={'btn   rainbow-1 btn-select-type'}
                                style={{
                                    fontWeight: 600,
                                    color: sortDataButton === 'MTD' ? '#4965EA' : '#495057',
                                    borderColor: sortDataButton === 'MTD' ? '#4965EA' : '#fff',
                                    backgroundColor: sortDataButton === 'MTD' ? '#E8ECFF' : '#EFF0F2',
                                }}>
                                MTD
                            </button>
                            <button
                                disabled={filter.type === 'week' || filter.type === 'month' || filter.type === 'period'}
                                onClick={() => handleSortDataButton('YTD')}
                                type="button"
                                className={'btn  rainbow-1 btn-select-type'}
                                style={{
                                    fontWeight: 600,
                                    color: sortDataButton === 'YTD' ? '#4965EA' : '#495057',
                                    borderColor: sortDataButton === 'YTD' ? '#4965EA' : '#fff',
                                    backgroundColor: sortDataButton === 'YTD' ? '#E8ECFF' : '#EFF0F2',
                                }}>
                                YTD
                            </button>
                        </div>

                        <div className="btn-sort">
                            <Dropdown
                                menu={{
                                    items: menuSort,
                                    onClick: handleSort,
                                }}
                                onOpenChange={handleOpenMenuSort}
                                open={openMenuSort}
                                placement="bottomLeft">
                                <a onClick={e => e.preventDefault()}>
                                    <img src={iconSort} alt="" height="26" />
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <PerfectScrollbar style={{height: '500px'}}>
                    <>
                        {sortData?.length > 0 ? (
                            <>
                                {sortData?.map((item, index) => {
                                    let percentColor = ''
                                    if (item?.v === -4) {
                                        percentColor = '#FF1515'
                                    }

                                    if (item?.v === -3) {
                                        percentColor = '#FF5050'
                                    }

                                    if (item?.v === -2) {
                                        percentColor = '#FF8A8A'
                                    }

                                    if (item?.v === -1) {
                                        percentColor = '#FFC5C5'
                                    }

                                    if (item?.v === 0) {
                                        percentColor = '#EAEBEA'
                                    }

                                    if (item?.v === 1) {
                                        percentColor = '#B2E8CB'
                                    }

                                    if (item?.v === 2) {
                                        percentColor = '#5CD295'
                                    }

                                    if (item?.v === 3) {
                                        percentColor = '#1ABF69'
                                    }

                                    if (item?.v === 4) {
                                        percentColor = '#17834a'
                                    }

                                    if (item?.id === 'other') {
                                        percentColor = '#D6DDE4'
                                    }

                                    return (
                                        <div
                                            id={item.code_jvector}
                                            ref={refDivRows[index]}
                                            className="row-data-drone-table"
                                            key={`${index.toString()}-${item}`}
                                            // onClick={() => handleClickRowTable(item?.code_jvector)}
                                        >
                                            <Row>
                                                <Col xl={2}>
                                                    <p
                                                        id={item.code_jvector}
                                                        ref={rowRefs[index]}
                                                        className="title-name">
                                                        {item?.name}
                                                    </p>
                                                </Col>
                                                <Col xl={10}>
                                                    <ProgressBar
                                                        data={item?.total}
                                                        percent={item?.percent}
                                                        color={percentColor}
                                                        unit={unit}
                                                        tab={tabData}
                                                        typeNotSpecified={item?.id === 'other' ? true : false}
                                                        progress={
                                                            ((Number(max) + Number(item.total) - Number(max)) * 100) /
                                                                Number(max) <
                                                            0
                                                                ? 0
                                                                : ((Number(max) + Number(item.total) - Number(max)) *
                                                                      100) /
                                                                  Number(max)
                                                        }
                                                        difference={item?.difference}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })}
                            </>
                        ) : (
                            <Row className="row-data-drone-table">
                                <Col xl={12}>
                                    <p className="title-name">{t('common.noData')}</p>
                                </Col>
                            </Row>
                        )}
                    </>
                </PerfectScrollbar>
            </Row>
        </Row>
    )
}

export default withTranslation()(TableMap)
