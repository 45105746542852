import React, {useEffect, useState} from 'react'
import './index.scss'
import {Select, Space, Radio} from 'antd'
import {Row, Col, Button, Form, FormGroup, TabContent, TabPane, Label} from 'reactstrap'

const {Option} = Select

import SelectDatePickerMultiple from './SelectDatePickerMultiple'
import FormTimeSkeleton from 'components/DroneiNalyze/Skeleton-iNalyze/Comparison/FormTime/FormTimeSkeleton'
import {withTranslation} from 'react-i18next'
import {optCompareDataTime, optCompareTimeType} from 'constants/lookup'
import {getFilterDrone} from 'services/skc/lookup'
import {useRecoilState, useSetRecoilState} from 'recoil'
import {formCompareTimeState, loadingInalyzeState} from 'store/atoms/inalyze'

const LutOptionsQuarter = [
    {
        label: 'Q1',
        value: 'Q1',
        disabled: false,
    },
    {
        label: 'Q2',
        value: 'Q2',
        disabled: false,
    },
    {
        label: 'Q3',
        value: 'Q3',
        disabled: false,
    },
    {
        label: 'Q4',
        value: 'Q4',
        disabled: false,
    },
]

const FormTime = props => {
    const {t, i18n, onClickClearSearchTime, onClickSearchTime} = props

    const setOptYear = () => {
        let startYear = 2020
        const currentYear = new Date().getFullYear()
        const optionYear = []
        while (startYear <= currentYear) {
            let year = startYear++
            optionYear.push({label: year, value: year, disable: false})
        }
        return optionYear
    }

    const [compareData, setCompareData] = useState('')
    const [selectDrones, setSelectDrones] = useState([])
    const [language, setLanguage] = useState(i18n.language)

    const [loading, setLoading] = useRecoilState(loadingInalyzeState)
    const setFormCompareTime = useSetRecoilState(formCompareTimeState)

    const [lutDrones, setLutDrones] = useState([])
    const [optionsHalfYear, setOptionsHalfYear] = useState([
        {label: t('inalyze.firstHalfYear'), value: 1, disabled: false},
        {label: t('inalyze.secondHalfYear'), value: 2, disabled: false},
    ])
    const [optionsMonth, setOptionsMonth] = useState([
        {
            label: t('common.janMonth'),
            value: 1,
            disabled: false,
        },
        {
            label: t('common.febMonth'),
            value: 2,
            disabled: false,
        },
        {
            label: t('common.marMonth'),
            value: 3,
            disabled: false,
        },
        {
            label: t('common.aprMonth'),
            value: 4,
            disabled: false,
        },
        {
            label: t('common.mayMonth'),
            value: 5,
            disabled: false,
        },
        {
            label: t('common.junMonth'),
            value: 6,
            disabled: false,
        },
        {
            label: t('common.julMonth'),
            value: 7,
            disabled: false,
        },
        {
            label: t('common.augMonth'),
            value: 8,
            disabled: false,
        },
        {
            label: t('common.sepMonth'),
            value: 9,
            disabled: false,
        },
        {
            label: t('common.octMonth'),
            value: 10,
            disabled: false,
        },
        {
            label: t('common.novMonth'),
            value: 11,
            disabled: false,
        },
        {
            label: t('common.decMonth'),
            value: 12,
            disabled: false,
        },
    ])
    const [optionsQuarter, setOptionsQuarter] = useState(LutOptionsQuarter)
    const [optionYear, setOptionYear] = useState(setOptYear())

    const [timeGroup1, setTimeGroup1] = useState({
        timeType: 'year',
        year: [],
        month: [],
        quarter: [],
        halfYear: [],
    })

    const [timeGroup2, setTimeGroup2] = useState({
        timeType: 'month',
        year: [],
        quarter: [],
        month: [],
        halfYear: null,
    })

    useEffect(() => {
        if (i18n.language !== language) {
            setLanguage(i18n.language)
            setOptionsHalfYear([
                {label: t('inalyze.firstHalfYear'), value: 1, disabled: false},
                {label: t('inalyze.secondHalfYear'), value: 2, disabled: false},
            ])
            setOptionsMonth([
                {
                    label: t('common.janMonth'),
                    value: 1,
                    disabled: false,
                },
                {
                    label: t('common.febMonth'),
                    value: 2,
                    disabled: false,
                },
                {
                    label: t('common.marMonth'),
                    value: 3,
                    disabled: false,
                },
                {
                    label: t('common.aprMonth'),
                    value: 4,
                    disabled: false,
                },
                {
                    label: t('common.mayMonth'),
                    value: 5,
                    disabled: false,
                },
                {
                    label: t('common.junMonth'),
                    value: 6,
                    disabled: false,
                },
                {
                    label: t('common.julMonth'),
                    value: 7,
                    disabled: false,
                },
                {
                    label: t('common.augMonth'),
                    value: 8,
                    disabled: false,
                },
                {
                    label: t('common.sepMonth'),
                    value: 9,
                    disabled: false,
                },
                {
                    label: t('common.octMonth'),
                    value: 10,
                    disabled: false,
                },
                {
                    label: t('common.novMonth'),
                    value: 11,
                    disabled: false,
                },
                {
                    label: t('common.decMonth'),
                    value: 12,
                    disabled: false,
                },
            ])
        }
    }, [i18n.language])

    const onSelectCompare = option => {
        setCompareData(option)
    }

    useEffect(() => {
        fetchDrone()
    }, [])

    const fetchDrone = async () => {
        try {
            const response = await getFilterDrone()

            if (response.status_code === 200 && response.results.length > 0) {
                setLutDrones(response.results)
            }
        } catch (error) {
            setLutDrones([])
        }
    }

    const onSelectDrone = (data, option) => {
        setSelectDrones(data)
    }

    const onSelectTimeType = (timeType, stateName) => {
        if (timeType && stateName === 'timeGroup1') {
            setTimeGroup1(cur => ({...cur, timeType, year: [], quarter: [], month: [], halfYear: []}))
            setTimeGroup2(cur => ({...cur, year: [], quarter: [], month: [], halfYear: null}))
            setOptionsHalfYear([
                {label: t('inalyze.firstHalfYear'), value: 1, disabled: false},
                {label: t('inalyze.secondHalfYear'), value: 2, disabled: false},
            ])
            setOptionsMonth([
                {
                    label: t('common.janMonth'),
                    value: 1,
                    disabled: false,
                },
                {
                    label: t('common.febMonth'),
                    value: 2,
                    disabled: false,
                },
                {
                    label: t('common.marMonth'),
                    value: 3,
                    disabled: false,
                },
                {
                    label: t('common.aprMonth'),
                    value: 4,
                    disabled: false,
                },
                {
                    label: t('common.mayMonth'),
                    value: 5,
                    disabled: false,
                },
                {
                    label: t('common.junMonth'),
                    value: 6,
                    disabled: false,
                },
                {
                    label: t('common.julMonth'),
                    value: 7,
                    disabled: false,
                },
                {
                    label: t('common.augMonth'),
                    value: 8,
                    disabled: false,
                },
                {
                    label: t('common.sepMonth'),
                    value: 9,
                    disabled: false,
                },
                {
                    label: t('common.octMonth'),
                    value: 10,
                    disabled: false,
                },
                {
                    label: t('common.novMonth'),
                    value: 11,
                    disabled: false,
                },
                {
                    label: t('common.decMonth'),
                    value: 12,
                    disabled: false,
                },
            ])
            setOptionYear(setOptYear())
            setOptionsQuarter(LutOptionsQuarter)
        }
        if (timeType && stateName === 'timeGroup2') {
            const timeType1 = timeGroup1?.timeType

            if (timeType1 === 'quarter') {
                const quarterItem = timeGroup1.quarter
                const Q1Value = quarterItem.find(element => element.quarter === 'Q1')
                const Q2Value = quarterItem.find(element => element.quarter === 'Q2')
                const Q3Value = quarterItem.find(element => element.quarter === 'Q3')
                const Q4Value = quarterItem.find(element => element.quarter === 'Q4')
                const quarterYearValue = quarterItem.map(element => element.year)
                if (timeType === 'halfYear') {
                    const disabledHalfYear1 = Q1Value || Q2Value ? false : true
                    const disabledHalfYear2 = Q3Value || Q4Value ? false : true
                    const arrayOptionHalfYear = [
                        {label: t('inalyze.firstHalfYear'), value: 1, disabled: false},
                        {label: t('inalyze.secondHalfYear'), value: 2, disabled: false},
                    ]
                    const newOptHalfYear = arrayOptionHalfYear.map(item => {
                        if (item.value === 1) {
                            return {...item, disabled: disabledHalfYear1}
                        }
                        if (item.value === 2) {
                            return {...item, disabled: disabledHalfYear2}
                        }
                    })
                    setOptionsHalfYear(newOptHalfYear)
                }

                if (timeType === 'month') {
                    const disableMonthQ1 = Q1Value ? false : true
                    const disableMonthQ2 = Q2Value ? false : true
                    const disableMonthQ3 = Q3Value ? false : true
                    const disableMonthQ4 = Q4Value ? false : true
                    const arrayOptionMonth = [
                        {
                            label: t('common.janMonth'),
                            value: 1,
                            disabled: false,
                        },
                        {
                            label: t('common.febMonth'),
                            value: 2,
                            disabled: false,
                        },
                        {
                            label: t('common.marMonth'),
                            value: 3,
                            disabled: false,
                        },
                        {
                            label: t('common.aprMonth'),
                            value: 4,
                            disabled: false,
                        },
                        {
                            label: t('common.mayMonth'),
                            value: 5,
                            disabled: false,
                        },
                        {
                            label: t('common.junMonth'),
                            value: 6,
                            disabled: false,
                        },
                        {
                            label: t('common.julMonth'),
                            value: 7,
                            disabled: false,
                        },
                        {
                            label: t('common.augMonth'),
                            value: 8,
                            disabled: false,
                        },
                        {
                            label: t('common.sepMonth'),
                            value: 9,
                            disabled: false,
                        },
                        {
                            label: t('common.octMonth'),
                            value: 10,
                            disabled: false,
                        },
                        {
                            label: t('common.novMonth'),
                            value: 11,
                            disabled: false,
                        },
                        {
                            label: t('common.decMonth'),
                            value: 12,
                            disabled: false,
                        },
                    ]
                    const newOptMonth = arrayOptionMonth.map(item => {
                        if (item.value <= 3) {
                            return {...item, disabled: disableMonthQ1}
                        }

                        if (item.value <= 6) {
                            return {...item, disabled: disableMonthQ2}
                        }

                        if (item.value <= 9) {
                            return {...item, disabled: disableMonthQ3}
                        }

                        if (item.value <= 12) {
                            return {...item, disabled: disableMonthQ4}
                        }
                    })

                    setOptionsMonth(newOptMonth)
                }

                if (timeType === 'year') {
                    const newOptYear = setOptYear().map(item => {
                        const checkDisable = quarterYearValue.find(year => item.value === year) ? false : true

                        return {
                            ...item,
                            disable: checkDisable,
                        }
                    })

                    setOptionYear(newOptYear)
                }
            }
            if (timeType1 === 'halfYear') {
                const halfYearItem = timeGroup1.halfYear
                const halfYearYearValue = halfYearItem.map(element => element.year)

                const halfYearValue1 = halfYearItem.find(element => element.halfYear === 1)
                const halfYearValue2 = halfYearItem.find(element => element.halfYear === 2)

                if (timeType == 'year') {
                    const newOptYear = setOptYear().map(item => {
                        const checkDisable = halfYearYearValue.find(year => item.value === year) ? false : true

                        return {
                            ...item,
                            disable: checkDisable,
                        }
                    })

                    setOptionYear(newOptYear)
                }

                if (timeType === 'month') {
                    const disableMonthHalfYear1 = halfYearValue1 ? false : true
                    const disableMonthHalfYear2 = halfYearValue2 ? false : true
                    const arrayOptionMonth = [
                        {
                            label: t('common.janMonth'),
                            value: 1,
                            disabled: false,
                        },
                        {
                            label: t('common.febMonth'),
                            value: 2,
                            disabled: false,
                        },
                        {
                            label: t('common.marMonth'),
                            value: 3,
                            disabled: false,
                        },
                        {
                            label: t('common.aprMonth'),
                            value: 4,
                            disabled: false,
                        },
                        {
                            label: t('common.mayMonth'),
                            value: 5,
                            disabled: false,
                        },
                        {
                            label: t('common.junMonth'),
                            value: 6,
                            disabled: false,
                        },
                        {
                            label: t('common.julMonth'),
                            value: 7,
                            disabled: false,
                        },
                        {
                            label: t('common.augMonth'),
                            value: 8,
                            disabled: false,
                        },
                        {
                            label: t('common.sepMonth'),
                            value: 9,
                            disabled: false,
                        },
                        {
                            label: t('common.octMonth'),
                            value: 10,
                            disabled: false,
                        },
                        {
                            label: t('common.novMonth'),
                            value: 11,
                            disabled: false,
                        },
                        {
                            label: t('common.decMonth'),
                            value: 12,
                            disabled: false,
                        },
                    ]

                    const newOptMonth = arrayOptionMonth.map(item => {
                        if (item.value <= 6) {
                            return {...item, disabled: disableMonthHalfYear1}
                        }
                        if (item.value <= 12) {
                            return {...item, disabled: disableMonthHalfYear2}
                        }
                    })

                    setOptionsMonth(newOptMonth)
                }

                if (timeType === 'quarter') {
                    const disableQuarterHalfYear1 = halfYearValue1 ? false : true
                    const disableQuarterHalfYear2 = halfYearValue2 ? false : true

                    const newOptQuarter = optionsQuarter.map(item => {
                        if (item.value === 'Q1' || item.value === 'Q2') {
                            return {...item, disabled: disableQuarterHalfYear1}
                        }
                        if (item.value === 'Q3' || item.value === 'Q4') {
                            return {...item, disabled: disableQuarterHalfYear2}
                        }
                    })

                    setOptionsQuarter(newOptQuarter)
                }
            }

            if (timeType1 === 'month') {
                const monthItem = timeGroup1.month
                const monthYearValue = monthItem.map(element => element.year)

                if (timeType === 'year') {
                    const newOptYear = setOptYear().map(item => {
                        const checkDisable = monthYearValue.find(year => item.value === year) ? false : true

                        return {
                            ...item,
                            disable: checkDisable,
                        }
                    })

                    setOptionYear(newOptYear)
                }

                if (timeType === 'quarter') {
                    const disableQ1 = monthItem.find(item => item.month <= 3) ? false : true
                    const disableQ2 = monthItem.find(item => item.month <= 6 && item.month > 3) ? false : true
                    const disableQ3 = monthItem.find(item => item.month <= 9 && item.month > 6) ? false : true
                    const disableQ4 = monthItem.find(item => item.month <= 12 && item.month > 9) ? false : true

                    const newOptQuarter = optionsQuarter.map(item => {
                        if (item.value === 'Q1') {
                            return {...item, disabled: disableQ1}
                        }
                        if (item.value === 'Q2') {
                            return {...item, disabled: disableQ2}
                        }
                        if (item.value === 'Q3') {
                            return {...item, disabled: disableQ3}
                        }
                        if (item.value === 'Q4') {
                            return {...item, disabled: disableQ4}
                        }
                    })

                    setOptionsQuarter(newOptQuarter)
                }

                if (timeType === 'halfYear') {
                    const disableHalfYear1 = monthItem.find(item => item.month <= 6) ? false : true
                    const disableHalfYear2 = monthItem.find(item => item.month <= 12) ? false : true
                    const arrayOptionHalfYear = [
                        {label: t('inalyze.firstHalfYear'), value: 1, disabled: false},
                        {label: t('inalyze.secondHalfYear'), value: 2, disabled: false},
                    ]

                    const newOptHalfYear = arrayOptionHalfYear.map(item => {
                        if (item.value === 1) {
                            return {...item, disabled: disableHalfYear1}
                        }

                        if (item.value === 2) {
                            return {...item, disabled: disableHalfYear2}
                        }
                    })

                    setOptionsHalfYear(newOptHalfYear)
                }
            }

            setTimeGroup2(cur => ({...cur, timeType, year: [], quarter: [], month: [], halfYear: null}))
        }
    }

    const onSetTimGroup1 = data => {
        const timeType = timeGroup1?.timeType
        const timeType2 = timeGroup2?.timeType

        if (timeType === 'year') {
            setTimeGroup1(cur => ({...cur, year: data, halfYear: [], month: [], quarter: []}))
        }
        if (timeType === 'quarter') {
            const Q1Value = data.find(element => element.quarter === 'Q1')
            const Q2Value = data.find(element => element.quarter === 'Q2')
            const Q3Value = data.find(element => element.quarter === 'Q3')
            const Q4Value = data.find(element => element.quarter === 'Q4')
            const quarterYearValue = data.map(element => element.year)
            if (timeType2 === 'halfYear') {
                const disabledHalfYear1 = Q1Value || Q2Value ? false : true
                const disabledHalfYear2 = Q3Value || Q4Value ? false : true
                const arrayOptionHalfYear = [
                    {label: t('inalyze.firstHalfYear'), value: 1, disabled: false},
                    {label: t('inalyze.secondHalfYear'), value: 2, disabled: false},
                ]

                const newOptHalfYear = arrayOptionHalfYear.map(item => {
                    if (item.value === 1) {
                        return {...item, disabled: disabledHalfYear1}
                    }
                    if (item.value === 2) {
                        return {...item, disabled: disabledHalfYear2}
                    }
                })
                setTimeGroup1(cur => ({...cur, year: [], halfYear: [], month: [], quarter: data}))
                setOptionsHalfYear(newOptHalfYear)
                setTimeGroup2(cur => ({...cur, halfYear: null}))
            }

            if (timeType2 === 'month') {
                const disableMonthQ1 = Q1Value ? false : true
                const disableMonthQ2 = Q2Value ? false : true
                const disableMonthQ3 = Q3Value ? false : true
                const disableMonthQ4 = Q4Value ? false : true
                const arrayOptionMonth = [
                    {
                        label: t('common.janMonth'),
                        value: 1,
                        disabled: false,
                    },
                    {
                        label: t('common.febMonth'),
                        value: 2,
                        disabled: false,
                    },
                    {
                        label: t('common.marMonth'),
                        value: 3,
                        disabled: false,
                    },
                    {
                        label: t('common.aprMonth'),
                        value: 4,
                        disabled: false,
                    },
                    {
                        label: t('common.mayMonth'),
                        value: 5,
                        disabled: false,
                    },
                    {
                        label: t('common.junMonth'),
                        value: 6,
                        disabled: false,
                    },
                    {
                        label: t('common.julMonth'),
                        value: 7,
                        disabled: false,
                    },
                    {
                        label: t('common.augMonth'),
                        value: 8,
                        disabled: false,
                    },
                    {
                        label: t('common.sepMonth'),
                        value: 9,
                        disabled: false,
                    },
                    {
                        label: t('common.octMonth'),
                        value: 10,
                        disabled: false,
                    },
                    {
                        label: t('common.novMonth'),
                        value: 11,
                        disabled: false,
                    },
                    {
                        label: t('common.decMonth'),
                        value: 12,
                        disabled: false,
                    },
                ]
                const newOptMonth = arrayOptionMonth.map(item => {
                    if (item.value <= 3) {
                        return {...item, disabled: disableMonthQ1}
                    }

                    if (item.value <= 6) {
                        return {...item, disabled: disableMonthQ2}
                    }

                    if (item.value <= 9) {
                        return {...item, disabled: disableMonthQ3}
                    }

                    if (item.value <= 12) {
                        return {...item, disabled: disableMonthQ4}
                    }
                })

                const newMonth = []
                // newOptMonth.forEach(item)
                timeGroup2?.month?.forEach(item => {
                    if (disableMonthQ1) {
                        if (item > 3) {
                            newMonth.push(item)
                        }
                    } else if (disableMonthQ2) {
                        if (item <= 3 || item > 6) {
                            newMonth.push(item)
                        }
                    } else if (disableMonthQ3) {
                        if (item <= 6 || item > 9) {
                            newMonth.push(item)
                        }
                    } else if (disableMonthQ4) {
                        if (item < 9) {
                            newMonth.push(item)
                        }
                    }
                })
                setTimeGroup2(current => ({...current, month: newMonth}))

                setTimeGroup1(cur => ({...cur, year: [], halfYear: [], month: [], quarter: data}))
                setOptionsMonth(newOptMonth)
            }

            if (timeType2 === 'year') {
                const newOptYear = setOptYear().map(item => {
                    const checkDisable = quarterYearValue.find(year => item.value === year) ? false : true

                    return {
                        ...item,
                        disable: checkDisable,
                    }
                })
                const resultsYear = []

                newOptYear.forEach(item => {
                    timeGroup2.year.forEach(result => {
                        if (item.disable !== true && result === item.value) {
                            resultsYear.push(result)
                        }
                    })
                })

                setTimeGroup1(cur => ({...cur, year: [], halfYear: [], month: [], quarter: data}))
                setOptionYear(newOptYear)
                setTimeGroup2(cur => ({...cur, year: resultsYear}))
            }
        }
        if (timeType === 'halfYear') {
            const halfYearYearValue = data.map(element => element.year)

            const halfYearValue1 = data.find(element => element.halfYear === 1)
            const halfYearValue2 = data.find(element => element.halfYear === 2)

            if (timeType2 == 'year') {
                const newOptYear = setOptYear().map(item => {
                    const checkDisable = halfYearYearValue.find(year => item.value === year) ? false : true

                    return {
                        ...item,
                        disable: checkDisable,
                    }
                })

                const resultsYear = []

                newOptYear.forEach(item => {
                    timeGroup2.year.forEach(result => {
                        if (item.disable !== true && result === item.value) {
                            resultsYear.push(result)
                        }
                    })
                })

                setTimeGroup1(cur => ({...cur, year: [], halfYear: data, month: [], quarter: []}))
                setOptionYear(newOptYear)
                setTimeGroup2(cur => ({...cur, year: resultsYear}))
            }
            if (timeType2 === 'month') {
                const disableMonthHalfYear1 = halfYearValue1 ? false : true
                const disableMonthHalfYear2 = halfYearValue2 ? false : true
                const arrayOptionMonth = [
                    {
                        label: t('common.janMonth'),
                        value: 1,
                        disabled: false,
                    },
                    {
                        label: t('common.febMonth'),
                        value: 2,
                        disabled: false,
                    },
                    {
                        label: t('common.marMonth'),
                        value: 3,
                        disabled: false,
                    },
                    {
                        label: t('common.aprMonth'),
                        value: 4,
                        disabled: false,
                    },
                    {
                        label: t('common.mayMonth'),
                        value: 5,
                        disabled: false,
                    },
                    {
                        label: t('common.junMonth'),
                        value: 6,
                        disabled: false,
                    },
                    {
                        label: t('common.julMonth'),
                        value: 7,
                        disabled: false,
                    },
                    {
                        label: t('common.augMonth'),
                        value: 8,
                        disabled: false,
                    },
                    {
                        label: t('common.sepMonth'),
                        value: 9,
                        disabled: false,
                    },
                    {
                        label: t('common.octMonth'),
                        value: 10,
                        disabled: false,
                    },
                    {
                        label: t('common.novMonth'),
                        value: 11,
                        disabled: false,
                    },
                    {
                        label: t('common.decMonth'),
                        value: 12,
                        disabled: false,
                    },
                ]

                const newOptMonth = arrayOptionMonth.map(item => {
                    if (item.value <= 6) {
                        return {...item, disabled: disableMonthHalfYear1}
                    }
                    if (item.value <= 12) {
                        return {...item, disabled: disableMonthHalfYear2}
                    }
                })

                if (disableMonthHalfYear1) {
                    const newMonth = timeGroup2?.month?.filter(item => item > 5)

                    setTimeGroup2(cur => ({...cur, month: newMonth}))
                }

                if (disableMonthHalfYear2) {
                    const newMonth = timeGroup2?.month?.filter(item => item < 6)

                    setTimeGroup2(cur => ({...cur, month: newMonth}))
                }
                setTimeGroup1(cur => ({...cur, year: [], halfYear: data, month: [], quarter: []}))
                setOptionsMonth(newOptMonth)
            }

            if (timeType2 === 'quarter') {
                const disableQuarterHalfYear1 = halfYearValue1 ? false : true
                const disableQuarterHalfYear2 = halfYearValue2 ? false : true

                const newOptQuarter = optionsQuarter.map(item => {
                    if (item.value === 'Q1' || item.value === 'Q2') {
                        return {...item, disabled: disableQuarterHalfYear1}
                    }
                    if (item.value === 'Q3' || item.value === 'Q4') {
                        return {...item, disabled: disableQuarterHalfYear2}
                    }
                })

                if (disableQuarterHalfYear1) {
                    const resultsQuarter = []
                    timeGroup2?.quarter?.forEach(item => {
                        if (item === 'Q3' || item === 'Q4') {
                            resultsQuarter.push(item)
                        }
                    })

                    setTimeGroup2(cur => ({...cur, quarter: resultsQuarter}))
                }

                if (disableQuarterHalfYear2) {
                    const resultsQuarter = []
                    timeGroup2?.quarter?.forEach(item => {
                        if (item === 'Q1' || item === 'Q2') {
                            resultsQuarter.push(item)
                        }
                    })

                    setTimeGroup2(cur => ({...cur, quarter: resultsQuarter}))
                }
                setTimeGroup1(cur => ({...cur, year: [], halfYear: data, month: [], quarter: []}))
                setOptionsQuarter(newOptQuarter)
            }
        }
        if (timeType === 'month') {
            const monthYearValue = data.map(element => element.year)
            if (timeType2 === 'year') {
                const newOptYear = setOptYear().map(item => {
                    const checkDisable = monthYearValue.find(year => item.value === year) ? false : true

                    return {
                        ...item,
                        disable: checkDisable,
                    }
                })

                const resultsYear = []

                newOptYear.forEach(item => {
                    timeGroup2.year.forEach(result => {
                        if (item.disable !== true && result === item.value) {
                            resultsYear.push(result)
                        }
                    })
                })

                setTimeGroup2(cur => ({...cur, year: resultsYear}))
                setTimeGroup1(cur => ({...cur, year: [], halfYear: [], month: data, quarter: []}))
                setOptionYear(newOptYear)
            }

            if (timeType2 === 'quarter') {
                const disableQ1 = data.find(item => item.month <= 3) ? false : true
                const disableQ2 = data.find(item => item.month <= 6 && item.month > 3) ? false : true
                const disableQ3 = data.find(item => item.month <= 9 && item.month > 6) ? false : true
                const disableQ4 = data.find(item => item.month <= 12 && item.month > 9) ? false : true

                const newOptQuarter = optionsQuarter.map(item => {
                    if (item.value === 'Q1') {
                        return {...item, disabled: disableQ1}
                    }
                    if (item.value === 'Q2') {
                        return {...item, disabled: disableQ2}
                    }
                    if (item.value === 'Q3') {
                        return {...item, disabled: disableQ3}
                    }
                    if (item.value === 'Q4') {
                        return {...item, disabled: disableQ4}
                    }
                })

                const disableQuarter = []
                newOptQuarter.forEach(item => {
                    timeGroup2.quarter.forEach(quarter => {
                        if (item.disabled && quarter === item.value) {
                            disableQuarter.push(quarter)
                        }
                    })
                })

                const resultQuarter = timeGroup2.quarter.filter(item => !disableQuarter.includes(item))

                setTimeGroup2(cur => ({...cur, quarter: resultQuarter}))

                setTimeGroup1(cur => ({...cur, year: [], halfYear: [], month: data, quarter: []}))
                setOptionsQuarter(newOptQuarter)
            }

            if (timeType2 === 'halfYear') {
                const disableHalfYear1 = data.find(item => item.month <= 6) ? false : true
                const disableHalfYear2 = data.find(item => item.month <= 12) ? false : true
                const arrayOptionHalfYear = [
                    {label: t('inalyze.firstHalfYear'), value: 1, disabled: false},
                    {label: t('inalyze.secondHalfYear'), value: 2, disabled: false},
                ]

                const newOptHalfYear = arrayOptionHalfYear.map(item => {
                    if (item.value === 1) {
                        return {...item, disabled: disableHalfYear1}
                    }

                    if (item.value === 2) {
                        return {...item, disabled: disableHalfYear2}
                    }
                })

                setTimeGroup1(cur => ({...cur, year: [], halfYear: [], month: data, quarter: []}))

                setOptionsHalfYear(newOptHalfYear)
            }

            setTimeGroup1(cur => ({...cur, year: [], halfYear: [], month: data, quarter: []}))
        }

        if (data.length <= 0) {
            setTimeGroup2(cur => ({...cur, year: [], quarter: [], month: [], halfYear: null}))
        }
    }

    const onSelectTimeGroup2 = (data, option) => {
        const timeTypeGroup = timeGroup2?.timeType

        if (option.length <= 5) {
            if (timeTypeGroup === 'year') {
                setTimeGroup2(cur => ({...cur, year: data}))
            }

            if (timeTypeGroup === 'quarter') {
                setTimeGroup2(cur => ({...cur, quarter: data}))
            }

            if (timeTypeGroup === 'month') {
                setTimeGroup2(cur => ({...cur, month: data}))
            }
        }
    }

    const checkDisableTimeGroup2 = () => {
        const yearTime1 = timeGroup1?.year?.length
        const halfYearTime1 = timeGroup1?.halfYear?.length
        const monthTime1 = timeGroup1?.month?.length
        const quarterTime1 = timeGroup1?.quarter?.length

        const allTime1 = yearTime1 + halfYearTime1 + monthTime1 + quarterTime1

        return allTime1 <= 0 ? true : false
    }

    const onSelectHalfYear = data => {
        setTimeGroup2(cur => ({...cur, halfYear: data}))
    }

    const onSubmit = () => {
        let paramData = {
            compareData: compareData,
            drone: selectDrones,
            periodTimeGroup: [
                {
                    ...timeGroup1,
                },
                {
                    ...timeGroup2,
                },
            ],
        }

        if (timeGroup2?.timeType === 'year') {
            paramData = {
                ...paramData,
                periodTimeGroup: [
                    {
                        ...paramData.periodTimeGroup[0],
                    },
                    {
                        ...paramData.periodTimeGroup[1],
                        year: timeGroup2?.year,
                        month: [],
                        halfYear: null,
                        quarter: [],
                    },
                ],
            }
        }

        if (timeGroup2?.timeType === 'quarter') {
            paramData = {
                ...paramData,
                periodTimeGroup: [
                    {
                        ...paramData.periodTimeGroup[0],
                    },
                    {
                        ...paramData.periodTimeGroup[1],
                        year: [],
                        month: [],
                        halfYear: null,
                        quarter: timeGroup2?.quarter,
                    },
                ],
            }
        }

        if (timeGroup2?.timeType === 'halfYear') {
            paramData = {
                ...paramData,
                periodTimeGroup: [
                    {
                        ...paramData.periodTimeGroup[0],
                    },
                    {
                        ...paramData.periodTimeGroup[1],
                        year: [],
                        month: [],
                        halfYear: timeGroup2?.halfYear,
                        quarter: [],
                    },
                ],
            }
        }

        if (timeGroup2?.timeType === 'month') {
            paramData = {
                ...paramData,
                periodTimeGroup: [
                    {
                        ...paramData.periodTimeGroup[0],
                    },
                    {
                        ...paramData.periodTimeGroup[1],
                        year: [],
                        month: timeGroup2?.month,
                        halfYear: null,
                        quarter: [],
                    },
                ],
            }
        }

        setFormCompareTime({...paramData})
        setLoading(cur => ({...cur, compare_time: true}))

        onClickSearchTime()
    }

    const checkDisableButtonSubmit = () => {
        const checkTimeGroup1 =
            timeGroup1.year.length > 0 ||
            timeGroup1.halfYear.length > 0 ||
            timeGroup1.month.length > 0 ||
            timeGroup1.quarter.length > 0
        const checkTimeGroup2 =
            timeGroup2.month.length > 0 ||
            timeGroup2.halfYear ||
            timeGroup2.quarter.length > 0 ||
            timeGroup2.year.length > 0

        if (checkTimeGroup1 && checkTimeGroup2 && compareData && selectDrones.length > 0) {
            return false
        } else {
            return true
        }
    }

    const onClear = () => {
        setCompareData('')
        setSelectDrones([])
        setTimeGroup1({
            timeType: 'year',
            year: [],
            quarter: [],
            month: [],
            halfYear: [],
        })

        setTimeGroup2({
            timeType: 'month',
            year: [],
            quarter: [],
            month: [],
            halfYear: null,
        })

        onClickClearSearchTime()
    }

    return (
        <Form>
            {loading?.compare_time ? (
                <FormTimeSkeleton />
            ) : (
                <FormGroup row>
                    <Col xs={12} sm={2} md={2} lg={2} className="mb-2">
                        <Label for="type" className="label-form">
                            {t('inalyze.dataForComparison')} :
                        </Label>
                    </Col>
                    <Col xs={12} sm={10} md={6} lg={3} className="mb-2">
                        <Space className="w-100" direction="vertical">
                            <Select
                                optionFilterProp="children"
                                className="w-100"
                                placeholder={t('inalyze.pleaseSelectDataForComparison')}
                                value={compareData}
                                onChange={onSelectCompare}>
                                {optCompareDataTime?.map((item, index) => (
                                    <Option
                                        key={index}
                                        value={item.value}
                                        label={i18n.language === 'en' ? item.label_en : item.label_th}>
                                        {i18n.language === 'en' ? item.label_en : item.label_th}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={7}></Col>
                    <Col xs={12} sm={2} md={2} lg={2} className="mb-2">
                        <Label for="type" className="label-form">
                            {t('common.selectDrone')}:
                        </Label>
                    </Col>
                    <Col xs={12} sm={10} md={10} lg={10} className="mb-2 " style={{paddingLeft: 4}}>
                        <Select
                            mode="multiple"
                            className="w-100 container-select-drone"
                            placeholder={t('common.pleaseSelectDrone')}
                            value={selectDrones}
                            showSearch
                            optionFilterProp="children"
                            onChange={onSelectDrone}>
                            {lutDrones?.map((item, index) => (
                                <Option key={index} value={item.id} label={item.name}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="my-4"
                        style={{borderBottom: '2px solid #eff2f7'}}></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-2 mt-2" style={{margin: 0, padding: 0}}>
                        <Row style={{margin: 0, padding: 0}}>
                            <Col xs={12} sm={12} md={12} lg={12} className="mb-2">
                                <Label for="type" className="label-title-form sub-paragraph">
                                    {t('inalyze.selectTimeRangeGroup')} 1 :
                                </Label>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} className="mb-2">
                                <Row>
                                    <Radio.Group
                                        style={{display: 'flex'}}
                                        name="radiogroup"
                                        value={timeGroup1?.timeType}>
                                        {optCompareTimeType?.map(itm => (
                                            <Col xs={12} sm={3} md={3} lg={3} key={itm.value} className="mb-2">
                                                <div className="radio-box sub-paragraph">
                                                    <Radio
                                                        disabled={itm.value === timeGroup2?.timeType}
                                                        name="radio-time-group-type-2"
                                                        value={itm.value}
                                                        onChange={() => onSelectTimeType(itm.value, 'timeGroup1')}>
                                                        {i18n.language === 'en' ? itm.label_en : itm.label_th}
                                                    </Radio>
                                                </div>
                                            </Col>
                                        ))}
                                    </Radio.Group>
                                </Row>
                            </Col>

                            <Col lg={12} xl={12} style={{margin: 0, padding: 0}}>
                                {timeGroup1?.timeType === 'year' && (
                                    <SelectDatePickerMultiple
                                        picker="year"
                                        data={timeGroup1.year}
                                        onSet={onSetTimGroup1}
                                    />
                                )}

                                {timeGroup1?.timeType === 'month' && (
                                    <SelectDatePickerMultiple
                                        picker="month"
                                        data={timeGroup1.month}
                                        onSet={onSetTimGroup1}
                                    />
                                )}

                                {timeGroup1?.timeType === 'quarter' && (
                                    <SelectDatePickerMultiple
                                        picker="quarter"
                                        data={timeGroup1.quarter}
                                        onSet={onSetTimGroup1}
                                    />
                                )}

                                {timeGroup1?.timeType === 'halfYear' && (
                                    <SelectDatePickerMultiple
                                        picker="halfYear"
                                        data={timeGroup1.halfYear}
                                        onSet={onSetTimGroup1}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-2  mt-4" style={{margin: 0, padding: 0}}>
                        <Row style={{margin: 0, padding: 0}}>
                            <Col xs={12} sm={12} md={12} lg={12} className="mb-2">
                                <Label for="type" className="label-title-form sub-paragraph">
                                    {t('inalyze.selectTimeRangeGroup')} 2 :
                                </Label>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} className="mb-2 ">
                                <Row>
                                    <Radio.Group
                                        style={{display: 'flex'}}
                                        name="radiogroup"
                                        value={timeGroup2?.timeType}>
                                        {optCompareTimeType?.map(itm => (
                                            <Col xs={12} sm={3} md={3} lg={3} key={itm.value} className="mb-2">
                                                <div className="radio-box sub-paragraph">
                                                    <Radio
                                                        disabled={itm.value === timeGroup1.timeType}
                                                        name="radio-time-group-type-2"
                                                        value={itm.value}
                                                        onChange={() => onSelectTimeType(itm.value, 'timeGroup2')}>
                                                        {i18n.language === 'en' ? itm.label_en : itm.label_th}
                                                    </Radio>
                                                </div>
                                            </Col>
                                        ))}
                                    </Radio.Group>
                                </Row>
                            </Col>
                            <Col lg={12} xl={12} className="mb-2  sub-paragraph" style={{margin: 0, padding: 0}}>
                                <TabContent activeTab={timeGroup2?.timeType}>
                                    <TabPane tabId="year" id="v-pills-confir" role="tabpanel">
                                        <div className="container-group-time-year container-select-drone w-100 ">
                                            <Select
                                                className="select-year"
                                                mode="multiple"
                                                showSearch
                                                optionFilterProp="children"
                                                placeholder={t('common.pleaseSelect')}
                                                allowClear
                                                value={timeGroup2?.year}
                                                onChange={(e, option) => {
                                                    onSelectTimeGroup2(e, option)
                                                }}
                                                disabled={checkDisableTimeGroup2()}>
                                                {optionYear?.map((item, index) => (
                                                    <Option
                                                        key={index}
                                                        value={item.value}
                                                        label={item.label}
                                                        disabled={item.disable}>
                                                        {item.label}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </TabPane>
                                    <TabPane
                                        tabId="month"
                                        id="v-pills-payment"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-payment-tab">
                                        <div className=" container-select-drone  container-group-time">
                                            <Select
                                                className="select-first"
                                                mode="multiple"
                                                showSearch
                                                optionFilterProp="children"
                                                placeholder={t('common.pleaseSelect')}
                                                allowClear
                                                value={timeGroup2?.month}
                                                onChange={(e, option) => {
                                                    onSelectTimeGroup2(e, option)
                                                }}
                                                disabled={checkDisableTimeGroup2()}>
                                                {optionsMonth?.map((item, index) => (
                                                    <Option
                                                        key={index}
                                                        value={item.value}
                                                        label={item.label}
                                                        disabled={item.disabled}>
                                                        {item.label}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="quarter" id="v-pills-confir" role="tabpanel">
                                        <div className="container-group-time  container-select-drone">
                                            <Select
                                                className="select-first"
                                                mode="multiple"
                                                showSearch
                                                optionFilterProp="children"
                                                placeholder={t('common.pleaseSelect')}
                                                allowClear
                                                value={timeGroup2?.quarter}
                                                onChange={(e, option) => {
                                                    onSelectTimeGroup2(e, option)
                                                }}
                                                disabled={checkDisableTimeGroup2()}>
                                                {optionsQuarter?.map((item, index) => (
                                                    <Option
                                                        key={index}
                                                        value={item.value}
                                                        label={item.label}
                                                        disabled={item.disabled}>
                                                        {item.label}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="halfYear" id="v-pills-confir" role="tabpanel">
                                        <div className="container-group-time ">
                                            <Select
                                                placeholder={t('inalyze.pleaseSelectTimeRange')}
                                                value={timeGroup2?.halfYear}
                                                className="select-first"
                                                onChange={value => {
                                                    onSelectHalfYear(value)
                                                }}
                                                disabled={checkDisableTimeGroup2()}>
                                                {optionsHalfYear?.map((item, index) => {
                                                    return (
                                                        <Option
                                                            key={index}
                                                            value={item.value}
                                                            label={item.label}
                                                            disabled={item.disabled}>
                                                            {item.label}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6} sm={4} md={3} lg={3} xl={2} className=" sub-paragraph mb-2 mt-3">
                        <Button
                            disabled={checkDisableButtonSubmit()}
                            className="btn-submit"
                            color="primary"
                            onClick={onSubmit}>
                            {t('inalyze.compare')}
                        </Button>
                    </Col>

                    <Col xs={6} sm={4} md={3} lg={3} xl={2} className="mb-2 mt-3">
                        <Button className="btn-clear" onClick={onClear}>
                            {t('common.clearData')}
                        </Button>
                    </Col>
                </FormGroup>
            )}
        </Form>
    )
}

export default withTranslation()(FormTime)
