import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import React, {Component, useEffect} from 'react'
import {Row, Col, Card, CardBody, CardTitle} from 'reactstrap'
import './style.scss'

const WorkingDroneSkeleton = ({}) => {
    return (
        <Card
            style={{
                borderRadius: 8,
                height: 670,
            }}>
            <CardTitle
                style={{
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    display: 'flex',
                    marginTop: 20,
                    paddingLeft: 25,
                    paddingRight: 25,
                }}>
                <div>
                    <Skeleton height={16} width={250} />
                    <Skeleton width={170} />
                    <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                        <Skeleton height={16} width={120} />
                        <Skeleton width={130} height={33} style={{borderRadius: 8, marginLeft: 15}} />
                        <Skeleton width={130} height={33} style={{borderRadius: 8, marginLeft: 15}} />
                    </div>
                </div>
            </CardTitle>
            <CardBody>
                <div className="d-flex align-items-center justify-content-end">
                    <div className="d-flex align-items-center justify-content-between">
                        <Skeleton width={70} height={16} style={{}} />
                        <Skeleton width={70} height={16} style={{marginLeft: 15}} />
                        <Skeleton width={70} height={16} style={{marginLeft: 15}} />
                        <Skeleton width={70} height={16} style={{marginLeft: 15}} />
                    </div>
                </div>
                <div className="card-skeleton " style={{marginTop: 20}}>
                    <Skeleton height={450} />
                </div>
            </CardBody>
        </Card>
    )
}

export default WorkingDroneSkeleton
