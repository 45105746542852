import {
  GET_NAVBAR_DRONE_ACTIVE,
  GET_NAVBAR_DRONE_ACTIVE_FAIL,
  GET_NAVBAR_DRONE_ACTIVE_SUCCESS,
  UPDATE_FILTER_DRONE_ACTIVE,
  UPDATE_FILTER_DRONE_ACTIVE_FAIL,
  UPDATE_FILTER_DRONE_ACTIVE_SUCCESS,
} from "./actionTypes";

export const getDroneActive = data => ({
  type: GET_NAVBAR_DRONE_ACTIVE,
  payload: data,
});

export const setFilterDroneActive = data => ({
  type: UPDATE_FILTER_DRONE_ACTIVE,
  payload: data,
});

export const setFilterDroneActiveSuccess = data => ({
  type: UPDATE_FILTER_DRONE_ACTIVE_SUCCESS,
  payload: data,
});

export const setFilterDroneActiveFail = error => ({
  type: UPDATE_FILTER_DRONE_ACTIVE_FAIL,
  payload: error,
});

export const getNavbarDroneActiveSuccess = navbar => ({
  type: GET_NAVBAR_DRONE_ACTIVE_SUCCESS,
  payload: navbar,
});

export const getNavbarDroneActiveFail = error => ({
  type: GET_NAVBAR_DRONE_ACTIVE_FAIL,
  payload: error,
});
