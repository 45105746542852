/* global google */
import { Padding } from "@mui/icons-material";
import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import { useState } from "react";

const App = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyB5RVDr2EpQhSt1YsMFCu7Xi2qlVz3qP2o",
  });
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const markers = [
    { address: "Address1", lat: 13.736717, lng: 100.523186 },
    { address: "Address1", lat: 14.101445, lng: 100.129465 },
    { address: "Address1", lat: 13.748358, lng: 100.754708 },
  ];

  const onMapLoad = map => {
    setMapRef(map);
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
    map.setZoom(12);
    // console.log("getZoom",map.getZoom())
  };

  const handleMarkerClick = (id, lat, lng, address) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, address });
    setIsOpen(true);
  };
  const mapStyles = () => {
    return {
      borderRadius: 8,
      height: "340px",
      width: "100%",
    };
  };
  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
    scaleControl: false,
    mapTypeId: "hybrid",
    controlSize: 25,
    // ControlPosition:true
  };
  return (
    <div className="App">
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          mapContainerStyle={mapStyles()}
          onLoad={onMapLoad}
          options={mapOptions}
        ></GoogleMap>
      )}
    </div>
  );
};

export default App;
