import React, {useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import {withTranslation} from 'react-i18next'
import {useRecoilState, useRecoilValue} from 'recoil'
import {Row, Col, Card, CardBody} from 'reactstrap'
import {Select} from 'antd'
const {Option} = Select

import {filterDashboardState, loadingDashboardState} from 'store/atoms/dashboard'
import {getDroneType} from 'services/skc/new-dashboard'
import {lookupDroneTypeAverage, lookupDroneTypeTotal} from 'constants/lookup'
import {getDistrict, getFilterProvince} from 'services/skc/lookup'

import TableDrone from 'components/Dashboard/DroneType/TableDrone'
import DroneTypeSkeleton from '../CardSkeleton/DroneTypeSkeleton'

import '../style/picker.scss'
import '../style/drone-type.scss'

const DroneType = props => {
    const {t, i18n} = props

    const searchFilter = useRecoilValue(filterDashboardState)
    const [loading, setLoading] = useRecoilState(loadingDashboardState)

    const [type, setType] = useState('total')
    const [selectProvince, setSelectProvince] = useState('all')
    const [selectDistrict, setSelectDistrict] = useState('all')

    const [dataTotal, setDataTotal] = useState('drone')
    const [dataAverage, setDataAverage] = useState('working_area')

    const [lutProvince, setLutProvince] = useState([])
    const [lutDistrict, setLutDistrict] = useState([])

    const [disableDistrict, setDisableDistrict] = useState(false)
    const [disableProvince, setDisableProvince] = useState(false)
    const [skeleton, setSkeleton] = useState(true)

    const [dataDonutCharts, setDataDonutCharts] = useState([])
    const [labelsChart, setLabelsChart] = useState([])
    const [colorChart, setColorChart] = useState([])
    const [dataTable, setDataTable] = useState({})

    const [page, setPage] = useState(1)

    // nf = twoDecimal
    const twoDecimal = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })

    // ni = nonDecimal

    const nonDecimal = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    })

    useEffect(() => {
        fetchProvince()
        fetchDroneType({
            ...searchFilter,
            value: type === 'total' ? dataTotal : dataAverage,
            display: type,
            pageDrone: page,
            sizeDrone: 5,
            province: '',
            district: '',
        })
    }, [])

    useEffect(() => {
        if (loading?.drone_type) {
            checkRegionOnlyOther()
            fetchProvince()
            if (searchFilter?.region?.includes('other') && searchFilter?.region?.length === 1) {
                fetchDroneType({
                    ...searchFilter,
                    value: type === 'total' ? dataTotal : dataAverage,
                    display: type,
                    pageDrone: page,
                    sizeDrone: 5,
                    province: 'other',
                    district: 'other',
                })
            } else {
                fetchDroneType({
                    ...searchFilter,
                    value: type === 'total' ? dataTotal : dataAverage,
                    display: type,
                    pageDrone: page,
                    sizeDrone: 5,
                    province: '',
                    district: '',
                })
            }
            setLoading(cur => ({...cur, drone_type: false}))
        }
    }, [loading.drone_type])

    const handleChangeType = type => {
        setType(type)
        setSelectDistrict('all')
        setSelectProvince('all')
        fetchDroneType({
            ...searchFilter,
            value: type === 'total' ? dataTotal : dataAverage,
            display: type,
            pageDrone: 1,
            sizeDrone: 5,
            province: '',
            district: '',
        })
    }

    const changeFirstString = str => {
        const changeString = str
            .toLowerCase()
            .split(' ')
            .map(item => {
                return item[0].toUpperCase() + item.slice(1).toLowerCase()
            })

        return changeString.join(' ')
    }

    const fetchProvince = async () => {
        try {
            const response = await getFilterProvince({
                region: searchFilter.region.length === 0 ? ['2', '3', '4', '5', '6', '7'] : searchFilter.region,
            })

            if (response.status_code === 200 && response.results.data.length > 0) {
                setLutProvince(response.results.data)
            } else {
                setLutProvince([])
            }
        } catch (error) {
            setLutProvince([])
        }
    }

    const fetchDistrict = async id => {
        try {
            const response = await getDistrict(id)

            if (response.status_code === 200 && response.results.length > 0) {
                setLutDistrict(response.results)
            }
        } catch (error) {
            setLutDistrict([])
        }
    }

    const handleChangeProvince = value => {
        setSelectProvince(value)

        if (value === 'other') {
            setDisableDistrict(true)
            setSelectDistrict('other')
            setLutDistrict([])
        }

        if (value === 'all') {
            setDisableDistrict(false)
            setSelectDistrict('all')
        }

        if (value !== 'all' && value !== 'other') {
            fetchDistrict(value)
            setDisableDistrict(false)
        }
    }

    const handleChangeDistrict = value => {
        setSelectDistrict(value)
    }

    const onChangePagination = async page => {
        setPage(page)
        fetchDroneType({
            ...searchFilter,
            value: type === 'total' ? dataTotal : dataAverage,
            display: type,
            pageDrone: page,
            sizeDrone: 5,
            province: selectProvince === 'all' ? '' : selectProvince,
            district: selectDistrict === 'all' ? '' : selectDistrict,
        })
    }

    const handleSelectDataTotal = data => {
        setDataTotal(data)
    }

    const handleSelectDataAverage = data => {
        setDataAverage(data)
    }

    const handleChangeSubmit = () => {
        fetchDroneType({
            ...searchFilter,
            value: type === 'total' ? dataTotal : dataAverage,
            display: type,
            pageDrone: page,
            sizeDrone: 5,
            province: selectProvince === 'all' ? '' : selectProvince,
            district: selectDistrict === 'all' ? '' : selectDistrict,
        })
    }

    const fetchDroneType = async params => {
        try {
            setSkeleton(true)
            const response = await getDroneType(params)

            if (response.status_code === 200 && response.results) {
                setDataTable(response.results)
                setDataDonutCharts(response?.results?.chart?.series)
                setLabelsChart(response?.results?.chart?.labels)
                setColorChart(response?.results?.chart?.colors)
            } else {
                setDataDonutCharts([])
                setDataTable([])
                setLabelsChart([])
                setColorChart([])
            }
        } catch (error) {
            setDataDonutCharts([])
            setDataTable([])
        } finally {
            setSkeleton(false)
        }
    }

    const checkRegionOnlyOther = () => {
        if (searchFilter?.region?.includes('other') && searchFilter?.region?.length === 1) {
            setSelectProvince('other')
            setSelectDistrict('other')
            setDisableDistrict(true)
            setDisableProvince(true)
        } else {
            setDisableDistrict(false)
            setDisableProvince(false)
            setSelectProvince('all')
            setSelectDistrict('all')
        }
    }

    console.log('dataDonutCharts :>> ', dataDonutCharts.length)
    return (
        <Col xl="6">
            {skeleton ? (
                <DroneTypeSkeleton />
            ) : (
                <Card className="card-drone-type">
                    <CardBody>
                        <Row>
                            <Col xs={6} lg={6}>
                                <div className=" me-3 ">
                                    <h4 className="text-head">{t('dashboard.droneTypes')}</h4>

                                    <p className="mb-4 text-muted text-sub">
                                        {t('dashboard.numberOfDronesAddedForEachType')}
                                    </p>
                                </div>
                            </Col>
                            <Col xs={6} lg={6} className="list-inline user-chat-nav text-end mb-0">
                                <button
                                    onClick={() => {
                                        handleChangeType('total')
                                    }}
                                    type="button"
                                    className={'btn w-xs  rainbow-1 btn-select-type'}
                                    style={{
                                        color: type === 'total' ? '#4965EA' : '#000',

                                        borderColor: type === 'total' ? '#4965EA' : '#fff',

                                        backgroundColor: type === 'total' ? '#E8ECFF' : '#EFF0F2',
                                    }}>
                                    {t('dashboard.cumulativeData')}
                                </button>
                                <button
                                    onClick={() => {
                                        handleChangeType('avg')
                                    }}
                                    type="button"
                                    className={'btn w-xs rainbow-1 btn-select-type'}
                                    style={{
                                        color: type === 'avg' ? '#4965EA' : '#000',
                                        borderColor: type === 'avg' ? '#4965EA' : '#fff',
                                        backgroundColor: type === 'avg' ? '#E8ECFF' : '#EFF0F2',
                                    }}>
                                    {t('dashboard.averagePerDrone')}
                                </button>
                            </Col>
                        </Row>
                        {(searchFilter.country === 'all' || searchFilter.country === 'th') && (
                            <Row>
                                <div className="d-flex align-items-center  box-province-district">
                                    <Select
                                        className="select-box"
                                        value={selectProvince}
                                        disabled={disableProvince}
                                        onChange={handleChangeProvince}>
                                        <Option value={'all'} label={t('common.all')}>
                                            {t('common.all')}
                                        </Option>
                                        <Option value={'other'} label={t('common.provinceNotSpecified')}>
                                            {t('common.provinceNotSpecified')}
                                        </Option>
                                        {lutProvince?.map((item, index) => (
                                            <Option
                                                key={index}
                                                value={item.id}
                                                label={i18n.language === 'en' ? item.prov_name_en : item?.prov_name_th}>
                                                {i18n.language === 'en' ? item.prov_name_en : item?.prov_name_th}
                                            </Option>
                                        ))}
                                    </Select>

                                    <Select
                                        className="select-box"
                                        value={selectDistrict}
                                        onChange={handleChangeDistrict}
                                        disabled={disableDistrict}>
                                        <Option value={'all'} label={t('common.all')}>
                                            {t('common.all')}
                                        </Option>

                                        {selectProvince !== 'all' && (
                                            <Option value={'other'} label={t('common.districtNotSpecified')}>
                                                {t('common.districtNotSpecified')}
                                            </Option>
                                        )}
                                        {lutDistrict?.map((item, index) => (
                                            <Option
                                                key={index}
                                                value={item.value}
                                                label={
                                                    i18n.language === 'en'
                                                        ? changeFirstString(item?.label_en)
                                                        : item?.label
                                                }>
                                                {i18n.language === 'en'
                                                    ? changeFirstString(item?.label_en)
                                                    : item?.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </Row>
                        )}
                        <div className="div-data-total-average ">
                            {type === 'total' && (
                                <>
                                    <label className="mt-1 text">{t('common.selectDate')} :</label>
                                    <Select className="box-select" value={dataTotal} onChange={handleSelectDataTotal}>
                                        {lookupDroneTypeTotal?.map((item, index) => (
                                            <Option
                                                key={index}
                                                value={item.value}
                                                label={i18n.language === 'en' ? item.label_en : item.label_th}>
                                                {i18n.language === 'en' ? item.label_en : item.label_th}
                                            </Option>
                                        ))}
                                    </Select>
                                </>
                            )}

                            {type === 'avg' && (
                                <>
                                    <label className="mt-1 text">{t('common.selectDate')}:</label>
                                    <Select
                                        className="box-select"
                                        value={dataAverage}
                                        onChange={handleSelectDataAverage}>
                                        {lookupDroneTypeAverage?.map((item, index) => (
                                            <Option
                                                key={index}
                                                value={item.value}
                                                label={i18n.language === 'en' ? item.label_en : item.label_th}>
                                                {i18n.language === 'en' ? item.label_en : item.label_th}
                                            </Option>
                                        ))}
                                    </Select>
                                </>
                            )}

                            <button
                                onClick={() => {
                                    handleChangeSubmit()
                                }}
                                type="button"
                                className="btn btn-primary w-xs btn-submit">
                                {t('common.searchInformation')}
                            </button>
                        </div>
                        {type === 'total' && dataDonutCharts?.length > 0 && (
                            <Row className="result-total">
                                <Col className="col-text-percent" lg={5}>
                                    <div className="div-text-percent">
                                        <div style={{position: 'relative', top: '29%'}}>
                                            <div className="text">
                                                {dataTable?.label === 'จำนวนโดรนทั้งหมด' &&
                                                    t('dashboard.cumulativeNumberOfWorkingDrones')}
                                                {dataTable?.label === 'พื้นที่ทำงาน (ไร่)' &&
                                                    t('dashboard.cumulativeWorkingArea')}
                                                {dataTable?.label === 'ชั่วโมงบิน (ชม.)' &&
                                                    t('dashboard.cumulativeFlightHours')}
                                                {dataTable?.label === 'จำนวนวันทำงาน (วัน)' &&
                                                    t('dashboard.cumulativeAmountTaskDays')}
                                                {dataTable?.label === 'อายุการใช้งาน (วัน)' &&
                                                    t('dashboard.cumulativeLifespan')}
                                            </div>
                                            <div className="text-percent">
                                                {dataTable?.label === 'พื้นที่ทำงาน (ไร่)' ||
                                                dataTable?.label === 'ชั่วโมงบิน (ชม.)'
                                                    ? twoDecimal.format(dataTable?.total || 0)
                                                    : nonDecimal.format(dataTable?.total || 0)}
                                            </div>

                                            <span
                                                className={`badge badge-soft-${
                                                    parseFloat(dataTable?.drones_percentage) > 0
                                                        ? 'success'
                                                        : parseFloat(dataTable?.drones_percentage) < 0
                                                        ? 'danger'
                                                        : 'warning'
                                                }`}>
                                                {parseFloat(dataTable?.drones_percentage) > 0
                                                    ? '+'
                                                    : parseFloat(dataTable?.drones_percentage) < 0
                                                    ? ''
                                                    : ''}{' '}
                                                {dataTable?.drones_percentage}%
                                            </span>
                                        </div>
                                    </div>
                                    {dataDonutCharts?.length > 0 && (
                                        <div id="chart">
                                            <ReactApexChart
                                                options={{
                                                    labels: labelsChart || [],
                                                    colors:
                                                        colorChart.length > 0
                                                            ? colorChart
                                                            : [
                                                                  '#50a5f1',
                                                                  '#db76ff',
                                                                  '#f1b44c',
                                                                  '#556ee6',
                                                                  '#f46a6a',
                                                                  '#fe8b38',
                                                                  '#34c38f',
                                                                  '#38e6fe',
                                                              ],
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    stroke: {
                                                        width: 0,
                                                    },
                                                    tooltip: {
                                                        fillSeriesColor: false,
                                                        theme: 'light',
                                                        style: {
                                                            fontSize: '12px',
                                                            fontFamily: undefined,
                                                        },
                                                        shared: false,
                                                        intersect: true,
                                                        y: {
                                                            formatter: function (y) {
                                                                let unit = ''

                                                                if (dataTable?.label === 'จำนวนโดรนทั้งหมด') {
                                                                    unit = t('common.droneUnit')
                                                                }

                                                                if (dataTable?.label === 'พื้นที่ทำงาน (ไร่)') {
                                                                    unit = t('common.raiUnit')
                                                                }

                                                                if (dataTable?.label === 'ชั่วโมงบิน (ชม.)') {
                                                                    unit = t('common.hourUnit')
                                                                }

                                                                if (
                                                                    dataTable?.label === 'จำนวนวันทำงาน (วัน)' ||
                                                                    dataTable?.label === 'อายุการใช้งาน (วัน)'
                                                                ) {
                                                                    unit = t('common.days')
                                                                }

                                                                return `${twoDecimal.format(y)}  ${unit}`
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        show: false,
                                                        position: 'bottom',
                                                        horizontalAlign: 'center',
                                                        verticalAlign: 'middle',
                                                        floating: false,
                                                        fontSize: '14px',
                                                        offsetX: 0,
                                                        offsetY: -10,
                                                    },
                                                }}
                                                series={dataDonutCharts}
                                                type="donut"
                                                height="225"
                                                className="apex-charts"></ReactApexChart>
                                        </div>
                                    )}
                                </Col>
                                <Col lg={7}>
                                    <TableDrone
                                        type={'total'}
                                        data={dataTable?.drone_models}
                                        filter={searchFilter}
                                        label={dataTable?.label}
                                        onChangePagination={onChangePagination}
                                        drone_models_last={dataTable?.drone_models_last}
                                        total={dataTable?.pagination?.total_records}
                                        page={page}
                                    />
                                </Col>
                            </Row>
                        )}
                        {type === 'avg' && dataDonutCharts?.length > 0 && (
                            <Row className="result-total">
                                <Col className="col-text-percent" lg={5}>
                                    <div className="div-text-percent">
                                        <div style={{position: 'relative', top: '29%'}}>
                                            <div className="text">
                                                {dataTable?.label === 'พื้นที่ทำงาน (ไร่)' &&
                                                    t('dashboard.averageWorkingAreaPerDrone')}
                                                {dataTable?.label === 'ชั่วโมงบิน (ชม.)' &&
                                                    t('dashboard.averageFlightHoursPerDrone')}
                                                {dataTable?.label === 'จำนวนวันทำงาน (วัน)' &&
                                                    t('dashboard.averageWorkingDaysPerDrone')}
                                                {dataTable?.label === 'อายุการใช้งาน (วัน)' &&
                                                    t('dashboard.averageOperationalAgePerDrone')}
                                            </div>
                                            <div className="text-percent">
                                                {dataTable?.label === 'พื้นที่ทำงาน (ไร่)' ||
                                                dataTable?.label === 'ชั่วโมงบิน (ชม.)'
                                                    ? twoDecimal.format(dataTable?.total || 0)
                                                    : nonDecimal.format(dataTable?.total || 0)}
                                            </div>

                                            <span
                                                className={`badge badge-soft-${
                                                    parseFloat(dataTable?.drones_percentage) > 0
                                                        ? 'success'
                                                        : parseFloat(dataTable?.drones_percentage) < 0
                                                        ? 'danger'
                                                        : 'warning'
                                                }`}>
                                                {parseFloat(dataTable?.drones_percentage) > 0
                                                    ? '+'
                                                    : parseFloat(dataTable?.drones_percentage) < 0
                                                    ? ''
                                                    : ''}{' '}
                                                {dataTable?.drones_percentage}%
                                            </span>
                                        </div>
                                    </div>
                                    {dataDonutCharts?.length > 0 && (
                                        <div id="chart">
                                            <ReactApexChart
                                                options={{
                                                    labels: labelsChart || [],
                                                    colors:
                                                        colorChart.length > 0
                                                            ? colorChart
                                                            : [
                                                                  '#50a5f1',
                                                                  '#db76ff',
                                                                  '#f1b44c',
                                                                  '#556ee6',
                                                                  '#f46a6a',
                                                                  '#fe8b38',
                                                                  '#34c38f',
                                                                  '#38e6fe',
                                                              ],
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    stroke: {
                                                        width: 0,
                                                    },
                                                    tooltip: {
                                                        fillSeriesColor: false,
                                                        theme: 'light',
                                                        style: {
                                                            fontSize: '12px',
                                                            fontFamily: undefined,
                                                        },
                                                        shared: false,
                                                        intersect: true,
                                                        y: {
                                                            formatter: function (y) {
                                                                let unit = ''

                                                                if (dataTable?.label === 'จำนวนโดรนทั้งหมด') {
                                                                    unit = t('common.droneUnit')
                                                                }

                                                                if (dataTable?.label === 'พื้นที่ทำงาน (ไร่)') {
                                                                    unit = ` ${t('common.raiUnit')}/${t(
                                                                        'common.droneUnit',
                                                                    )}`
                                                                }

                                                                if (dataTable?.label === 'ชั่วโมงบิน (ชม.)') {
                                                                    unit = ` ${t('common.hourUnit')}/${t(
                                                                        'common.droneUnit',
                                                                    )}`
                                                                }

                                                                if (
                                                                    dataTable?.label === 'จำนวนวันทำงาน (วัน)' ||
                                                                    dataTable?.label === 'อายุการใช้งาน (วัน)'
                                                                ) {
                                                                    unit = ` ${t('common.days')}/${t(
                                                                        'common.droneUnit',
                                                                    )}`
                                                                }

                                                                return `${twoDecimal.format(y)}  ${unit}`
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        show: false,
                                                        position: 'bottom',
                                                        horizontalAlign: 'center',
                                                        verticalAlign: 'middle',
                                                        floating: false,
                                                        fontSize: '14px',
                                                        offsetX: 0,
                                                        offsetY: -10,
                                                    },
                                                }}
                                                series={dataDonutCharts}
                                                type="donut"
                                                height="225"
                                                className="apex-charts"></ReactApexChart>
                                        </div>
                                    )}
                                </Col>
                                <Col lg={7}>
                                    <TableDrone
                                        type={'average'}
                                        data={dataTable?.drone_models}
                                        filter={searchFilter}
                                        label={dataTable?.label}
                                        onChangePagination={onChangePagination}
                                        drone_models_last={dataTable?.drone_models_last}
                                        total={dataTable?.pagination?.total_records}
                                        page={page}
                                    />
                                </Col>
                            </Row>
                        )}
                        {dataDonutCharts?.length === 0 && !skeleton && (
                            <div
                                style={{
                                    width: '100%',
                                    height: 500,

                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                <p style={{fontSize: 20}}>{t('common.noData')}</p>
                            </div>
                        )}
                    </CardBody>
                </Card>
            )}
        </Col>
    )
}

export default withTranslation()(DroneType)
