import React from 'react'
import './flight-history-detail-skeleton.scss'
import {Row, Col, Card, CardBody} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const MapFlightHistorySkeleton = () => {
    return (
        <Row className="row row-map">
            <Col xl={12} lg={12}>
                <Skeleton width={749} height={820} />
            </Col>
        </Row>
    )
}

export default MapFlightHistorySkeleton
