import React, {useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {
    Row,
    Col,
    Card,
    CardBody,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    TabContent,
    TabPane,
} from 'reactstrap'
import {useRecoilState, useSetRecoilState} from 'recoil'
import html2canvas from 'html2canvas'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import {DatePicker, Space, Select} from 'antd'
const {RangePicker} = DatePicker
const {Option} = Select

import {buttonFilterTimeRange, optionsCountry} from 'constants/lookup'
import {getFilterRegion, getFilterDrone} from 'services/skc/lookup'

import {filterDashboardState, loadingDashboardState} from 'store/atoms/dashboard'

import iconExport from '../../assets/images/icon/iconexport.png'
import './style/navbar.scss'

const SearchFilterDashboard = props => {
    const {t, i18n} = props

    const [lutDrones, setLutDrones] = useState([])
    const [lutRegion, setLutRegion] = useState([])

    const [selectDrones, setSelectDrones] = useState([])
    const [selectCountry, setSelectCountry] = useState('all')
    const [selectRegion, setSelectRegion] = useState([])

    const [filterDashboard, setFilterDashboard] = useRecoilState(filterDashboardState)
    const setLoading = useSetRecoilState(loadingDashboardState)

    const [scrollTop, setScrollTop] = useState(0)
    const [activeTab, setActiveTab] = useState('year')
    const [activeExport, setActiveExport] = useState(false)
    const [disabledRegion, setDisabledRegion] = useState(true)
    const [date, setDate] = useState(dayjs())
    const [dateDynamic, setDateDynamic] = useState([])

    useEffect(() => {
        window.addEventListener('scroll', setSearchBarToTop)

        return () => {
            window.removeEventListener('scroll', setSearchBarToTop)
        }
    }, [])

    useEffect(() => {
        fetchLutRegion()
        fetchLutDrone()
    }, [])

    useEffect(() => {
        return () => {
            setFilterDashboard({
                type: 'year',
                country: 'all',
                region: [],
                drone: [],
                week: dayjs().week(),
                month: dayjs().month() + 1,
                year: dayjs().year(),
                start_date: dayjs().startOf('year').format('YYYY-MM-DD'),
                end_date: dayjs().endOf('year').format('YYYY-MM-DD'),
            })
        }
    }, [])

    const fetchLutRegion = async () => {
        try {
            const response = await getFilterRegion()

            if (response.status_code === 200 && response.results.length > 0) {
                setLutRegion(response.results)
            }
        } catch (error) {
            setLutRegion([])
        }
    }

    const fetchLutDrone = async () => {
        try {
            const response = await getFilterDrone()

            if (response.status_code === 200 && response.results.length > 0) {
                setLutDrones(response.results)
            }
        } catch (error) {
            setLutDrones([])
        }
    }

    const setSearchBarToTop = () => {
        setScrollTop(window.pageYOffset)
    }

    const toggleTab = tab => {
        setActiveTab(tab)
    }

    const customWeekStartEndFormat = value =>
        `${dayjs(value).startOf('week').format(weekFormat)} - ${dayjs(value).endOf('week').format(weekFormat)}`

    const disabledDate = current => {
        return current && current > dayjs().endOf('day')
    }

    const weekFormat = 'DD MMMM YYYY'
    const monthFormat = 'MMMM YYYY'

    const handleChangeTime = date => {
        if (activeTab === 'period') {
            setDateDynamic(date)
        } else {
            setDate(date)
        }
    }

    const onChangeCountry = value => {
        setSelectCountry(value)

        if (value === 'th') {
            setDisabledRegion(false)
        } else {
            setDisabledRegion(true)
            setSelectRegion([])
        }
    }

    const handleChangeRegion = value => {
        setSelectRegion(value)
    }

    const handleChangeDrones = value => {
        setSelectDrones(value)
    }

    const toggleOther = () => {
        setActiveExport(current => !current)
    }

    const downloadScreenshot = ext => {
        html2canvas(document.getElementById('page-content'), {
            scale: 1.2,
        }).then(async function (canvas) {
            const data = await canvas.toDataURL(`image/${ext}`)
            const link = document.createElement('a')

            link.href = data
            link.download = `${dayjs().format('DD-MM-YYYY-hmmss')}.${ext}`

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        })
    }

    const handleChangeSubmit = () => {
        let params = {
            ...filterDashboard,
            type: activeTab,
            country: selectCountry,
            region: selectRegion,
            drone: selectDrones,
        }

        if (activeTab === 'year') {
            params = {
                ...params,
                week: dayjs(date).week(),
                month: dayjs(date).month() + 1,
                year: dayjs(date).year(),
                start_date: dayjs(date).startOf('year').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('year').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'month') {
            params = {
                ...params,
                week: dayjs(date).week(),
                month: dayjs(date).month() + 1,
                year: dayjs(date).year(),
                start_date: dayjs(date).startOf('month').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('month').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'week') {
            params = {
                ...params,
                week: dayjs(date).week(),
                month: dayjs(date).month() + 1,
                year: dayjs(date).year(),
                start_date: dayjs(date).startOf('week').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('week').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'period') {
            params = {
                ...params,

                start_date: dayjs(dateDynamic[0]).format('YYYY-MM-DD'),
                end_date: dayjs(dateDynamic[1]).format('YYYY-MM-DD'),
            }
        }

        setFilterDashboard(params)
        setLoading({
            cumulative: true,
            map: true,
            drone_overview: true,
            drone_type: true,
            working_drone: true,
            total_area_average_flight: true,
            flight_history: true,
        })
    }

    return (
        <React.Fragment>
            <div className={`${scrollTop > 0 && 'main-nav-scrolled'}`}>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={12}>
                                        <div className="d-flex">
                                            <div className={'px-1 d-flex'}>
                                                {buttonFilterTimeRange?.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {item.value !== 'halfYear' && item.value !== 'quarter' && (
                                                                <Button
                                                                    color="secondary"
                                                                    outline
                                                                    style={{
                                                                        fontFamily: 'Kanit',
                                                                        fontWeight: 400,
                                                                        fontSize: 14,
                                                                        borderColor: '#E2E2E2',
                                                                        borderRadius: 8,
                                                                        color:
                                                                            activeTab === item.value
                                                                                ? '#fff'
                                                                                : '#495057',
                                                                        backgroundColor:
                                                                            activeTab === item.value && '#79829C',
                                                                        marginRight: 10,
                                                                    }}
                                                                    onClick={() => toggleTab(item.value)}>
                                                                    {i18n.language === 'th'
                                                                        ? item.title_th
                                                                        : item.title_en}
                                                                </Button>
                                                            )}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className={'px-1'}>
                                                <TabContent activeTab={activeTab}>
                                                    <TabPane tabId="week">
                                                        <Space direction="vertical">
                                                            <DatePicker
                                                                style={{
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: 400,
                                                                    fontSize: 14,
                                                                    borderColor: '#CED4DA',
                                                                    borderRadius: 8,
                                                                    color: '#495057',
                                                                    height: 38,
                                                                }}
                                                                value={date}
                                                                format={customWeekStartEndFormat}
                                                                disabledDate={disabledDate}
                                                                picker="week"
                                                                bordered={true}
                                                                onChange={handleChangeTime}
                                                            />
                                                        </Space>
                                                    </TabPane>
                                                    <TabPane
                                                        tabId="month"
                                                        id="v-pills-payment"
                                                        role="tabpanel"
                                                        aria-labelledby="v-pills-payment-tab">
                                                        <DatePicker
                                                            style={{
                                                                fontFamily: 'Inter',
                                                                fontWeight: 400,
                                                                fontSize: 14,
                                                                borderColor: '#CED4DA',
                                                                borderRadius: 8,
                                                                color: '#495057',
                                                                height: 38,
                                                                width: 210,
                                                            }}
                                                            disabledDate={disabledDate}
                                                            picker="month"
                                                            bordered={true}
                                                            value={date}
                                                            format={monthFormat}
                                                            onChange={handleChangeTime}
                                                        />
                                                    </TabPane>
                                                    <TabPane tabId="year" id="v-pills-confir" role="tabpanel">
                                                        <DatePicker
                                                            style={{
                                                                fontFamily: 'Inter',
                                                                fontWeight: 400,
                                                                fontSize: 14,
                                                                borderColor: '#CED4DA',
                                                                borderRadius: 8,
                                                                color: '#495057',
                                                                height: 38,
                                                            }}
                                                            disabledDate={disabledDate}
                                                            picker="year"
                                                            value={date}
                                                            bordered={true}
                                                            onChange={handleChangeTime}
                                                        />
                                                    </TabPane>
                                                    <TabPane tabId="period">
                                                        <RangePicker
                                                            style={{
                                                                fontFamily: 'Inter',
                                                                fontWeight: 400,
                                                                fontSize: 14,
                                                                borderColor: '#CED4DA',
                                                                borderRadius: 8,
                                                                color: '#495057',
                                                                height: 38,
                                                            }}
                                                            format="DD MMM YYYY"
                                                            disabledDate={disabledDate}
                                                            onChange={handleChangeTime}
                                                        />
                                                    </TabPane>
                                                </TabContent>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} className="d-flex align-items-center  mt-2 ">
                                        <p
                                            style={{
                                                fontFamily: 'Kanit',
                                                fontWeight: 400,
                                                fontSize: 14,
                                                margin: 0,
                                                padding: 0,
                                                marginLeft: 18,
                                                marginRight: i18n.language === 'th' ? 0 : 20,

                                                width: 60,
                                            }}>
                                            {t(`common.country`) + ' :'}
                                        </p>

                                        <Select
                                            style={{
                                                width: '70%',
                                                borderColor: '#CED4DA',
                                                borderRadius: 8,
                                                color: '#495057',
                                            }}
                                            placeholder={t(`common.all`)}
                                            value={selectCountry}
                                            onChange={onChangeCountry}
                                            className="ms-3">
                                            {optionsCountry?.map((option, index) => (
                                                <Option
                                                    key={index}
                                                    value={option.value}
                                                    label={i18n.language === 'th' ? option.label_th : option.label_en}>
                                                    {i18n.language === 'th' ? option.label_th : option.label_en}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col lg={9} className="d-flex align-items-center  mt-2">
                                        <p
                                            style={{
                                                fontFamily: 'Kanit',
                                                fontWeight: 400,
                                                fontSize: 14,
                                                margin: 0,
                                                padding: 0,
                                                marginLeft: 18,
                                            }}>
                                            {t(`common.region`) + ' :'}
                                        </p>

                                        <Select
                                            mode="multiple"
                                            className="container-select-drone"
                                            style={{
                                                width: '59%',
                                                borderColor: '#CED4DA',
                                                borderRadius: 8,
                                                color: '#495057',
                                            }}
                                            disabled={disabledRegion}
                                            placeholder={t(`common.all`)}
                                            value={selectRegion}
                                            optionFilterProp="children"
                                            onChange={handleChangeRegion}>
                                            <Option value={'other'} label={t('common.areaNotSpecified')}>
                                                {t('common.areaNotSpecified')}
                                            </Option>
                                            {lutRegion?.map(
                                                (option, index) =>
                                                    option.reg_code != 1 && (
                                                        <Option
                                                            key={index}
                                                            value={option.reg_code}
                                                            label={
                                                                i18n.language === 'en' ? option.name_en : option.name_th
                                                            }>
                                                            {i18n.language === 'en' ? option.name_en : option.name_th}
                                                        </Option>
                                                    ),
                                            )}
                                        </Select>
                                    </Col>
                                    <Col lg={9} className="d-flex align-items-center  mt-3">
                                        <p
                                            style={{
                                                fontFamily: 'Kanit',
                                                fontWeight: 400,
                                                fontSize: 14,
                                                margin: 0,
                                                padding: 0,
                                                marginLeft: 18,
                                            }}>
                                            {t(`common.droneModel`) + ' :'}
                                        </p>

                                        <Select
                                            style={{width: i18n.language === 'th' ? '93.1%' : '91%'}}
                                            mode="multiple"
                                            className="container-select-drone"
                                            placeholder={t(`common.all`)}
                                            value={selectDrones}
                                            onChange={handleChangeDrones}
                                            showSearch
                                            optionFilterProp="children">
                                            {lutDrones?.map((item, index) => (
                                                <Option key={index} value={item.id} label={item.name}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col lg={2} className="d-flex align-items-center  mt-3">
                                        <div className={'d-flex'}>
                                            <button
                                                onClick={() => {
                                                    handleChangeSubmit()
                                                }}
                                                type="button"
                                                className="btn btn-primary w-xs"
                                                style={{
                                                    borderColor: '#00A69C',
                                                    borderRadius: 8,
                                                    backgroundColor: '#00A69C',
                                                    marginLeft: 16,
                                                }}>
                                                {t(`common.searchInformation`)}
                                            </button>
                                            <Dropdown
                                                isOpen={activeExport}
                                                toggle={toggleOther}
                                                direction="right"
                                                className=" ms-3 dropend"
                                                style={{
                                                    paddingTop: 5,
                                                    cursor: 'pointer',
                                                }}>
                                                <DropdownToggle tag="i" className="text-muted">
                                                    <img
                                                        style={{boxShadow: 'none'}}
                                                        src={iconExport}
                                                        alt=""
                                                        height="26"
                                                    />
                                                </DropdownToggle>
                                                <DropdownMenu data-popper-placement="right-start">
                                                    <DropdownItem
                                                        header
                                                        style={{
                                                            backgroundColor: '#F3F3F3',
                                                            fontSize: 12,
                                                            fontWeight: 600,
                                                            fontFamily: 'Inter',
                                                            color: '#212529',
                                                        }}>
                                                        Export to
                                                    </DropdownItem>

                                                    <DropdownItem
                                                        style={{
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                            fontFamily: 'Inter',
                                                            color: '#212529',
                                                        }}
                                                        onClick={() => {
                                                            downloadScreenshot('png')
                                                        }}>
                                                        png
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        style={{
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                            fontFamily: 'Inter',
                                                            color: '#212529',
                                                        }}
                                                        onClick={() => {
                                                            downloadScreenshot('jpeg')
                                                        }}>
                                                        jpeg
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default withTranslation()(SearchFilterDashboard)
