import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {Card, CardBody, CardTitle} from 'reactstrap'

const DataPilotSkeleton = ({}) => {
    const loadCards = Array(5).fill(1)
    return (
        <Card
            style={{
                display: 'flex',
                borderRadius: 8,
                boxShadow: '0px 12px 24px rgb(240, 240, 240)',
                height: 496,
            }}>
            <CardTitle
                className="card-title  text-center "
                style={{
                    fontSize: 20,
                    fontWeight: 600,
                    fontFamily: 'Kanit',
                    marginTop: 32,
                }}>
                <Skeleton circle width={54} height={54} style={{}} />
                <Skeleton width={120} style={{marginTop: 12}} />
                <Skeleton width={180} height={16} style={{}} />
            </CardTitle>
            <CardBody>
                {loadCards.map((card, i) => (
                    <div key={i}>
                        <div className="row">
                            <div className="col-4">
                                <Skeleton />
                            </div>
                            <div className="col-8">
                                <Skeleton />
                            </div>
                        </div>
                        <Skeleton height={1} style={{marginBottom: 10}} />
                    </div>
                ))}
            </CardBody>
        </Card>
    )
}

export default DataPilotSkeleton
