import React, {useState} from 'react'
import {withTranslation} from 'react-i18next'
import {Col, Card, CardBody, Button, Form, TabContent, TabPane} from 'reactstrap'
import {DatePicker, Space} from 'antd'
import {useRecoilState} from 'recoil'

import dayjs from 'dayjs'
import 'dayjs/locale/th'

import {filterNavbarUsageState, loadingDroneInformationState} from 'store/atoms/drone-information'

import SkeletonNavbarUsage from 'components/DroneiNalyze/Skeleton-DroneInformation/Usage/SkeletonNavbarUsage'
import {buttonFilterTimeRange} from 'constants/lookup'

const UsageNavbar = props => {
    const {t, i18n} = props

    const [filterNavbarUsage, setFilterNavbarUsage] = useRecoilState(filterNavbarUsageState)
    const [loadingDroneInformation, setLoadingDroneInformation] = useRecoilState(loadingDroneInformationState)

    const [activeTab, setActiveTab] = useState('year')
    const [date, setDate] = useState(filterNavbarUsage.date)

    const weekFormat = 'DD MMMM YYYY'
    const monthFormat = 'MMMM YYYY'

    const customWeekStartEndFormat = value =>
        `${dayjs(value).startOf('week').format(weekFormat)} - ${dayjs(value).endOf('week').format(weekFormat)}`

    const disabledDate = current => {
        return current && current > dayjs().endOf('day')
    }

    const toggleTab = tab => {
        setActiveTab(tab)
    }

    const handleChangeTime = date => {
        setDate(date)
    }

    const handleChangeSubmit = () => {
        let params = {
            ...filterNavbarUsage,
            type: activeTab,
            week: dayjs(date).week(),
            month: dayjs(date).month() + 1,
            year: dayjs(date).year(),
        }

        if (activeTab === 'year') {
            setFilterNavbarUsage({
                ...params,
                start_date: dayjs(date).startOf('year').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('year').format('YYYY-MM-DD'),
            })
        }

        if (activeTab === 'month') {
            setFilterNavbarUsage({
                ...params,
                start_date: dayjs(date).startOf('month').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('month').format('YYYY-MM-DD'),
            })
        }

        if (activeTab === 'week') {
            setFilterNavbarUsage({
                ...params,
                start_date: dayjs(date).startOf('week').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('week').format('YYYY-MM-DD'),
            })
        }

        setLoadingDroneInformation({
            detail: true,
            fly_duration: true,
            working_area: true,
            speed: true,
            height: true,
            flight_time_history: true,
            flight_lists_history: true,
        })
    }

    return (
        <React.Fragment>
            <Col lg="12">
                <Card
                    style={{
                        borderRadius: 8,
                    }}>
                    <CardBody>
                        {loadingDroneInformation.fly_time ? (
                            <SkeletonNavbarUsage activeTab={activeTab} />
                        ) : (
                            <Form className="row gy-2 gx-3 align-items-center">
                                <div className="col-sm-auto">
                                    <div className="row-sm-auto button-items">
                                        {buttonFilterTimeRange?.map((item, index) => {
                                            if (
                                                item.value !== 'quarter' &&
                                                item.value !== 'halfYear' &&
                                                item.value !== 'period'
                                            ) {
                                                return (
                                                    <Button
                                                        key={index}
                                                        color="secondary"
                                                        outline
                                                        style={{
                                                            fontFamily: 'Kanit',
                                                            fontWeight: 400,
                                                            fontSize: 14,
                                                            borderColor: '#E2E2E2',
                                                            borderRadius: 8,
                                                            color: activeTab === item.value ? '#fff' : '#495057',
                                                            backgroundColor: activeTab === item.value && '#79829C',
                                                        }}
                                                        onClick={() => toggleTab(item.value)}>
                                                        {i18n.language === 'th' ? item.title_th : item?.title_en}
                                                    </Button>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>

                                <div className="col-sm-auto">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="week">
                                            <Space direction="vertical" size={13}>
                                                <DatePicker
                                                    style={{
                                                        fontFamily: 'Inter',
                                                        fontWeight: 400,
                                                        fontSize: 14,
                                                        borderColor: '#CED4DA',
                                                        borderRadius: 8,
                                                        color: '#495057',
                                                        height: 38,
                                                    }}
                                                    value={date}
                                                    format={customWeekStartEndFormat}
                                                    disabledDate={disabledDate}
                                                    picker="week"
                                                    bordered={true}
                                                    onChange={handleChangeTime}
                                                />
                                            </Space>
                                        </TabPane>
                                        <TabPane
                                            tabId="month"
                                            id="v-pills-payment"
                                            role="tabpanel"
                                            aria-labelledby="v-pills-payment-tab">
                                            <Space direction="vertical" size={12}>
                                                <DatePicker
                                                    style={{
                                                        fontFamily: 'Inter',
                                                        fontWeight: 400,
                                                        fontSize: 14,
                                                        borderColor: '#CED4DA',
                                                        borderRadius: 8,
                                                        color: '#495057',
                                                        height: 38,
                                                    }}
                                                    picker="month"
                                                    bordered={true}
                                                    value={date}
                                                    format={monthFormat}
                                                    onChange={handleChangeTime}
                                                    disabledDate={disabledDate}
                                                />
                                            </Space>
                                        </TabPane>
                                        <TabPane tabId="year" id="v-pills-confir" role="tabpanel">
                                            <Space direction="vertical" size={12}>
                                                <DatePicker
                                                    style={{
                                                        fontFamily: 'Inter',
                                                        fontWeight: 400,
                                                        fontSize: 14,
                                                        borderColor: '#CED4DA',
                                                        borderRadius: 8,
                                                        color: '#495057',
                                                        height: 38,
                                                    }}
                                                    picker="year"
                                                    value={date}
                                                    bordered={true}
                                                    onChange={handleChangeTime}
                                                    disabledDate={disabledDate}
                                                />
                                            </Space>
                                        </TabPane>
                                    </TabContent>
                                </div>

                                <div className="col-sm-auto d-flex">
                                    <button
                                        onClick={handleChangeSubmit}
                                        type="button"
                                        className="btn btn-primary w-xs"
                                        style={{
                                            borderColor: '#00A69C',
                                            borderRadius: 8,
                                            backgroundColor: '#00A69C',
                                        }}>
                                        {t('common.searchInformation')}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
}

export default withTranslation()(UsageNavbar)
