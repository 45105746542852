import React, {Component} from 'react'
import {Card, CardBody, Col, Row, Input, Label, Button, InputGroup, Table} from 'reactstrap'
import {Pagination} from 'antd'
import './index.scss'
import moment from 'moment'

export default class DataTableMonitoring extends Component {
    constructor(props) {
        super(props)
        // console.log("this.props :>> ", this.props);
        this.state = {
            // column: this.props.column,
            // row: this.props.row,
            // action: this.props.action,
            sortType: 'ASC',
            // isPagination: this.props.isPagination ?? false,
            // pageSize: this.props.pageSize ?? 10,
            // total: this.props.total ?? 0,
            // rowPerpage: this.props.rowPerpage ?? 0,
            // onChangePagination: this.props.onChangePagination,
            page: 1,
            pageSize: 10,
            defaultPage: 1,
            showIndex: this.props.isShowIndex ?? true,
            borderLine: this.props.isShowBorderLine ?? true,
        }

        this.handleSort = this.handleSort.bind(this)
    }
    handleSort(columnName) {
        // console.log("columnName", columnName);
        let sortType
        // console.log("columnName :>> ", columnName);

        const sortedData = [...this.props.row].sort((a, b) => {
            if (this.state.sortType == 'ASC') {
                // console.log("min");
                sortType = 'DESC'
                // if (a[columnName] < b[columnName]) return -1;
                // if (a[columnName] > b[columnName]) return 1;
                // return a[columnName] - b[columnName];
            } else {
                // console.log("max");
                sortType = 'ASC'
                // if (a[columnName] > b[columnName]) return -1;
                // if (a[columnName] < b[columnName]) return 1;
                // return b[columnName] - a[columnName];
            }
        })

        if (columnName === 'hour') {
            this.props.onSort('hoursort', sortType)
        } else {
            this.props.onSort(columnName, sortType)
        }
        // console.log("sortedData", sortedData);
        // console.log("sortedData :>> ", sortedData);
        this.setState({row: sortedData, sortType})
        this.state.row = sortedData
    }

    handleChangePagnation(page, pageSize) {
        this.props.onChangePagination(page, pageSize)
        this.setState({page, pageSize})
    }

    render() {
        const state = this.state
        // console.log(this.props.row)
        return (
            <>
                <div className="table-header-fixed">
                    <Table
                        className="table table-hover  table-bordered  mb-0 data-table"
                        style={{
                            borderLeft: state.isShowBorderLine ? '' : 'none',
                            borderRight: state.isShowBorderLine ? '' : 'none',
                        }}>
                        <thead className="table-header" style={{zIndex: 0}}>
                            <tr>
                                {state.showIndex && <th className="column-index">#</th>}
                                {this.props.column.map((itm, index) => (
                                    <th
                                        key={`${itm.key}-${index}`}
                                        style={{
                                            textAlign: 'center',
                                            borderRight: '1px rgba(73, 80, 87, 0.1) solid',
                                            borderLeft: !state.isShowBorderLine ?? 'none',
                                            borderRight: !state.isShowBorderLine ?? 'none',
                                        }}>
                                        <div className="container-text-column">
                                            {itm.title}
                                            {itm.sort && (
                                                <i
                                                    className="bx bx-sort icon-sort"
                                                    onClick={() => this.handleSort(itm.key)}></i>
                                            )}
                                        </div>
                                    </th>
                                ))}
                                {this.props.action?.length > 0 && <th className="column-action">Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.row.length > 0 &&
                                this.props.row.map(
                                    (itemRow, indexRow) =>
                                        itemRow?.position != undefined && (
                                            <tr key={`${itemRow}-${indexRow}`}>
                                                {/* {state.showIndex && (
                      <th scope="row" width={"3%"} style={{ textAlign: "center" }}>
                        {(state.page - 1) * state.pageSize + (indexRow + 1)}
                      </th>
                    )} */}
                                                {/* <td
                        className="row-data"
                      >
                         <div
                            style={{
                              borderRadius: "16px",
                              height: "100%",
                              padding: "4px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "20px",
                            }}
                          >
                            <p style={{ margin: 0, padding: 0 }}>{
                              (itemRow.farm_mode == 0) ? 'None' : 
                              (itemRow.farm_mode == 1) ? 'Manual' : 
                              (itemRow.farm_mode == 2) ? 'AB' :
                              (itemRow.farm_mode == 3) ? 'M+' : 'Auto'
                            }</p>
                          </div>
                      </td> */}
                                                {/* <td
                        className="row-data"
                      >
                         <div
                            style={{
                              borderRadius: "16px",
                              height: "100%",
                              padding: "4px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "20px",
                            }}
                          >
                            <p style={{ margin: 0, padding: 0 }}>{itemRow.hardwareId}</p>
                          </div>
                      </td> */}
                                                <td className="row-data">
                                                    <div
                                                        style={{
                                                            borderRadius: '16px',
                                                            // width: 66,
                                                            height: '100%',
                                                            padding: '4px',
                                                            textAlign: 'left',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            // marginLeft: "20px",
                                                        }}>
                                                        <p style={{margin: 0, padding: 0}}>
                                                            {itemRow.dji_sn ? itemRow.dji_sn : ''}
                                                        </p>
                                                    </div>
                                                </td>
                                                <td className="row-data">
                                                    <div
                                                        style={{
                                                            borderRadius: '16px',
                                                            // width: 66,
                                                            height: '100%',
                                                            padding: '4px',
                                                            textAlign: 'left',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            // marginLeft: "20px",
                                                        }}>
                                                        <p style={{margin: 0, padding: 0}}>
                                                            {itemRow.kubota_sn ? itemRow.kubota_sn : ''}
                                                        </p>
                                                    </div>
                                                </td>
                                                <td className="row-data">
                                                    <div
                                                        style={{
                                                            borderRadius: '16px',
                                                            // width: 66,
                                                            height: '100%',
                                                            padding: '4px',
                                                            textAlign: 'left',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            // marginLeft: "20px",
                                                        }}>
                                                        <p style={{margin: 0, padding: 0}}>{itemRow?.hardware_model}</p>
                                                    </div>
                                                </td>
                                                <td className="row-data">
                                                    <div
                                                        style={{
                                                            borderRadius: '16px',
                                                            // width: 66,
                                                            height: '100%',
                                                            padding: '4px',
                                                            textAlign: 'left',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            // marginLeft: "20px",
                                                        }}>
                                                        <p style={{margin: 0, padding: 0}}>
                                                            {itemRow.pilot_firstname != undefined
                                                                ? itemRow.pilot_firstname
                                                                : '-'}
                                                        </p>
                                                    </div>
                                                </td>
                                                {/* <td
                        className="row-data"
                      >
                         <div
                            style={{
                              borderRadius: "16px",
                              height: "100%",
                              padding: "4px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "20px",
                            }}
                          >
                            <p style={{ margin: 0, padding: 0 }}>{(itemRow.spray_flag) ? itemRow.flow_speed.toFixed(2)+' L/Min' : ''}</p>
                          </div>
                      </td> */}
                                                {/* <td
                        className="row-data"
                      >
                         <div
                            style={{
                              borderRadius: "16px",
                              height: "100%",
                              padding: "4px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "20px",
                            }}
                          >
                            <p style={{ margin: 0, padding: 0 }}>{(itemRow.fusion_height) ? itemRow.fusion_height+' m' : ''}</p>
                          </div>
                      </td> */}
                                                {this.props.action?.length > 0 && (
                                                    <td
                                                        style={{
                                                            // width: 20,
                                                            margin: 0,
                                                            padding: 0,
                                                            borderLeft: !state.isShowBorderLine ?? 'none',
                                                            borderRight: !state.isShowBorderLine ?? 'none',
                                                        }}
                                                        className="row-action"
                                                        width="5%">
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                margin: '4px',
                                                                padding: 0,
                                                            }}>
                                                            {this.props.action.map((itm, index) => (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        margin: 2,
                                                                    }}
                                                                    onClick={() => itm.onClick(itemRow.hardwareId)}>
                                                                    {itm.button}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </td>
                                                )}
                                            </tr>
                                        ),
                                )}
                        </tbody>
                    </Table>
                </div>
                {this.props.isPagination && (
                    <div
                        className="container-pagination"
                        style={{
                            marginTop: 20,
                            marginBottom: 16,
                        }}>
                        <Pagination
                            defaultCurrent={1}
                            total={this.props.total}
                            defaultPageSize={this.props.pageSize}
                            onChange={(page, pageSize) => this.handleChangePagnation(page, pageSize)}
                            // onShowSizeChange={e => console.log("e-sizeChange", e)}
                        />
                    </div>
                )}
            </>
        )
    }
}
