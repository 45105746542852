const droneInformation = {
    overall: 'Overall',
    usage: 'Usage',
    userInformation: 'User Information',
    usageDuration: 'Usage Duration',
    pilot: 'Pilot',
    phoneNumber: 'Phone Number',
    email: 'Email',
    address: 'Address',
    droneOwnerInformation: 'Drone owner information',
    totalNumberOfFlights: 'Total number of flights',
    totalFlightDuration: 'Total fight duration',
    averageFlightDuration: 'Average flight duration',
    totalWorkingArea: 'Total working area',
    averageWorkingArea: 'Average working area',
    numberOfSpraying: 'Number of Spraying',
    totalSprayingArea: 'Total spraying area',
    totalSprayingQuantity: 'Total spraying quantity',
    averageSprayingQuantity: 'Average spraying quantity',
    numberOfSpreading: 'Number of spreading',
    totalSpreadingArea: 'Total Spreading area',
    totalSpreadingQuantity: 'Total Spreading area',
    averageSpreadingQuantity: 'Average Spreading quantity',
    latestDroneLocation: 'Latest drone location',
    latestDroneLocationNotFound: 'Latest drone location not fund',
    details: 'Details',
    spraying: 'Spraying',
    spreading: 'Spreading',
    totalFlightTime: 'Total flight time',
    numberOfSpreader: 'Number of Spreader',
    spreaderQuantity: 'Spreader quantity',
    sprayingQuantity: 'Spraying quantity',
    totalFlightTimesMinutes: 'Total Flight times (Minutes)',
    averageFlightTimesMinutesMonth: 'Average flight times Min/Month',
    averageFlightTimesMinutesDays: 'Average flight times Min/Days',
    averageFlightTimesWorkingPeriodMinMonth: 'Average flight time (Working period) Min/Month',
    averageFlightTimesWorkingPeriodMinDays: 'Average flight time (Working period) Min/Days',
    flightTimes: 'Flight times',
    averageFlightTimesWorkingPeriod: 'Average flight time (Working period)',
    averageFlightTimesTotal: 'Average flight time (Total)',
    flightTimesMinutes: 'Flight times (Minutes)',
    totalWorkingAreaRaiUnit: 'Total working area (Rai)',
    averageWorkingAreaRaiMonth: 'Average working area  (Rai/Month)',
    averageWorkingAreaRaiDays: 'Average working area  (Rai/Days)',
    averageWorkingAreaWorkingPeriodRaiMonth: 'Average working area (Working period) (Rai/Month)',
    averageWorkingAreaWorkingPeriodRaiDays: 'Average working area (Working period) (Rai/Days)',
    numberOfWorkingAreas: 'Number of working areas',
    averageWorkingAreaWorkingPeriod: 'Average working area (Working period)',
    averageWorkingAreaTotal: 'Average working area (Total)',
    workingAreaRai: 'Working area (Rai)',
    numberOfFlights: 'Number of Flights',
    average: 'Average',
    speedUsed: 'Speed Used',
    speedMsUnit: 'Speed (m/s)',
    heightUsed: 'Height Used',
    heightMUnit: ' Height (m)',
    median: 'Median',
    mode: 'Mode',
    totalFlightHistory: 'Total flight history',
    dronesFlightHistory: `Drone's flight history`,
    flightTimeRanking: 'Flight time ranking',
    flightDate: 'Flight date',
    location: 'Location',
    flightTimeMUnit: 'Flight time (M.)',
    averageSpeedMSUnit: 'Average speed (m/s)',
    workingType: 'Working type',
    averageSprayingSpreadingQuantity: 'Average Spraying/Spreading quantity',
    spraying: 'Spraying',
    spreading: 'Spreading',
    flightHistory: 'Flight history',
    playFlightHistory: 'Play Flight History',
    flightAt: 'Flight at',
    totalWorkingTime: 'Total working time',
    workingArea: 'Working area',
    flightTimes: 'Flight times',
    averageSpeed: 'Average Speed',
    averageHeight: 'Average Height',
    totalSubstanceAmount: 'Total substance amount',
    operationPattern: 'Operation pattern',
    quantity: 'Quantity',
    flightSpeed: 'Flight Speed',
    height: 'Height',
    routeDistance: 'Route Distance',
    rotationalSpeed: 'Rotational Speed',
    flowRate: 'Flow rate',
    nozzleSize: 'Nozzle Size',
    substanceUsed: 'Substance Used',
    battery: 'Battery',
    latitude: 'Latitude',
    longitude: 'Longitude',
    totalWorkingAreaMap: 'Total working area',
    totalSubstanceUsed: 'Total Substance Used',
    searchForMachines: 'Search for machines',
    workingHours: 'Working hours',
    workType: 'Work type',
    numberOfHours: 'Number of hours',
    minimumHours: 'Minimum hours',
    maximumHours: 'Maximum hours',
}

export default droneInformation
