import React, {useEffect, useState} from 'react'
import {Row, Col} from 'reactstrap'
import {Select} from 'antd'
import {withTranslation} from 'react-i18next'
import {useRecoilState, useSetRecoilState} from 'recoil'

import {filterUserInformationState, loadingUserInformationState} from 'store/atoms/user-information'

const {Option, OptGroup} = Select

import './search-bar.scss'
import {getSearchUserInformation} from 'services/skc/user-information'

const SearchBar = props => {
    const {t} = props

    const [search, setSearch] = useState()
    const [searchResults, setSearchResults] = useState({})

    const [filterUserInformation, setFilterUserInformation] = useRecoilState(filterUserInformationState)
    const setLoadingUserInformation = useSetRecoilState(loadingUserInformationState)

    const handleSelect = value => {
        setSearch(value.value)
        setFilterUserInformation({...filterUserInformation, id: value?.value})
        setLoadingUserInformation({
            data_pilot: true,
            data_drone: true,
            work_overview: true,
            flight_time_ranking: true,
            information_area: true,
            flight_history: true,
            drone_overview: true,
            drone_detail: true,
            drone_flight_duration: true,
            drone_working_area: true,
            drone_speed: true,
            drone_height: true,
            drone_flight_history: true,
            drone_table: true,
        })
    }

    const handleSearch = value => {
        fetchSearch(value)
    }

    useEffect(() => {
        fetchSearch('start')
    }, [])

    const fetchSearch = async params => {
        try {
            const response = await getSearchUserInformation(params)

            if (response?.results) {
                setSearchResults(response?.results)
            }
        } catch (error) {
            setSearchResults({})
        }
    }

    return (
        <div>
            <Row className="justify-content-center d-flex">
                <Col lg={4} className="justify-content-end d-flex align-item?s-center">
                    <label className="mt-1 title">{t('userInformation.searchUser')} :</label>
                </Col>
                <Col lg={8}>
                    <Select
                        className="search-user-user-info"
                        showSearch
                        placeholder={t('userInformation.searchByPilotNamePhoneNumber')}
                        style={{width: '70%'}}
                        filterOption={false}
                        optionLabelProp="label"
                        onSelect={handleSelect}
                        onSearch={handleSearch}
                        labelInValue={true}
                        allowClear={true}
                        value={search}>
                        <OptGroup label={t('userInformation.searchHistory')} key={t('userInformation.searchHistory')}>
                            {searchResults?.recently_searched?.map((item, idx) => (
                                <Option
                                    key={`${item?.user_id}-${idx}-1`}
                                    value={item?.user_id}
                                    label={`${item?.mobile_phone_no || '-'}    ${'\xa0'.repeat(10)}   ${
                                        typeof item?.first_name == 'string' ? item?.first_name : item?.first_name || '-'
                                    } ${
                                        typeof item?.last_name == 'string' ? item?.last_name : item?.last_name || '-'
                                    } ${'\xa0'.repeat(10)} ${item?.email_address || '-'}`}>
                                    <div className="row">
                                        <div className="col-3">{item?.mobile_phone_no || '-'} </div>
                                        <div className="col-4">
                                            {(typeof item?.first_name == 'string'
                                                ? item?.first_name
                                                : item?.first_name || '-') +
                                                ' ' +
                                                (typeof item?.last_name == 'string'
                                                    ? item?.last_name
                                                    : item?.last_name || '-')}
                                        </div>
                                        <div className="col-5">{item?.email_address || '-'}</div>
                                    </div>
                                </Option>
                            ))}
                        </OptGroup>
                        <OptGroup label={t('userInformation.searchResults')} key={t('userInformation.searchResults')}>
                            {searchResults?.data?.map((item, idx) => (
                                <Option
                                    key={`${item?.user_id}-${idx}-2`}
                                    value={item?.user_id}
                                    label={`${item?.mobile_phone_no || '-'} ${
                                        typeof item?.first_name == 'string' ? item?.first_name : item?.first_name || '-'
                                    } ${
                                        typeof item?.last_name == 'string' ? item?.last_name : item?.last_name || '-'
                                    }       ${item?.email_address}`}>
                                    <div className="row">
                                        <div className="col-3">{item?.mobile_phone_no || '-'}</div>
                                        <div className="col-4">
                                            {(typeof item?.first_name == 'string'
                                                ? item?.first_name
                                                : item?.first_name || '-') +
                                                ' ' +
                                                (typeof item?.last_name == 'string'
                                                    ? item?.last_name
                                                    : item?.last_name || '-')}
                                        </div>
                                        <div className="col-5">{item?.email_address || '-'}</div>
                                    </div>
                                </Option>
                            ))}
                        </OptGroup>
                    </Select>
                </Col>
            </Row>
        </div>
    )
}

export default withTranslation()(SearchBar)
