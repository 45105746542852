import React, {useState, useEffect} from 'react'
import {Col, Card, CardBody, CardTitle, TabContent, TabPane, Row} from 'reactstrap'
import {withTranslation} from 'react-i18next'
import {Space, Select} from 'antd'

import {Section, ButtonSubmit, SelectWrapper, SelectWrapperII, SelectButton, DivTitle, H3} from '../component/MyStyle'

import WeekPicker from '../component/weekPicker'
import MonthPicker from '../component/monthPicker'
import YearPicker from '../component/yearPicker'
import QuarterPicker from '../component/quarterPicker'
import HalfYearPicker from '../component/HalfYearPicker'
import CustomPicker from '../component/customPicker'

import {buttonFilterTimeRange, selectOptionCustomerSegmentation} from 'constants/lookup'
import {countryOptions} from '../component/TitleButton'
import BoxPlot from './component/BoxPlot'

import {getFilterRegion, getFilterProvince} from 'services/skc/lookup'
import {getUserInalyzeBoxPlot} from 'services/skc/user-inalyze'

import dayjs from 'dayjs'
import 'dayjs/locale/th'

import BoxPlotSkeleton from '../CardSkeleton/BoxPlotSkeleton'

let quarterOfYear = require('dayjs/plugin/quarterOfYear')

dayjs.extend(quarterOfYear)

const BoxPlotCustomerUserInalyze = props => {
    const {t, i18n} = props

    const [activeTab, setActiveTab] = useState('year')
    const [loadingSkeleton, setLoadingSkeleton] = useState(false)
    const [language, setLanguage] = useState(i18n.language)

    const [date, setDate] = useState(dayjs())
    const [dateDynamic, setDateDynamic] = useState([])

    const [lutProvince, setLutProvince] = useState([])
    const [lutRegion, setLutRegion] = useState([])

    const [selectHalfYear, setSelectHalfYear] = useState(1)

    const [selectY, setSelectY] = useState('fly_amount')
    const [selectX, setSelectX] = useState(['th', 'la', 'kh'])
    const [selectProvince, setSelectProvince] = useState([])
    const [selectRegion, setSelectRegion] = useState([])

    const [disableRegion, setDisableRegion] = useState(true)
    const [disableProvince, setDisableProvince] = useState(true)

    const [dataBoxPlot, setDataBoxPlot] = useState({})
    const [page, setPage] = useState(1)
    const [numPro, setNumPro] = useState(0)

    useEffect(() => {
        fetchLutRegion()
    }, [])

    useEffect(() => {
        const params = {
            type: 'year',
            start_date: '',
            end_date: '',
            year: dayjs(date).year(),
            quarter: '',
            halfYear: 0,
            month: 0,
            week: 0,
            y: 'fly_amount',
            country: ['th', 'la', 'kh'],
            page_size: 5,
            page: 1,
        }
        fetchDataBoxPlot(params)
    }, [])

    useEffect(() => {
        if (language !== i18n.language) {
            const params = {
                type: activeTab,

                country: selectX,
                y: selectY,
                week: dayjs(date).week(),
                month: dayjs(date).month() + 1,
                year: dayjs(date).year(),
                quarter: 'Q' + dayjs(date).quarter(),
                halfYear: selectHalfYear,
                page_size: 5,
                page: page,
            }
            fetchDataBoxPlot(params)

            setLanguage(i18n.language)
        }
    }, [i18n.language])

    useEffect(() => {
        onSetProvinceValueState(lutProvince)
    }, [lutProvince])

    const fetchLutRegion = async () => {
        try {
            const response = await getFilterRegion()

            if (response.status_code === 200 && response.results.length > 0) {
                setLutRegion(response.results)
            }
        } catch (error) {
            setLutRegion([])
        }
    }

    const fetchFilterProvince = async params => {
        try {
            const response = await getFilterProvince(params)

            if (response.status_code === 200 && response?.results?.data?.length > 0) {
                setLutProvince(response?.results?.data)
            }
        } catch (error) {
            setLutProvince([])
        }
    }

    const fetchDataBoxPlot = async params => {
        try {
            setLoadingSkeleton(true)
            const response = await getUserInalyzeBoxPlot(params)

            if (response?.status_code === 200 && response?.results) {
                setDataBoxPlot(response.results)
            }
            setNumPro(Math.ceil(selectProvince?.length / 5))
        } catch (error) {
            setLutProvince([])
        } finally {
            setLoadingSkeleton(false)
        }
    }

    const toggleTab = tab => {
        setActiveTab(tab)
    }

    const handleChangeTime = date => {
        if (activeTab === 'period') {
            setDateDynamic(date)
        } else {
            setDate(date)
        }
    }

    const handleHalfYearChange = option => {
        setSelectHalfYear(option.value)
    }

    const handleChangeSelectY = value => {
        setSelectY(value)
    }

    const onChangeX = value => {
        setSelectX(value)
        const isTH = value.find(element => element === 'th')
        if (isTH && value.length === 1) {
            setDisableRegion(false)
        } else {
            setDisableProvince(true)
            setDisableRegion(true)
            setSelectRegion([])
            setSelectProvince([])
        }
    }

    const onChangeRegion = value => {
        setSelectRegion(value)

        if (value.length > 0) {
            fetchFilterProvince({region: value})
            setDisableProvince(false)
        } else {
            setSelectProvince([])
            setDisableProvince(true)
        }
    }

    const handleChangeSelectProvince = value => {
        setSelectProvince(value)
    }

    const onSetProvinceValueState = newFilterProvince => {
        const resultProvince = []
        const province = []

        newFilterProvince?.forEach(itm => {
            province.push({value: itm.id, prov_name_th: itm.prov_name_th, prov_name_en: itm.prov_name_en})
        })

        selectProvince.forEach(itemProvince => {
            province.forEach(itm => {
                if (itemProvince === itm.value) {
                    resultProvince.push(itemProvince)
                }
            })
        })

        setSelectProvince(resultProvince)
    }

    const handleChangeSubmit = () => {
        let params = {
            type: activeTab,
            country: selectX,
            y: selectY,
            week: dayjs(date).week(),
            month: dayjs(date).month() + 1,
            year: dayjs(date).year(),
            quarter: 'Q' + dayjs(date).quarter(),
            halfYear: selectHalfYear,
            page_size: 5,
            page: 1,
        }

        const isTH = selectX.find(element => element === 'th') ? true : false

        if (isTH) {
            params = {
                ...params,

                region: selectRegion,
                province: selectProvince,
            }
        }

        if (activeTab === 'year') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('year').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('year').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'month') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('month').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('month').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'week') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('week').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('week').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'quarter') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('quarter').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('quarter').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'period') {
            params = {
                ...params,

                start_date: dayjs(dateDynamic[0]).format('YYYY-MM-DD'),
                end_date: dayjs(dateDynamic[1]).format('YYYY-MM-DD'),
            }
        }

        fetchDataBoxPlot(params)
    }

    const handleSlideLeft = () => {
        let params = {
            type: activeTab,
            y: selectY,
            week: dayjs(date).week(),
            month: dayjs(date).month() + 1,
            year: dayjs(date).year(),
            quarter: 'Q' + dayjs(date).quarter(),
            halfYear: selectHalfYear,
            page_size: 5,
            page: page - 1,
            country: selectX,
        }

        const isTH = selectX.find(element => element === 'th') ? true : false

        if (isTH) {
            params = {
                ...params,

                region: selectRegion,
                province: selectProvince,
            }
        }

        if (activeTab === 'year') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('year').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('year').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'month') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('month').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('month').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'week') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('week').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('week').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'quarter') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('quarter').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('quarter').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'period') {
            params = {
                ...params,

                start_date: dayjs(dateDynamic[0]).format('YYYY-MM-DD'),
                end_date: dayjs(dateDynamic[1]).format('YYYY-MM-DD'),
            }
        }

        fetchDataBoxPlot(params)

        setPage(cur => cur - 1)
    }

    const handleSlideRight = () => {
        let params = {
            type: activeTab,
            y: selectY,
            week: dayjs(date).week(),
            month: dayjs(date).month() + 1,
            year: dayjs(date).year(),
            quarter: 'Q' + dayjs(date).quarter(),
            halfYear: selectHalfYear,
            page_size: 5,
            page: page + 1,
            country: selectX,
        }

        const isTH = selectX.find(element => element === 'th') ? true : false

        if (isTH) {
            params = {
                ...params,

                region: selectRegion,
                province: selectProvince,
            }
        }

        if (activeTab === 'year') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('year').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('year').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'month') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('month').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('month').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'week') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('week').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('week').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'quarter') {
            params = {
                ...params,

                start_date: dayjs(date).startOf('quarter').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('quarter').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'period') {
            params = {
                ...params,

                start_date: dayjs(dateDynamic[0]).format('YYYY-MM-DD'),
                end_date: dayjs(dateDynamic[1]).format('YYYY-MM-DD'),
            }
        }

        fetchDataBoxPlot(params)

        setPage(cur => cur + 1)
    }

    return (
        <Row>
            {loadingSkeleton ? (
                <BoxPlotSkeleton />
            ) : (
                <Col xl={12}>
                    <Card
                        style={{
                            borderRadius: 8,
                        }}>
                        <CardTitle
                            style={{
                                borderBottom: '1px solid #EDECEC',
                                paddingTop: 25,
                                paddingLeft: 60,
                                paddingRight: 35,
                                paddingBottom: 20,
                            }}>
                            <DivTitle>
                                <H3>Customer segmentation</H3>
                            </DivTitle>
                        </CardTitle>
                        <CardBody
                            style={{
                                paddingLeft: 60,
                                paddingRight: 130,
                            }}>
                            <SelectWrapper>
                                {buttonFilterTimeRange.map((item, index) => {
                                    return (
                                        <SelectButton
                                            key={index}
                                            color={activeTab == item.value ? '#fff' : '#495057'}
                                            backgroundColor={activeTab == item.value ? '#79829C' : '#fff'}
                                            onClick={() => toggleTab(item.value)}>
                                            {i18n.language === 'en' ? item.title_en : item.title_th}
                                        </SelectButton>
                                    )
                                })}
                                <TabContent activeTab={activeTab}>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId={'week'}>
                                            <WeekPicker value={date} onChange={handleChangeTime} />
                                        </TabPane>

                                        <TabPane tabId={'month'}>
                                            <MonthPicker value={date} onChange={handleChangeTime} />
                                        </TabPane>
                                        <TabPane tabId={'year'}>
                                            <YearPicker value={date} onChange={handleChangeTime} />
                                        </TabPane>
                                        <TabPane tabId={'quarter'}>
                                            <QuarterPicker value={date} onChange={handleChangeTime} />
                                        </TabPane>
                                        <TabPane tabId={'halfYear'}>
                                            <div className="d-flex">
                                                <HalfYearPicker
                                                    value={selectHalfYear}
                                                    onChange={handleHalfYearChange}
                                                />
                                                <YearPicker value={date} onChange={handleChangeTime} />
                                            </div>
                                        </TabPane>
                                        <TabPane tabId={'period'}>
                                            <CustomPicker onChange={handleChangeTime} />
                                        </TabPane>
                                    </TabContent>
                                </TabContent>
                            </SelectWrapper>
                            <SelectWrapperII>
                                <Section> {t('userInalyze.yAxis')} </Section>
                                <Select
                                    style={{width: '27%', marginRight: 20}}
                                    showSearch
                                    value={selectY}
                                    onChange={handleChangeSelectY}>
                                    {selectOptionCustomerSegmentation?.map((item, index) => (
                                        <Option
                                            disabled={item.disabled}
                                            key={index}
                                            value={item.value}
                                            label={i18n.language === 'en' ? item.label_en : item?.label_th}>
                                            {i18n.language === 'en' ? item.label_en : item?.label_th}
                                        </Option>
                                    ))}
                                </Select>
                                <Section> {t('userInalyze.xAxis')} </Section>

                                <Select
                                    className=" container-select-multiple"
                                    mode="multiple"
                                    style={{width: '20%', marginRight: 25}}
                                    placeholder={t('common.all')}
                                    value={selectX}
                                    onChange={onChangeX}
                                    showSearch
                                    optionFilterProp="label">
                                    {countryOptions?.map(
                                        (item, index) =>
                                            item.value != 'all' && (
                                                <Option key={index} value={item.value} label={item.label}>
                                                    <Space>{item.label}</Space>
                                                </Option>
                                            ),
                                    )}
                                </Select>
                                <Section> {t('common.region')} </Section>

                                <Select
                                    className=" container-select-multiple"
                                    mode="multiple"
                                    style={{width: '40%'}}
                                    placeholder={t('common.selectRegion')}
                                    value={selectRegion}
                                    onChange={onChangeRegion}
                                    showSearch
                                    optionFilterProp="label"
                                    disabled={disableRegion}>
                                    <Option value={'other'} label={t('common.areaNotSpecified')}>
                                        <Space>{t('common.areaNotSpecified')}</Space>
                                    </Option>
                                    {lutRegion?.map(
                                        (option, index) =>
                                            option.reg_code != 1 && (
                                                <Option
                                                    key={index}
                                                    value={option.reg_code}
                                                    label={i18n.language === 'en' ? option.name_en : option.name_th}>
                                                    {i18n.language === 'en' ? option.name_en : option.name_th}
                                                </Option>
                                            ),
                                    )}
                                </Select>
                            </SelectWrapperII>
                            <SelectWrapperII>
                                <Section> {t('common.province')} </Section>
                                <Select
                                    className=" container-select-multiple"
                                    mode="multiple"
                                    style={{width: '50%'}}
                                    placeholder={t('common.chooseProvince')}
                                    value={selectProvince}
                                    onChange={handleChangeSelectProvince}
                                    showSearch
                                    optionFilterProp="label"
                                    disabled={disableProvince}>
                                    {lutProvince.map((item, index) => (
                                        <Option
                                            key={index}
                                            value={item.id}
                                            label={i18n.language === 'en' ? item.prov_name_en : item.prov_name_th}>
                                            <Space>
                                                {i18n.language === 'en' ? item.prov_name_en : item.prov_name_th}
                                            </Space>
                                        </Option>
                                    ))}
                                </Select>
                                <ButtonSubmit type="Submit" onClick={handleChangeSubmit}>
                                    {t('common.searchInformation')}
                                </ButtonSubmit>
                            </SelectWrapperII>
                            <div
                                id="chart"
                                style={{
                                    marginTop: 50,
                                    marginLeft: 100,
                                    marginBottom: 50,
                                }}>
                                <BoxPlot
                                    series={dataBoxPlot?.series}
                                    nameY={dataBoxPlot?.name_y}
                                    nameX={dataBoxPlot?.name_x}
                                    type={dataBoxPlot?.name_y}
                                />
                                {selectProvince?.length > 5 && (
                                    <div
                                        className="col align-items-center d-flex justify-content-between "
                                        style={{marginTop: '-5%'}}>
                                        <span
                                            className="btn "
                                            onClick={() => {
                                                page !== 1 && handleSlideLeft()
                                            }}
                                            style={{color: '#9AA2A9', marginLeft: '2%'}}>
                                            <i className="mdi mdi-triangle mdi-2px mdi-rotate-270"></i>
                                        </span>
                                        <span
                                            className="btn"
                                            onClick={() => {
                                                numPro !== page && handleSlideRight()
                                            }}
                                            style={{color: '#9AA2A9', marginLeft: '4%'}}>
                                            <i className="mdi mdi-triangle mdi-2px mdi-rotate-90"></i>
                                        </span>
                                    </div>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            )}
        </Row>
    )
}

export default withTranslation()(BoxPlotCustomerUserInalyze)
