import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './style.scss'

const OverviewSearchSkeleton = () => {
    return (
        <div className="overview-search-skeleton">
            <Skeleton className="skelton-box" width={200} height={32} />
            <Skeleton className="skelton-box" width={200} height={32} />
            <Skeleton width={70} height={37} />
        </div>
    )
}

export default OverviewSearchSkeleton
