import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React, { Component, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

const CardSkeleton = ({ }) => {
  const loadCards = Array(10).fill(1);
  return (
    <Col>
      <Card
        style={{
          display: "flex",
          borderRadius: 8,
          boxShadow: "0px 12px 24px rgb(240, 240, 240)",
        }}
      >
        <CardTitle
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            marginTop: 20,
            paddingLeft: 25,
            paddingRight: 25,
            paddingBottom: 20,
            borderBottom: "1px solid #EDECEC",
          }}
        >
          <div>
            <div style={{ marginLeft: 15 }}>
              <Skeleton width={180} height={24} style={{}} />
            </div>
          </div>
          <div style={{ marginLeft: 15, display: "flex", alignItems: "center" }}>
            <Skeleton width={80} style={{}} />
            <div className="filter-navbar" style={{ marginLeft: 15 }}>
              <Skeleton width={100} />
            </div>
            <div className="filter-navbar" style={{ marginLeft: 15 }}>
              <Skeleton width={100} />
            </div>
          </div>
        </CardTitle>
        <CardBody>
          <Row>
            <Col lg="12">
              {loadCards.map((card, i) => (
                <div key={i}>
                  <div className="row">
                    <div className="col-1">
                      <Skeleton />
                    </div>
                    <div className="col-1">
                      <Skeleton />
                    </div>
                    <div className="col-4">
                      <Skeleton />
                    </div>
                    <div className="col-2">
                      <Skeleton />
                    </div>
                    <div className="col-2">
                      <Skeleton />
                    </div>
                    <div className="col-2">
                      <Skeleton />
                    </div>
                  </div>
                  <Skeleton height={1} style={{ marginBottom: 16 }} />
                </div>))}

            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CardSkeleton;
