import React, {useState, useEffect} from 'react'
import {Col, Card, CardBody, CardTitle, Button} from 'reactstrap'
import {withTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {useRecoilState, useRecoilValue} from 'recoil'

import {filterUserInformationState, loadingUserInformationState} from 'store/atoms/user-information'

import MapOverview from './Component/MapOverview'
import OverviewSkeleton from '../../CardSkeleton/OverviewSkeleton'

import modalLogo from '../../../../assets/images/logoImg.png'
import {getUserHardwareID, getUserInformationDrone} from 'services/skc/user-information'
import {getUserInformationDroneStatus} from 'services/skc/new-kafka'

const Overview = props => {
    const {t, i18n} = props

    const history = useHistory()
    const filterUserInformation = useRecoilValue(filterUserInformationState)
    const [loading, setLoading] = useRecoilState(loadingUserInformationState)

    const [dataInfoDrone, setDataInfoDrone] = useState([])
    const [userHardwareID, setUserHardwareID] = useState([])
    const [dataDroneStatus, setDataDroneStatus] = useState([])
    const [dataMap, setDataMap] = useState([])

    const [skeletonDataInfoDrone, setSkeletonDataInfoDrone] = useState(false)
    const [skeletonDataDroneStatus, setSkeletonDataDroneStatus] = useState(false)

    const user_infodrone = {}

    useEffect(() => {
        fetchUserInformationDrone(filterUserInformation.id)
        fetchUserHardwareID(filterUserInformation.id)
    }, [])

    useEffect(() => {
        if (userHardwareID) {
            fetchDroneStatus({hardware: userHardwareID})
        }
    }, [userHardwareID])

    useEffect(() => {
        if (dataDroneStatus.length > 0) {
            setDataToMap(dataDroneStatus)
        }
    }, [dataDroneStatus])

    useEffect(() => {
        if (loading?.drone_overview) {
            fetchUserInformationDrone(filterUserInformation.id)
            fetchUserHardwareID(filterUserInformation.id)
            setLoading(cur => ({...cur, drone_overview: false}))
        }
    }, [loading.drone_overview])

    const fetchUserInformationDrone = async params => {
        try {
            setSkeletonDataInfoDrone(true)
            const response = await getUserInformationDrone(params)

            if (response?.status_code === 200 && response?.results?.data?.length > 0) {
                setDataInfoDrone(response?.results?.data)
            }
        } catch (error) {
            setDataInfoDrone([])
        } finally {
            setSkeletonDataInfoDrone(false)
        }
    }

    const fetchUserHardwareID = async params => {
        try {
            const response = await getUserHardwareID(params)

            if (response?.status_code === 200 && response?.results?.data?.length > 0) {
                setUserHardwareID(response?.results?.data)
            }
        } catch (error) {
            setUserHardwareID([])
        } finally {
        }
    }

    const fetchDroneStatus = async params => {
        try {
            setSkeletonDataDroneStatus(true)
            const response = await getUserInformationDroneStatus(params)
            if (response?.result.length > 0) {
                setDataDroneStatus(response?.result)
            }
        } catch (error) {
            setDataDroneStatus([])
        } finally {
            setSkeletonDataDroneStatus(false)
        }
    }

    const setDataToMap = dataDrone => {
        const result = []

        dataDrone.forEach(item => {
            dataInfoDrone?.forEach(name => {
                if (item.position_latest?.hardwareid === name?.hardware_id) {
                    result.push({
                        ...item,
                        drone_name: name?.drone_model?.name || '-',
                    })
                }
            })
        })

        setDataMap(result)
    }

    const handleClickMenuDrone = id => {
        history.push({
            pathname: '/drone-information/overall',
            search: `${id}`,
            id,
        })
    }

    return (
        <>
            {skeletonDataInfoDrone || skeletonDataDroneStatus ? (
                <OverviewSkeleton />
            ) : (
                <Card
                    style={{
                        borderRadius: 8,
                    }}>
                    {dataInfoDrone?.length > 0 && (
                        <CardTitle
                            style={{
                                borderBottom: '1px solid #EDECEC',
                                paddingTop: 20,
                                paddingLeft: 60,
                                paddingRight: 35,
                            }}>
                            <div
                                className="d-flex align-items-center mb-3 "
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                }}>
                                {dataInfoDrone?.map((item, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            color="secondary"
                                            outline
                                            style={{
                                                fontFamily: 'Kanit',
                                                fontWeight: 400,
                                                fontSize: 14,
                                                borderColor: '#F05A28',
                                                borderRadius: 8,
                                                color: '#495057',
                                                backgroundColor: '#fff',
                                                marginRight: 8,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            onClick={() => {
                                                handleClickMenuDrone(item.id)
                                            }}>
                                            <img
                                                src={
                                                    !item?.drone_model.image
                                                        ? modalLogo
                                                        : 'https://s3dev-gramick.sgp1.cdn.digitaloceanspaces.com/kubota/' +
                                                          item?.drone_model.image +
                                                          '?v=1'
                                                }
                                                style={{height: 38, width: 56}}
                                            />

                                            <span
                                                style={{
                                                    marginLeft: 8,
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    fontFamily: 'Inter',
                                                    color: '#495057',
                                                }}>
                                                {item?.drone_model.name || '-'}
                                            </span>
                                            <i
                                                className="mdi mdi-chevron-right"
                                                style={{
                                                    marginLeft: 8,
                                                    color: '#FBCEBF',
                                                    fontSize: 20,
                                                }}></i>
                                        </Button>
                                    )
                                })}
                            </div>
                        </CardTitle>
                    )}
                    <CardBody
                        style={{
                            paddingLeft: 40,
                            paddingRight: 40,
                            paddingBottom: 40,
                        }}>
                        <h4
                            style={{
                                fontSize: 20,
                                fontWeight: 600,
                                marginBottom: 20,
                            }}>
                            {t('userInformation.usageOverview')}{' '}
                            {dataDroneStatus?.length === 0 && (
                                <span
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 400,
                                        fontFamily: 'Kanit',
                                        color: 'gray',
                                        textAlign: 'center',
                                        marginLeft: 20,
                                    }}>
                                    ({t('userInformation.latestDronePositionNotFound')})
                                </span>
                            )}
                        </h4>
                        {<MapOverview location={dataMap} />}
                    </CardBody>
                </Card>
            )}
        </>
    )
}

export default withTranslation()(Overview)
