const userInformation = {
    searchByPilotNamePhoneNumber: 'Search by pilot name, phone number , email ',
    searchUser: 'Search users',
    searchHistory: 'Search history',
    searchResults: 'Search results',
    joinedOn: 'Joined on',
    membershipDuration: 'Membership duration',
    ownedDrones: 'Owned Drones',
    picture: 'Picture',
    droneModel: 'Drone Model',
    usageInHours: 'Usage in hours',
    droneInformation: 'Drone Information',
    latestDronePosition: 'Latest Drone Position',
    latestDronePositionNotFound: 'Latest Drone Position NotFound',
    totalFlights: 'Total Flights',
    totalFlightTime: 'Total Flight Time',
    averageFlightTime: 'Average Flight Time',
    totalWorkingArea: 'Total Working Area',
    averageWorkingArea: 'Average Working Area',
    totalSprayingArea: 'Total Spraying Area',
    totalSpreadingArea: 'Total Spreading Area',
    workOverview: 'Work Overview',
    flightTimeRanking: 'Flight Time Ranking',
    plotList: 'Plot List',
    regularTime: 'Regular Time',
    workingFields: 'Working Fields',
    fieldLocation: 'Field location',
    areaRai: 'Area (Rai)',
    usageOverview: 'Usage Overview',
    averageSprayingQuantity: 'Average Spraying Quantity',
    averageSpreadingQuantity: 'Average Spreading Quantity',
    totalFlightTimesMinutes: 'Total Flight times (Minutes)',
    averageFlightTimesMinutesMonth: 'Average flight times Min/Month',
    averageFlightTimesMinutesDays: 'Average flight times Min/Days',
    averageFlightTimesWorkingPeriodMinMonth: 'Average flight time (Working period) Min/Month',
    averageFlightTimesWorkingPeriodMinDays: 'Average flight time (Working period) Min/Days',
    flightTime: 'Flight time',
    averageFlightTimeDuringWork: 'Average flight time (During work)',
    averageFlightTimeTotal: 'Average flight time (Total)',
    flightTimesMinutes: 'Flight times (Minutes)',
    totalWorkingAreaRaiUnit: 'Total working area (Rai)',
    averageWorkingAreaRaiMonth: 'Average working area  (Rai/Month)',
    averageWorkingAreaRaiDays: 'Average working area  (Rai/Days)',
    averageWorkingAreaWorkingPeriodRaiMonth: 'Average working area (Working period) (Rai/Month)',
    averageWorkingAreaWorkingPeriodRaiDays: 'Average working area (Working period) (Rai/Days)',
    workingAreas: 'Working areas',
    averageWorkingAreasDuringWork: 'Average working areas (During Work)',
    averageWorkingAreasTotal: 'Average working areas (Total)',
    workingAreaRai: 'Working area (Rai)',
    numberOfFlights: 'Number of Flights',
    average: 'Average',
    speedUsed: 'Speed Used',
    speedMsUnit: 'Speed (m/s)',
    median: 'Median',
    mode: 'Mode',
    heightUsed: 'Height Used',
    heightMUnit: ' Height (m)',
    totalFlightHistory: 'Total flight history',
    dronesFlightHistory: `Drone's flight history`,
    flightTimeRanking: 'Flight Time Ranking',
    flightDate: 'Flight Date',
    location: 'Location',
    flightTimesHrs: 'Flight times (Hrs.)',
    averageSpeedMS: 'Average speed (M/S)',
    workType: 'Work type',
    spraying: 'Spraying',
    averageSprayingSpreadingQuantity: 'Average Spraying/Spreading quantity',
    aviationHistory: 'Aviation History',
    averageSpreadingQuantity: 'Average Spreading quantity',
}

export default userInformation
