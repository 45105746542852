import { takeEvery, put, call } from "redux-saga/effects";

// Chat Redux States
import { LOADING_DRONE_ACTIVE } from "./actionTypes";
import { loadingDroneActiveSuccess, loadingDroneActiveFail } from "../actions";

function* onLoadingDroneActive({ payload: data }) {
  try {
    yield put(loadingDroneActiveSuccess(data));
  } catch (error) {
    yield put(loadingDroneActiveFail(error));
  }
}

function* loadingDroneActiveSaga() {
  yield takeEvery(LOADING_DRONE_ACTIVE, onLoadingDroneActive);
}

export default loadingDroneActiveSaga;
