import React, {useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import {withTranslation} from 'react-i18next'

import {Row, Col} from 'reactstrap'

const AverageFlight = props => {
    const {t, searchFilter, series = [], labels, max} = props

    const [seriesMonth, setSeriesMonth] = useState([])
    const [labelsMonth, setLabelsMonth] = useState([])
    const [activeTab, setActiveTab] = useState(1)
    const [countTab, setCountTab] = useState(0)
    const [isClickSlideTap, setIsClickSlideTap] = useState(false)

    const setSeries = () => {
        return [
            {
                name: t('dashboard.averageFlightTimeHrUnit'),
                data: series,
            },
        ]
    }

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(Number(tab))
        }
    }

    useEffect(() => {
        if (searchFilter.type === 'month') {
            setChartTypeMonth()
            setIsClickSlideTap(false)
        }
    }, [searchFilter, labels])

    const setChartTypeMonth = () => {
        const dataAverageFly = []
        const newLabel = []

        if ((isClickSlideTap === false || activeTab === 1) && searchFilter.type === 'month') {
            for (let i = 0; i < 10; i++) {
                dataAverageFly.push(series[i])
                newLabel.push(labels[i])
            }
            const seriesMonth = [
                {
                    name: t('dashboard.averageFlightTimeHrUnit'),
                    data: dataAverageFly,
                },
            ]

            setSeriesMonth(seriesMonth)
            setLabelsMonth(newLabel)
            setCountTab(0)
        }

        if (isClickSlideTap === true && activeTab !== 1) {
            if (activeTab == 2) {
                for (let i = 0; i < series.length; i++) {
                    if (dataAverageFly.length < 10 && i > 9) {
                        dataAverageFly.push(series[i])
                        newLabel.push(labels[i])
                    }
                }
            } else {
                for (let i = 0; i < series.length; i++) {
                    if (dataAverageFly.length <= 10 && i > 19) {
                        dataAverageFly.push(series[i])
                        newLabel.push(labels[i])
                    }
                }
            }
            const seriesMonth = [
                {
                    name: t('dashboard.averageFlightTimeHrUnit'),
                    data: dataAverageFly,
                },
            ]

            setSeriesMonth(seriesMonth)
            setLabelsMonth(newLabel)
        }
    }

    const handleSlideRight = () => {
        const dataAverageFly = []
        const newLabel = []
        const newSeries = []

        for (let i = 0; i < series?.length; i++) {
            if (countTab === 0 && dataAverageFly.length < 10 && i > 9) {
                dataAverageFly.push(series[i])
                newLabel.push(labels[i])
            }
            if (countTab === 1 && i > 19) {
                dataAverageFly.push(series[i])
                newLabel.push(labels[i])
            }
        }
        newSeries.push({
            name: t('dashboard.averageFlightTimeHrUnit'),
            data: dataAverageFly,
        })

        setSeriesMonth(newSeries)
        setIsClickSlideTap(true)
        setCountTab(countTab === 1 ? 1 : countTab + 1)
        setLabelsMonth(newLabel)
    }

    const handleSlideLeft = () => {
        const dataAverageFly = []
        const newLabel = []
        const newSeries = []

        for (let i = 0; i < series.length; i++) {
            if (countTab === 1 && dataAverageFly.length < 10 && i > 9) {
                dataAverageFly.push(series[i])
                newLabel.push(labels[i])
            }

            if (countTab === 0 && dataAverageFly.length < 10) {
                dataAverageFly.push(series[i])
                newLabel.push(labels[i])
            }
        }

        newSeries.push({
            name: t('dashboard.averageFlightTimeHrUnit'),
            data: dataAverageFly,
        })

        setSeriesMonth(newSeries)
        setIsClickSlideTap(true)
        setCountTab(countTab === 0 ? 0 : countTab - 1)
        setLabelsMonth(newLabel)
    }

    return (
        <Row>
            <Col xl={12}>
                <p
                    style={{
                        fontFamily: 'Kanit',
                        fontWeight: 600,
                        fontSize: '20px',
                        color: '#000000',
                        margin: 0,
                    }}>
                    {t('dashboard.averageFlightTime')}
                </p>
            </Col>

            <Col xl={12} style={{marginTop: '8px'}}>
                <p
                    style={{
                        fontSize: 15,
                        fontWeight: 400,
                        color: '#495057',
                    }}>
                    {t('dashboard.averageDroneFlightTime')}
                </p>
            </Col>
            <Col xl={12}>
                <div
                    id="chart"
                    style={{
                        width: '100%',
                        height: 350,
                        marginTop: 30,
                    }}>
                    <ReactApexChart
                        options={{
                            chart: {
                                height: 350,
                                type: 'line',
                                toolbar: {
                                    show: false,
                                },
                                zoom: {
                                    enabled: false,
                                },
                                animations: {
                                    enabled: false,
                                },
                            },
                            legend: {
                                show: true,
                                showForSingleSeries: true,
                                floating: true,
                                inverseOrder: true,
                                position: 'top',
                                horizontalAlign: 'right',
                                fontSize: 14,
                                fontWeight: 400,
                            },
                            markers: {
                                size: 3,

                                hover: {
                                    size: undefined,
                                    sizeOffset: 3,
                                },
                            },
                            colors: ['#F1B44C'],
                            dataLabels: {
                                enabled: false,
                            },
                            stroke: {
                                curve: 'straight',
                                width: 2,
                            },
                            labels: [],
                            grid: {
                                show: true,
                                borderColor: '#E0E0E0',
                                strokeDashArray: 0,
                                position: 'front',
                                xaxis: {
                                    lines: {
                                        show: false,
                                    },
                                },
                                yaxis: {
                                    lines: {
                                        show: true,
                                    },
                                },
                            },
                            xaxis: {
                                categories: searchFilter.type === 'month' ? labelsMonth : labels,

                                axisBorder: {
                                    show: true,
                                    color: '#E0E0E0',
                                },
                                labels: {
                                    style: {
                                        fontSize: '12px',
                                        fontFamily: 'Kanit, sans-serif',
                                        color: '#495057',
                                    },
                                },
                                axisTicks: {
                                    show: true,
                                    borderType: 'solid',
                                    color: '#E0E0E0',
                                    height: 6,
                                },
                                crosshairs: {
                                    show: true,
                                    width: 'tickWidth',
                                    fill: {
                                        type: 'gradient',
                                        gradient: {
                                            colorFrom: '#495057',
                                            colorTo: '#495057',
                                            stops: [0, 100],
                                            opacityFrom: 0.4,
                                            opacityTo: 0.5,
                                        },
                                    },
                                },
                            },
                            yaxis: {
                                min: 0,
                                max: max === 0 ? 5 : max,
                                tickAmount: 5,
                                title: {
                                    text: `${t('dashboard.flightDurationHrUnit')}`,

                                    offsetX: 8,
                                    offsetY: 6,
                                    style: {
                                        fontSize: '12px',
                                    },
                                },
                                axisTicks: {
                                    show: true,
                                    borderType: 'solid',
                                    color: '#E0E0E0',
                                    height: 6,
                                },
                                axisBorder: {
                                    show: true,
                                    color: '#E0E0E0',
                                },
                                labels: {
                                    style: {
                                        fontSize: 12,
                                    },
                                },
                            },
                            tooltip: {
                                shared: true,
                                theme: 'dark',
                                intersect: false,
                                y: {
                                    formatter: function (y) {
                                        return y.toLocaleString('en-US', {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 2,
                                        })
                                    },
                                },
                            },
                        }}
                        series={searchFilter.type === 'month' ? seriesMonth : setSeries()}
                        type="line"
                        height={350}
                    />
                </div>
                {searchFilter.type === 'month' && labelsMonth && (
                    <div
                        className="col align-items-center d-flex justify-content-between "
                        style={{marginTop: '-5%', width: '800px'}}>
                        <span
                            className="btn "
                            style={{color: '#9AA2A9', marginLeft: '2%'}}
                            onClick={() => {
                                handleSlideLeft()
                                activeTab > 1 ? toggle(Number(activeTab) - 1) : toggle(1)
                            }}>
                            <i className="mdi mdi-triangle mdi-2px mdi-rotate-270"></i>
                        </span>
                        <span
                            className="btn"
                            style={{color: '#9AA2A9', marginLeft: '5%'}}
                            onClick={() => {
                                handleSlideRight()
                                activeTab < 3 ? toggle(Number(activeTab) + 1) : toggle(3)
                            }}>
                            <i className="mdi mdi-triangle mdi-2px mdi-rotate-90"></i>
                        </span>
                    </div>
                )}
            </Col>
        </Row>
    )
}

export default withTranslation()(AverageFlight)
