import {
  AUTHORIZED,
  AUTHORIZED_FAIL,
  AUTHORIZED_SUCCESS,
} from "./actionTypes";

export const authorizedLogin = (data) => ({
  type: AUTHORIZED,
  payload: data
});

export const authorizedSuccess = (data) => ({
  type: AUTHORIZED_SUCCESS,
  payload: data,
});

export const authorizedFail = (error) => ({
  type: AUTHORIZED_FAIL,
  payload: error,
});

