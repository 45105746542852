const droneInformation = {
    overall: 'Overall',
    usage: 'Usage',
    userInformation: 'ข้อมูลผู้ใช้',
    usageDuration: 'อายุการใช้งาน',
    pilot: 'นักบิน',
    phoneNumber: 'เบอร์โทรศัพท์',
    email: 'อีเมล',
    address: 'ที่อยู่',
    droneOwnerInformation: 'ข้อมูลเจ้าของโดรน',
    totalNumberOfFlights: 'จำนวนเที่ยวบินทั้งหมด',
    totalFlightDuration: 'ระยะเวลาการบินทั้งหมด',
    averageFlightDuration: 'ระยะเวลาการบินเฉลี่ย',
    totalWorkingArea: 'พื้นที่การทำงานทั้งหมด',
    averageWorkingArea: 'พื้นที่การทำงานเฉลี่ย',
    numberOfSpraying: 'จำนวนการฉีดพ่น',
    totalSprayingArea: 'พื้นที่การฉีดพ่นทั้งหมด',
    totalSprayingQuantity: 'ปริมาณการฉีดพ่นทั้งหมด',
    averageSprayingQuantity: 'ปริมาณการฉีดพ่นเฉลี่ย',
    numberOfSpreading: 'จำนวนการหว่าน',
    totalSpreadingArea: 'พื้นที่การหว่านทั้งหมด',
    totalSpreadingQuantity: 'ปริมาณการหว่านทั้งหมด',
    averageSpreadingQuantity: 'ปริมาณการหว่านเฉลี่ย',
    latestDroneLocation: 'ตำแหน่งโดรนล่าสุด',
    latestDroneLocationNotFound: 'ไม่พบตำแหน่งโดรนล่าสุด',
    details: 'รายละเอียด',
    spraying: 'การพ่น',
    spreading: 'การหว่าน',
    totalFlightTime: 'ระยะเวลาการบินทั้งหมด',
    numberOfSpreader: 'จำนวนการหว่าน',
    spreaderQuantity: 'ปริมาณการหว่าน',
    sprayingQuantity: 'ปริมาณการฉีดพ่น',
    totalFlightTimesMinutes: 'จำนวนระยะเวลาบินทั้งหมด (นาที)',
    averageFlightTimesMinutesMonth: 'จำนวนระยะเวลาบินเฉลี่ย นาที/เดือน',
    averageFlightTimesMinutesDays: 'จำนวนระยะเวลาบินเฉลี่ย นาที/วัน',
    averageFlightTimesWorkingPeriodMinMonth: 'ระยะเวลาการบินเฉลี่ย (ช่วงที่มีการทำงาน) นาที/เดือน',
    averageFlightTimesWorkingPeriodMinDays: 'ระยะเวลาการบินเฉลี่ย (ช่วงที่มีการทำงาน) นาที/วัน',
    flightTimes: 'ระยะเวลาการบิน',
    averageFlightTimesWorkingPeriod: 'ระยะเวลาการบินเฉลี่ย (ช่วงที่มีการทำงาน)',
    averageFlightTimesTotal: 'ระยะเวลาการบินเฉลี่ย (ทั้งหมด)',
    flightTimesMinutes: 'ระยะเวลาการบิน (นาที)',
    totalWorkingAreaRaiUnit: 'พื้นที่ทำงานทั้งหมด (ไร่)',
    averageWorkingAreaRaiMonth: 'พื้นที่ทำงานเฉลี่ย (ไร่/เดือน)',
    averageWorkingAreaRaiDays: 'พื้นที่ทำงานเฉลี่ย (ไร่/วัน)',
    averageWorkingAreaWorkingPeriodRaiMonth: 'พื้นที่ทำงานเฉลี่ย (ช่วงที่มีการทำงาน) ไร่/เดือน',
    averageWorkingAreaWorkingPeriodRaiDays: 'พื้นที่ทำงานเฉลี่ย (ช่วงที่มีการทำงาน) ไร่/วัน',
    numberOfWorkingAreas: 'จำนวนพื้นที่การทำงาน',
    averageWorkingAreaWorkingPeriod: 'พื้นที่ทำงานเฉลี่ย (ช่วงที่มีการทำงาน)',
    averageWorkingAreaTotal: 'พื้นที่ทำงานเฉลี่ย (ทั้งหมด)',
    workingAreaRai: 'พื้นที่ทำงาน (ไร่)',
    numberOfFlights: 'จำนวนเที่ยวบิน',
    average: 'ค่าเฉลี่ย',
    speedUsed: 'ความเร็วที่ใช้',
    speedMsUnit: 'ความเร็ว (m/s)',
    heightUsed: 'ความสูงที่ใช้',
    heightMUnit: ' ความสูง (m)',
    median: 'ค่ามัธยฐาน',
    mode: 'ค่าฐานนิยม',
    totalFlightHistory: 'ประวัติการบินทั้งหมด',
    dronesFlightHistory: 'ประวัติการบินจากโดรน',
    flightTimeRanking: 'อันดับเวลาการบิน',
    flightDate: 'วันที่บิน',
    location: 'สถานที่',
    flightTimeMUnit: 'ระยะเวลาบิน (น.)',
    averageSpeedMSUnit: 'ความเร็วเฉลี่ย (m/s)',
    workingType: 'ประเภทการทำงาน',
    averageSprayingSpreadingQuantity: 'ปริมาณการพ่น/หว่าน เฉลี่ย',
    spraying: 'ฉีดพ่น',
    spreading: 'หว่าน',
    flightHistory: 'ประวัติการบิน',
    playFlightHistory: 'เล่นประวัติการบิน',
    flightAt: 'เที่ยวบินที่',
    totalWorkingTime: 'ระยะเวลาการทำงานทั้งหมด',
    workingArea: 'พื้นที่ทำงาน',
    flightTimes: 'ระยะเวลาบิน',
    averageSpeed: 'ความเร็วเฉลี่ย',
    averageHeight: 'ความสูงเฉลี่ย',
    totalSubstanceAmount: 'ปริมาณสารทั้งหมด',
    operationPattern: 'รูปแบบการทำงาน',
    quantity: 'ปริมาณการ',
    flightSpeed: 'ความเร็วการบิน',
    height: 'ความสูง',
    routeDistance: 'ระยะห่างเส้นทาง',
    rotationalSpeed: 'ความเร็วจานหมุน',
    flowRate: 'อัตราการไหล',
    nozzleSize: 'ขนาดช่องปล่อย',
    substanceUsed: 'สารที่ใช้ไป',
    battery: 'แบตเตอรี่',
    latitude: 'ละติจูด',
    longitude: 'ลองจิจูด',
    totalWorkingAreaMap: 'พื้นที่ทำงานทั้งหมด',
    totalSubstanceUsed: 'ปริมาณสารที่ใช้ไปทั้งหมด',
    searchForMachines: 'ค้นหาเครื่องจักร',
    workingHours: 'ชั่วโมงการทำงาน',
    workType: 'ประเภททำงาน',
    numberOfHours: 'จำนวนชั่วโมง',
    minimumHours: 'ชั่วโมงต่ำสุด',
    maximumHours: 'ชั่วโมงสูงสุด',
}

export default droneInformation
