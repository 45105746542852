import {getWithQuery, postWithQuery} from '../../helpers/api_helper'

import {ENDPOINT} from 'constants/endpoint'

export const getCumulativeCard = async params => {
    try {
        const region = params.region || []

        const queryRegion = region.join(',')

        const response = await getWithQuery(
            `${ENDPOINT.DASHBOARD_CUMULATIVE}?type=${params.type}&week=${params.week}&month=${params.month}&year=${params.year}&region=${queryRegion}&start_date=${params.start_date}&end_date=${params.end_date}&country=${params.country}&drone=${params.drone}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getDashboardMap = async (params, value) => {
    try {
        const response = await postWithQuery(
            `${ENDPOINT.DASHBOARD_MAP}?type=${params.type}&week=${params.week}&month=${params.month}&year=${params.year}&start_date=${params.start_date}&end_date=${params.end_date}&value=${value}&country=${params.country}&to_date=${params.to_date}`,
            params,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getOverviewDetail = async params => {
    try {
        const region = params.region || []

        const queryRegion = region.join(',')

        const response = await getWithQuery(
            `${ENDPOINT.DASHBOARD_OVERVIEW_DETAIL}?type=${params.type}&week=${params.week}&month=${params.month}&year=${params.year}&start_date=${params.start_date}&end_date=${params.end_date}&province=${params.province}&district=${params.district}&region=${queryRegion}&country=${params.country}&drone=${params.drone}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getOverviewAverage = async (params, value) => {
    try {
        const region = params.region || []

        const queryRegion = region.join(',')

        const response = await getWithQuery(
            `${ENDPOINT.DASHBOARD_OVERVIEW_AVERAGE}?type=${params.type}&week=${params.week}&month=${params.month}&year=${params.year}&start_date=${params.start_date}&end_date=${params.end_date}&province=${params.province}&district=${params.district}&region=${queryRegion}&value=${value}&country=${params.country}&drone=${params.drone}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getDroneType = async params => {
    try {
        const region = params.region || []

        const queryRegion = region.join(',')

        const response = await getWithQuery(
            `${ENDPOINT.DASHBOARD_DRONE_TYPE}?type=${params.type}&week=${params.week}&month=${params.month}&year=${params.year}&start_date=${params.start_date}&end_date=${params.end_date}&province=${params.province}&district=${params.district}&region=${queryRegion}&value=${params.value}&country=${params.country}&display=${params.display}&pageDrone=${params.pageDrone}&sizeDrone=${params.sizeDrone}&drone=${params.drone}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getWorkingDrone = async params => {
    try {
        const region = params.region || []

        const queryRegion = region.join(',')

        const response = await getWithQuery(
            `${ENDPOINT.DASHBOARD_WORKING_DRONE}?type=${params.type}&week=${params.week}&month=${params.month}&year=${params.year}&start_date=${params.start_date}&end_date=${params.end_date}&province=${params.province}&district=${params.district}&region=${queryRegion}&country=${params.country}&drone=${params.drone}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getTotalArea = async params => {
    try {
        const response = await postWithQuery(
            `${ENDPOINT.DASHBOARD_TOTAL_AREA}?type=${params.type}&week=${params.week}&month=${params.month}&year=${params.year}&start_date=${params.start_date}&end_date=${params.end_date}&drone=${params.drone}&country=${params.country}`,
            params,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getAverageFlight = async params => {
    try {
        const response = await postWithQuery(
            `${ENDPOINT.DASHBOARD_AVERAGE_FLIGHT}?type=${params.type}&week=${params.week}&month=${params.month}&year=${params.year}&start_date=${params.start_date}&end_date=${params.end_date}&drone=${params.drone}&country=${params.country}`,
            params,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getFlightHistory = async params => {
    try {
        const region = params.region || []

        const queryRegion = region.join(',')

        const response = await getWithQuery(
            `${ENDPOINT.DASHBOARD_FLIGHT_HISTORY}?type=${params.type}&week=${params.week}&month=${params.month}&year=${params.year}&start_date=${params.start_date}&end_date=${params.end_date}&province=${params.province}&district=${params.district}&region=${queryRegion}&country=${params.country}&drone=${params.drone}`,
        )

        return response
    } catch (error) {
        return null
    }
}
