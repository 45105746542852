import React, {useEffect, useState} from 'react'
import {Card, CardBody, Col, Row, CardTitle, Table, TabContent, TabPane} from 'reactstrap'
import {withTranslation} from 'react-i18next'
import styled from 'styled-components'
import classnames from 'classnames'
import {useRecoilState, useRecoilValue} from 'recoil'
import {filterUserInformationState, loadingUserInformationState} from 'store/atoms/user-information'
import {getLocationArea, getUserInformationArea, getFlightHistory} from 'services/skc/user-information'

import iconAccount from '../../../../assets/images/icon/account-user.png'

import MapPlot from './Component/MapPlot'
import MapDefault from './Component/MapDefault'
import HistoryFlightTotal from './Component/HistoryFlightTotal'
import DataRegularSkeleton from 'components/UseriNalyze/CardSkeleton/DataRegularSkeleton'

const Button = styled.button`
    background-color: #fff;
    color: ${props => (props.color == '0' ? '#F05A28' : '#4E4E4E')};
    text-align: center;
    font-family: Kanit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding: 14px 37px 14px 37px;
    // margin: 10px 0px;
    cursor: pointer;
    border-width: 0px;
`
const ActiveTab = styled.div`
    background-color: ${props => (props.$primary ? '#F05A28' : '#FEEEE9')};
    padding: 1px;
    //width: 90%;
`

const DataRegular = props => {
    const {t, i18n} = props

    const [customActiveTab, setCustomActiveTab] = useState('area')
    const [language, setLanguage] = useState(i18n.language)

    const [dataLocationArea, setDataLocationArea] = useState({})
    const [dataInformationArea, setDataInformationArea] = useState([])
    const [dataFlightHistory, setDataFlightHistory] = useState([])

    const [skeletonLocationArea, setSkeletonLocationArea] = useState(false)
    const [skeletonInformationArea, setSkeletonInformationArea] = useState(false)
    const [skeletonFlightHistory, setSkeletonFlightHistory] = useState(false)

    const filterUserInformation = useRecoilValue(filterUserInformationState)
    const [loading, setLoading] = useRecoilState(loadingUserInformationState)

    const toggleCustom = tab => {
        setCustomActiveTab(tab)
    }

    useEffect(() => {
        fetchInformationArea(filterUserInformation.id)
        fetchFlightHistory(filterUserInformation)
    }, [])

    useEffect(() => {
        if (loading.information_area) {
            fetchInformationArea(filterUserInformation.id)
            setLoading(cur => ({...cur, information_area: false}))
        }
    }, [loading.information_area])

    useEffect(() => {
        if (loading.flight_history) {
            fetchFlightHistory(filterUserInformation)
            setLoading(cur => ({...cur, flight_history: false}))
        }
    }, [loading.flight_history])

    useEffect(() => {
        if (dataInformationArea.length > 0) {
            fetchLocationArea(dataInformationArea[0].id)
        }
    }, [dataInformationArea.length > 0])

    useEffect(() => {
        if (i18n.language !== language) {
            fetchInformationArea(filterUserInformation.id)
            fetchFlightHistory(filterUserInformation)
            setLanguage(i18n.language)
        }
    }, [i18n.language])

    const fetchInformationArea = async params => {
        try {
            setSkeletonInformationArea(true)
            const response = await getUserInformationArea(params)

            if (response?.status_code === 200 && response.results?.data.length > 0) {
                setDataInformationArea(response?.results?.data)
            }
        } catch (error) {
            setDataInformationArea([])
        } finally {
            setSkeletonInformationArea(false)
        }
    }
    const fetchLocationArea = async params => {
        try {
            setSkeletonLocationArea(true)
            const response = await getLocationArea(params)

            if (response?.status_code === 200 && response.results?.data) {
                setDataLocationArea(response?.results?.data)
            }
        } catch (error) {
            setDataLocationArea({})
        } finally {
            setSkeletonLocationArea(false)
        }
    }

    const fetchFlightHistory = async params => {
        try {
            setSkeletonFlightHistory(true)
            const response = await getFlightHistory(params)

            if (response?.status_code === 200 && response.results) {
                setDataFlightHistory(response?.results)
            }
        } catch (error) {
            setDataFlightHistory({})
        } finally {
            setSkeletonFlightHistory(false)
        }
    }

    return (
        <Row>
            {skeletonInformationArea || skeletonFlightHistory || skeletonLocationArea ? (
                <DataRegularSkeleton />
            ) : (
                <Col xl={12}>
                    <Card
                        style={{
                            borderRadius: 8,
                            height: 570,
                        }}>
                        <CardTitle
                            style={{
                                paddingTop: 15,
                                paddingLeft: 40,
                            }}>
                            <div style={{textAlignLast: 'center', padding: 0, display: 'flex'}}>
                                <div style={{textAlignLast: 'start', padding: 0}}>
                                    <Button
                                        className={classnames({
                                            active: customActiveTab === 'area',
                                        })}
                                        onClick={() => toggleCustom('area')}
                                        color={customActiveTab === 'area' ? '0' : '1'}>
                                        {t('userInformation.plotList')}
                                    </Button>

                                    <ActiveTab $primary={customActiveTab === 'area' ? true : false} />
                                </div>
                                <div style={{textAlignLast: 'center', padding: 0}}>
                                    <Button
                                        className={classnames({
                                            active: customActiveTab === 'time',
                                        })}
                                        onClick={() => toggleCustom('time')}
                                        color={customActiveTab === 'time' ? '0' : '1'}>
                                        {t('userInformation.regularTime')}
                                    </Button>
                                    <ActiveTab $primary={customActiveTab === 'time' ? true : false} />
                                </div>
                            </div>
                        </CardTitle>
                        <CardBody
                            style={{
                                paddingTop: 18,
                                paddingLeft: 40,
                                paddingRight: 40,
                            }}>
                            <TabContent activeTab={customActiveTab}>
                                <TabPane tabId="area">
                                    <Row>
                                        <Col md={7} lg={7} xl={7}>
                                            <div className="table-responsive ">
                                                <Table hover>
                                                    <thead
                                                        className="table-light "
                                                        style={{
                                                            borderTopColor: '#EAEBEC',
                                                            borderWidth: 1,
                                                            borderBottomColor: '#EAEBEC',
                                                        }}>
                                                        <tr className="align-items-center align-middle">
                                                            <th
                                                                className="align-items-center"
                                                                style={{
                                                                    fontSize: 14,
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Kanit',
                                                                    fontStyle: 'normal',
                                                                    color: '#495057',
                                                                    alignItems: 'center',
                                                                    alignSelf: 'center',
                                                                    paddingLeft: 27,
                                                                }}>
                                                                {t('userInformation.workingFields')}
                                                            </th>
                                                            <th
                                                                style={{
                                                                    fontSize: 14,
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Kanit',
                                                                    color: '#495057',
                                                                    fontStyle: 'normal',
                                                                }}>
                                                                {t('userInformation.fieldLocation')}
                                                            </th>
                                                            <th
                                                                style={{
                                                                    fontSize: 14,
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Kanit',
                                                                    color: '#495057',
                                                                    fontStyle: 'normal',
                                                                    paddingRight: 27,
                                                                }}>
                                                                {t('userInformation.areaRai')}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    {dataInformationArea?.length > 0 ? (
                                                        <tbody>
                                                            {dataInformationArea?.map((item, index) => (
                                                                <tr
                                                                    key={index}
                                                                    className="align-items-center align-middle cursor-pointer"
                                                                    onClick={() => this.handleChange(item.id)}>
                                                                    <th
                                                                        scope="row"
                                                                        className="d-flex"
                                                                        style={{
                                                                            padding: 0,
                                                                            paddingTop: 9,
                                                                            paddingBottom: 9,
                                                                            paddingLeft: 16,
                                                                            alignItems: 'center',
                                                                        }}>
                                                                        <img
                                                                            src={iconAccount}
                                                                            alt=""
                                                                            height={38}
                                                                            width={38}
                                                                        />
                                                                        <div
                                                                            style={{
                                                                                marginLeft: 15,
                                                                            }}>
                                                                            <p
                                                                                className="mb-0 "
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    fontWeight: 500,
                                                                                    fontFamily: 'Kanit',
                                                                                    color: '#495057',
                                                                                    fontStyle: 'normal',
                                                                                }}>
                                                                                {item?.area_name || '-'}
                                                                            </p>
                                                                        </div>
                                                                    </th>
                                                                    <td
                                                                        style={{
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                            fontFamily: 'Kanit',
                                                                            color: '#595757',
                                                                            fontStyle: 'normal',
                                                                        }}>
                                                                        {item?.subdistrict || '-'}{' '}
                                                                        {item?.district || '-'} {item?.province || '-'}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            fontSize: 14,
                                                                            fontWeight: 500,
                                                                            fontFamily: 'Inter',
                                                                            color: '#595757',
                                                                            fontStyle: 'normal',
                                                                        }}>
                                                                        {item?.area_size || '-'}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="4">
                                                                    <p
                                                                        style={{
                                                                            fontSize: 16,
                                                                            fontWeight: 400,
                                                                            fontFamily: 'Kanit',
                                                                            color: 'gray',
                                                                            textAlign: 'center',
                                                                            marginTop: 20,
                                                                            fontStyle: 'normal',
                                                                        }}>
                                                                        {t('common.noData')}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </Table>
                                            </div>
                                        </Col>
                                        <Col md={5} lg={5} xl={5}>
                                            {dataLocationArea?.lat_long ? (
                                                <MapPlot
                                                    keyIndex={dataInformationArea?.[0]?.id}
                                                    data_plot={dataInformationArea?.length}
                                                    data={dataLocationArea}
                                                    polygon={dataLocationArea?.area_geojson?.[0]?.polygon}
                                                    location={dataLocationArea?.lat_long}
                                                />
                                            ) : (
                                                <>
                                                    <p
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            fontFamily: 'Kanit',
                                                            color: 'gray',
                                                            textAlign: 'center',
                                                            marginLeft: 20,
                                                        }}>
                                                        {t('common.noData')}
                                                    </p>
                                                    <MapDefault />
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="time">
                                    <HistoryFlightTotal flightHistory={dataFlightHistory} />
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            )}
        </Row>
    )
}

export default withTranslation()(DataRegular)
