import {del, get, getWithQuery, post, put, upload} from '../../helpers/api_helper'

import {ENDPOINT} from 'constants/endpoint'

export const getFilterRegion = async () => {
    try {
        const request = await get(ENDPOINT.GET_FILTER_REGION)

        return request
    } catch (error) {
        return null
    }
}

export const getFilterDrone = async () => {
    try {
        const request = await get(ENDPOINT.GET_FILTER_DRONE)

        return request
    } catch (error) {
        return null
    }
}

export const getProvince = async () => {
    try {
        const response = await get(ENDPOINT.PROVINCE)

        return response
    } catch (error) {
        return null
    }
}

export const getDistrict = async id => {
    try {
        const response = await get(`${ENDPOINT.DISTRICT}/${id}`)

        return response
    } catch (error) {
        return null
    }
}

export const getFilterProvince = async params => {
    try {
        const response = await post(`${ENDPOINT.FILTER_PROVINCE}`, params)

        return response
    } catch (error) {
        return null
    }
}
export const getFilterDistrict = async params => {
    try {
        const response = await post(`${ENDPOINT.FILTER_DISTRICT}`, params)

        return response
    } catch (error) {
        return null
    }
}
