import React, {Component} from 'react'
import ReactApexChart from 'react-apexcharts'
import './style.scss'
import {withTranslation} from 'react-i18next'

var nf = new Intl.NumberFormat('en-NZ', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
})

class BoxPlot extends Component {
    constructor(props) {
        super(props)

        this.state = {
            options: {
                chart: {
                    type: 'boxPlot',
                    height: 380,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    animations: {
                        enabled: false,
                    },
                },
                plotOptions: {
                    boxPlot: {
                        colors: {
                            upper: '#FF7445',
                            lower: '#F1D9D9',
                        },
                    },
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                xaxis: {
                    tickAmount: 15,
                    axisTicks: {
                        show: false,
                        borderType: 'solid',
                        color: '#E0E0E0',
                        height: 6,
                        // offsetX: 20,
                    },
                    title: {
                        text: this.props.nameX,
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            fontSize: '14px',
                        },
                    },
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Inter',
                        color: '#495057',
                    },
                },
                yaxis: {
                    min: 0,
                    tickAmount: 4,
                    axisTicks: {
                        show: false,
                        borderType: 'solid',
                        color: '#E0E0E0',
                        height: 6,
                        // offsetX: 20,
                    },
                    axisBorder: {
                        show: true,
                        color: '#E0E0E0',
                    },
                    title: {
                        text: this.props.nameY,
                        style: {
                            fontSize: '14px',
                        },
                        offsetX: 8,
                        offsetY: 0,
                    },
                    labels: {
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Inter sans-serif !important',
                            cssClass: 'xaxis-label',
                        },
                        formatter: value => {
                            return nf.format(Number(value))
                        },
                    },
                },
                tooltip: {
                    x: {
                        show: true,
                    },
                    enabled: true,
                    custom({series, seriesIndex, dataPointIndex, w}) {
                        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]
                        var seriesname = w.globals.initialSeries[seriesIndex].name
                        return (
                            '<div style="width: 180px;  padding-bottom: 12px;  background: #fff;" >' +
                            '<div style="width: 180px;  padding-top: 10px; 12px;padding-bottom: 1px; padding-left: 15px; background: #EFEFEF;">' +
                            '<h5 style="color:#000; font-family: Inter; font-style: normal; font-weight: 600;font-size: 14px;">' +
                            data.x +
                            '</h5>' +
                            '</div>' +
                            '<h5 style="color:#595757; font-family: Inter; font-style: normal;font-weight: 400;font-size: 15px; display: flex;  padding-left: 12px; padding-top: 12px;">' +
                            'Maximum' +
                            '\xa0\xa0' +
                            ':' +
                            '\xa0\xa0' +
                            '<span style="color:#595757; font-family: Inter; font-weight: 600;font-size: 15px;">' +
                            nf.format(data.y[0]) +
                            '</span>' +
                            '</h5>' +
                            '<h5 style="color:#595757; font-family: Inter; font-style: normal;font-weight: 400;font-size: 15px; display: flex; padding-left: 12px; margin-top: -3px;">' +
                            'Q3' +
                            '\xa0\xa0' +
                            ':' +
                            '\xa0\xa0' +
                            '<span style="color:#595757; font-family: Inter; font-weight: 600;font-size: 14px;">' +
                            nf.format(data.y[1]) +
                            '</span>' +
                            '</h5>' +
                            '<h5 style="color:#595757; font-family: Inter; font-style: normal;font-weight: 400;font-size: 15px; display: flex; padding-left: 12px; margin-top: -3px;">' +
                            'Median' +
                            '\xa0\xa0' +
                            ':' +
                            '\xa0\xa0' +
                            '<span style="color:#595757; font-family: Inter; font-weight: 600;font-size: 14px;">' +
                            nf.format(data.y[2]) +
                            '</span>' +
                            '</h5>' +
                            '<h5 style="color:#595757; font-family: Inter; font-style: normal;font-weight: 400;font-size: 15px; display: flex; padding-left: 12px; margin-top: -3px;">' +
                            'Q1' +
                            '\xa0\xa0' +
                            ':' +
                            '\xa0\xa0' +
                            '<span style="color:#595757; font-family: Inter; font-weight: 600;font-size: 14px;">' +
                            nf.format(data.y[3]) +
                            '</span>' +
                            '</h5>' +
                            '<h5 style="color:#595757; font-family: Inter; font-style: normal;font-weight: 400;font-size: 15px; display: flex; padding-left: 12px; margin-top: -3px;">' +
                            'Minimum' +
                            '\xa0\xa0' +
                            ':' +
                            '\xa0\xa0' +
                            '<span style="color:#595757; font-family: Inter; font-weight: 600;font-size: 14px;">' +
                            nf.format(data.y[4]) +
                            '</span>' +
                            '</h5>' +
                            '</div>'
                        )
                    },
                },
            },
        }
    }
    render() {
        const topix = this.state
        switch (this.props.type) {
            case this?.props?.t('userInalyze.numberOfFlightsTimes'):
                topix.options = {
                    ...topix.options,
                    yaxis: {
                        min: 0,
                        max: this.props.max_x == 0 ? 5 : this.props.max_x,
                        tickAmount: 4,
                        axisTicks: {
                            show: false,
                            borderType: 'solid',
                            color: '#E0E0E0',
                            height: 6,
                            // offsetX: 20,
                        },
                        axisBorder: {
                            show: true,
                            color: '#E0E0E0',
                        },
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Inter sans-serif !important',
                                cssClass: 'xaxis-label',
                            },
                            formatter: value => {
                                return nf.format(Number(value))
                            },
                        },
                        title: {
                            text: this.props.nameY,
                            style: {
                                fontSize: '14px',
                            },
                            offsetX: 8,
                            offsetY: 0,
                        },
                    },
                }
                break
            case this?.props?.t('userInalyze.numberOfSprayingTimes'):
                topix.options = {
                    ...topix.options,
                    yaxis: {
                        min: 0,
                        max: this.props.max_x == 0 ? 5 : this.props.max_x,
                        tickAmount: 4,
                        axisTicks: {
                            show: false,
                            borderType: 'solid',
                            color: '#E0E0E0',
                            height: 6,
                            // offsetX: 20,
                        },
                        axisBorder: {
                            show: true,
                            color: '#E0E0E0',
                        },
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Inter sans-serif !important',
                                cssClass: 'xaxis-label',
                            },
                            formatter: value => {
                                return nf.format(Number(value))
                            },
                        },
                        title: {
                            text: this.props.nameY,
                            style: {
                                fontSize: '14px',
                            },
                            offsetX: 8,
                            offsetY: 0,
                        },
                    },
                }
                break
            case this?.props?.t('userInalyze.numberOfSpreadingTimes'):
                topix.options = {
                    ...topix.options,
                    yaxis: {
                        min: 0,
                        max: this.props.max_x == 0 ? 5 : this.props.max_x,
                        tickAmount: 4,
                        axisTicks: {
                            show: false,
                            borderType: 'solid',
                            color: '#E0E0E0',
                            height: 6,
                            // offsetX: 20,
                        },
                        axisBorder: {
                            show: true,
                            color: '#E0E0E0',
                        },
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Inter sans-serif !important',
                                cssClass: 'xaxis-label',
                            },
                            formatter: value => {
                                return nf.format(Number(value))
                            },
                        },
                        title: {
                            text: this.props.nameY,
                            style: {
                                fontSize: '14px',
                            },
                            offsetX: 8,
                            offsetY: 0,
                        },
                    },
                }
                break
            case this?.props?.t('userInalyze.spreadingRateKgTai'):
                topix.options = {
                    ...topix.options,
                    yaxis: {
                        min: 0,
                        max: this.props.max_x == 0 ? 5 : this.props.max_x,
                        tickAmount: 4,
                        axisTicks: {
                            show: false,
                            borderType: 'solid',
                            color: '#E0E0E0',
                            height: 6,
                            // offsetX: 20,
                        },
                        axisBorder: {
                            show: true,
                            color: '#E0E0E0',
                        },
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Inter sans-serif !important',
                                cssClass: 'xaxis-label',
                            },
                            formatter: value => {
                                return nf.format(Number(value))
                            },
                        },
                        title: {
                            text: this.props.nameY,
                            style: {
                                fontSize: '14px',
                            },
                            offsetX: 8,
                            offsetY: 0,
                        },
                    },
                }
                break
            case this?.props?.t('userInalyze.sprayingRateLRai'):
                topix.options = {
                    ...topix.options,
                    yaxis: {
                        min: 0,
                        max: this.props.max_x == 0 ? 5 : this.props.max_x,
                        tickAmount: 4,
                        axisTicks: {
                            show: false,
                            borderType: 'solid',
                            color: '#E0E0E0',
                            height: 6,
                            // offsetX: 20,
                        },
                        axisBorder: {
                            show: true,
                            color: '#E0E0E0',
                        },
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Inter sans-serif !important',
                                cssClass: 'xaxis-label',
                            },
                            formatter: value => {
                                return nf.format(Number(value))
                            },
                        },
                        title: {
                            text: this.props.nameY,
                            style: {
                                fontSize: '14px',
                            },
                            offsetX: 8,
                            offsetY: 0,
                        },
                    },
                }
                break
            default:
                topix.options = {
                    ...topix.options,
                    yaxis: {
                        min: 0,
                        max: this.props.max_x == 0 ? 5 : this.props.max_x,
                        tickAmount: 4,
                        axisTicks: {
                            show: false,
                            borderType: 'solid',
                            color: '#E0E0E0',
                            height: 6,
                            // offsetX: 20,
                        },
                        axisBorder: {
                            show: true,
                            color: '#E0E0E0',
                        },
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Inter sans-serif !important',
                                cssClass: 'xaxis-label',
                            },
                            formatter: value => {
                                return nf.format(Number(value))
                            },
                        },
                        title: {
                            text: this.props.nameY,
                            style: {
                                fontSize: '14px',
                            },
                            offsetX: 8,
                            offsetY: 0,
                        },
                    },
                }
                break
        }
        return (
            <React.Fragment>
                <ReactApexChart
                    options={this.state.options}
                    series={this.props.series?.length > 0 ? this.props.series : []}
                    type="boxPlot"
                    height={380}
                />
            </React.Fragment>
        )
    }
}

export default withTranslation()(BoxPlot)
