import React from 'react'
import './flight-history-detail-skeleton.scss'
import {Row, Col} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const FlightHistoryDetailTitleSkeleton = () => {
    return (
        <Row className="container-skeleton-flight-history-detail">
            <Col xs={10} md={10} lg={10} xl={10}>
                <Skeleton height={36} width={128} />
            </Col>
            <Col className="col-button-back" xs={2} md={2} lg={2} xl={2}>
                <Skeleton height={48} width={107} style={{borderRadius: 8}} />
            </Col>
        </Row>
    )
}

export default FlightHistoryDetailTitleSkeleton
