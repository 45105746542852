import React from 'react'

export const buttonName = [
    {title: 'สัปดาห์', value: 'week'},
    {title: 'เดือน', value: 'month'},
    {title: '\xa0\xa0\xa0' + 'ปี' + '\xa0\xa0\xa0', value: 'year'},
    {title: 'ไตรมาส', value: 'quarter'},
    {title: 'ครึ่งปี', value: 'halfYear'},
    {title: 'กำหนดเอง', value: 'period'},
]

export const optionsData = [
    {
        label: 'รุ่นโดรน',
        value: 'dronemodel',
    },
    {
        label: 'ประเทศ',
        value: 'country',
    },
    {
        label: 'ภูมิภาค',
        value: 'region',
    },
    {
        label: 'จังหวัด',
        value: 'province',
    },
]
export const countryOptions = [
    {
        label: 'ทั้งหมด',
        value: 'all',
    },
    {
        label: 'ไทย',
        value: 'th',
    },
    {
        label: 'ลาว',
        value: 'la',
    },
    {
        label: 'กัมพูชา',
        value: 'kh',
    },
]

export const regionOptions = [
    {
        label: 'ทั้งหมด',
        value: 'region_all',
    },
    {
        label: 'เหนือ',
        value: 'north',
    },
    {
        label: 'ตะวันออกเฉียงเหนือ',
        value: 'northeast',
    },
    {
        label: 'กลาง',
        value: 'central',
    },
    {
        label: 'ใต้',
        value: 'south',
    },
]
export const dataXY = [
    {
        value: 'working_area',
        label: 'พื้นที่การทำงาน (ไร่)',
        disabled: false,
    },
    // {
    //   value: "fly_frequency",
    //   label: "ความถี่ในการบิน (ชม./ไร่)",
    // },
    {
        value: 'fly_amount',
        label: 'จำนวนเที่ยวบิน (เที่ยว)',
        disabled: false,
    },
    {
        value: 'spraying_trip',
        label: 'จำนวนการฉีดพ่น (เที่ยว)',
        disabled: false,
    },
    {
        value: 'spreading_trip',
        label: 'จำนวนการหว่าน (เที่ยว)',
        disabled: false,
    },
    {
        value: 'spreading_area',
        label: 'อัตราการหว่าน (กก./ไร่)',
        disabled: false,
    },
    {
        value: 'spraying_area',
        label: 'อัตราการฉีดพ่น (ล./ไร่)',
        disabled: false,
    },
]

export const datatest = [
    [
        {
            v: 'Country',
            f: 'value',
        },
        '',
    ],

    ['LA', {v: 0, f: 'ลาว 0'}],
    ['KH', {v: 1, f: 'กัมพูชา 0'}],
    ['TH-15', {v: 2, f: 'อ่างทอง 0'}],
    ['TH-14', {v: 2, f: 'พระนครศรีอยุธยา 0'}],
    ['TH-10', {v: 2, f: '	กรุงเทพมหานคร 0'}],
    ['TH-18', {v: 2, f: 'ชัยนาท 0'}],
    ['TH-16', {v: 2, f: 'ลพบุรี 0'}],
    ['TH-73', {v: 2, f: 'นครปฐม 0'}],
    ['TH-12', {v: 2, f: 'นนทบุรี 0'}],
    ['TH-13', {v: 2, f: 'ปทุมธานี 0'}],
    ['TH-11', {v: 2, f: 'สมุทรปราการ 0'}],
    ['TH-75', {v: 2, f: '	สมุทรสงคราม 0'}],
    ['TH-74', {v: 2, f: 'สมุทรสาคร 0'}],
    ['TH-19', {v: 2, f: 'สระบุรี 0'}],
    ['TH-17', {v: 2, f: 'สิงห์บุรี 0'}],
    ['TH-72', {v: 2, f: 'สุพรรณบุรี 0'}],
    ['TH-26', {v: 2, f: 'นครนายก 0'}],
    ['TH-24', {v: 2, f: 'ฉะเชิงเทรา 0'}],
    ['TH-22', {v: 2, f: 'จันทบุรี 0'}],
    ['TH-20', {v: 2, f: 'ชลบุรี 0'}],
    ['TH-25', {v: 2, f: 'ปราจีนบุรี 0'}],
    ['TH-21', {v: 2, f: 'ระยอง 0'}],
    ['TH-27', {v: 2, f: 'สระแก้ว 0'}],
    ['TH-23', {v: 2, f: 'ตราด 0'}],
    ['TH-71', {v: 2, f: 'กาญจนบุรี 0'}],
    ['TH-70', {v: 2, f: 'ราชบุรี 0'}],
    ['TH-76', {v: 2, f: 'เพชรบุรี 0'}],
    ['TH-77', {v: 2, f: 'ประจวบคีรีขันธ์ 0'}],
    ['TH-38', {v: 2, f: 'บึงกาฬ 0'}],
    ['TH-31', {v: 2, f: 'บุรีรัมย์ 0'}],
    ['TH-37', {v: 2, f: 'อำนาจเจริญ 0'}],
    ['TH-36', {v: 2, f: 'ชัยภูมิ 0'}],
    ['TH-46', {v: 2, f: 'กาฬสินธุ์ 0'}],
    ['TH-40', {v: 2, f: 'ขอนแก่น 0'}],
    ['TH-42', {v: 2, f: 'เลย 0'}],
    ['TH-44', {v: 2, f: 'มหาสารคาม 0'}],
    ['TH-49', {v: 2, f: 'มุกดาหาร 0'}],
    ['TH-48', {v: 2, f: 'นครพนม 0'}],
    ['TH-30', {v: 2, f: 'นครราชสีมา 140'}],
    ['TH-39', {v: 2, f: 'หนองบัวลำภู 0'}],
    ['TH-43', {v: 2, f: 'หนองคาย 0'}],
    ['TH-45', {v: 2, f: 'ร้อยเอ็ด 0'}],
    ['TH-47', {v: 2, f: 'สกลนคร 0'}],
    ['TH-33', {v: 2, f: 'ศรีสะเกษ 0'}],
    ['TH-32', {v: 2, f: 'สุรินทร์ 0'}],
    ['TH-34', {v: 2, f: 'อุบลราชธานี 0'}],
    ['TH-41', {v: 2, f: 'อุดรธานี 0'}],
    ['TH-35', {v: 2, f: 'ยโสธร 0'}],
    ['TH-50', {v: 2, f: 'เชียงใหม่ 0'}],
    ['TH-57', {v: 2, f: 'เชียงราย 0'}],
    ['TH-52', {v: 2, f: 'ลำปาง 0'}],
    ['TH-51', {v: 2, f: 'ลำพูน 0'}],
    ['TH-58', {v: 2, f: 'แม่ฮ่องสอน 0'}],
    ['TH-55', {v: 2, f: 'น่าน 0'}],
    ['TH-56', {v: 2, f: 'พะเยา 0'}],
    ['TH-54', {v: 2, f: 'แพร่ 0'}],
    ['TH-53', {v: 2, f: 'อุตรดิตถ์ 0'}],
    ['TH-63', {v: 2, f: 'ตาก 0'}],
    ['TH-64', {v: 2, f: 'สุโขทัย 0'}],
    ['TH-65', {v: 2, f: 'พิษณุโลก 0'}],
    ['TH-66', {v: 2, f: 'พิจิตร 0'}],
    ['TH-62', {v: 2, f: 'กำแพงเพชร 0'}],
    ['TH-67', {v: 2, f: 'เพชรบูรณ์ 0'}],
    ['TH-60', {v: 2, f: 'นครสวรรค์ 0'}],
    ['TH-61', {v: 2, f: 'อุทัยธานี 0'}],
    ['TH-86', {v: 2, f: 'ชุมพร 0'}],
    ['TH-80', {v: 2, f: 'นครศรีธรรมราช 0'}],
    ['TH-96', {v: 2, f: 'นราธิวาส 0'}],
    ['TH-94', {v: 2, f: 'ปัตตานี 0'}],
    ['TH-93', {v: 2, f: 'พัทลุง 0'}],
    ['TH-90', {v: 2, f: 'สงขลา 0'}],
    ['TH-84', {v: 2, f: 'สุราษฎร์ธานี 0'}],
    ['TH-95', {v: 2, f: 'ยะลา 0'}],
    ['TH-81', {v: 2, f: 'กระบี่ 0'}],
    ['TH-82', {v: 2, f: 'พังงา 0'}],
    ['TH-83', {v: 2, f: 'ภูเก็ต 0'}],
    ['TH-85', {v: 2, f: 'ระนอง 0'}],
    ['TH-91', {v: 2, f: 'สตูล 0'}],
    ['TH-92', {v: 2, f: 'ตรัง 0'}],
]

export const datatestth = [
    [
        {
            v: 'Country',
            f: 'value',
        },
        '',
    ],

    ['TH-15', {v: 2, f: 'อ่างทอง 0'}],
    ['TH-14', {v: 2, f: 'พระนครศรีอยุธยา 0'}],
    ['TH-10', {v: 2, f: '	กรุงเทพมหานคร 0'}],
    ['TH-18', {v: 2, f: 'ชัยนาท 0'}],
    ['TH-16', {v: 2, f: 'ลพบุรี 0'}],
    ['TH-73', {v: 2, f: 'นครปฐม 0'}],
    ['TH-12', {v: 2, f: 'นนทบุรี 0'}],
    ['TH-13', {v: 2, f: 'ปทุมธานี 0'}],
    ['TH-11', {v: 2, f: 'สมุทรปราการ 0'}],
    ['TH-75', {v: 2, f: '	สมุทรสงคราม 0'}],
    ['TH-74', {v: 2, f: 'สมุทรสาคร 0'}],
    ['TH-19', {v: 2, f: 'สระบุรี 0'}],
    ['TH-17', {v: 2, f: 'สิงห์บุรี 0'}],
    ['TH-72', {v: 2, f: 'สุพรรณบุรี 0'}],
    ['TH-26', {v: 2, f: 'นครนายก 0'}],
    ['TH-24', {v: 2, f: 'ฉะเชิงเทรา 0'}],
    ['TH-22', {v: 2, f: 'จันทบุรี 0'}],
    ['TH-20', {v: 2, f: 'ชลบุรี 0'}],
    ['TH-25', {v: 2, f: 'ปราจีนบุรี 0'}],
    ['TH-21', {v: 2, f: 'ระยอง 0'}],
    ['TH-27', {v: 2, f: 'สระแก้ว 0'}],
    ['TH-23', {v: 2, f: 'ตราด 0'}],
    ['TH-71', {v: 2, f: 'กาญจนบุรี 0'}],
    ['TH-70', {v: 2, f: 'ราชบุรี 0'}],
    ['TH-76', {v: 2, f: 'เพชรบุรี 0'}],
    ['TH-77', {v: 2, f: 'ประจวบคีรีขันธ์ 0'}],
    ['TH-38', {v: 2, f: 'บึงกาฬ 0'}],
    ['TH-31', {v: 2, f: 'บุรีรัมย์ 0'}],
    ['TH-37', {v: 2, f: 'อำนาจเจริญ 0'}],
    ['TH-36', {v: 2, f: 'ชัยภูมิ 0'}],
    ['TH-46', {v: 2, f: 'กาฬสินธุ์ 0'}],
    ['TH-40', {v: 2, f: 'ขอนแก่น 0'}],
    ['TH-42', {v: 2, f: 'เลย 0'}],
    ['TH-44', {v: 2, f: 'มหาสารคาม 0'}],
    ['TH-49', {v: 2, f: 'มุกดาหาร 0'}],
    ['TH-48', {v: 2, f: 'นครพนม 0'}],
    ['TH-30', {v: 2, f: 'นครราชสีมา 140'}],
    ['TH-39', {v: 2, f: 'หนองบัวลำภู 0'}],
    ['TH-43', {v: 2, f: 'หนองคาย 0'}],
    ['TH-45', {v: 2, f: 'ร้อยเอ็ด 0'}],
    ['TH-47', {v: 2, f: 'สกลนคร 0'}],
    ['TH-33', {v: 2, f: 'ศรีสะเกษ 0'}],
    ['TH-32', {v: 2, f: 'สุรินทร์ 0'}],
    ['TH-34', {v: 2, f: 'อุบลราชธานี 0'}],
    ['TH-41', {v: 2, f: 'อุดรธานี 0'}],
    ['TH-35', {v: 2, f: 'ยโสธร 0'}],
    ['TH-50', {v: 2, f: 'เชียงใหม่ 0'}],
    ['TH-57', {v: 2, f: 'เชียงราย 0'}],
    ['TH-52', {v: 2, f: 'ลำปาง 0'}],
    ['TH-51', {v: 2, f: 'ลำพูน 0'}],
    ['TH-58', {v: 2, f: 'แม่ฮ่องสอน 0'}],
    ['TH-55', {v: 2, f: 'น่าน 0'}],
    ['TH-56', {v: 2, f: 'พะเยา 0'}],
    ['TH-54', {v: 2, f: 'แพร่ 0'}],
    ['TH-53', {v: 2, f: 'อุตรดิตถ์ 0'}],
    ['TH-63', {v: 2, f: 'ตาก 0'}],
    ['TH-64', {v: 2, f: 'สุโขทัย 0'}],
    ['TH-65', {v: 2, f: 'พิษณุโลก 0'}],
    ['TH-66', {v: 2, f: 'พิจิตร 0'}],
    ['TH-62', {v: 2, f: 'กำแพงเพชร 0'}],
    ['TH-67', {v: 2, f: 'เพชรบูรณ์ 0'}],
    ['TH-60', {v: 2, f: 'นครสวรรค์ 0'}],
    ['TH-61', {v: 2, f: 'อุทัยธานี 0'}],
    ['TH-86', {v: 2, f: 'ชุมพร 0'}],
    ['TH-80', {v: 2, f: 'นครศรีธรรมราช 0'}],
    ['TH-96', {v: 2, f: 'นราธิวาส 0'}],
    ['TH-94', {v: 2, f: 'ปัตตานี 0'}],
    ['TH-93', {v: 2, f: 'พัทลุง 0'}],
    ['TH-90', {v: 2, f: 'สงขลา 0'}],
    ['TH-84', {v: 2, f: 'สุราษฎร์ธานี 0'}],
    ['TH-95', {v: 2, f: 'ยะลา 0'}],
    ['TH-81', {v: 2, f: 'กระบี่ 0'}],
    ['TH-82', {v: 2, f: 'พังงา 0'}],
    ['TH-83', {v: 2, f: 'ภูเก็ต 0'}],
    ['TH-85', {v: 2, f: 'ระนอง 0'}],
    ['TH-91', {v: 2, f: 'สตูล 0'}],
    ['TH-92', {v: 2, f: 'ตรัง 0'}],
]
export const datatestla = [
    [
        {
            v: 'Country',
            f: 'value',
        },
        '',
    ],

    ['LA', {v: 0, f: 'ลาว 0', p: '0', code_jvector: 'LA'}],
]
export const datatestkh = [
    [
        {
            v: 'Country',
            f: 'value',
        },
        '',
    ],

    ['KH', {v: 1, f: 'กัมพูชา 0', p: '0', code_jvector: 'KH'}],
]
