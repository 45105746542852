import React from 'react'
import '../skeleton-droneInformation.scss'
import {Row, Col, Card, CardBody} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonDroneFlightListsTable = () => {
    return (
        <Card style={{borderRadius: '8px'}}>
            <CardBody>
                <Row>
                    <Col xl={5}>
                        <Skeleton width={110} height={30} />
                    </Col>
                    <Col
                        xl={1}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            padding: 0,
                            margin: 0,
                        }}>
                        <Skeleton width={64} height={23} />
                    </Col>
                    <Col xl={3}>
                        <div
                            style={{
                                marginRight: 16,
                                height: 38,
                                width: 376,
                                borderRadius: 8,
                                padding: '4px 11px',
                                border: '1px solid #ced4da',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                            <Skeleton width={151} height={22} />
                            <Skeleton width={151} height={22} />
                        </div>
                    </Col>
                    <Col xl={3}>
                        <div
                            style={{
                                marginRight: 16,
                                height: 38,
                                width: 376,
                                borderRadius: 8,
                                padding: '4px 11px',
                                border: '1px solid #ced4da',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                            <Skeleton width={85} height={22} />
                        </div>
                    </Col>
                    <Col
                        xl={12}
                        style={{
                            marginTop: '24px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                        <Skeleton width={1580} height={623} style={{marginLeft: 20, marginRight: 20}} />
                        <Skeleton width={470} height={32} style={{marginTop: 16}} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default SkeletonDroneFlightListsTable
