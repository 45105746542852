import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {Row, Col, Card, CardBody, CardTitle} from 'reactstrap'
import './style.scss'

const TotalAreaAndAverageFlightSkeleton = () => {
    return (
        <Col lg={12}>
            <Card className="skeleton-total-average ">
                <CardTitle className="head-title">
                    <Row className="row-filter">
                        <Col xl={1} className="col-text">
                            <Skeleton width={100} />
                        </Col>
                        <Col xl={11}>
                            <Row className="row-select">
                                <Col xl={5} className="col-province-district">
                                    <div className="filter-avg" style={{marginLeft: 15}}>
                                        <Skeleton width={100} />
                                    </div>
                                </Col>
                                <Col xl={5} className="col-province-district">
                                    <div className="filter-avg" style={{marginLeft: 15}}>
                                        <Skeleton width={100} />
                                    </div>
                                </Col>
                                <Col xl={2} className="col-province-district">
                                    <Skeleton width={80} height={33} style={{borderRadius: 8}} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardTitle>
                <CardBody>
                    <Row>
                        <Col lg="6">
                            <div className="justify-content-start">
                                <Skeleton height={16} width={170} />
                                <Skeleton width={200} />
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                                <div className="d-flex align-items-center justify-content-between">
                                    <Skeleton width={70} height={16} style={{}} />
                                    <Skeleton width={70} height={16} style={{marginLeft: 15}} />
                                </div>
                            </div>
                            <div className="card-skeleton " style={{marginTop: 20}}>
                                <Skeleton height={280} />
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="justify-content-start">
                                <Skeleton height={16} width={170} />
                                <Skeleton width={200} />
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                                <div className="d-flex align-items-center justify-content-between">
                                    <Skeleton width={70} height={16} style={{}} />
                                </div>
                            </div>
                            <div className="card-skeleton " style={{marginTop: 20}}>
                                <Skeleton height={280} />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

export default TotalAreaAndAverageFlightSkeleton
