import { takeEvery, put, call } from "redux-saga/effects";

// Chat Redux States
import { GET_DISTRICT } from "./actionTypes";
import { getdistrictSuccess, getdistrictFail } from "../actions";

//Include Both Helper File with needed methods
import { getdistrict } from "../../services/skc/dashboard";

function* onGetDistrict({ id }) {
 
  try {
    const response = yield call( getdistrict, id);
    
    yield put(getdistrictSuccess(response));
  } catch (error) {
    yield put(getdistrictFail(error));
  }
}

function* districtSaga() {
  yield takeEvery(GET_DISTRICT, onGetDistrict);
}

export default districtSaga;
