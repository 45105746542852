import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React, { Component, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

const CardSkeleton = ({ }) => {
  const loadCards = Array(3).fill(1);
  const loadCards2 = Array(6).fill(1);
  return (
    <Col>
      <Card
        style={{
          display: "flex",
          borderRadius: 8,
          boxShadow: "0px 12px 24px rgb(240, 240, 240)",
          height: 410,
        }}
      >
        <CardBody>
          <div className="row">
            <div className="col-6">
              <Skeleton width={140} height={22} style={{ marginTop: 12, marginBottom: 14 }} />
              {loadCards.map((card, i) => (
                <div key={i} className="row" style={{ marginTop: 12 }}>
                  <div className="col-4">
                    <Skeleton height={62} />
                  </div>
                  <div className="col-4">
                    <Skeleton height={62} />
                  </div>
                  <div className="col-4">
                    <Skeleton height={62} />
                  </div>
                </div>
              ))}
            </div>
            <div className="col-6">
            <Skeleton width={140} height={22} style={{ marginTop: 12, marginBottom: 24 }} />
              {loadCards2.map((card, i) => (
                <div key={i}>
                  <div className="row">
                    <div className="col-4">
                      <Skeleton />
                    </div>
                    <div className="col-8">
                      <Skeleton />
                    </div>
                  </div>
                  <Skeleton height={1} style={{ marginBottom: 10 }} />
                </div>))}        
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CardSkeleton;
