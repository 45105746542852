import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React, { Component, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

const CardSkeleton = ({}) => {
  return (
    <Col>
      <Card
        style={{
          display: "flex",
          borderRadius: 8,
          boxShadow: "0px 12px 24px rgb(240, 240, 240)",
        }}
      >
        <CardTitle
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            marginTop: 20,
            paddingLeft: 25,
            paddingRight: 25,
            paddingBottom: 20,
            borderBottom: "1px solid #EDECEC",
          }}
        >
          <div style={{ marginLeft: 15 }}>
            <Skeleton width={80} height={33} style={{ borderRadius: 8 }} />
          </div>
          <div style={{ marginLeft: 15 }}>
            <Skeleton width={80} height={33} style={{ borderRadius: 8 }} />
          </div>
          <div style={{ marginLeft: 15 }}>
            <Skeleton width={80} height={33} style={{ borderRadius: 8 }} />
          </div>
        </CardTitle>
        <CardBody>
          <Row>
            <Col lg="12">
              <div className="card-skeleton " style={{ marginTop: 10 }}>
                <Skeleton height={448} />
              </div>
            </Col>      
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CardSkeleton;
