import {
  GET_DISTRICT,
  GET_DISTRICT_FAIL,
  GET_DISTRICT_SUCCESS,
 
} from "./actionTypes";

export const getdistrict = (id) => ({
  type: GET_DISTRICT,
  id
});

export const getdistrictSuccess = (district) => ({
  type: GET_DISTRICT_SUCCESS,
  payload:district,
});

export const getdistrictFail = (error) => ({
  type: GET_DISTRICT_FAIL,
  payload: error,
});

