import React from 'react'

import {Row, Col} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import '../comparison-skeleton.scss'

const FormTimeSkeleton = () => {
    return (
        <Col xl={12} className="form-time">
            <Row>
                <Col xs={12} sm={2} md={2} lg={2} className="mb-2">
                    <div className="label-form">
                        <Skeleton width={103} />
                    </div>
                </Col>
                <Col xs={12} sm={10} md={6} lg={3} className="mb-2">
                    <Skeleton width={376} height={32} />
                </Col>
                <Col xs={12} sm={12} md={4} lg={7}></Col>
                <Col xs={12} sm={2} md={2} lg={2} className="mb-2">
                    <div className="label-form">
                        <Skeleton width={64} />
                    </div>
                </Col>
                <Col xs={12} sm={10} md={10} lg={10} className="mb-2">
                    <Skeleton width={1310} height={32} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} className="my-4" style={{borderBottom: '2px solid #eff2f7'}}></Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-2 mt-2" style={{margin: 0, padding: 0}}>
                    <Row style={{margin: 0, padding: 0}}>
                        <Col xs={12} sm={12} md={12} lg={12} className="mb-2">
                            <Skeleton width={144} className="label-title-form sub-paragraph" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} style={{margin: 0, padding: 0}}>
                            <Skeleton width={1247} height={22} className=" mb-2 sub-paragraph" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} style={{margin: 0, padding: 0}}>
                            <Skeleton width={1560} height={32} className="mb-2 sub-paragraph" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} style={{margin: 0, padding: 0}}>
                            <Skeleton width={1560} height={32} className="mb-2 sub-paragraph" />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-2  mt-4" style={{margin: 0, padding: 0}}>
                    <Row style={{margin: 0, padding: 0}}>
                        <Col xs={12} sm={12} md={12} lg={12} className="mb-2">
                            <Skeleton width={144} className="label-title-form sub-paragraph" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} style={{margin: 0, padding: 0}}>
                            <Skeleton width={1247} height={22} className=" mb-2 sub-paragraph" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} style={{margin: 0, padding: 0}}>
                            <Skeleton width={1560} height={32} className="mb-2 sub-paragraph" />
                        </Col>
                    </Row>
                </Col>
                <Col xs={6} sm={4} md={3} lg={3} xl={2} className=" sub-paragraph mt-3" style={{marginBottom: 34}}>
                    <Skeleton width={250} height={37} style={{borderRadius: 8}} />
                </Col>
                <Col xs={6} sm={4} md={3} lg={3} xl={2} className="mb-2 mt-3">
                    <Skeleton width={170} height={37} style={{borderRadius: 8}} />
                </Col>
            </Row>
        </Col>
    )
}

export default FormTimeSkeleton
