const dashboard = {
    cumulativeUsers: 'จำนวนผู้ใช้สะสม',
    cumulativeDrones: 'จำนวนโดรนสะสม',
    totalAmountOfUserPlotSpaceRai: 'จำนวนพื้นที่แปลงของผู้ใช้สะสม (ไร่)',

    numberOfDrones: 'จำนวนโดรน',
    numberOfUsers: 'จำนวนผู้ใช้',
    droneOverview: 'Drone Overview',
    totalNumberOfFlights: 'จำนวนเที่ยวบินทั้งหมด',
    totalFlightHours: 'จำนวนชั่วโมงบินทั้งหมด',
    totalWorkAreas: 'พื้นที่ทำงานทั้งหมด',
    numberOfWorkingDrones: 'จำนวนโดรนที่ทำงาน',
    averageDroneUsageData: 'ข้อมูลเฉลี่ยการใช้งานโดรน',
    averageWorkingTime: 'ระยะเวลาการทำงานเฉลี่ย',
    averageWorkArea: 'พื้นที่ทำงานเฉลี่ย',
    averageAmountFlights: 'จำนวนเที่ยวบินเฉลี่ย',
    cumulativeWorkingArea: 'พื้นที่การทำงานสะสม',
    cumulativeFlightHours: 'ชั่วโมงบินสะสม',
    cumulativeAmountTaskDays: 'จำนวนวันทำงานสะสม',
    cumulativeLifespan: 'อายุการใช้งานสะสม',
    averageWorkingAreaPerDrone: 'พื้นที่การทำงานเฉลี่ยต่อลำ',
    averageFlightHoursPerDrone: 'ชั่วโมงบินเฉลี่ยต่อลำ',
    averageWorkingDaysPerDrone: 'จำนวนวันทำงานเฉลี่ยต่อลำ',
    averageOperationalAgePerDrone: 'อายุการใช้งานเฉลี่ยต่อลำ',
    days: 'วัน',
    droneTypes: 'ประเภทโดรน',
    numberOfDronesAddedForEachType: 'จำนวนโดรนที่เพิ่มขึ้นในแต่ละประเภท',
    cumulativeData: 'ข้อมูลสะสม',
    averagePerDrone: 'ข้อมูลเฉลี่ยต่อลำ',
    cumulativeNumberOfWorkingDrones: 'จำนวนโดรนที่ทำงานสะสม',
    accumulate: 'สะสม',
    average: 'เฉลี่ย',
    numberOfDronesWorkingEachDay: 'จำนวนโดรนที่ทำงานในแต่ละวัน',
    dronesRegisteredInTheKiDroneSystem: 'โดรนที่ลงทะเบียนในระบบ K-iAgri',
    droneAmount: 'จำนวนโดรน',
    summaryOfTotalAreas: 'สรุปพื้นที่ทั้งหมด',
    workingAreaFromDrones: 'พื้นที่การทำงานจากโดรน',
    areaRai: 'จำนวนพื้นที่ (ไร่)',
    workArea: 'พื้นที่ทำงาน',
    totalArea: 'พื้นที่ทั้งหมด',
    userOverview: 'User Overview',
    droneAmountUnit: 'จำนวนโดรน (ลำ)',
    averageFlightTimeHrUnit: 'ระยะเวลาการบินเฉลี่ย (ชม./ลำ)',
    averageFlightTime: 'ระยะเวลาบินเฉลี่ย',
    averageDroneFlightTime: 'ระยะเวลาการบินเฉลี่ยของโดรน',
    flightDurationHrUnit: 'ระยะเวลาการบิน (ชั่วโมง)',
    totalFlightHistory: 'ประวัติการบินทั้งหมด',
    droneFlightHistory: 'ประวัติการบินจากโดรน',
    flightTimeRanking: 'อันดับเวลาการบิน',
    informationAboutDronesAndUsersInEachArea: 'ข้อมูลโดรนและผู้ใช้งานในแต่ละพื้นที่',
}

export default dashboard
