import { takeEvery, put, call } from "redux-saga/effects";

// Chat Redux States
import { GET_PROVINCE } from "./actionTypes";
import { getprovinceSuccess, getprovinceFail } from "../actions";

//Include Both Helper File with needed methods
import { getprovince } from "../../services/skc/dashboard";

function* onGetProvince() {
  try {
    const response = yield call(getprovince);
    // console.log("response-province :>> ", response);

    yield put(getprovinceSuccess(response));
  } catch (error) {
    yield put(getprovinceFail(error));
  }
}

function* provinceSaga() {
  yield takeEvery(GET_PROVINCE, onGetProvince);
}

export default provinceSaga;
