import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import React from 'react'
import {Card, CardBody} from 'reactstrap'
import './style.scss'

const FlightDurationSkeleton = () => {
    return (
        <Card
            style={{
                borderRadius: 8,
                height: 600,
                paddingTop: 10,
            }}>
            <CardBody>
                <div className="d-flex flex-wrap" style={{marginBottom: 20}}>
                    <Skeleton width={130} height={32} style={{marginLeft: 20}} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginLeft: 80,
                    }}>
                    <div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Skeleton width={20} height={20} style={{marginRight: 16}} />
                            <Skeleton width={230} height={20} />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',

                                marginTop: 10,
                            }}>
                            <Skeleton width={50} height={26} style={{marginRight: 8}} />
                            <Skeleton width={50} height={20} />
                        </div>
                    </div>
                    <div
                        style={{
                            width: '50%',
                            borderLeft: '2px rgba(242, 242, 242, 1) solid',
                            paddingLeft: 20,
                            marginRight: 20,
                        }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Skeleton width={20} height={20} style={{marginRight: 8}} />
                            <Skeleton width={120} height={20} />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 10,
                            }}>
                            <Skeleton width={50} height={26} style={{marginRight: 8}} />
                            <Skeleton width={50} height={20} />
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        width: '100%',
                        height: 380,
                        paddingLeft: 10,
                        marginTop: 30,
                    }}>
                    <Skeleton width={732} height={350} style={{marginTop: 16}} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        paddingLeft: 74,
                        alignItems: 'center',
                    }}>
                    <Skeleton width={14} height={14} style={{marginRight: 7, borderRadius: 16}} />
                    <Skeleton width={160} height={20} style={{marginRight: 7}} />
                    <Skeleton width={14} height={14} style={{marginRight: 4, borderRadius: 16}} />
                    <Skeleton width={165} height={20} style={{marginRight: 10}} />
                    <Skeleton width={14} height={14} style={{marginRight: 4, borderRadius: 16}} />
                    <Skeleton width={256} height={20} />
                </div>
            </CardBody>
        </Card>
    )
}

export default FlightDurationSkeleton
