import React from 'react'
import {Redirect} from 'react-router-dom'

// Authentication related pages
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'

// Dashboard

// Drone iNalyze
import Dashboard from '../pages/Dashboard/Dashboard'
import DroneInformation from '../pages/Drone-iNalyze/DroneInformation'
import Inalyze from '../pages/Drone-iNalyze/Inalyze'
import RealTimeMonitoring from 'pages/Drone-iNalyze/RealTimeMonitoring'
import RealTimeMonitoringDetail from 'pages/Drone-iNalyze/RealTimeMonitoringDetail'

import FlightHistoryDetail from '../pages/Drone-iNalyze/FlightHistoryDetail'
import FlightHistoryMapPlay from 'pages/Drone-iNalyze/FlightHistoryMapPlay'
import DroneInformationOverallAndUsage from 'pages/Drone-iNalyze/DroneInformationOverallAndUsage'

// User iNalyze
import UserInalyze from '../pages/User-iNalyze/UserInalyze'
import UserInformation from '../pages/User-iNalyze/UserInformation'

const authProtectedRoutes = [
    {path: '/dashboard', component: Dashboard},

    {path: '/drone-information', component: DroneInformation},
    {path: '/drone-information/:tab', component: DroneInformationOverallAndUsage},

    {
        path: '/drone-information/flight-history/:hardwareID/:id/:droneModel/detail/:flightId',
        component: FlightHistoryDetail,
    },
    {path: '/drone-information/flight-history/:hardwareID/:id/play', component: FlightHistoryMapPlay},
    {path: '/inalyze', component: Inalyze},
    {path: '/real-time-monitoring', component: RealTimeMonitoring},
    {path: '/real-time-monitoring/:id/detail', component: RealTimeMonitoringDetail},

    {path: '/user-inalyze', component: UserInalyze},
    {path: '/user-information/:tab', component: UserInformation},
    {path: '/', exact: true, component: () => <Redirect to="/dashboard" />},
]

const publicRoutes = [
    {path: '/logout', component: Logout},
    {path: '/login', component: Login},
]

export {authProtectedRoutes, publicRoutes}
