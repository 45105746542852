import React from 'react'
import {Row, Col, Card, CardTitle, CardBody} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './realtime-skeleton.scss'

const CardDroneWorkingSkeleton = () => {
    return (
        <div className="skeleton-card-drone-working">
            <Card>
                <CardTitle>
                    <Row>
                        <Col lg={11} xl={11}>
                            <Skeleton width={150} height={30} />
                        </Col>
                        <Col lg={1} xl={1}>
                            <Skeleton width={50} height={41} />
                        </Col>
                    </Row>
                </CardTitle>
                <CardBody>
                    <Row>
                        <Col xl={12} className="box-count-data">
                            <Skeleton width={42} height={21} />
                            <Skeleton width={18} height={17} style={{marginLeft: 8}} />
                            <Skeleton width={14} height={21} className="count" />
                        </Col>
                        <Col xl={12}>
                            <Skeleton width={'100%'} height={684} />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}

export default CardDroneWorkingSkeleton
