import React, {useEffect, useRef, useState} from 'react'
import {Row, Col, Button, Card, CardBody, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap'
import {DatePicker, Space, Select} from 'antd'
import {withTranslation} from 'react-i18next'
const {RangePicker} = DatePicker
const {Option} = Select
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import './index.scss'
import {buttonFilterTimeRange, optionsCountry, tabDataInalyzeMap} from 'constants/lookup'
import {useRecoilState} from 'recoil'
import {filterDroneInalyzeState} from 'store/atoms/inalyze'
import {getFilterDistrict, getFilterDrone, getFilterRegion, getFilterProvince} from 'services/skc/lookup'
import {getMapInalyze} from 'services/skc/inalyze'
import ChartsMap from 'components/Map/ChartsMap'
import TableMap from 'components/Map/TableMap'
import InalyzeNavbarSkeleton from 'components/DroneiNalyze/Skeleton-iNalyze/iNalyze/InalyzeNavbarSkeleton'
import CardMapInalyzeDataSkeleton from 'components/DroneiNalyze/Skeleton-iNalyze/iNalyze/CardMapInalyzeDataSkeleton'

const weekFormat = 'DD MMMM YYYY'
const monthFormat = 'MMMM YYYY'

const InalyzeData = props => {
    const {t, i18n} = props

    const [filterInalyze, setFilterInalyze] = useRecoilState(filterDroneInalyzeState)

    const [activeTabSearch, setActiveTabSearch] = useState('year')
    const [activeTabData, setActiveTabData] = useState('drone')
    const [selectCountry, setSelectCountry] = useState('all')
    const [selectDrones, setSelectDrones] = useState([])
    const [selectRegion, setSelectRegion] = useState([])
    const [selectProvince, setSelectProvince] = useState([])
    const [selectDistrict, setSelectDistrict] = useState([])

    const [sortDataButton, setSortDataButton] = useState('UNIT')

    const [disabledRegion, setDisabledRegion] = useState(true)
    const [disabledProvince, setDisabledProvince] = useState(true)
    const [disabledDistrict, setDisabledDistrict] = useState(true)
    const [onFetchDistrictLut, setOnFetchDistrictLut] = useState(false)

    const [lutProvince, setLutProvince] = useState([])
    const [lutDistrict, setLutDistrict] = useState([])
    const [lutDrones, setLutDrones] = useState([])
    const [lutRegion, setLutRegion] = useState([])

    const [skeleton, setSkeleton] = useState(true)
    const [language, setLanguage] = useState(i18n.language)

    const [date, setDate] = useState(dayjs)
    const [dateDynamic, setDateDynamic] = useState([])

    const [dataMapInalyze, setDataMapInalyze] = useState({})

    const mapRefDrone = useRef()
    const mapRefFlyHour = useRef()
    const mapRefAmountWorking = useRef()
    const mapRefWorkingArea = useRef()
    const mapRefSpraying = useRef()

    const refDivRowsDrone = useRef()
    const refDivRowsFlyHour = useRef()
    const refDivRowsAmountWorking = useRef()
    const refDivRowsWorkingArea = useRef()
    const refDivRowsSpraying = useRef()

    let rowRefsDrone = useRef()
    let rowRefsFlyHour = useRef()
    let rowRefsAmountWorking = useRef()
    let rowRefsWorkingArea = useRef()
    let rowRefsSpraying = useRef()

    useEffect(() => {
        fetchLutRegion()
        fetchLutDrone()

        fetchMapInalyze(filterInalyze, activeTabData)
    }, [])

    useEffect(() => {
        return () => {
            setFilterInalyze({
                type: 'year',
                week: '',
                month: '',
                start_date: dayjs().startOf('year').format('YYYY-MM-DD'),
                end_date: dayjs().endOf('year').format('YYYY-MM-DD'),
                year: dayjs().year(),
                province: [],
                district: [],
                region: [],
                drone: [],
                country: 'all',
            })
        }
    }, [])

    useEffect(() => {
        onSetDistrictValueState()
    }, [lutDistrict])

    useEffect(() => {
        if (selectRegion.length > 0) {
            onSetProvinceSetState()
        }
    }, [lutProvince])

    useEffect(() => {
        if (i18n.language !== language) {
            fetchMapInalyze(filterInalyze, activeTabData)
            setLanguage(i18n.language)
        }
    }, [i18n.language])

    const fetchLutRegion = async () => {
        try {
            const response = await getFilterRegion()

            if (response.status_code === 200 && response.results.length > 0) {
                setLutRegion(response.results)
            }
        } catch (error) {
            setLutRegion([])
        }
    }

    const fetchLutDrone = async () => {
        try {
            const response = await getFilterDrone()

            if (response.status_code === 200 && response.results.length > 0) {
                setLutDrones(response.results)
            }
        } catch (error) {
            setLutDrones([])
        }
    }

    const fetchProvince = async params => {
        try {
            const response = await getFilterProvince({
                region: params,
            })

            if (response.status_code === 200 && response.results.data.length > 0) {
                setLutProvince(response.results.data)
            }
        } catch (error) {
            setLutProvince([])
        }
    }

    const fetchDistrict = async params => {
        try {
            const response = await getFilterDistrict(params)

            if (response.status_code === 200 && response.results.data.length > 0) {
                setLutDistrict(response.results.data)
            }
        } catch (error) {
            setLutDistrict([])
        }
    }

    const fetchMapInalyze = async (params, tab) => {
        try {
            setSkeleton(true)
            const response = await getMapInalyze(params, tab)

            if (response?.status_code === 200 && response?.results) {
                setDataMapInalyze(response.results)
            }
        } catch (error) {
            setDataMapInalyze({})
        } finally {
            setSkeleton(false)
        }
    }

    const disabledDate = current => {
        return current && current > dayjs().endOf('day')
    }

    const toggleTabSearch = tab => {
        if (activeTabSearch !== tab) {
            setActiveTabSearch(tab)
        }
    }

    const handleChangeTime = date => {
        if (activeTabSearch === 'period') {
            setDateDynamic(date)
        } else {
            setDate(date)
        }
    }

    const handleChangeRegion = (value, options) => {
        setSelectRegion(value)

        if (options.length > 0) {
            options.forEach((item, index, array) => {
                if (item.value === 'other' && array.length === 1) {
                    setDisabledProvince(true)
                    setDisabledDistrict(true)
                    setSelectProvince([])
                    setSelectDistrict([])
                } else {
                    setDisabledProvince(false)
                    setDisabledDistrict(false)
                    fetchProvince(value)
                }
            })
        }
    }

    const onDeSelectRegion = value => {
        setOnFetchDistrictLut(true)
        if (value === 'other') {
            setDisabledProvince(false)
            setDisabledDistrict(false)
            setSelectDistrict([])
        }
        if (selectRegion.length <= 1) {
            setDisabledProvince(true)
            setDisabledDistrict(true)
            setSelectProvince([])
            setSelectDistrict([])
            fetchProvince([])
        }
    }

    const onChangeCountry = value => {
        setSelectCountry(value)

        if (value === 'th') {
            setDisabledRegion(false)
        } else {
            setSelectRegion([])
            setSelectDistrict([])
            setSelectProvince([])
            setDisabledDistrict(true)
            setDisabledProvince(true)
            setDisabledRegion(true)
        }
    }

    const handleChangeProvince = (value, options) => {
        setSelectProvince(value)
        const paramsData = {province: [...value]}

        options.forEach(item => {
            if (item.value === 'other' && value.length === 1) {
                setDisabledDistrict(true)
                setSelectDistrict([])
            } else {
                setDisabledDistrict(false)

                fetchDistrict(paramsData)
            }
        })
    }

    const onDeSelectProvince = value => {
        if (value === 'other') {
            setDisabledDistrict(false)
        }
        if (selectProvince.length <= 1) {
            setDisabledDistrict(true)
            setSelectProvince([])
            setSelectDistrict([])
            fetchDistrict({province: []})
        }
    }

    const onSetDistrictValueState = () => {
        const resultDistrict = []
        const district = []

        lutDistrict?.forEach(itm => {
            district.push({value: itm.id, name_th: itm.name_th, name_en: itm.name_en})
        })

        selectDistrict.forEach(itemDistrict => {
            district.forEach(itm => {
                if (itemDistrict === itm.value) {
                    resultDistrict.push(itemDistrict)
                }
            })
        })

        setSelectDistrict(resultDistrict)
    }

    const onSetProvinceSetState = () => {
        const resultProvince = []
        const province = []

        lutProvince?.forEach(itm => {
            province.push({value: itm.id, prov_name_th: itm.prov_name_th, prov_name_en: itm.prov_name_en})
        })

        selectProvince.forEach(itemProvince => {
            province.forEach(itm => {
                if (itemProvince === itm.value) {
                    resultProvince.push(itemProvince)
                }
            })
        })

        if (onFetchDistrictLut) {
            fetchDistrict({province: [...resultProvince]})
        }

        setSelectProvince(resultProvince)
        setOnFetchDistrictLut(false)
    }

    const onChangeDistrict = value => {
        setSelectDistrict(value)
    }

    const onSelectDrone = data => {
        setSelectDrones(data)
    }

    const onChangeTabData = tab => {
        setActiveTabData(tab)
        setSortDataButton('UNIT')
        fetchMapInalyze(filterInalyze, tab)
    }

    const handleClickMapDrone = selectMap => {
        const dataRow = Object.keys(rowRefsDrone).map(key => rowRefsDrone[key])
        const dataDivRow = Object.keys(refDivRowsDrone).map(key => refDivRowsDrone[key])

        const findDataRow = dataRow?.find(item => item?.current?.id === selectMap)
        const findDataDiv = dataDivRow?.find(item => item?.current?.id === selectMap)

        dataDivRow?.forEach(ref => {
            ref?.current?.classList?.remove('table-active')
        })

        dataRow?.forEach(ref => {
            ref?.current?.classList?.remove('text-click')
        })

        findDataRow?.current?.scrollIntoView({behavior: 'auto', block: 'nearest'})
        findDataRow?.current?.classList?.add('text-click')
        findDataDiv?.current?.classList?.add('table-active')
    }

    const handleClickMapFlyHour = selectMap => {
        const dataRow = Object.keys(rowRefsFlyHour).map(key => rowRefsFlyHour[key])
        const dataDivRow = Object.keys(refDivRowsFlyHour).map(key => refDivRowsFlyHour[key])

        const findDataRow = dataRow?.find(item => item?.current?.id === selectMap)
        const findDataDiv = dataDivRow?.find(item => item?.current?.id === selectMap)

        dataDivRow?.forEach(ref => {
            ref?.current?.classList?.remove('table-active')
        })

        dataRow?.forEach(ref => {
            ref?.current?.classList?.remove('text-click')
        })

        findDataRow?.current?.scrollIntoView({behavior: 'auto', block: 'nearest'})
        findDataRow?.current?.classList?.add('text-click')
        findDataDiv?.current?.classList?.add('table-active')
    }

    const handleClickMapAmountWorking = selectMap => {
        const dataRow = Object.keys(rowRefsAmountWorking).map(key => rowRefsAmountWorking[key])
        const dataDivRow = Object.keys(refDivRowsAmountWorking).map(key => refDivRowsAmountWorking[key])

        const findDataRow = dataRow?.find(item => item?.current?.id === selectMap)
        const findDataDiv = dataDivRow?.find(item => item?.current?.id === selectMap)

        dataDivRow?.forEach(ref => {
            ref?.current?.classList?.remove('table-active')
        })

        dataRow?.forEach(ref => {
            ref?.current?.classList?.remove('text-click')
        })

        findDataRow?.current?.scrollIntoView({behavior: 'auto', block: 'nearest'})
        findDataRow?.current?.classList?.add('text-click')
        findDataDiv?.current?.classList?.add('table-active')
    }

    const handleClickMapWorkingArea = selectMap => {
        const dataRow = Object.keys(rowRefsWorkingArea).map(key => rowRefsWorkingArea[key])
        const dataDivRow = Object.keys(refDivRowsWorkingArea).map(key => refDivRowsWorkingArea[key])

        const findDataRow = dataRow?.find(item => item?.current?.id === selectMap)
        const findDataDiv = dataDivRow?.find(item => item?.current?.id === selectMap)

        dataDivRow?.forEach(ref => {
            ref?.current?.classList?.remove('table-active')
        })

        dataRow?.forEach(ref => {
            ref?.current?.classList?.remove('text-click')
        })

        findDataRow?.current?.scrollIntoView({behavior: 'auto', block: 'nearest'})
        findDataRow?.current?.classList?.add('text-click')
        findDataDiv?.current?.classList?.add('table-active')
    }

    const handleClickMapSpraying = selectMap => {
        const dataRow = Object.keys(rowRefsSpraying).map(key => rowRefsSpraying[key])
        const dataDivRow = Object.keys(refDivRowsSpraying).map(key => refDivRowsSpraying[key])

        const findDataRow = dataRow?.find(item => item?.current?.id === selectMap)
        const findDataDiv = dataDivRow?.find(item => item?.current?.id === selectMap)

        dataDivRow?.forEach(ref => {
            ref?.current?.classList?.remove('table-active')
        })

        dataRow?.forEach(ref => {
            ref?.current?.classList?.remove('text-click')
        })

        findDataRow?.current?.scrollIntoView({behavior: 'auto', block: 'nearest'})
        findDataRow?.current?.classList?.add('text-click')
        findDataDiv?.current?.classList?.add('table-active')
    }

    const handleClickViewInformation = () => {
        let params = {...filterInalyze}
        setSortDataButton('UNIT')

        if (activeTabSearch === 'year') {
            params = {
                ...params,
                type: activeTabSearch,
                week: '',
                month: '',
                start_date: dayjs(date).startOf('year').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('year').format('YYYY-MM-DD'),
                year: dayjs(date).year(),
                province: selectProvince === 'all' ? [] : selectProvince,
                district: selectDistrict === 'all' ? [] : selectDistrict,
                region: selectRegion,
                drone: selectDrones,
                country: selectCountry,
            }
        }

        if (activeTabSearch === 'month') {
            params = {
                ...params,
                type: activeTabSearch,
                week: '',
                month: dayjs(date).month() + 1,
                start_date: dayjs(date).startOf('month').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('month').format('YYYY-MM-DD'),
                year: dayjs(date).year(),
                province: selectProvince === 'all' ? [] : selectProvince,
                drone: selectDrones,
                country: selectCountry,
                district: selectDistrict === 'all' ? [] : selectDistrict,

                region: selectRegion,
            }
        }

        if (activeTabSearch === 'week') {
            params = {
                ...params,
                type: activeTabSearch,
                week: dayjs(date).week(),
                month: dayjs(date).month() + 1,
                start_date: dayjs(date).startOf('week').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('week').format('YYYY-MM-DD'),
                year: dayjs(date).year(),
                province: selectProvince === 'all' ? [] : selectProvince,
                drone: selectDrones,
                country: selectCountry,
                district: selectDistrict === 'all' ? [] : selectDistrict,

                region: selectRegion,
            }
        }

        if (activeTabSearch === 'period') {
            params = {
                ...params,
                type: activeTabSearch,
                week: '',
                month: '',
                start_date: dayjs(dateDynamic[0]).format('YYYY-MM-DD'),
                end_date: dayjs(dateDynamic[1]).format('YYYY-MM-DD'),
                year: '',
                province: selectProvince === 'all' ? [] : selectProvince,
                drone: selectDrones,
                country: selectCountry,
                district: selectDistrict === 'all' ? [] : selectDistrict,

                region: selectRegion,
            }
        }

        setActiveTabData('drone')
        setFilterInalyze(params)
        fetchMapInalyze(params, 'drone')
    }

    const customWeekStartEndFormat = value =>
        `${dayjs(value).startOf('week').format(weekFormat)} - ${dayjs(value).endOf('week').format(weekFormat)}`

    const sortFilterBtn = data => {
        setSortDataButton(data)
        fetchMapInalyze({...filterInalyze, to_date: data}, activeTabData)
    }

    return (
        <>
            {skeleton ? (
                <Row>
                    <InalyzeNavbarSkeleton activeTab={activeTabSearch} />
                    <CardMapInalyzeDataSkeleton />
                </Row>
            ) : (
                <Row>
                    <Col xl={12}>
                        <Card className="card-search-inalyze">
                            <CardBody>
                                <Row>
                                    <Col xl={6}>
                                        <div className=" box-search-time">
                                            {buttonFilterTimeRange?.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    {item.value !== 'halfYear' && item.value !== 'quarter' && (
                                                        <Button
                                                            color="secondary"
                                                            outline
                                                            onClick={() => toggleTabSearch(item.value)}
                                                            style={{
                                                                width: item.value !== 'period' ? 70 : 104,
                                                                fontFamily: 'Kanit',
                                                                fontWeight: 400,
                                                                fontSize: 14,
                                                                borderColor: '#E2E2E2',
                                                                borderRadius: 8,
                                                                height: '40px',
                                                                marginRight: '16px',
                                                                color:
                                                                    activeTabSearch === item.value ? '#fff' : '#495057',
                                                                backgroundColor:
                                                                    activeTabSearch === item.value && '#79829C',
                                                            }}>
                                                            {i18n.language === 'th' ? item.title_th : item.title_en}
                                                        </Button>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                            <div style={{width: '50%'}}>
                                                <Space direction="vertical" size={13} style={{width: '100%'}}>
                                                    <TabContent activeTab={activeTabSearch}>
                                                        <TabPane tabId="week">
                                                            <Space direction="vertical" size={13}>
                                                                <DatePicker
                                                                    style={{
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: 400,
                                                                        fontSize: 14,
                                                                        borderColor: '#CED4DA',
                                                                        borderRadius: 8,
                                                                        color: '#495057',
                                                                        height: 38,
                                                                    }}
                                                                    value={date}
                                                                    format={customWeekStartEndFormat}
                                                                    picker="week"
                                                                    bordered={true}
                                                                    onChange={handleChangeTime}
                                                                    disabledDate={disabledDate}
                                                                />
                                                            </Space>
                                                        </TabPane>
                                                        <TabPane
                                                            tabId="month"
                                                            id="v-pills-payment"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-payment-tab">
                                                            <Space direction="vertical" size={13}>
                                                                <DatePicker
                                                                    style={{
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: 400,
                                                                        fontSize: 14,
                                                                        borderColor: '#CED4DA',
                                                                        borderRadius: 8,
                                                                        color: '#495057',
                                                                        height: 38,
                                                                        width: '240px',
                                                                    }}
                                                                    picker="month"
                                                                    bordered={true}
                                                                    value={date}
                                                                    format={monthFormat}
                                                                    onChange={handleChangeTime}
                                                                    disabledDate={disabledDate}
                                                                />
                                                            </Space>
                                                        </TabPane>
                                                        <TabPane tabId="year" id="v-pills-confir" role="tabpanel">
                                                            <Space direction="vertical" size={13}>
                                                                <DatePicker
                                                                    style={{
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: 400,
                                                                        fontSize: 14,
                                                                        borderColor: '#CED4DA',
                                                                        borderRadius: 8,
                                                                        color: '#495057',
                                                                        height: 38,
                                                                    }}
                                                                    picker="year"
                                                                    value={date}
                                                                    bordered={true}
                                                                    onChange={handleChangeTime}
                                                                    disabledDate={disabledDate}
                                                                />
                                                            </Space>
                                                        </TabPane>
                                                        <TabPane tabId="period" id="v-pills-confir" role="tabpanel">
                                                            <Space direction="vertical" size={13}>
                                                                <RangePicker
                                                                    style={{
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: 400,
                                                                        fontSize: 14,
                                                                        borderColor: '#CED4DA',
                                                                        borderRadius: 8,
                                                                        color: '#495057',
                                                                        height: 38,
                                                                    }}
                                                                    value={dateDynamic}
                                                                    format="DD MMM YYYY"
                                                                    onChange={handleChangeTime}
                                                                    disabledDate={disabledDate}
                                                                />
                                                            </Space>
                                                        </TabPane>
                                                    </TabContent>
                                                </Space>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={12}>
                                        <Row className="box-search">
                                            <Col xl={2} className="col-select">
                                                <span className="text-label">{t('common.country')} :</span>

                                                <Select
                                                    style={{
                                                        width: i18n.language === 'th' ? '80%' : '70%',
                                                        borderColor: '#CED4DA',
                                                        borderRadius: 8,
                                                        color: '#495057',
                                                    }}
                                                    placeholder={t('common.pleaseSelect')}
                                                    value={selectCountry}
                                                    onChange={onChangeCountry}
                                                    className="ms-3">
                                                    {optionsCountry?.map((option, index) => (
                                                        <Option key={index} value={option.value} label={option.label}>
                                                            {i18n.language === 'th' ? option.label_th : option.label_en}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col xl={5} className="col-select">
                                                <span style={{width: '80px'}}>{t(`common.region`)} :</span>
                                                <Select
                                                    mode="multiple"
                                                    className="container-select-drone"
                                                    disabled={disabledRegion}
                                                    placeholder={t(`common.all`)}
                                                    value={selectRegion}
                                                    optionFilterProp="children"
                                                    onChange={handleChangeRegion}
                                                    onDeselect={onDeSelectRegion}>
                                                    <Option value={'other'} label={t('common.areaNotSpecified')}>
                                                        {t('common.areaNotSpecified')}
                                                    </Option>
                                                    {lutRegion?.map(
                                                        (option, index) =>
                                                            option.reg_code != 1 && (
                                                                <Option
                                                                    key={index}
                                                                    value={option.reg_code}
                                                                    label={
                                                                        i18n.language === 'en'
                                                                            ? option.name_en
                                                                            : option.name_th
                                                                    }>
                                                                    {i18n.language === 'en'
                                                                        ? option.name_en
                                                                        : option.name_th}
                                                                </Option>
                                                            ),
                                                    )}
                                                </Select>
                                            </Col>
                                            <Col xl={5} className="col-select">
                                                <span style={{width: '80px'}}>{t('common.province')} :</span>
                                                <Select
                                                    mode="multiple"
                                                    placeholder={t('common.all')}
                                                    value={selectProvince}
                                                    onChange={handleChangeProvince}
                                                    disabled={disabledProvince}
                                                    className="container-select-drone"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    onDeselect={onDeSelectProvince}>
                                                    <Option value={'other'} label={t('common.provinceNotSpecified')}>
                                                        {t('common.provinceNotSpecified')}
                                                    </Option>

                                                    {lutProvince?.map((item, index) => (
                                                        <Option
                                                            key={index}
                                                            value={item.id}
                                                            label={
                                                                i18n.language === 'en'
                                                                    ? item.prov_name_en
                                                                    : item?.prov_name_th
                                                            }>
                                                            {i18n.language === 'en'
                                                                ? item.prov_name_en
                                                                : item?.prov_name_th}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col xl={5} className="col-select">
                                                <span style={{width: '90px'}}>{t('common.district')}:</span>
                                                <Select
                                                    mode="multiple"
                                                    className="container-select-drone"
                                                    placeholder={t('common.all')}
                                                    value={selectDistrict}
                                                    onChange={onChangeDistrict}
                                                    disabled={disabledDistrict}
                                                    showSearch
                                                    optionFilterProp="children">
                                                    {lutDistrict?.map((item, index) => (
                                                        <Option
                                                            key={index}
                                                            value={item.id}
                                                            label={
                                                                i18n.language === 'en' ? item.name_en : item.name_th
                                                            }>
                                                            {i18n.language === 'en' ? item.name_en : item.name_th}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Col>

                                            <Col xl={5} className="col-select">
                                                <span className="text-label">{t('common.droneModel')} :</span>
                                                <Space className="container-select-drone " direction="vertical">
                                                    <Select
                                                        className="w-100"
                                                        mode="multiple"
                                                        placeholder={t('common.all')}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        value={selectDrones}
                                                        onChange={onSelectDrone}>
                                                        {lutDrones?.map((item, index) => (
                                                            <Option key={index} value={item.id} label={item.name}>
                                                                {item.name}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Space>
                                            </Col>
                                            <Col xl={2} className="col-select">
                                                <Button
                                                    className="btn-submit-detail"
                                                    onClick={handleClickViewInformation}>
                                                    {t('common.searchInformation')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xl={12}>
                        <Card className="card-result">
                            <CardTitle className="card-title">
                                <Nav tabs>
                                    {tabDataInalyzeMap?.map(itm => (
                                        <NavItem
                                            className="container-tab"
                                            key={itm.value}
                                            onClick={() => onChangeTabData(itm.value)}>
                                            <NavLink className={`${activeTabData === itm.value && 'active-tab'}`}>
                                                <span>{i18n.language === 'en' ? itm.title_en : itm.title_th}</span>
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                            </CardTitle>
                            <CardBody>
                                <TabContent activeTab={activeTabData} className="p-3 text-muted">
                                    <TabPane tabId="drone">
                                        <Row style={{margin: 0, padding: 0}}>
                                            <Col xl={4}>
                                                <p className="title-head-map">
                                                    {t('inalyze.droneInformationAndUseInEachArea')}
                                                </p>
                                                <ChartsMap
                                                    country={filterInalyze.country}
                                                    data={dataMapInalyze?.mapData}
                                                    mapRef={mapRefDrone}
                                                    selectMap={handleClickMapDrone}
                                                />
                                            </Col>
                                            <Col xl={8}>
                                                <TableMap
                                                    title={t('inalyze.totalDrones')}
                                                    data={dataMapInalyze?.provinceData}
                                                    percentChart={dataMapInalyze?.percentChart}
                                                    max={dataMapInalyze?.max}
                                                    tabData={'drone'}
                                                    unit={t('common.droneUnit')}
                                                    refDivRows={refDivRowsDrone}
                                                    rowRefs={rowRefsDrone}
                                                    filter={filterInalyze}
                                                    sortFilterBtn={sortFilterBtn}
                                                    sortDataButton={sortDataButton}
                                                    percentHeatMap={{
                                                        maxPercent: dataMapInalyze?.maxPercent,
                                                        minPercent: dataMapInalyze?.minPercent,
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="fly_hour">
                                        <Row style={{margin: 0, padding: 0}}>
                                            <Col xl={4}>
                                                <p className="title-head-map">
                                                    {t('inalyze.droneInformationAndUseInEachArea')}
                                                </p>
                                                <ChartsMap
                                                    country={filterInalyze.country}
                                                    data={dataMapInalyze?.mapData}
                                                    mapRef={mapRefFlyHour}
                                                    selectMap={handleClickMapFlyHour}
                                                />
                                            </Col>
                                            <Col xl={8}>
                                                <TableMap
                                                    title={t('inalyze.totalFlightHours')}
                                                    data={dataMapInalyze?.provinceData}
                                                    percentChart={dataMapInalyze?.percentChart}
                                                    max={dataMapInalyze?.max}
                                                    tabData={'fly_hour'}
                                                    unit={t('common.hourUnit')}
                                                    refDivRows={refDivRowsFlyHour}
                                                    rowRefs={rowRefsFlyHour}
                                                    filter={filterInalyze}
                                                    sortFilterBtn={sortFilterBtn}
                                                    sortDataButton={sortDataButton}
                                                    percentHeatMap={{
                                                        maxPercent: dataMapInalyze?.maxPercent,
                                                        minPercent: dataMapInalyze?.minPercent,
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="amount_working">
                                        <Row style={{margin: 0, padding: 0}}>
                                            <Col xl={4}>
                                                <p className="title-head-map">
                                                    {t('inalyze.droneInformationAndUseInEachArea')}
                                                </p>
                                                <ChartsMap
                                                    country={filterInalyze.country}
                                                    data={dataMapInalyze?.mapData}
                                                    mapRef={mapRefAmountWorking}
                                                    selectMap={handleClickMapAmountWorking}
                                                />
                                            </Col>
                                            <Col xl={8}>
                                                <TableMap
                                                    title={t('inalyze.totalWorkingDays')}
                                                    data={dataMapInalyze?.provinceData}
                                                    percentChart={dataMapInalyze?.percentChart}
                                                    max={dataMapInalyze?.max}
                                                    tabData={'amount_working'}
                                                    unit={t('common.day')}
                                                    refDivRows={refDivRowsAmountWorking}
                                                    rowRefs={rowRefsAmountWorking}
                                                    filter={filterInalyze}
                                                    sortFilterBtn={sortFilterBtn}
                                                    sortDataButton={sortDataButton}
                                                    percentHeatMap={{
                                                        maxPercent: dataMapInalyze?.maxPercent,
                                                        minPercent: dataMapInalyze?.minPercent,
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="working_area">
                                        <Row style={{margin: 0, padding: 0}}>
                                            <Col xl={4}>
                                                <p className="title-head-map">
                                                    {t('inalyze.droneInformationAndUseInEachArea')}
                                                </p>
                                                <ChartsMap
                                                    country={filterInalyze.country}
                                                    data={dataMapInalyze?.mapData}
                                                    mapRef={mapRefWorkingArea}
                                                    selectMap={handleClickMapWorkingArea}
                                                />
                                            </Col>
                                            <Col xl={8}>
                                                <TableMap
                                                    title={t('inalyze.allWorkingArea')}
                                                    data={dataMapInalyze?.provinceData}
                                                    percentChart={dataMapInalyze?.percentChart}
                                                    max={dataMapInalyze?.max}
                                                    tabData={'working_area'}
                                                    unit={t('common.raiUnit')}
                                                    refDivRows={refDivRowsWorkingArea}
                                                    rowRefs={rowRefsWorkingArea}
                                                    filter={filterInalyze}
                                                    sortFilterBtn={sortFilterBtn}
                                                    sortDataButton={sortDataButton}
                                                    percentHeatMap={{
                                                        maxPercent: dataMapInalyze?.maxPercent,
                                                        minPercent: dataMapInalyze?.minPercent,
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="spraying">
                                        <Row style={{margin: 0, padding: 0}}>
                                            <Col xl={4}>
                                                <p className="title-head-map">
                                                    {t('inalyze.droneInformationAndUseInEachArea')}
                                                </p>
                                                <ChartsMap
                                                    country={filterInalyze.country}
                                                    data={dataMapInalyze?.mapData}
                                                    mapRef={mapRefSpraying}
                                                    selectMap={handleClickMapSpraying}
                                                />
                                            </Col>
                                            <Col xl={8}>
                                                <TableMap
                                                    title={t('inalyze.averageSprayingArea')}
                                                    data={dataMapInalyze?.provinceData}
                                                    percentChart={dataMapInalyze?.percentChart}
                                                    max={dataMapInalyze?.max}
                                                    tabData={'spraying'}
                                                    unit={`${t('common.raiUnit')}/${t('common.droneUnit')}`}
                                                    refDivRows={refDivRowsSpraying}
                                                    rowRefs={rowRefsSpraying}
                                                    filter={filterInalyze}
                                                    sortFilterBtn={sortFilterBtn}
                                                    sortDataButton={sortDataButton}
                                                    percentHeatMap={{
                                                        maxPercent: dataMapInalyze?.maxPercent,
                                                        minPercent: dataMapInalyze?.minPercent,
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    )
}

export default withTranslation()(InalyzeData)
