import React, {Component, useEffect} from 'react'
import PropTypes from 'prop-types'

import {Alert, Card, CardBody, Col, Container, Row, Label} from 'reactstrap'
// Redux
import {connect} from 'react-redux'
import {Link, withRouter, Redirect} from 'react-router-dom'

import {Formik, Field, Form, ErrorMessage} from 'formik'
import * as Yup from 'yup'

// actions
import {authorizedLogin, authorizedFail} from '../../store/actions'

// import images
import profile from '../../assets/images/profile-img.png'
import logo from '../../assets/images/logo.svg'
import lightlogo from '../../assets/images/logo-light.png'
import iconappop15 from '../../assets/images/icon-app-op15.png'
import Swal from 'sweetalert2'

//SweetAlert
// import SweetAlert from "react-bootstrap-sweetalert"
import {MetaTags} from 'react-meta-tags'

class LoginPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visiblePassword: false,
            closeAlert: false,
        }
    }

    async componentDidMount() {
        // window.location.href = '/dashboard';
    }

    componentDidUpdate() {
        const {authorized, onAuthorizedFail} = this.props
        console.log('authorized :>> ', authorized)

        if (!authorized.status) {
            Swal.fire({
                icon: 'warning',
                title: 'ผิดพลาด',
                html: `username หรือ password ไม่ถูกต้อง`,
                timer: 3000,
                allowOutsideClick: false,
                timerProgressBar: true,
                confirmButtonText: 'Close',
                confirmButtonColor: '#F05A28',
            }).then(result => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                }
            })

            onAuthorizedFail({status: true})
        }
    }

    visiblePassword = () => {
        this.setState({
            visiblePassword: !this.state.visiblePassword,
        })
    }

    render() {
        const {onAuthorized, authorized} = this.props
        if (localStorage.getItem('authUser')) {
            return <Redirect to={{pathname: '/dashboard'}} />
        } else {
            return (
                <React.Fragment>
                    <MetaTags>
                        <title>Login | K-iDrone</title>
                    </MetaTags>
                    <div className="home-btn d-none d-sm-block">
                        <Link to="/" className="text-dark">
                            <i className="bx bx-home h2" />
                        </Link>
                    </div>

                    <div className="account-pages my-5 pt-sm-5">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="overflow-hidden">
                                        <div className="bg-primary bgGreen login-header-bg">
                                            <img src={iconappop15} alt="" className="img-op" />
                                            <div className="p-2 px-5">
                                                <Row>
                                                    <Col className="col-6 align-self-end">
                                                        <img src={lightlogo} alt="" className="img-fluid img-logo" />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <CardBody className="pt-4">
                                            <div className="p-2">
                                                {this.props.error && this.props.error ? (
                                                    <Alert color="danger">{this.props.error}</Alert>
                                                ) : null}
                                                <Formik
                                                    enableReinitialize={true}
                                                    initialValues={{
                                                        username: '',
                                                        password: '',
                                                    }}
                                                    validationSchema={Yup.object().shape({
                                                        username: Yup.string().required('Please Enter Your Username'),
                                                        password: Yup.string().required('Please Enter Valid Password'),
                                                    })}
                                                    onSubmit={values => {
                                                        onAuthorized(values)
                                                    }}>
                                                    {({errors, status, touched}) => (
                                                        <Form className="form-horizontal">
                                                            <div className="mb-3">
                                                                <Label for="username" className="form-label">
                                                                    Username
                                                                </Label>
                                                                <Field
                                                                    name="username"
                                                                    type="text"
                                                                    className={
                                                                        'form-control' +
                                                                        (errors.username && touched.username
                                                                            ? ' is-invalid'
                                                                            : '')
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="username"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <Label for="password" className="form-label">
                                                                    Password
                                                                </Label>
                                                                <div className="input-group auth-pass-inputgroup">
                                                                    <Field
                                                                        name="password"
                                                                        type={
                                                                            this.state.visiblePassword
                                                                                ? 'text'
                                                                                : 'password'
                                                                        }
                                                                        autoComplete="true"
                                                                        className={
                                                                            'form-control' +
                                                                            (errors.password && touched.password
                                                                                ? ' is-invalid'
                                                                                : '')
                                                                        }
                                                                    />
                                                                    <button
                                                                        className="btn btn-light "
                                                                        type="button"
                                                                        id="password-addon"
                                                                        onClick={this.visiblePassword}>
                                                                        <i className="mdi mdi-eye-outline"></i>
                                                                    </button>
                                                                </div>
                                                                <ErrorMessage
                                                                    name="password"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>

                                                            {/* <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                            </div> */}

                                                            <div className="mt-3 d-grid">
                                                                <button
                                                                    className="btn btn-primary btn-orange btn-block"
                                                                    type="submit">
                                                                    Log In
                                                                </button>
                                                            </div>

                                                            {/* <div className="mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock me-1" /> Forgot your
                                password?
                              </Link>
                            </div> */}
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <div className="mt-5 text-center">
                                        {/* <p>
                    Don&apos;t have an account ?
                    <Link to="register" className="fw-medium text-primary">
                      Signup Now
                    </Link>
                  </p> */}
                                        <p>Copyright © {new Date().getFullYear()} KUBOTA. All rights reserved</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            )
        }
    }
}

LoginPage.propTypes = {
    authorized: PropTypes.any,
    onAuthorized: PropTypes.func,
    onAuthorizedFail: PropTypes.func,
}

const mapStateToProps = ({authorized}) => ({
    authorized: authorized.data,
})

const mapDispatchToProps = dispatch => ({
    onAuthorized: data => dispatch(authorizedLogin(data)),
    onAuthorizedFail: data => dispatch(authorizedFail(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage))
