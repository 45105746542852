import React, { Component, useRef, createRef } from "react";
import { DatePicker, Space, DatePickerProps, Select ,theme,ConfigProvider, Input,Button} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/th";
import "./style.scss";
const { RangePicker } = DatePicker;
const monthPicker = ({ value, onChange }) => {
  const monthFormat = "MMMM YYYY";
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };
  return (

    <Space direction="vertical" size={13} > 
   
      <DatePicker
      className="month-style"
        picker="month"
        style={{
          fontFamily: "Inter",
          fontWeight: 400,
          fontSize: 14,
          borderColor: "#CED4DA",
          borderRadius: 8,
          color: "#495057",
          height: 38,
          
        }}
        defaultValue={value}
        format={monthFormat}
        disabledDate={disabledDate}
        bordered={true}
        onChange={onChange}
      
      />
</Space> 

  );
};
export default monthPicker;
