import {getWithQuery, postWithQuery, post, get, postNoLang} from '../../helpers/api_helper'

import {ENDPOINT} from 'constants/endpoint'

export const getSearchUserInformation = async params => {
    try {
        const response = await getWithQuery(`${ENDPOINT.USER_INFORMATION_SEARCH}?search=${params}`)

        return response
    } catch (error) {
        return null
    }
}

export const getUserHardwareID = async params => {
    try {
        const response = await get(`${ENDPOINT.USER_HARDWARE_ID}/${params}`)

        return response
    } catch (error) {
        return null
    }
}

export const getUserInformationDetail = async params => {
    try {
        const response = await get(`${ENDPOINT.USER_INFORMATION_DETAIL}/${params}`)

        return response
    } catch (error) {
        return null
    }
}

export const getUserInformationDrone = async params => {
    try {
        const response = await get(`${ENDPOINT.USER_INFORMATION_DRONE}/${params}`)

        return response
    } catch (error) {
        return null
    }
}

export const getUserInformationWorkOverView = async params => {
    try {
        const response = await getWithQuery(
            `${ENDPOINT.USER_INFORMATION_WORK_OVERVIEW}/${params?.id}?type=${params?.type}&year=${params?.year}&month=${params?.month}&week=${params?.week}&start_date=${params?.start_date}&end_date=${params?.end_date}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getUserInformationFlightTime = async params => {
    try {
        const response = await getWithQuery(
            `${ENDPOINT.USER_INFORMATION_FLIGHT_TIME}/${params?.id}?type=${params?.type}&year=${params?.year}&month=${params?.month}&week=${params?.week}&start_date=${params?.start_date}&end_date=${params?.end_date}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getUserInformationArea = async params => {
    try {
        const response = await get(`${ENDPOINT.USER_INFORMATION_AREA}/${params}`)
        return response
    } catch (error) {
        return null
    }
}

export const getLocationArea = async params => {
    try {
        const response = await get(`${ENDPOINT.USER_INFORMATION_LOCATION_AREA}/${params}`)
        return response
    } catch (error) {
        return null
    }
}

export const getFlightHistory = async params => {
    try {
        const response = await getWithQuery(
            `${ENDPOINT.USER_INFORMATION_FLIGHT_HISTORY}?user_id=${params?.id}&type=${params?.type}&year=${params?.year}&month=${params?.month}&week=${params?.week}&start_date=${params?.start_date}&end_date=${params?.end_date}`,
        )
        return response
    } catch (error) {
        return null
    }
}

export const getDroneDetail = async params => {
    try {
        const response = await getWithQuery(
            `${ENDPOINT.USER_INFORMATION_DRONE_DETAIL}/${params?.id}?type=${params?.type}&year=${params?.year}&month=${params?.month}&week=${params?.week}&start_date=${params?.start_date}&end_date=${params?.end_date}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getDroneFlightDuration = async params => {
    try {
        const response = await getWithQuery(
            `${ENDPOINT.USER_INFORMATION_DRONE_FLIGHT_DURATION}/${params?.id}?type=${params?.type}&year=${params?.year}&month=${params?.month}&week=${params?.week}&start_date=${params?.start_date}&end_date=${params?.end_date}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getDroneWorkingArea = async params => {
    try {
        const response = await getWithQuery(
            `${ENDPOINT.USER_INFORMATION_DRONE_WORKING_AREA}/${params?.id}?type=${params?.type}&year=${params?.year}&month=${params?.month}&week=${params?.week}&start_date=${params?.start_date}&end_date=${params?.end_date}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getDroneFlightHistory = async params => {
    try {
        const response = await getWithQuery(
            `${ENDPOINT.USER_INFORMATION_DRONE_FLIGHT_HISTORY}?user_id=${params?.id}&type=${params?.type}&year=${params?.year}&month=${params?.month}&week=${params?.week}&start_date=${params?.start_date}&end_date=${params?.end_date}`,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getDroneTableFlightHistory = async params => {
    try {
        const response = await getWithQuery(
            `${ENDPOINT.USER_INFORMATION_DRONE_TABLE_FLIGHT_HISTORY}/${params?.id}?type=${params?.type}&month=${
                params?.month
            }&week=${params?.week}&year=${params?.year}&page=${params?.page}&pageSize=${params?.pageSize}&search=${
                params?.search ?? ''
            }&sortBy=${params?.sortBy ?? ''}&upOrDown=${params?.upOrDown}&start_date=${params?.start_date}&end_date=${
                params?.end_date
            }&idType=${params?.idType}`,
        )

        return response
    } catch (error) {
        return null
    }
}
