import {
  GET_PROVINCE,
  GET_PROVINCE_FAIL,
  GET_PROVINCE_SUCCESS,
 
} from "./actionTypes";

export const getprovince = () => ({
  type: GET_PROVINCE,

});

export const getprovinceSuccess = (province) => ({
  type: GET_PROVINCE_SUCCESS,
  payload: province,
});

export const getprovinceFail = (error) => ({
  type: GET_PROVINCE_FAIL,
  payload: error,
});

