import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGIN_FAIL,
} from "./actionTypes"

const initialState = {
  user:{},
  error: "",
  loading: false,
  errors: false,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        user : action.payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case LOGIN_FAIL:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
