import React, {Component} from 'react'
import {Card, CardTitle, CardBody, Col, Row, Input, Button, InputGroup} from 'reactstrap'
import iconAreaMap from '../../../assets/images/icon/icon-area-map.png'
import iconModeFly from '../../../assets/images/icon/icon-mode-fly.png'
import iconLocationDrone from '../../../assets/images/icon/icon-location-drone.png'
import iconSpeedDrone from '../../../assets/images/icon/icon-speed.png'
import iconSprayFlag from '../../../assets/images/icon/icon-spray-flag.png'
import iconSpaceHeight from '../../../assets/images/icon/icon-space-height.png'
import iconUserGreen from '../../../assets/images/icon/icon-user-green.png'
import iconDroneOrange from '../../../assets/images/icon/icon-drone-orange.png'

import './index.scss'
import CardDetailDroneSkeleton from '../Skeleton-iNalyze/Real-time-Monitoring-Detail/CardDetailDroneSkeleton'
import {withTranslation} from 'react-i18next'

class CardDetailDrone extends Component {
    constructor(props) {
        super(props)
        this.state = {loadingSkeleton: true}
    }

    convertCoordinates = (latitude, longitude) => {
        if (latitude != undefined && longitude != undefined) {
            // Convert latitude to the "N15°19'26"" format
            const latDegrees = Math.floor(latitude)
            const latMinutes = Math.floor((latitude - latDegrees) * 60)
            const latSeconds = Math.round(((latitude - latDegrees) * 60 - latMinutes) * 60)

            // Convert longitude to the "E100°30'15"" format (assuming it's in the Eastern Hemisphere)
            const lonDegrees = Math.floor(longitude)
            const lonMinutes = Math.floor((longitude - lonDegrees) * 60)
            const lonSeconds = Math.round(((longitude - lonDegrees) * 60 - lonMinutes) * 60)

            // Create the formatted strings
            const latString = `N${latDegrees}°${latMinutes}'${latSeconds}"`
            const lonString = `E${lonDegrees}°${lonMinutes}'${lonSeconds}"`

            return {latString, lonString}
        } else {
            return {latString: '', lonString: ''}
        }
    }

    componentDidMount() {
        this.setState({loadingSkeleton: true})
    }

    componentDidUpdate() {
        if (this.state.loadingSkeleton) {
            setTimeout(() => {
                this.setState({loadingSkeleton: false})
            }, 3000)
        }
    }

    render() {
        const {latString, lonString} = this.convertCoordinates(
            this.props.data?.position?.lat,
            this.props.data?.position?.lng,
        )

        const {t} = this.props

        return (
            <>
                {this.state.loadingSkeleton ? (
                    <CardDetailDroneSkeleton />
                ) : (
                    <>
                        {this.props?.data ? (
                            <div className="card-detail">
                                <div className="box-title">
                                    <p>{t('realtime.workInformation')}</p>
                                    <div className="line"></div>
                                </div>

                                <Row className="box-card-data">
                                    <Col xl={2} style={{padding: 0}}>
                                        <div className="box-mode-pilot">
                                            <img src={iconUserGreen} />
                                        </div>
                                    </Col>
                                    <Col xl={10}>
                                        <div className="title">
                                            <p>{t('realtime.pilot')}</p>
                                        </div>
                                        <div className="value">
                                            <span style={{fontFamily: 'Kanit'}}>
                                                {this.props.information?.pilotName}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="box-card-data">
                                    <Col xl={2} style={{padding: 0}}>
                                        <div className="box-mode-drone">
                                            <img src={iconDroneOrange} />
                                        </div>
                                    </Col>
                                    <Col xl={10}>
                                        <div className="title">
                                            <p>{t('common.droneModel')}</p>
                                        </div>
                                        <div className="value">
                                            <span>{this.props.information?.droneModel}</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="box-card-data">
                                    <Col xl={2} style={{padding: 0}}>
                                        <div className="box-mode-fly">
                                            <img src={iconModeFly} />
                                        </div>
                                    </Col>
                                    <Col xl={10}>
                                        <div className="title">
                                            <p>{t('realtime.flightMode')}</p>
                                        </div>
                                        <div className="value">
                                            <span>
                                                {this.props.data?.farm_mode == 0
                                                    ? 'None'
                                                    : this.props.data?.farm_mode == 1
                                                    ? 'Manual'
                                                    : this.props.data?.farm_mode == 2
                                                    ? 'AB'
                                                    : this.props.data?.farm_mode == 3
                                                    ? 'M+'
                                                    : 'Auto'}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="box-card-data">
                                    <Col xl={2} style={{padding: 0}}>
                                        <div className="box-location-drone">
                                            <img src={iconLocationDrone} />
                                        </div>
                                    </Col>
                                    <Col xl={10}>
                                        <div className="title">
                                            <p>{t('common.position')} Real - time</p>
                                        </div>
                                        <div className="value">
                                            <span>
                                                {latString} <br></br> {lonString}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="box-card-data">
                                    <Col xl={2} style={{padding: 0}}>
                                        <div className="box-speed-drone">
                                            <img src={iconSpeedDrone} />
                                        </div>
                                    </Col>
                                    <Col xl={10}>
                                        <div className="title">
                                            <p>{t('realtime.speed')}</p>
                                        </div>
                                        <div className="value">
                                            <span>
                                                {this.props.velocity ? this.props.velocity?.toFixed(2) + ' m/s' : '-'}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="box-card-data">
                                    <Col xl={2} style={{padding: 0}}>
                                        <div className="box-space-height">
                                            <img src={iconSpaceHeight} />
                                        </div>
                                    </Col>
                                    <Col xl={10}>
                                        <div className="title">
                                            <p>{t('realtime.heightFromCrop')}</p>
                                        </div>
                                        <div className="value">
                                            <span>
                                                {this.props.data?.fusion_height != null
                                                    ? this.props.data?.fusion_height + ' m'
                                                    : '-'}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="box-card-data">
                                    <Col xl={2} style={{padding: 0}}>
                                        <div className="box-spray-flag">
                                            <img src={iconSprayFlag} />
                                        </div>
                                    </Col>
                                    <Col xl={10}>
                                        <div className="title">
                                            <p>{t('realtime.sprayingRate')}</p>
                                        </div>
                                        <div className="value">
                                            <span>
                                                {this.props.data?.spray_flag
                                                    ? this.props.data?.flow_speed.toFixed(2) + ' L/Min'
                                                    : '-'}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </>
                )}
            </>
        )
    }
}

export default withTranslation()(CardDetailDrone)
