import React, {Component} from 'react'
import {Row, Col, Table} from 'reactstrap'
import ReactApexChart from 'react-apexcharts'
import {withTranslation} from 'react-i18next'

class HistoryFlight extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        const {flightHistory, t} = this.props

        var nf = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})

        return (
            <>
                {flightHistory?.series?.length > 0 && (
                    <Row
                        style={{
                            paddingTop: 20,
                        }}>
                        <Col lg="3">
                            <div className="d-flex">
                                <div className="me-2">
                                    <h5
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            color: '#000000',
                                            paddingLeft: 20,
                                            fontFamily: 'Kanit',
                                        }}>
                                        {t('userInformation.flightTimeRanking')}
                                    </h5>
                                </div>
                            </div>
                            <div
                                className="type-drone"
                                style={{
                                    paddingTop: 20,
                                    paddingLeft: 20,
                                }}>
                                <Table className="table-nowrap">
                                    <tbody>
                                        {flightHistory?.rating.length ? (
                                            flightHistory?.rating.map((item, index) => (
                                                <tr key={index}>
                                                    <td
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            fontFamily: 'Inter',
                                                            color: '#000',
                                                        }}>
                                                        {index + 1}.
                                                    </td>
                                                    <td
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            fontFamily: 'Kanit',
                                                            color: '#000',
                                                        }}>
                                                        {item.days.toLocaleString('en-US', {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 2,
                                                        })}
                                                    </td>
                                                    <td>
                                                        <span
                                                            className="ms-2 text-truncate"
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 500,
                                                                fontFamily: 'Inter',
                                                                color: '#000',
                                                            }}>
                                                            {item?.amounthour.toLocaleString(
                                                                'en-US',
                                                                {
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 2,
                                                                } || '-',
                                                            )}
                                                        </span>
                                                        <span
                                                            className="ms-2 text-truncate"
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 300,
                                                                fontFamily: 'Kanit',
                                                                color: '#495057',
                                                            }}>
                                                            {t('common.hourUnit')}
                                                        </span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            fontFamily: 'Inter',
                                                            color: '#000',
                                                        }}>
                                                        {item?.time || '-'}
                                                        <span
                                                            className="ms-2 text-truncate"
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 300,
                                                                fontFamily: 'Kanit',
                                                                color: '#495057',
                                                            }}>
                                                            {t('common.minuteUnit')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td
                                                    style={{
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        fontFamily: 'Kanit',
                                                        color: 'gray',
                                                        textAlign: 'center',
                                                    }}
                                                    colSpan={4}>
                                                    {t('common.noData')}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>

                        <Col lg="9">
                            {flightHistory?.series.length != 0 ? (
                                <div
                                    id="chart"
                                    style={{
                                        paddingLeft: 20,
                                        paddingRight: 30,
                                        // backgroundColor:"red",
                                        marginTop: -40,
                                    }}>
                                    <ReactApexChart
                                        options={{
                                            chart: {
                                                height: 350,
                                                type: 'heatmap',
                                                toolbar: {
                                                    show: false,
                                                },
                                            },
                                            legend: {
                                                show: false,
                                                position: 'top',
                                                horizontalAlign: 'center',
                                                fontSize: 17,
                                                fontWeight: 500,
                                            },
                                            plotOptions: {
                                                heatmap: {
                                                    shadeIntensity: 0.5,
                                                    radius: 0,
                                                    // useFillColorAsStroke: true,
                                                    colorScale: {
                                                        ranges: flightHistory.ranges,
                                                    },
                                                },
                                            },
                                            dataLabels: {
                                                enabled: false,
                                            },
                                            yaxis: {
                                                // max: 70,
                                                title: {
                                                    text: t('common.day'),
                                                    // rotate: -90,
                                                    offsetX: 12,
                                                    offsetY: 0,
                                                    style: {
                                                        fontSize: '12px',
                                                    },
                                                },
                                                // labels: {
                                                //   formatter: function (val, index) {
                                                //     return val+" ชม.";
                                                //   },
                                                // },
                                            },
                                            tooltip: {
                                                y: {
                                                    formatter: function (val, index) {
                                                        return nf.format(val) + ` ${t('common.hourUnit')}`
                                                    },
                                                },
                                            },
                                            xaxis: {
                                                title: {
                                                    text: t('common.time'),
                                                    // rotate: -90,
                                                    offsetX: 0,
                                                    offsetY: 0,
                                                    style: {
                                                        fontSize: '12px',
                                                        color: '#495057',
                                                    },
                                                },
                                            },
                                        }}
                                        series={flightHistory?.series}
                                        type="heatmap"
                                        height={450}
                                    />
                                </div>
                            ) : (
                                <div
                                    style={{
                                        width: '100%',
                                        height: 330,
                                        paddingLeft: 10,
                                        paddingRight: 30,
                                        marginTop: 30,
                                        textAlign: 'center',
                                        paddingTop: 100,
                                    }}>
                                    <p
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            fontFamily: 'Kanit',
                                            color: 'gray',
                                            textAlign: 'center',
                                            marginTop: 50,
                                        }}>
                                        {t('common.noData')}
                                    </p>
                                </div>
                            )}
                        </Col>
                    </Row>
                )}
            </>
        )
    }
}

export default withTranslation()(HistoryFlight)
