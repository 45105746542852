import {takeEvery, put, call} from 'redux-saga/effects'

// Chat Redux States
import {AUTHORIZED} from './actionTypes'
import {authorizedSuccess, authorizedFail} from '../actions'

import {postLogin} from '../../services/skc/auth'

function* onAuthorized({payload: data}) {
    try {
        const response = yield call(postLogin, {
            email: data.username,
            password: data.password,
        })
        localStorage.setItem('authUser', JSON.stringify(response))
        window.location.href = '/dashboard'
        // yield put(authorizedSuccess({status : true}))
    } catch (error) {
        yield put(authorizedFail({status: false}))
    }
}

function* authorizedSaga() {
    yield takeEvery(AUTHORIZED, onAuthorized)
}

export default authorizedSaga
