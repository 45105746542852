import React, {useEffect, useRef, useState} from 'react'
import {Row, Col, Card, CardBody} from 'reactstrap'
import 'react-perfect-scrollbar/dist/css/styles.css'
import classnames from 'classnames'
import styled from 'styled-components'
import {withTranslation} from 'react-i18next'
import {useRecoilState, useRecoilValue} from 'recoil'

import './style/map.scss'

import MapSkeleton from '../Dashboard/CardSkeleton/MapSkeleton'
import ChartsMap from 'components/Map/ChartsMap'
import TableMap from 'components/Map/TableMap'

import {filterDashboardState, loadingDashboardState} from 'store/atoms/dashboard'
import {getDashboardMap} from 'services/skc/new-dashboard'

const DashboardMap = props => {
    const {t, i18n} = props
    const filterDashboard = useRecoilValue(filterDashboardState)

    const [loading, setLoading] = useRecoilState(loadingDashboardState)

    const [dataDrone, setDataDrone] = useState([])
    const [dataUser, setDataUser] = useState([])
    const [activeTab, setActiveTab] = useState('drones')
    const [loadingSkeleton, setLoadingSkeleton] = useState(true)
    const [language, setLanguage] = useState(i18n.language)
    const [sortDataButton, setSortDataButton] = useState('UNIT')
    const mapRefDrone = useRef()
    const mapRefUser = useRef()
    const refDivRowsDrone = useRef()
    const refDivRowsUser = useRef()
    let rowRefsDrone = useRef()
    let rowRefsUser = useRef()

    const handleChangeTab = tab => {
        setActiveTab(tab)
    }

    useEffect(() => {
        fetchDataMap(filterDashboard)
    }, [])

    useEffect(() => {
        if (i18n.language !== language) {
            fetchDataMap(filterDashboard)
            setLanguage(i18n.language)
        }
    }, [i18n.language])

    useEffect(() => {
        if (loading.map) {
            setSortDataButton('UNIT')
            fetchDataMap({...filterDashboard, to_date: 'UNIT'})
            setLoading(cur => ({...cur, map: false}))
        }
    }, [loading.map])

    const fetchDataMap = async params => {
        try {
            setLoadingSkeleton(true)
            const responseDrone = await getDashboardMap(params, 'drone')

            if (responseDrone.status_code === 200 && responseDrone.results?.mapData?.length > 0) {
                setDataDrone(responseDrone?.results)
            }

            const responseUser = await getDashboardMap(params, 'user')

            if (responseUser.status_code === 200 && responseUser.results?.mapData?.length > 0) {
                setDataUser(responseUser?.results)
            }
        } catch (error) {
            setDataDrone({})
            setDataUser({})
        } finally {
            setLoadingSkeleton(false)
        }
    }

    const handleClickMapDrone = selectMap => {
        const dataRow = Object.keys(rowRefsDrone).map(key => rowRefsDrone[key])
        const dataDivRow = Object.keys(refDivRowsDrone).map(key => refDivRowsDrone[key])

        const findDataRow = dataRow?.find(item => item?.current?.id === selectMap)
        const findDataDiv = dataDivRow?.find(item => item?.current?.id === selectMap)

        dataDivRow?.forEach(ref => {
            ref?.current?.classList?.remove('table-active')
        })

        dataRow?.forEach(ref => {
            ref?.current?.classList?.remove('text-click')
        })

        findDataRow?.current?.scrollIntoView({behavior: 'auto', block: 'nearest'})
        findDataRow?.current?.classList?.add('text-click')
        findDataDiv?.current?.classList?.add('table-active')
    }

    const handleClickMapUser = selectMap => {
        const dataRow = Object.keys(rowRefsUser).map(key => rowRefsUser[key])
        const dataDivRow = Object.keys(refDivRowsUser).map(key => refDivRowsUser[key])

        const findDataRow = dataRow?.find(item => item?.current?.id === selectMap)
        const findDataDiv = dataDivRow?.find(item => item?.current?.id === selectMap)

        dataDivRow?.forEach(ref => {
            ref?.current?.classList?.remove('table-active')
        })

        dataRow?.forEach(ref => {
            ref?.current?.classList?.remove('text-click')
        })

        findDataRow?.current?.scrollIntoView({behavior: 'auto', block: 'nearest'})
        findDataRow?.current?.classList?.add('text-click')
        findDataDiv?.current?.classList?.add('table-active')
    }

    const sortFilterBtn = data => {
        setSortDataButton(data)
        fetchDataMap({...filterDashboard, to_date: data})
    }

    return (
        <Col xl={12}>
            {loadingSkeleton ? (
                <MapSkeleton />
            ) : (
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg="4">
                                <p className="title-head-map">
                                    {t('dashboard.informationAboutDronesAndUsersInEachArea')}
                                </p>
                                {activeTab === 'drones' && (
                                    <ChartsMap
                                        country={filterDashboard.country}
                                        data={dataDrone?.mapData}
                                        mapRef={mapRefDrone}
                                        selectMap={handleClickMapDrone}
                                    />
                                )}
                                {activeTab === 'users' && (
                                    <>
                                        <ChartsMap
                                            country={filterDashboard.country}
                                            data={dataUser?.mapData}
                                            mapRef={mapRefUser}
                                            selectMap={handleClickMapUser}
                                        />
                                    </>
                                )}
                            </Col>
                            <Col lg="8">
                                <div
                                    style={{
                                        textAlignLast: 'center',
                                        padding: 0,
                                        display: 'flex',
                                        marginBottom: 20,
                                        marginTop: 16,
                                    }}>
                                    <div style={{padding: 0}}>
                                        <Button
                                            className={classnames({
                                                active: activeTab === 'drones',
                                            })}
                                            onClick={() => handleChangeTab('drones')}
                                            color={activeTab === 'drones' ? '0' : '1'}>
                                            {t(`dashboard.numberOfDrones`)}
                                        </Button>

                                        <ActiveTab $primary={activeTab === 'drones' ? true : false} />
                                    </div>
                                    <div style={{textAlignLast: 'center', padding: 0}}>
                                        <Button
                                            className={classnames({
                                                active: activeTab === 'users',
                                            })}
                                            onClick={() => handleChangeTab('users')}
                                            color={activeTab === 'users' ? '0' : '1'}>
                                            {t(`dashboard.numberOfUsers`)}
                                        </Button>
                                        <ActiveTab $primary={activeTab === 'users' ? true : false} />
                                    </div>
                                </div>
                                {activeTab === 'drones' && (
                                    <TableMap
                                        title={t('dashboard.cumulativeDrones')}
                                        data={dataDrone?.provinceData}
                                        percentChart={dataDrone?.percentChart}
                                        max={dataDrone?.max}
                                        tabData={'drone'}
                                        unit={t('common.droneUnit')}
                                        refDivRows={refDivRowsDrone}
                                        rowRefs={rowRefsDrone}
                                        filter={filterDashboard}
                                        sortFilterBtn={sortFilterBtn}
                                        sortDataButton={sortDataButton}
                                        percentHeatMap={{
                                            maxPercent: dataDrone?.maxPercent,
                                            minPercent: dataDrone?.minPercent,
                                        }}
                                    />
                                )}
                                {activeTab === 'users' && (
                                    <TableMap
                                        title={t('dashboard.cumulativeUsers')}
                                        percentChart={dataUser?.percentChart}
                                        data={dataUser?.provinceData}
                                        max={dataUser?.max}
                                        tabData={'user'}
                                        unit={t('common.personUnit')}
                                        refDivRows={refDivRowsUser}
                                        rowRefs={rowRefsUser}
                                        filter={filterDashboard}
                                        sortFilterBtn={sortFilterBtn}
                                        sortDataButton={sortDataButton}
                                        percentHeatMap={{
                                            maxPercent: dataUser?.maxPercent,
                                            minPercent: dataUser?.minPercent,
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )}
        </Col>
    )
}

export default withTranslation()(DashboardMap)

const Button = styled.button`
    background-color: #fff;
    color: ${props => (props.color == '0' ? '#F05A28' : '#4E4E4E')};
    text-align: center;
    font-family: Kanit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding: 14px 37px 14px 37px;

    cursor: pointer;
    border-width: 0px;
`
const ActiveTab = styled.div`
    background-color: ${props => (props.$primary ? '#F05A28' : '#FEEEE9')};
    padding: 1px;
`
