import React, {useState} from 'react'
import {withTranslation} from 'react-i18next'
import {Table, Row, Col} from 'reactstrap'
import {Pagination} from 'antd'

import './stylesTable.scss'

const TableDroneInformation = props => {
    const {
        onChangePagination,
        column,
        row,
        isPagination,
        pageSize,
        total,
        page,
        onSort,
        isShowIndex = true,
        action,
        t,
        i18n,
        onClickRowTable,
    } = props

    const [sortTypeData, setSortType] = useState('ASC')

    const [isHovered, setIsHovered] = useState(false)

    const handleSort = columnName => {
        let sortType

        if (sortTypeData === 'ASC') {
            sortType = 'DESC'
        } else {
            sortType = 'ASC'
        }

        if (columnName === 'sale_date' || columnName === 'sale_date_number') {
            onSort('sale_date', sortType)
        } else {
            onSort(columnName, sortType)
        }

        setSortType(sortType)
    }

    const handleChangePagination = (page, pageSize) => {
        onChangePagination(page === 0 ? 1 : page, pageSize)
    }

    const handleMouseEnter = index => {
        setIsHovered(index)
    }

    const handleMouseLeave = index => {
        setIsHovered(index)
    }
    return (
        <div>
            <div className="table-responsive">
                <Table
                    style={{margin: 0, marginTop: 12, padding: 0}}
                    className="table table-hover  table-bordered  mb-0 data-table">
                    <thead className="table-header ">
                        <tr>
                            {isShowIndex && (
                                <th className="column-index" style={{backgroundColor: '#F3F6F9'}}>
                                    #
                                </th>
                            )}
                            {column?.map((itm, index) => (
                                <th
                                    key={`${itm.key}-${index}`}
                                    style={{
                                        textAlign: itm.textAlign,
                                        backgroundColor: '#F3F6F9',
                                    }}>
                                    <div className="container-text-column">
                                        <p style={{marginLeft: itm.textAlign === 'center' ? 20 : 0, fontSize: 12}}>
                                            {i18n.language === 'en' ? itm.title_en : itm.title_th}
                                        </p>
                                        {itm.sort && (
                                            <i className="bx bx-sort icon-sort" onClick={() => handleSort(itm.key)}></i>
                                        )}
                                    </div>
                                </th>
                            ))}
                            {action?.length > 0 && (
                                <th className="column-action" style={{backgroundColor: '#F3F6F9', fontSize: 12}}>
                                    Action
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {row?.length > 0 ? (
                            row?.map((itemRow, indexRow) => (
                                <tr
                                    style={{cursor: 'pointer'}}
                                    key={`${itemRow}-${indexRow}`}
                                    onClick={() => onClickRowTable(itemRow.id)}>
                                    {isShowIndex && (
                                        <th scope="row" width={'3%'} style={{textAlign: 'center', fontSize: 12}}>
                                            {(page - 1) * pageSize + (indexRow + 1)}
                                        </th>
                                    )}
                                    {column?.map((itemColumn, columnIndex) => (
                                        <td
                                            key={`${itemRow[itemColumn.key]}-${columnIndex}`}
                                            style={{
                                                width: itemColumn.width,
                                                color: itemColumn.color,
                                                fontSize: 12,
                                            }}
                                            className="row-data">
                                            <>
                                                {itemColumn.key === 'company_code' ? (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            margin: 0,
                                                            padding: 0,
                                                            height: '100%',
                                                            paddingLeft: 20,
                                                        }}
                                                        onMouseEnter={() => handleMouseEnter(indexRow)}
                                                        onMouseLeave={() => handleMouseLeave(null)}>
                                                        {itemColumn.key === 'company_code' && (
                                                            <div
                                                                style={{
                                                                    display: isHovered === indexRow ? 'block' : 'none',
                                                                }}
                                                                className={`container-company `}>
                                                                <p
                                                                    className="text-company"
                                                                    style={{
                                                                        display:
                                                                            isHovered === indexRow ? 'block' : 'none',
                                                                        fontSize: 12,
                                                                    }}>
                                                                    {itemRow.company || '-'}
                                                                </p>
                                                            </div>
                                                        )}
                                                        <p style={{margin: 0, padding: 0, fontSize: 12}}>
                                                            {itemRow[itemColumn.key] || '-'}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            margin: 0,
                                                            padding: 0,
                                                            height: '100%',
                                                            paddingLeft: 20,
                                                        }}>
                                                        <p style={{margin: 0, padding: 0}}>
                                                            {itemRow[itemColumn.key] || '-'}
                                                        </p>
                                                    </div>
                                                )}
                                            </>
                                        </td>
                                    ))}
                                    {action?.length > 0 && (
                                        <td
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                            }}
                                            className="row-action">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginTop: 4,
                                                    padding: 0,
                                                }}>
                                                {action.map((itm, index) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            margin: 2,
                                                        }}
                                                        onClick={() => itm.onClick(itemRow.id)}>
                                                        {itm.button}
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr key={0} className="text-center py-5 px-4">
                                <th colSpan={column?.length + 2}>{t('common.noData')}</th>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <Row style={{display: 'flex', alignItems: 'center', margin: 0, padding: 0, marginTop: 20}}>
                <Col xs={3} sm={3} md={3} xl={3} style={{fontFamily: 'Kanit', fontWeight: 400, color: '#495057'}}>
                    <span style={{color: '#B5B5B6'}}> {t('common.total')} : </span>
                    <span style={{fontFamily: 'Inter', fontWeight: 500}}>
                        {Number(total)?.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0}) ||
                            0}
                    </span>
                    <span style={{marginLeft: 4}}>{t('common.list')}</span>
                </Col>
                {isPagination && (
                    <Col xs={8} sm={3} md={8} xl={6} className="container-kads-pagination">
                        <Pagination
                            current={page}
                            defaultCurrent={page}
                            pageSize={pageSize}
                            total={total === 0 ? 1 : total}
                            onChange={(page, pageSize) => handleChangePagination(page, pageSize)}
                        />
                    </Col>
                )}
            </Row>
        </div>
    )
}

export default withTranslation()(TableDroneInformation)
