import React from 'react'
import Skeleton from 'react-loading-skeleton'
import {Row, Col, Card, CardBody} from 'reactstrap'
import 'react-loading-skeleton/dist/skeleton.css'
import './inalyze-skeleton-styles.scss'

const InalyzeNavbarSkeleton = props => {
    return (
        <Col xl={12}>
            <Card className="card-navbar">
                <CardBody>
                    <Row>
                        <Col xl={6}>
                            <div className="box-container">
                                <Skeleton className="main-button" />
                                <Skeleton className="main-button" />
                                <Skeleton className="main-button" />
                                <Skeleton className="custom-button" />
                                <div
                                    className="div-date"
                                    style={{width: props?.activeTab === 'week' ? '357px' : '173px'}}>
                                    <Skeleton width={props?.activeTab === 'week' ? 330 : 150} />
                                </div>
                            </div>
                        </Col>
                        <Col xl={12}>
                            <Row className="box-search">
                                <Col xl={2} className="col-section">
                                    <Skeleton width={50} style={{marginRight: 40}} />
                                    <div className="div-select">
                                        <Skeleton width={150} />
                                    </div>
                                </Col>
                                <Col xl={5} className="col-section">
                                    <Skeleton width={40} style={{marginRight: 40}} />
                                    <div className="div-select-long">
                                        <Skeleton width={620} />
                                    </div>
                                </Col>
                                <Col xl={5} className="col-section">
                                    <Skeleton width={40} style={{marginRight: 40}} />
                                    <div className="div-select-long">
                                        <Skeleton width={620} />
                                    </div>
                                </Col>
                                <Col xl={5} className="col-section">
                                    <Skeleton width={60} style={{marginRight: 30}} />
                                    <div className="div-select-long">
                                        <Skeleton width={620} />
                                    </div>
                                </Col>
                                <Col xl={5} className="col-section">
                                    <Skeleton width={40} style={{marginRight: 50}} />
                                    <div className="div-select-long-section">
                                        <Skeleton width={620} />
                                    </div>
                                </Col>
                                <Col xl={2} className="col-section">
                                    <Skeleton className="button-submit" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

export default InalyzeNavbarSkeleton
