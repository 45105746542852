import { GET_DISTRICT_SUCCESS, GET_DISTRICT_FAIL, GET_DISTRICT } from "./actionTypes";

const INIT_STATE = {
  district: [],
};

const district = (state = INIT_STATE, action) => {
  // console.log("action",action.payload)
  switch (action.type) {
    case GET_DISTRICT_SUCCESS:
      return {
        ...state,
        district: action.payload,
      };

    case GET_DISTRICT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default district;
