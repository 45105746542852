import {getWithQuery, post, postWithQuery} from '../../helpers/api_helper'
import {ENDPOINT} from 'constants/endpoint'

export const getMapInalyze = async (params, value) => {
    try {
        const response = await postWithQuery(
            `${ENDPOINT.INALYZE_MAP}?type=${params.type}&week=${params.week}&month=${params.month}&year=${params.year}&start_date=${params.start_date}&end_date=${params.end_date}&value=${value}&country=${params.country}&to_date=${params.to_date}`,
            params,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getCompareAreaData = async params => {
    try {
        const response = await post(`${ENDPOINT.COMPARE_AREA_DATA}`, params)

        return response
    } catch (error) {
        return null
    }
}

export const getCompareAreaGraph = async (params, value, average) => {
    try {
        const response = await postWithQuery(
            `${ENDPOINT.COMPARE_AREA_GRAPH}?&value=${value}&average=${average}`,
            params,
        )

        return response
    } catch (error) {
        return null
    }
}

export const getCompareTime = async params => {
    try {
        const response = await post(`${ENDPOINT.COMPARE_TIME}`, params)

        return response
    } catch (error) {
        return null
    }
}
