import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import React from 'react'
import {Row, Col, Card, CardBody, CardTitle} from 'reactstrap'
import './style.scss'
const UsedSkeleton = ({}) => {
    return (
        <Card
            style={{
                borderRadius: 8,
                height: 536,
            }}>
            <CardTitle
                style={{
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    display: 'flex',
                    marginTop: 20,
                    paddingLeft: 25,
                    paddingRight: 25,
                }}>
                <div>
                    <Skeleton height={24} width={160} />
                </div>
                <div className="justify-content-end">
                    <div style={{display: 'flex', marginTop: 24}}>
                        <Skeleton width={120} height={64} style={{}} />
                        <Skeleton width={120} height={64} style={{marginLeft: 15}} />
                        <Skeleton width={120} height={64} style={{marginLeft: 15}} />
                    </div>
                </div>
            </CardTitle>
            <CardBody>
                <Row>
                    <Col xs={12} lg={12}>
                        <div className="card-skeleton " style={{marginTop: 20}}>
                            <Skeleton height={300} />
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default UsedSkeleton
