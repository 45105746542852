import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  OverlayView,
  Circle,
  useLoadScript,
  useGoogleMap,
  Polygon,
  InfoBox,
} from "@react-google-maps/api";
import { Button } from "reactstrap";

const MapContainer = ({ keyIndex, location, array, isAdding, getLocation, polygon, data, data_plot }) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [selected, setSelected] = useState({});
  const [currentPosition, setCurrentPosition] = useState({});
  const mapStyles = () => {
    return {
      borderRadius: 8,
      height: "340px",
      width: "100%",
    };
  };

  useEffect(() => {
    if (currentPosition.length == 0) {
      success();
    }
    // navigator.geolocation.getCurrentPosition(success);
  });

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
    scaleControl: false,
    mapTypeId: "hybrid",
    controlSize: 25,
    // ControlPosition:true
  };
  const onMapLoad = map => {
    const bounds = new google.maps.LatLngBounds();
    polygon?.forEach(item => bounds.extend({ lat: item.lat, lng: item.lng }));
    map.fitBounds(bounds);
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyB5RVDr2EpQhSt1YsMFCu7Xi2qlVz3qP2o",
  });
  const options = { closeBoxURL: "", enableEventPropagation: true };
  const onLoad = infoBox => {
    // console.log("infoBox: ", infoBox);
  };
  let GetCentrePoint = coordinates => {
    let x = 0;
    let y = 0;
    coordinates.map(c => {
      (x += c.lat), (y += c.lng);
    });
    x = x / coordinates.length;
    y = y / coordinates.length;
    return { lat: x, lng: y };
  };
  const [actionType, setActionType] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (actionType < 2) {
        setActionType(item => item + 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [actionType]);
  if (Object.keys(location).length != 0) {
    // console.log("actionType",actionType)
    return (
      <>
        {isLoaded && actionType === 2 ? (
          <GoogleMap
            id="example-map"
            mapContainerStyle={mapStyles()}
            draggable={true}
            key={keyIndex}
            center={location}
            options={mapOptions}
            onLoad={onMapLoad}
          >
            {polygon.length != 0 ? (
              <Polygon
                path={polygon}
                draggable={false}
                editable={false}
                // onClick={(e: any) => console.log("polygon key", items.id_polygon)}
                options={{
                  strokeColor: "#00A69C",
                  //   strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#00A69C",
                  fillOpacity: 0.45,
                  geodesic: true,
                }}
              />
            ) : (
              polygon.forEach(element => {
                // console.log(element);
              })
            )}
            {location ? (
              <InfoBox onLoad={onLoad} options={options} position={GetCentrePoint(polygon)}>
                <div
                  style={{
                    fontSize: 16,
                    fontColor: `#fff`,
                    color: "#fff",
                    fontFamily: "Kanit",
                  }}
                >
                  {typeof data.area_name == "string"
                    ? data.area_name
                    : data.area_name === null
                    ? "-"
                    : data.area_name.th}
                  <br />
                  <p
                    style={{
                      fontSize: 16,
                      fontColor: `#fff`,
                      color: "#fff",
                      fontFamily: "Inter",
                    }}
                  >
                    {empty(data.area_size) == true ? "" : data.area_size}
                    <span
                      style={{
                        fontSize: 14,
                        fontColor: `#fff`,
                        color: "#fff",
                        fontFamily: "Kanit",
                        marginLeft: 5,
                      }}
                    >
                      ไร่
                    </span>
                  </p>
                </div>
              </InfoBox>
            ) : (
              location.forEach(element => {
                // console.log(element);
              })
            )}
          </GoogleMap>
        ) : (
          <div>Loading...</div>
        )}
      </>
    );
  } else {
    return <div></div>;
  }
};

export default MapContainer;
function empty(e) {
  switch (e) {
    case "":
    case 0:
    case "0":
    case null:
    case false:
    case undefined:
      return true;
    default:
      return false;
  }
}
