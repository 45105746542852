import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import React, {Component, useEffect} from 'react'
import {Row, Col, Card, CardBody, CardTitle} from 'reactstrap'

const DataRegularSkeleton = ({}) => {
    const loadCards = Array(7).fill(1)
    return (
        <Col>
            <Card
                style={{
                    display: 'flex',
                    borderRadius: 8,
                    boxShadow: '0px 12px 24px rgb(240, 240, 240)',
                    height: 570,
                    paddingTop: 30,
                }}>
                <CardBody>
                    <div className="row">
                        <div className="col-8">
                            <div className="d-flex">
                                <Skeleton width={140} height={22} style={{marginTop: 12, marginBottom: 24}} />
                                <Skeleton
                                    width={140}
                                    height={22}
                                    style={{marginTop: 12, marginBottom: 24, marginLeft: 20}}
                                />
                            </div>
                            {loadCards.map((card, i) => (
                                <div key={i}>
                                    <div className="row">
                                        <div className="col-4">
                                            <Skeleton />
                                        </div>
                                        <div className="col-8">
                                            <Skeleton />
                                        </div>
                                    </div>
                                    <Skeleton height={1} style={{marginBottom: 16}} />
                                </div>
                            ))}
                        </div>
                        <div className="col-4">
                            <Skeleton height={340} style={{marginTop: 56}} />
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}

export default DataRegularSkeleton
