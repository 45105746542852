import React from 'react'
import {Row, Col} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'

import '../skeleton-droneInformation.scss'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonCardDroneDetail = () => {
    return (
        <div style={{marginTop: '40px', borderTop: '1px solid #EFF2F7'}}>
            <Row style={{margin: 0}}>
                <Col lg={12}>
                    <Skeleton width={120} height={27} style={{marginTop: 16}} />
                </Col>
                <Col lg={3} style={{marginBottom: 16, marginTop: 16}}>
                    <Skeleton width={234} height={107} style={{borderRadius: 8}} />
                </Col>
                <Col lg={3} style={{marginBottom: 16, marginTop: 16}}>
                    <Skeleton width={234} height={107} style={{borderRadius: 8}} />
                </Col>
                <Col lg={3} style={{marginBottom: 16, marginTop: 16}}>
                    <Skeleton width={234} height={107} style={{borderRadius: 8}} />
                </Col>
                <Col lg={3} style={{marginBottom: 16, marginTop: 16}}>
                    <Skeleton width={234} height={107} style={{borderRadius: 8}} />
                </Col>
                <Col lg={3} style={{marginBottom: 16, marginTop: 16}}>
                    <Skeleton width={234} height={107} style={{borderRadius: 8}} />
                </Col>
                <Col lg={12}>
                    <Skeleton width={120} height={27} style={{marginTop: 16}} />
                </Col>
                <Col lg={3} style={{marginBottom: 16, marginTop: 16}}>
                    <Skeleton width={234} height={107} style={{borderRadius: 8}} />
                </Col>
                <Col lg={3} style={{marginBottom: 16, marginTop: 16}}>
                    <Skeleton width={234} height={107} style={{borderRadius: 8}} />
                </Col>
                <Col lg={3} style={{marginBottom: 16, marginTop: 16}}>
                    <Skeleton width={234} height={107} style={{borderRadius: 8}} />
                </Col>
                <Col lg={3} style={{marginBottom: 16, marginTop: 16}}>
                    <Skeleton width={234} height={107} style={{borderRadius: 8}} />
                </Col>
                <Col lg={12}>
                    <Skeleton width={120} height={27} style={{marginTop: 16}} />
                </Col>
                <Col lg={3} style={{marginBottom: 16, marginTop: 16}}>
                    <Skeleton width={234} height={107} style={{borderRadius: 8}} />
                </Col>
                <Col lg={3} style={{marginBottom: 16, marginTop: 16}}>
                    <Skeleton width={234} height={107} style={{borderRadius: 8}} />
                </Col>
                <Col lg={3} style={{marginBottom: 16, marginTop: 16}}>
                    <Skeleton width={234} height={107} style={{borderRadius: 8}} />
                </Col>
                <Col lg={3} style={{marginBottom: 16, marginTop: 16}}>
                    <Skeleton width={234} height={107} style={{borderRadius: 8}} />
                </Col>
            </Row>
        </div>
    )
}

export default SkeletonCardDroneDetail
