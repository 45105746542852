import React, {useState, useEffect, useRef} from 'react'
import {GoogleMap, OverlayView, Circle, useLoadScript} from '@react-google-maps/api'
import {Button} from 'reactstrap'

const MapLocation = ({location, array, isAdding, getLocation}) => {
    const [latitude, setLatitude] = useState(null)
    const [longitude, setLongitude] = useState(null)
    const [selected, setSelected] = useState({})
    const [currentPosition, setCurrentPosition] = useState({})

    useEffect(() => {
        if (currentPosition.length == 0) {
            success()
        }
        // console.log("navigator",navigator)
        // navigator.geolocation.getCurrentPosition(success);
    })
    const defaultCenter = {
        lat: 18.968780517578125,
        lng: 99.58447265625,
    }

    const onSelect = item => {
        setSelected(item)
    }

    const success = position => {
        // const latitude = position.coords.latitude;
        // const longitude = position.coords.longitude;
        const latitude = 18.968780517578125
        const longitude = 99.58447265625
        const currentPosition = {
            lat: latitude,
            lng: longitude,
        }
        setCurrentPosition(currentPosition)
    }

    const onMarkerDragEnd = e => {
        const lat = e.latLng.lat()
        const lng = e.latLng.lng()
        setCurrentPosition({lat, lng})
    }

    const mapStyles = () => {
        return {
            borderRadius: 8,
            height: '340px',
            width: '100%',
        }
    }

    const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
        return {
            x: offsetWidth + labelAnchor.x,
            y: offsetHeight + labelAnchor.y,
        }
    }
    const mapOptions = {
        disableDefaultUI: true,
        zoomControl: true,
        fullscreenControl: true,
        scaleControl: false,
        mapTypeId: 'hybrid',
        controlSize: 25,
        // ControlPosition:true
    }
    const onMapLoad = map => {
        const bounds = new google.maps.LatLngBounds()
        // console.log("navigator", location)
        location?.forEach(item =>
            bounds.extend({
                lat: item?.position_latest?.position?.latitude,
                lng: item?.position_latest?.position?.longitude,
            }),
        )
        map.fitBounds(bounds)
    }
    const ondefaultMapLoad = map => {
        const bounds = new google.maps.LatLngBounds()

        polygon?.forEach(item => bounds.extend({lat: item.lat, lng: item.lng}))
        map.fitBounds(bounds)
    }
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: 'AIzaSyB5RVDr2EpQhSt1YsMFCu7Xi2qlVz3qP2o',
    })
    const [actionType, setActionType] = useState(0)
    useEffect(() => {
        const interval = setInterval(() => {
            if (actionType < 2) {
                setActionType(item => item + 1)
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [actionType])

    if (location.length != 0) {
        // console.log("actionType",actionType)

        return (
            <>
                {isLoaded && actionType === 2 ? (
                    <GoogleMap
                        id="example-map"
                        mapContainerStyle={mapStyles()}
                        draggable={true}
                        center={{
                            lat: location[0]?.position_latest?.position?.latitude,
                            lng: location[0]?.position_latest?.position?.longitude,
                        }}
                        options={mapOptions}
                        onLoad={onMapLoad}>
                        {location.length != 0
                            ? location.map((item, index) => {
                                  return (
                                      <Circle
                                          key={index}
                                          center={{
                                              lat: item?.position_latest?.position?.latitude,
                                              lng: item?.position_latest?.position?.longitude,
                                          }}
                                          radius={5}
                                          options={{
                                              strokeColor: '#00FFF0',
                                              strokeOpacity: 0.3,
                                              strokeWeight: 30,
                                              fillColor: 'rgba(0, 255, 240)',
                                              fillOpacity: 0.8,
                                          }}
                                      />
                                  )
                              })
                            : location.forEach(element => {
                                  // console.log(element);
                              })}
                        {location.length != 0
                            ? location.map((item, index) => (
                                  <OverlayView
                                      key={index}
                                      position={{
                                          lat: item?.position_latest?.position?.latitude,
                                          lng: item?.position_latest?.position?.longitude,
                                      }}
                                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                      getPixelPositionOffset={(x, y) =>
                                          getPixelPositionOffset(x, y, {x: -(x / 0.65), y: -(y / 0.36)})
                                      }>
                                      <div
                                          style={{
                                              fontFamily: 'Inter',
                                              fontSize: 13,
                                              fontWeight: 500,
                                              background: `rgba(0, 0, 0, 0.85)`,
                                              color: `white`,
                                              padding: 10,
                                              paddingLeft: 15,
                                              paddingRight: 15,
                                              borderRadius: 4,
                                              width: 'max-content',
                                              // marginTop: -70,
                                          }}>
                                          {item?.drone_name}
                                      </div>
                                  </OverlayView>
                              ))
                            : location.forEach(element => {
                                  // console.log(element);
                              })}
                    </GoogleMap>
                ) : (
                    <div>Loading...</div>
                )}
            </>
        )
    } else {
        return (
            <>
                {isLoaded ? (
                    <GoogleMap
                        id="example-map"
                        mapContainerStyle={mapStyles()}
                        draggable={false}
                        center={defaultCenter}
                        options={mapOptions}
                        onLoad={ondefaultMapLoad}></GoogleMap>
                ) : (
                    <div>Loading...</div>
                )}
            </>
        )
    }
}

export default MapLocation

const polygon = [
    {
        title: 'K-D20P',
        lat: 13.809911,
        lng: 100.652376,
    },
    {
        title: 'K-D10',
        lat: 13.812706,
        lng: 100.656489,
    },
    {
        title: 'K-D20',
        lat: 13.808014,
        lng: 100.657517,
    },
]

const points = [
    {
        id: 1,
        title: 'Round Pond',
        lat: 13.809911,
        lng: 100.652376,
    },
    {
        id: 2,
        title: 'The Long Water',
        lat: 13.812706,
        lng: 100.656489,
    },
    {
        id: 3,
        title: 'The Serpentine',
        lat: 13.808014,
        lng: 100.657517,
    },
]

function createCenterControl(map) {
    const controlButton = document.createElement('button')

    // Set CSS for the control.
    controlButton.style.backgroundColor = '#fff'
    controlButton.style.border = '2px solid #fff'
    controlButton.style.borderRadius = '3px'
    controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)'
    controlButton.style.color = 'rgb(25,25,25)'
    controlButton.style.cursor = 'pointer'
    controlButton.style.fontFamily = 'Roboto,Arial,sans-serif'
    controlButton.style.fontSize = '16px'
    controlButton.style.lineHeight = '38px'
    controlButton.style.margin = '8px 0 22px'
    controlButton.style.padding = '0 5px'
    controlButton.style.textAlign = 'center'
    controlButton.textContent = 'Center Map'
    controlButton.title = 'Click to recenter the map'
    controlButton.type = 'button'
    // Setup the click event listeners: simply set the map to Chicago.
    controlButton.addEventListener('click', () => {
        map.setCenter(chicago)
    })
    return controlButton
}
