import React, {useEffect, useState} from 'react'
import {Card, CardBody, CardText, CardTitle} from 'reactstrap'
import {useRecoilState, useRecoilValue} from 'recoil'
import {withTranslation} from 'react-i18next'

import dayjs from 'dayjs'

import logoDefault from '../../../../assets/images/logoHome.png'

import DataPilotSkeleton from '../../CardSkeleton/DataPilotSkeleton'

import {filterUserInformationState, loadingUserInformationState} from 'store/atoms/user-information'
import {getUserInformationDetail} from 'services/skc/user-information'

const DataPilot = props => {
    const {t, i18n} = props

    const [skeleton, setSkeleton] = useState(false)
    const [data, setData] = useState({})

    const filterUserInformation = useRecoilValue(filterUserInformationState)
    const [loading, setLoading] = useRecoilState(loadingUserInformationState)

    useEffect(() => {
        fetchUserInformationDetail(filterUserInformation.id)
    }, [])

    useEffect(() => {
        if (loading?.data_pilot) {
            fetchUserInformationDetail(filterUserInformation.id)
            setLoading(cur => ({...cur, data_pilot: false}))
        }
    }, [loading?.data_pilot])

    const fetchUserInformationDetail = async params => {
        try {
            setSkeleton(true)

            const response = await getUserInformationDetail(params)

            if (response?.status_code === 200 && response?.results?.data) {
                setData(response?.results?.data)
            }
        } catch (error) {
            setData({})
        } finally {
            setSkeleton(false)
        }
    }

    return (
        <div>
            {skeleton ? (
                <DataPilotSkeleton />
            ) : (
                <Card
                    style={{
                        borderRadius: 8,
                        height: 496,
                    }}>
                    <CardBody>
                        <CardTitle
                            className="card-title  text-center "
                            style={{
                                fontSize: 20,
                                fontWeight: 600,
                                fontFamily: 'Kanit',
                                marginTop: 10,
                            }}>
                            <img
                                style={{boxShadow: 'none'}}
                                src={logoDefault}
                                alt=""
                                className="avatar-md rounded-circle img-thumbnail"
                            />
                        </CardTitle>
                        <CardText
                            className="text-center"
                            style={{
                                fontSize: 22,
                                fontWeight: 500,
                                fontFamily: 'Kanit',
                                color: '#000000',
                            }}>
                            {data?.first_name || '-'} {data?.last_name || '-'}
                        </CardText>

                        <div className="">
                            <div
                                className="row "
                                style={{
                                    paddingRight: 20,
                                    paddingLeft: 20,
                                }}>
                                <table className="table  table-wrap white-bg">
                                    <tbody>
                                        <tr scope="row">
                                            <td
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 300,
                                                    fontFamily: i18n.language === 'th' ? 'Kanit' : 'Inter',

                                                    width: '130px',
                                                }}>
                                                {t('userInformation.joinedOn')}:
                                            </td>
                                            <td
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    fontFamily: 'Inter',
                                                    color: '#495057',
                                                }}>
                                                {data?.created_date
                                                    ? dayjs(data?.created_date).format('DD/MM/YYYY')
                                                    : '-'}
                                            </td>
                                        </tr>
                                        <tr scope="row">
                                            <td
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 300,
                                                    fontFamily: i18n.language === 'th' ? 'Kanit' : 'Inter',
                                                    width: '130px',
                                                }}>
                                                {t('userInformation.membershipDuration')}:
                                            </td>
                                            <td
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    fontFamily: 'Inter',
                                                    color: '#495057',
                                                }}>
                                                {data?.created_time || '-'}
                                            </td>
                                        </tr>
                                        <tr scope="row">
                                            <td
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 300,
                                                    fontFamily: i18n.language === 'th' ? 'Kanit' : 'Inter',
                                                    width: '130px',
                                                }}>
                                                {t('common.phoneNumber')}:
                                            </td>
                                            <td
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    fontFamily: 'Inter',
                                                    color: '#495057',
                                                }}>
                                                {data?.mobile_phone_no || '-'}
                                            </td>
                                        </tr>
                                        <tr scope="row">
                                            <td
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 300,
                                                    fontFamily: i18n.language === 'th' ? 'Kanit' : 'Inter',
                                                    width: '130px',
                                                }}>
                                                {t('common.email')}:
                                            </td>
                                            <td
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    fontFamily: 'Inter',
                                                    color: '#495057',
                                                }}>
                                                {data?.email_address || '-'}
                                            </td>
                                        </tr>
                                        <tr scope="row">
                                            <td
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 300,
                                                    fontFamily: i18n.language === 'th' ? 'Kanit' : 'Inter',
                                                    width: '130px',
                                                }}>
                                                {t('common.address')}:
                                            </td>
                                            <td
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    fontFamily: 'Inter',
                                                    color: '#495057',
                                                }}>
                                                {data?.address || '-'}{' '}
                                                {i18n.language === 'en'
                                                    ? data?.subdistrict?.name_en || '-'
                                                    : data?.subdistrict?.name_th || '-'}{' '}
                                                {i18n.language === 'en'
                                                    ? data?.province?.name_en || '-'
                                                    : data?.province?.name_th || '-'}{' '}
                                                {data?.zip_code || '-'}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            )}
        </div>
    )
}

export default withTranslation()(DataPilot)
