import {combineReducers} from 'redux'

// Front
import Layout from './layout/reducer'

// Authentication
import Login from './auth/login/reducer'

import authorized from './authorized/reducer'

// -----------------------------------------------------------

//tasks

import drone from './filterdrone/reducer'

import province from './getprovince/reducer'

import district from './getdistrict/reducer'

//Drone Infomation iNalyze

import navbarDroneActive from './navbar-drone-active/reducer'

// User info

import way_point from './waypoint/reducer'

// Drone Active

import loadingDroneActive from './loading-drone-active/reducer'
import speedDroneActive from './speed-drone-active/reducer'
import heightDroneActive from './height-drone-active/reducer'

const rootReducer = combineReducers({
    // public

    Layout,
    Login,
    authorized,

    drone,
    province,
    district,

    navbarDroneActive,

    way_point,

    loadingDroneActive,

    speedDroneActive,
    heightDroneActive,
})

export default rootReducer
