import React from 'react'
import Skeleton from 'react-loading-skeleton'
import {Row, Col} from 'reactstrap'

import './skeleton-droneInformation.scss'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonSearchDroneAndHour = () => {
    return (
        <Row className="skeleton-search-drone-and-hour">
            <Col lg={4} className="col-drone">
                <Skeleton className="text" width={54} height={24} />
                <Skeleton width={350} height={40} />
            </Col>
            <Col lg={7} className="col-time">
                <Skeleton className="text" width={110} height={24} />
                <div className="box-time">
                    <Skeleton className="section" width={86} height={24} />
                    <Skeleton className="select-type" width={150} height={40} />
                    <Skeleton className="section" width={86} height={24} />
                    <Skeleton className="input-hour" width={120} height={40} />
                    <p className="hyphen">-</p>
                    <Skeleton className="input-hour" width={120} height={40} />
                </div>
                <Skeleton style={{marginLeft: 40, borderRadius: 8}} width={82} height={40} />
            </Col>
        </Row>
    )
}

export default SkeletonSearchDroneAndHour
