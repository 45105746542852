import React from 'react'
import '../skeleton-droneInformation.scss'
import {Row, Col, Card, CardBody} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonDetailDrone = () => {
    return (
        <Row style={{marginTop: '24px', marginBottom: '24px'}}>
            <Col>
                <Card
                    style={{
                        background: '#FFFFFF',
                        borderRadius: '8px',
                    }}>
                    <CardBody>
                        <Skeleton width={150} height={21} />

                        <div style={{display: 'flex', alignItems: 'flex-end', marginTop: 16}}>
                            <Skeleton width={35} height={24} style={{marginRight: 8}} />
                            <Skeleton width={26} height={19} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col>
                <Card
                    style={{
                        background: '#FFFFFF',
                        borderRadius: '8px',
                    }}>
                    <CardBody>
                        <Skeleton width={150} height={21} />

                        <div style={{display: 'flex', alignItems: 'flex-end', marginTop: 16}}>
                            <Skeleton width={35} height={24} style={{marginRight: 8}} />
                            <Skeleton width={26} height={19} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col>
                <Card
                    style={{
                        background: '#FFFFFF',
                        borderRadius: '8px',
                    }}>
                    <CardBody>
                        <Skeleton width={150} height={21} />

                        <div style={{display: 'flex', alignItems: 'flex-end', marginTop: 16}}>
                            <Skeleton width={35} height={24} style={{marginRight: 8}} />
                            <Skeleton width={26} height={19} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col>
                <Card
                    style={{
                        background: '#FFFFFF',
                        borderRadius: '8px',
                    }}>
                    <CardBody>
                        <Skeleton width={150} height={21} />

                        <div style={{display: 'flex', alignItems: 'flex-end', marginTop: 16}}>
                            <Skeleton width={35} height={24} style={{marginRight: 8}} />
                            <Skeleton width={26} height={19} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col>
                <Card
                    style={{
                        background: '#FFFFFF',
                        borderRadius: '8px',
                    }}>
                    <CardBody>
                        <Skeleton width={150} height={21} />

                        <div style={{display: 'flex', alignItems: 'flex-end', marginTop: 16}}>
                            <Skeleton width={35} height={24} style={{marginRight: 8}} />
                            <Skeleton width={26} height={19} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col>
                <Card
                    style={{
                        background: '#FFFFFF',
                        borderRadius: '8px',
                    }}>
                    <CardBody>
                        <Skeleton width={150} height={21} />

                        <div style={{display: 'flex', alignItems: 'flex-end', marginTop: 16}}>
                            <Skeleton width={35} height={24} style={{marginRight: 8}} />
                            <Skeleton width={26} height={19} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col>
                <Card
                    style={{
                        background: '#FFFFFF',
                        borderRadius: '8px',
                    }}>
                    <CardBody>
                        <Skeleton width={150} height={21} />

                        <div style={{display: 'flex', alignItems: 'flex-end', marginTop: 16}}>
                            <Skeleton width={35} height={24} style={{marginRight: 8}} />
                            <Skeleton width={26} height={19} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default SkeletonDetailDrone
