const realtime = {
    workingDrone: 'Working Drone',
    total: 'Total',
    pilot: 'Pilot',
    workInformation: 'Work information',
    flightMode: 'Flight mode',
    speed: 'Speed',
    heightFromCrop: 'Height from Crop',
    sprayingRate: 'Spraying rate',
}

export default realtime
