import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {Row, Col} from 'reactstrap'

const OverviewDetailSkeleton = () => {
    return (
        <Row>
            <Col lg="6" className="mb-2">
                <div
                    className="x-2 py-4 border"
                    style={{
                        borderRadius: 8,
                    }}>
                    <div style={{marginLeft: 15}} className="d-flex align-items-center mb-3 px-3">
                        <Skeleton width={150} height={16} />
                    </div>
                    <div
                        className="text-muted"
                        style={{
                            paddingLeft: 15,
                        }}>
                        <h3
                            className="pb-1 px-3"
                            style={{
                                paddingLeft: 15,
                                display: 'flex',
                                alignItems: 'flex-end',
                            }}>
                            <Skeleton width={118} height={33} />

                            <Skeleton width={20} height={20} style={{marginLeft: 8}} />
                        </h3>

                        <div className="d-flex border-top pt-3 px-3">
                            <Skeleton width={58} height={19} />
                            <Skeleton style={{marginLeft: 4}} width={58} height={19} />
                        </div>
                    </div>
                </div>
            </Col>
            <Col lg="6" className="mb-2">
                <div
                    className="x-2 py-4 border"
                    style={{
                        borderRadius: 8,
                    }}>
                    <div style={{marginLeft: 15}} className="d-flex align-items-center mb-3 px-3">
                        <Skeleton width={150} height={16} />
                    </div>
                    <div
                        className="text-muted"
                        style={{
                            paddingLeft: 15,
                        }}>
                        <h3
                            className="pb-1 px-3"
                            style={{
                                paddingLeft: 15,
                                display: 'flex',
                                alignItems: 'flex-end',
                            }}>
                            <Skeleton width={118} height={33} />

                            <Skeleton width={20} height={20} style={{marginLeft: 8}} />
                        </h3>

                        <div className="d-flex border-top pt-3 px-3">
                            <Skeleton width={58} height={19} />
                            <Skeleton style={{marginLeft: 4}} width={58} height={19} />
                        </div>
                    </div>
                </div>
            </Col>
            <Col lg="6" className="mb-2">
                <div
                    className="x-2 py-4 border"
                    style={{
                        borderRadius: 8,
                    }}>
                    <div style={{marginLeft: 15}} className="d-flex align-items-center mb-3 px-3">
                        <Skeleton width={150} height={16} />
                    </div>
                    <div
                        className="text-muted"
                        style={{
                            paddingLeft: 15,
                        }}>
                        <h3
                            className="pb-1 px-3"
                            style={{
                                paddingLeft: 15,
                                display: 'flex',
                                alignItems: 'flex-end',
                            }}>
                            <Skeleton width={118} height={33} />

                            <Skeleton width={20} height={20} style={{marginLeft: 8}} />
                        </h3>

                        <div className="d-flex border-top pt-3 px-3">
                            <Skeleton width={58} height={19} />
                            <Skeleton style={{marginLeft: 4}} width={58} height={19} />
                        </div>
                    </div>
                </div>
            </Col>
            <Col lg="6" className="mb-2">
                <div
                    className="x-2 py-4 border"
                    style={{
                        borderRadius: 8,
                    }}>
                    <div style={{marginLeft: 15}} className="d-flex align-items-center mb-3 px-3">
                        <Skeleton width={150} height={16} />
                    </div>
                    <div
                        className="text-muted"
                        style={{
                            paddingLeft: 15,
                        }}>
                        <h3
                            className="pb-1 px-3"
                            style={{
                                paddingLeft: 15,
                                display: 'flex',
                                alignItems: 'flex-end',
                            }}>
                            <Skeleton width={118} height={33} />

                            <Skeleton width={20} height={20} style={{marginLeft: 8}} />
                        </h3>

                        <div className="d-flex border-top pt-3 px-3">
                            <Skeleton width={58} height={19} />
                            <Skeleton style={{marginLeft: 4}} width={58} height={19} />
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default OverviewDetailSkeleton
