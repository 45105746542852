import {
  GET_WAY_POINT_FAIL,
  GET_WAY_POINT_SUCCESS,
  
} from "./actionTypes";

const INIT_STATE = {
  way_point: [],
 
};

const way_point = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WAY_POINT_SUCCESS:
      return {
        ...state,
        way_point: action.payload,
      };

    case GET_WAY_POINT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
   
    default:
      return state;
  }
};

export default way_point;
