import React from 'react'
import {withTranslation} from 'react-i18next'
import {Row, Col, Nav, NavItem, NavLink} from 'reactstrap'

const OverviewAverage = props => {
    const {data, t, tabData, changeTab} = props

    const tabDataName = [
        {
            title: 'Mean',
            value: 'MEAN',
        },
        {
            title: 'Mode',
            value: 'MODE',
        },
        {
            title: 'Median',
            value: 'MEDIAN',
        },
    ]

    return (
        <Row className="row-average">
            <Col lg={6}>
                <Nav tabs>
                    {tabDataName?.map(itm => (
                        <NavItem key={itm.value} onClick={() => changeTab(itm.value)}>
                            <NavLink className={`${tabData === itm.value && 'active-tab'} `}>{itm.title}</NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </Col>
            <Col lg={12} className="col-data-average">
                <div className="me-2 mb-4">
                    <h5
                        style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: '#000000',
                        }}>
                        {t('dashboard.averageDroneUsageData')}
                    </h5>
                </div>

                <div className="table-responsive border type-drone">
                    <table className="table table-striped mb-0 table white-bg">
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        color: '#495057',
                                        fontSize: 14,
                                        fontWeight: 400,
                                    }}>
                                    {t('dashboard.averageWorkingTime')}
                                </td>
                                <td
                                    style={{
                                        color: '#000000',
                                        fontSize: 14,
                                        fontWeight: 700,
                                        fontFamily: 'Inter',
                                    }}>
                                    {data?.average_hour_per_drone || '-'}
                                    <span
                                        style={{
                                            color: '#000000',
                                            fontSize: 14,
                                            fontWeight: 400,
                                            fontFamily: 'Kanit',
                                            marginLeft: 7,
                                        }}>
                                        {t('common.hourUnitDroneUnit')}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        color: '#495057',
                                        fontSize: 14,
                                        fontWeight: 400,
                                    }}>
                                    {t('dashboard.averageWorkArea')}
                                </td>
                                <td
                                    style={{
                                        color: '#000000',
                                        fontSize: 14,
                                        fontWeight: 700,
                                        fontFamily: 'Inter',
                                    }}>
                                    {data?.average_task_area_per_drone || '-'}{' '}
                                    <span
                                        style={{
                                            color: '#000000',
                                            fontSize: 14,
                                            fontWeight: 400,
                                            fontFamily: 'Kanit',
                                            marginLeft: 7,
                                        }}>
                                        {'  '}
                                        {t('common.raiUnitDroneUnit')}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        color: '#495057',
                                        fontSize: 14,
                                        fontWeight: 400,
                                    }}>
                                    {t('dashboard.averageAmountFlights')}
                                </td>
                                <td
                                    style={{
                                        color: '#000000',
                                        fontSize: 14,
                                        fontWeight: 700,
                                        fontFamily: 'Inter',
                                    }}>
                                    {data?.average_flight_per_drone || '-'}

                                    <span
                                        style={{
                                            color: '#000000',
                                            fontSize: 14,
                                            fontWeight: 400,
                                            fontFamily: 'Kanit',
                                            marginLeft: 7,
                                        }}>
                                        {t('common.hourUnitFlightUnit')}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Col>
        </Row>
    )
}

export default withTranslation()(OverviewAverage)
