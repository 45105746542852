import React, {useState, useEffect} from 'react'
import {useRecoilState, useSetRecoilState} from 'recoil'
import {withTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import {Container, Row, Col, Button, DropdownMenu, DropdownToggle, Dropdown, DropdownItem} from 'reactstrap'
import html2canvas from 'html2canvas'
import dayjs from 'dayjs'

import './index.scss'

import {overAllUsageTab} from 'constants/lookup'
//Import components Overall

import CardDrone from '../../components/DroneiNalyze/DroneInformation/Overall/CardDrone'
import DataDrone from '../../components/DroneiNalyze/DroneInformation/Overall/DataDrone'

//Import components Usage

import UsageNavbar from 'components/DroneiNalyze/DroneInformation/Usage/UsageNavbar'
import UsageDetailDrone from 'components/DroneiNalyze/DroneInformation/Usage/UsageDetailDrone'
import UsageFlightDuration from 'components/DroneiNalyze/DroneInformation/Usage/UsageFlightDuration'
import UsageWorkingArea from 'components/DroneiNalyze/DroneInformation/Usage/UsageWorkingArea'
import UsageSpeedDrone from 'components/DroneiNalyze/DroneInformation/Usage/UsageSpeedDrone'
import UsageHeightDroneInfo from 'components/DroneiNalyze/DroneInformation/Usage/UsageHeightDroneInfo'
import UsageAllFlightHistory from 'components/DroneiNalyze/DroneInformation/Usage/UsageAllFlightHistory'
import UsageDroneFlightListsTable from 'components/DroneiNalyze/DroneInformation/Usage/UsageDroneFlightListsTable'

import IconExport from '../../assets/images/icon/icon-export.png'
import IconGoBackArrowOrange from '../../assets/images/icon/icon-go-back-orange.png'
import IconUserProfile from '../../assets/images/icon/user-profile-square.png'
import IconArrowRight from '../../assets/images/icon/chevron-right-white.png'

import {getOverAllData, getDroneHardwareID} from 'services/skc/drone-information'
import {getDroneLatest} from 'services/skc/new-kafka'

import {filterNavbarUsageState} from 'store/atoms/drone-information'
import {filterUserInformationState} from 'store/atoms/user-information'

const DroneInformationOverallAndUsage = props => {
    const {t, i18n} = props

    const history = useHistory()
    const params = useParams()

    const droneId = history?.location?.search?.split('?').join('')

    const [filterNavbarUsage, setFilterNavbarUsage] = useRecoilState(filterNavbarUsageState)
    const setFilterUserInformation = useSetRecoilState(filterUserInformationState)

    const [activeTab, setActiveTab] = useState('overall')

    const [menuExport, setMenuExport] = useState(false)

    const [skeletonOverAll, setSkeletonOverAll] = useState(false)
    const [skeletonDroneLatest, setSkeletonDroneLatest] = useState(false)

    const [overAllData, setOverAllData] = useState()
    const [droneLatest, setDroneLatest] = useState()

    const [hardwareID, setHardwareID] = useState('')

    const toggleTab = tab => {
        setActiveTab(tab)

        if (tab === 'overall') {
            history.push({
                pathname: '/drone-information/overall',
                search: `${droneId}`,
            })
        }

        if (tab === 'usage') {
            history.push({
                pathname: '/drone-information/usage',
                search: `${droneId}`,
            })
        }
    }

    useEffect(() => {
        setActiveTab(params.tab)
    }, [])

    useEffect(() => {
        setFilterNavbarUsage({...filterNavbarUsage, id: droneId})
        fetchOverAllData({id: droneId})
        fetchHardwareID(droneId)
    }, [droneId])

    useEffect(() => {
        if (hardwareID) {
            fetchDroneLatest(hardwareID)
        }
    }, [hardwareID])

    const onBtnUser = () => {
        setFilterUserInformation(cur => ({...cur, id: overAllData?.user_id}))
        history.push(`/user-information/overall`)
    }

    const goBack = () => {
        history.push(`/drone-information`)
    }

    const fetchOverAllData = async params => {
        try {
            setSkeletonOverAll(true)
            const response = await getOverAllData(params)

            if (response.status_code === 200 && response.results) {
                setOverAllData(response.results)
            }
        } catch (error) {
            setOverAllData({})
        } finally {
            setSkeletonOverAll(false)
        }
    }

    const fetchHardwareID = async params => {
        try {
            const response = await getDroneHardwareID(params)

            if (response.status_code === 200 && response.results?.data) {
                setHardwareID(response?.results?.data)
            }
        } catch (error) {
            setHardwareID('')
        } finally {
        }
    }

    const fetchDroneLatest = async params => {
        try {
            setSkeletonDroneLatest(true)
            const response = await getDroneLatest(params)

            if (response?.result) {
                setDroneLatest(response?.result)
            }
        } catch (error) {
            setDroneLatest({})
        } finally {
            setSkeletonDroneLatest(false)
        }
    }

    const toggleExport = () => {
        setMenuExport(prevent => !prevent)
    }

    const downloadScreenshot = async ext => {
        html2canvas(document.getElementById('page-content'), {
            scale: 1.2,
        }).then(async function (canvas) {
            const data = await canvas.toDataURL(`image/${ext}`),
                link = document.createElement('a')

            link.href = data
            link.download = `${dayjs().format('DD-MM-YYYY-hmmss')}.${ext}`

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        })
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Row className="flex-container space-between container-row-overall-usage">
                    <Col lg={12} onClick={goBack}>
                        <div className="box-go-back">
                            <img className="icon-arrow" src={IconGoBackArrowOrange} />
                            <p
                                className="text-back"
                                style={{
                                    fontFamily: i18n.language === 'en' ? 'Inter' : 'Kanit',
                                }}>
                                {t('common.back')}
                            </p>
                        </div>
                    </Col>

                    <Col xl={6} className="col-tab">
                        {overAllUsageTab.map((item, index) => {
                            return (
                                <Button
                                    key={index}
                                    color="#F5F1EF"
                                    outline
                                    className="w-lg  btn-tab"
                                    style={{
                                        fontFamily: 'Inter',
                                        color: activeTab == item.value ? '#fff' : '#F05A28',
                                        backgroundColor: activeTab == item.value ? '#F05A28' : '#F5F1EF',
                                    }}
                                    onClick={() => toggleTab(item.value)}>
                                    {item.title}
                                </Button>
                            )
                        })}
                    </Col>

                    <Col xl={6} className="container-btn-export  d-flex justify-content-end">
                        <Dropdown isOpen={menuExport} toggle={toggleExport} style={{marginRight: 16}}>
                            <DropdownToggle className="btn-export">
                                <img src={IconExport} alt="" height="20" className="img-export" />
                                <span>Export</span>
                            </DropdownToggle>
                            <DropdownMenu className="box-menu-export">
                                <DropdownItem className="item-menu-export-header" header>
                                    Export to
                                </DropdownItem>

                                <DropdownItem
                                    className="item-menu-export"
                                    onClick={() => {
                                        downloadScreenshot('png')
                                    }}>
                                    png
                                </DropdownItem>
                                <DropdownItem className="item-menu-export" onClick={() => downloadScreenshot('jpeg')}>
                                    jpeg
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <Button className="btn-user" onClick={onBtnUser}>
                            <img src={IconUserProfile} alt="" height="20" style={{marginRight: '8px'}} />
                            <span>{t('droneInformation.userInformation')}</span>
                            <img src={IconArrowRight} alt="" height="20" style={{marginLeft: '8px'}} />
                        </Button>
                    </Col>
                </Row>
                {activeTab === 'overall' ? (
                    <Row id="page-content">
                        <DataDrone data={overAllData} loadingSkeleton={skeletonOverAll} />
                        <CardDrone
                            overAllData={overAllData}
                            droneLatest={droneLatest}
                            loadingOverAll={skeletonOverAll}
                            loadingDroneLatest={skeletonDroneLatest}
                        />
                    </Row>
                ) : (
                    <Row id="page-content">
                        <Col xl={12}>
                            <UsageNavbar />
                        </Col>
                        <Col xl={12}>
                            <UsageDetailDrone />
                        </Col>
                        <Col xl={6}>
                            <UsageFlightDuration />
                        </Col>
                        <Col xl={6}>
                            <UsageWorkingArea />
                        </Col>

                        <Col xl={6}>
                            <UsageSpeedDrone hardwareID={hardwareID} />
                        </Col>
                        <Col xl={6}>
                            <UsageHeightDroneInfo hardwareID={hardwareID} />
                        </Col>
                        <Col xl={12}>
                            <UsageAllFlightHistory />
                        </Col>
                        <Col xl={12}>
                            <UsageDroneFlightListsTable />
                        </Col>
                    </Row>
                )}
            </Container>
        </div>
    )
}

export default withTranslation()(DroneInformationOverallAndUsage)
