import React, {useState} from 'react'
import {withTranslation} from 'react-i18next'
import {Container, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'
import styled from 'styled-components'
import html2canvas from 'html2canvas'
import dayjs from 'dayjs'

import './index.scss'

import SelectNavBar from '../../components/UseriNalyze/Inalyze/SelectNavBar'
import Widget from '../../components/UseriNalyze/Inalyze/Widget'
import MapUserInalyze from 'components/UseriNalyze/Inalyze/MapUserInalyze'

// image
import IconExport from '../../assets/images/icon/icon-export.png'
import ScatterCustomerUserInalyze from 'components/UseriNalyze/Inalyze/ScatterCustomerUserInalyze'
import BoxPlotCustomerUserInalyze from 'components/UseriNalyze/Inalyze/BoxPlotCustomerUserInalyze'

const UserInalyze = props => {
    const {t} = props

    const [otherMenu, setOtherMenu] = useState(false)

    const toggleOther = () => {
        setOtherMenu(current => !current)
    }

    const downloadScreenshot = async ext => {
        html2canvas(document.getElementById('page-content'), {
            scale: 1.2,
        }).then(async function (canvas) {
            const data = await canvas.toDataURL(`image/${ext}`),
                link = document.createElement('a')

            link.href = data
            link.download = `${dayjs().format('DD-MM-YYYY-hmmss')}.${ext}`

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        })
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Row
                    style={{
                        marginBottom: 20,
                    }}>
                    <Col xl="6">
                        <h3
                            style={{
                                fontSize: 24,
                                fontWeight: 800,
                                fontFamily: 'Inter',
                                color: '#000000',
                                marginTop: 10,
                            }}>
                            {t('userInalyze.userInalyze')}
                        </h3>
                    </Col>
                    <Col xl="6" className="text-end">
                        <Dropdown
                            isOpen={otherMenu}
                            toggle={toggleOther}
                            direction="down"
                            className=" ms-3 dropend"
                            style={{
                                paddingTop: 5,
                            }}>
                            <DropdownToggle tag="i" className="text-muted">
                                <ButtonExport color="#3B89FF" size="sm">
                                    <img src={IconExport} alt="" height="18" />
                                    <span
                                        style={{
                                            fontSize: 15,
                                            fontWeight: 600,
                                            fontFamily: 'Inter',
                                            color: '#FFFFFF',
                                            marginLeft: 10,
                                        }}>
                                        Export
                                    </span>
                                </ButtonExport>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    header
                                    style={{
                                        backgroundColor: '#F3F3F3',
                                        fontSize: 12,
                                        fontWeight: 600,
                                        fontFamily: 'Inter',
                                        color: '#212529',
                                    }}>
                                    Export to
                                </DropdownItem>

                                <DropdownItem
                                    style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        color: '#212529',
                                    }}
                                    onClick={() => {
                                        downloadScreenshot('png')
                                    }}>
                                    png
                                </DropdownItem>
                                <DropdownItem
                                    style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        fontFamily: 'Inter',
                                        color: '#212529',
                                    }}
                                    onClick={() => {
                                        downloadScreenshot('jpeg')
                                    }}>
                                    jpeg
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                </Row>

                <div id="page-content">
                    <Row>
                        <Col xl="12">
                            <SelectNavBar />
                        </Col>
                        <Col xl="12">
                            <Widget />
                        </Col>
                        <Col xl="12">
                            <MapUserInalyze />
                        </Col>
                        <Col xl="12">
                            <ScatterCustomerUserInalyze />
                        </Col>
                        <Col xl="12">
                            <BoxPlotCustomerUserInalyze />
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default withTranslation()(UserInalyze)

const ButtonExport = styled.button`
    background-color: #3b89ff;
    color: white;
    font-size: 14px;
    padding: 13px 25px 13px 25px;
    border-radius: 8px;
    border-width: 0px;
    text-align: center;
`
