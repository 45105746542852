import React, {useEffect, useRef, useState} from 'react'
import {Chart} from 'react-google-charts'
import './style-map.scss'
const MapALL = props => {
    const {data, scrollData, mapRef, onMap, zoom} = props

    const options = {
        region: 'TH',
        displayMode: 'regions',
        resolution: 'provinces',

        colorAxis: {
            colors: ['#FF1515', '#FF5050', '#FF8A8A', '#FFC5C5', '#EAEBEA', '#B2E8CB', '#5CD295', '#1ABF69', '#17834A'],
            values: [-4, -3, -2, -1, 0, 1, 2, 3, 4],
        },

        backgroundColor: {
            fill: 'transparent',
            stroke: 'transparent',
            strokeWidth: 0,
        },

        datalessRegionColor: 'transparent',
        keepAspectRatio: true,
        tooltip: {
            trigger: 'hover',
        },
        legend: 'none',

        enableRegionInteractivity: 'true',
    }

    return (
        <div style={{position: 'relative', height: 600}}>
            <Chart
                ref={mapRef}
                style={{position: 'absolute', left: '-120px', top: '-20px'}}
                chartEvents={[
                    {
                        eventName: 'select',
                        callback: ({chartWrapper}) => {
                            const chart = chartWrapper?.getChart()

                            const selection = chart?.getSelection()

                            const region = mapRef?.current?.props?.data?.[selection?.[0]?.row + 1]

                            scrollData(region?.[0])
                        },
                    },
                    // {
                    //     eventName: 'ready',
                    //     callback: ({chartWrapper}) => {
                    //         onMap(chartWrapper)
                    //         mapRef.current = chartWrapper
                    //     },
                    // },
                ]}
                data={data}
                chartType="GeoChart"
                options={options}
                width="880px"
                height="700px"
            />
        </div>
    )
}

const MapLA = props => {
    const {data, scrollData, mapRef, onMap, zoom} = props
    const options = {
        region: 'LA',
        // displayMode: "regions",
        // resolution: "provinces",
        defaultColor: 'transparent',
        colorAxis: {
            colors: ['#FF1515', '#FF5050', '#FF8A8A', '#FFC5C5', '#EAEBEA', '#B2E8CB', '#5CD295', '#1ABF69', '#17834A'],
            values: [-4, -3, -2, -1, 0, 1, 2, 3, 4],
        },
        backgroundColor: {
            fill: 'transparent',
            stroke: 'transparent',
            strokeWidth: 0,
        },

        datalessRegionColor: 'transparent',
        keepAspectRatio: true,
        tooltip: {
            trigger: 'hover',
        },
        legend: 'none',

        enableRegionInteractivity: 'true',
    }

    return (
        <Chart
            ref={mapRef}
            style={{position: 'relative', left: '-100px', top: '10px'}}
            chartEvents={[
                {
                    eventName: 'select',
                    callback: ({chartWrapper}) => {
                        const chart = chartWrapper?.getChart()
                        const selection = chart?.getSelection()

                        const region = mapRef?.current?.props?.data?.[selection?.[0]?.row + 1]

                        scrollData(region?.[0])
                    },
                },
            ]}
            chartType="GeoChart"
            width="880px"
            height="600px"
            data={data}
            options={options}
        />
    )
}

const MapKH = props => {
    const {data, scrollData, mapRef, onMap, zoom} = props
    const options = {
        region: 'KH',
        // displayMode: "regions",
        // resolution: "provinces",
        defaultColor: 'transparent',
        colorAxis: {
            colors: ['#FF1515', '#FF5050', '#FF8A8A', '#FFC5C5', '#EAEBEA', '#B2E8CB', '#5CD295', '#1ABF69', '#17834A'],
            values: [-4, -3, -2, -1, 0, 1, 2, 3, 4],
        },
        backgroundColor: {
            fill: 'transparent',
            stroke: 'transparent',
            strokeWidth: 0,
        },

        datalessRegionColor: 'transparent',
        keepAspectRatio: true,
        tooltip: {
            trigger: 'hover',
        },
        legend: 'none',

        enableRegionInteractivity: 'true',
    }

    return (
        <Chart
            ref={mapRef}
            style={{position: 'relative', left: '-100px', top: '10px'}}
            chartEvents={[
                {
                    eventName: 'select',
                    callback: ({chartWrapper}) => {
                        const chart = chartWrapper?.getChart()
                        const selection = chart?.getSelection()

                        const region = mapRef?.current?.props?.data?.[selection?.[0]?.row + 1]

                        scrollData(region?.[0])
                    },
                },
            ]}
            chartType="GeoChart"
            width="880px"
            height="600px"
            data={data}
            options={options}
        />
    )
}

const ChartsMap = props => {
    const {country, data, selectMap, mapRef} = props

    return (
        <div>
            {data?.length > 0 && (
                <>
                    {(country === 'th' || country === 'all') && (
                        <div className="div-map-all">
                            <MapALL
                                data={data}
                                // zoom={state.zoomMap}
                                // onMap={this.onMap}
                                mapRef={mapRef}
                                scrollData={selectMap}></MapALL>
                        </div>
                    )}
                    {country === 'la' && (
                        <div className="div-map-la">
                            <MapLA data={data} scrollData={selectMap} mapRef={mapRef}></MapLA>
                        </div>
                    )}
                    {country === 'kh' && (
                        <div className="div-map-kh">
                            <MapKH data={data} scrollData={selectMap} mapRef={mapRef}></MapKH>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default ChartsMap
