import React from 'react'

import {Row, Col} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import '../comparison-skeleton.scss'

const FormAreaSkeleton = () => {
    return (
        <Col xl={12} className="form-area">
            <Row>
                <Col xs={12} sm={2} md={2} lg={2} className="mb-2">
                    <div className="label-form">
                        <Skeleton width={125} />
                    </div>
                </Col>
                <Col xs={12} sm={10} md={10} lg={10} className="mb-2">
                    <Skeleton width={735} height={22} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <Row className="d-flex align-items-center">
                        <Col xs={12} sm={2} md={2} lg={2} className="mb-2">
                            <div className="label-form">
                                <Skeleton width={115} />
                            </div>
                        </Col>
                        <Col xs={12} sm={2} md={2} lg={1} className="mb-2">
                            <Skeleton width={111} height={32} />
                        </Col>
                        <Col xs={12} sm={2} md={2} lg={2} className="mb-2">
                            <Skeleton width={246} height={32} />
                        </Col>
                        <Col xs={12} sm={2} md={2} lg={2} className="mb-2">
                            <Skeleton width={246} height={32} />
                        </Col>
                        <Col xs={12} sm={2} md={2} lg={2} className="mb-2">
                            <Skeleton width={246} height={32} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={2} md={2} lg={2} className="mb-2">
                    <div className="label-form">
                        <Skeleton width={84} />
                    </div>
                </Col>
                <Col xs={12} sm={10} md={10} lg={10} className="mb-2">
                    <Skeleton width={718} height={32} />
                </Col>
                <Col xs={12} sm={2} md={2} lg={2} className="mb-2"></Col>
                <Col xs={12} sm={6} md={6} lg={3} className="mb-2">
                    <Skeleton width={376} height={22} />
                </Col>
                <Col xs={12} sm={4} md={4} lg={7} className="mb-2"></Col>
                <Col xs={12} sm={2} md={2} lg={2} className="mb-2">
                    <div className="label-form">
                        <Skeleton width={64} />
                    </div>
                </Col>
                <Col xs={12} sm={10} md={10} lg={10} className="mb-2">
                    <Skeleton width={1177} height={32} />
                </Col>
                <Col xs={12} sm={2} md={2} log={2} className="mb-2  mt-3"></Col>
                <Col xs={6} sm={4} md={3} lg={3} xl={2} className="mb-2 mt-3">
                    <Skeleton width={245} height={37} style={{borderRadius: 8}} />
                </Col>
                <Col xs={6} sm={4} md={3} lg={3} xl={2} className="mb-2 mt-3">
                    <Skeleton width={170} height={37} style={{borderRadius: 8}} />
                </Col>
            </Row>
        </Col>
    )
}

export default FormAreaSkeleton
