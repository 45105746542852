import React from 'react'
import {Row, Col, Button, Card, CardBody, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './inalyze-skeleton-styles.scss'

const CardMapInalyzeDataSkeleton = () => {
    return (
        <Col xl={12}>
            <Card className="card-skeleton-result">
                <CardTitle className="card-title">
                    <div className="container-tab">
                        <Skeleton className="skeleton-tab" />
                    </div>
                    <div className="container-tab">
                        <Skeleton className="skeleton-tab" />
                    </div>
                    <div className="container-tab">
                        <Skeleton className="skeleton-tab" />
                    </div>
                    <div className="container-tab">
                        <Skeleton className="skeleton-tab" />
                    </div>
                    <div className="container-tab">
                        <Skeleton className="skeleton-tab" />
                    </div>
                </CardTitle>
                <CardBody className="card-skeleton-body">
                    <Row style={{width: '100%'}}>
                        <Col xl={5} className="box-map">
                            <Skeleton className="skeleton-map" />
                        </Col>
                        <Col xl={7} className="box-result">
                            <Row className="container-title">
                                <Col xl={4}>
                                    <Skeleton className="skeleton-title" />
                                </Col>
                                <Col xl={8}>
                                    <Row className="box-percent">
                                        <Col xl={11}>
                                            <Skeleton className="skeleton-percent" />
                                        </Col>
                                        <Col xl={1}>
                                            <Skeleton className="skeleton-filter" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="row-data-table">
                                <Col xl={4}>
                                    <Skeleton className="skeleton-section" />
                                </Col>
                                <Col xl={8}>
                                    <Skeleton className="skeleton-percent" />
                                </Col>
                            </Row>
                            <Row className="row-data-table">
                                <Col xl={4}>
                                    <Skeleton className="skeleton-section" />
                                </Col>
                                <Col xl={8}>
                                    <Skeleton className="skeleton-percent" />
                                </Col>
                            </Row>
                            <Row className="row-data-table">
                                <Col xl={4}>
                                    <Skeleton className="skeleton-section" />
                                </Col>
                                <Col xl={8}>
                                    <Skeleton className="skeleton-percent" />
                                </Col>
                            </Row>
                            <Row className="row-data-table">
                                <Col xl={4}>
                                    <Skeleton className="skeleton-section" />
                                </Col>
                                <Col xl={8}>
                                    <Skeleton className="skeleton-percent" />
                                </Col>
                            </Row>
                            <Row className="row-data-table">
                                <Col xl={4}>
                                    <Skeleton className="skeleton-section" />
                                </Col>
                                <Col xl={8}>
                                    <Skeleton className="skeleton-percent" />
                                </Col>
                            </Row>
                            <Row className="row-data-table">
                                <Col xl={4}>
                                    <Skeleton className="skeleton-section" />
                                </Col>
                                <Col xl={8}>
                                    <Skeleton className="skeleton-percent" />
                                </Col>
                            </Row>
                            <Row className="row-data-table">
                                <Col xl={4}>
                                    <Skeleton className="skeleton-section" />
                                </Col>
                                <Col xl={8}>
                                    <Skeleton className="skeleton-percent" />
                                </Col>
                            </Row>
                            <Row className="row-data-table">
                                <Col xl={4}>
                                    <Skeleton className="skeleton-section" />
                                </Col>
                                <Col xl={8}>
                                    <Skeleton className="skeleton-percent" />
                                </Col>
                            </Row>
                            <Row className="row-data-table">
                                <Col xl={4}>
                                    <Skeleton className="skeleton-section" />
                                </Col>
                                <Col xl={8}>
                                    <Skeleton className="skeleton-percent" />
                                </Col>
                            </Row>
                            <Row className="row-data-table">
                                <Col xl={4}>
                                    <Skeleton className="skeleton-section" />
                                </Col>
                                <Col xl={8}>
                                    <Skeleton className="skeleton-percent" />
                                </Col>
                            </Row>
                            <Row className="row-data-table">
                                <Col xl={4}>
                                    <Skeleton className="skeleton-section" />
                                </Col>
                                <Col xl={8}>
                                    <Skeleton className="skeleton-percent" />
                                </Col>
                            </Row>
                            <Row className="row-data-table">
                                <Col xl={4}>
                                    <Skeleton className="skeleton-section" />
                                </Col>
                                <Col xl={8}>
                                    <Skeleton className="skeleton-percent" />
                                </Col>
                            </Row>
                            <Row className="row-data-table">
                                <Col xl={4}>
                                    <Skeleton className="skeleton-section" />
                                </Col>
                                <Col xl={8}>
                                    <Skeleton className="skeleton-percent" />
                                </Col>
                            </Row>
                            <Row className="row-data-table">
                                <Col xl={4}>
                                    <Skeleton className="skeleton-section" />
                                </Col>
                                <Col xl={8}>
                                    <Skeleton className="skeleton-percent" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

export default CardMapInalyzeDataSkeleton
