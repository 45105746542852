import {
  GET_NAVBAR_DRONE_ACTIVE,
  GET_NAVBAR_DRONE_ACTIVE_FAIL,
  GET_NAVBAR_DRONE_ACTIVE_SUCCESS,
  UPDATE_FILTER_DRONE_ACTIVE,
  UPDATE_FILTER_DRONE_ACTIVE_FAIL,
  UPDATE_FILTER_DRONE_ACTIVE_SUCCESS,
} from "./actionTypes";

import * as moment from "moment";
import * as dayjs from "dayjs";

const INIT_STATE = {
  navbar: [],
  type: "year",
  month: 1,
  filter: {
    type: "year",
    month: moment().month() + 1,
    week: moment().week(),
    year: moment().year(),
    country: "all",
    province: [],
    district: [],
    drone: [],
    // id: "f8cad956-2449-4afc-bbc4-358f0b5e9562",
    startDate: moment().subtract(1, "month").date(1).format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  },
};

const navbarDroneActive = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NAVBAR_DRONE_ACTIVE:
      return {
        ...state,
        navbar: action.payload,
        type: action.payload.type,
      };

    case GET_NAVBAR_DRONE_ACTIVE_SUCCESS:
      return {
        ...state,
        navbar: action.payload,
      };

    case GET_NAVBAR_DRONE_ACTIVE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_FILTER_DRONE_ACTIVE:
      return {
        ...state,
        filter: action.payload,
      };
    case UPDATE_FILTER_DRONE_ACTIVE_SUCCESS:
      return {
        ...state,
        filter: action.payload,
      };

    case UPDATE_FILTER_DRONE_ACTIVE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default navbarDroneActive;
