import React, {Component} from 'react'
import ReactApexChart from 'react-apexcharts'

export default class ChartResultAreaData extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const {unit} = this.props

        var nf = new Intl.NumberFormat()

        var formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
        })

        return (
            <>
                <p className="title-chart">{this.props.title || '-'}</p>

                <ReactApexChart
                    options={{
                        chart: {
                            height: 350,
                            toolbar: {
                                show: false,
                            },
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '40%',
                                barHeight: '40%',
                                endingShape: 'rounded',
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        stroke: {
                            show: true,
                            width: 1,
                            colors: ['transparent'],
                        },

                        colors: ['#F1B44C', '#556EE6', '#50A5F1'],
                        xaxis: {
                            //ค่า categories ที่ส่งมา data เป็นอะไร  exsample เป็น เดือน
                            categories: this.props.labels,
                            labels: {
                                style: {
                                    fontSize: '12px',
                                    fontFamily: 'Kanit, sans-serif',
                                    color: '#495057',
                                },
                            },
                        },
                        yaxis: {
                            title: {
                                text: this.props.yaxisTitle,
                                offsetX: 6,
                                offsetY: 0,
                                style: {
                                    fontSize: '12px',
                                },
                            },
                            tickAmount: 5,
                            axisTicks: {
                                show: true,
                                borderType: 'solid',
                                color: '#E0E0E0',
                                height: 6,
                            },
                            axisBorder: {
                                show: true,
                                color: '#E0E0E0',
                            },
                            labels: {
                                style: {
                                    fontSize: 12,
                                },
                                formatter: value => {
                                    return nf.format(value)
                                },
                            },
                            crosshairs: {
                                show: true,
                                position: 'back',
                                stroke: {
                                    color: '#b6b6b6',
                                    width: 1,
                                    dashArray: 0,
                                },
                            },
                        },
                        grid: {
                            show: true,
                            borderColor: '#E0E0E0',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: false,
                                },
                            },
                            yaxis: {
                                lines: {
                                    show: true,
                                },
                            },
                        },
                        fill: {
                            opacity: 1,
                        },
                        tooltip: {
                            shared: true,
                            theme: 'dark',
                            intersect: false,
                            y: {
                                formatter: function (y) {
                                    if (typeof y !== 'undefined') {
                                        return `${formatter.format(y).toLocaleString()}` + ` ${unit}`
                                    }
                                    return y
                                },
                            },
                        },
                        labels: {
                            style: {
                                fontSize: 12,
                            },
                            formatter: value => {
                                return nf.format(value)
                            },
                        },
                        legend: {
                            show: false,
                        },
                    }}
                    series={this?.props?.series || []}
                    type="bar"
                    height={350}
                />
            </>
        )
    }
}
