const userInformation = {
    searchByPilotNamePhoneNumber: 'ค้นหาด้วยชื่อนักบิน, เบอร์โทร, อีเมล',
    searchUser: 'ค้นหาผู้ใช้',
    searchHistory: 'ประวัติการค้นหา',
    searchResults: 'ผลการค้นหา',
    joinedOn: 'เป็นสมาชิกเมื่อ',
    membershipDuration: 'ระยะเวลาสมาชิก',
    ownedDrones: 'โดรนที่เป็นเจ้าของ',
    picture: 'รูปภาพ',
    droneModel: 'โมเดลโดรน',
    usageInHours: 'ชม.การใช้งาน',
    droneInformation: 'ข้อมูลโดรน',
    latestDronePosition: 'ตำแหน่งโดรนล่าสุด',
    latestDronePositionNotFound: 'ไม่พบตำแหน่งโดรนล่าสุด',
    totalFlights: 'จำนวนเที่ยวบินทั้งหมด',
    totalFlightTime: 'ระยะเวลาการบินทั้งหมด',
    averageFlightTime: 'ระยะเวลาการบินเฉลี่ย',
    totalWorkingArea: 'พื้นที่การทำงานทั้งหมด',
    averageWorkingArea: 'พื้นที่การทำงานเฉลี่ย',
    totalSprayingArea: 'พื้นที่ที่การฉีดพ่นทั้งหมด',
    totalSpreadingArea: 'พื้นที่การหว่านทั้งหมด',
    workOverview: 'ภาพรวมงาน',
    flightTimeRanking: 'อันดับเวลาการบิน',
    plotList: 'รายการแปลง',
    regularTime: 'เวลาประจำ',
    workingFields: 'แปลงที่ทำงาน',
    fieldLocation: 'ตำแหน่งแปลง',
    areaRai: 'พื้นที่ (ไร่)',
    usageOverview: 'ภาพรวมการใช้งาน',
    averageSprayingQuantity: 'ปริมาณการฉีดพ่นเฉลี่ย',
    totalFlightTimesMinutes: 'จำนวนระยะเวลาบินทั้งหมด (นาที)',
    averageFlightTimesMinutesMonth: 'จำนวนระยะเวลาบินเฉลี่ย นาที/เดือน',
    averageFlightTimesMinutesDays: 'จำนวนระยะเวลาบินเฉลี่ย นาที/วัน',
    averageFlightTimesWorkingPeriodMinMonth: 'ระยะเวลาการบินเฉลี่ย (ช่วงที่มีการทำงาน) นาที/เดือน',
    averageFlightTimesWorkingPeriodMinDays: 'ระยะเวลาการบินเฉลี่ย (ช่วงที่มีการทำงาน) นาที/วัน',
    flightTime: 'ระยะเวลาการบิน',
    averageFlightTimeDuringWork: 'ระยะเวลาการบินเฉลี่ย (ช่วงที่มีการทำงาน)',
    averageFlightTimeTotal: 'ระยะเวลาการบินเฉลี่ย (ทั้งหมด)',
    flightTimesMinutes: 'ระยะเวลาการบิน (นาที)',
    totalWorkingAreaRaiUnit: 'พื้นที่ทำงานทั้งหมด (ไร่)',
    averageWorkingAreaRaiMonth: 'พื้นที่ทำงานเฉลี่ย (ไร่/เดือน)',
    averageWorkingAreaRaiDays: 'พื้นที่ทำงานเฉลี่ย (ไร่/วัน)',
    averageWorkingAreaWorkingPeriodRaiMonth: 'พื้นที่ทำงานเฉลี่ย (ช่วงที่มีการทำงาน) ไร่/เดือน',
    averageWorkingAreaWorkingPeriodRaiDays: 'พื้นที่ทำงานเฉลี่ย (ช่วงที่มีการทำงาน) ไร่/วัน',
    workingAreas: 'จำนวนพื้นที่การทำงาน',
    averageWorkingAreasDuringWork: ' พื้นที่ทำงานเฉลี่ย (ช่วงที่มีการทำงาน)',
    averageWorkingAreasTotal: 'พื้นที่ทำงานเฉลี่ย (ทั้งหมด)',
    workingAreaRai: 'พื้นที่ทำงาน (ไร่)',
    numberOfFlights: 'จำนวนเที่ยวบิน',
    average: 'ค่าเฉลี่ย',
    speedUsed: 'ความเร็วที่ใช้',
    speedMsUnit: 'ความเร็ว (m/s)',
    median: 'ค่ามัธยฐาน',
    mode: 'ค่าฐานนิยม',
    heightUsed: 'ความสูงที่ใช้',
    heightMUnit: ' ความสูง (m)',
    totalFlightHistory: 'ประวัติการบินทั้งหมด',
    dronesFlightHistory: `ประวัติการบินจากโดรน`,
    flightTimeRanking: 'อันดับเวลาการบิน',
    flightDate: 'วันที่บิน',
    location: 'สถานที่',
    flightTimesHrs: 'ระยะเวลาบิน (น.)',
    averageSpeedMS: 'ความเร็วเฉลี่ย (m/s)',
    workType: 'ประเภทการทำงาน',
    spraying: 'ฉีดพ่น',
    averageSprayingSpreadingQuantity: 'ปริมาณการพ่น/หว่าน เฉลี่ย',
    aviationHistory: 'ประวัติการบิน',
    averageSpreadingQuantity: 'ปริมาณการหว่านเฉลี่ย',
}

export default userInformation
