import React, {Component} from 'react'
import {Container, Row, Col, Button, InputGroup, Input} from 'reactstrap'
import './index.scss'
import iconClose from '../../../assets/images/icon/icon-close.png'
import 'animate.css'
import {withRouter} from 'react-router-dom'
import CardDetailDrone from 'components/DroneiNalyze/Real-time-Monitoring-Detail/CardDetailDrone'

import io from 'socket.io-client'

import 'leaflet/dist/leaflet.css' // sass
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet-rotatedmarker'
import config from 'config'
import RealTimeMapDetailSkeleton from '../Skeleton-iNalyze/Real-time-Monitoring-Detail/RealTimeMapDetailSkeleton'
import {withTranslation} from 'react-i18next'

class MapDroneMonitoringDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            positionOld: null,
            positionNew: null,
            degree: 0,
            positionLatest: null,
            velocity: 0,
            pilotName: '',
            droneModel: '',
            loadingSkeleton: true,
            loadingMapFirst: true,
        }
    }

    componentDidMount() {
        this.setState({loadingSkeleton: true})
    }

    componentDidUpdate() {
        if (this.state.loadingSkeleton) {
            setTimeout(() => {
                this.setState({loadingSkeleton: false})
            }, 3000)
        }

        if (!this.state.loadingSkeleton && this.state.loadingMapFirst) {
            // this.mapInit()
            const map = L.map('map')

            // if (map != null) map.remove()

            L.tileLayer(`${config.google.MAP_VT}`, {
                // zoom: 12,
                // attribution: '© OpenStreetMap'
            }).addTo(map)

            const icon = L.icon({
                iconUrl: `${config.s3.URL}icon-drone-new.svg`,
                iconSize: [32, 32],
            })

            const markers = L.markerClusterGroup({
                // spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                // zoomToBoundsOnClick: false
            })

            const socket = io(`${config.socket.URL}?hardwareID=${this.props.match.params.id}`)
            socket.on('Realtime', async data => {
                var polylines = []
                markers.clearLayers()
                markers.remove()
                map.removeLayer(markers)
                let newPosition = JSON.parse(data)
                this.setState({
                    droneModel: newPosition.hardware_model,
                    pilotName: newPosition.pilot_firstname,
                })
                this.state.positionOld == null
                    ? this.setState({
                          positionOld: newPosition,
                      })
                    : this.setState({
                          positionOld: this.state.positionNew,
                      })

                this.setState({
                    positionNew: newPosition,
                })

                if (this.state.positionOld != null && this.state.positionNew != null) {
                    let degree = await this.calculateDegree(
                        this.state.positionOld.velocity.x ?? 0,
                        this.state.positionOld.velocity.y ?? 0,
                        this.state.positionNew.velocity.x ?? 0,
                        this.state.positionNew.velocity.y ?? 0,
                    )

                    if (parseInt(degree) > 0) {
                        let vx = Math.abs(this.state.positionNew.velocity.x)
                        let vy = Math.abs(this.state.positionNew.velocity.y)

                        this.setState({
                            velocity: vx > vy ? vx : vy,
                        })

                        this.setState({
                            degree,
                        })
                    }
                }

                newPosition.positions.forEach((e, i) => {
                    polylines.push([e.lat, e.lng])

                    if (newPosition.positions.length - 1 == i) {
                        const marker = L.marker([e.lat, e.lng], {
                            icon,
                            rotationOrigin: 'center center',
                            rotationAngle: this.state.degree,
                        })
                        markers.addLayer(marker)

                        this.setState({
                            positionLatest: newPosition,
                        })
                    }
                })
                var polyline = L.polyline(polylines, {color: '#FFB800'}).addTo(map)
                map.fitBounds(polyline.getBounds())
                map.addLayer(markers)
            })
            this.setState({loadingMapFirst: false})
        }
    }

    mapInit() {}

    onClickBack = () => {
        const {history} = this.props
        history.push('/real-time-monitoring')
    }

    calculateDegree = (x1, y1, x2, y2) => {
        const dy = y2 - y1
        const dx = x2 - x1
        let angle = 0

        if (dy > dx) {
            angle = (Math.atan2(dy, dx) * 180) / Math.PI
        } else {
            angle = (Math.atan2(dx, dy) * 180) / Math.PI
        }

        if (angle < 0) {
            angle += 360
        }
        return angle
    }

    render() {
        const {t} = this.props

        return (
            <Row className="container-data-detail">
                <Col lg={10} xl={10} className="col-data-map">
                    <div className="container-map-detail">
                        {this.state.loadingSkeleton ? (
                            <RealTimeMapDetailSkeleton />
                        ) : (
                            <div id="map">
                                <div className="btn-close-map-detail">
                                    <Button
                                        className="btn"
                                        type="button"
                                        color="primary"
                                        onClick={() => this.onClickBack()}>
                                        <img src={iconClose} />
                                        <span className="btn-text">{t('common.close')}</span>
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </Col>
                <Col lg={2} xl={2} className="col-data-detail ">
                    <CardDetailDrone
                        information={{droneModel: this.state.droneModel, pilotName: this.state.pilotName}}
                        data={this.state.positionLatest}
                        velocity={this.state.velocity}
                    />
                </Col>
            </Row>
        )
    }
}
export default withRouter(withTranslation()(MapDroneMonitoringDetail))
