import React from 'react'
import {Row, Col, Button, Card, CardBody, CardTitle} from 'reactstrap'
import iconPlay from '../../../../../assets/images/icon/icon-play.png'
import './index.scss'
import {useParams, useHistory} from 'react-router-dom'

import {withTranslation} from 'react-i18next'

const TableDetail = props => {
    const {t, data} = props

    const {hardwareID, id, droneModel, flightId} = useParams()
    const history = useHistory()

    const onClickPlayFlightHistory = () => {
        history.push(`/drone-information/flight-history/${hardwareID}/${id}/play`, {
            path: 'drone',
            backTo: `/drone-information/flight-history/${hardwareID}/${id}/${droneModel}/detail/${flightId}`,
        })
    }
    return (
        <Card className="card-detail">
            <CardTitle>
                <Row className="row">
                    <Col xl={9} lg={9}>
                        <p className="title-detail">{t('common.details')}</p>
                    </Col>
                    <Col xl={3} lg={3} className="col-btn-play-drone">
                        <Button
                            color="success"
                            className="btn btn-success waves-effect waves-light btn-play-drone"
                            onClick={onClickPlayFlightHistory}>
                            <img src={iconPlay} />
                            <span>{t('droneInformation.playFlightHistory')}</span>
                        </Button>
                    </Col>
                </Row>
            </CardTitle>
            <CardBody>
                <Row className={'row-table  row-table-color'}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <p className="title">{t('droneInformation.flightAt')}</p>
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <span className="value">{data?.data?.dji_record_id || '-'}</span>
                    </Col>
                </Row>
                <Row className={'row-table'}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <p className="title">{t('droneInformation.totalWorkingTime')}</p>
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <span className="value">{data?.data?.totalTime || '-'}</span>{' '}
                        <span>{t('common.minuteUnit')}</span>
                    </Col>
                </Row>

                <Row className={'row-table  row-table-color'}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <p className="title">{t('droneInformation.workingArea')}</p>
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <span className="value">{data?.data?.area || '-'}</span> <span>{t('common.raiUnit')}</span>
                    </Col>
                </Row>
                <Row className={'row-table'}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <p className="title">{t('droneInformation.flightTimes')}</p>
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <span className="value">{data?.data?.totalTime || '-'}</span>{' '}
                        <span>{t('common.minuteUnit')}</span>
                    </Col>
                </Row>

                <Row className={'row-table  row-table-color'}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <p className="title">{t('droneInformation.averageSpeed')}</p>
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <span className="value">{data?.data?.velocity || '-'}</span> <span>m/s</span>
                    </Col>
                </Row>
                <Row className={'row-table'}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <p className="title">{t('droneInformation.averageHeight')}</p>
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <span className="value">{data?.data?.totalTime || '-'}</span> <span>m</span>
                    </Col>
                </Row>

                <Row className={'row-table  row-table-color'}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <p className="title">{t('droneInformation.workingType')}</p>
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <span className="value">{data?.data?.usage_type ?? '-'}</span>
                    </Col>
                </Row>
                <Row className={'row-table'}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <p className="title">{t('droneInformation.totalSubstanceAmount')}</p>
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <span className="value">{data?.data?.used}</span>{' '}
                        <span>{data?.data?.usage_type === 'ฉีดพ่น' ? t('common.literUnit') : t('common.kg')}</span>
                    </Col>
                </Row>

                <Row className={'row-table  row-table-color'}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <p className="title">
                            {t('droneInformation.quantity')}
                            {data?.data?.usage_type}
                            {t('common.average')}
                        </p>
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <span className="value">{data?.data?.usedPerRai}</span>{' '}
                        <span>
                            {data?.data?.usage_type === 'ฉีดพ่น' ? t('common.literRaiUnit') : t('common.kgUnitRaiUnit')}
                        </span>
                    </Col>
                </Row>
                <Row className={'row-table'}>
                    <Col lg={6} xl={6} className="col-title-table">
                        <p className="title">{t('droneInformation.operationPattern')}</p>
                    </Col>
                    <Col lg={6} xl={6} className="col-value-table">
                        <span className="value">
                            {data?.data?.information?.mode == 1
                                ? 'None'
                                : data?.data?.information?.mode == 2
                                ? 'Manual'
                                : data?.data?.information?.mode == 3
                                ? 'Manual Plus'
                                : data?.data?.information?.mode == 4
                                ? 'Auto'
                                : data?.data?.information?.usage_type == 5
                                ? 'Auto'
                                : 'None'}
                        </span>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default withTranslation()(TableDetail)
