const inalyze = {
    inalyze: 'iNalyze',
    comparison: 'การเปรียบเทียบ',
    droneActive: 'Drone (Active)',
    numberOfDrones: 'จำนวนโดรน',
    flightHours: 'ชั่วโมงการทำงาน',
    workingDays: 'จำนวนวันทำงาน',
    workingArea: 'พื้นที่ทำงาน',
    averageSprayingArea: 'พื้นที่ฉีดพ่นเฉลี่ย',
    totalFlightHours: 'ชั่วโมงการทำงานทั้งหมด',
    totalWorkingDays: 'จำนวนวันทำงานทั้งหมด',
    allWorkingArea: 'พื้นที่ทำงานทั้งหมด',
    totalWorkingArea: 'พื้นที่ทำงานทั้งหมด',
    compareByArea: 'เปรียบเทียบตามพื้นที่',
    compareByTime: 'เปรียบเทียบตามช่วงเวลา',
    selectComparison: 'เลือกการเปรียบเทียบ',
    comparisonQuantity: 'จำนวนเปรียบเทียบ',
    selectTimeRange: 'เลือกช่วงเวลา',
    pleaseSelectTimeRange: 'กรุณาเลือกช่วงเวลา',
    pleaseSelectYear: 'กรุณาเลือกปี',
    firstHalfYear: 'ครึ่งปีแรก (ม.ค - มิ.ย.)',
    secondHalfYear: 'ครึ่งปีหลัง (ก.ค - ธ.ค.)',
    compare: 'เปรียบเทียบ',
    comparisonSelectionResult: 'ผลลัพธ์การเลือกเปรียบเทียบ',
    closeData: 'ปิดข้อมูล',
    openData: 'เปิดข้อมูล',
    cumulativeDrones: 'โดรนสะสม',
    totalFlightTime: 'ระยะเวลาการบินทั้งหมด',
    totalFlightTimeHrUnit: 'ระยะเวลาการบินทั้งหมด (ชั่วโมง)',
    averageFlightTime: 'ระยะเวลาการบินเฉลี่ย',
    averageFlightTimeHrDroneUnit: 'ระยะเวลาการบินเฉลี่ย (ชั่วโมง/ลำ)',
    averageLine: 'เส้นค่าเฉลี่ย',
    workingDay: 'จำนวนวันทำงาน',
    workingDayDayUnit: 'จำนวนวันทำงาน (วัน)',
    averageWorkingDays: 'จำนวนวันทำงานเฉลี่ย',
    averageWorkingDaysHourDayUnit: 'จำนวนวันทำงานเฉลี่ย (ชั่วโมง/วัน)',
    totalWorkingAreas: 'พื้นที่การทำงานทั้งหมด',
    totalWorkingAreasHourUnit: 'พื้นที่การทำงานทั้งหมด (ชั่วโมง)',
    averageWorkingAreas: 'พื้นที่การทำงานเฉลี่ย',
    averageWorkingAreasRaiDroneUnit: 'พื้นที่การทำงานเฉลี่ย (ไร่/ลำ)',
    totalNumberOfFlights: 'จำนวนเที่ยวบินทั้งหมด',
    totalFlight: 'จำนวนเที่ยวบินทั้งหมด',

    totalNumberOfFlightsFlightUnit: 'จำนวนเที่ยวบินทั้งหมด (เที่ยวบิน)',
    averageNumberOfFlights: 'จำนวนเที่ยวบินเฉลี่ย',
    averageNumberOfFlightsTimeDroneUnit: 'จำนวนเที่ยวบินเฉลี่ย (เที่ยว/ลำ)',
    averageSprayingQuantity: 'ปริมาณการฉีดพ่นเฉลี่ย',
    averageSprayingQuantityLiterRaiUnit: 'ปริมาณการฉีดพ่นเฉลี่ย (ลิตร/ไร่)',
    averageSpeed: 'ความเร็วเฉลี่ย',
    averageSpeedMsUnit: 'ความเร็วเฉลี่ย (m/s)',
    averageHeight: 'ความสูงเฉลี่ย',
    averageHeightMUnit: 'ความสูงเฉลี่ย (m)',
    dataForComparison: 'ข้อมูลที่จะใช้เทียบ',
    pleaseSelectDataForComparison: 'กรุณาเลือกข้อมูลที่จะใช้เทียบ',
    selectTimeRangeGroup: 'เลือกช่วงเวลากลุ่มที่',
    flightDuration: 'ระยะเวลาการบิน',
    numberOfWorkingDays: 'จำนวนวันทำงาน',
    compareWorkingAreas: 'พื้นที่การทำงาน',
    compareNumberOfFlights: 'จำนวนเที่ยวบิน',
    compareSprayingQuantity: 'ปริมาณการฉีดพ่น',
    compareSpeed: 'ความเร็ว',
    compareHeight: 'ความสูง',
    pleaseSelectYear: 'กรุณาเลือกปี',
    duringThisTimeTheSelectionHasBeenMade: 'ช่วงเวลานี้ ได้ทำการเลือกแล้ว',
    firstYear: 'ปีแรก',
    secondYear: 'ปีหลัง',
    droneInformationAndUseInEachArea: 'ข้อมูลโดรนและการใช้งานแต่ละพื้นที่',
    comparisonOfDataByAreaAndTimePeriod: 'การเปรียบเทียบข้อมูลตามพื้นที่ และ ช่วงเวลา',
    totalWorkingDrones: 'จำนวนโดรนที่มีการทำงาน',
    totalDrones: 'โดรนทั้งหมด',
}
export default inalyze
