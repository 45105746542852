import { GET_HEIGHT_DRONE_ACTIVE, GET_HEIGHT_DRONE_ACTIVE_SUCCESS, GET_HEIGHT_DRONE_ACTIVE_FAIL } from "./actionTypes";

export const getHeightDroneActive = data => ({
  type: GET_HEIGHT_DRONE_ACTIVE,
  payload: data,
});

export const getHeightDroneActiveSuccess = navbar => ({
  type: GET_HEIGHT_DRONE_ACTIVE_SUCCESS,
  payload: navbar,
});

export const getHeightDroneActiveFail = error => ({
  type: GET_HEIGHT_DRONE_ACTIVE_FAIL,
  payload: error,
});
