import React from 'react'
import {withTranslation} from 'react-i18next'
import {Card, CardBody, Col, Row, CardImgOverlay, CardImg, CardText, CardTitle} from 'reactstrap'

import backGround from '../../../../assets/images/icon/IMAGE.png'
import Drone from '../../../../assets/images/icon/Rectangle46.png'

import SkeletonDataDroneInformation from 'components/DroneiNalyze/Skeleton-DroneInformation/Overall/SkeletonDataDroneInformation'

import './index.scss'
const DataDrone = props => {
    const {t, i18n, data, loadingSkeleton} = props

    return (
        <>
            {loadingSkeleton ? (
                <SkeletonDataDroneInformation />
            ) : (
                <Col xl={4}>
                    <Card className="card-img-drone">
                        <CardImg className="img-fluid" src={backGround} alt="Skote" />
                        <CardImgOverlay>
                            <Row className="d-flex align-items-start mb-3">
                                <Col xs="5" className="align-self-start col-img-drone">
                                    <img
                                        src={`${
                                            data?.drone_model_id
                                                ? `https://s3dev-gramick.sgp1.cdn.digitaloceanspaces.com/kubota/drone_model/${data?.drone_model_id}.png`
                                                : Drone
                                        }`}
                                        height={`${!data?.drone_model_id && '130px'}`}
                                        width={`${!data?.drone_model_id && '190px'}`}
                                        alt=""
                                        className={`${!data?.drone_model_id && 'img-fluid'} img-drone`}
                                    />
                                </Col>
                                <Col xs="7" className="col-drone-name">
                                    <CardTitle className="mt-0">{data?.drone_name || '-'}</CardTitle>
                                    <CardText style={{fontFamily: i18n.language === 'th' ? 'Kanit' : 'Inter'}}>
                                        Kubota S/N : {data?.uav_sn || '-'}
                                    </CardText>
                                    <CardText style={{fontFamily: i18n.language === 'th' ? 'Kanit' : 'Inter'}}>
                                        {t('droneInformation.usageDuration')}: {data?.lifetime || '-'}{' '}
                                        {t('common.dayUnit')}
                                    </CardText>
                                </Col>
                            </Row>
                        </CardImgOverlay>
                    </Card>

                    <Card className="card-data-drone">
                        <CardBody>
                            <CardTitle
                                tag="h5"
                                className="card-title mb-3 me-2"
                                style={{fontFamily: i18n.language === 'th' ? 'Kanit' : 'Inter'}}>
                                {t('droneInformation.droneOwnerInformation')}
                            </CardTitle>

                            <div className="row type-drone box-table ">
                                <table className="table align-middle white-bg table-detail-drone">
                                    <tbody
                                        className="table-body"
                                        style={{fontFamily: i18n.language === 'th' ? 'Kanit' : 'Inter'}}>
                                        <tr scope="row">
                                            <td style={{width: '30%'}}> {t('droneInformation.pilot')}</td>
                                            <td style={{width: '70%'}}>
                                                {data?.user?.first_name || '-'} {data?.user?.last_name || '-'}
                                            </td>
                                        </tr>
                                        <tr scope="row">
                                            <td style={{width: '30%'}}>{t('droneInformation.phoneNumber')}</td>
                                            <td style={{width: '70%'}}>{data?.user?.mobile_phone_no || '-'}</td>
                                        </tr>
                                        <tr scope="row">
                                            <td style={{width: '30%'}}>{t('droneInformation.email')}</td>
                                            <td style={{width: '70%'}}>{data?.user?.email_address || '-'}</td>
                                        </tr>
                                        <tr scope="row">
                                            <td style={{width: '30%'}}>{t('droneInformation.address')}</td>
                                            <td style={{width: '70%'}}>
                                                {data?.user?.address || '-'} {data?.user?.village_building || '-'}{' '}
                                                {i18n.language === 'th'
                                                    ? data?.user?.mas_subdistricts.name_th || '-'
                                                    : data?.user?.mas_subdistricts.name_en || '-'}{' '}
                                                {i18n.language === 'th'
                                                    ? data?.user?.mas_districts.name_th || '-'
                                                    : data?.user?.mas_districts.name_en || '-'}{' '}
                                                {i18n.language === 'th'
                                                    ? data?.user?.mas_provinces.prov_name_th || '-'
                                                    : data?.user?.mas_provinces.prov_name_en || '-'}{' '}
                                                {data?.user?.mas_subdistricts.zip_code || '-'}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            )}
        </>
    )
}

export default withTranslation()(DataDrone)
