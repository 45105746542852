import React from 'react'
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import './skeleton-droneInformation.scss'

const SkeletonSearchMachine = () => {
    return (
        <div className="skeleton-search-machine">
            <Skeleton className="skeleton-text" width={139} height={30} />
            <Skeleton width={985} height={48} />
        </div>
    )
}

export default SkeletonSearchMachine
