import React from 'react'
import dayjs from 'dayjs'
import {useRecoilValue} from 'recoil'
import {filterDashboardState} from 'store/atoms/dashboard'
import {Container, Row, Col} from 'reactstrap'
import {withTranslation} from 'react-i18next'

import DashboardMap from 'components/Dashboard/DashboardMap'
import Cumulative from 'components/Dashboard/Cumulative'
import SearchFilterDashboard from 'components/Dashboard/SearchFilterDashboard'
import DroneTotalAreaAndAverageFlight from 'components/Dashboard/DroneTotalAreaAndAverageFlight/DroneTotalAreaAndAverageFlight'
import DashboardFlightHistory from 'components/Dashboard/DashboardFlightHistory'
import DroneOverview from 'components/Dashboard/Overview/DroneOverview'
import DroneType from 'components/Dashboard/DroneType/DroneType'
import WorkingDrone from 'components/Dashboard/WorkingDrone'

import './index.scss'

const Dashboard = props => {
    const {t} = props
    const searchFilterDashboard = useRecoilValue(filterDashboardState)

    return (
        <div className="page-content">
            <Container fluid>
                <SearchFilterDashboard />

                <div id="page-content">
                    <Row>
                        <Col xl="12">
                            <Row>
                                <Cumulative />
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <DashboardMap />
                    </Row>
                    {/* ---------------------------Drone Overview----------------------------- */}
                    <Row>
                        <Col xl="12">
                            <Row>
                                <div className="text-center div-drone-overview ">
                                    <h3 className="title-drone-overview">{t('dashboard.droneOverview')}</h3>
                                    <p className="text-data-at">
                                        {t('common.dataAt') + ':'}{' '}
                                        <span className="text-data-at">
                                            {dayjs(searchFilterDashboard?.start_date).format('DD MMMM YYYY')} -
                                            {dayjs(searchFilterDashboard?.end_date).format('DD MMMM YYYY')}
                                        </span>
                                    </p>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <DroneOverview />
                    </Row>

                    <Row>
                        <DroneType />

                        <WorkingDrone />
                    </Row>
                    <Row>
                        <DroneTotalAreaAndAverageFlight />
                    </Row>

                    <Row>
                        <Col xl="12">
                            <Row>
                                <div className="text-center div-drone-user-overview">
                                    <h3 className="title">{t('dashboard.userOverview')}</h3>
                                    <p className="text-data-at">
                                        {t('common.dataAt') + ':'}{' '}
                                        <span className="text-data-at">
                                            {dayjs(searchFilterDashboard?.start_date).format('DD MMMM YYYY')} -
                                            {dayjs(searchFilterDashboard?.end_date).format('DD MMMM YYYY')}
                                        </span>
                                    </p>
                                </div>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <DashboardFlightHistory />
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default withTranslation()(Dashboard)
