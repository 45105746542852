import React, {useEffect, useState} from 'react'
import {Row, Col} from 'reactstrap'
import {withTranslation} from 'react-i18next'

const OverviewDetail = props => {
    const {data, t, searchFilter, i18n} = props

    const [detailCard, setDetailCard] = useState([])

    useEffect(() => {
        setData()
    }, [data, i18n.language])

    const setData = () => {
        let nf = new Intl.NumberFormat()

        const newDetail = []

        if (data?.flights) {
            newDetail.push({
                title: t(`dashboard.totalNumberOfFlights`),
                value: nf.format(data?.flights?.total_flights),
                badgeValue: data?.flights?.flights_percentage,
                unit: t(`common.flightTimeUnit`),
                color:
                    parseFloat(data?.flights?.flights_percentage) > 0
                        ? 'success'
                        : parseFloat(data?.flights?.flights_percentage) < 0
                        ? 'danger'
                        : 'warning',
                mark:
                    parseFloat(data?.flights?.flights_percentage) > 0
                        ? '+'
                        : parseFloat(data?.flights?.flights_percentage) < 0
                        ? ''
                        : '',
            })
        }

        if (data?.hours) {
            newDetail.push({
                title: t(`dashboard.totalFlightHours`),
                value: nf.format(data?.hours?.total_time),
                badgeValue: data?.hours?.time_percentage,
                unit: t(`common.hourUnit`),
                color:
                    parseFloat(data?.hours?.time_percentage) > 0
                        ? 'success'
                        : parseFloat(data?.hours?.time_percentage) < 0
                        ? 'danger'
                        : 'warning',
                mark:
                    parseFloat(data?.hours?.time_percentage) > 0
                        ? '+'
                        : parseFloat(data?.hours?.time_percentage) < 0
                        ? ''
                        : '',
            })
        }

        if (data?.task_area) {
            newDetail.push({
                title: t(`dashboard.totalWorkAreas`),
                value: nf.format(data?.task_area?.total_task_area),
                badgeValue: data?.task_area?.task_area_percentage,
                unit: t('common.raiUnit'),
                color:
                    parseFloat(data?.task_area?.task_area_percentage) > 0
                        ? 'success'
                        : parseFloat(data?.task_area?.task_area_percentage) < 0
                        ? 'danger'
                        : 'warning',
                mark:
                    parseFloat(data?.task_area?.task_area_percentage) > 0
                        ? '+'
                        : parseFloat(data?.task_area?.task_area_percentage) < 0
                        ? ''
                        : '',
            })
        }

        if (data?.drones) {
            newDetail.push({
                title: t(`dashboard.numberOfWorkingDrones`),
                value: nf.format(data?.drones?.total_drones),
                badgeValue: data?.drones?.drones_percentage,
                unit: t(`common.droneUnit`),
                color:
                    parseFloat(data?.drones?.drones_percentage) > 0
                        ? 'success'
                        : parseFloat(data?.drones?.drones_percentage) < 0
                        ? 'danger'
                        : 'warning',
                mark:
                    parseFloat(data?.drones?.drones_percentage) > 0
                        ? '+'
                        : parseFloat(data?.drones?.drones_percentage) < 0
                        ? ''
                        : '',
            })
        }

        setDetailCard(newDetail)
    }

    return (
        <Row>
            {detailCard?.length > 0 &&
                detailCard?.map((item, index) => (
                    <Col lg="6" className="mb-2" key={index}>
                        <div
                            className="x-2 py-4 border"
                            style={{
                                borderRadius: 8,

                                marginLeft: index % 2 == 0 ? 0 : -8,
                            }}>
                            <div className="d-flex align-items-center mb-3 px-3">
                                <h5
                                    className=" mb-0"
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                        paddingLeft: 15,
                                        color: '#495057',
                                    }}>
                                    {item.title}
                                </h5>
                            </div>
                            <div
                                className="text-muted"
                                style={{
                                    paddingLeft: 15,
                                }}>
                                <h3
                                    className="pb-1 px-3"
                                    style={{
                                        fontSize: 24,
                                        fontWeight: 700,
                                        fontFamily: 'Inter',
                                        color: '#000000',
                                        paddingLeft: 15,
                                    }}>
                                    {item?.value}{' '}
                                    <span
                                        className="text-muted "
                                        style={{
                                            fontSize: 13,
                                            fontWeight: 300,
                                            fontFamily: 'Kanit',
                                            color: '#595757',
                                        }}>
                                        {item?.unit}
                                    </span>
                                </h3>
                                {searchFilter?.type !== 'period' && (
                                    <div className="d-flex border-top pt-3 px-3">
                                        <span
                                            className={'badge badge-soft-' + item.color + ' font-size-12'}
                                            style={{
                                                fontSize: 11,
                                                fontWeight: 600,
                                                fontFamily: 'Inter',
                                            }}>
                                            {item?.mark} {item?.badgeValue}%
                                        </span>
                                        <span
                                            className="ms-2 text-truncate"
                                            style={{
                                                fontSize: 13,
                                                fontWeight: 400,
                                                fontFamily: 'Kanit',
                                                color: '#79829C',
                                            }}>
                                            {searchFilter?.type === 'year' && t(`common.lastYearAgo`)}
                                            {searchFilter?.type === 'month' && t(`common.lastMonthAgo`)}
                                            {searchFilter?.type === 'week' && t(`common.lastWeekAgo`)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                ))}
        </Row>
    )
}

export default withTranslation()(OverviewDetail)
