import React from 'react'
import {Row, Col, Card, CardTitle, CardBody} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './realtime-detail-skeleton.scss'
const CardDetailDroneSkeleton = () => {
    return (
        <div className="card-detail-skeleton">
            <div className="box-title">
                <Skeleton width={100} height={20} />
            </div>
            <Row className="box-card-data">
                <Col xl={2} style={{padding: 0}}>
                    <Skeleton className="box-mode" />
                </Col>
                <Col xl={10}>
                    <Skeleton />

                    <Skeleton />
                </Col>
            </Row>
            <Row className="box-card-data">
                <Col xl={2} style={{padding: 0}}>
                    <Skeleton className="box-mode" />
                </Col>
                <Col xl={10}>
                    <Skeleton />

                    <Skeleton />
                </Col>
            </Row>
            <Row className="box-card-data">
                <Col xl={2} style={{padding: 0}}>
                    <Skeleton className="box-mode" />
                </Col>
                <Col xl={10}>
                    <Skeleton />

                    <Skeleton />
                </Col>
            </Row>
            <Row className="box-card-data">
                <Col xl={2} style={{padding: 0}}>
                    <Skeleton className="box-mode" />
                </Col>
                <Col xl={10}>
                    <Skeleton />

                    <Skeleton />
                </Col>
            </Row>
            <Row className="box-card-data">
                <Col xl={2} style={{padding: 0}}>
                    <Skeleton className="box-mode" />
                </Col>
                <Col xl={10}>
                    <Skeleton />

                    <Skeleton />
                </Col>
            </Row>
            <Row className="box-card-data">
                <Col xl={2} style={{padding: 0}}>
                    <Skeleton className="box-mode" />
                </Col>
                <Col xl={10}>
                    <Skeleton />

                    <Skeleton />
                </Col>
            </Row>
            <Row className="box-card-data">
                <Col xl={2} style={{padding: 0}}>
                    <Skeleton className="box-mode" />
                </Col>
                <Col xl={10}>
                    <Skeleton />

                    <Skeleton />
                </Col>
            </Row>
        </div>
    )
}

export default CardDetailDroneSkeleton
