import React from 'react'
import {Row, Col, Card, CardBody, Table} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'

import '../skeleton-droneInformation.scss'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonFlightHistoryDroneInformation = () => {
    return (
        <Col xl={12}>
            <Card
                style={{
                    borderRadius: 8,
                    height: 600,
                }}>
                <CardBody>
                    <div>
                        <div className=" me-3" style={{paddingLeft: 20, paddingTop: 10}}>
                            <Skeleton width={170} height={34} />
                        </div>
                        <div style={{marginTop: 8, marginLeft: 20}}>
                            <Skeleton width={140} height={23} />
                        </div>
                    </div>
                    <Row style={{marginTop: 24}}>
                        <Col lg="3">
                            <div className="d-flex">
                                <div className="me-2" style={{paddingLeft: 20}}>
                                    <Skeleton width={115} height={19} />
                                </div>
                            </div>
                            <div
                                className="type-drone"
                                style={{
                                    paddingTop: 20,
                                    paddingLeft: 20,
                                }}>
                                <Table className="table-nowrap">
                                    <tbody>
                                        <tr>
                                            <td style={{width: 46, height: 47}}>
                                                <Skeleton width={20} height={17} />
                                            </td>
                                            <td style={{width: 83, height: 47}}>
                                                <Skeleton width={40} height={17} />
                                            </td>
                                            <td style={{display: 'flex', paddingLeft: 20, width: 113, height: 47}}>
                                                <Skeleton width={30} height={17} style={{marginRight: 8}} />
                                                <Skeleton width={20} height={17} />
                                            </td>

                                            <td style={{width: 114, height: 47}}>
                                                <div style={{display: 'flex', margin: 0, padding: 0}}>
                                                    <Skeleton
                                                        style={{marginLeft: 8, marginRight: 4}}
                                                        width={40}
                                                        height={17}
                                                    />
                                                    <Skeleton width={10} height={17} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: 46, height: 47}}>
                                                <Skeleton width={20} height={17} />
                                            </td>
                                            <td style={{width: 83, height: 47}}>
                                                <Skeleton width={40} height={17} />
                                            </td>
                                            <td style={{display: 'flex', paddingLeft: 20, width: 113, height: 47}}>
                                                <Skeleton width={30} height={17} style={{marginRight: 8}} />
                                                <Skeleton width={20} height={17} />
                                            </td>

                                            <td style={{width: 114, height: 47}}>
                                                <div style={{display: 'flex', margin: 0, padding: 0}}>
                                                    <Skeleton
                                                        style={{marginLeft: 8, marginRight: 4}}
                                                        width={40}
                                                        height={17}
                                                    />
                                                    <Skeleton width={10} height={17} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: 46, height: 47}}>
                                                <Skeleton width={20} height={17} />
                                            </td>
                                            <td style={{width: 83, height: 47}}>
                                                <Skeleton width={40} height={17} />
                                            </td>
                                            <td style={{display: 'flex', paddingLeft: 20, width: 113, height: 47}}>
                                                <Skeleton width={30} height={17} style={{marginRight: 8}} />
                                                <Skeleton width={20} height={17} />
                                            </td>

                                            <td style={{width: 114, height: 47}}>
                                                <div style={{display: 'flex', margin: 0, padding: 0}}>
                                                    <Skeleton
                                                        style={{marginLeft: 8, marginRight: 4}}
                                                        width={40}
                                                        height={17}
                                                    />
                                                    <Skeleton width={10} height={17} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: 46, height: 47}}>
                                                <Skeleton width={20} height={17} />
                                            </td>
                                            <td style={{width: 83, height: 47}}>
                                                <Skeleton width={40} height={17} />
                                            </td>
                                            <td style={{display: 'flex', paddingLeft: 20, width: 113, height: 47}}>
                                                <Skeleton width={30} height={17} style={{marginRight: 8}} />
                                                <Skeleton width={20} height={17} />
                                            </td>

                                            <td style={{width: 114, height: 47}}>
                                                <div style={{display: 'flex', margin: 0, padding: 0}}>
                                                    <Skeleton
                                                        style={{marginLeft: 8, marginRight: 4}}
                                                        width={40}
                                                        height={17}
                                                    />
                                                    <Skeleton width={10} height={17} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width: 46, height: 47}}>
                                                <Skeleton width={20} height={17} />
                                            </td>
                                            <td style={{width: 83, height: 47}}>
                                                <Skeleton width={40} height={17} />
                                            </td>
                                            <td style={{display: 'flex', paddingLeft: 20, width: 113, height: 47}}>
                                                <Skeleton width={30} height={17} style={{marginRight: 8}} />
                                                <Skeleton width={20} height={17} />
                                            </td>

                                            <td style={{width: 114, height: 47}}>
                                                <div style={{display: 'flex', margin: 0, padding: 0}}>
                                                    <Skeleton
                                                        style={{marginLeft: 8, marginRight: 4}}
                                                        width={40}
                                                        height={17}
                                                    />
                                                    <Skeleton width={10} height={17} />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col lg="9">
                            <Skeleton width={1127} height={450} />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

export default SkeletonFlightHistoryDroneInformation
