import React, {useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {Button, TabContent, TabPane, CardTitle} from 'reactstrap'

import dayjs from 'dayjs'
import 'dayjs/locale/th'

import WeekPicker from '../../component/weekPicker'
import MonthPicker from '../../component/monthPicker'
import YearPicker from '../../component/yearPicker'

import './styles/select-navbar.scss'
import {buttonFilterTimeRange} from 'constants/lookup'
import {useRecoilState, useSetRecoilState} from 'recoil'
import {filterUserInformationState, loadingUserInformationState} from 'store/atoms/user-information'

const SelectNavBar = props => {
    const {t, i18n} = props

    const [activeTab, setActiveTab] = useState('year')
    const [date, setDate] = useState(dayjs())
    const [filterUserInformation, setFilterUserInformation] = useRecoilState(filterUserInformationState)
    const setLoading = useSetRecoilState(loadingUserInformationState)

    const toggleTab = tab => {
        setActiveTab(tab)
    }

    const handleChangeTime = date => {
        setDate(date)
    }

    const handleChangeSubmit = () => {
        let params = {
            ...filterUserInformation,
            type: activeTab,
        }

        if (activeTab === 'year') {
            params = {
                ...params,
                week: dayjs(date).week(),
                month: dayjs(date).month() + 1,
                year: dayjs(date).year(),
                start_date: dayjs(date).startOf('year').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('year').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'month') {
            params = {
                ...params,
                week: dayjs(date).week(),
                month: dayjs(date).month() + 1,
                year: dayjs(date).year(),
                start_date: dayjs(date).startOf('month').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('month').format('YYYY-MM-DD'),
            }
        }

        if (activeTab === 'week') {
            params = {
                ...params,
                week: dayjs(date).week(),
                month: dayjs(date).month() + 1,
                year: dayjs(date).year(),
                start_date: dayjs(date).startOf('week').format('YYYY-MM-DD'),
                end_date: dayjs(date).endOf('week').format('YYYY-MM-DD'),
            }
        }

        setFilterUserInformation(params)
        setLoading(cur => ({...cur, work_overview: true, flight_time_ranking: true}))
    }

    return (
        <CardTitle className="card-title-select-navbar ">
            <div className="col-sm-auto ">
                <h4 className="text-select-date">{t('common.selectDate')}</h4>
            </div>
            <div className={'col-sm-auto box-btn-date'}>
                <div className="row-sm-auto button-items">
                    {buttonFilterTimeRange?.map((item, index) => {
                        return (
                            item.value != 'quarter' &&
                            item.value != 'halfYear' &&
                            item.value != 'period' && (
                                <Button
                                    key={index}
                                    color="secondary"
                                    outline
                                    style={{
                                        fontFamily: 'Kanit',
                                        fontWeight: 400,
                                        fontSize: 14,
                                        borderColor: '#E2E2E2',
                                        borderRadius: 8,
                                        color: activeTab == item.value ? '#fff' : '#495057',
                                        backgroundColor: activeTab == item.value && '#79829C',
                                    }}
                                    onClick={() => toggleTab(item.value)}>
                                    {i18n.language === 'en' ? item.title_en : item.title_th}
                                </Button>
                            )
                        )
                    })}
                </div>
            </div>

            <div className="col-sm-auto box-date ">
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="week">
                        <WeekPicker value={date} onChange={handleChangeTime} />
                    </TabPane>
                    <TabPane tabId="month" id="v-pills-payment" role="tabpanel" aria-labelledby="v-pills-payment-tab">
                        <MonthPicker value={date} onChange={handleChangeTime} />
                    </TabPane>
                    <TabPane tabId="year" id="v-pills-confir" role="tabpanel">
                        <YearPicker value={date} onChange={handleChangeTime} />
                    </TabPane>
                </TabContent>
            </div>

            <div className="col-sm-auto d-flex box-date">
                <button onClick={() => handleChangeSubmit()} type="button" className="btn btn-primary w-xs btn-submit">
                    {t('common.searchInformation')}
                </button>
            </div>
        </CardTitle>
    )
}

export default withTranslation()(SelectNavBar)
