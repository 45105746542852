import { GET_PROVINCE_SUCCESS, GET_PROVINCE_FAIL, GET_PROVINCE } from "./actionTypes";

const INIT_STATE = {
  province: [],
};

const province = (state = INIT_STATE, action) => {
  // console.log("action",action.payload)
  switch (action.type) {
    case GET_PROVINCE_SUCCESS:
      return {
        ...state,
        province: action.payload,
      };

    case GET_PROVINCE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default province;
