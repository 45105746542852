import { GET_SPEED_DRONE_ACTIVE, GET_SPEED_DRONE_ACTIVE_SUCCESS, GET_SPEED_DRONE_ACTIVE_FAIL } from "./actionTypes";

export const getSpeedDroneActive = data => ({
  type: GET_SPEED_DRONE_ACTIVE,
  payload: data,
});

export const getSpeedDroneActiveSuccess = navbar => ({
  type: GET_SPEED_DRONE_ACTIVE_SUCCESS,
  payload: navbar,
});

export const getSpeedDroneActiveFail = error => ({
  type: GET_SPEED_DRONE_ACTIVE_FAIL,
  payload: error,
});
