import React, {useEffect, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {Col, Card, CardBody, Row} from 'reactstrap'
import {useRecoilState, useRecoilValue} from 'recoil'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Text,
    LineChart,
    Line,
} from 'recharts'
import './style/working-drone.scss'

import barChartIcon from '../../assets/images/icon/IconBar.png'
import IconBar2 from '../../assets/images/icon/Icon-Bar2.png'
import lineChartIcon from '../../assets/images/icon/line-chart-up-02.png'
import IconLine2 from '../../assets/images/icon/IconLine2.png'

import WorkingDroneSkeleton from './CardSkeleton/WorkingDroneSkeleton'

import {filterDashboardState, loadingDashboardState} from 'store/atoms/dashboard'
import {getWorkingDrone} from 'services/skc/new-dashboard'
import i18n from 'i18n'

const WorkingDrone = props => {
    const {t} = props

    const searchFilter = useRecoilValue(filterDashboardState)
    const [loading, setLoading] = useRecoilState(loadingDashboardState)

    const [tab, setTab] = useState('bar')
    const [labels, setLabels] = useState([])
    const [max, setMax] = useState(0)
    const [data, setData] = useState([])
    const [dataMonth, setDataMonth] = useState([])
    const [count, setCount] = useState(0)
    const [skeleton, setSkeleton] = useState(true)
    const [language, setLanguage] = useState(i18n.language)

    useEffect(() => {
        setChartTypeMonth()
    }, [labels])

    useEffect(() => {
        fetchWorkingDrone(searchFilter)
    }, [])

    useEffect(() => {
        if (loading.working_drone) {
            fetchWorkingDrone(searchFilter)
            setLoading(cur => ({...cur, working_drone: false}))
        }
    }, [loading?.working_drone])

    useEffect(() => {
        if (i18n.language !== language) {
            fetchWorkingDrone(searchFilter)
            setLanguage(i18n.languages)
        }
    }, [i18n.language])

    const fetchWorkingDrone = async params => {
        try {
            setSkeleton(true)
            const response = await getWorkingDrone(params)

            if (response.status_code === 200 && response?.results?.labels?.length > 0) {
                setData(response?.results?.series)
                setLabels(response?.results?.labels)
                setMax(response?.results?.max)
            }
        } catch (error) {
            setData([])
            setLabels([])
            setMax(0)
        } finally {
            setSkeleton(false)
        }
    }

    const handleChangeTab = selectTab => {
        setTab(selectTab)
    }

    const CustomTooltip = ({active, payload, label}) => {
        const tooltip = {
            backgroundColor: '#000',
            opacity: '0.8',
            borderRadius: '4px',
            paddingTop: '8px',
        }

        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip " style={tooltip}>
                    <p
                        style={{
                            textAlign: 'center',
                            color: '#fff',
                            fontSize: 13,
                            fontWeight: 400,
                        }}>
                        {label}
                    </p>
                    <div
                        style={{
                            marginTop: -10,
                            marginBottom: 8,
                            borderTopWidth: '1px',
                            borderTopColor: '#404040',
                            borderTopStyle: 'solid',
                        }}
                    />
                    {payload.map((pld, key) => (
                        <div
                            key={key}
                            className="d-flex align-items-start "
                            style={{paddingLeft: 10, paddingRight: 10}}>
                            <div className="me-2">
                                <i className="mdi mdi-square" style={{color: pld.fill}} />
                            </div>
                            <div className="flex-grow-1">
                                <div className="text-muted">
                                    <p
                                        className="mb-0"
                                        style={{
                                            color: '#fff',
                                            fontSize: 12,
                                            fontWeight: 500,
                                            fontFamily: 'Inter',
                                        }}>
                                        {' '}
                                        {pld.dataKey}
                                    </p>
                                </div>
                            </div>

                            <div className="ml-2">
                                <p
                                    className=""
                                    style={{
                                        color: '#fff',
                                        marginLeft: 40,
                                        fontSize: 13,
                                        fontWeight: 600,
                                        fontFamily: 'Inter',
                                    }}>
                                    {pld.value}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            )
        }

        return null
    }

    const CustomTooltipLine = ({active, payload, label}) => {
        const tooltip = {
            backgroundColor: '#000',
            opacity: '0.8',
            borderRadius: '4px',
            paddingTop: '8px',
        }

        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip " style={tooltip}>
                    <p
                        style={{
                            textAlign: 'center',
                            color: '#fff',
                            fontSize: 13,
                            fontWeight: 400,
                        }}>
                        {label}
                    </p>
                    <div
                        style={{
                            marginTop: -10,
                            marginBottom: 8,
                            borderTopWidth: '1px',
                            borderTopColor: '#404040',
                            borderTopStyle: 'solid',
                        }}
                    />
                    {payload.map((pld, key) => (
                        <div
                            key={key}
                            className="d-flex align-items-start "
                            style={{paddingLeft: 10, paddingRight: 10}}>
                            <div className="me-2">
                                <i className="mdi mdi-square" style={{color: pld.stroke}} />
                            </div>
                            <div className="flex-grow-1">
                                <div className="text-muted">
                                    <p
                                        className="mb-0"
                                        style={{
                                            color: '#fff',
                                            fontSize: 12,
                                            fontWeight: 500,
                                            fontFamily: 'Inter',
                                        }}>
                                        {' '}
                                        {pld.dataKey}
                                    </p>
                                </div>
                            </div>

                            <div className="ml-2">
                                <p
                                    className=""
                                    style={{
                                        color: '#fff',
                                        marginLeft: 40,
                                        fontSize: 13,
                                        fontWeight: 600,
                                        fontFamily: 'Inter',
                                    }}>
                                    {pld.value}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            )
        }

        return null
    }

    const handleSlideLeft = () => {
        const results = []

        for (let i = 0; i < data.length; i++) {
            if (count == 1 && results.length < 10 && i > 9) {
                results.push(data[i])
            } else if (count === 0 && results.length < 10) {
                results.push(data[i])
            }
        }
        setCount(current => (current === 0 ? 0 : current - 1))

        setDataMonth(results)
    }

    const handleSlideRight = () => {
        const results = []
        for (let i = 0; i < data.length; i++) {
            if (count === 0 && results.length < 10 && i > 9) {
                results.push(data[i])
            } else if (count === 1 && i > 19) {
                results.push(data[i])
            }
        }
        setCount(current => (current === 1 ? 1 : current + 1))
        setDataMonth(results)
    }

    const setChartTypeMonth = () => {
        const results = []
        for (let i = 0; i < 10; i++) {
            results.push(data[i])
        }

        setDataMonth(results)
    }

    return (
        <Col xl="6">
            {skeleton ? (
                <WorkingDroneSkeleton />
            ) : (
                <Card className="card-working-drone">
                    <CardBody>
                        <Row>
                            <Col xs={12} lg={12}>
                                <div className=" me-3">
                                    <h4 className="text-head">{t('dashboard.numberOfDronesWorkingEachDay')}</h4>
                                </div>

                                <div>
                                    <p className="mb-4 text-muted text-sub">
                                        {t('dashboard.dronesRegisteredInTheKiDroneSystem')}
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} lg={12}>
                                <div className="div-display ">
                                    <label className="text">{t('common.displayOption')} :</label>

                                    <button
                                        onClick={() => handleChangeTab('bar')}
                                        type="button"
                                        className={'btn w-xs  rainbow-1 btn-type'}
                                        style={{
                                            backgroundColor: tab === 'bar' ? '#E8ECFF' : '#EFF0F2',
                                            borderColor: tab === 'bar' ? '#4965EA' : '#fff',
                                        }}>
                                        <div>
                                            {tab === 'bar' ? (
                                                <img src={barChartIcon} alt="" height="13" />
                                            ) : (
                                                <img src={IconBar2} alt="" height="13" />
                                            )}
                                        </div>
                                        <div className="text-sub" style={{color: tab === 'bar' ? '#4965EA' : '#000'}}>
                                            {t('common.barChart')}
                                        </div>
                                    </button>

                                    {/* ------------------------------------------------------- */}
                                    <button
                                        onClick={() => handleChangeTab('line')}
                                        type="button"
                                        className={'btn w-xs rainbow-1 btn-type '}
                                        style={{
                                            borderColor: tab === 'line' ? '#4965EA' : '#fff',
                                            backgroundColor: tab === 'line' ? '#E8ECFF' : '#EFF0F2',
                                        }}>
                                        <div>
                                            {tab !== 'line' ? (
                                                <img src={lineChartIcon} alt="" height="13.5" />
                                            ) : (
                                                <img src={IconLine2} alt="" height="13.5" />
                                            )}
                                        </div>{' '}
                                        <div className="text-sub" style={{color: tab === 'line' ? '#4965EA' : '#000'}}>
                                            {t('common.lineChart')}
                                        </div>
                                    </button>
                                </div>
                            </Col>
                            <Col xs={12} lg={12}>
                                {data?.length > 0 ? (
                                    <div className="results-working-drone">
                                        {tab === 'bar' ? (
                                            <ResponsiveContainer>
                                                <BarChart
                                                    data={searchFilter.type == 'month' ? dataMonth : data}
                                                    margin={{
                                                        top: 0,
                                                        right: 30,
                                                        left: 0,
                                                        bottom: 20,
                                                    }}>
                                                    <CartesianGrid stroke="#E0E0E0" vertical={false} />
                                                    <XAxis
                                                        angle={-45}
                                                        interval={0}
                                                        textAnchor="end"
                                                        dataKey={'name'}
                                                        stroke="#373D3F"
                                                        style={{
                                                            color: '#373D3F',
                                                            textAlign: 'center',
                                                            fontSize: 12,
                                                            fontWeight: 300,
                                                            fontFamily: 'Kanit',
                                                        }}
                                                    />

                                                    <YAxis
                                                        stroke="#373D3F"
                                                        tickLine={false}
                                                        label={
                                                            <Text
                                                                x={-30}
                                                                y={80}
                                                                dx={50}
                                                                dy={150}
                                                                offset={0}
                                                                angle={-90}
                                                                fill={'#495057'}
                                                                fontSize={'12'}
                                                                style={{
                                                                    textAlign: 'center',
                                                                    fontSize: 12,
                                                                    fontWeight: 500,
                                                                    fontFamily: 'Kanit',
                                                                }}>
                                                                {t('dashboard.droneAmountUnit')}
                                                            </Text>
                                                        }
                                                    />
                                                    <Tooltip content={<CustomTooltip />} cursor={{fill: '#EAEAEA'}} />
                                                    <Legend
                                                        textDecoration={{
                                                            color: '#fff',
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            fontFamily: 'Inter',
                                                        }}
                                                        textLength={{
                                                            color: '#fff',
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            fontFamily: 'Inter',
                                                        }}
                                                        verticalAlign="top"
                                                        height={36}
                                                        align="end"
                                                        inactiveColor="red"
                                                        wrapperStyle={{
                                                            color: '#79829Cff',
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            fontFamily: 'Inter',
                                                            marginTop: -10,
                                                            paddingBottom: 50,
                                                        }}
                                                        payload={labels.map((item, index) => ({
                                                            ke: index,
                                                            id: item.name,
                                                            type: 'square',
                                                            value: `${item.name} `,
                                                            color: item.color,
                                                        }))}
                                                    />
                                                    {labels.map((item, index) => {
                                                        return (
                                                            <Bar
                                                                key={index}
                                                                dataKey={item.name}
                                                                stackId="a"
                                                                fill={item.color}
                                                                barSize={15}
                                                            />
                                                        )
                                                    })}
                                                </BarChart>
                                            </ResponsiveContainer>
                                        ) : (
                                            <ResponsiveContainer>
                                                <LineChart
                                                    data={searchFilter.type == 'month' ? dataMonth : data}
                                                    margin={{
                                                        top: 0,
                                                        right: 30,
                                                        left: 0,
                                                        bottom: 20,
                                                    }}>
                                                    <CartesianGrid stroke="#E0E0E0" vertical={false} />
                                                    <XAxis
                                                        angle={-45}
                                                        interval={0}
                                                        textAnchor="end"
                                                        dataKey={'name'}
                                                        stroke="#373D3F"
                                                        style={{
                                                            color: '#373D3F',
                                                            textAlign: 'center',
                                                            fontSize: 12,
                                                            fontWeight: 300,
                                                            fontFamily: 'Kanit',
                                                        }}
                                                    />

                                                    <YAxis
                                                        stroke="#373D3F"
                                                        tickLine={false}
                                                        label={
                                                            <Text
                                                                x={-30}
                                                                y={80}
                                                                dx={50}
                                                                dy={150}
                                                                offset={0}
                                                                angle={-90}
                                                                fill={'#495057'}
                                                                fontSize={'12'}
                                                                style={{
                                                                    textAlign: 'center',
                                                                    fontSize: 12,
                                                                    fontWeight: 500,
                                                                    fontFamily: 'Kanit',
                                                                }}>
                                                                {t('dashboard.droneAmountUnit')}
                                                            </Text>
                                                        }
                                                    />

                                                    <Tooltip
                                                        content={<CustomTooltipLine />}
                                                        cursor={{fill: '#EAEAEA'}}
                                                    />

                                                    <Legend
                                                        textDecoration={{
                                                            color: '#fff',
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            fontFamily: 'Inter',
                                                        }}
                                                        textLength={{
                                                            color: '#fff',
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            fontFamily: 'Inter',
                                                        }}
                                                        verticalAlign="top"
                                                        height={36}
                                                        align="end"
                                                        inactiveColor="red"
                                                        wrapperStyle={{
                                                            color: '#79829Cff',
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            fontFamily: 'Inter',

                                                            marginTop: -10,
                                                            paddingBottom: 50,
                                                        }}
                                                        payload={labels.map((item, index) => ({
                                                            ke: index,
                                                            id: item.name,
                                                            type: 'line',
                                                            value: `${item.name} `,
                                                            color: item.color != null ? item.color : '#000',
                                                        }))}
                                                    />

                                                    {labels.map((item, index) => {
                                                        return (
                                                            <Line
                                                                key={index}
                                                                dataKey={item.name}
                                                                type="monotone"
                                                                stroke={item.color != null ? item.color : '#000'}
                                                                strokeWidth={1.5}
                                                            />
                                                        )
                                                    })}
                                                </LineChart>
                                            </ResponsiveContainer>
                                        )}
                                    </div>
                                ) : (
                                    <div className="div-no-data">
                                        <p className="text-data">{t('common.noData')}</p>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        {searchFilter.type === 'month' && labels.length > 0 && (
                            <div
                                className="col align-items-center d-flex justify-content-between "
                                style={{marginTop: '-5%'}}>
                                <span
                                    className="btn "
                                    onClick={() => {
                                        handleSlideLeft()
                                    }}
                                    style={{color: '#9AA2A9', marginLeft: '2%'}}>
                                    <i className="mdi mdi-triangle mdi-2px mdi-rotate-270"></i>
                                </span>
                                <span
                                    className="btn"
                                    onClick={() => {
                                        handleSlideRight()
                                    }}
                                    style={{color: '#9AA2A9', marginLeft: '12%'}}>
                                    <i className="mdi mdi-triangle mdi-2px mdi-rotate-90"></i>
                                </span>
                            </div>
                        )}
                    </CardBody>
                </Card>
            )}
        </Col>
    )
}

export default withTranslation()(WorkingDrone)
