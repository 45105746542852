const userInalyze = {
    userInalyze: ' User iNalyze',
    totalUsers: 'Total users',
    newUsers: 'New Users',
    totalJobs: 'Total Jobs',
    personUnit: 'Person',
    jobUnit: 'Jobs',
    completedTasks: 'Completed tasks',
    ongoingTasks: 'Ongoing tasks',
    averageTasks: 'Average tasks',
    totalUsersMap: 'Total users',
    numberOfFlightsTimes: 'Number of Flights (Times)',
    workingAreaRai: 'Working Area (Rai)',
    yAxis: 'Y Axis',
    numberOfSprayingTimes: 'Number of Spraying (Times)',
    numberOfSpreadingTimes: 'Number of Spreading (Times)',
    spreadingRateKgTai: 'Spreading Rate (Kg./Rai)',
    sprayingRateLRai: 'Spraying Rate (L.Rai)',
    xAxis: 'X Axis',
    informationOnUsageAndUsageInEachArea: 'Information on usage and usage in each area',
}

export default userInalyze
