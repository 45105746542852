import {useEffect, useState} from 'react'
import {useRecoilState, useRecoilValue} from 'recoil'
import {Row, Col, Card, CardBody} from 'reactstrap'
import {Select} from 'antd'
const {Option} = Select

import {filterDashboardState, loadingDashboardState} from 'store/atoms/dashboard'
import '../style/drone-overview.scss'
import {withTranslation} from 'react-i18next'
import {getDistrict, getFilterProvince} from 'services/skc/lookup'

import OverviewDetail from './OverviewDetail'
import OverviewAverage from './OverviewAverage'
import {getOverviewAverage, getOverviewDetail} from 'services/skc/new-dashboard'
import OverviewDetailSkeleton from '../CardSkeleton/OverviewDetailSkeleton'
import OverviewAverageSkeleton from '../CardSkeleton/OverviewAverageSkeleton'
import OverviewSearchSkeleton from '../CardSkeleton/OverviewSearchSkeleton'

const DroneOverview = props => {
    const {t, i18n} = props

    const searchFilter = useRecoilValue(filterDashboardState)
    const [loading, setLoading] = useRecoilState(loadingDashboardState)

    const [selectProvince, setSelectProvince] = useState('all')
    const [selectDistrict, setSelectDistrict] = useState('all')

    const [lutProvince, setLutProvince] = useState([])
    const [lutDistrict, setLutDistrict] = useState([])
    const [dataDetail, setDataDetail] = useState({})
    const [dataAverage, setDataAverage] = useState({})
    const [tabAverage, setTabAverage] = useState('MEAN')

    const [disableProvince, setDisableProvince] = useState(false)
    const [disableDistrict, setDisableDistrict] = useState(false)

    const [SkeletonDetail, setSkeletonDetail] = useState(true)
    const [SkeletonAverage, setSkeletonAverage] = useState(true)

    useEffect(() => {
        fetchProvince()
        fetchOverviewAverage({...searchFilter, province: '', district: ''})
        fetchOverviewDetail({...searchFilter, province: '', district: ''})
    }, [])

    useEffect(() => {
        if (loading.drone_overview) {
            fetchProvince()
            checkRegionOnlyOther()

            if (searchFilter?.region?.includes('other') && searchFilter?.region?.length === 1) {
                fetchOverviewAverage({...searchFilter, province: 'other', district: 'other'})
                fetchOverviewDetail({...searchFilter, province: '', district: ''})
            } else {
                fetchOverviewAverage({...searchFilter, province: '', district: ''})
                fetchOverviewDetail({...searchFilter, province: '', district: ''})
            }
            setLoading(cur => ({...cur, drone_overview: false}))
        }
    }, [loading.drone_overview])

    const changeFirstString = str => {
        const changeString = str
            .toLowerCase()
            .split(' ')
            .map(item => {
                return item[0].toUpperCase() + item.slice(1).toLowerCase()
            })

        return changeString.join(' ')
    }

    const fetchProvince = async () => {
        try {
            const response = await getFilterProvince({
                region: searchFilter.region.length === 0 ? ['2', '3', '4', '5', '6', '7'] : searchFilter.region,
            })

            if (response.status_code === 200 && response.results.data.length > 0) {
                setLutProvince(response.results.data)
            }
        } catch (error) {
            setLutProvince([])
        }
    }

    const fetchDistrict = async id => {
        try {
            const response = await getDistrict(id)

            if (response.status_code === 200 && response.results.length > 0) {
                setLutDistrict(response.results)
            }
        } catch (error) {
            setLutDistrict([])
        }
    }

    const fetchOverviewDetail = async params => {
        try {
            setSkeletonDetail(true)

            const response = await getOverviewDetail(params)

            if (response.status_code === 200 && response.results) {
                setDataDetail(response.results)
            }
        } catch (error) {
            setDataDetail({})
        } finally {
            setSkeletonDetail(false)
        }
    }

    const fetchOverviewAverage = async params => {
        try {
            setSkeletonAverage(true)

            const responseAverage = await getOverviewAverage(params, 'MEAN')

            if (responseAverage.status_code === 200 && responseAverage.results) {
                setDataAverage(responseAverage.results)
            }
        } catch (error) {
            setDataAverage({})
        } finally {
            setSkeletonAverage(false)
        }
    }

    const handleChangeProvince = value => {
        setSelectProvince(value)

        if (value === 'other') {
            setDisableDistrict(true)
            setSelectDistrict('other')
            setLutDistrict([])
        }

        if (value === 'all') {
            setDisableDistrict(false)
            setSelectDistrict('all')
        }

        if (value !== 'all' && value !== 'other') {
            fetchDistrict(value)
            setDisableDistrict(false)
        }
    }

    const handleChangeDistrict = value => {
        setSelectDistrict(value)
    }

    const onSubmit = () => {
        setTabAverage('MEAN')
        fetchOverviewAverage({
            ...searchFilter,
            province: selectProvince === 'all' ? '' : selectProvince,
            district: selectDistrict === 'all' ? '' : selectDistrict,
        })
        fetchOverviewDetail({
            ...searchFilter,
            province: selectProvince === 'all' ? '' : selectProvince,
            district: selectDistrict === 'all' ? '' : selectDistrict,
        })
    }

    const handleChangeTabAverage = async value => {
        setTabAverage(value)

        try {
            setSkeletonAverage(true)
            let params = {
                ...searchFilter,
            }
            if (searchFilter.country !== 'all' && searchFilter.country !== 'th') {
                params = {
                    ...params,
                    province: '',
                    district: '',
                }
            } else {
                params = {
                    ...params,
                    province: selectProvince === 'all' ? '' : selectProvince,
                    district: selectDistrict === 'all' ? '' : selectDistrict,
                }
            }
            const responseAverage = await getOverviewAverage(params, value)

            if (responseAverage.status_code === 200 && responseAverage.results) {
                setDataAverage(responseAverage.results)
            }
        } catch (error) {
            setDataDetail({})
        } finally {
            setSkeletonAverage(false)
        }
    }

    const checkRegionOnlyOther = () => {
        if (searchFilter?.region?.includes('other') && searchFilter?.region?.length === 1) {
            setSelectProvince('other')
            setSelectDistrict('other')
            setDisableDistrict(true)
            setDisableProvince(true)
        } else {
            setDisableDistrict(false)
            setDisableProvince(false)
            setSelectProvince('all')
            setSelectDistrict('all')
        }
    }

    return (
        <Col lg={12}>
            <Card className="mini-stats-wid card-drone-overview">
                <CardBody>
                    {SkeletonDetail || SkeletonAverage ? (
                        <OverviewSearchSkeleton />
                    ) : (
                        <>
                            {(searchFilter.country === 'all' || searchFilter.country === 'th') && (
                                <div className="d-flex align-items-center justify-content-center">
                                    <Select
                                        disabled={disableProvince}
                                        className="div-select"
                                        value={selectProvince}
                                        onChange={handleChangeProvince}>
                                        <Option value={'all'} label={t('common.all')}>
                                            {t('common.all')}
                                        </Option>
                                        <Option value={'other'} label={t('common.provinceNotSpecified')}>
                                            {t('common.provinceNotSpecified')}
                                        </Option>
                                        {lutProvince?.map((item, index) => (
                                            <Option
                                                key={index}
                                                value={item.id}
                                                label={i18n.language === 'en' ? item.prov_name_en : item?.prov_name_th}>
                                                {i18n.language === 'en' ? item.prov_name_en : item?.prov_name_th}
                                            </Option>
                                        ))}
                                    </Select>
                                    <Select
                                        className="div-select"
                                        value={selectDistrict}
                                        onChange={handleChangeDistrict}
                                        disabled={disableDistrict}>
                                        <Option value={'all'} label={t('common.all')}>
                                            {t('common.all')}
                                        </Option>
                                        {selectProvince !== 'all' && (
                                            <Option value={'other'} label={t('common.districtNotSpecified')}>
                                                {t('common.districtNotSpecified')}
                                            </Option>
                                        )}
                                        {lutDistrict?.map((item, index) => (
                                            <Option
                                                key={index}
                                                value={item.value}
                                                label={
                                                    i18n.language === 'en'
                                                        ? changeFirstString(item?.label_en)
                                                        : item?.label
                                                }>
                                                {i18n.language === 'en'
                                                    ? changeFirstString(item?.label_en)
                                                    : item?.label}
                                            </Option>
                                        ))}
                                    </Select>
                                    <button
                                        onClick={onSubmit}
                                        type="button"
                                        className="btn btn-primary w-xs button-submit">
                                        {t('common.searchInformation')}
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                    <Row className="row-detail">
                        <Col lg={6}>
                            {SkeletonDetail ? (
                                <OverviewDetailSkeleton />
                            ) : (
                                <OverviewDetail data={dataDetail} searchFilter={searchFilter} />
                            )}
                        </Col>

                        <Col lg={6}>
                            {SkeletonAverage ? (
                                <OverviewAverageSkeleton />
                            ) : (
                                <OverviewAverage
                                    changeTab={handleChangeTabAverage}
                                    tabData={tabAverage}
                                    data={dataAverage}
                                    searchFilter={searchFilter}
                                />
                            )}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

export default withTranslation()(DroneOverview)
