import React, {Component} from 'react'
import {Row, Col, Card, CardBody, CardTitle} from 'reactstrap'
import ReactApexChart from 'react-apexcharts'

import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
class CardAverage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isClickSlideTap: false,
            activeTab: 1,
            countTab: 0,
            checkAverage: false,
            showAverage: 'none',

            series: [],
            seriesNoAverage: [],
            seriesMonth: [],
            labelMonth: [],
        }
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this)
        this.setDataUnitColorSeries = this.setDataUnitColorSeries.bind(this)
        this.setChartTypeMonth = this.setChartTypeMonth.bind(this)
        this.toggle = this.toggle.bind(this)
        this.handleSlideRight = this.handleSlideRight.bind(this)
        this.handleSlideLeft = this.handleSlideLeft.bind(this)
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: Number(tab),
            })
        }
    }

    onChangeCheckbox = value => {
        if (value.target.checked) {
            this.setState({
                showAverage: '',
            })
        } else {
            this.setState({
                showAverage: 'none',
            })
        }
        this.setState({
            checkAverage: value.target.checked,
        })
    }

    componentDidMount() {
        const {series, unit, labels, type} = this.props

        this.setDataUnitColorSeries(series, unit, labels, type)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({isClickSlideTap: false, activeTab: 1, countTab: 0, checkAverage: false, showAverage: 'none'})
        if (nextProps?.series?.length > 0) {
            this.setDataUnitColorSeries(nextProps.series, nextProps.unit, nextProps.labels, nextProps.type)
        }
    }

    setDataUnitColorSeries = (data, unit, labels, type) => {
        const newData = []

        data?.forEach((element, index) => {
            let newItem

            if (element.id === 'average') {
                newItem = {
                    ...element,
                    color: '#F46A6A',
                    unit: unit,
                    name: element.title,
                }
                newData.push(newItem)
            }

            if (index === 0 && element.id !== 'average') {
                newItem = {
                    ...element,
                    color: '#556EE6',
                    unit: unit,
                    name: element.title,
                }
                newData.push(newItem)
            }

            if (index === 1 && element.id !== 'average') {
                newItem = {
                    ...element,
                    color: '#F1B44C',
                    unit: unit,
                    name: element.title,
                }
                newData.push(newItem)
            }

            if (index === 2 && element.id !== 'average') {
                newItem = {
                    ...element,
                    color: '#34C38F',
                    unit: unit,
                    name: element.title,
                }
                newData.push(newItem)
            }

            if (index === 3 && element.id !== 'average') {
                newItem = {
                    ...element,
                    color: '#e83e8c',
                    unit: unit,
                    name: element.title,
                }
                newData.push(newItem)
            }
        })

        const seriesNoAverage = [...newData].filter(itm => itm.id !== 'average')

        this.setState({series: newData, seriesNoAverage})

        if (type === 'month') {
            this.setChartTypeMonth(type, newData, labels, type)
        }
    }

    setChartTypeMonth = (type, series, labels) => {
        const {isClickSlideTap, activeTab} = this.state

        if ((isClickSlideTap === false || activeTab === 1) && type === 'month') {
            const newSeries = series.map(itm => {
                return {
                    ...itm,
                    data: [...itm.data?.slice(0, 10)],
                }
            })

            const newLabels = labels.slice(0, 10)

            const seriesNoAverage = [...newSeries].filter(itm => itm.id !== 'average')
            this.setState({seriesMonth: newSeries, labelMonth: newLabels, seriesNoAverage})
        } else if (activeTab === 2) {
            const newSeries = series.map(itm => {
                return {
                    ...itm,
                    data: [...itm.data?.slice(10, 20)],
                }
            })

            const newLabels = labels.slice(10, 20)

            const seriesNoAverage = [...newSeries].filter(itm => itm.id !== 'average')
            this.setState({seriesMonth: newSeries, labelMonth: newLabels, seriesNoAverage})
        } else {
            const newSeries = series.map(itm => {
                return {
                    ...itm,
                    data: [...itm.data?.slice(20, 31)],
                }
            })

            const newLabels = labels.slice(20, 310)

            const seriesNoAverage = [...newSeries].filter(itm => itm.id !== 'average')
            this.setState({seriesMonth: newSeries, labelMonth: newLabels, seriesNoAverage})
        }
    }

    handleSlideRight() {
        const {labels, series} = this.props
        const {activeTab} = this.state
        const tabCount = activeTab + 1

        if (tabCount <= 3) {
            if (activeTab + 1 === 1) {
                const newSeries = series.map(itm => {
                    return {
                        ...itm,
                        data: [...itm.data?.slice(0, 10)],
                    }
                })

                const newLabels = labels.slice(0, 10)

                const seriesNoAverage = [...newSeries].filter(itm => itm.id !== 'average')
                this.setState({seriesMonth: newSeries, labelMonth: newLabels, seriesNoAverage})
            } else if (tabCount === 2) {
                const newSeries = series.map(itm => {
                    return {
                        ...itm,
                        data: [...itm.data?.slice(10, 20)],
                    }
                })

                const newLabels = labels.slice(10, 20)

                const seriesNoAverage = [...newSeries].filter(itm => itm.id !== 'average')
                this.setState({seriesMonth: newSeries, labelMonth: newLabels, seriesNoAverage})
            } else {
                const newSeries = series.map(itm => {
                    return {
                        ...itm,
                        data: [...itm.data?.slice(20, 31)],
                    }
                })

                const newLabels = labels.slice(20, 310)

                const seriesNoAverage = [...newSeries].filter(itm => itm.id !== 'average')
                this.setState({seriesMonth: newSeries, labelMonth: newLabels, seriesNoAverage})
            }
        }
    }

    handleSlideLeft() {
        const {series, labels} = this.props
        const {activeTab} = this.state

        const tabCount = activeTab - 1
        if (tabCount !== 0) {
            if (activeTab - 1 === 1) {
                const newSeries = series.map(itm => {
                    return {
                        ...itm,
                        data: [...itm.data?.slice(0, 10)],
                    }
                })

                const newLabels = labels.slice(0, 10)

                const seriesNoAverage = [...newSeries].filter(itm => itm.id !== 'average')
                this.setState({seriesMonth: newSeries, labelMonth: newLabels, seriesNoAverage})
            } else if (tabCount === 2) {
                const newSeries = series.map(itm => {
                    return {
                        ...itm,
                        data: [...itm.data?.slice(10, 20)],
                    }
                })

                const newLabels = labels.slice(10, 20)

                const seriesNoAverage = [...newSeries].filter(itm => itm.id !== 'average')
                this.setState({seriesMonth: newSeries, labelMonth: newLabels, seriesNoAverage})
            } else {
                const newSeries = series.map(itm => {
                    return {
                        ...itm,
                        data: [...itm.data?.slice(20, 31)],
                    }
                })

                const newLabels = labels.slice(20, 310)

                const seriesNoAverage = [...newSeries].filter(itm => itm.id !== 'average')
                this.setState({seriesMonth: newSeries, labelMonth: newLabels, seriesNoAverage})
            }
        }
    }

    render() {
        const state = this.state
        const {t} = this.props

        const {type, unit} = this.props
        let nf = new Intl.NumberFormat()

        let formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
        })

        let displaySeries = [...state.series]

        if (type === 'month' && !state.checkAverage) {
            displaySeries = state.seriesNoAverage
        }

        if (type === 'month' && state.checkAverage) {
            displaySeries = state.seriesMonth
        }

        if (type !== 'month' && !state.checkAverage) {
            displaySeries = state.seriesNoAverage
        }

        if (type !== 'month' && state.checkAverage) {
            displaySeries = state.series
        }

        return (
            <Card className="card-average">
                <CardTitle className="title-card">
                    <p className="title">{this.props.title}</p>

                    <div className="check-box-average form-check-success">
                        <input
                            type="checkbox"
                            className="form-check-input check-box-input "
                            id="customCheckcolor2"
                            checked={state.checkAverage}
                            onChange={e => this.onChangeCheckbox(e)}
                        />
                        <p className="text-average">{t('inalyze.averageLine')}</p>
                    </div>
                </CardTitle>
                <CardBody>
                    <Row style={{margin: 0, padding: 0}}>
                        <Col xl={12}>
                            <Row style={{margin: 0, padding: 0}}>
                                {state?.series?.map((item, index, array) => (
                                    <Col
                                        xl={array.length === 2 ? 6 : ''}
                                        className="box-average"
                                        style={{
                                            borderRight:
                                                index + 1 != array.length && index + 1 != array.length - 1
                                                    ? 'none'
                                                    : '',
                                            display: item.id === 'average' ? state?.showAverage : '',
                                        }}
                                        key={index}>
                                        <div className="country-average">
                                            <div style={{background: item.color}} className="dot-color"></div>
                                            <p className="text-country">{item.title || '-'}</p>
                                        </div>
                                        <div className="box-data">
                                            <p className="data">{Number(item?.total).toLocaleString() || '-'}</p>
                                            <p className="unit">{item.unit || '-'}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                        <Col size={12}>
                            <ReactApexChart
                                options={{
                                    chart: {
                                        height: 350,
                                        type: 'line',
                                        toolbar: {
                                            show: false,
                                        },
                                        zoom: {
                                            enabled: false,
                                        },
                                        animations: {
                                            enabled: false,
                                        },
                                    },
                                    stroke: {
                                        curve: 'smooth',
                                        width: 1,
                                    },
                                    markers: {
                                        size: 4,
                                        hover: {
                                            size: undefined,
                                            sizeOffset: 3,
                                        },
                                    },

                                    plotOptions: {
                                        bar: {
                                            horizontal: false,
                                            columnWidth: '35%',
                                            endingShape: 'rounded',
                                        },
                                    },

                                    grid: {
                                        show: true,
                                        borderColor: '#E0E0E0',
                                        strokeDashArray: 0,
                                        position: 'back',
                                        xaxis: {
                                            lines: {
                                                show: false,
                                            },
                                        },
                                        yaxis: {
                                            lines: {
                                                show: true,
                                            },
                                        },
                                    },
                                    legend: {
                                        show: false,
                                    },
                                    dataLabels: {
                                        enabled: false,
                                    },
                                    labels: [],
                                    xaxis: {
                                        // ค่า categories ที่ส่งมา data เป็นอะไร  exsample เป็น เดือน
                                        categories: type === 'month' ? state.labelMonth : this.props.labels,
                                        labels: {
                                            style: {
                                                fontSize: '12px',
                                                fontFamily: 'Kanit, sans-serif',
                                                color: '#495057',
                                            },
                                        },
                                        tooltip: {
                                            enabled: false,
                                        },

                                        crosshairs: {
                                            show: true,
                                            width: 'tickWidth',
                                            fill: {
                                                type: 'gradient',
                                                gradient: {
                                                    colorFrom: '#495057',
                                                    colorTo: '#495057',
                                                    stops: [0, 100],
                                                    opacityFrom: 0.4,
                                                    opacityTo: 0.5,
                                                },
                                            },
                                        },
                                    },

                                    yaxis: {
                                        title: {
                                            //อันนี้รอเปลี่ยน text ด้านซ้าย
                                            text: this.props.yaxisText,
                                            offsetX: 8,
                                            offsetY: 0,
                                            style: {
                                                fontSize: '12px',
                                            },
                                        },
                                        tickAmount: 5,
                                        axisTicks: {
                                            show: true,
                                            borderType: 'solid',
                                            color: '#E0E0E0',
                                            height: 6,
                                        },
                                        axisBorder: {
                                            show: true,
                                            color: '#E0E0E0',
                                        },
                                        labels: {
                                            style: {
                                                fontSize: 12,
                                            },
                                            formatter: value => {
                                                return nf.format(value)
                                            },
                                        },
                                        crosshairs: {
                                            show: true,
                                            position: 'back',
                                            stroke: {
                                                color: '#b6b6b6',
                                                width: 1,
                                                dashArray: 0,
                                            },
                                        },
                                    },

                                    tooltip: {
                                        shared: true,
                                        theme: 'dark',
                                        intersect: false,

                                        y: {
                                            formatter: function (y) {
                                                if (typeof y !== 'undefined') {
                                                    return `${y.toLocaleString()} ${unit}`
                                                }
                                                return y
                                            },
                                        },
                                    },
                                }}
                                series={displaySeries}
                                type="area"
                                height="350"
                            />
                            {type === 'month' && state.labelMonth && (
                                <div
                                    className="col align-items-center d-flex justify-content-between "
                                    style={{marginTop: '-7.5%'}}>
                                    <span
                                        className="btn"
                                        style={{color: '#9AA2A9', marginLeft: '-1%', marginTop: '0.5%'}}
                                        onClick={() => {
                                            this.handleSlideLeft()
                                            state.activeTab > 1
                                                ? this.toggle(Number(state.activeTab) - 1)
                                                : this.toggle(1)
                                        }}>
                                        <i className="mdi mdi-triangle mdi-2px mdi-rotate-270"></i>
                                    </span>
                                    <span
                                        className="btn"
                                        style={{color: '#9AA2A9', marginRight: '-3%'}}
                                        onClick={() => {
                                            this.handleSlideRight()
                                            state.activeTab < 3
                                                ? this.toggle(Number(state.activeTab) + 1)
                                                : this.toggle(3)
                                        }}>
                                        <i className="mdi mdi-triangle mdi-2px mdi-rotate-90"></i>
                                    </span>
                                </div>
                            )}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

const mapStateToProps = ({compareAreaAverage}) => ({
    // loadingSkeletonAverage: compareAreaAverage.finally,
})
const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CardAverage))
