import {atom} from 'recoil'
import dayjs from 'dayjs'

export const filterUserInformationState = atom({
    key: 'filterUserInformation',
    default: {
        type: 'year',
        month: dayjs().month() + 1,
        week: dayjs().week(),
        year: dayjs().year(),
        start_date: dayjs().startOf('year').format('YYYY-MM-DD'),
        end_date: dayjs().endOf('year').format('YYYY-MM-DD'),
        id: 'start',
        date: dayjs(),
    },
})

export const loadingUserInformationState = atom({
    key: 'loadingUserInformation',
    default: {
        data_pilot: false,
        data_drone: false,
        work_overview: false,
        flight_time_ranking: false,
        information_area: false,
        flight_history: false,
        drone_overview: false,
        drone_detail: false,
        drone_flight_duration: false,
        drone_working_area: false,
        drone_speed: false,
        drone_height: false,
        drone_flight_history: false,
        drone_table: false,
    },
})
