import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {
    changeLayout,
    changeSidebarTheme,
    changeSidebarThemeImage,
    changeSidebarType,
    toggleRightSidebar,
    changeTopbarTheme,
    changeLayoutWidth,
} from '../../store/actions'

// Layout Related Components
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'
import RightSidebar from '../CommonForBoth/RightSidebar'

class Layout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
            width: 0,
            height: 0,
        }
        this.toggleMenuCallback = this.toggleMenuCallback.bind(this)
        this.hideRightbar = this.hideRightbar.bind(this)
    }

    capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2)
    }

    componentDidMount() {
        document.body.addEventListener('click', this.hideRightbar, true)

        if (this.props.isPreloader === true) {
            document.getElementById('preloader').style.display = 'block'
            document.getElementById('status').style.display = 'block'

            setTimeout(function () {
                document.getElementById('preloader').style.display = 'none'
                document.getElementById('status').style.display = 'none'
            }, 2500)
        } else {
            document.getElementById('preloader').style.display = 'none'
            document.getElementById('status').style.display = 'none'
        }

        // Scroll Top to 0
        window.scrollTo(0, 0)
        // let currentage = this.capitalizeFirstLetter(this.props.location.pathname)

        // document.title =
        //   currentage + " | Skote - React Admin & Dashboard Template"
        if (this.props.leftSideBarTheme) {
            this.props.changeSidebarTheme(this.props.leftSideBarTheme)
        }

        if (this.props.leftSideBarThemeImage) {
            this.props.changeSidebarThemeImage(this.props.leftSideBarThemeImage)
        }

        if (this.props.layoutWidth) {
            this.props.changeLayoutWidth(this.props.layoutWidth)
        }

        if (this.props.leftSideBarType) {
            this.props.changeSidebarType(this.props.leftSideBarType)
        }
        if (this.props.topbarTheme) {
            this.props.changeTopbarTheme(this.props.topbarTheme)
        }
    }

    componentDidUpdate() {
        let body = document.body
        let menuDrone = document?.getElementById('arrow-menu-drone')
        let menuUser = document?.getElementById('arrow-menu-user')

        if (window.screen.width <= 1440) {
            body.classList.add('vertical-collpsed')
            body.classList.add('sidebar-enable')
            menuDrone.classList.add('hide-arrow-menu')
            menuUser.classList.add('hide-arrow-menu')
        }
    }

    toggleMenuCallback = () => {
        let body = document.body
        let menuDrone = document?.getElementById('arrow-menu-drone')
        let menuUser = document?.getElementById('arrow-menu-user')

        if (menuDrone) {
            menuDrone.classList.toggle('hide-arrow-menu')
        }
        if (menuUser) {
            menuUser.classList.toggle('hide-arrow-menu')
        }

        if (window.screen.width <= 998) {
            body.classList.toggle('sidebar-enable')
        } else {
            body.classList.toggle('vertical-collpsed')
            body.classList.toggle('sidebar-enable')
        }
    }

    // //hides right sidebar on body click
    hideRightbar = event => {
        var rightbar = document.getElementById('right-bar')
        //if clicked in inside right bar, then do nothing
        if (rightbar && rightbar.contains(event.target)) {
            return
        } else {
            if (document.body.classList.contains('right-bar-enabled')) {
                this.props.toggleRightSidebar(false)
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                        </div>
                    </div>
                </div>

                <div id="layout-wrapper">
                    <Header toggleMenuCallback={this.toggleMenuCallback} />
                    <Sidebar
                        theme={this.props.leftSideBarTheme}
                        type={this.props.leftSideBarType}
                        isMobile={this.state.isMobile}
                    />
                    <div className="main-content">{this.props.children}</div>
                    <Footer />
                </div>
                {this.props.showRightSidebar ? <RightSidebar /> : null}
            </React.Fragment>
        )
    }
}

Layout.propTypes = {
    changeLayoutWidth: PropTypes.func,
    changeSidebarTheme: PropTypes.func,
    changeSidebarThemeImage: PropTypes.func,
    changeSidebarType: PropTypes.func,
    changeTopbarTheme: PropTypes.func,
    children: PropTypes.any,
    isPreloader: PropTypes.bool,
    layoutWidth: PropTypes.any,
    leftSideBarTheme: PropTypes.any,
    leftSideBarThemeImage: PropTypes.any,
    leftSideBarType: PropTypes.any,
    location: PropTypes.object,
    showRightSidebar: PropTypes.any,
    toggleRightSidebar: PropTypes.any,
    topbarTheme: PropTypes.any,
}

const mapStateToProps = state => {
    return {
        ...state.Layout,
    }
}
export default connect(mapStateToProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarThemeImage,
    changeSidebarType,
    toggleRightSidebar,
    changeTopbarTheme,
    changeLayoutWidth,
})(withRouter(Layout))
