import React, {Component} from 'react'
import ReactApexChart from 'react-apexcharts'
import {withTranslation} from 'react-i18next'

import './style.scss'

class OptionScatter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            series: [
                {
                    name: 'K-D10',
                    data: [
                        {
                            x: 16,
                            y: 5,
                        },
                        {
                            x: 21,
                            y: 2,
                        },
                        {
                            x: 25,
                            y: 3,
                        },
                    ],
                },
                {
                    name: 'K-D20',
                    data: [
                        {
                            x: 36,
                            y: 13,
                        },
                        {
                            x: 1,
                            y: 11,
                        },
                        {
                            x: 5,
                            y: 8,
                        },
                    ],
                },
            ],
            options: {},
        }
    }

    render() {
        const englishText = 'This is an example text in English.'
        const thaiText = 'นี่คือตัวอย่างข้อความภาษาไทย'

        var nf = new Intl.NumberFormat('en-NZ', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        var nd = new Intl.NumberFormat('en-NZ', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
        const topix = this.state
        const {t} = this.props

        switch (this.props.data) {
            case t('common.droneModel'):
                topix.options = {
                    colors: this.props.color,
                    chart: {
                        id: 'chart1',
                        height: 350,
                        type: 'scatter',
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false,
                        },
                        animations: {
                            enabled: false,
                        },
                        events: {
                            legendClick: function (chartContext, seriesIndex, config) {},
                        },
                    },

                    dataLabels: {
                        enabled: false,
                    },

                    legend: {
                        // inverseOrder:true,
                        showForSingleSeries: true,
                        showForNullSeries: true,
                        showForZeroSeries: true,
                        show: true,
                        position: 'top',
                        horizontalAlign: 'right',
                        fontFamily: 'Inter, Arial',
                        fontSize: '18px',
                        fontWeight: 400,
                        color: '#495057',
                        formatter: function (seriesName) {
                            return '<span class="custom-marker " id="series-legend">' + seriesName + '</h5></span>'
                        },
                        labels: {
                            colors: '#495057',
                            useSeriesColors: false,
                        },
                        onItemClick: {
                            toggleDataSeries: false,
                        },
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: true,
                            },
                        },
                        yaxis: {
                            lines: {
                                show: true,
                            },
                        },
                    },
                    xaxis: {
                        type: 'numeric',
                        min: 0,
                        // max:-15,
                        max: this.props.max_x == 0 ? 15 : this.props.max_x,
                        tickAmount: 15,
                        title: {
                            text: this.props.name_x,
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                fontSize: '14px',
                            },
                        },
                        tooltip: {
                            formatter: function (val, opts) {
                                switch (opts.w.config.xaxis.title.text) {
                                    case t('userInalyze.numberOfFlightsTimes'):
                                        return nf.format(val)
                                        break
                                    default:
                                        return nd.format(val)
                                }
                            },
                        },
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Inter sans-serif !important',
                                cssClass: 'xaxis-label',
                            },
                            formatter: value => {
                                return nf.format(Number(value))
                            },
                        },
                    },
                    yaxis: {
                        min: 0,
                        max: this.props.max_y == 0 ? 7 : this.props.max_y,
                        tickAmount: 7,
                        axisTicks: {
                            show: false,
                            borderType: 'solid',
                            color: '#E0E0E0',
                            height: 6,
                            // offsetX: 20,
                        },
                        axisBorder: {
                            show: true,
                            color: '#E0E0E0',
                        },
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Inter sans-serif !important',
                                cssClass: 'xaxis-label',
                            },
                            formatter: value => {
                                return nf.format(Number(value))
                            },
                        },
                        title: {
                            text: this.props.name_y,
                            style: {
                                fontSize: '14px',
                            },
                            offsetX: -1,
                            offsetY: 0,
                        },
                    },

                    tooltip: {
                        x: {
                            show: true,
                        },
                        enabled: true,
                        custom({series, seriesIndex, dataPointIndex, w}) {
                            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]
                            var seriesname = w.globals.initialSeries[seriesIndex].name
                            var name_xaxis = w.globals.initialConfig.xaxis.title.text
                            var name_yaxis = w.globals.initialConfig.yaxis[0].title.text

                            switch (name_xaxis) {
                                case t('userInalyze.numberOfFlightsTimes'):
                                    return (
                                        '<div class="myDivI" >' +
                                        '<div class="myDivII">' +
                                        '<h5 id="series-name">' +
                                        seriesname +
                                        '</h5>' +
                                        '</div>' +
                                        '<h5 id="series-xy">' +
                                        name_xaxis +
                                        '\xa0\xa0' +
                                        ':' +
                                        '\xa0\xa0' +
                                        '<span id="value-xy">' +
                                        nf.format(data.x) +
                                        '</span>' +
                                        '</h5>' +
                                        '<h5 id="series-y">' +
                                        name_yaxis +
                                        '\xa0\xa0' +
                                        ':' +
                                        '\xa0\xa0' +
                                        '<span id="value-xy">' +
                                        nd.format(data.y) +
                                        '</span>' +
                                        '</h5>' +
                                        '</div>'
                                    )
                                    break
                                default:
                                    return (
                                        '<div class="myDivI" >' +
                                        '<div class="myDivII">' +
                                        '<h5 id="series-name">' +
                                        seriesname +
                                        '</h5>' +
                                        '</div>' +
                                        '<h5 id="series-xy">' +
                                        name_xaxis +
                                        '\xa0\xa0' +
                                        ':' +
                                        '\xa0\xa0' +
                                        '<span id="value-xy">' +
                                        nd.format(data.x) +
                                        '</span>' +
                                        '</h5>' +
                                        '<h5 id="series-y">' +
                                        name_yaxis +
                                        '\xa0\xa0' +
                                        ':' +
                                        '\xa0\xa0' +
                                        '<span id="value-xy">' +
                                        nf.format(data.y) +
                                        '</span>' +
                                        '</h5>' +
                                        '</div>'
                                    )
                            }
                        },
                    },
                }
                break

            default:
                topix.options = {
                    colors: this.props.color,
                    chart: {
                        height: 350,
                        type: 'scatter',
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false,
                        },
                        animations: {
                            enabled: false,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },

                    legend: {
                        showForSingleSeries: true,
                        showForNullSeries: true,
                        showForZeroSeries: true,
                        show: true,
                        position: 'top',
                        horizontalAlign: 'right',
                        fontFamily: 'Inter, Arial',
                        fontSize: '18px',
                        fontWeight: 400,
                        color: '#495057',
                        formatter: function (seriesName) {
                            return '<span class="custom-marker " id="series-legend-th">' + seriesName + '</h5></span>'
                        },
                        labels: {
                            colors: '#495057',
                            useSeriesColors: false,
                        },
                        onItemClick: {
                            toggleDataSeries: false,
                        },
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: true,
                            },
                        },
                        yaxis: {
                            lines: {
                                show: true,
                            },
                        },
                    },
                    xaxis: {
                        type: 'numeric',
                        min: 0,
                        max: this.props.max_x == 0 ? 15 : this.props.max_x,
                        tickAmount: 15,
                        title: {
                            text: this.props.name_x,
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                fontSize: '14px',
                            },
                        },
                        // style: {
                        //   fontSize: "14px",
                        //   fontFamily: "Inter",
                        //   color: "#495057",
                        // },

                        labels: {
                            style: {
                                fontFamily: 'Inter sans-serif !important',
                                cssClass: 'custom-xaxis-label',
                            },
                            formatter: value => {
                                return nf.format(Number(value))
                            },
                        },
                    },
                    yaxis: {
                        min: 0,
                        max: this.props.max_y == 0 ? 7 : this.props.max_y,
                        tickAmount: 7,
                        axisTicks: {
                            show: false,
                            borderType: 'solid',
                            color: '#E0E0E0',
                            height: 6,
                            // offsetX: 20,
                        },
                        axisBorder: {
                            show: true,
                            color: '#E0E0E0',
                        },
                        labels: {
                            style: {
                                fontFamily: 'Inter sans-serif !important',
                                cssClass: 'xaxis-label',
                            },
                            formatter: value => {
                                return nf.format(value)
                            },
                        },
                        title: {
                            text: this.props.name_y,
                            style: {
                                fontSize: '14px',
                            },
                            offsetX: 10,
                            offsetY: 0,
                        },
                    },

                    tooltip: {
                        x: {
                            show: true,
                        },
                        enabled: true,
                        custom({series, seriesIndex, dataPointIndex, w}) {
                            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]
                            var seriesname = w.globals.initialSeries[seriesIndex].name
                            var name_xaxis = w.globals.initialConfig.xaxis.title.text
                            var name_yaxis = w.globals.initialConfig.yaxis[0].title.text

                            switch (name_xaxis) {
                                case t('userInalyze.numberOfFlightsTimes'):
                                    return (
                                        '<div class="myDivI" >' +
                                        '<div class="myDivII">' +
                                        '<h5 id="series-name">' +
                                        seriesname +
                                        '</h5>' +
                                        '</div>' +
                                        '<h5 id="series-xy">' +
                                        name_xaxis +
                                        '\xa0\xa0' +
                                        ':' +
                                        '\xa0\xa0' +
                                        '<span id="value-xy">' +
                                        nf.format(data.x) +
                                        '</span>' +
                                        '</h5>' +
                                        '<h5 id="series-y">' +
                                        name_yaxis +
                                        '\xa0\xa0' +
                                        ':' +
                                        '\xa0\xa0' +
                                        '<span id="value-xy">' +
                                        nd.format(data.y) +
                                        '</span>' +
                                        '</h5>' +
                                        '</div>'
                                    )
                                    break
                                default:
                                    return (
                                        '<div class="myDivI" >' +
                                        '<div class="myDivII">' +
                                        '<h5 id="series-name">' +
                                        seriesname +
                                        '</h5>' +
                                        '</div>' +
                                        '<h5 id="series-xy">' +
                                        name_xaxis +
                                        '\xa0\xa0' +
                                        ':' +
                                        '\xa0\xa0' +
                                        '<span id="value-xy">' +
                                        nd.format(data.x) +
                                        '</span>' +
                                        '</h5>' +
                                        '<h5 id="series-y">' +
                                        name_yaxis +
                                        '\xa0\xa0' +
                                        ':' +
                                        '\xa0\xa0' +
                                        '<span id="value-xy">' +
                                        nf.format(data.y) +
                                        '</span>' +
                                        '</h5>' +
                                        '</div>'
                                    )
                            }
                        },
                    },
                }
                break
        }
        return (
            <React.Fragment>
                <ReactApexChart
                    options={topix.options}
                    series={this.props?.series?.length > 0 ? this.props?.series : []}
                    type="scatter"
                    height={380}
                />
            </React.Fragment>
        )
    }
}

export default withTranslation()(OptionScatter)
